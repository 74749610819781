export default {
  TABLE_TRAILER_GENERAL_FETCH_STATUSES: 'TABLE_TRAILER_GENERAL_FETCH_STATUSES',
  TABLE_TRAILER_GENERAL_SET_DATA: 'TABLE_TRAILER_GENERAL_SET_DATA',
  TABLE_TRAILER_GENERAL_SET_TOTAL: 'TABLE_TRAILER_GENERAL_SET_TOTAL',
  TABLE_TRAILER_GENERAL_SET_LOADING: 'TABLE_TRAILER_GENERAL_SET_LOADING',
  TABLE_TRAILER_GENERAL_ERROR: 'TABLE_TRAILER_GENERAL_ERROR',
  TABLE_TRAILER_GENERAL_FETCH_DATA: 'TABLE_TRAILER_GENERAL_FETCH_DATA',
  TABLE_TRAILER_GENERAL_SAGA_ONSEARCH_ACTION:
    'TABLE_TRAILER_GENERAL_SAGA_ONSEARCH_ACTION',
};
