import React, { useState } from 'react';
import Chip from '@mui/material/Chip';
import ModalWindow from '../../Modals/FullScreen';
import DriverLoads from '../../../../views/pages/Drivers/Loads';
import { headerMaker } from '../helper2';

export default ({ value }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <ModalWindow
        title="Driver trips"
        modal={showModal}
        handleModal={() => {
          setShowModal(false);
        }}
        body={
          <DriverLoads
            modal={showModal}
            driverId={value.id}
            headerMaker={headerMaker}
            toggle={() => {
              setShowModal(false);
            }}
          />
        }
      />
      <Chip
        size="small"
        style={{
          fontSize: '12px',
          color: 'white',
          backgroundColor: 'green',
          textAlign: 'center',
          textTransform: 'uppercase',
        }}
        label="GET"
        variant="container"
        onClick={() => {
          console.log(value);
          setShowModal(true);
        }}
      />
    </>
  );
};
