import React from 'react';
import { Button, Form, FormGroup, Input } from 'reactstrap';

export default function TokenModal({
  modal,
  toggle,
  addSamsaraToken,
  editSamsaraToken,
  token,
}) {
  return (
    <>
      <Form>
        <FormGroup>
          <Input
            defaultValue={token || ''}
            type="text"
            id="token"
            placeholder="token"
            onChange={(e) => {
              document.querySelector('#token').value = e.target.value;
            }}
          />
        </FormGroup>
      </Form>

      <Button
        color="success"
        className="d-flex align-items-center"
        type="button"
        onClick={() => {
          if (token) {
            editSamsaraToken(document.querySelector('#token').value);
          } else {
            addSamsaraToken(document.querySelector('#token').value);
          }

          toggle();
        }}
      >
        OK
      </Button>
    </>
  );
}
