import types from '../../constants/action-types';

const defaultState = {
  total: 0,
};

const map = {
  [types.TABLE_SET_TOTAL]: (state, { payload }) => ({
    ...state,
    total: payload,
  }),
  [types.SET_PROFILE_IMAGE]: (state, { payload }) => ({
    ...state,
    image: payload,
  }),
  [types.SET_MODAL]: (state, { payload }) => ({
    ...state,
    modal: payload,
  }),
  [types.SET_HEADERS]: (state, { payload }) => ({
    ...state,
    headers: payload,
  }),
  [types.SET_NOTIFICATION]: (state, { payload }) => ({
    ...state,
    notification: payload,
  }),
  [types.SET_MESSAGES]: (state, { payload }) => ({
    ...state,
    messages: payload,
  }),
  [types.SET_REFRESH_PAGE]: (state, { payload }) => ({
    ...state,
    refreshPage: payload,
  }),
  [types.SET_REFRESH_PAGE_1]: (state, { payload }) => ({
    ...state,
    refreshPage1: payload,
  }),
};

export default (state, action) =>
  (map[action.type] && map[action.type](state, action)) ||
  state ||
  defaultState;
