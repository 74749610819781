import { service } from '.';

export default {
  getAll: (query) => service.get(`/fuel/list?${query}`),
  getAllUnitList: (query) => service.get(`/fuel/get_unit_list`),
  getAllBook: (query) => service.get(`/fuel/book_list?${query}`),
  getContext: () => service.get(`/fuel/context`),
  getOne: (id) => service.get(`/fuel/${id}`),
  getSearch: (value) => service.get(`/fuel/search_by_number?q=${value}`),
  getFuelPrices: (query) => service.get(`/fuel/get_fuel_prices?${query}`),
  create: (data) => service.post('/fuel/new', data),
  createBook: (data) => service.post('/fuel/new_book', data),
  updateFuelManagement: (data) =>
    service.post(`/fuel/edit_fuel_management`, data),
  updateInterState: (data) => service.post(`/fuel/edit_interstate`, data),
  updateStatus: (id, status, data) =>
    service.put(`/fuel/update_status/${id}/${status}`, data),
  clearAtHomeTime: (id, data) =>
    service.put(`/fuel/clear_at_home_time/${id}`, data),
  delete: (id) => service.delete(`/fuel/${id}`),
};
