/* eslint-disable import/no-named-as-default */
import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  FormGroup,
  Input,
  // Label,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
} from 'reactstrap';
import * as Icon from 'react-feather';
// eslint-disable-next-line import/no-named-as-default
import AsyncSelect from 'react-select/async';
import {
  // ToastContainer,
  toast,
  // Slide,
  // Zoom,
  Flip,
  // Bounce,
} from 'react-toastify';
import { IconButton, DialogActions } from '@mui/material';
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import PickupModal from '../Modals/Pickup';
import ActivePickup from '../Components/ActivePickup';
import ActiveDelivery from '../Components/ActiveDelivery';
import DeliveryModal from '../Modals/Delivery';
import NewCompany from '../Modals/NewCompany';
// import { history } from "../../../history";
import loadService from '../../../../services/loads';
import stateProvinceService from '../../../../services/state_province';
import companyService from '../../../../services/companies';
import fileUploadService from '../../../../services/file_upload';
import ModalWindow from '../../../../components/main/Modals';

export default function NewLoads() {
  const [abbreviation, setAbbreviation] = useState('');
  const [states, setStates] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [showPickupModal, setShowPickupModal] = useState(false);
  const [showNewCompanyModal, setShowNewCompanyModal] = useState(false);
  const [showDeliveryModal, setShowDeliveryModal] = useState(false);

  const [activePickups, setActivePickups] = useState([]);
  const [canBeChanged, setCanBeChanged] = useState(true);
  const [activeDeliveries, setActiveDeliveries] = useState([]);
  const [ownedCompanies, setOwnedCompanies] = useState([]);
  const [ownedCompany, setOwnedCompany] = useState(null);
  const [editingChildId, setEditingChildId] = useState(null);
  const [rateConfirmationId, setRateConfirmationId] = useState(null);
  const [revisedRateConfirmationId, setRevisedRateConfirmationId] =
    useState(null);
  const [searchVal, setSearchVal] = useState(null);

  // state = {

  //   owned_company: null,
  //   editingChildId: null,
  //   rateConfirmationId: null,
  //   revisedRateConfirmationId: null,
  //   searchVal: null,
  // };

  const togglePickupModal = (editingChildId) => {
    setShowPickupModal(!showPickupModal);
    // setEditingChildId(editingChildId);
  };

  const toggleDeliveryModal = (editingChildId) => {
    setShowDeliveryModal(!showDeliveryModal);
    setEditingChildId(editingChildId);
  };

  const addPickup = (shipper, date, id, hasAttachment) => {
    const array = [...activePickups];
    const existing = array.find((el) => el.id === id);
    const index = array.indexOf(existing);
    console.log(array);
    if (index > -1) {
      array.forEach((item, i) => {
        if (array[i].id === id) {
          array.splice(i, 1);
        }
      });
    }
    const obj = {
      shipper,
      date,
      id,
      hasAttachment,
    };
    array.push(obj);
    setActivePickups(array);
    togglePickupModal();
  };

  const addDelivery = (consignee, date, id) => {
    const array = [...activeDeliveries];
    const existing = array.find((el) => el.id === id);
    const index = array.indexOf(existing);
    if (index > -1) {
      array.forEach((item, i) => {
        if (array[i].id === id) {
          array.splice(i, 1);
        }
      });
    }
    const obj = {
      consignee,
      date,
      id,
    };
    array.push(obj);
    setActiveDeliveries(array);
    toggleDeliveryModal(null);
  };

  const deletePickup = (id) => {
    const array = [...activePickups];
    array.forEach((item, i) => {
      if (array[i].id === id) {
        array.splice(i, 1);
      }
    });

    setActivePickups(array);
  };

  const deleteDelivery = (id) => {
    const array = [...activeDeliveries];
    array.forEach((item, i) => {
      if (array[i].id === id) {
        array.splice(i, 1);
      }
    });
    setActiveDeliveries(array);
  };

  const toggleNewCompany = () => {
    setShowNewCompanyModal(!showNewCompanyModal);
  };

  const newLoad = () => {
    const deliveries = activeDeliveries.map((el) => el.id);
    const pickups = activePickups.map((el) => el.id);
    const data = {
      customLoadNumber:
        document.querySelector('#customId').value + '-' + abbreviation,
      customerId,
      deliveries,
      pickups,
      ownedCompanyId: parseInt(
        document.querySelector('#owned_company').value,
        10
      ),
      rateConfirmationId,
      revisedRateConfirmationId,
      rcPrice: document.querySelector('#rc_price').value,
      revisedRcPrice: document.querySelector('#rrc_price').value,
    };

    loadService
      .create(data)
      .then((res) => {
        if (res) {
          toast.success('Load successfuly added', {
            transition: Flip,
          });
          window.history.back();
        } else {
          const result = res.json();
          try {
            result.then((data) => {
              toast.error(data.error_message, {
                transition: Flip,
              });
            });
          } catch (err) {
            console.log(err);
          }
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const componentDidMount = () => {
    stateProvinceService
      .getAll()
      .then((data) => {
        setStates(data);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });

    loadService
      .getContext()
      .then((data) => {
        setOwnedCompanies(data.owned_companies);
        setAbbreviation(data.owned_companies[0].abbreviation);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const loadOptions = (inputValue, callback) => {
    const req = { search: inputValue };
    companyService
      .getSearch(req)
      .then((data) =>
        callback(
          data.map((el) => {
            return {
              ...el,
              value: el.id,
              label:
                el.companyName +
                ', ' +
                (el.cityDto && el.cityDto.nameWithParentAnsi),
            };
          })
        )
      )
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const uploadFile = (file, item) => {
    const formData = new FormData();
    formData.append('file', file);
    if (file === undefined) {
      return;
    }
    fileUploadService
      .upload('/file/upload', formData)
      .then((data) => {
        if (item === 'rc') setRateConfirmationId(data);
        if (item === 'rrc') setRevisedRateConfirmationId(data);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const setterAbbreviation = (e) => {
    const abbreviationData = ownedCompanies.find(
      (item) => item.id === e.target.value * 1
    );
    setAbbreviation(abbreviationData.abbreviation);
  };

  useEffect(() => {
    componentDidMount();
  }, []);

  return (
    <>
      <ModalWindow
        title="Company modal"
        modal={showNewCompanyModal}
        handleModal={toggleNewCompany}
        maxWidth="md"
        body={
          <NewCompany
            modal={showNewCompanyModal}
            toggleNewCompany={toggleNewCompany}
          />
        }
        // dialogActions={
        //   <DialogActions>
        //     <Button
        //       color="primary"
        //       variant="contained"
        //       autoFocus
        //       onClick={() => {
        //         newCompany();
        //       }}
        //     >
        //       Accept
        //     </Button>
        //   </DialogActions>
        // }
      />
      <Card>
        <CardHeader>
          <h3 className="mb-0">Adding a new Load</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <h4 className="mb-0">Basic Details</h4>
            <FormGroup className="align-items-center mt-2" row>
              <Col md="4">
                <span>Company</span>
                <span style={{ color: 'red' }}> *</span>
              </Col>
              <Col md="8">
                <CustomInput
                  type="select"
                  name="select"
                  id="owned_company"
                  onChange={(e) => setterAbbreviation(e)}
                >
                  {ownedCompanies.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </CustomInput>
              </Col>
            </FormGroup>
            <FormGroup className="align-items-center" row>
              <Col md="4">
                <span>Custom Load Number</span>
                <span style={{ color: 'red' }}> *</span>
              </Col>
              <Col md="8">
                <InputGroup>
                  <Input placeholder="" id="customId" />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>{abbreviation}</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
            </FormGroup>

            <FormGroup className="align-items-center" row>
              <Col md="4">
                <span>Customer</span>
                <span style={{ color: 'red' }}> *</span>
              </Col>
              <Col md="6" style={{ zIndex: 100 }}>
                <AsyncSelect
                  noOptionsMessage={(value) =>
                    !value.inputValue
                      ? 'type something to search'
                      : 'nothing to show'
                  }
                  placeholder="Search"
                  isClearable={true}
                  defaultValue={null}
                  loadOptions={loadOptions}
                  onChange={(value) => {
                    if (value !== null) {
                      setCustomerId(value.value);
                    } else {
                      setCustomerId(null);
                    }
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary50: '#fe810b',
                      primary25: 'rgb(253, 179, 46)',
                      primary: 'rgb(253, 179, 46)',
                    },
                  })}
                />
              </Col>
              <Col md="2 pl-0">
                <Button
                  size="sm"
                  color="success"
                  type="button"
                  onClick={() => toggleNewCompany()}
                >
                  <Icon.Plus size={22} />
                </Button>
              </Col>
            </FormGroup>
            <FormGroup className="align-items-center" row>
              <Col md="4">
                <span>Rate confirmation</span>
              </Col>
              <Col md="2">
                <CustomInput
                  id="fileRate"
                  type="file"
                  onInput={(e) => uploadFile(e.target.files[0], 'rc')}
                />
              </Col>
              <Col md="2" className="text-right">
                <span>Revised RC</span>
              </Col>
              <Col md="2">
                <CustomInput
                  id="fileRevised"
                  type="file"
                  onInput={(e) => uploadFile(e.target.files[0], 'rrc')}
                />
              </Col>
            </FormGroup>
            <FormGroup className="align-items-center" row>
              <Col md="4">
                <span>RC Price</span>
              </Col>
              <Col md="2">
                <FormGroup className="position-relative has-icon-left input-divider-left mb-0">
                  <Input type="number" placeholder="0.00" id="rc_price" />
                  <div className="form-control-position">
                    <Icon.DollarSign />
                  </div>
                </FormGroup>
              </Col>
              <Col md="2" className="text-right">
                <span>Revised RC Price</span>
              </Col>
              <Col md="2">
                <FormGroup className="position-relative has-icon-left input-divider-left mb-0">
                  <Input type="number" placeholder="0.00" id="rrc_price" />
                  <div className="form-control-position">
                    <Icon.DollarSign />
                  </div>
                </FormGroup>
              </Col>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
      <Row>
        <Col sm="12" md="6">
          <Card>
            <CardHeader>
              <CardTitle>Pickup</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="flex-column">
                {activePickups.map((item) => (
                  <ActivePickup
                    key={item.id}
                    data={item}
                    canBeChanged={canBeChanged}
                    deletePickup={(id) => deletePickup(id)}
                    editPickup={togglePickupModal}
                    has_attachment={item.has_attachment}
                  />
                ))}
              </div>
              <Button
                size="sm"
                color="success"
                className="d-flex align-items-center"
                type="button"
                onClick={() => {
                  togglePickupModal();
                }}
              >
                <Icon.Plus size={22} />
                Add new pickup
              </Button>
              <ModalWindow
                title="Pickup modal"
                modal={showPickupModal}
                handleModal={togglePickupModal}
                maxWidth="md"
                body={
                  <PickupModal
                    disabled={true}
                    modal={showPickupModal}
                    togglePickupModal={togglePickupModal}
                    toggleNewCompany={toggleNewCompany}
                    companies={companies}
                    canBeChanged={canBeChanged}
                    addPickup={addPickup}
                    editingChildId={editingChildId}
                    loadOptions={loadOptions}
                  />
                }
              />
            </CardBody>
          </Card>
        </Col>
        <Col sm="12" md="6">
          <Card>
            <CardHeader>
              <CardTitle>Delivery</CardTitle>
            </CardHeader>
            <CardBody>
              {activeDeliveries.map((item) => (
                <ActiveDelivery
                  key={item.id}
                  data={item}
                  canBeChanged={canBeChanged}
                  deleteDelivery={(id) => deleteDelivery(id)}
                  editDelivery={toggleDeliveryModal}
                />
              ))}
              <Button
                size="sm"
                color="success"
                className="d-flex align-items-center"
                type="button"
                onClick={() => toggleDeliveryModal()}
              >
                <Icon.Plus size={22} />
                Add new delivery
              </Button>
              <ModalWindow
                title="Delivery modal"
                modal={showDeliveryModal}
                handleModal={toggleDeliveryModal}
                maxWidth="md"
                body={
                  <DeliveryModal
                    disabled={true}
                    modal={showDeliveryModal}
                    toggleDeliveryModal={toggleDeliveryModal}
                    companies={companies}
                    canBeChanged={canBeChanged}
                    addDelivery={addDelivery}
                    toggleNewCompany={toggleNewCompany}
                    editingChildId={editingChildId}
                    loadOptions={loadOptions}
                  />
                }
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <div className="d-flex justify-content-center">
        <Button
          size="sm"
          color="success"
          className="d-flex align-items-center"
          type="button"
          onClick={() => newLoad()}
        >
          <Icon.Plus size={22} />
          Add new Load
        </Button>
      </div>
    </>
  );
}
