export default {
  TABLE_SET_TOTAL: 'TABLE_SET_TOTAL',
  SET_PROFILE_IMAGE: 'SET_PROFILE_IMAGE',
  SET_MODAL: 'SET_MODAL',
  SET_HEADERS: 'SET_HEADERS',
  SET_NOTIFICATION: 'SET_NOTIFICATION',
  SET_MESSAGES: 'SET_MESSAGES',
  SET_REFRESH_PAGE: 'SET_REFRESH_PAGE',
  SET_REFRESH_PAGE_1: 'SET_REFRESH_PAGE_1',
};
