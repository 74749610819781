import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
} from 'reactstrap';
import * as Icon from 'react-feather';

export default function RenameFileModal({ modal, toggle, name, toggleRename }) {
  useEffect(() => {}, [modal]);

  return (
    <>
      <Modal
        centered={true}
        isOpen={modal}
        toggle={toggle}
        // className={this.props.className}
        backdrop="static"
      >
        <ModalHeader toggle={() => toggle()}>Rename</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Input
                defaultValue={name || ''}
                type="text"
                id="file_name"
                placeholder="name"
                onChange={(e) => {
                  document.querySelector('#file_name').value = e.target.value;
                }}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            size="sm"
            color="success"
            className="d-flex align-items-center"
            type="button"
            onClick={() => {
              toggleRename(document.querySelector('#file_name').value);
              toggle();
            }}
          >
            <Icon.CheckCircle size={22} /> OK
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
