export function dataSelector(dataResponse, page, size) {
  const filtered = dataResponse.data.map((item, i) => ({
    index: i + 1 + page * size,
    id: item.id,
    ansi: item.ansi,
    name: item.name,
    parentAnsi: item.parentAnsi,
    firstTimeZone: item.firstTimeZone,
    secondTimeZone: item.secondTimeZone,
    parentTimeZone: item.parentTimeZone,
  }));
  return { total: dataResponse.total, data: filtered };
}
