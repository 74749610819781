export function dataSelector(dataResponse, page, size) {
  const filtered = dataResponse.data.map((item, i) => ({
    index: i + 1 + page * size,
    id: item.id,
    // state: stateId[0] === undefined ? '' : stateId[0].name,
    name: item.companyName,
    phone: item.phoneNumber,
    city: item.cityDto.nameWithParentAnsi,
    email: item.email,
  }));
  return { total: dataResponse.total_elements, data: filtered };
}
