import React, { useState, useMemo } from 'react';
import { Card, CardBody, Input, Button } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { TextField, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as Icon from 'react-feather';
import { toast, Flip } from 'react-toastify';
import { LoadingOutlined } from '@ant-design/icons';
import IconButton from '@mui/material/IconButton';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import jwtDecode from 'jwt-decode';
import { AnimatePresence } from 'framer-motion';
import FilterListIcon from '@mui/icons-material/FilterList';
import SweetAlert from '../../../../components/main/SweetAlert';
import AnimationDiv from '../../../../components/main/Animations/Transition';
import FilterTools from '../../../../components/main/FilteredBody/Loads';
import loadService from '../../../../services/loads';
import { fetchData } from '../../../../redux/modules/loads/actions';
import { useQuery } from '../../useQuery';
import Loading from '../../../../components/main/Loading';
import Table from '../../../../components/main/table';
import { loadsHeader } from '../../../../redux/constants/table/headers';
import { headerMaker } from '../../../../components/main/table/helper';
import { toolTipUser, toolTipAdmin } from './helper';
import { useStyles } from './style';

export default function Loads() {
  const {
    query,
    search,
    deleted,
    pageIndex,
    pageSize,
    setPageIndex,
    setPageSize,
    setSearch,
    setDeleted,
    setRefresh,
    handleOnTableChange,
  } = useQuery({ fetchData });

  const classes = useStyles();
  const navigate = useNavigate();
  const [isFilterShow, setFilterIsShow] = useState(false);
  const [page, setPage] = useState(null);

  const [deletingId, setDeletingId] = useState(null);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [modal, setModal] = useState(false);

  const headers = useMemo(() => headerMaker(loadsHeader), [loadsHeader]);

  const { data, loading, total } = useSelector((state) => state.loadsReducer);
  localStorage.setItem('total_data', total);

  const token = localStorage.getItem(
    localStorage.getItem('current_account') + '_access_token'
  );
  const decodedToken = jwtDecode(token.replace('Bearer ', ''));

  const createHeaders = (headerData) => {
    const cData = headerData.map((item) => ({
      item,
      // ref: useRef(),
    }));
    // console.log(cData);

    // setColumns(cData);

    return cData;
  };

  const [columns, setColumns] = useState(createHeaders(headers));

  // state = {
  //   data: [],
  //   page: null,
  //   total: 1,
  //   loading: false,
  //   number: null,
  //   paginationPageSize: 100000,
  //   defaultColDef: {
  //     sortable: true,
  //     resizable: true,
  //     // suppressMenu: true,
  //     wrapText: true,
  //     // autoHeight: true,
  //     floatingFilter: true,
  //     tooltipValueGetter: (params) => {
  //       return params.value;
  //     },
  //   },
  //   columnDefs: [
  //     {
  //       headerName: '#',
  //       field: 'index',
  //       minWidth: 80,
  //       flex: 1,
  //       filter: false,
  //       cellStyle: function (params) {
  //         return {
  //           fontWeight: '500',
  //         };
  //       },
  //     },
  //     {
  //       headerName: 'Load #',
  //       field: 'load',
  //       minWidth: 170,
  //       filter: 'agTextColumnFilter',
  //       flex: 1,
  //     },
  //     {
  //       headerName: 'Trip #',
  //       field: 'trip',
  //       filter: 'agTextColumnFilter',
  //       minWidth: 150,
  //       flex: 1,
  //       cellRendererFramework: function (params) {
  //         return (
  //           <Link className="link-in-table" to={`/trips/view/${params.value}`}>
  //             {params.value}
  //           </Link>
  //         );
  //       },
  //     },
  //     {
  //       headerName: 'Status',
  //       field: 'status',
  //       filter: false,
  //       minWidth: 140,
  //       flex: 1,
  //       cellStyle: function (params) {
  //         if (params.value === 'NOT DISPATCHED') {
  //           //mark police cells as red
  //           return {
  //             color: 'white',
  //             backgroundColor: 'rgb(24, 128, 56)',
  //           };
  //         } else {
  //           return { color: 'white', backgroundColor: '#ea5455' };
  //         }
  //       },
  //     },
  //     {
  //       headerName: 'Pickup',
  //       field: 'pickup',
  //       filter: 'agTextColumnFilter',
  //       maxWidth: 140,
  //       minWidth: 140,
  //       flex: 1,
  //     },
  //     {
  //       headerName: 'Delivery',
  //       field: 'delivery',
  //       filter: 'agTextColumnFilter',
  //       maxWidth: 140,
  //       minWidth: 140,
  //       flex: 1,
  //     },
  //     // {
  //     //   headerName: "Driver",
  //     //   field: "driver",
  //     //   filter: true,
  //     //   minWidth: 150,
  //     //   flex: 1,
  //     //   cellRendererFramework: (params) => {
  //     //     return (
  //     //       <Link
  //     //         className="link-in-table"
  //     //         to={`/driver/view/${params.data.driverId}`}
  //     //       >
  //     //         {params.value}
  //     //       </Link>
  //     //     );
  //     //   },
  //     // },
  //     // {
  //     //   headerName: "Truck",
  //     //   field: "truck",
  //     //   filter: true,
  //     //   minWidth: 130,
  //     //   flex: 1,
  //     //   cellRendererFramework: (params) => {
  //     //     return (
  //     //       <Link
  //     //         className="link-in-table"
  //     //         to={`/unit/edit/${params.value}`}
  //     //       >
  //     //         {params.value}
  //     //       </Link>
  //     //     );
  //     //   },
  //     // },
  //     {
  //       headerName: 'Customer',
  //       field: 'customer',
  //       filter: 'agTextColumnFilter',
  //       minWidth: 130,
  //       // flex: 1,
  //       tooltip: (params) => {
  //         return params.value;
  //       },
  //     },
  //     {
  //       headerName: 'From',
  //       field: 'from',
  //       filter: 'agTextColumnFilter',
  //       minWidth: 200,
  //       // flex: 1,
  //       tooltip: (params) => {
  //         return params.value;
  //       },
  //     },
  //     {
  //       headerName: 'To',
  //       field: 'to',
  //       filter: 'agTextColumnFilter',
  //       minWidth: 200,
  //       // flex: 1,
  //       tooltip: (params) => {
  //         return params.value;
  //       },
  //     },
  //     {
  //       minWidth: 150,
  //       headerName: '',
  //       field: 'actions',
  //       sortable: false,
  //       editable: false,
  //       suppressMenu: false,
  //       flex: 1,
  //       cellRendererFramework: (params) => {
  //         return (
  //           <div>
  //             <Link to={`/loads/edit/${params.data.id}`}>
  //               <Icon.Edit className="icon-button" size={20} color="darkgray" />
  //             </Link>
  //             {this.props.userRole === 'admin' && (
  //               <Icon.Delete
  //                 onClick={() => this.nominateToDelete(params.data.id)}
  //                 className="icon-button ml-1"
  //                 size={20}
  //                 color="darkgray"
  //               />
  //             )}
  //             {params.data.editStatus ? (
  //               <IconButton
  //                 color="primary"
  //                 aria-label="upload picture"
  //                 component="label"
  //               >
  //                 <LockOpenIcon />
  //               </IconButton>
  //             ) : (
  //               <IconButton
  //                 color="primary"
  //                 aria-label="upload picture"
  //                 component="label"
  //               >
  //                 <LockIcon />
  //               </IconButton>
  //             )}
  //           </div>
  //         );
  //       },
  //     },
  //     // {
  //     //   minWidth: 230,
  //     //   flex: 1,
  //     //   headerName: "Actions",
  //     //   field: "actions",
  //     //   sortable: false,
  //     //   editable: false,
  //     //   suppressMenu: false,
  //     //   cellRendererFramework: function (params) {
  //     //     return (
  //     //       <div>
  //     //         <Button.Ripple color="warning" className="mr-1" size="sm">
  //     //           <Icon.Eye size={16} />
  //     //         </Button.Ripple>
  //     //         <Button.Ripple color="success" className="mr-1" size="sm">
  //     //           <Icon.Edit size={16} />
  //     //         </Button.Ripple>
  //     //         <Button.Ripple color="danger" size="sm">
  //     //           <Icon.Delete size={16} />
  //     //         </Button.Ripple>
  //     //       </div>
  //     //     );
  //     //   },
  //     // },
  //   ],
  //   deleteAlert: false,
  //   deletingId: null,
  // };

  const nominateToDelete = (id) => {
    setDeletingId(id);
    setDeleteAlert(true);
  };

  const deleteLoad = () => {
    loadService
      .delete(deletingId)
      .then((res) => {
        setRefresh(true);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
    setDeletingId(null);
    setDeleteAlert(false);
  };

  // const componentDidUpdate = (prevProps, prevState) => {
  //   if (
  //     this.state.page !== prevState.page ||
  //     this.state.number !== prevState.number
  //   ) {
  //     let number = this.state.number;
  //     if (this.state.number !== prevState.number && this.state.page !== 0) {
  //       this.setState({ page: 0 });
  //       return;
  //     }
  //     this.setState({
  //       loading: true,
  //     });
  //     loadService
  //       .getAll(
  //         `sort=id,DESC&size=100&tripId=-1&page=${this.state.page}${
  //           number ? `&number=${number}` : ''
  //         }`
  //       )
  //       .then((data) => {
  //         if (number !== this.state.number) return;
  //         let dataToShow = [];
  //         data.content.forEach((el, i) => {
  //           let elToShow = {
  //             index: i + 1 + this.state.page * 100,
  //             id: el.id,
  //             load: el.customLoadNumber,
  //             trip: el.tripId,
  //             status: el.tripId === null ? 'NOT DISPATCHED' : 'DISPATCHED',
  //             pickup: el.pickupDateFormatted,
  //             delivery: el.deliveryDateFormatted,
  //             customer: el.customer,
  //             from: el.from,
  //             to: el.to,
  //             driverId: el.driverId,
  //             driver: el.driverName,
  //             truck: el.truckNumber,
  //             editStatus: el.editStatus,
  //           };
  //           dataToShow.push(elToShow);
  //         });
  //         this.setState({
  //           data: dataToShow,
  //           loading: false,
  //           total: data.total_elements,
  //         });
  //       })
  //       .catch((err) => {
  //         toast.error(err.response.data.message, {
  //           transition: Flip,
  //         });
  //       });
  //   }
  // };

  const componentDidMount = () => {
    // setLoading(true);

    const dispatcher = null;
    // if (this.props.userRole === "dispatcher") {
    //     const token = localStorage.getItem("access_token");
    //     if (token) {
    //         const decodedToken = jwtDecode(token.replace("Bearer ", ""));
    //         dispatcher = decodedToken.userId;
    //     }
    // }
    loadService
      .getAll(
        `sort=id,DESC&size=100&tripId=-1${
          dispatcher ? `&dispatcherId=${dispatcher}` : ''
        }`
      )
      .then((data) => {
        const dataToShow = [];
        data.content.forEach((el, i) => {
          const elToShow = {
            index: i + 1 + page * 100,
            id: el.id,
            load: el.customLoadNumber,
            trip: el.tripId,
            status: el.tripId === null ? 'NOT DISPATCHED' : 'DISPATCHED',
            pickup: el.pickupDateFormatted,
            delivery: el.deliveryDateFormatted,
            customer: el.customer,
            from: el.from,
            to: el.to,
            driverId: el.driverId,
            driver: el.driverName,
            truck: el.truckNumber,
            editStatus: el.editStatus,
          };
          dataToShow.push(elToShow);
        });
        // setData(dataToShow);
        // setLoading(false);
        // setTotal(data.total_elements);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const handleModal = () => {
    setModal(!modal);
  };

  const doubleClick = (id) => {
    navigate(`/loads/edit/${id}`);
  };

  return (
    <>
      <Helmet>
        <title> Loads </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: '#000',
          height: 'calc(100vh - 6.5rem)',
          py: 1,
        }}
      >
        <Loading loading={loading} />
        <SweetAlert
          title="Are you sure?"
          warning
          show={deleteAlert}
          showCancel
          reverseButtons
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          onConfirm={() => {
            deleteLoad(deletingId);
          }}
          onCancel={() => setDeleteAlert(false)}
        >
          You won`t be able to revert this!
        </SweetAlert>
        <Card
          className="overflow-hidden agGrid-card"
          style={{ marginRight: 8 }}
        >
          <div className="d-flex justify-content-between align-items-center mt-2 mx-2 mb-1">
            <h3>All loads list</h3>
            <div>
              <Link to="/loads/new">
                <Button
                  color="success"
                  className="d-flex align-items-center"
                  type="button"
                  size="sm"
                >
                  <Icon.Plus size={20} /> Add new Load
                </Button>
              </Link>
            </div>
          </div>
          <div className="d-flex align-items-center mx-2">
            <TextField
              fullWidth
              size="small"
              id="search"
              label="Search"
              variant="outlined"
              onChange={(e) => setSearch(e.target.value)}
            />
            <IconButton
              color="primary"
              aria-label="filter"
              onClick={() => {
                setFilterIsShow(!isFilterShow);
              }}
            >
              <FilterListIcon />
            </IconButton>
          </div>
          <div className={isFilterShow ? 'mx-2' : ''}>
            <AnimatePresence className={classes.filters}>
              {isFilterShow ? (
                <AnimationDiv>
                  <FilterTools deleted={deleted} setDeleted={setDeleted} />
                </AnimationDiv>
              ) : null}
            </AnimatePresence>
          </div>
          <CardBody>
            <div>
              <Table
                data={data}
                headers={headers}
                toolTips={
                  decodedToken.role === 'ROLE_ADMIN' ||
                  decodedToken.role === 'SUPER_ADMIN'
                    ? toolTipAdmin
                    : toolTipUser
                }
                onChange={handleOnTableChange}
                modal={handleModal}
                deleteModal={nominateToDelete}
                doubleClick={doubleClick}
                columns={columns}
                setColumns={setColumns}
                // hidePagination={1}
              />
            </div>
          </CardBody>
        </Card>
      </Box>
    </>
  );
}
