import React from 'react';
import Icon from '../../../assets/img/logo/lbs.png';

export default ({ ...rest }) => (
  <img
    style={{ borderRadius: '15%' }}
    alt="Logo"
    src={Icon}
    {...rest}
    width={50}
  />
);
