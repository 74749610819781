export function dataSelector(dataResponse, page, size) {
  const filtered = dataResponse.content.map((item, i) => ({
    index: i + 1 + page * size,
    id: item.id,
    load: item.customLoadNumber,
    trip: item.tripId,
    status: item.tripId === null ? 'NOT DISPATCHED' : 'DISPATCHED',
    pickup: item.pickupDateFormatted,
    delivery: item.deliveryDateFormatted,
    customer: item.customer,
    from: item.from,
    to: item.to,
    driverId: item.driverId,
    driver: item.driverName,
    truck: item.truckNumber,
    editStatus: item.editStatus,
    deleted: item.deleted,
    data: item,
    unitData: item,
  }));
  return { total: dataResponse.total_elements, data: filtered };
}
