import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { toast, Flip } from 'react-toastify';
import unitService from '../../../../services/units';
import { setRefreshPage } from '../../../../redux/modules/store/actions';
import ShowModal from './Modals/ShowModal';

export default function UnitStatus({ value }) {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const [statuses, setStatuses] = useState([
    { id: 1, name: 'COVERED' },
    { id: 2, name: 'UPCOMING' },
    { id: 3, name: 'READY' },
    { id: 5, name: 'REPAIR' },
    { id: 4, name: 'NO DRIVER' },
    { id: 6, name: 'OFF' },
    { id: 7, name: 'WAITING FOR ELD' },
    { id: 8, name: 'AT HOME' },
    { id: 9, name: 'INSPECTION' },
    { id: 10, name: 'INSURANCE' },
    { id: 11, name: 'REGISTRATION' },
  ]);

  const getUnitStatuses = () => {
    unitService
      .getUnitStatuses('page=0&size=1000&deleted=false')
      .then((data) => {
        setStatuses(data.content);
      })
      .catch((err) => {
        // toast.error(err.response.data.message, {
        //   transition: Flip,
        // });
      });
  };

  const saveStatus = (event) => {
    unitService
      .updateStatus(value.id, event.target.value, {})
      .then(() => {
        console.log(event.target.value);
        dispatch(setRefreshPage(true));
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const handleChange = (event) => {
    console.log(event.target.value);
    if (event.target.value === 8) {
      toggleModal();
    } else {
      saveStatus(event);
    }
  };

  useEffect(() => {
    getUnitStatuses();
  }, []);

  return (
    <>
      <ShowModal modal={showModal} toggleModal={toggleModal} item={value} />

      <FormControl fullWidth sx={{ paddingLeft: 2, paddingRight: 2 }}>
        <Select
          sx={{ color: 'white', backgroundColor: value.unitStatusColor }}
          size="small"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value.unitStatusId}
          // value={statuses.find((item) => item.id === value.unitStatusId).id}
          label=""
          onChange={handleChange}
        >
          {statuses.map((item, i) => {
            return (
              <MenuItem key={`${i + 1}`} value={item.id}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
}
