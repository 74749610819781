import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import Search from './Search';
import Chats from './Chats';
import driverApi from '../../../../services/drivers';
import userApi from '../../../../services/users';
import Loading from '../../../../components/main/Loading';

export default function Sidebar({ setUser, getMessages }) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [currentStatus, setCurrentStatus] = useState('only_chats');
  const [hasMore, setHasMore] = useState(true);
  const [searchText, setSearchText] = useState('');

  const userId = localStorage.getItem('userId');

  const getUsers = (status, search, page) => {
    // setLoading(true);
    setCurrentStatus(status);
    if (status === 'only_chats') {
      userApi
        .getAllConversations(`userId=${userId}&page=${page}&page_size=${10}`)
        .then((res) => {
          // console.log(res);
          setLoading(false);
          const arr = [];
          if (page <= res.totalPages) {
            setHasMore(false);
          }
          setTotalPage(res.totalPages);
          res.forEach((item) => {
            const value = {
              id: item.senderId === userId ? item.senderId : item.receiverId,
              name:
                item.senderId === userId ? item.senderName : item.receiverName,
              text:
                item.messageType === 'text' ? item.messageText[0].data : 'File',
              isOnline: item.isOnline,
            };
            arr.push(value);
          });
          if (page === 0) {
            setUsers(arr);
          } else {
            setUsers([...users, ...arr]);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      userApi
        .getAllForChat(
          `status=${status}&search=${search}&page=${page}&page_size=${10}`
        )
        .then((res) => {
          console.log(res);
          setLoading(false);
          const arr = [];
          console.log(page, res.totalPages);
          if (res.totalPages <= page) {
            setHasMore(false);
          }
          setTotalPage(res.totalPages);
          res.content.forEach((item) => {
            const value = {
              id: item.id,
              name:
                item.name === null || item.name === undefined
                  ? item.fullName
                  : item.name,
              text: '',
              isOnline: item.isOnline,
            };
            arr.push(value);
          });
          if (page === 0) {
            setUsers(arr);
          } else {
            setUsers([...users, ...arr]);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const handlerUsers = (status) => {
    setUser({ id: 0 });
    setHasMore(true);
    setUsers([]);
    setTotalPage(0);
    setCurrentPage(0);
    setSearchText('');
    getUsers(status, '', 0);
  };

  useEffect(() => {
    getUsers(currentStatus, '', 0);
  }, []);

  const getData = () => {
    setCurrentPage(currentPage + 1);
    getUsers(currentStatus, searchText, currentPage + 1);
  };

  const getSearchData = (text) => {
    setSearchText(text);
    getUsers(currentStatus, text, currentPage + 1);
  };

  return (
    <div>
      <Loading loading={loading} />
      <Typography variant="h3" align="left">
        Chats
      </Typography>
      <Search getSearchData={getSearchData} handlerUsers={handlerUsers} />
      <Chats
        getData={getData}
        hasMore={hasMore}
        chats={users}
        getMessages={getMessages}
      />
    </div>
  );
}
