import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useDispatch } from 'react-redux';
import ModalWindow from '../Modals';
import Login from './Login';
import { setModal } from '../../../redux/modules/store/actions';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

export default () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [currentAccount, setCurrentAccount] = useState(
    localStorage.getItem('current_account')
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [showLogin, setShowLogin] = useState(false);

  const accounts = JSON.parse(
    localStorage.getItem('accounts') ? localStorage.getItem('accounts') : '[]'
  );

  const toggleLogin = () => {
    setShowLogin(!showLogin);
    setAnchorEl(null);
  };

  return (
    <>
      <ModalWindow
        title="Login"
        modal={showLogin}
        handleModal={toggleLogin}
        maxWidth={500}
        minWidth={500}
        maxHeight={380}
        minHeight={380}
        body={<Login toggle={toggleLogin} />}
      />
      <FormControl anchorel={anchorEl} sx={{ width: 200 }} size="small">
        <InputLabel id="demo-simple-select-label">Select account</InputLabel>
        <Select
          anchorel={anchorEl}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={currentAccount || ''}
          label="Select account"
          MenuProps={MenuProps}
          onChange={(e) => {
            const { value } = e.target;
            setCurrentAccount(value);
            localStorage.setItem('current_account', value.toUpperCase());
            window.open(location.pathname, '_self');
          }}
        >
          {accounts.map((item, i) => {
            return (
              <MenuItem key={`${i + 1}`} value={item}>
                {item}
              </MenuItem>
            );
          })}
          <MenuItem
            key="new"
            onClick={() => {
              toggleLogin();
              // dispatch(setModal(true));
            }}
          >
            New account
          </MenuItem>
        </Select>
      </FormControl>
    </>
  );
};
