import React from 'react';
import StatusCell from './CellStatus';
import DashboardUnitStatusCell from './CellDashboardUnitStatus';
import DashboardNoteCell from './CellDashboardNote';
import DashboardDriverCell from './CellDashboardDriver';
import UnitStatusCell from './CellUnitStatus';
import LoadStatusCell from './CellLoadStatus';
import LoadLinkCell from './CellLoadLink';
import UnitLinkCell from './CellUnitLink';
import TripLinkCell from './CellTripLink';
import DriversCell from './CellDrivers';
import DriverCell from './CellDriver';
import DriverStatusCell from './CellDriverStatus';
import DriverLoadsCell from './CellDriverLoads';
import DriverPhoneCell from './CellDriversPhone';
import MomentCell from './CellMoment';
import InfoCell from './CellInfo';
import LeaveHomeDateCell from './CellLeaveHomeDate';
import DriverApproveCell from './CellDriverApprove';
import SinceTimeCell from './CellSinceTime';
import AtHomeDateCell from './CellAtHomeDate';
import EditStatusCell from './CellEditStatus';
import NotificationStatusCell from './CellNotificationStatus';
import FuelTankCell from './CellFuelTank';
import FuelPercentCell from './CellFuelPercent';
import FuelUnitStatusCell from './CellFuelUnitStatus';
import TaskPhotoCell from './CellTaskPhoto';
import TaskShowCell from './CellTaskShow';

export const headerMaker = (data) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  data
    .filter(({ show }) => show)
    .map(({ type, ...rest }) => {
      if (type === 'task_photo') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => <TaskPhotoCell value={value} />,
        };
      }
      if (type === 'task_show') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => <TaskShowCell value={value} />,
        };
      }
      if (type === 'notification_status') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => (
            <NotificationStatusCell value={value} />
          ),
        };
      }
      if (type === 'fuel_unit_status') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => <FuelUnitStatusCell value={value} />,
        };
      }
      if (type === 'info') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => <InfoCell value={value} />,
        };
      }
      if (type === 'sinceTime') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => <SinceTimeCell value={value} />,
        };
      }
      if (type === 'leaveHomeDate') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => <LeaveHomeDateCell value={value} />,
        };
      }
      if (type === 'atHomeDate') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => <AtHomeDateCell value={value} />,
        };
      }
      if (type === 'status' || type === 'state') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => <StatusCell value={value} />,
        };
      }

      if (type === 'show_status') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => <StatusCell value={value} />,
        };
      }

      if (type === 'load_status') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => <LoadStatusCell value={value} />,
        };
      }

      if (type === 'dashboard_unit_status') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => (
            <DashboardUnitStatusCell value={value} />
          ),
        };
      }

      if (type === 'driver_approve') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => <DriverApproveCell value={value} />,
        };
      }

      if (type === 'edit_status') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => <EditStatusCell value={value} />,
        };
      }

      if (type === 'driver_phone') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => <DriverPhoneCell value={value} />,
        };
      }

      if (type === 'load_link') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => <LoadLinkCell value={value} />,
        };
      }

      if (type === 'unit_link') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => <UnitLinkCell value={value} />,
        };
      }

      if (type === 'unit_status') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => <UnitStatusCell value={value} />,
        };
      }

      if (type === 'driver_status') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => <DriverStatusCell value={value} />,
        };
      }
      if (type === 'driver_loads') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => <DriverLoadsCell value={value} />,
        };
      }

      if (type === 'drivers_link') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => <DriversCell value={value} />,
        };
      }

      if (type === 'dashboard_driver_link') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => <DashboardDriverCell value={value} />,
        };
      }

      if (type === 'driver_link') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => <DriverCell value={value} />,
        };
      }

      if (type === 'trip_link') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => <TripLinkCell value={value} />,
        };
      }

      if (type === 'moment') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => <MomentCell value={value} />,
        };
      }

      if (type === 'dashboard_note') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => <DashboardNoteCell value={value} />,
        };
      }

      if (type === 'fuel_tank') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => <FuelTankCell value={value} />,
        };
      }

      if (type === 'fuel_percent') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => <FuelPercentCell value={value} />,
        };
      }

      return rest;
    });
