import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Col,
} from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import { toast, Flip } from 'react-toastify';

export default function ShowModal({ modal, toggleModal, handleChange, item }) {
  const [date, setDate] = useState();

  useEffect(() => {
    setDate();
  }, [modal]);

  return (
    <Modal
      centered={true}
      isOpen={modal}
      toggle={toggleModal}
      className="sticky"
      backdrop="static"
    >
      <ModalHeader toggle={() => toggleModal()}>At home</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup className="align-items-center" row>
            <Col md="4">
              <span>At home date:</span>
            </Col>
            <Col md="8">
              <div>
                <Flatpickr
                  className="form-control"
                  data-enable-time
                  options={{
                    dateFormat: 'Z',
                    altInput: true,
                    altFormat: 'm-d-Y H:i',
                  }}
                  value={date}
                  onChange={(val) => {
                    setDate(new Date(val[0]).getTime());
                  }}
                />
              </div>
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            console.log(date);
            if (date !== null && date !== undefined) {
              handleChange(date);
            } else {
              toast.error('Please, select date', {
                transition: Flip,
              });
            }
          }}
        >
          Save
        </Button>{' '}
      </ModalFooter>
    </Modal>
  );
}
