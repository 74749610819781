import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  CustomInput,
  Col,
} from 'reactstrap';
import { Fab, IconButton, Stack } from '@mui/material';
import axios from 'axios';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { toast, Flip } from 'react-toastify';
import AsyncSelect from 'react-select/async';
import Flatpickr from 'react-flatpickr';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ImageIcon from '@mui/icons-material/Image';
import ImageViewer from 'react-simple-image-viewer';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import locationService from '../../../../services/locations';
import companyService from '../../../../services/companies';
import fileUploadService from '../../../../services/file_upload';
import DragAndDrop from './DragAndDrop';
import trailerRepairService from '../../../../services/trailer_repairs';
import paymentMethodsService from '../../../../services/trailer_payment_methods';
import damageCategoryService from '../../../../services/trailer_damage_category';
import PaymentMethodsModal from '../Modals/PaymentMethodsModal';
import DamageCategoryModal from '../Modals/DamageCategoryModal';
import ModalWindow from '../../../../components/main/Modals';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function RepairFormNew({
  modal,
  toggle,
  trailerId,
  userId,
  getRepairs,
}) {
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [amount, setAmount] = useState();
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [date, setDate] = useState();

  const [paymentMethodId, setPaymentMethodId] = useState();
  const [showPaymentMethodsModal, setShowPaymentMethodsModal] = useState(false);
  const [showDamageCategoryModal, setShowDamageCategoryModal] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [damageCategory, setDamageCategory] = useState([]);
  const [damageCategoryIds, setDamageCategoryIds] = useState([]);
  const [filesUpload, setFilesUpload] = useState([]);

  const [openDrop, setOpenDrop] = useState(false);

  const theme = useTheme();
  const [selectDamageCategory, setSelectDamageCategory] = useState([]);

  const openImageViewer = (index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    if (file === undefined) {
      return;
    }
    await axios.post(
      process.env.REACT_APP_BASE_URL + '/file/upload/trailer',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization:
            'Bearer ' +
            localStorage.getItem(
              localStorage.getItem('current_account') + '_access_token'
            ),
        },
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          // this.setState({
          //     progress,
          // });
          // console.log(progressEvent);
          //   setProgress(progress);
        },
        onDownloadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          console.log(progressEvent);

          const fileList = files;
          const data = JSON.parse(progressEvent.target.response);
          fileList.push({
            url: data.originalFileName,
            originalFileName: data.originalFileName,
            size: data.size,
            uploadedAt: data.uploadedAt,
          });
          setFiles(fileList);
          filesUpload.push(data);
          setFilesUpload(filesUpload);
          setOpenDrop(false);
          // this.setState({
          //     progress,
          // });
          //   setProgress(progress);
        },
      }
    );
  };

  const newRepair = () => {
    const data = {
      trailerId,
      loadNumber: document.querySelector('#load').value,
      unitNumber: document.querySelector('#truck').value,
      drivers: document.querySelector('#drivers').value,
      date,
      userId,
      paymentMethodId,
      amount: parseFloat(document.querySelector('#amount').value),
      categoryIds: damageCategoryIds,
      shopServiceName: document.querySelector('#shopServiceName').value,
      shopServiceAddress: document.querySelector('#shopServiceAddress').value,
      shopServicePhone: document.querySelector('#shopServicePhone').value,
      shopServiceEmail: document.querySelector('#shopServiceEmail').value,
      filesUpload,
      notes: document.querySelector('#repairNotesNew').value,
    };
    trailerRepairService.create(data).then((res) => {
      if (res) {
        toast.success('Company successfuly added', {
          transition: Flip,
        });
        getRepairs(trailerId);
        toggle();
      } else {
        toast.error('Something went wrong', { transition: Flip });
        // res.text();
      }
    });
  };

  // const loadNumberOptions = (inputValue, callback) => {
  //     loadService.getSearch(inputValue).then((data) => {
  //         console.log(data);
  //         callback(
  //             data.map((el) => {
  //                 return {
  //                     value: el.loadId,
  //                     label: el.loadNumber,
  //                     truckNumber: el.unitNumber,
  //                     unitId: el.unitId,
  //                     driverId: el.driverId,
  //                     driverName: el.driverName,
  //                     secondDriverId: el.secondDriverId,
  //                     secondDriverName: el.secondDriverName,
  //                 };
  //             })
  //         );
  //     });
  // };

  const getPaymentMathods = () => {
    paymentMethodsService.getAll('deleted=false').then((data) => {
      setPaymentMethods(data);
      if (data.length > 0) {
        setPaymentMethodId(data[0].id);
      }
    });
  };

  const getDamageCategory = () => {
    damageCategoryService.getAll('deleted=false').then((data) => {
      setDamageCategory(data);
    });
  };

  const checkImageFile = (file) => {
    const index = file.indexOf('.jpg');
    if (index > -1) {
      return true;
    }
    return false;
  };

  const togglePaymentMethod = () => {
    setShowPaymentMethodsModal(!showPaymentMethodsModal);
  };

  const toggleDamageCategory = () => {
    setShowDamageCategoryModal(!showDamageCategoryModal);
  };

  const handleChange = (event) => {
    setDamageCategoryIds([]);
    const {
      target: { value },
    } = event;

    const arr = [];
    value.forEach((element, index) => {
      damageCategory.forEach((element) => {
        if (element.name === value[index]) {
          arr.push(element.id);
        }
      });
    });
    setDamageCategoryIds(arr);
    setSelectDamageCategory(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleCloseDrop = () => {
    setOpenDrop(false);
  };

  const handleToggle = () => {
    setOpenDrop(!openDrop);
  };

  const handleDrop = (filesData) => {
    Array.from(filesData).forEach((item, i) => {
      handleToggle();
      if (!filesData[i].name) return;
      uploadFile(filesData[i]);
    });
  };

  useEffect(() => {
    getPaymentMathods();
    getDamageCategory();
    setFiles([]);
  }, [modal]);

  return (
    <>
      <ModalWindow
        title="Payment method"
        modal={showPaymentMethodsModal}
        handleModal={togglePaymentMethod}
        maxWidth="sm"
        body={
          <PaymentMethodsModal
            modal={showPaymentMethodsModal}
            toggle={togglePaymentMethod}
            getList={getPaymentMathods}
          />
        }
      />
      <ModalWindow
        title="Damage category"
        modal={showDamageCategoryModal}
        handleModal={toggleDamageCategory}
        maxWidth="sm"
        body={
          <DamageCategoryModal
            modal={showDamageCategoryModal}
            toggle={toggleDamageCategory}
            getList={getDamageCategory}
          />
        }
      />
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openDrop}
        onClick={handleCloseDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div style={{ width: '100%', marginRight: 20 }}>
        <Form>
          <FormGroup className="align-items-center" row>
            <Col md="2">
              <span>Load*</span>
            </Col>
            <Col md="10">
              <Input type="text" id="load" />
              {/* <AsyncSelect
                                    noOptionsMessage={(value) =>
                                        !value.inputValue
                                            ? "type something to search"
                                            : "nothing to show"
                                    }
                                    placeholder="Search"
                                    isClearable={true}
                                    defaultValue={null}
                                    loadOptions={loadNumberOptions}
                                    onChange={(value) => {
                                        console.log(value);
                                        if (value !== null) {
                                            document.querySelector(
                                                "#truck"
                                            ).value = value.truckNumber;
                                            document.querySelector(
                                                "#drivers"
                                            ).value =
                                                value.driverName +
                                                "\n" +
                                                value.secondDriverName;
                                            setLoadId(value.value);
                                            setUnitId(value.unitId);
                                        } else {
                                            document.querySelector(
                                                "#truck"
                                            ).value = "";
                                            document.querySelector(
                                                "#drivers"
                                            ).value = "";
                                            setLoadId(null);
                                        }
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary50: "#fe810b",
                                            primary25: "rgb(253, 179, 46)",
                                            primary: "rgb(253, 179, 46)",
                                        },
                                    })}
                                /> */}
            </Col>
          </FormGroup>
          <FormGroup className="align-items-center" row>
            <Col md="2">
              <span>Truck</span>
            </Col>
            <Col md="10">
              <Input type="text" id="truck" />
            </Col>
          </FormGroup>
          <FormGroup className="align-items-center" row>
            <Col md="2">
              <span>Driver(s) name</span>
            </Col>
            <Col md="10">
              <Input type="textarea" id="drivers" />
            </Col>
          </FormGroup>
          <FormGroup className="align-items-center" row>
            <Col md="2">
              <span>Date</span>
            </Col>
            <Col md="10">
              <Flatpickr
                id="from"
                className="form-control"
                data-enable-time
                options={{
                  dateFormat: 'Z',
                  altInput: true,
                  altFormat: 'm-d-Y H:i',
                }}
                value={date}
                onChange={(val, date, fbo) => {
                  setDate(new Date(val[0]).getTime());
                  // fbo.close();
                }}
              />
            </Col>
          </FormGroup>
        </Form>
      </div>
      <div style={{ width: '100%' }}>
        <FormGroup className="align-items-center" row>
          <Col md="2">
            <span>Username</span>
          </Col>
          <Col md="10">
            <Input
              value={localStorage.getItem('username')}
              disabled={true}
              type="text"
              id="username"
            />
          </Col>
        </FormGroup>

        <FormGroup className="align-items-center" row>
          <Col md="2">
            <span>Payment method</span>
          </Col>
          <Col md="10">
            <div style={{ display: 'flex' }}>
              <CustomInput
                type="select"
                name="select"
                id="payment_method"
                onChange={(e) => {
                  console.log(e.target.value);
                  setPaymentMethodId(e.target.value);
                }}
              >
                {paymentMethods.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </CustomInput>
              <IconButton
                color="primary"
                onClick={() => {
                  togglePaymentMethod();
                }}
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </div>
          </Col>
        </FormGroup>
        <FormGroup className="align-items-center" row>
          <Col md="2">
            <span>Amount</span>
          </Col>
          <Col md="10">
            <div style={{ display: 'flex' }}>
              <Input
                // value={
                //     this.state.amount ? this.state.amount : ""
                // }
                type="text"
                id="amount"
                onChange={(e) => {
                  const re = /^[0-9\b\\.]+$/;
                  if (e.target.value === '' || re.test(e.target.value)) {
                    setAmount(e.target.value);
                  }
                }}
              />
              <IconButton color="primary">
                <AttachMoneyIcon />
              </IconButton>
            </div>
          </Col>
        </FormGroup>
        <FormGroup className="align-items-center" row>
          <Col md="2">
            <span>Damage category</span>
          </Col>
          <Col md="10">
            <div style={{ display: 'flex' }}>
              <FormControl
                sx={{
                  // m: 1,
                  width: 300,
                  height: 10,
                  // border: 1,
                  // borderRadius: "5px",
                  // borderStyle: "solid",
                  // borderColor: "grey",
                }}
              >
                <Select
                  sx={{
                    border: 1,
                    borderRadius: '5px',
                    borderStyle: 'solid',
                    borderColor: 'grey',
                  }}
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={selectDamageCategory}
                  onChange={handleChange}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Chip" />
                  }
                  renderValue={(selected) => (
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 0.5,
                      }}
                    >
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {damageCategory.map((item, i) => (
                    <MenuItem
                      key={item.id}
                      value={item.name}
                      style={getStyles(item.name, selectDamageCategory, theme)}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* <CustomInput
                                    type="select"
                                    name="select"
                                    id="customerType"
                                    onSelectCapture={(e) => {
                                        console.log(e);
                                    }}
                                >
                                    {damageCategory.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </CustomInput> */}
              <IconButton
                color="primary"
                onClick={() => {
                  toggleDamageCategory();
                }}
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </div>
          </Col>
        </FormGroup>
      </div>

      <div style={{ width: '100%', marginRight: 20 }}>
        <h4>Shop/Service</h4>
        <Form>
          <FormGroup className="align-items-center" row>
            <Col md="2">
              <span>Name</span>
            </Col>
            <Col md="10">
              <Input type="text" id="shopServiceName" />
            </Col>
          </FormGroup>
          <FormGroup className="align-items-center" row>
            <Col md="2">
              <span>Address</span>
            </Col>
            <Col md="10">
              <Input type="text" id="shopServiceAddress" />
            </Col>
          </FormGroup>
          <FormGroup className="align-items-center" row>
            <Col md="2">
              <span>Phone</span>
            </Col>
            <Col md="10">
              <Input type="text" id="shopServicePhone" />
            </Col>
          </FormGroup>
          <FormGroup className="align-items-center" row>
            <Col md="2">
              <span>Email</span>
            </Col>
            <Col md="10">
              <Input type="text" id="shopServiceEmail" />
            </Col>
          </FormGroup>
        </Form>
      </div>
      <div style={{ width: '100%' }}>
        <h4>Attachments</h4>
        <FormGroup className="align-items-center" row>
          <Col md="12">
            <DragAndDrop handlerDrop={handleDrop}>
              <div
                style={{
                  height: 200,
                  display: 'flex',
                  gap: '6rem',
                  minWidth: '400px',
                }}
              >
                {files.map((file, i) =>
                  checkImageFile(file.originalFileName) ? (
                    <Stack key={`${i + 1}`} direction="row">
                      <div
                        key={`${i + 1}`}
                        style={{
                          padding: 10,
                          minWidth: '20px',
                        }}
                      >
                        {!isViewerOpen ? (
                          <Fab
                            color="primary"
                            aria-label="add"
                            style={{
                              borderRadius: 30,
                            }}
                            onClick={() => {
                              openImageViewer(i);
                            }}
                          >
                            <ImageIcon />
                          </Fab>
                        ) : null}

                        {file.originalFileName}
                      </div>
                    </Stack>
                  ) : (
                    <Stack key={`${i + 1}`} direction="row">
                      <div
                        key={`${i + 1}`}
                        style={{
                          padding: 10,
                          minWidth: '20px',
                        }}
                      >
                        {!isViewerOpen ? (
                          <Fab
                            color="primary"
                            aria-label="add"
                            style={{
                              borderRadius: 30,
                            }}
                            onClick={() => {}}
                          >
                            <AttachFileIcon />
                          </Fab>
                        ) : null}

                        {file.originalFileName}
                      </div>
                    </Stack>
                  )
                )}
                {isViewerOpen && (
                  <ImageViewer
                    src={images}
                    currentIndex={currentImage}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                  />
                )}
              </div>
            </DragAndDrop>
          </Col>
        </FormGroup>
      </div>

      <div style={{ marginRight: 10 }}>
        <h4>Case description</h4>
        <Form>
          <FormGroup className="align-items-center" row>
            <Col md="12">
              <Input type="textarea" id="repairNotesNew" />
            </Col>
          </FormGroup>
        </Form>
      </div>

      <Button
        color="primary"
        onClick={() => {
          newRepair();
        }}
      >
        Accept
      </Button>
    </>
  );
}
