import { service } from '.';

export default {
  getAll: (query) => service.get(`/notification_driver/list?${query}`),
  getOne: (id) => service.get(`/notification_driver/${id}`),
  create: (data) => service.post('/notification_driver/new', data),
  update: (data) => service.put(`/notification_driver/edit`, data),
  editStatus: (id, status) =>
    service.put(`/notification_driver/edit-status/${id}/${status}`),
  delete: (id) => service.delete(`/notification_driver/${id}`),
};
