import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ModalWindowFullScreen from '../../Modals/FullScreen';
import UnitViewModal from '../../../../views/pages/Units/Modals/ViewModal';
import { headerMaker } from '../helper2';

export default ({ value }) => {
  const [unitModal, setUnitModal] = useState(false);

  return (
    <>
      <ModalWindowFullScreen
        title="Unit modal"
        modal={unitModal}
        handleModal={() => {
          setUnitModal(!unitModal);
        }}
        body={<UnitViewModal id={value.truckId} headerMaker={headerMaker} />}
      />
      <Link
        className="link-in-table"
        onClick={() => {
          setUnitModal(true);
        }}
        // to={`/units/view/${value.truckId}`}
      >
        {value.number || value.truckNumber}
      </Link>
    </>
  );
};
