import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as Icon from 'react-feather';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { toast, Flip } from 'react-toastify';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DownloadIcon from '@mui/icons-material/Download';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RenameFile from '../Modals/RenameFile';
import accountingService from '../../../../services/accounting';

export default function FilesMenu({
  row,
  getAccountingFiles,
  getDownloadFile,
}) {
  const [showRenameFileModal, setShowRenameFileModal] = useState(false);

  const [fileId, setFileId] = useState();
  const [fileName, setFileName] = useState('');
  useEffect(() => {}, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleRenameFile = () => {
    setShowRenameFileModal(!showRenameFileModal);
  };

  const updateFileName = (name) => {
    const data = {
      id: fileId,
      fileName: name,
    };
    accountingService
      .updateFileName(data)
      .then((data) => {
        getAccountingFiles();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  return (
    <>
      <RenameFile
        modal={showRenameFileModal}
        toggle={toggleRenameFile}
        name={fileName}
        toggleRename={updateFileName}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Tooltip title="">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{
              ml: 2,
            }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <MoreHorizIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 2px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
      >
        <MenuItem
          onClick={() => {
            setFileId(row.fileId);
            setFileName(row.fileName);
            setShowRenameFileModal(true);
          }}
        >
          <ListItemIcon>
            <DriveFileRenameOutlineIcon fontSize="small" />
          </ListItemIcon>
          Rename
        </MenuItem>
        <MenuItem
          onClick={() => {
            setFileId(row.fileId);
            getDownloadFile(row.fileId);
          }}
        >
          <ListItemIcon>
            <DownloadIcon fontSize="small" />
          </ListItemIcon>
          Download
        </MenuItem>
      </Menu>
    </>
  );
}
