import { Box, Drawer, Hidden, List, ListItemButton } from '@mui/material';
import { React, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  BarChart as BarChartIcon,
  LogOut as LogOutIcon,
  GitBranch as BranchIcon,
} from 'react-feather';
import jwtDecode from 'jwt-decode';
import PersonIcon from '@mui/icons-material/Person';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import NavigationIcon from '@mui/icons-material/Navigation';
import InventoryIcon from '@mui/icons-material/Inventory';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ApartmentIcon from '@mui/icons-material/Apartment';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ListAltIcon from '@mui/icons-material/ListAlt';
import TaskIcon from '@mui/icons-material/Task';
import HelpIcon from '@mui/icons-material/Help';
import ExtensionIcon from '@mui/icons-material/Extension';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import ConstructionIcon from '@mui/icons-material/Construction';
import FireTruckIcon from '@mui/icons-material/FireTruck';
import HistoryIcon from '@mui/icons-material/History';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import SettingsIcon from '@mui/icons-material/Settings';
import ChatIcon from '@mui/icons-material/Chat';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useStyles } from './style';
import Item from './Item';

export default ({ onMobileClose, openMobile, expand, handleExpand }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();

  const token = localStorage.getItem(
    localStorage.getItem('current_account') + '_access_token'
  );
  const decodedToken = jwtDecode(token);
  const lastPage = localStorage.getItem('last_page');

  const [sideMenuItems, setSideMenu] = useState([]);

  const [isOpenTrip, setIsOpenTrip] = useState(false);
  const [isOpenLoad, setIsOpenLoad] = useState(false);
  const [isOpenTrailer, setIsOpenTrailer] = useState(false);

  const activeTrip =
    location.pathname.startsWith('/trips/list') ||
    location.pathname.startsWith('/trips/new') ||
    location.pathname.startsWith('/trips/edit/') ||
    location.pathname.startsWith('/trips/view/');

  const activeLoad =
    location.pathname.startsWith('/loads/list') ||
    location.pathname.startsWith('/loads/new') ||
    location.pathname.startsWith('/loads/edit');

  const activeTrailer =
    location.pathname.startsWith('/trailers/edit/') ||
    location.pathname.startsWith('/trailers/new') ||
    location.pathname.startsWith('/trailers/list') ||
    location.pathname.startsWith('/trailer_repairs/list') ||
    location.pathname.startsWith('/trailer_idles/list') ||
    location.pathname.startsWith('/trailer_history/list');

  const handleColapse = (index) => {
    if (index === 1) {
      setIsOpenTrip(!isOpenTrip);
      setIsOpenLoad(false);
      setIsOpenTrailer(false);
    }
    if (index === 2) {
      setIsOpenTrip(false);
      setIsOpenLoad(!isOpenLoad);
      setIsOpenTrailer(false);
    }
    if (index === 6) {
      setIsOpenTrip(false);
      setIsOpenLoad(false);
      setIsOpenTrailer(!isOpenTrailer);
    }
  };

  const adminSideMenu = [
    {
      href: '/dashboard/list',
      unit: '/dashboard',
      icon: BarChartIcon,
      title: 'Dashboard',
    },
    {
      href: '/trips/list',
      unit: '/trips',
      icon: NavigationIcon,
      title: 'Trips',
      childrenTrip: [
        {
          href: '/trips/new',
          unit: '/trips',
          icon: AddLocationAltIcon,
          title: 'Add trip',
        },
      ],
    },
    {
      href: '/loads/list',
      unit: '/loads',
      icon: InventoryIcon,
      title: 'Loads',
      childrenLoad: [
        {
          href: '/loads/new',
          unit: '/loads',
          icon: ControlPointIcon,
          title: 'Add load',
        },
      ],
    },
    {
      href: '/calendar',
      unit: '/calendar',
      icon: CalendarMonthIcon,
      title: 'Calendar',
    },
    {
      href: '/drivers/list',
      unit: '/drivers',
      icon: PeopleAltOutlinedIcon,
      title: 'Drivers',
    },
    {
      href: '/units/list',
      unit: '/units',
      icon: LocalShippingIcon,
      title: 'Units',
    },
    {
      href: '/fuel/list',
      unit: '/fuel',
      icon: LocalGasStationIcon,
      title: 'Fuel',
    },
    {
      href: '/trailers/list',
      unit: '/trailers',
      icon: RvHookupIcon,
      title: 'Trailers',
      childrenTrailer: [
        {
          href: '/trailer_repairs/list',
          unit: '/trailers',
          icon: ConstructionIcon,
          title: 'Repairs',
        },
        {
          href: '/trailer_idles/list',
          unit: '/trailers',
          icon: FireTruckIcon,
          title: 'Idles',
        },
        {
          href: '/trailer_history/list',
          unit: '/trailers',
          icon: HistoryIcon,
          title: 'History',
        },
      ],
    },
    {
      href: '/chats',
      unit: '/chats',
      icon: ChatIcon,
      title: 'Chats',
    },
    {
      href: '/reports',
      unit: '/reports',
      icon: SummarizeIcon,
      title: 'Reports',
    },
    {
      href: '/accounting',
      unit: '/accounting',
      icon: MonetizationOnIcon,
      title: 'Accounting',
    },
    {
      href: '/users/list',
      unit: '/users',
      icon: SupportAgentIcon,
      title: 'Users',
    },
    {
      href: '/companies/list',
      unit: '/companies',
      icon: ApartmentIcon,
      title: 'Companies',
    },
    {
      href: '/customers/list',
      unit: '/customers',
      icon: GroupAddIcon,
      title: 'Customers',
    },
    {
      href: '/locations',
      unit: '/locations',
      icon: LocationOnIcon,
      title: 'Locations',
    },
    {
      href: '/logs',
      unit: '/logs',
      icon: ListAltIcon,
      title: 'Logs',
    },
    {
      href: '/settings',
      unit: '/settings',
      icon: SettingsIcon,
      title: 'Settings',
    },
    {
      href: '/tasks',
      unit: '/tasks',
      icon: TaskIcon,
      title: 'Tasks',
    },
    {
      href: '/instruction',
      unit: '/instruction',
      icon: HelpIcon,
      title: 'Instruction',
    },
    {
      href: '/apps',
      unit: '/apps',
      icon: ExtensionIcon,
      title: 'Apps',
    },
    {
      href: '/exit',
      icon: ExitToAppIcon,
      title: 'Exit',
    },
  ];

  const updaterSideMenu = [
    {
      href: '/dashboard/list',
      unit: '/dashboard',
      icon: BarChartIcon,
      title: 'Dashboard',
    },
    {
      href: '/trips/list',
      unit: '/trips',
      icon: NavigationIcon,
      title: 'Trips',
    },
    {
      href: '/loads/list',
      unit: '/loads',
      icon: InventoryIcon,
      title: 'Loads',
    },
    {
      href: '/calendar',
      unit: '/calendar',
      icon: CalendarMonthIcon,
      title: 'Calendar',
    },
    {
      href: '/drivers/list',
      unit: '/drivers',
      icon: PeopleAltOutlinedIcon,
      title: 'Drivers',
    },
    {
      href: '/units/list',
      unit: '/units',
      icon: LocalShippingIcon,
      title: 'Units',
    },
    {
      href: '/fuel/list',
      unit: '/fuel',
      icon: LocalGasStationIcon,
      title: 'Fuel',
    },
    {
      href: '/trailers/list',
      unit: '/trailers',
      icon: RvHookupIcon,
      title: 'Trailers',
    },
    {
      href: '/chats',
      unit: '/chats',
      icon: ChatIcon,
      title: 'Chats',
    },
    {
      href: '/tasks',
      unit: '/tasks',
      icon: TaskIcon,
      title: 'Tasks',
    },
    {
      href: '/instruction',
      unit: '/instruction',
      icon: HelpIcon,
      title: 'Instruction',
    },
    {
      href: '/apps',
      unit: '/apps',
      icon: ExtensionIcon,
      title: 'Apps',
    },
    {
      href: '/exit',
      icon: ExitToAppIcon,
      title: 'Exit',
    },
  ];

  const dispatcherSideMenu = [
    {
      href: '/dashboard/list',
      unit: '/dashboard',
      icon: BarChartIcon,
      title: 'Dashboard',
    },
    {
      href: '/trips/list',
      unit: '/trips',
      icon: NavigationIcon,
      title: 'Trips',
    },
    {
      href: '/loads/list',
      unit: '/loads',
      icon: InventoryIcon,
      title: 'Loads',
    },
    {
      href: '/calendar',
      unit: '/calendar',
      icon: CalendarMonthIcon,
      title: 'Calendar',
    },
    {
      href: '/drivers/list',
      unit: '/drivers',
      icon: PeopleAltOutlinedIcon,
      title: 'Drivers',
    },
    {
      href: '/units/list',
      unit: '/units',
      icon: LocalShippingIcon,
      title: 'Units',
    },
    {
      href: '/fuel/list',
      unit: '/fuel',
      icon: LocalGasStationIcon,
      title: 'Fuel',
    },
    {
      href: '/trailers/list',
      unit: '/trailers',
      icon: RvHookupIcon,
      title: 'Trailers',
    },
    {
      href: '/chats',
      unit: '/chats',
      icon: ChatIcon,
      title: 'Chats',
    },
    {
      href: '/reports',
      unit: '/reports',
      icon: SummarizeIcon,
      title: 'Reports',
    },
    {
      href: '/tasks',
      unit: '/tasks',
      icon: TaskIcon,
      title: 'Tasks',
    },
    {
      href: '/instruction',
      unit: '/instruction',
      icon: HelpIcon,
      title: 'Instruction',
    },
    {
      href: '/apps',
      unit: '/apps',
      icon: ExtensionIcon,
      title: 'Apps',
    },
    {
      href: '/exit',
      icon: ExitToAppIcon,
      title: 'Exit',
    },
  ];

  const fuelDepartmentSideMenu = [
    {
      href: '/dashboard/list',
      unit: '/dashboard',
      icon: BarChartIcon,
      title: 'Dashboard',
    },
    {
      href: '/drivers/list',
      unit: '/drivers',
      icon: PeopleAltOutlinedIcon,
      title: 'Drivers',
    },
    {
      href: '/units/list',
      unit: '/units',
      icon: LocalShippingIcon,
      title: 'Units',
    },
    {
      href: '/fuel/list',
      unit: '/fuel',
      icon: LocalGasStationIcon,
      title: 'Fuel',
    },
    {
      href: '/tasks',
      unit: '/tasks',
      icon: TaskIcon,
      title: 'Tasks',
    },
    {
      href: '/instruction',
      unit: '/instruction',
      icon: HelpIcon,
      title: 'Instruction',
    },
    {
      href: '/apps',
      unit: '/apps',
      icon: ExtensionIcon,
      title: 'Apps',
    },
    {
      href: '/exit',
      icon: ExitToAppIcon,
      title: 'Exit',
    },
  ];

  const accounts = [
    {
      href: '/app/account',
      unit: '/app',
      icon: PersonIcon,
      title: 'Account',
    },
    // {
    //   index: 13,
    //   href: '/app/settings',
    //   icon: SettingsIcon,
    //   title: 'settings',
    // },
    {
      href: '/exit',
      icon: ExitToAppIcon,
      title: 'Exit',
    },
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    } else if (
      location.pathname === '/app/account' ||
      location.pathname === '/app/settings'
    ) {
      setSideMenu(accounts);
    } else {
      if (
        decodedToken.role === 'ROLE_ADMIN' ||
        decodedToken.role === 'SUPER_ADMIN'
      ) {
        setSideMenu(adminSideMenu);
      }
      if (decodedToken.role === 'ROLE_UPDATER') {
        setSideMenu(updaterSideMenu);
        // const arr = updaterSideMenu.filter(
        //   (item) => item.href === location.pathname
        // );
        // if (arr.length === 0) {
        //   navigate('/dashboard/list');
        // }
      }
      if (decodedToken.role === 'ROLE_DISPATCHER') {
        setSideMenu(dispatcherSideMenu);
        // const arr = dispatcherSideMenu.filter(
        //   (item) => item.href === location.pathname
        // );
        // if (arr.length === 0) {
        //   navigate('/dashboard/list');
        // }
      }
      if (decodedToken.role === 'ROLE_FUEL_DEPARTMENT') {
        setSideMenu(fuelDepartmentSideMenu);
        // navigate('/fuel/list');
      }
    }
  }, [location]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#2A455F',
        position: 'relative',
        width: !expand ? '50px' : '180px',
        height: openMobile ? 'calc(100% - 0px)' : 'calc(100% - 130px)',
        '&::-webkit-scrollbar': {
          width: 5,
        },
        '&::-webkit-scrollbar-track': {
          // boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'darkgrey',
          // outline: '1px solid slategrey',
          borderRadius: 2,
        },
      }}
      style={{
        // border: '1px solid black',
        overflow: 'auto',
        overflowY: 'scroll', // added scroll
      }}
    >
      <Box sx={{ pl: 0.5, paddingTop: 1 }}>
        <div className={classes.root}>
          <List>
            <ListItemButton
              key="expand"
              onClick={() => {
                handleExpand();
              }}
              disableGutters
              sx={{
                paddingLeft: 0.5,
                borderTopRightRadius: 3,
                borderBottomRightRadius: 3,
                color: '#fff',
                // color: 'text.secondary',
                fontWeight: 'medium',
                justifyContent: 'flex-start',
                letterSpacing: 0,
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },

                '& svg': {
                  mr: 1,
                },
              }}
              // {...rest}
              // to={href}
            >
              <AspectRatioIcon size="20" />
            </ListItemButton>
            {sideMenuItems.map((item, i) => (
              <Item
                index={i}
                expand={expand}
                href={item.href}
                unit={item.unit}
                key={item.title}
                title={expand ? item.title : null}
                titleName={item.title}
                icon={item.icon}
                subItemsTrip={item.childrenTrip}
                subItemsLoad={item.childrenLoad}
                subItemsTrailer={item.childrenTrailer}
                isOpenLoad={isOpenLoad}
                isOpenTrip={isOpenTrip}
                isOpenTrailer={isOpenTrailer}
                activeTrip={activeTrip}
                activeLoad={activeLoad}
                activeTrailer={activeTrailer}
                handleColapse={handleColapse}
              />
            ))}
          </List>
        </div>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: !expand ? '50px' : '180px',
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: !expand ? '50px' : '180px',
              top: 64,
              height: 'calc(100% + 84px)',
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};
