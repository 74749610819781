import React from 'react';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import './style.css';

export default function ScrollToTop({ showTopBtn, handleTop }) {
  return (
    <div className="top-to-btm">
      {showTopBtn && (
        <KeyboardArrowUpOutlinedIcon
          className="icon-position icon-style"
          onClick={handleTop}
        />
      )}
    </div>
  );
}
