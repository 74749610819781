export function dataSelector(dataResponse, page, size) {
  const filtered = dataResponse.content.map((item, i) => ({
    index: i + 1 + page * size,
    id: item.id,
    truck: item.vehicle,
    driver: item.driver,
    fuel: item.fuelPercent ? item.fuelPercent + ' %' : '',
    fuelShopType: item.fuelShopType,
    fuelAmount: item.isFull ? 'Full' : item.fuelAmount,
    fuelShopStore: item.fuelShopData.store,
    fuelShopAddress: item.fuelShopData.city + ', ' + item.fuelShopData.state,
    createdAt: item.createdAt,
    data: item,
    unitData: item,
    tankData: item,
    fuelData: item,
  }));
  return { total: dataResponse.totalElements, data: filtered };
}
