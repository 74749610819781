export function dataSelector(dataResponse, page, size) {
  const filtered = dataResponse.content.map((item, i) => ({
    index: i + 1 + page * size,
    username: item.username,
    tableName: item.tableName,
    actionType: item.actionType,
    entityId: item.entityId,
    initialObject: item.initialObject,
    timeStamp: item.timeStamp,
    item,
  }));
  return { total: dataResponse.totalElements, data: filtered };
}
