import { service } from '.';

export default {
  getAll: (query) => service.get(`/admin/users?${query}`),
  getAllDispatchers: (query) => service.get(`/admin/dispatchers?${query}`),
  getAllForChat: (query) => service.get(`/admin/for_chat/users?${query}`),
  getAllConversations: (query) => service.get(`/conversations?${query}`),
  getLogs: (query, data) => service.post(`/admin/log?${query}`, data),
  getOne: (id) => service.get(`/admin/${id}`),
  getUserData: (id) => service.get(`/admin/get_user/${id}`),
  getProfileData: (id) => service.get(`/admin/get_profile_data`),
  getContext: () => service.get(`/admin/user_context`),
  create: (data) => service.post('/admin/create_user', data),
  update: (data) => service.put(`/admin/edit_user`, data),
  updateUserInfo: (data) => service.put(`/admin/edit_user_info`, data),
  delete: (id) => service.delete(`/admin/${id}`),

  sendConfirmationCode: (data) =>
    service.post('/admin/send_confirmation_code', data),
  sendConfirmationCode2: (query, data) =>
    service.post('/admin/send_confirmation_code2/' + query, data),
  resetPassword: (data) => service.post('/admin/reset_password', data),
  resetPassword2: (data) => service.post('/admin/reset_password2', data),
  formEmailConfirmationCode: (query, data) =>
    service.post('/admin/form_email_confirmation_code2/' + query, data),

  getTableHeader: () => service.get(`/users/get_table_header`),
  createTableHeader: (data) => service.post('/users/create_table_header', data),
  updateTableHeader: (data) => service.post('/users/update_table_header', data),
};
