import React, { useState } from 'react';
import { Button } from '@mui/material';
import { Form, FormGroup, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
// import ShowModal from './Modals/ShowItem';
import ModalWindow from '../../Modals';

export default ({ value }) => {
  const [showModal, setShowModal] = useState(false);
  const [item, setItem] = useState(value.initialObject);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <ModalWindow
        title="Form link"
        modal={showModal}
        handleModal={toggleModal}
        maxWidth={550}
        minWidth={550}
        maxHeight={350}
        minHeight={350}
        body={
          <>
            <Form>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Load number:</span>
                </Col>
                <Col md="8">
                  <Link
                    className="link-in-table"
                    to={`/loads/edit/${item.customLoadNumber ? item.id : ''}`}
                  >
                    {item.customLoadNumber ? item.customLoadNumber : ''}
                  </Link>
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Rate Confirmation:</span>
                </Col>
                <Col md="8">
                  {item.rateConfirmationId ? (
                    <>
                      <Button
                        style={{}}
                        className="mt-1"
                        type="button"
                        // href={`${process.env.REACT_APP_BASE_URL}/file/${prevRateConfirmationId}`}
                        onClick={() => {
                          fetch(
                            `${process.env.REACT_APP_BASE_URL}/file/${item.rateConfirmationId}`,
                            {
                              method: 'GET',
                              headers: {
                                Authorization:
                                  'Bearer ' +
                                  localStorage.getItem(
                                    localStorage.getItem('current_account') +
                                      '_access_token'
                                  ),
                              },
                            }
                          ).then((response) => {
                            response.blob().then((blob) => {
                              const spl = response.headers
                                .get('content-disposition')
                                .split(';');

                              const filename = spl[1]
                                .split('=')[1]
                                .replace('"', '');
                              const fileName2 = filename.replace('"', '');

                              // Creating new object of PDF file
                              const fileURL = window.URL.createObjectURL(blob);
                              // Setting various property values
                              const alink = document.createElement('a');
                              alink.href = fileURL;
                              alink.target = '_blank';
                              alink.download = fileName2;
                              alink.click();
                            });
                          });
                        }}
                      >
                        Download Rate Confirmation
                      </Button>
                    </>
                  ) : (
                    <span>Empty</span>
                  )}
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Revised RC:</span>
                </Col>
                <Col md="8">
                  {item.revisedRateConfirmationId ? (
                    <>
                      <Button
                        style={{}}
                        className="mt-1"
                        type="button"
                        // href={`${process.env.REACT_APP_BASE_URL}/file/${prevRateConfirmationId}`}
                        onClick={() => {
                          fetch(
                            `${process.env.REACT_APP_BASE_URL}/file/${item.revisedRateConfirmationId}`,
                            {
                              method: 'GET',
                              headers: {
                                Authorization:
                                  'Bearer ' +
                                  localStorage.getItem(
                                    localStorage.getItem('current_account') +
                                      '_access_token'
                                  ),
                              },
                            }
                          ).then((response) => {
                            response.blob().then((blob) => {
                              const spl = response.headers
                                .get('content-disposition')
                                .split(';');

                              const filename = spl[1]
                                .split('=')[1]
                                .replace('"', '');
                              const fileName2 = filename.replace('"', '');

                              // Creating new object of PDF file
                              const fileURL = window.URL.createObjectURL(blob);
                              // Setting various property values
                              const alink = document.createElement('a');
                              alink.href = fileURL;
                              alink.target = '_blank';
                              alink.download = fileName2;
                              alink.click();
                            });
                          });
                        }}
                      >
                        Download Revised Rate Confirmation
                      </Button>
                    </>
                  ) : (
                    <span>Empty</span>
                  )}
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Trip:</span>
                </Col>
                <Col md="8">
                  {item.tripId ? (
                    <>
                      <span>{item.tripId}</span>
                    </>
                  ) : (
                    <span>Empty</span>
                  )}
                </Col>
              </FormGroup>
              {/* <FormGroup className="align-items-center" row>
            <Col md="4">
              <span>From:</span>
            </Col>
            <Col md="8">
              <span> {item.loadDtos ? item.loadDtos.from : ''}</span>
            </Col>
          </FormGroup>
          <FormGroup className="align-items-center" row>
            <Col md="4">
              <span>Pickup date:</span>
            </Col>
            <Col md="8">
              <span>
                {' '}
                {item.loadDtos ? item.loadDtos.pickupDateFormatted : ''}
              </span>
            </Col>
          </FormGroup>

          <FormGroup className="align-items-center" row>
            <Col md="4">
              <span>To:</span>
            </Col>
            <Col md="8">
              <span> {item.loadDtos ? item.loadDtos.to : ''}</span>
            </Col>
          </FormGroup>
          <FormGroup className="align-items-center" row>
            <Col md="4">
              <span>Delivery date:</span>
            </Col>
            <Col md="8">
              <span>
                {' '}
                {item.loadDtos ? item.loadDtos.deliveryDateFormatted : ''}
              </span>
            </Col>
          </FormGroup> */}
            </Form>
          </>
        }
      />
      {/* <ShowModal
        modal={showModal}
        toggleModal={toggleModal}
        item={value.initialObject}
      /> */}
      <div
        style={{
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '20%',
        }}
      >
        <Button
          disabled={value.tableName !== 'loads'}
          variant="contained"
          color="success"
          className="d-flex align-items-center"
          type="button"
          size="small"
          onClick={() => {
            toggleModal();
          }}
        >
          Show
        </Button>
      </div>
    </>
  );
};
