import React, { useState, useMemo, useEffect } from 'react';
import { Card, CardBody, Input, Button } from 'reactstrap';
import * as Icon from 'react-feather';
import { Helmet } from 'react-helmet';
import { TextField, Box, IconButton } from '@mui/material';
import { toast, Flip } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { AnimatePresence } from 'framer-motion';
import FilterListIcon from '@mui/icons-material/FilterList';
import SweetAlert from '../../../../components/main/SweetAlert';
import AnimationDiv from '../../../../components/main/Animations/Transition';
import FilterTools from '../../../../components/main/FilteredBody/NotificationDriver';
import driverNotificationService from '../../../../services/driver_notifications';
import { fetchData } from '../../../../redux/modules/driver_notifications/actions';
import { useQuery } from '../../useQuery';
import Loading from '../../../../components/main/Loading';
import Table from '../../../../components/main/table';
import { driverNotificationsHeader } from '../../../../redux/constants/table/headers';
import { headerMaker } from '../../../../components/main/table/helper';
import { toolTipsUser, toolTipsAdmin } from './helper';
import { useStyles } from './style';
import NewNotificationModal from './Add';
import ModalWindow from '../../../../components/main/Modals';

export default function DriverNotifications() {
  const {
    query,
    deleted,
    inProcess,
    setSearch,
    setDeleted,
    setInProcess,
    setRefresh,
    handleOnTableChange,
  } = useQuery({ fetchData });

  const classes = useStyles();
  const navigate = useNavigate();
  const [isFilterShow, setFilterIsShow] = useState(false);

  const [deletingId, setDeletingId] = useState(null);
  const [deleteAlert, setDeleteAlert] = useState(false);

  const [modal, setModal] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const headers = useMemo(
    () => headerMaker(driverNotificationsHeader),
    [driverNotificationsHeader]
  );

  const { data, loading, total } = useSelector(
    (state) => state.driverNotificationsReducer
  );

  localStorage.setItem('total_data', total);

  const token = localStorage.getItem(
    localStorage.getItem('current_account') + '_access_token'
  );
  const decodedToken = jwtDecode(token.replace('Bearer ', ''));

  const createHeaders = (headerData) => {
    const cData = headerData.map((item) => ({
      item,
      // ref: useRef(),
    }));
    // console.log(cData);

    // setColumns(cData);

    return cData;
  };

  const [columns, setColumns] = useState(createHeaders(headers));

  const nominateToDelete = (id) => {
    setDeleteAlert(true);
    setDeletingId(id);
  };

  const deleteNotification = () => {
    driverNotificationService
      .delete(`${deletingId}`)
      .then((res) => {
        setRefresh(true);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
    setDeletingId(null);
    setDeleteAlert(false);
  };

  const handleModal = () => {
    setModal(!modal);
  };

  const doubleClick = (id) => {};

  useEffect(() => {}, []);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <Helmet>
        <title> Notifications </title>
      </Helmet>
      <Box
        sx={{
          // backgroundColor: '#000',
          // height: 'calc(100vh)',
          py: 1,
        }}
      >
        <ModalWindow
          title="New"
          modal={showModal}
          handleModal={toggleModal}
          maxWidth="sm"
          body={
            <NewNotificationModal
              modal={showModal}
              toggle={toggleModal}
              refreshData={() => setRefresh(true)}
            />
          }
        />
        <Loading loading={loading} />
        <SweetAlert
          title="Are you sure ?"
          warning
          show={deleteAlert}
          showCancel
          reverseButtons
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          onConfirm={() => {
            deleteNotification(deletingId);
          }}
          onCancel={() => setDeleteAlert(false)}
        />

        <Card
          className="overflow-hidden agGrid-card"
          style={{ marginRight: 8 }}
        >
          <div className="d-flex justify-content-between align-items-center mt-2 mx-2 mb-1">
            <h3 className="mb-0">All notifications list</h3>
            <div className="d-flex justify-content-between align-items-center">
              <div className="mr-1">
                <Button
                  size="sm"
                  color="success"
                  className="d-flex align-items-center"
                  type="button"
                  onClick={() => {
                    toggleModal();
                  }}
                >
                  <Icon.Plus size={20} /> Add new
                </Button>
              </div>
              <div className="d-flex align-items-center mx-2 mb-1">
                <IconButton
                  color="primary"
                  aria-label="add"
                  onClick={() => {
                    setFilterIsShow(!isFilterShow);
                  }}
                >
                  <FilterListIcon />
                </IconButton>
              </div>
            </div>
          </div>

          <div className={isFilterShow ? 'align-items-center mb-2 mx-2' : ''}>
            <AnimatePresence className={classes.filters}>
              {isFilterShow ? (
                <AnimationDiv>
                  <FilterTools deleted={deleted} setDeleted={setDeleted} />
                </AnimationDiv>
              ) : null}
            </AnimatePresence>
          </div>
          <CardBody className="py-0 no-pagination">
            <div className="w-100 ag-grid-table with-pagination">
              <Table
                data={data}
                headers={headers}
                toolTips={
                  decodedToken.role === 'ROLE_ADMIN' ||
                  decodedToken.role === 'SUPER_ADMIN'
                    ? toolTipsAdmin
                    : toolTipsUser
                }
                onChange={handleOnTableChange}
                modal={handleModal}
                deleteModal={nominateToDelete}
                doubleClick={doubleClick}
                columns={columns}
                setColumns={setColumns}
                // hidePagination={1}
              />
            </div>
          </CardBody>
        </Card>
      </Box>
    </>
  );
}
