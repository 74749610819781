export default {
  TABLE_OWNED_COMPANIES_FETCH_STATUSES: 'TABLE_OWNED_COMPANIES_FETCH_STATUSES',
  TABLE_OWNED_COMPANIES_SET_DATA: 'TABLE_OWNED_COMPANIES_SET_DATA',
  TABLE_OWNED_COMPANIES_SET_TOTAL: 'TABLE_OWNED_COMPANIES_SET_TOTAL',
  TABLE_OWNED_COMPANIES_SET_LOADING: 'TABLE_OWNED_COMPANIES_SET_LOADING',
  TABLE_OWNED_COMPANIES_ERROR: 'TABLE_OWNED_COMPANIES_ERROR',
  TABLE_OWNED_COMPANIES_FETCH_DATA: 'TABLE_OWNED_COMPANIES_FETCH_DATA',
  TABLE_OWNED_COMPANIES_SAGA_ONSEARCH_ACTION:
    'TABLE_OWNED_COMPANIES_SAGA_ONSEARCH_ACTION',
};
