export const byCategoryName = [
  {
    name: 'Home',
    value: 1,
  },
  {
    name: 'Car',
    value: 2,
  },
];
export const byStatus = [
  {
    name: 'true',
    value: 1,
  },
  {
    name: 'false',
    value: 0,
  },
];
