export default {
  TABLE_LOAD_CALENDAR_FETCH_STATUSES: 'TABLE_LOAD_CALENDAR_FETCH_STATUSES',
  TABLE_LOAD_CALENDAR_SET_DATA: 'TABLE_LOAD_CALENDAR_SET_DATA',
  TABLE_LOAD_CALENDAR_SET_TOTAL: 'TABLE_LOAD_CALENDAR_SET_TOTAL',
  TABLE_LOAD_CALENDAR_SET_LOADING: 'TABLE_LOAD_CALENDAR_SET_LOADING',
  TABLE_LOAD_CALENDAR_ERROR: 'TABLE_LOAD_CALENDAR_ERROR',
  TABLE_LOAD_CALENDAR_FETCH_DATA: 'TABLE_LOAD_CALENDAR_FETCH_DATA',
  TABLE_LOAD_CALENDAR_SAGA_ONSEARCH_ACTION:
    'TABLE_LOAD_CALENDAR_SAGA_ONSEARCH_ACTION',
};
