import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
  Col,
} from 'reactstrap';
import { toast, Flip } from 'react-toastify';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import driverService from '../../../../services/drivers';

export default function MobileAppAccess({ driverId, login, modal, toggle }) {
  const [openDrop, setOpenDrop] = useState(false);

  useEffect(() => {}, [!modal]);

  const handleCloseDrop = () => {
    setOpenDrop(false);
  };

  const editAccess = () => {
    const data = {
      id: driverId * 1,
      login: document.querySelector('#login').value,
      password: document.querySelector('#password').value,
    };
    driverService
      .updateMobileAppAccess(data)
      .then((res) => {
        if (res) {
          toast.success('Access successfuly edited', {
            transition: Flip,
          });
        } else {
          toast.error('Something went wrong', { transition: Flip });
        }

        toggle();
      })
      .catch((err) => {
        toast.error(err.response.data.message, { transition: Flip });
      });
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      className="modal-dialog-centered modal-lg"
    >
      <ModalHeader toggle={toggle}>Mobile App Access</ModalHeader>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openDrop}
        onClick={handleCloseDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ModalBody>
        <div style={{ width: '100%', marginRight: 20 }}>
          <Form>
            <FormGroup className="align-items-center" row>
              <Col md="4">
                <span>Login</span>
              </Col>
              <Col md="8">
                <Input
                  defaultValue={login}
                  type="text"
                  id="login"
                  onChange={(e) => {
                    document.querySelector('#login').value = e.target.value;
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup className="align-items-center" row>
              <Col md="4">
                <span>Password</span>
              </Col>
              <Col md="8">
                <Input type="password" id="password" onChange={() => {}} />
              </Col>
            </FormGroup>
            <FormGroup className="align-items-center" row>
              <Col md="4">
                <span>Confirm Password</span>
              </Col>
              <Col md="8">
                <Input
                  type="password"
                  id="confirm_password"
                  onChange={() => {}}
                />
                <Label for="password">
                  Must contain at least one uppercase and lowercase letter, and
                  at least 6 or more characters
                </Label>
              </Col>
            </FormGroup>
          </Form>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            editAccess();
          }}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
