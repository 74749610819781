import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  CustomInput,
  Col,
} from 'reactstrap';
import { toast, Flip } from 'react-toastify';
import companyService from '../../../../services/companies';

export default function OfficeModal({
  modal,
  states,
  toggleDeliveryModal,
  className,
  parentId,
  addOffice,
  toggleOfficeModal,
}) {
  const [consignee, setConsignee] = useState();
  const [consigneeName, setConsigneeName] = useState('');
  const [consigneeSelected, setConsigneeSelected] = useState();

  const newOffice = () => {
    const sendingData = {
      parentId: parseInt(parentId, 10),
      stateProvinceId: parseInt(
        document.querySelector('#office-state').value,
        10
      ),
      city: document.querySelector('#office-city').value,
      street: document.querySelector('#office-street').value,
    };
    companyService
      .createChild(sendingData)
      .then((data) => {
        addOffice(
          parseInt(document.querySelector('#office-state').value, 10),
          document.querySelector('#office-city').value,
          data.id
        );
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggleDeliveryModal}
      className={className}
      backdrop="static"
    >
      <ModalHeader toggle={() => toggleOfficeModal(null)}>
        New office
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup className="align-items-center" row>
            <Col md="4">
              <span>State</span>
              <span style={{ color: 'red' }}> *</span>
            </Col>
            <Col md="8">
              <CustomInput type="select" name="select" id="office-state">
                {states.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </CustomInput>
            </Col>
          </FormGroup>
          <FormGroup className="align-items-center" row>
            <Col md="4">
              <span>City</span>
              <span style={{ color: 'red' }}> *</span>
            </Col>
            <Col md="8">
              <Input type="text" id="office-city" />
            </Col>
          </FormGroup>
          <FormGroup className="align-items-center" row>
            <Col md="4">
              <span>Street</span>
            </Col>
            <Col md="8">
              <Input type="text" id="office-street" />
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => newOffice()}>
          Accept
        </Button>
      </ModalFooter>
    </Modal>
  );
}
