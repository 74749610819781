import { service } from '.';

export default {
  getAll: (query) => service.get(`/load/list?${query}`),
  getAllForTrip: (query) => service.get(`/load/list/for_trip?${query}`),
  getContext: () => service.get(`/load/context`),
  getOne: (id) => service.get(`/load/${id}`),
  getSearch: (value) =>
    service.get(`/load/for_trailer_repairs/search_by_number?q=${value}`),
  create: (data) => service.post('/load/new', data),
  update: (data) => service.put(`/load/edit`, data),
  updateStatus: (status, id, data) =>
    service.put(`/load/update_status/${status}/${id}`, data),
  delete: (id) => service.delete(`/load/${id}`),
};
