import React, { useState, useEffect } from 'react';
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  // Button,
  Input,
  Row,
  FormGroup,
  Label,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import Select from 'react-select';
import moment from 'moment';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { toast, Flip } from 'react-toastify';
import Flatpickr from 'react-flatpickr';
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader,
} from 'react-calendar-timeline/lib';
// import { connect } from "react-redux";
// import { changeDashboardState } from "../../../redux/actions/utility";
// import containerResizeDetector from 'react-calendar-timeline/lib/resize-detector/container'
import randomColor from 'randomcolor';
import {
  Button,
  Card,
  Grid,
  TextField,
  Stack,
  colors,
  Typography,
} from '@mui/material';
import InfoLabel from './InfoLabel';
import ShowLoadModal from './Modals/ShowLoad';
import dispatcherService from '../../../services/users';
import ownedCompanyService from '../../../services/owned_company';
import loadCalendarService from '../../../services/load_calendar';
import { fetchData } from '../../../redux/modules/load_calendar/actions';
import { useQuery } from '../useQuery';
import Loading from '../../../components/main/Loading';

const keys = {
  groupIdKey: 'id',
  groupTitleKey: 'title',
  groupRightTitleKey: 'rightTitle',
  itemIdKey: 'id',
  itemTitleKey: 'title',
  itemDivTitleKey: 'title',
  itemGroupKey: 'group',
  itemTimeStartKey: 'start',
  itemTimeEndKey: 'end',
  groupLabelKey: 'title',
};

export default function LoadCalendar() {
  const {
    query,
    search,
    deleted,
    start,
    end,
    pageIndex,
    setSearch,
    setDeleted,
    setStart,
    setEnd,
    setCurrentEmployerId,
    setDispatcherId,
    setPageIndex,
    setPageSize,
    handleOnTableChange,
  } = useQuery({ fetchData });

  const antIcon = <LoadingOutlined style={{ fontSize: 44 }} spin />;

  const minTime = moment().add(-12, 'months').valueOf();
  const maxTime = moment().add(6, 'months').valueOf();

  const unitStatuses = ['#188038', '#75ACFF', '#ea5455'];

  const [draggedItem, setDraggedItem] = useState();
  const [active, setActive] = useState();

  const [loadingStatus, setLoadingStatus] = useState(true);
  const [showLoadModal, setShowLoadModal] = useState(false);

  const { data, total, loading, error } = useSelector(
    (state) => state.loadCalendarReducer
  );

  const [groups, setGroups] = useState([]);
  const [items, setItems] = useState([]);
  const [itemLoad, setItemLoad] = useState({});
  const [ownedCompanies, setOwnedCompanies] = useState([]);
  const [companyId, setCompanyId] = useState();
  const [companyName, setCompanyName] = useState('All');
  const [dispatchers, setDispatchers] = useState([]);
  const [dispatcher, setDispatcher] = useState();

  const [startDate, setStartDate] = useState(
    moment().startOf('day').subtract(13, 'day').valueOf()
  );
  const [endDate, setEndDate] = useState(
    moment().startOf('day').add(1, 'day').valueOf()
  );

  const [visibleTimeStart, setVisibleTimeStart] = useState(startDate);
  const [visibleTimeEnd, setVisibleTimeEnd] = useState(
    moment().startOf('day').add(2, 'day').valueOf()
  );

  const [sendStatus, setSendStatus] = useState(0);

  const toggleTeam = (value) => {
    if (value === null) {
      // setLoading(true);
      setDispatcher(null);
      setCurrentEmployerId(companyId);
      setStart(startDate);
      setEnd(endDate);
      setDispatcherId(null);
      setSearch(document.querySelector('#search').value);
      // updateInfoGetData(companyId, startDate, endDate, null);
    } else {
      // setLoading(true);
      localStorage.setItem('dispatcherId', value.value);
      setDispatcher(value);
      setCurrentEmployerId(companyId);
      setStart(startDate);
      setEnd(endDate);
      setDispatcherId(value.value);
      setSearch(document.querySelector('#search').value);
      // updateInfoGetData(companyId, startDate, endDate, value.value);
    }
  };

  // const updateInfoGetData = (page, tab, start, end, dispatcherId) => {
  //   const search = document.querySelector('#search').value;

  //   loadCalendarService
  //     .getAll(
  //       `size=10&page=${page}&start=${start}&end=${end}${
  //         tab ? `&currentEmployerId=${tab}` : ''
  //       }${dispatcherId ? `&dispatcherId=${dispatcherId}` : ''}${
  //         search ? `&search=${search}` : '&search='
  //       }`
  //     )
  //     .then((data) => {
  //       // if (active !== tab) return;
  //       let gr = [];
  //       let ite = [];
  //       readData(data.data);
  //       // data.data.forEach((el, i) => {
  //       //     if (el.unitStatus !== "OFF") {
  //       //         gr.push({
  //       //             id: `${i + 1}`,
  //       //             title: el.number,
  //       //             rightTitle: `${i + 1}. ` + el.id,
  //       //             bgColor: randomColor({
  //       //                 luminosity: "light",
  //       //                 seed: randomSeed + i,
  //       //             }),
  //       //         });

  //       //         if (el.loads != null) {
  //       //             for (let k = 0; k < el.loads.length; k++) {
  //       //                 ite.push({
  //       //                     id: `${ite.length + 1}`,
  //       //                     group: `${i + 1}`,
  //       //                     title: "",
  //       //                     start: el.loads[k].timeStart,
  //       //                     end: el.loads[k].endTime,
  //       //                     item: el.loads[k],
  //       //                     itemProps: {
  //       //                         "data-tip": "",
  //       //                     },
  //       //                 });
  //       //             }
  //       //         }
  //       //     }
  //       // });
  //       // ite = ite.sort((a, b) => b - a);
  //       // setGroups(gr);
  //       // setItems(ite);

  //       // setLoading(false);
  //     })
  //     .catch((err) => {
  //       toast.error(err.response.data.message, {
  //         transition: Flip,
  //       });
  //     });
  // };

  const readData = (data) => {
    const randomSeed = Math.floor(Math.random() * 1000);

    const gr = [];
    let ite = [];

    data.forEach((el, i) => {
      if (el.unitStatus !== 'OFF') {
        gr.push({
          id: `${i + 1}`,
          title: el.number,
          rightTitle: `${i + 1}. ` + el.id,
          bgColor: randomColor({
            luminosity: 'light',
            seed: randomSeed + i,
          }),
        });

        if (el.loads != null) {
          el.loads.forEach((lItem, k) => {
            ite.push({
              id: `${ite.length + 1}`,
              group: `${i + 1}`,
              title: '',
              start: el.loads[k].timeStart,
              end: el.loads[k].endTime,
              item: el.loads[k],
              itemProps: {
                'data-tip': '',
              },
            });
          });
          // for (let k = 0; k < el.loads.length; k + 1) {

          // }
        }
        if (el.unitChangeStatusDtos != null) {
          el.unitChangeStatusDtos.forEach((uItem, k) => {
            const datas = el.unitChangeStatusDtos[k];
            datas.truckNumber = el.number;
            ite.push({
              id: `${ite.length + 1}`,
              group: `${i + 1}`,
              title: '',
              start: el.unitChangeStatusDtos[k].startTime,
              end: el.unitChangeStatusDtos[k].endTime,
              item: datas,
              itemProps: {
                'data-tip': '',
              },
            });
          });
          // for (let k = 0; k < el.unitChangeStatusDtos.length; k + 1) {

          // }
        }
      }
    });
    ite = ite.sort((a, b) => b - a);

    setGroups(gr);
    setItems(ite);
    // setLoading(false);
  };

  const getDataInterval = () => {
    if (
      localStorage.getItem('timer') === null ||
      localStorage.getItem('timer') === '0'
    ) {
      localStorage.setItem('timer', '1');
      const interval = setInterval(() => {
        const dispatcherId = localStorage.getItem('dispatcherId');
        const start = localStorage.getItem('startDate');
        const end = localStorage.getItem('endDate');
        const companyId = localStorage.getItem('companyId');
        if (window.location.pathname === '/calendar') {
          // updateInfoGetData(companyId, start, end, dispatcherId);
        }
        if (window.location.pathname !== '/calendar') {
          console.log('off');
          clearInterval(interval);
        }
      }, 60000);
    }
  };

  const getDataDispatchers = (company) => {
    setDispatcher(null);
    setDispatchers([]);
    dispatcherService
      .getAllDispatchers(
        `size=10000&roleId=3${company ? `&currentEmployerId=${company}` : ''}`
      )
      .then((data) => {
        const dataToShow = [];
        data.content.forEach((item) => {
          const elToShow = {
            value: item.id,
            label: item.username,
          };
          dataToShow.push(elToShow);
        });
        setDispatchers(dataToShow);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const toggle = (tab) => {
    // setLoading(true);
    localStorage.setItem('companyId', tab);
    setCompanyId(tab);
    // this.props.changeDashboardState(tab, this.state.team);
    getDataDispatchers(tab);
    setCurrentEmployerId(tab);
    setStart(startDate);
    setEnd(endDate);
    setDispatcherId(null);
    setSearch(document.querySelector('#search').value);
    // updateInfoGetData(tab, startDate, endDate, null);
  };

  const handleTimeChange = (
    visibleTimeStart,
    visibleTimeEnd,
    updateScrollCanvas
  ) => {
    // console.log(updateScrollCanvas);
    // console.log('second', new Date(visibleTimeStart));
    // console.log('second', new Date(visibleTimeEnd));

    if (visibleTimeStart < minTime && visibleTimeEnd > maxTime) {
      updateScrollCanvas(minTime, maxTime);
    } else if (visibleTimeStart < minTime) {
      updateScrollCanvas(
        minTime,
        minTime + (visibleTimeEnd - visibleTimeStart)
      );
    } else if (visibleTimeEnd > maxTime) {
      updateScrollCanvas(
        maxTime - (visibleTimeEnd - visibleTimeStart),
        maxTime
      );
    } else {
      updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
    }
    setVisibleTimeStart(visibleTimeStart);
    setVisibleTimeEnd(visibleTimeEnd);
    // updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
  };

  const groupRenderer = ({ group }) => {
    return (
      <div className="custom-group">
        <Link className="link-in-table" to={`/unit/view/${group.title}`}>
          {group.id + '. ' + group.title}
        </Link>
      </div>
    );
  };

  const toggleLoadModal = () => {
    setShowLoadModal(!showLoadModal);
  };

  const itemRenderer = ({
    item,
    timelineContext,
    itemContext,
    getItemProps,
    getResizeProps,
  }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    const backgroundColor = item.item.statusColor
      ? item.item.statusColor
      : '#228B22';
    // ? itemContext.dragging
    //     ? "green"
    //     : item.item.statusColor
    // : item.item.statusColor;
    const borderColor = itemContext.resizing ? 'green' : 'green';
    return (
      <div
        {...getItemProps({
          style: {
            zIndex: 49,
            backgroundColor,
            color: 'red',
            // borderColor,
            // borderStyle: 'solid',
            // borderWidth: 1,
            borderRadius: 4,
            borderLeftWidth: itemContext.selected ? 2 : 1,
            borderRightWidth: itemContext.selected ? 2 : 1,
          },
          onMouseDown: () => {
            // setDraggedItem({
            //     item: item,
            //     startTime: item.start,
            //     endTime: item.end,
            // });

            setItemLoad(item.item);
            toggleLoadModal();

            // console.log("on item click", item);
          },
        })}
      >
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

        {/* <div
                    style={{
                        backgroundColor,
                        borderRadius: 4,
                        borderLeftWidth: itemContext.selected ? 2 : 1,
                        borderRightWidth: itemContext.selected ? 2 : 1,
                        // height: itemContext.dimensions.height,
                        overflow: "hidden",
                        // paddingLeft: 3,
                        // textOverflow: "ellipsis",
                        // whiteSpace: "nowrap",
                    }}
                    onClick={() => {
                        toggleLoadModal();
                    }}
                >
                    {itemContext.title}
                </div> */}

        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
      </div>
    );
  };

  useEffect(() => {
    setPageSize(1000);
    setStart(startDate);
    setEnd(endDate);
  }, []);

  useEffect(() => {
    localStorage.setItem('startDate', startDate);
    localStorage.setItem('endDate', endDate);
    localStorage.removeItem('dispatcherId');
    localStorage.removeItem('companyId');

    if (sendStatus === 0) {
      // setLoading(true);
      ownedCompanyService
        .getAllDashboard('sort=id,DESC&size=10000')
        .then((data) => {
          setOwnedCompanies(data);
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message, {
            transition: Flip,
          });
        });
      getDataDispatchers();
      setSendStatus(1);
    }

    setGroups([]);
    setItems([]);

    if (data.length !== 0) {
      readData(data);
    }

    if (
      localStorage.getItem('timer') === null ||
      localStorage.getItem('timer') === '0'
    ) {
      // getDataInterval();
    }
  }, [data]);

  useEffect(() => {
    if (error !== '') {
      toast.error(error, {
        transition: Flip,
      });
    }
  }, [error]);

  return (
    <>
      <Helmet>
        <title> Load calendar </title>
      </Helmet>
      <Loading loading={loading} />
      <ShowLoadModal
        modal={showLoadModal}
        toggleLoadModal={toggleLoadModal}
        item={itemLoad}
      />
      <Card>
        <div className="d-flex justify-content-between ml-1 mt-1 mr-1">
          <div className="d-flex align-items-center ">
            <Nav pills className="dashboard-tabs">
              <NavItem>
                <NavLink
                  style={{
                    height: 38,
                    backgroundColor:
                      companyName === 'All' ? '#00FF7F' : 'white',
                  }}
                  className={classnames(
                    {
                      customActive: active === 0,
                    },
                    'd-flex align-items-center'
                  )}
                  onClick={() => {
                    setCompanyName('All');
                    toggle(0);
                  }}
                >
                  <h4 className="mb-0 ml-1 mr-1">All</h4>
                </NavLink>
              </NavItem>
              {ownedCompanies.map((item) => (
                <NavItem key={`${item.id}`}>
                  <NavLink
                    style={{
                      height: 38,
                      backgroundColor:
                        companyName === item.abbreviation ? '#00FF7F' : 'white',
                    }}
                    className={classnames(
                      {
                        customActive: active === item.id,
                      },
                      'd-flex align-items-center'
                    )}
                    onClick={() => {
                      if (startDate >= endDate) {
                        let text = 'Start time is greater than end time';
                        if (startDate === endDate) {
                          text = 'Start time is equal to end time';
                        }
                        toast.error(text, {
                          transition: Flip,
                        });
                      } else {
                        setCompanyId(item.id);
                        setCompanyName(item.abbreviation);
                        toggle(item.id);
                      }
                    }}
                  >
                    {item.logoFileId ? (
                      // eslint-disable-next-line jsx-a11y/alt-text
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}/file/${item.logoFileId}`}
                        style={{
                          maxHeight: 38,
                          maxWidth: 120,
                        }}
                      />
                    ) : (
                      <h4 className="mb-0 ml-1 mr-1">{item.abbreviation}</h4>
                    )}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </div>

          <div className="d-flex" style={{ width: 300 }}>
            <Col md="12" className="pr-0">
              <Select
                className="React"
                classNamePrefix="select"
                name="color"
                placeholder="Select team"
                options={dispatchers}
                isClearable={true}
                value={dispatcher}
                onChange={(e) => {
                  if (startDate >= endDate) {
                    let text = 'Start time is greater than end time';
                    if (startDate === endDate) {
                      text = 'Start time is equal to end time';
                    }
                    toast.error(text, {
                      transition: Flip,
                    });
                  } else {
                    toggleTeam(e);
                  }
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary50: '#fe810b',
                    primary25: 'rgb(253, 179, 46)',
                    primary: 'rgb(253, 179, 46)',
                  },
                })}
              />
            </Col>
          </div>
        </div>

        <Row
          style={{
            top: 0,
            bottom: 0,
            paddingLeft: 6,
            paddingRight: 6,
          }}
        >
          <Col md="6" sm="12">
            <FormGroup>
              <Label for="search">Global search</Label>
              <Input
                autoComplete="off"
                type="text"
                placeholder="Search"
                onInput={(e) => {
                  if (startDate >= endDate) {
                    let text = 'Start time is greater than end time';
                    if (startDate === endDate) {
                      text = 'Start time is equal to end time';
                    }
                    toast.error(text, {
                      transition: Flip,
                    });
                  } else {
                    setCurrentEmployerId(companyId);
                    setStart(startDate);
                    setEnd(endDate);
                    setDispatcherId(dispatcher ? dispatcher.value : null);
                    setSearch(e.target.value);
                  }
                }}
                id="search"
              />
            </FormGroup>
          </Col>
          <Col md="3" sm="12">
            <FormGroup>
              <Label for="from">Start</Label>
              <Flatpickr
                id="from"
                className="form-control"
                data-enable-time
                options={{
                  dateFormat: 'Z',
                  altInput: true,
                  altFormat: 'm-d-Y H:i',
                }}
                value={startDate}
                onChange={(val, date, fbo) => {
                  localStorage.setItem('startDate', new Date(val[0]).getTime());

                  setStartDate(new Date(val[0]).getTime());
                  if (new Date(val[0]).getTime() >= endDate) {
                    let text = 'Start time is greater than end time';
                    if (new Date(val[0]).getTime() === endDate) {
                      text = 'Start time is equal to end time';
                    }
                    toast.error(text, {
                      transition: Flip,
                    });
                  } else {
                    localStorage.setItem(
                      'startDate',
                      new Date(val[0]).getTime()
                    );
                    setCurrentEmployerId(companyId);
                    setStart(new Date(val[0]).getTime());
                    setEnd(endDate);
                    setDispatcherId(dispatcher ? dispatcher.value : null);
                    setSearch(document.querySelector('#search').value);
                    setTimeout(() => {
                      setVisibleTimeStart(new Date(val[0]).getTime());
                    }, 1000);

                    // setVisibleTimeEnd(endDate);
                    // setLoading(true);
                  }
                  fbo.close();
                }}
              />
            </FormGroup>
          </Col>
          <Col md="3" sm="12">
            <FormGroup>
              <Label for="lastNameMulti">End</Label>
              <Flatpickr
                id="until"
                className="form-control"
                data-enable-time
                options={{
                  dateFormat: 'Z',
                  altInput: true,
                  altFormat: 'm-d-Y H:i',
                }}
                value={endDate}
                onChange={(val, date, fbo) => {
                  localStorage.setItem('endDate', new Date(val[0]).getTime());
                  setEndDate(new Date(val[0]).getTime());
                  if (startDate >= new Date(val[0]).getTime()) {
                    let text = 'Start time is greater than end time';
                    if (startDate === new Date(val[0]).getTime()) {
                      text = 'Start time is equal to end time';
                    }
                    toast.error(text, {
                      transition: Flip,
                    });
                  } else {
                    // setLoading(true);

                    setCurrentEmployerId(companyId);
                    setStart(startDate);
                    setEnd(new Date(val[0]).getTime());
                    setDispatcherId(dispatcher ? dispatcher.value : null);
                    setSearch(document.querySelector('#search').value);
                    const dated = new Date(val[0]);
                    // add 1 day
                    dated.setDate(dated.getDate() + 1);
                    setTimeout(() => {
                      setVisibleTimeStart(startDate);
                      setVisibleTimeEnd(dated.getTime());
                    }, 1000);
                  }

                  fbo.close();
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        {/* <CardBody> */}

        <div
          style={{
            top: 0,
            paddingLeft: 4,
            overflow: 'auto',
            height: window.innerHeight * 0.72,
          }}
        >
          <Timeline
            groups={groups}
            items={items}
            keys={keys}
            sidebarWidth={150}
            canMove={false}
            canResize={false}
            canSelect
            itemsSorted
            defaultTimeStart={moment().add(-7, 'day')}
            defaultTimeEnd={moment().add(7, 'day')}
            maxZoom={moment.duration(2, 'months').asMilliseconds()}
            minZoom={moment.duration(3, 'days').asMilliseconds()}
            timeSteps={{
              day: 1,
              month: 1,
              year: 1,
              // hour: 1,
            }}
            // minResizeWidth={100}
            itemTouchSendsClick={false}
            stackItems
            itemHeightRatio={0.55}
            visibleTimeStart={visibleTimeStart}
            visibleTimeEnd={visibleTimeEnd}
            onTimeChange={handleTimeChange}
            groupRenderer={groupRenderer}
            itemRenderer={itemRenderer}
          >
            <TimelineHeaders className="sticky">
              <SidebarHeader>
                {({ getRootProps }) => {
                  return (
                    <div
                      {...getRootProps({
                        style: {
                          align: 'center',
                          color: 'white',
                        },
                      })}
                    >
                      &nbsp; &nbsp; Units
                    </div>
                  );
                }}
              </SidebarHeader>
              <DateHeader
                unit="primaryHeader"
                style={{
                  background: 'green',
                  height: '100%',
                }}
              />
              <DateHeader />
            </TimelineHeaders>
          </Timeline>
          {draggedItem && (
            <InfoLabel
              item={draggedItem.item}
              startTime={draggedItem.startTime}
              endTime={draggedItem.endTime}
            />
          )}
        </div>
        {/* </CardBody> */}
      </Card>
    </>
  );
}
