import React, { useRef, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import InfiniteScroll from 'react-infinite-scroll-component';

export default function Chats({ getData, hasMore, chats, getMessages }) {
  const [selectedIndex, setSelectedIndex] = useState(1);

  useEffect(() => {}, []);

  const handleSelect = (u) => {
    // dispatch({ type: "CHANGE_USER", payload: u });
  };

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };

  const getSliceText = (text, len) => {
    if (text.length > len) {
      return text.slice(0, len) + '...';
    }
    return text.slice(0, len);
  };

  return (
    <div style={{ paddingTop: 5 }}>
      <InfiniteScroll
        dataLength={chats.length}
        next={getData}
        hasMore={hasMore}
        loader={<></>}
        height="70vh"
        // endMessage={
        //   // <p style={{ textAlign: 'center' }}>
        //   //   <b>You have seen it all</b>
        //   // </p>
        // }
      >
        <List
          sx={{
            width: '100%',
            overflow: 'auto',
            bgcolor: 'background.paper',
          }}
        >
          {chats.map((item, i) => {
            return (
              <div key={`${i + 1}`}>
                <ListItemButton
                  selected={selectedIndex === i}
                  onClick={(event) => {
                    localStorage.setItem('pageIndex', '0');
                    localStorage.setItem('totalPages', '0');
                    handleListItemClick(i);
                    getMessages(item);
                  }}
                >
                  <ListItemAvatar>
                    <Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      variant="dot"
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: item.isOnline ? '#44b700' : 'red',
                          color: '#44b700',
                          boxShadow: `0 0 0 2px #ffffff`,
                          '&::after': {
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            // animation: 'ripple 1.2s infinite ease-in-out',
                            border: '1px solid currentColor',
                            content: '""',
                          },
                        },
                      }}
                    >
                      <Avatar
                        alt={
                          item.name === null ||
                          item.name === undefined ||
                          item.name === ''
                            ? 'Unknown'
                            : getSliceText(item.name, 15)
                        }
                        src="/static/images/avatar/1.jpg"
                      />
                    </Badge>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      item.name === null ||
                      item.name === undefined ||
                      item.name === ''
                        ? 'Unknown'
                        : getSliceText(item.name, 15)
                    }
                    secondary={
                      <>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {item.text === null ||
                          item.text === undefined ||
                          item.text === ''
                            ? ''
                            : getSliceText(item.text, 40)}
                        </Typography>
                      </>
                    }
                  />
                </ListItemButton>
                <Divider variant="inset" component="li" />
              </div>
            );
          })}
        </List>
      </InfiniteScroll>
    </div>
  );
}
