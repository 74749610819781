import React, { useState } from 'react';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import { toast, Flip } from 'react-toastify';
import { Card, Grid, TextField, Box, Button } from '@mui/material';
import fileUploadService from '../../../../services/file_upload';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function Input({
  status = 'driver',
  userId,
  user,
  sendMessage,
}) {
  const [text, setText] = useState('');
  const [fileList, setFileList] = useState(null);

  const handleSend = async () => {
    sendMessage(text);
    setText('');
  };

  const uploadFile = (formData) => {
    // const formData = new FormData();
    // formData.append('files', file);
    if (formData === undefined) {
      return;
    }
    fileUploadService
      .upload(
        `/file/upload/web/message_file/${status}/${userId}/${user.id}`,
        formData
      )
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const uploadFile2 = async (fileList) => {
    if (!fileList) {
      return;
    }

    const data = new FormData();
    data.append(`files`, fileList);

    await axios.post(
      process.env.REACT_APP_BASE_URL +
        `/file/upload/web/message_file/${status}/${userId}/${user.id}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization:
            'Bearer ' +
            localStorage.getItem(
              localStorage.getItem('current_account') + '_access_token'
            ),
        },
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          // this.setState({
          //     progress,
          // });
          // console.log(progressEvent);
          //   setProgress(progress);
        },
        onDownloadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          console.log(progressEvent);
        },
      }
    );
  };

  return (
    <>
      {user.id !== 0 ? (
        <div style={{ paddingBottom: 5, display: 'flex', gap: 4 }}>
          <Button
            component="label"
            variant="contained"
            startIcon={<AttachFileIcon />}
          >
            Upload
            <VisuallyHiddenInput
              type="file"
              onChange={(e) => {
                console.log(e.target.files);
                setFileList(e.target.files[0]);
                uploadFile2(e.target.files[0]);
              }}
            />
          </Button>
          <TextField
            size="small"
            type="text"
            fullWidth
            multiline
            placeholder="Write a message..."
            onChange={(e) => setText(e.target.value)}
            value={text}
            onKeyUp={(event) => {
              if (event.ctrlKey && event.key === 'Enter') {
                handleSend();
              }
            }}
          />
          <Button
            onClick={handleSend}
            variant="contained"
            endIcon={<SendIcon />}
          >
            Send
          </Button>
        </div>
      ) : null}
    </>
  );
}
