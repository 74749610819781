export function dataSelector(dataResponse, page, size) {
  const filtered = dataResponse.data.map((item, i) => ({
    index: i + 1 + page * size,
    unitNumber: item.number,
    driverOneId: item.driverOneId,
    driverTwoId: item.driverTwoId,
    driverOne: item.driverOne,
    driverOnePhoneNumber: item.driverOnePhoneNumber,
    driverTwo: item.driverTwo,
    driverTwoPhoneNumber: item.driverTwoPhoneNumber,
    typeOfUnit: item.typeOfUnit,
    typeOfDriver: item.typeOfDriver,
    unitStatus: item.unitStatus,
    unitStatusColor: item.unitStatusColor,
    teamColor: item.teamColor,
    driverStatus: item.driverStatus,
    driverStatusColor: item.driverStatusColor,
    from: item.from,
    to: item.to,
    loadNumber: item.loadNumber,
    notes: item.notes,
    endTime: item.endTime,
    loadId: item.loadId,
    truckId: item.truckId,
    unitStatusId: item.unitStatusId,
    calc: item.calc,
    atHomeDate: item.atHomeDate,
    leaveHomeDate: item.leaveHomeDate,
    data: item,
    dataDriverPhoneNumber: item,
    dataUnit: item,
    dataLoad: item,
    dataDriver: item,
    dataNotes: item,
    dataAtHomeDate: item,
    dataLeaveHomeDate: item,
    dataSinceTime: item,
    dataDriverApprove: item,
  }));
  return { total: dataResponse.total_elements, data: filtered };
}
