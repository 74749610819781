/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  FormGroup,
  Input,
  CustomInput,
  Col,
  // Card,
  CardBody,
  // CardHeader,
} from 'reactstrap';
import {
  Box,
  Typography,
  Divider,
  Avatar,
  Grid,
  Paper,
  Card,
  // CardBody,
  CardHeader,
} from '@mui/material';
import { Helmet } from 'react-helmet';
import Checkbox from '@mui/material/Checkbox';
import * as Icon from 'react-feather';
import { connect } from 'react-redux';
import { toast, Flip } from 'react-toastify';
import Flatpickr from 'react-flatpickr';
import { Spin } from 'antd';
import jwtDecode from 'jwt-decode';
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import FolderIcon from '@mui/icons-material/Folder';
import Backdrop from '@mui/material/Backdrop';
import MenuIcon from '@mui/icons-material/Menu';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SaveIcon from '@mui/icons-material/Save';
import { styled } from '@mui/material/styles';
import SweetAlert from '../../../../../components/main/SweetAlert';
// import InfiniteFileUploaderCustom from '../../../../../components/main/infiniteFileUploader/uploaderCustom';
import FolderModal from '../../Modals/FolderModal';
import FolderFilesModal from '../../Modals/FolderFilesModal';
import ShowEditModal from '../../Modals/ShowEditModal';
import fileUploadService from '../../../../../services/file_upload';
import trailerGeneralService from '../../../../../services/trailer_general';
import trailerRepairService from '../../../../../services/trailer_repairs';
import folderService from '../../../../../services/folders';
import RepairFormNew from '../RepairFormNew';
import RepairFormEdit from '../RepairFormEdit';
import ModalWindow from '../../../../../components/main/Modals';
import Loading from '../../../../../components/main/Loading';

const columns = [
  { id: 'repairId', label: '', maxWidth: 0 },
  { id: 'number', label: '№', minWidth: 70 },
  {
    id: 'issueCategory',
    label: 'Issue category',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'date',
    label: 'Date',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'username',
    label: 'User',
    minWidth: 170,
    align: 'right',
  },
  {
    id: 'amount',
    label: 'Amount',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'paymentMethod',
    label: 'Payment method',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#4d4d4d',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function EditTrailer() {
  const navigate = useNavigate();
  const [statusTrailer, setStatusTrailer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newFiles, setNewFiles] = useState([]);
  const [prevFiles, setPrevFiles] = useState([]);
  const [fileId, setFileId] = useState();
  const [fileIds, setFileIds] = useState(new Map());
  const [currentFile, setCurrentFile] = useState(0);
  const [filesUpload, setFilesUpload] = useState([]);
  const [comments, setComments] = useState([]);
  const [purchaseDate, setPurchaseDate] = useState();
  const [saleDate, setSaleDate] = useState();
  const [subscriptionStartDate, setSubscriptionStartDate] = useState();
  const [inServiceOnDate, setInServiceOnDate] = useState();
  const [nextInspectionOnDate, setNextInspectionOnDate] = useState();
  const [tagsExpireOnDate, setTagsExpireOnDate] = useState();
  const [daysLeft, setDaysLeft] = useState();
  const [purchaseDatePriceInput, setPurchaseDatePriceInput] = useState('');
  const [saleDatePriceInput, setSaleDatePriceInput] = useState('');

  const [showEditModal, setShowEditModal] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [deleteRepairStatus, setDeleteRepairStatus] = useState(false);
  const [deleteRepairId, setDeleteRepairId] = useState();
  const [deleteItem, setDeleteItem] = useState('');
  const [commentIndex, setCommentIndex] = useState();
  const [itemEdit, setItemEdit] = useState({});
  const [progress, setProgress] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showRepairFormNewModal, setShowRepairFormNewModal] = useState(false);
  const [showRepairFormEditModal, setShowRepairFormEditModal] = useState(false);
  const [repairId, setRepairId] = useState();
  const [repairs, setRepairs] = useState([]);
  const [folders, setFolders] = useState([]);
  const [folderModal, setFolderModal] = useState(false);
  const [showFolderFilesModal, setShowFolderFilesModal] = useState(false);

  const [folderId, setFolderId] = useState();
  const [folderItem, setFolderItem] = useState();
  const [folderName, setFolderName] = useState();

  const userId = localStorage.getItem('userId');

  const token = localStorage.getItem(
    localStorage.getItem('current_account') + '_access_token'
  );
  const decodedToken = jwtDecode(token);
  const username = decodedToken.preferred_username.split('_')[2];

  const { id } = useParams();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const actions = [{ icon: <SaveIcon />, name: 'Save' }];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(+event.target.value);
  };

  const toggleRepairFormNew = () => {
    setShowRepairFormNewModal(!showRepairFormNewModal);
  };

  const toggleRepairFormEdit = () => {
    setShowRepairFormEditModal(!showRepairFormEditModal);
  };

  const toggleEditModal = () => {
    setShowEditModal(!showEditModal);
  };

  const toggleFolderModal = () => {
    setFolderModal(!folderModal);
  };

  const toggleFolderFilesModal = () => {
    setShowFolderFilesModal(!showFolderFilesModal);
  };

  const newFile = () => {
    const newFilesData = newFiles;
    const currentFileData = currentFile;
    newFilesData.push(currentFileData);
    setNewFiles(newFilesData);
    setCurrentFile(currentFileData + 1);
  };

  const deleteFile = (item) => {
    const newFilesData = newFiles;
    const fileIdsData = fileIds;
    fileIdsData.delete(item);
    setFileIds(fileIdsData);

    const index = newFilesData.indexOf(item);
    if (index > -1) {
      newFilesData.splice(index, 1);
    }
    setNewFiles(newFilesData);
  };

  const deletePrevFile = (item) => {
    const element = filesUpload[item];
    const arr = filesUpload.filter(
      (item) => item.originalFileName !== element.originalFileName
    );
    setFilesUpload(arr);

    const prevFilesData = prevFiles;
    const fileIdsData = fileIds;
    fileIdsData.delete(item);
    setFileIds(fileIdsData);

    const obj = prevFilesData.find((el) => el.id === item);
    const index = prevFilesData.indexOf(obj);
    if (index > -1) {
      prevFilesData.splice(index, 1);
    }
    setPrevFiles(prevFilesData);
    setDeleteAlert(false);
  };

  const uploadFile = async (file, item) => {
    const formData = new FormData();
    formData.append('file', file);
    if (file === undefined) {
      return;
    }
    await axios.post(
      process.env.REACT_APP_BASE_URL + '/file/upload/trailer',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization:
            'Bearer ' +
            localStorage.getItem(
              localStorage.getItem('current_account') + '_access_token'
            ),
        },
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          setProgress(progress);

          console.log(progress);
          //   setProgress(progress);
        },
        onDownloadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          console.log(progress);
          // setState({
          //     progress,
          // });
          //   setProgress(progress);
        },
      }
    );
  };

  const getComments = () => {
    trailerGeneralService.getComments(`trailerId=${id}`).then((data) => {
      if (data !== null) {
        // const reversed = data.slice().reverse();
        data.sort((a, b) => b.publishDate - a.publishDate);
        setComments(data);
      }
    });
  };

  const getFolders = (id) => {
    folderService.getAll(`trailerId=${id}`).then((data) => {
      if (data !== null) {
        setFolders(data.data);
      }
    });
  };

  const getRepairs = (id) => {
    trailerRepairService.getAllByTrailerId(`trailerId=${id}`).then((data) => {
      if (data !== null) {
        setRepairs(data.data);
      }
    });
  };

  const componentDidMount = () => {
    const token = localStorage.getItem(
      localStorage.getItem('current_account') + '_access_token'
    );
    const decodedToken2 = jwtDecode(token);

    setLoading(true);
    trailerGeneralService.getOne(id).then((data) => {
      setLoading(false);
      document.querySelector('#trailer_number').value = data.trailerNumber
        ? data.trailerNumber.toUpperCase()
        : '';
      document.querySelector('#trailer_type').value = data.type
        ? data.type.toUpperCase()
        : '';
      document.querySelector('#make').value = data.make
        ? data.make.toUpperCase()
        : '';
      document.querySelector('#make_year').value = data.makeYear
        ? data.makeYear.toUpperCase()
        : '';
      document.querySelector('#model').value = data.model
        ? data.model.toUpperCase()
        : '';
      document.querySelector('#vin').value = data.vin
        ? data.vin.toUpperCase()
        : '';
      document.querySelector('#title_number').value = data.titleNumber
        ? data.titleNumber.toUpperCase()
        : '';
      document.querySelector('#license_plate').value = data.licensePlate
        ? data.licensePlate.toUpperCase()
        : '';

      setPurchaseDate(data.purchaseDate);
      setPurchaseDatePriceInput(data.purchaseDatePrice);
      setSaleDatePriceInput(data.saleDatePrice);
      setSaleDate(data.saleDate);
      setStatusTrailer(data.status);

      setTagsExpireOnDate(data.tagsExpireOn);
      setInServiceOnDate(data.inServiceOn);
      setNextInspectionOnDate(data.nextInspectionOn);
      setDaysLeft(data.daysLeft);

      document.querySelector('#financed_by').value = data.financedBy
        ? data.financedBy.toUpperCase()
        : '';
      document.querySelector('#leasing_company').value = data.leasingCompany
        ? data.leasingCompany.toUpperCase()
        : '';
      document.querySelector('#lease_reference').value = data.leaseReference
        ? data.leaseReference.toUpperCase()
        : '';

      document.querySelector('#owned_by').value = data.ownedBy
        ? data.ownedBy.toUpperCase()
        : '';
      // reefer unit

      document.querySelector('#brand').value = data.brand
        ? data.brand.toUpperCase()
        : '';
      document.querySelector('#year').value = data.year
        ? data.year.toUpperCase()
        : '';
      document.querySelector('#reefer_unit_type').value = data.reeferUnitType
        ? data.reeferUnitType.toUpperCase()
        : '';
      document.querySelector('#serial_number').value = data.serialNumber
        ? data.serialNumber.toUpperCase()
        : '';

      // tracking information

      document.querySelector('#tracking_type').value = data.trackingType
        ? data.trackingType.toUpperCase()
        : '';
      document.querySelector('#unit_serial_number').value =
        data.unitSerialNumber ? data.unitSerialNumber.toUpperCase() : '';
      setSubscriptionStartDate(data.subscriptionStartDate);

      // options
      document.querySelector('#color').value = data.color
        ? data.color.toUpperCase()
        : '';
      document.querySelector('#roof_material').value = data.roofMaterial
        ? data.roofMaterial.toUpperCase()
        : '';
      document.querySelector('#wall_material').value = data.wallMaterial
        ? data.wallMaterial.toUpperCase()
        : '';
      document.querySelector('#floor_material').value = data.floorMaterial
        ? data.floorMaterial.toUpperCase()
        : '';
      document.querySelector('#wheel_types').value = data.wheelTypes
        ? data.wheelTypes.toUpperCase()
        : '';
      document.querySelector('#wheel_seal_type').value = data.wheelSealType
        ? data.wheelSealType.toUpperCase()
        : '';
      document.querySelector('#inflation_system').value = data.inflationSystem
        ? data.inflationSystem.toUpperCase()
        : '';
      document.querySelector('#tire_size').value = data.tireSize
        ? data.tireSize.toUpperCase()
        : '';
      document.querySelector('#wheel_mount_type').value = data.wheelMountType
        ? data.wheelMountType.toUpperCase()
        : '';
      document.querySelector('#door_type').value = data.doorType
        ? data.doorType.toUpperCase()
        : '';
      document.querySelector('#slider_type').value = data.sliderType
        ? data.sliderType.toUpperCase()
        : '';
      document.querySelector('#suspension_type').value = data.suspensionType
        ? data.suspensionType.toUpperCase()
        : '';
      document.querySelector('#suspension_model').value = data.suspensionModel
        ? data.suspensionModel.toUpperCase()
        : '';
      document.querySelector('#suspension_serial').value = data.suspensionSerial
        ? data.suspensionSerial.toUpperCase()
        : '';
      document.querySelector('#lights_type').value = data.lightsType
        ? data.lightsType.toUpperCase()
        : '';

      if (data.comments !== null) {
        // const reversed = data.comments.slice().reverse();
        data.comments.sort((a, b) => b.publishDate - a.publishDate);
        setComments(data.comments);
      }
      // console.log(data);
      // // if (data.filesUpload !== null) {
      // //   const fileIdsData = new Map();
      // //   const prevFilesData = [];
      // //   data.filesUpload.forEach((itemt, key) => {
      // //     prevFilesData.push({
      // //       id: parseInt(key, 10),
      // //       name: data.filesUpload[key].originalFileName,
      // //       userId: data.filesUpload[key].uploadedById,
      // //       username: data.filesUpload[key].username,
      // //       date: data.filesUpload[key].uploadedAt,
      // //     });
      // //     fileIdsData.set(
      // //       parseInt(key, 10),
      // //       data.filesUpload[key].originalFileName
      // //     );
      // //   });
      // //   setFileIds(fileIdsData);
      // //   setPrevFiles(prevFilesData);
      // //   setFilesUpload(data.filesUpload);
      // // }
    });

    getFolders(id);
    getRepairs(id);
  };

  const sendComment = (text) => {
    const data = {
      index: comments.length + 1,
      trailerId: id,
      userId,
      comment: text,
    };
    trailerGeneralService.addComment(data).then((res) => {
      if (res === 'updated successfully') {
        toast.success('Comment successfuly added', {
          transition: Flip,
        });
        document.querySelector('#notes').value = '';
        getComments();
      } else {
        toast.error('Something went wrong', { transition: Flip });
        // res.text();
      }
    });
  };

  const editComment = (text, indexValue) => {
    const data = {
      index: indexValue,
      trailerId: id,
      userId,
      comment: text,
    };
    trailerGeneralService.editComment(data).then((res) => {
      if (res === 'updated successfully') {
        toast.success('Comment successfuly edit', {
          transition: Flip,
        });
        document.querySelector('#notes').value = '';
        getComments();
      } else {
        toast.error('Something went wrong', { transition: Flip });
        // res.text();
      }
    });
  };

  const deleteRepair = (id) => {
    trailerRepairService.delete(`${id}`).then((data) => {
      getRepairs(id);
      setDeleteRepairStatus(false);
      setDeleteRepairId(null);
    });
  };

  const addFolder = (text) => {
    const data = {
      trailerId: id,
      userId,
      folderName: text,
    };
    folderService.create(data).then((res) => {
      if (res) {
        toast.success('Folder successfuly added', {
          transition: Flip,
        });
        getFolders(id);
      } else {
        toast.error('Something went wrong', { transition: Flip });
        // res.text();
      }
    });
  };

  const editFolder = (text) => {
    folderItem.folderName = text;
    folderService.update(folderItem).then((res) => {
      if (res) {
        toast.success('Folder successfuly edited', {
          transition: Flip,
        });
        getFolders(id);
      } else {
        toast.error('Something went wrong', { transition: Flip });
        // res.text();
      }
    });
  };

  const editTrailer = () => {
    if (document.querySelector('#trailer_number').value === '') {
      toast.error('Enter trailer number', { transition: Flip });
    } else {
      const dataGeneral = {
        id,
        trailerNumber: document.querySelector('#trailer_number').value,
        status: statusTrailer,
        type: document.querySelector('#trailer_type').value,
        make: document.querySelector('#make').value,
        makeYear: document.querySelector('#make_year').value,
        model: document.querySelector('#model').value,
        vin: document.querySelector('#vin').value,
        titleNumber: document.querySelector('#title_number').value,
        licensePlate: document.querySelector('#license_plate').value,
        purchaseDate,
        saleDate,
        purchaseDatePrice: document.querySelector('#purchase_date_price').value,
        saleDatePrice: document.querySelector('#sale_date_price').value,
        financedBy: document.querySelector('#financed_by').value,
        leasingCompany: document.querySelector('#leasing_company').value,
        leaseReference: document.querySelector('#lease_reference').value,
        inServiceOn: inServiceOnDate,
        tagsExpireOn: tagsExpireOnDate,
        nextInspectionOn: nextInspectionOnDate,
        daysLeft,
        filesUpload,
        comments,
        ownedBy: document.querySelector('#owned_by').value,

        brand: document.querySelector('#brand').value,
        year: document.querySelector('#year').value,
        reeferUnitType: document.querySelector('#reefer_unit_type').value,
        serialNumber: document.querySelector('#serial_number').value,

        trackingType: document.querySelector('#tracking_type').value,
        unitSerialNumber: document.querySelector('#unit_serial_number').value,
        subscriptionStartDate,

        color: document.querySelector('#color').value,
        roofMaterial: document.querySelector('#roof_material').value,
        wallMaterial: document.querySelector('#wall_material').value,
        floorMaterial: document.querySelector('#floor_material').value,
        wheelTypes: document.querySelector('#wheel_types').value,
        wheelSealType: document.querySelector('#wheel_seal_type').value,
        inflationSystem: document.querySelector('#inflation_system').value,
        tireSize: document.querySelector('#tire_size').value,
        wheelMountType: document.querySelector('#wheel_mount_type').value,
        doorType: document.querySelector('#door_type').value,
        sliderType: document.querySelector('#slider_type').value,
        suspensionType: document.querySelector('#suspension_type').value,
        suspensionModel: document.querySelector('#suspension_model').value,
        suspensionSerial: document.querySelector('#suspension_serial').value,
        lightsType: document.querySelector('#lights_type').value,
      };

      trailerGeneralService.update(dataGeneral).then((res) => {
        if (res) {
          toast.success('Trailer successfuly edited', {
            transition: Flip,
          });
          navigate('/trailers/list');
        } else {
          toast.error('Something went wrong', { transition: Flip });
          // res.text();
        }
      });
    }
  };

  const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const deleteComment = (index) => {
    trailerGeneralService.deleteComment(`${index}/${id}`).then((res) => {
      getComments();
    });
    setDeleteAlert(false);
    setCommentIndex(null);
  };

  const getDateString = (date) => {
    return (
      (new Date(date).getMonth() + 1 < 10
        ? '0' + parseInt(new Date(date).getMonth() + 1, 10)
        : parseInt(new Date(date).getMonth() + 1, 10)) +
      '/' +
      (new Date(date).getDate() < 10
        ? '0' + new Date(date).getDate()
        : new Date(date).getDate()) +
      '/' +
      new Date(date).getFullYear() +
      ' ' +
      (new Date(date).getHours() < 10
        ? '0' + new Date(date).getHours()
        : new Date(date).getHours()) +
      ':' +
      (new Date(date).getMinutes() < 10
        ? '0' + new Date(date).getMinutes()
        : new Date(date).getMinutes())
    );
  };

  const checkAllow = (role) => {
    if (role === 'ROLE_ADMIN' || role === 'SUPER_ADMIN') {
      return true;
    }
    return false;
  };

  useEffect(() => {
    componentDidMount();
  }, []);

  return (
    <>
      <Helmet>
        <title> Trailers </title>
      </Helmet>
      <Loading loading={loading} />
      <ModalWindow
        title="Repair new modal"
        modal={showRepairFormNewModal}
        handleModal={toggleRepairFormNew}
        maxWidth="md"
        body={
          <RepairFormNew
            modal={showRepairFormNewModal}
            toggle={toggleRepairFormNew}
            trailerId={id}
            userId={userId}
            getRepairs={getRepairs}
          />
        }
      />
      <ModalWindow
        title="Repair edit modal"
        modal={showRepairFormEditModal}
        handleModal={toggleRepairFormEdit}
        maxWidth="md"
        body={
          <RepairFormEdit
            repairId={repairId}
            modal={showRepairFormEditModal}
            toggle={toggleRepairFormEdit}
            trailerId={id}
            getRepairs={getRepairs}
          />
        }
      />
      <ModalWindow
        title="Folder edit modal"
        modal={showEditModal}
        handleModal={toggleEditModal}
        maxWidth="sm"
        body={
          <ShowEditModal
            modal={showEditModal}
            toggleEditModal={toggleEditModal}
            editComment={editComment}
            item={itemEdit}
          />
        }
      />
      <ModalWindow
        title="Folder new modal"
        modal={folderModal}
        handleModal={toggleFolderModal}
        maxWidth="sm"
        body={
          <FolderModal
            modal={folderModal}
            toggle={toggleFolderModal}
            addFolder={addFolder}
            editFolder={editFolder}
            name={folderName}
          />
        }
      />
      <ModalWindow
        title="Folder files modal"
        modal={showFolderFilesModal}
        handleModal={toggleFolderFilesModal}
        maxWidth="md"
        body={
          <FolderFilesModal
            modal={showFolderFilesModal}
            toggle={toggleFolderFilesModal}
            item={folderItem}
          />
        }
      />
      <SweetAlert
        title="Are you sure?"
        warning
        show={deleteAlert}
        showCancel
        reverseButtons
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        onConfirm={() => {
          if (deleteItem === 'files') {
            folderService.delete(folderId).then((data) => {
              setDeleteAlert(false);
              getFolders(id);
            });
          } else {
            deleteComment(commentIndex);
          }
        }}
        onCancel={() => setDeleteAlert(false)}
      >
        You won`t be able to revert this!
      </SweetAlert>
      <SweetAlert
        title="Are you sure?"
        warning
        show={deleteRepairStatus}
        showCancel
        reverseButtons
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        onConfirm={() => {
          deleteRepair(deleteRepairId);
        }}
        onCancel={() => setDeleteRepairStatus(false)}
      >
        You won`t be able to revert this!
      </SweetAlert>
      <div style={{ paddingBottom: 10 }}>
        <Card>
          <CardHeader title="Editing Trailer" />
          <CardBody>
            <>
              <Form className="d-flex">
                <div
                  style={{
                    width: '50%',
                    marginRight: 20,
                  }}
                >
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Activate/Deactivate</span>
                    </Col>
                    <Col md="8">
                      <Checkbox
                        checked={statusTrailer}
                        onChange={(e) => {
                          setStatusTrailer(!statusTrailer);
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Trailer number</span>
                      <span style={{ color: 'red' }}> *</span>
                    </Col>
                    <Col md="8">
                      <Input
                        type="text"
                        id="trailer_number"
                        onChange={(e) => {
                          document.querySelector('#trailer_number').value =
                            e.target.value.toUpperCase();
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Type</span>
                    </Col>
                    <Col md="8">
                      <Input
                        type="text"
                        id="trailer_type"
                        onChange={(e) => {
                          document.querySelector('#trailer_type').value =
                            e.target.value.toUpperCase();
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Make</span>
                    </Col>
                    <Col md="8">
                      <Input
                        type="text"
                        id="make"
                        onChange={(e) => {
                          document.querySelector('#make').value =
                            e.target.value.toUpperCase();
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Make year</span>
                    </Col>
                    <Col md="8">
                      <Input
                        type="text"
                        id="make_year"
                        onChange={(e) => {
                          document.querySelector('#make_year').value =
                            e.target.value.toUpperCase();
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Model</span>
                    </Col>
                    <Col md="8">
                      <Input
                        type="text"
                        id="model"
                        onChange={(e) => {
                          document.querySelector('#model').value =
                            e.target.value.toUpperCase();
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>VIN</span>
                    </Col>
                    <Col md="8">
                      <Input
                        type="text"
                        id="vin"
                        onChange={(e) => {
                          document.querySelector('#vin').value =
                            e.target.value.toUpperCase();
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Title number</span>
                    </Col>
                    <Col md="8">
                      <Input
                        type="text"
                        id="title_number"
                        onChange={(e) => {
                          document.querySelector('#title_number').value =
                            e.target.value.toUpperCase();
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>License plate</span>
                    </Col>
                    <Col md="8">
                      <Input
                        type="text"
                        id="license_plate"
                        onChange={(e) => {
                          document.querySelector('#license_plate').value =
                            e.target.value.toUpperCase();
                        }}
                      />
                    </Col>
                  </FormGroup>
                </div>
                <div
                  style={{
                    width: '50%',
                    marginRight: 20,
                  }}
                >
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Purchase date /price</span>
                    </Col>
                    <Col md="4">
                      <Flatpickr
                        id="from"
                        className="form-control"
                        data-enable-time
                        options={{
                          dateFormat: 'Z',
                          altInput: true,
                          altFormat: 'm-d-Y H:i',
                        }}
                        value={purchaseDate}
                        onChange={(val, date, fbo) => {
                          setPurchaseDate(new Date(val[0]).getTime());
                          // fbo.close();
                        }}
                      />
                    </Col>
                    <Col md="4">
                      <Input
                        autoComplete="off"
                        value={purchaseDatePriceInput || ''}
                        type="text"
                        id="purchase_date_price"
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === '' ||
                            re.test(e.target.value)
                          ) {
                            setPurchaseDatePriceInput(e.target.value);
                          }
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Sale date /price</span>
                    </Col>
                    <Col md="4">
                      <Flatpickr
                        id="from"
                        className="form-control"
                        data-enable-time
                        options={{
                          dateFormat: 'Z',
                          altInput: true,
                          altFormat: 'm-d-Y H:i',
                        }}
                        value={saleDate}
                        onChange={(val, date, fbo) => {
                          setSaleDate(new Date(val[0]).getTime());
                          // fbo.close();
                        }}
                      />
                    </Col>
                    <Col md="4">
                      <Input
                        autoComplete="off"
                        value={saleDatePriceInput || ''}
                        type="text"
                        id="sale_date_price"
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === '' ||
                            re.test(e.target.value)
                          ) {
                            setSaleDatePriceInput(e.target.value);
                          }
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Financed by</span>
                    </Col>
                    <Col md="8">
                      <Input
                        type="text"
                        id="financed_by"
                        onChange={(e) => {
                          document.querySelector('#financed_by').value =
                            e.target.value.toUpperCase();
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Leasing company</span>
                    </Col>
                    <Col md="8">
                      <Input
                        type="text"
                        id="leasing_company"
                        onChange={(e) => {
                          document.querySelector('#leasing_company').value =
                            e.target.value.toUpperCase();
                        }}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Lease reference</span>
                    </Col>
                    <Col md="8">
                      <Input
                        type="text"
                        id="lease_reference"
                        onChange={(e) => {
                          document.querySelector('#lease_reference').value =
                            e.target.value.toUpperCase();
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>In service on</span>
                    </Col>
                    <Col md="8">
                      <Flatpickr
                        id="from"
                        className="form-control"
                        data-enable-time
                        options={{
                          dateFormat: 'Z',
                          altInput: true,
                          altFormat: 'm-d-Y H:i',
                        }}
                        value={inServiceOnDate}
                        onChange={(val, date, fbo) => {
                          setInServiceOnDate(new Date(val[0]).getTime());
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Tags expire on</span>
                    </Col>
                    <Col md="8">
                      <Flatpickr
                        id="from"
                        className="form-control"
                        data-enable-time
                        options={{
                          dateFormat: 'Z',
                          altInput: true,
                          altFormat: 'm-d-Y H:i',
                        }}
                        value={tagsExpireOnDate}
                        onChange={(val, date, fbo) => {
                          setTagsExpireOnDate(new Date(val[0]).getTime());
                          // fbo.close();
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Next inspection on</span>
                    </Col>
                    <Col md="8">
                      <Flatpickr
                        id="from"
                        className="form-control"
                        data-enable-time
                        options={{
                          dateFormat: 'Z',
                          altInput: true,
                          altFormat: 'm-d-Y H:i',
                        }}
                        value={nextInspectionOnDate}
                        onChange={(val, date, fbo) => {
                          setNextInspectionOnDate(new Date(val[0]).getTime());
                          // fbo.close();
                        }}
                      />
                    </Col>
                  </FormGroup>
                  {/* <FormGroup
                                            className="align-items-center"
                                            row
                                        >
                                            <Col md="4">
                                                <span>Days Left</span>
                                            </Col>
                                            <Col md="8">
                                                <Flatpickr
                                                    id="from"
                                                    className="form-control"
                                                    data-enable-time
                                                    options={{
                                                        dateFormat: "Z",
                                                        altInput: true,
                                                        altFormat: "m-d-Y H:i",
                                                    }}
                                                    value={daysLeft}
                                                    onChange={(
                                                        val,
                                                        date,
                                                        fbo
                                                    ) => {
                                                        setState({
                                                            daysLeft: new Date(
                                                                val[0]
                                                            ).getTime(),
                                                        });
                                                        // fbo.close();
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup> */}
                </div>
              </Form>
            </>
          </CardBody>
        </Card>
      </div>
      <div style={{ paddingBottom: 10 }}>
        <Card>
          <CardHeader>
            <h3 className="mb-0">Editing Ownership</h3>
          </CardHeader>
          <CardBody>
            <Form className="d-flex">
              <div style={{ width: '50%', marginRight: 20 }}>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Owned by</span>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      id="owned_by"
                      onChange={(e) => {
                        document.querySelector('#owned_by').value =
                          e.target.value.toUpperCase();
                      }}
                    />
                  </Col>
                </FormGroup>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
      <div style={{ paddingBottom: 10 }}>
        <Card>
          <CardHeader
            action={
              <Button
                color="primary"
                size="sm"
                className="d-flex align-items-center"
                type="button"
                onClick={() => {
                  toggleRepairFormNew();
                }}
              >
                <Icon.Plus size={20} /> &nbsp; Add new case
              </Button>
            }
            title="Editing Tracking Information"
          />

          <CardBody>
            <Form className="d-flex">
              <div style={{ width: '50%', marginRight: 20 }}>
                <h4>Main</h4>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Tracking type</span>
                  </Col>
                  <Col md="8">
                    <Input type="text" id="tracking_type" />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Unit serial number</span>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      id="unit_serial_number"
                      onChange={(e) => {
                        document.querySelector('#unit_serial_number').value =
                          e.target.value.toUpperCase();
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Subscription start date</span>
                  </Col>
                  <Col md="8">
                    <Flatpickr
                      id="from"
                      className="form-control"
                      data-enable-time
                      options={{
                        dateFormat: 'Z',
                        altInput: true,
                        altFormat: 'm-d-Y H:i',
                      }}
                      value={subscriptionStartDate}
                      onChange={(val, date, fbo) => {
                        setSubscriptionStartDate(new Date(val[0]).getTime());
                        // fbo.close();
                      }}
                    />
                  </Col>
                </FormGroup>
              </div>
              <div style={{ width: '50%', marginRight: 20 }}>
                <h4>Repairs</h4>
                <Paper
                  sx={{
                    width: '100%',
                    overflow: 'hidden',
                  }}
                >
                  <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) =>
                            column.id !== 'repairId' ? (
                              <StyledTableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  minWidth: column.minWidth,
                                }}
                              >
                                {column.label}
                              </StyledTableCell>
                            ) : null
                          )}
                          <StyledTableCell key="actions" align="right">
                            Actions
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {repairs
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, i) => {
                            return (
                              <StyledTableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={`${i + 1}`}
                              >
                                {columns.map((column) => {
                                  if (column.id !== 'repairId') {
                                    let value = row[column.id];
                                    if (column.id === 'number') {
                                      value = i + 1;
                                    }
                                    if (column.id === 'date') {
                                      value = getDateString(row[column.id]);
                                    }
                                    return (
                                      <StyledTableCell
                                        key={column.id}
                                        // align={
                                        //     column.align
                                        // }
                                        style={{
                                          cursor: 'pointer',
                                        }}
                                        onDoubleClick={() => {
                                          setRepairId(row.id);
                                          toggleRepairFormEdit();
                                        }}
                                      >
                                        {column.format &&
                                        typeof value === 'number'
                                          ? column.format(value) + ' $'
                                          : value}
                                      </StyledTableCell>
                                    );
                                  }
                                })}
                                <StyledTableCell
                                  key={`${i + 1}`}
                                  // align={
                                  //     column.align
                                  // }
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                >
                                  <>
                                    <Grid container wrap="nowrap" spacing={2}>
                                      <Grid item>
                                        <Button
                                          size="sm"
                                          outline
                                          color="primary"
                                          className="d-flex align-items-center"
                                          type="button"
                                          onClick={() => {
                                            setRepairId(row.id);
                                            toggleRepairFormEdit();
                                          }}
                                        >
                                          <Icon.Edit size={16} />
                                        </Button>
                                      </Grid>
                                      {row.username === username ? (
                                        <Grid item>
                                          <Button
                                            size="sm"
                                            color="danger"
                                            className="d-flex align-items-center"
                                            type="button"
                                            onClick={() => {
                                              setDeleteRepairStatus(true);
                                              setDeleteRepairId(row.id);
                                            }}
                                          >
                                            <Icon.Trash size={16} />
                                          </Button>
                                        </Grid>
                                      ) : null}
                                    </Grid>
                                  </>
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={repairs.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
      <div style={{ paddingBottom: 10 }}>
        <Card>
          <CardHeader title="Editing Reefer Unit" />

          <CardBody>
            <Form className="d-flex">
              <div style={{ width: '50%', marginRight: 20 }}>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Brand</span>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      id="brand"
                      onChange={(e) => {
                        document.querySelector('#brand').value =
                          e.target.value.toUpperCase();
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Year</span>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      id="year"
                      onChange={(e) => {
                        document.querySelector('#year').value =
                          e.target.value.toUpperCase();
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Type</span>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      id="reefer_unit_type"
                      onChange={(e) => {
                        document.querySelector('#reefer_unit_type').value =
                          e.target.value.toUpperCase();
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Serial number</span>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      id="serial_number"
                      onChange={(e) => {
                        document.querySelector('#serial_number').value =
                          e.target.value.toUpperCase();
                      }}
                    />
                  </Col>
                </FormGroup>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
      <div style={{ paddingBottom: 10 }}>
        <Card>
          <CardHeader title="Editing Trailer Options" />
          <CardBody>
            <Form className="d-flex">
              <div style={{ width: '50%', marginRight: 20 }}>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Color</span>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      id="color"
                      onChange={(e) => {
                        document.querySelector('#color').value =
                          e.target.value.toUpperCase();
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Roof material</span>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      id="roof_material"
                      onChange={(e) => {
                        document.querySelector('#roof_material').value =
                          e.target.value.toUpperCase();
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Wall material</span>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      id="wall_material"
                      onChange={(e) => {
                        document.querySelector('#wall_material').value =
                          e.target.value.toUpperCase();
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Floor material</span>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      id="floor_material"
                      onChange={(e) => {
                        document.querySelector('#floor_material').value =
                          e.target.value.toUpperCase();
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Wheel types</span>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      id="wheel_types"
                      onChange={(e) => {
                        document.querySelector('#wheel_types').value =
                          e.target.value.toUpperCase();
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Wheel seal type</span>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      id="wheel_seal_type"
                      onChange={(e) => {
                        document.querySelector('#wheel_seal_type').value =
                          e.target.value.toUpperCase();
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Inflation system</span>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      id="inflation_system"
                      onChange={(e) => {
                        document.querySelector('#inflation_system').value =
                          e.target.value.toUpperCase();
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Tire size </span>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      id="tire_size"
                      onChange={(e) => {
                        document.querySelector('#tire_size').value =
                          e.target.value.toUpperCase();
                      }}
                    />
                  </Col>
                </FormGroup>
              </div>
              <div style={{ width: '50%', marginRight: 20 }}>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Wheel mount type</span>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      id="wheel_mount_type"
                      onChange={(e) => {
                        document.querySelector('#wheel_mount_type').value =
                          e.target.value.toUpperCase();
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Door type</span>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      id="door_type"
                      onChange={(e) => {
                        document.querySelector('#door_type').value =
                          e.target.value.toUpperCase();
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Slider type</span>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      id="slider_type"
                      onChange={(e) => {
                        document.querySelector('#slider_type').value =
                          e.target.value.toUpperCase();
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Suspension type</span>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      id="suspension_type"
                      onChange={(e) => {
                        document.querySelector('#suspension_type').value =
                          e.target.value.toUpperCase();
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Suspension model</span>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      id="suspension_model"
                      onChange={(e) => {
                        document.querySelector('#suspension_model').value =
                          e.target.value.toUpperCase();
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Suspension serial</span>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      id="suspension_serial"
                      onChange={(e) => {
                        document.querySelector('#suspension_serial').value =
                          e.target.value.toUpperCase();
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Lights type</span>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      id="lights_type"
                      onChange={(e) => {
                        document.querySelector('#lights_type').value =
                          e.target.value.toUpperCase();
                      }}
                    />
                  </Col>
                </FormGroup>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
      <Card>
        {/* <CardHeader>
                        <h3 className="mb-0">Comments</h3>
                    </CardHeader> */}
        <CardBody>
          <Form className="d-flex">
            <div style={{ width: '50%', marginRight: 20 }}>
              <h4>Upload Files</h4>

              <Button
                size="sm"
                color="primary"
                className="d-flex align-items-center"
                type="button"
                onClick={() => {
                  setFolderName(null);
                  setFolderModal(true);
                }}
              >
                <Icon.Plus size={20} /> &nbsp; Add folder
              </Button>
              <div
                style={{
                  overflowY: 'scroll',
                  height: '520px',
                  padding: '3px',
                }}
              >
                {folders.map((item, i) => (
                  <Paper
                    key={`${i + 1}`}
                    style={{
                      padding: '40px 20px',
                      marginTop: 10,
                      cursor: 'pointer',
                    }}
                    elevation={6}
                    onDoubleClick={() => {
                      console.log(item);
                      setFolderItem(item);
                      setShowFolderFilesModal(!showFolderFilesModal);
                    }}
                  >
                    <Grid container wrap="nowrap" spacing={2}>
                      <Grid item>
                        <FolderIcon size="large" color="primary" />
                      </Grid>
                      <Grid justifyContent="left" item xs zeroMinWidth>
                        <h3
                          style={{
                            margin: 0,
                            textAlign: 'left',
                          }}
                        >
                          {item.folderName}{' '}
                          {item.filesUpload
                            ? '(' + item.filesUpload.length + ')'
                            : '(0)'}
                        </h3>
                        <h6
                          style={{
                            margin: 0,
                            textAlign: 'left',
                          }}
                        >
                          {item.username}
                        </h6>
                        <p
                          style={{
                            textAlign: 'left',
                            color: 'gray',
                          }}
                        >
                          {getDateString(item.publishDate)}
                        </p>
                      </Grid>
                      {decodedToken.preferred_username.split('_')[2] ===
                      item.username ? (
                        <>
                          <Grid item>
                            <Button
                              size="sm"
                              outline
                              color="primary"
                              className="d-flex align-items-center"
                              type="button"
                              onClick={() => {
                                setFolderName(item.folderName);
                                setFolderId(item.id);
                                setFolderItem(item);
                                toggleFolderModal();
                              }}
                            >
                              <Icon.Edit size={16} />
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              size="sm"
                              color="danger"
                              className="d-flex align-items-center"
                              type="button"
                              onClick={() => {
                                setFolderId(item.id);
                                setDeleteItem('files');
                                setDeleteAlert(true);
                              }}
                            >
                              <Icon.Trash size={16} />
                            </Button>
                          </Grid>
                        </>
                      ) : null}
                    </Grid>
                  </Paper>
                ))}
              </div>
            </div>
            {/* <Divider
              color="primary"
              variant="middle"
              orientation="vertical"
              flexItem
            /> */}
            <div style={{ width: '50%', marginRight: 20 }}>
              <h4>Notes</h4>
              <FormGroup>
                <Input
                  type="textarea"
                  id="notes"
                  placeholder="Notes"
                  // maxLength="10000"
                  style={{ minHeight: 100 }}
                />
              </FormGroup>
              <Button
                size="sm"
                color="primary"
                className="d-flex align-items-center"
                type="button"
                onClick={() => {
                  sendComment(document.querySelector('#notes').value);
                }}
              >
                <Icon.Send size={20} /> &nbsp; Send
              </Button>
              <div
                style={{
                  overflowY: 'scroll',
                  height: '400px',
                  padding: '3px',
                }}
              >
                {comments.map((item, i) => (
                  <Paper
                    key={`${i + 1}`}
                    style={{
                      padding: '40px 20px',
                      marginTop: 10,
                    }}
                    elevation={6}
                  >
                    <Grid container wrap="nowrap" spacing={2}>
                      <Grid item>
                        <Avatar
                          alt=""
                          src=""
                          sx={{
                            bgcolor: stringToColor(item.username),
                          }}
                        />
                      </Grid>
                      <Grid justifyContent="left" item xs zeroMinWidth>
                        <h4
                          style={{
                            margin: 0,
                            textAlign: 'left',
                          }}
                        >
                          {item.username}
                        </h4>
                        <p
                          style={{
                            textAlign: 'left',
                            color: 'gray',
                          }}
                        >
                          {getDateString(item.publishDate)}
                        </p>

                        <p
                          style={{
                            textAlign: 'left',
                          }}
                        >
                          {item.comment}
                        </p>
                      </Grid>
                      {item.editStatus ? (
                        <>
                          <Grid item>
                            <Button
                              size="sm"
                              outline
                              color="primary"
                              className="d-flex align-items-center"
                              type="button"
                              onClick={() => {
                                setItemEdit(item);
                                toggleEditModal();
                              }}
                            >
                              <Icon.Edit size={16} />
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              size="sm"
                              color="danger"
                              className="d-flex align-items-center"
                              type="button"
                              onClick={() => {
                                setDeleteItem('comment');
                                setCommentIndex(item.index);
                                setDeleteAlert(true);
                              }}
                            >
                              <Icon.Trash size={16} />
                            </Button>
                          </Grid>
                        </>
                      ) : null}
                    </Grid>
                  </Paper>
                ))}
              </div>
            </div>
          </Form>
        </CardBody>
      </Card>
      {/* {checkAllow(decodedToken.role) ? (
        <div className="d-flex justify-content-center mt-2">
          <Button
            color="primary"
            className="d-flex align-items-center"
            type="button"
            onClick={() => editTrailer()}
          >
            <Icon.Save size={22} /> &nbsp; Save
          </Button>
        </div>
      ) : null} */}
      {checkAllow(decodedToken.role) ? (
        <>
          <Backdrop open={open} />
          <SpeedDial
            ariaLabel="SpeedDial"
            sx={{ position: 'absolute', bottom: 16, right: 16 }}
            icon={<MenuIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
          >
            {actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                tooltipOpen
                onClick={() => {
                  handleClose();
                  editTrailer();
                }}
              />
            ))}
          </SpeedDial>
        </>
      ) : null}
    </>
  );
}
