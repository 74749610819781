import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  CustomInput,
  Col,
} from 'reactstrap';
import { IconButton } from '@mui/material';
import { toast, Flip } from 'react-toastify';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import driverService from '../../../../../services/drivers';

export default function SendFormLinkModal({ modal, tenant, toggle }) {
  const [formUrl, setFormUrl] = useState(
    window.location.origin + '/drivers/add/newForm/' + tenant
  );

  const sendLink = () => {
    const data = {
      email: document.querySelector('#email').value,
      link: document.querySelector('#link').value,
    };
    driverService
      .sendFromLink(data)
      .then((res) => {
        if (res) {
          toast.success('Send form link', {
            transition: Flip,
          });
        } else {
          toast.error('Something went wrong', { transition: Flip });
        }
        toggle();
      })
      .catch((err) => {
        toast.error(err.response.data.message, { transition: Flip });
      });
  };

  return (
    <>
      <div style={{ width: '100%' }}>
        <Form>
          <FormGroup className="align-items-center" row>
            <Col md="4">
              <span>Form link:</span>
            </Col>
            <Col md="6">
              <Input
                id="link"
                disabled={true}
                defaultValue={formUrl}
                type="text"
              />
            </Col>
            <Col md="2">
              <IconButton
                color="primary"
                onClick={() => {
                  navigator.clipboard.writeText(formUrl);
                  toast.success('Copy link to clipboard', {
                    transition: Flip,
                  });
                }}
              >
                <ContentCopyIcon />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => {
                  window.open(formUrl, '__blank');
                }}
              >
                <OpenInNewIcon />
              </IconButton>
            </Col>
          </FormGroup>
          <FormGroup className="align-items-center" row>
            <Col md="4">
              <span>Email</span>
            </Col>
            <Col md="8">
              <Input
                type="email"
                id="email"
                onChange={(e) => {
                  document.querySelector('#email').value = e.target.value;
                }}
              />
            </Col>
          </FormGroup>
        </Form>
      </div>
      <div>
        <Button
          color="primary"
          onClick={() => {
            sendLink();
          }}
        >
          Send
        </Button>
      </div>
    </>
  );
}
