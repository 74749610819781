/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useState, useRef, useEffect, useMemo } from 'react';
import {
  Card,
  Col,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Button,
  Input,
  Label,
  FormGroup,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';
// import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';

// import { changeDashboardState } from '../../../../redux/actions/utility';
import {
  Card as CardMui,
  CardActions as CardActionsMui,
  CardContent as CardContentMui,
  TextField,
  Box,
} from '@mui/material';
import { toast, Flip } from 'react-toastify';
// import ButtonMui from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import chatAPI from '../../../../services/chatApi';
// import { client, publishMessage } from "../../../../services/websocket/socket";
import dashboardService from '../../../../services/dashboard';
import ownedCompanyService from '../../../../services/owned_company';
import dispatcherService from '../../../../services/users';
import unitService from '../../../../services/units';
import truckNotesService from '../../../../services/truck_notes';
import { fetchData } from '../../../../redux/modules/dashboard/actions';
import { useQuery } from '../../useQuery';
import Loading from '../../../../components/main/Loading';
import Table from '../../../../components/main/table';
import { setRefreshPage } from '../../../../redux/modules/store/actions';
import { dashboardHeader } from '../../../../redux/constants/table/headers';
import { headerMaker } from '../../../../components/main/table/helper';
import { toolTips } from './helper';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

export default function Dashboard() {
  const {
    query,
    search,
    start,
    end,
    pageIndex,
    setSearch,
    setStart,
    setEnd,
    setCurrentEmployerId,
    setDispatcherId,
    setUnitStatusId,
    setPageIndex,
    setPageSize,
    setRefresh,
    handleOnTableChange,
  } = useQuery({ fetchData });

  const scrollRef = useRef();

  const dispatch = useDispatch();
  const [openDrop, setOpenDrop] = useState(false);

  const { data, total, loading, error } = useSelector(
    (state) => state.dashboardReducer
  );

  localStorage.setItem('total_data', total);

  const { refreshPage, refreshPage1 } = useSelector(
    (state) => state.storeReducer
  );

  const handleCloseDrop = () => {
    setOpenDrop(false);
  };

  const [datas, setDatas] = useState(data);

  const [modal, setModal] = useState(false);

  const headers = useMemo(
    () => headerMaker(dashboardHeader),
    [dashboardHeader]
  );

  const handleModal = () => {
    setModal(!modal);
  };

  const createHeaders = (headerData) => {
    const cData = headerData.map((item) => ({
      item,
      // ref: useRef(),
    }));
    // console.log(cData);

    // setColumns(cData);

    return cData;
  };

  const [columns, setColumns] = useState(createHeaders(headers));

  // const defaultColDef = {
  //   sortable: true,
  //   resizable: true,
  //   filter: true,
  //   floatingFilter: true,
  //   tooltipValueGetter: (params) => {
  //     return params.value;
  //   },
  // };

  // const columnDefs = [
  //   {
  //     headerName: '#',
  //     field: 'index',
  //     minWidth: 70,
  //     flex: 1,
  //     filter: false,
  //     cellStyle: function (params) {
  //       return {
  //         fontWeight: '500',
  //       };
  //     },
  //   },
  //   {
  //     headerName: 'Unit #',
  //     field: 'unitNumber',
  //     minWidth: 150,
  //     flex: 1,
  //     filter: 'agTextColumnFilter',
  //     cellRendererFramework: function (params) {
  //       return (
  //         <Link
  //           className="link-in-table"
  //           to={`/unit/view/${params.value}`}
  //           onClick={() => {
  //             // history.push(`/unit/view/${params.value}`);
  //             // currentSubs.unsubscribe();
  //           }}
  //         >
  //           {params.value}
  //         </Link>
  //       );
  //     },
  //   },
  //   {
  //     headerName: 'Driver',
  //     field: 'driverOne',
  //     minWidth: 200,
  //     flex: 1,
  //     filter: 'agTextColumnFilter',
  //     resizable: true,
  //     cellRendererFramework: function (params) {
  //       return (
  //         <div className="drivers-col">
  //           <Link
  //             style={{
  //               color: 'black',
  //             }}
  //             className="link-in-table"
  //             to={`/driver/view/${params.data.driverOneId}`}
  //             onClick={() => {
  //               // currentSubs.unsubscribe();
  //             }}
  //           >
  //             {params.data.driverOne}
  //           </Link>
  //           {params.data.driverTwoId !== null && (
  //             <Link
  //               style={{
  //                 color: 'black',
  //               }}
  //               className="link-in-table"
  //               to={`/driver/view/${params.data.driverTwoId}`}
  //               onClick={() => {
  //                 // currentSubs.unsubscribe();
  //               }}
  //             >
  //               {params.data.driverTwo}
  //             </Link>
  //           )}
  //         </div>
  //       );
  //     },
  //     cellStyle: function (params) {
  //       return {
  //         backgroundColor: params.data.teamColor,
  //       };
  //     },
  //   },
  //   {
  //     headerName: 'Phone numbers',
  //     field: 'driverOne',
  //     minWidth: 170,
  //     flex: 1,
  //     filter: 'agTextColumnFilter',
  //     resizable: true,
  //     cellRendererFramework: function (params) {
  //       return (
  //         <div className="drivers-col">
  //           <p>
  //             {params.data.driverOnePhoneNumber}
  //             <br />
  //             {params.data.driverTwoId !== null
  //               ? params.data.driverTwoPhoneNumber
  //               : null}
  //           </p>

  //           {/* <Link
  //                           className="link-in-table"
  //                           to={`/driver/view/${params.data.driverOneId}`}
  //                           onClick={() => {
  //                               // currentSubs.unsubscribe();
  //                           }}
  //                       >
  //                           {params.data.driverOnePhoneNumber}
  //                       </Link>
  //                       {params.data.driverTwoId !== null && (
  //                           <Link
  //                               className="link-in-table"
  //                               to={`/driver/view/${params.data.driverTwoId}`}
  //                               onClick={() => {
  //                                   // currentSubs.unsubscribe();
  //                               }}
  //                           >
  //                               {params.data.driverTwoPhoneNumber}
  //                           </Link>
  //                       )} */}
  //         </div>
  //       );
  //     },
  //     // cellStyle: function (params) {
  //     //     return {
  //     //         backgroundColor: "#ffffff",
  //     //     };
  //     // },
  //   },
  //   {
  //     headerName: 'UT',
  //     field: 'typeOfUnit',
  //     filter: false,
  //     minWidth: 100,
  //     maxWidth: 100,
  //     flex: 1,
  //   },
  //   {
  //     headerName: 'DT',
  //     field: 'typeOfDriver',
  //     filter: false,
  //     minWidth: 70,
  //     maxWidth: 70,
  //     flex: 1,
  //   },
  //   {
  //     headerName: 'Unit Status',
  //     field: 'unitStatus',
  //     filter: false,
  //     minWidth: 110,
  //     maxWidth: 110,
  //     flex: 1,
  //     cellStyle: function (params) {
  //       return {
  //         fontSize: '13px',
  //         color: 'black',
  //         backgroundColor: params.data.unitStatusColor
  //           ? params.data.unitStatusColor
  //           : 'white',
  //         textAlign: 'center',
  //         textTransform: 'uppercase',
  //       };
  //     },
  //     cellRendererFramework: (params) => {
  //       // console.log(params);
  //       return (
  //         <MakeSelector
  //           params={params}
  //           changeUnitStatus={changeUnitStatus}
  //           setStatusChangeId={setStatusChangeId}
  //           unitStatuses={unitStatuses}
  //         />
  //         // <div
  //         //     // aria-controls={this.state.openMenu ? 'basic-menu' : undefined}
  //         //     // aria-haspopup="true" aria-expanded={this.state.openMenu ? 'true' : undefined}
  //         //     style={{ width: "78px" }}
  //         //     aria-controls={"account-menu"}
  //         //     aria-haspopup="true"
  //         //     aria-expanded={"true"}
  //         //     onClick={(event) =>
  //         //         handleMenuClick(
  //         //             event,
  //         //             params.data.truckId,
  //         //             params.data.unitStatusId
  //         //         )
  //         //     }
  //         // >
  //         //     {params.data.unitStatus}
  //         // </div>
  //       );
  //     },
  //   },
  //   {
  //     headerName: 'From',
  //     field: 'from',
  //     filter: false,
  //     minWidth: 200,
  //     flex: 1,
  //   },
  //   {
  //     headerName: 'To',
  //     field: 'to',
  //     filter: 'agTextColumnFilter',
  //     minWidth: 200,
  //     flex: 1,
  //   },
  //   {
  //     headerName: 'Destination time',
  //     field: 'endTime',
  //     filter: false,
  //     minWidth: 150,
  //     flex: 1,
  //   },
  //   {
  //     headerName: 'Load #',
  //     field: 'loadNumber',
  //     filter: 'agTextColumnFilter',
  //     minWidth: 170,
  //     flex: 1,
  //     cellRendererFramework: function (params) {
  //       return (
  //         <Link
  //           className="link-in-table"
  //           to={`/loads/edit/${params.data.loadId}`}
  //           onClick={() => {
  //             // currentSubs.unsubscribe();
  //           }}
  //         >
  //           {params.value}
  //         </Link>
  //       );
  //     },
  //   },
  //   {
  //     headerName: 'Weekly gross',
  //     field: 'calc',
  //     filter: false,
  //     minWidth: 170,
  //     flex: 1,
  //     cellRendererFramework: function (params) {
  //       return (
  //         <Link
  //           className="link-in-table"
  //           to={`/loads/edit/${params.data.loadId}`}
  //           onClick={() => {
  //             // currentSubs.unsubscribe();
  //           }}
  //         >
  //           {params.value}
  //         </Link>
  //       );
  //     },
  //   },
  //   {
  //     headerName: 'notes',
  //     field: 'notes',
  //     filter: false,
  //     minWidth: 250,
  //     flex: 1,
  //     // tooltip: (params) => {
  //     //     return params.value;
  //     // },
  //     cellRendererFramework: (params) => {
  //       return (
  //         <div className="notes-col">
  //           {!params.data.notes ? (
  //             <Button.Ripple
  //               style={{ width: 225 }}
  //               className="d-flex align-items-center"
  //               type="button"
  //               onClick={() => handleToggle(params.data.truckId)}
  //             >
  //               Add note
  //             </Button.Ripple>
  //           ) : (
  //             <div onClick={() => handleToggle(params.data.truckId)}>
  //               {params.data.notes}
  //             </div>
  //           )}
  //         </div>
  //       );
  //     },
  //   },
  // ];

  const [page, setPage] = useState(0);
  // const [total, setTotal] = useState(1);
  const [active, setActive] = useState();
  const [team, setTeam] = useState(
    localStorage.getItem('dispatcher')
      ? localStorage.getItem('dispatcher') * 1
      : null
  );
  const [dataLoading, setDataLoading] = useState(loading);

  const [ownedCompanies, setOwnedCompanies] = useState([]);
  const [dispatchers, setDispatchers] = useState([]);
  const [dispatcher, setDispatcher] = useState();
  const [searchText, setSearchText] = useState('');

  // const [open, setOpen] = useState(false);
  const [truckId, setTruckId] = useState();
  const [notesData, setNotesData] = useState([]);
  const [note, setNote] = useState('');
  const [notesTotalElements, setNotesTotalElements] = useState();
  const [notesTotalPages, setNotesTotalPages] = useState();
  const [unitStatuses, setUnitStatuses] = useState([]);
  const [unitStatus, setUnitStatus] = useState('all');
  const [company, setCompany] = useState('0');

  const updateInfoGetData = (tab = 0, dispatcherData) => {
    setDataLoading(true);
    setDatas([]);
    dashboardService
      .getAll(
        `${
          dispatcherData ? `&dispatcherId=${dispatcherData}` : ''
        }&size=2000&page=${page}&searchText=${searchText}${
          tab ? `&currentEmployerId=${tab}` : ''
        }`
      )
      .then((data) => {
        setDataLoading(false);
        const dataToShow = [];
        data.data.forEach((el, i) => {
          if (el.unitStatus !== 'OFF') {
            const elToShow = {
              index: i + 1,
              unitNumber: el.number,
              driverOneId: el.driverOneId,
              driverTwoId: el.driverTwoId,
              driverOne: el.driverOne,
              driverOnePhoneNumber: el.driverOnePhoneNumber,
              driverTwo: el.driverTwo,
              driverTwoPhoneNumber: el.driverTwoPhoneNumber,
              typeOfUnit: el.typeOfUnit,
              typeOfDriver: el.typeOfDriver,
              unitStatus: el.unitStatus,
              unitStatusColor: el.unitStatusColor,
              teamColor: el.teamColor,
              driverStatus: el.driverStatus,
              driverStatusColor: el.driverStatusColor,
              from: el.from,
              to: el.to,
              loadNumber: el.loadNumber,
              notes: el.notes,
              endTime: el.endTime,
              loadId: el.loadId,
              truckId: el.truckId,
              unitStatusId: el.unitStatusId,
              calc: el.calc,
            };
            dataToShow.push(elToShow);
          }
        });
        setDatas(dataToShow);
        localStorage.setItem('dashboard_data', JSON.stringify(dataToShow));

        // setTotal(data.total_elements);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const updateInfoGetData2 = (tab, dispatcherData) => {
    // event.api.applyTransactionAsync(
    //     { update: updatedList },
    //     resultCallback
    // );
    dashboardService
      .getAll(
        `${
          dispatcherData ? `&dispatcherId=${dispatcherData}` : ''
        }&size=2000&page=${page}&searchText=${searchText}${
          tab ? `&currentEmployerId=${tab}` : ''
        }`
      )
      .then((data) => {
        const dataToShow = [];
        data.data.forEach((el, i) => {
          if (el.unitStatus !== 'OFF') {
            const elToShow = {
              index: i + 1,
              unitNumber: el.number,
              driverOneId: el.driverOneId,
              driverTwoId: el.driverTwoId,
              driverOne: el.driverOne,
              driverOnePhoneNumber: el.driverOnePhoneNumber,
              driverTwo: el.driverTwo,
              driverTwoPhoneNumber: el.driverTwoPhoneNumber,
              typeOfUnit: el.typeOfUnit,
              typeOfDriver: el.typeOfDriver,
              unitStatus: el.unitStatus,
              unitStatusColor: el.unitStatusColor,
              teamColor: el.teamColor,
              driverStatus: el.driverStatus,
              driverStatusColor: el.driverStatusColor,
              from: el.from,
              to: el.to,
              loadNumber: el.loadNumber,
              notes: el.notes,
              endTime: el.endTime,
              loadId: el.loadId,
              truckId: el.truckId,
              unitStatusId: el.unitStatusId,
              calc: el.calc,
            };
            dataToShow.push(elToShow);
          }
        });
        setOpenDrop(true);
        setDatas(dataToShow);
        setOpenDrop(false);
        localStorage.setItem('dashboard_data', JSON.stringify(dataToShow));

        // setTotal(data.total_elements);
      })
      .catch((err) => {});
  };

  const getNotes = (truckId) => {
    truckNotesService
      .getAll(`truck_id=${truckId}&page=0&size=1000000`)
      .then((data) => {
        setNotesData(data.data.notes);
        setNotesTotalElements(data.data.totalElements);
        setNotesTotalPages(data.data.totalPages);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const handleToggle = (id) => {
    setTruckId(id);
    getNotes(id);
  };

  const handleClose = () => {
    setTruckId(null);
    setNote(null);
    setNotesData([]);
  };

  const addNote = () => {
    const data = {
      content: note,
      truckId,
    };
    truckNotesService
      .create(data)
      .then((data) => {
        // eslint-disable-next-line no-use-before-define
        getNotes(truckId);
        setNote('');
        updateInfoGetData();
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const getUnitStatuses = () => {
    unitService
      .getContext()
      .then((data) => {
        setUnitStatuses(data.unit_statuses);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      addNote();
    }
  };

  const handleChangeNote = (event) => {
    setNote(event.target.value);
  };

  const getDataInterval = () => {
    if (
      localStorage.getItem('timer') === null ||
      localStorage.getItem('timer') === '0'
    ) {
      localStorage.setItem('timer', '1');
      const interval = setInterval(() => {
        if (window.location.pathname === '/dashboard/list') {
          const activen = localStorage.getItem('tabIndex');
          let dispatchern = localStorage.getItem('dispatcher');
          if (dispatchern) {
            dispatchern = JSON.parse(dispatchern);
          }
          updateInfoGetData2(
            activen === '0' ? null : activen,
            dispatchern ? dispatchern.value : null
          );
        }
        if (window.location.pathname !== '/dashboard/list') {
          console.log('off');
          clearInterval(interval);
        }
      }, 60000);
    }
  };

  const getDataDispatchers = (company) => {
    setDispatcher();
    setDispatchers([]);
    dispatcherService
      .getAllDispatchers(
        `size=10000&roleId=3${company ? `&currentEmployerId=${company}` : ''}`
      )
      .then((data) => {
        const dataToShow = [];
        data.content.forEach((item) => {
          const elToShow = {
            value: item.id,
            label: item.username,
          };
          dataToShow.push(elToShow);

          // setTotal(data.total_elements);
        });
        let dispatcherNew = null;
        if (team !== null) {
          dispatcherNew = dataToShow.find((item) => {
            return item.value === team;
          });
        }

        setDispatcher(dispatcherNew);
        setDispatchers(dataToShow);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const toggle = (tab) => {
    if (active !== tab) {
      setDispatcher();
      setActive(tab);
      getDataDispatchers(tab);
      setCurrentEmployerId(tab);
      setDispatcherId(null);
      // updateInfoGetData(tab, null);
    }
  };

  const getCompanies = () => {
    ownedCompanyService
      .getAllDashboard('deleted=false&sort=id,DESC&size=10000')
      .then((data) => {
        if (data.length === 1) {
          setCompany(data[0].id);
        }
        setOwnedCompanies(data);
        data.forEach((item) => {
          if (item.id === localStorage.getItem('tabIndex')) {
            setCurrentEmployerId(item.id);
          }
        });
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
    setDispatcher(null);
    getDataDispatchers();
    getUnitStatuses();
    // getDataInterval();
  };

  useEffect(() => {
    getCompanies();
    localStorage.setItem('pageIndex', 0);
  }, []);

  useEffect(() => {
    if (refreshPage !== undefined) {
      dispatch(setRefreshPage());
      setRefresh(true);

      toast.success('Status successfully edit', {
        transition: Flip,
      });
    }
  }, [refreshPage]);

  useEffect(() => {
    if (refreshPage1 !== undefined) {
      dispatch(setRefreshPage());
      setRefresh(true);
    }
  }, [refreshPage1]);

  // useEffect(() => {

  // if (localStorage.getItem('dashboard_data')) {
  //   setDataLoading(false);
  //   const oldData = JSON.parse(localStorage.getItem('dashboard_data'));
  //   setDatas(oldData);
  // } else {
  //   const dataToShow = [];
  //   data.forEach((el, i) => {
  //     if (el.unitStatus !== 'OFF') {
  //       const elToShow = {
  //         index: i + 1,
  //         unitNumber: el.number,
  //         driverOneId: el.driverOneId,
  //         driverTwoId: el.driverTwoId,
  //         driverOne: el.driverOne,
  //         driverOnePhoneNumber: el.driverOnePhoneNumber,
  //         driverTwo: el.driverTwo,
  //         driverTwoPhoneNumber: el.driverTwoPhoneNumber,
  //         typeOfUnit: el.typeOfUnit,
  //         typeOfDriver: el.typeOfDriver,
  //         unitStatus: el.unitStatus,
  //         unitStatusColor: el.unitStatusColor,
  //         teamColor: el.teamColor,
  //         driverStatus: el.driverStatus,
  //         driverStatusColor: el.driverStatusColor,
  //         from: el.from,
  //         to: el.to,
  //         loadNumber: el.loadNumber,
  //         notes: el.notes,
  //         endTime: el.endTime,
  //         loadId: el.loadId,
  //         truckId: el.truckId,
  //         unitStatusId: el.unitStatusId,
  //         calc: el.calc,
  //       };
  //       dataToShow.push(elToShow);
  //     }
  //     localStorage.setItem('dashboard_data', JSON.stringify(dataToShow));
  //   });
  //   setDatas(dataToShow);
  //   setDataLoading(!loading);
  // }
  //   setDatas(data);
  // }, [data]);

  return (
    <>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>

      <Box
        sx={{
          // backgroundColor: '#000',
          // height: 'calc(100vh - 6.5rem)',
          paddingRight: 1,
          // overflow: 'auto',
          // overflowY: 'scroll',
          // scrollBehavior: 'smooth',
          // '&::-webkit-scrollbar': {
          //   scrollBehavior: 'smooth',
          //   width: 14,
          // },
          // '&::-webkit-scrollbar-track': {
          //   // boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
          // },
          // '&::-webkit-scrollbar-thumb': {
          //   backgroundColor: 'darkgrey',
          //   outline: '1px solid slategrey',
          //   borderRadius: 2,
          // },
        }}
      >
        <Loading loading={loading} />
        <Card>
          <div className="d-flex justify-content-between ml-1 mt-1 mr-0">
            {/* <div className="d-flex align-items-center">
              <Nav pills className="dashboard-tabs">
                <NavItem>
                  <NavLink
                    style={{
                      height: 38,
                      backgroundColor:
                        localStorage.getItem('tab') === 'All' ||
                        localStorage.getItem('tab') === null
                          ? '#00FF7F'
                          : 'white',
                    }}
                    className={classnames(
                      {
                        customActive: active === 0,
                      },
                      'd-flex align-items-center'
                    )}
                    onClick={() => {
                      localStorage.setItem('tabIndex', '0');
                      localStorage.setItem('tab', 'All');
                      toggle(null);
                    }}
                  >
                    <h4
                      className="mb-0 ml-1 mr-1"
                      style={{
                        color: 'black',
                      }}
                    >
                      All
                    </h4>
                  </NavLink>
                </NavItem>
                {ownedCompanies.map((item) => (
                  <NavItem key={`${item.id}`}>
                    <NavLink
                      style={{
                        height: 38,
                        backgroundColor:
                          localStorage.getItem('tab') === item.abbreviation
                            ? '#00FF7F'
                            : 'white',
                      }}
                      className={classnames(
                        {
                          customActive: active === item.id,
                        },
                        'd-flex align-items-center'
                      )}
                      onClick={() => {
                        localStorage.setItem('tabIndex', item.id);
                        localStorage.setItem('tab', item.abbreviation);
                        toggle(item.id);
                      }}
                    >
                      {item.logoFileId ? (
                        // eslint-disable-next-line jsx-a11y/alt-text
                        <img
                          src={`${process.env.REACT_APP_BASE_URL}/file/${item.logoFileId}`}
                          style={{
                            maxHeight: 38,
                            maxWidth: 120,
                          }}
                        />
                      ) : (
                        <h4
                          className="mb-0 ml-1 mr-1"
                          style={{
                            color: 'black',
                          }}
                        >
                          {item.abbreviation}
                        </h4>
                      )}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </div> */}
            <div
              style={{
                paddingRight: '15px',
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                justifyContent: 'flex-start',
              }}
            >
              <div
                style={{
                  // display: 'flex',
                  // flexDirection: 'row',
                  // gap: '10px',
                  justifyContent: 'flex-start',
                }}
              >
                {/* </div>
            <div className="d-flex" style={{ width: 300 }}> */}

                <FormControl sx={{ width: 200 }} size="small">
                  <InputLabel id="demo-simple-select-label0">
                    Select company
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label0"
                    id="demo-simple-select"
                    value={ownedCompanies.length > 0 ? company : ''}
                    label="Select company"
                    MenuProps={MenuProps}
                    onChange={(e) => {
                      const { value } = e.target;
                      console.log(value);
                      if (value !== undefined && value === '0') {
                        localStorage.setItem('tabIndex', '0');
                        setCompany(0);
                        toggle(null);
                      } else {
                        localStorage.setItem('tabIndex', value);
                        setCompany(value);
                        toggle(value);
                      }
                    }}
                  >
                    {ownedCompanies.length > 1 ? (
                      <MenuItem key="empty" value="0">
                        All
                      </MenuItem>
                    ) : null}
                    {ownedCompanies.map((item, i) => {
                      return (
                        <MenuItem key={`${i + 1}`} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>

            <div
              style={{
                paddingRight: '15px',
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                justifyContent: 'flex-end',
              }}
            >
              <div
                style={{
                  // display: 'flex',
                  // flexDirection: 'row',
                  // gap: '10px',
                  justifyContent: 'flex-end',
                }}
              >
                <FormControl sx={{ width: 200 }} size="small">
                  <InputLabel id="demo-simple-select-label">
                    Select dispatcher
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={dispatcher ? dispatcher.value : 'all'}
                    label="Select dispatcher"
                    MenuProps={MenuProps}
                    onChange={(e) => {
                      const { value } = e.target;

                      if (value === null || value === 'all') {
                        localStorage.removeItem('dispatcher');
                        setDispatcherId(null);
                        setDispatcher({
                          value: 'all',
                          label: 'All',
                        });
                      } else {
                        dispatchers.forEach((el, i) => {
                          if (el.value === e.target.value) {
                            setDispatcher({
                              value: el.value,
                              label: el.label,
                            });
                          }
                        });

                        setDispatcherId(value);
                        localStorage.setItem(
                          'dispatcher',
                          JSON.stringify(value)
                        );
                      }
                    }}
                  >
                    <MenuItem key="empty" value="all">
                      All
                    </MenuItem>
                    {dispatchers.map((item, i) => {
                      return (
                        <MenuItem
                          key={`${i + 1}`}
                          name={item.label}
                          value={item.value}
                        >
                          {item.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  // display: 'flex',
                  // flexDirection: 'row',
                  // gap: '10px',
                  justifyContent: 'flex-end',
                }}
              >
                {/* </div>
            <div className="d-flex" style={{ width: 300 }}> */}

                <FormControl sx={{ width: 200 }} size="small">
                  <InputLabel id="demo-simple-select-label1">
                    Select unit status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label1"
                    id="demo-simple-select"
                    value={unitStatus}
                    label="Select unit status"
                    MenuProps={MenuProps}
                    onChange={(e) => {
                      const { value } = e.target;
                      setUnitStatus(value);

                      if (value === null || value === 'all') {
                        setUnitStatusId(null);
                        // localStorage.removeItem('dispatcher');
                        // updateInfoGetData(active, null);
                      } else {
                        setUnitStatusId(value);
                        // localStorage.setItem('dispatcher', JSON.stringify(value));
                        // updateInfoGetData(active, value.value);
                      }
                    }}
                  >
                    <MenuItem key="empty" value="all">
                      All
                    </MenuItem>
                    {unitStatuses.map((item, i) => {
                      if (item.name !== 'OFF') {
                        return (
                          <MenuItem key={`${i + 1}`} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center mt-1 mx-1 mb-1 mr-1">
            <TextField
              fullWidth
              size="small"
              id="search"
              label="Search"
              variant="outlined"
              onChange={(e) => setSearch(e.target.value)}
            />
            {/* <IconButton
              color="primary"
              aria-label="add to shopping cart"
              onClick={() => {
                setFilterIsShow(!isFilterShow);
              }}
            >
              <FilterListIcon />
            </IconButton> */}
            {/* <h4 className="mx-1 mb-0 text-nowrap">&nbsp;</h4> */}
          </div>

          <div style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 1 }}>
            <Table
              data={data}
              headers={headers}
              toolTips={toolTips}
              onChange={handleOnTableChange}
              modal={handleModal}
              columns={columns}
              setColumns={setColumns}
              // hidePagination={1}
            />
          </div>
        </Card>
        {/* <Notes open={this.state} handleClose={()=>this.handleClose()}></Notes> */}
        {/* <Backdrop
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={openDrop}
          onClick={handleCloseDrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Backdrop
          transitionDuration={350}
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={open}
          id="backdrop"
          // onClick={this.handleClose}
        >
          <CardMui
            style={{ marginLeft: 'auto', height: '100%' }}
            sx={{ minWidth: 475 }}
          >
            <CardContentMui>
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <CloseIcon onClick={handleClose} />
                <h1
                  style={{
                    marginLeft: '15px',
                    marginBottom: 0,
                  }}
                >
                  Notes
                </h1>
              </div>
              <TextField
                style={{ width: '100%' }}
                value={note}
                onChange={handleChangeNote}
                onKeyPress={handleKeyPress}
                label="Add note"
                variant="standard"
              />
              <div
                style={{
                  height: document.getElementById('backdrop')
                    ? parseInt(
                        document.getElementById('backdrop').clientHeight,
                        10
                      )
                    : 0 - 140 + 'px',
                  overflow: 'auto',
                }}
              >
                {notesData.map((item, index) => {
                  return (
                    <div
                      key={`${index + 1}`}
                      style={{
                        border: '1px solid silver',
                        borderRadius: '10px',
                        padding: '10px',
                        marginBottom: '10px',
                        marginTop: index === 0 ? '10px' : '0',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexWrap: 'wrap',
                        }}
                      >
                        <p>{item.author}</p>
                        <p>{new Date(item.postedDate).toLocaleString()}</p>
                      </div>
                      <div style={{ fontStyle: 'italic' }}>{item.content}</div>
                    </div>
                  );
                })}
              </div>
            </CardContentMui>
            <CardActionsMui />
          </CardMui>
        </Backdrop> */}
      </Box>
    </>
  );
}
