export default {
  TABLE_TRAILER_HISTORY_FETCH_STATUSES: 'TABLE_TRAILER_HISTORY_FETCH_STATUSES',
  TABLE_TRAILER_HISTORY_SET_DATA: 'TABLE_TRAILER_HISTORY_SET_DATA',
  TABLE_TRAILER_HISTORY_SET_TOTAL: 'TABLE_TRAILER_HISTORY_SET_TOTAL',
  TABLE_TRAILER_HISTORY_SET_LOADING: 'TABLE_TRAILER_HISTORY_SET_LOADING',
  TABLE_TRAILER_HISTORY_ERROR: 'TABLE_TRAILER_HISTORY_ERROR',
  TABLE_TRAILER_HISTORY_FETCH_DATA: 'TABLE_TRAILER_HISTORY_FETCH_DATA',
  TABLE_TRAILER_HISTORY_SAGA_ONSEARCH_ACTION:
    'TABLE_TRAILER_HISTORY_SAGA_ONSEARCH_ACTION',
};
