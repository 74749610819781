import axios from 'axios';
import types from '../../constants/action-types';

export const setToken = (payload) => {
  localStorage.setItem(
    payload.company + '_access_token',
    payload.data.token.access_token
  );
  localStorage.setItem('refresh_token', payload.data.token.refresh_token);
  localStorage.setItem('userId', payload.data.userId);
  // localStorage.setItem('user', JSON.stringify(payload.user_data));
  return {
    type: types.SET_TOKEN,
    payload,
  };
};

export const setVerify = (payload) => {
  return {
    type: types.SET_VERIFY,
    payload,
  };
};

export const clearToken = (company) => {
  localStorage.removeItem(company + '_access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('userId');
  // localStorage.removeItem('user');
  return {
    type: types.CLEAR_TOKEN,
  };
};
