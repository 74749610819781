import React, { useEffect, useState } from 'react';
import { Button, Box } from '@mui/material';
import Message from './Message';

export default function Messages({
  refer,
  page,
  totalPage,
  getMessages,
  userId,
  user,
  messages,
}) {
  useEffect(() => {}, []);

  console.log(page, totalPage);

  return (
    <>
      <div className="messages">
        {user.id !== 0 && page <= totalPage ? (
          <Box textAlign="center">
            <Button
              variant="contained"
              onClick={() => {
                getMessages();
              }}
            >
              More
            </Button>
          </Box>
        ) : null}
        {messages.map((m, i) => (
          <Message
            refer={refer}
            page={page}
            user={user}
            key={`${i + 1}`}
            userId={userId}
            message={m}
          />
        ))}
      </div>
    </>
  );
}
