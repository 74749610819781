import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BlockIcon from '@mui/icons-material/Block';

export const toolTipsUser = [
  // {
  //   name: 'View',
  //   icon: <VisibilityIcon color="action" fontSize="small" />,
  //   onClick: (id, { history }) => {
  //     console.log(id);
  //     history(`/drivers/view/${id}`);
  //   },
  // },
];

export const toolTipsAdmin = [
  {
    name: 'Delete',
    icon: <BlockIcon color="action" fontSize="small" />,
    onClick: (id, { dispatch, deleteModal }) => {
      //   dispatch(setOrderData(id));
      deleteModal(id);
    },
  },
];
