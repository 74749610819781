import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Col,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import Flatpickr from 'react-flatpickr';
import AsyncSelect from 'react-select/async';
import { toast, Flip } from 'react-toastify';

export default function ShowEditModal({
  modal,
  toggleEditModal,
  editComment,
  item,
}) {
  return (
    <>
      <Form>
        <FormGroup>
          <Input
            defaultValue={item.comment}
            type="textarea"
            id="comment"
            placeholder="Comment"
            style={{ minHeight: 100 }}
            onChange={(e) => {
              // document.querySelector("#comment").value =
              //     e.target.value;
              // // item.comment = e.target.value;
              // console.log(item.comment);
              // console.log(e.target.value);
            }}
          />
        </FormGroup>
      </Form>

      <Button
        color="success"
        className="d-flex align-items-center"
        type="button"
        onClick={() => {
          editComment(document.querySelector('#comment').value, item.index);
          toggleEditModal();
        }}
      >
        <Icon.Send size={22} /> Send
      </Button>
    </>
  );
}
