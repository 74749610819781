import { service } from '.';

export default {
  getAll: (query) => service.get(`/trailer_repairs/list/?${query}`),
  getAllByTrailerId: (query) =>
    service.get(`/trailer_repairs/list/by_trailer_id?${query}`),
  getOne: (id) => service.get(`/trailer_repairs/${id}`),
  getExcelFile: (query) => service.get(`/trailer_repairs/get_excel?${query}`),
  create: (data) => service.post('/trailer_repairs/new', data),
  update: (data) => service.put('/trailer_repairs/edit', data),
  delete: (id) => service.delete(`/trailer_repairs/${id}`),
};
