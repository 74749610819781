import React, { useState, useEffect } from 'react';
// import Preloader from '../../Pre';
// import Navbar from '../../Navbar';
import { Card, CardContent, Container, Grid } from '@mui/material';
// import Footer from '../../Footer';
// import Resume from './Resume/ResumeNew';
// import ScrollToTop from '../../ScrollToTop';
// import '../../style.css';
// import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function MobilePrivacy() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {/* <Preloader load={load} /> */}
      <div className="App" id={load ? 'no-scroll' : 'scroll'}>
        {/* <Navbar />
        <ScrollToTop /> */}
        <Container fluid className="about-section">
          {/* <Particle /> */}
          <Container>
            <Grid style={{ justifyContent: 'center', padding: '10px' }}>
              <Grid
                md={7}
                style={{
                  justifyContent: 'center',
                  paddingTop: '30px',
                  paddingBottom: '50px',
                }}
              >
                <h1 style={{ fontSize: '2.1em', paddingBottom: '20px' }}>
                  <strong className="purple"> LBS Mobile Privacy</strong>
                </h1>
                <Card className="quote-card-view">
                  <CardContent>
                    <blockquote className="blockquote mb-0">
                      <p style={{ textAlign: 'justify' }}>
                        <span className="purple"> Privacy policy </span>
                        <br />
                        <span className="purple">
                          Your privacy is important to team LBS Group and we are
                          committed to protecting your privacy. So we’ve
                          developed a Privacy Policy. This Privacy Policy
                          explains how the we treats your information and
                          protects your privacy when you Use the Application and
                          the rights and options available to you regarding your
                          information. Personal Information we don`t collect any
                          personal information of the user.
                          <br /> Permissions
                          <br /> 1. Location
                          <br />
                        </span>
                        <br />
                        <br />
                      </p>
                    </blockquote>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Container>
      </div>
    </>
  );
}
