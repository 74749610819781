/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { Paper, TextField } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import 'flatpickr/dist/themes/material_blue.css';
import { toast, Flip } from 'react-toastify';
import fuelService from '../../../../../services/fuel';

const columns = [
  { id: 'id', label: '№', minWidth: 70 },
  {
    id: 'store',
    label: 'Store',
    minWidth: 120,
    align: 'right',
  },
  {
    id: 'city',
    label: 'City',
    minWidth: 170,
    align: 'right',
  },
  {
    id: 'state',
    label: 'State',
    minWidth: 50,
    align: 'right',
  },
  {
    id: 'unleaded',
    label: 'Unleaded',
    minWidth: 100,
    align: 'right',
  },
  {
    id: 'midgrade',
    label: 'Midgrade',
    minWidth: 100,
    align: 'right',
  },
  {
    id: 'super',
    label: 'Super',
    minWidth: 100,
    align: 'right',
  },
  {
    id: 'propane',
    label: 'Propane',
    minWidth: 100,
    align: 'right',
  },
  {
    id: 'diesel',
    label: 'Diesel',
    minWidth: 100,
    align: 'right',
  },
  {
    id: 'pump_def',
    label: 'Pump DEF',
    minWidth: 100,
    align: 'right',
  },
  {
    id: 'intended_bio_blend',
    label: 'Intended Bio Blend',
    minWidth: 100,
    align: 'right',
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function PilotFuelPricesModal({ modal }) {
  const [rowData, setRowData] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState(null);

  const loadData = (newPage, rowsPerPage1, search) => {
    fuelService
      .getFuelPrices(
        'store=pilot&page=' +
          newPage +
          '&size=' +
          rowsPerPage1 +
          `${search ? '&search=' + search : ''}`
      )
      .then((data) => {
        setTotal(data.total_elements);
        setRowData(data.content);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    loadData(newPage, rowsPerPage, search);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(+event.target.value);
    loadData(0, +event.target.value, search);
  };

  useEffect(() => {
    loadData(page, rowsPerPage, null);
  }, []);

  return (
    <Paper
      sx={{
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <div className="d-flex align-items-center mx-0 mb-1">
        <TextField
          fullWidth
          size="small"
          id="search"
          label="Search"
          variant="outlined"
          onChange={(e) => {
            loadData(page, rowsPerPage, e.target.value);
          }}
        />
      </div>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align="left"
                  style={{
                    minWidth: column.minWidth,
                  }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowData
              // .slice(
              //     page * rowsPerPage,
              //     page * rowsPerPage +
              //         rowsPerPage
              // )
              .map((row, i) => {
                return (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={`${i + 1}`}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      if (column.id === 'id') {
                        return (
                          <StyledTableCell
                            key={column.id}
                            align="left"
                            style={{
                              cursor: 'pointer',
                            }}
                          >
                            {i + 1 + page * rowsPerPage}
                          </StyledTableCell>
                        );
                      }
                      return (
                        <StyledTableCell
                          key={column.id}
                          align="left"
                          style={{
                            cursor: 'pointer',
                          }}
                        >
                          {value}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
