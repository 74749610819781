import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import * as reducers from './reducers';
import * as sagas from './sagas';
import customizer from '../reducers/customizer';
import auth from '../reducers/auth';
import navbar from '../reducers/navbar/Index';
import utility from '../reducers/utility';

export const rootReducer = combineReducers({
  ...reducers,
});

export function* rootSaga() {
  yield all(Object.keys(sagas).map((key) => sagas[key]()));
}
