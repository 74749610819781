import React, { useState, useEffect, useMemo } from 'react';
import { CardBody, Nav, NavItem, NavLink, Button } from 'reactstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import { LoadingOutlined } from '@ant-design/icons';
import * as Icon from 'react-feather';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import {
  Card,
  Grid,
  TextField,
  Stack,
  colors,
  Typography,
  IconButton,
} from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import jwtDecode from 'jwt-decode';
import { toast, Flip } from 'react-toastify';
import DownloadFilterTools from '../../../../components/main/FilteredBody/TrailersDownload';
import AnimationDiv from '../../../../components/main/Animations/Transition';
import { fetchData } from '../../../../redux/modules/trailer_idles/actions';
import { useQuery } from '../../useQuery';
import trailerIdlesService from '../../../../services/trailer_idles';
import Loading from '../../../../components/main/Loading';
import Table from '../../../../components/main/table';
import { headerMaker } from '../../../../components/main/table/helper';
import { trailerIdlesHeader } from '../../../../redux/constants/table/headers';
import { toolTips } from './helper';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function TrailerIdles() {
  const {
    query,
    search,
    trailerStatus,
    setPageSize,
    setSearch,
    setTrailerStatus,
    handleOnTableChange,
  } = useQuery({ fetchData });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const headers = useMemo(
    () => headerMaker(trailerIdlesHeader),
    [trailerIdlesHeader]
  );

  const antIcon = <LoadingOutlined style={{ fontSize: 44 }} spin />;

  const [active, setActive] = useState();
  const [page, setPage] = useState(0);

  const { data, loading, total } = useSelector(
    (state) => state.trailerIdlesReducer
  );
  localStorage.setItem('total_data', total);

  const token = localStorage.getItem(
    localStorage.getItem('current_account') + '_access_token'
  );
  const decodedToken = jwtDecode(token);

  const createHeaders = (headerData) => {
    const cData = headerData.map((item) => ({
      item,
      // ref: useRef(),
    }));
    // console.log(cData);

    // setColumns(cData);

    return cData;
  };

  const [columns, setColumns] = useState(createHeaders(headers));

  const [modal, setModal] = useState(false);
  const [isFilterShow, setFilterIsShow] = useState(false);
  const [isDownloadShow, setDownloadShow] = useState(false);

  const [tabs, setTabs] = useState([
    { id: 1, name: 'Trailers' },
    { id: 2, name: 'Repairs' },
    { id: 3, name: 'Idles' },
    { id: 4, name: 'History' },
  ]);

  const tabList = [
    '/trailers/list',
    '/trailer_repairs/list',
    '/trailer_idles/list',
    '/trailer_history/list',
  ];

  const options = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: '',
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Days',
        },
      },
    },
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'left',
      },
      title: {
        display: true,
        text: 'Dormancy',
      },
    },
  };

  const labels = ['8+ High', '4-7 Medium', '0-3 Low'];
  const [dataSet, setDataSet] = useState([0, 0, 0]);

  const dataBar = {
    type: 'bar',
    labels,
    datasets: [
      {
        label: 'Trailers',
        display: false,
        data: dataSet,
        borderColor: 'white',
        backgroundColor: ['red', 'orange', 'green'],
        borderRadius: 5,
        borderSkipped: false,
      },
    ],
  };

  // useEffect(() => {
  //   setPageSize(20);
  // }, []);

  const handleModal = () => {
    setModal(!modal);
  };

  const checkAllow = (role) => {
    if (role === 'ROLE_ADMIN' || role === 'SUPER_ADMIN') {
      return true;
    }
    return false;
  };

  const getIdleDays = () => {
    trailerIdlesService
      .getIdleDays()
      .then((result) => {
        setDataSet([result.high, result.medium, result.low]);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const getFile = (ids) => {
    trailerIdlesService
      .getExcelFile()
      .then((result) => {
        if (result.status === 'success') {
          window.open(result.data, '_blank');
        } else {
          toast.error(result.msg, {
            transition: Flip,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  useEffect(() => {
    getIdleDays();
  }, []);

  return (
    <>
      <Loading loading={loading} />
      <Card className="agGrid-card" style={{ marginRight: 8 }}>
        <br />
        <CardBody className="py-0 no-pagination">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center ">
              <Nav pills className="dashboard-tabs">
                {tabs.map((item) => (
                  <NavItem key={`${item.id}`}>
                    <NavLink
                      style={{
                        height: 38,
                        backgroundColor: item.id === 3 ? '#00FF7F' : 'white',
                      }}
                      className={classnames(
                        {
                          customActive: active === item.id,
                        },
                        'd-flex align-items-center'
                      )}
                      onClick={() => {
                        localStorage.setItem('trailerTabId', item.id);
                        navigate(tabList[item.id - 1]);
                        // history.push(tabList[item.id - 1]);
                      }}
                    >
                      <h4 className="mb-0">{item.name}</h4>
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </div>

            {checkAllow(decodedToken.role) ? (
              <div>
                <Link
                  to="/trailers/new"
                  className="d-flex align-items-center text-white"
                >
                  <Button
                    size="sm"
                    color="primary"
                    className="d-flex align-items-center"
                    type="button"
                  >
                    <Icon.Plus size={20} />
                    &nbsp; Add new trailer
                  </Button>
                </Link>
              </div>
            ) : null}
          </div>
          <div
            style={{
              // position: 'relative',
              // left: '20%',
              // transform: translate(-50%, -50%);
              height: '200px',
              // width: '50%',
            }}
          >
            <Bar options={options} data={dataBar} />
          </div>
          <div className="d-flex align-items-center mt-1 mx-0 mb-1">
            <TextField
              fullWidth
              size="small"
              id="search"
              label="Search"
              variant="outlined"
              onChange={(e) => setSearch(e.target.value)}
            />
            {/* <IconButton
              color="primary"
              aria-label="filter"
              onClick={() => {
                setDownloadShow(false);
                setFilterIsShow(!isFilterShow);
              }}
            >
              <FilterListIcon />
            </IconButton> */}
            <IconButton
              color="primary"
              aria-label="download2"
              onClick={() => {
                setFilterIsShow(false);
                setDownloadShow(!isDownloadShow);
              }}
            >
              <DownloadForOfflineIcon />
            </IconButton>
          </div>

          <div className={isDownloadShow ? 'align-items-center mb-2 mx-0' : ''}>
            <AnimatePresence>
              {isDownloadShow ? (
                <AnimationDiv>
                  <DownloadFilterTools
                    trailerStatus={trailerStatus}
                    setTrailerStatus={setTrailerStatus}
                    getFile={getFile}
                  />
                </AnimationDiv>
              ) : null}
            </AnimatePresence>
          </div>
          <div className="w-100">
            <Table
              data={data}
              headers={headers}
              toolTips={toolTips}
              onChange={handleOnTableChange}
              modal={handleModal}
              columns={columns}
              setColumns={setColumns}
              // hidePagination={1}
            />
          </div>
          <br />
        </CardBody>
      </Card>
    </>
  );
}
