import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Box,
  Paper,
  Slide,
} from '@mui/material';
import Draggable from 'react-draggable';
import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
import { Resizable } from 're-resizable';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// const Transition = React.forwardRef((props, ref) => {
//   return (
//     <Draggable
//       handle="#customized-dialog-title"
//       // cancel={'[class*="MuiDialogContent-root"]'}
//       // handle={'[#customized-dialog-title="MuiDialog-root"]'}
//       cancel={'[class*="MuiDialogContent-root"]'}
//     >
//       <Slide direction="up" ref={ref} {...props} />
//     </Draggable>
//   );
// });

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

// function PaperComponent(props) {
//   return (
//     <Draggable
//       handle="#customized-dialog-title"
//       cancel={'[class*="MuiDialogContent-root"]'}
//     >
//       <Paper {...props} />
//     </Draggable>
//   );
// }

function BootstrapDialogTitle(props) {
  const { children, onClose, backgroundColor, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 2, backgroundColor: '#4d4d4d', color: 'white' }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 20,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function PaperComponent(props) {
  const onStart = () => {};

  const onStop = () => {};
  const dragHandlers = { onStart: { onStart }, onStop: { onStop } };
  return (
    <Draggable handle=".modal-title">
      <ModalDialog {...props} />
    </Draggable>
  );
}

export default ({
  title,
  modal = false,
  handleModal,
  maxWidth,
  maxHeight,
  minWidth,
  minHeight,
  body,
  backgroundColor = '#4d4d4d',
  dialogActions,
}) => {
  const [fullWidth, setFullWidth] = useState(true);
  const [activeDrags, setActiveDrags] = useState(0);

  const onStart = () => {
    setActiveDrags(activeDrags + 1);
  };

  const onStop = () => {
    setActiveDrags(activeDrags - 1);
  };

  return (
    <div style={{ position: 'sticky', display: 'block' }}>
      <Modal
        backdrop={false}
        show={modal}
        onHide={() => {
          handleModal();
        }}
        dialogAs={PaperComponent}
        scrollable={true}
        keyboard={false}
        // centered
      >
        <Resizable
          // bounds="parent"
          // className="modal-resizable"
          style={{
            backgroundColor: 'white',
            backgroundClip: 'padding-box',
            // border: '1px solid #ebedee',
            border: 0,
            borderRadius: '.2em',
            top: 60,
            boxShadow:
              '0 0 0 1px rgba(101, 108, 126, 0.5) inset, 0 1px 3px 1px rgba(64, 64, 64, 0.5)',
          }}
          // size={{ width: 'auto', height: 'auto' }}
          maxWidth={maxWidth}
          maxHeight={maxHeight}
          minWidth={minWidth}
          minHeight={minHeight}
        >
          <Modal.Header>
            <Modal.Title>
              <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={() => handleModal(null)}
                backgroundColor={backgroundColor}
              >
                {title}
              </BootstrapDialogTitle>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{body}</Modal.Body>
          {dialogActions ? <Modal.Footer>{dialogActions}</Modal.Footer> : null}
        </Resizable>
      </Modal>
    </div>

    // <BootstrapDialog
    //   scroll="paper"
    //   hideBackdrop
    //   fullWidth={fullWidth}
    //   open={modal}
    //   onClose={(event, reason) => {
    //     console.log(reason);
    //     if (reason && reason === 'backdropClick') {
    //       return;
    //     }
    //     handleModal();
    //   }}
    //   maxWidth={maxWidth}
    //   TransitionComponent={Transition}
    //   keepMounted
    //   // PaperComponent={PaperComponent}
    //   aria-labelledby="customized-dialog-title"
    // >
    //   <ResizableBox
    //     height={400}
    //     width={600}
    //     draggableOpts={{}}
    //     minConstraints={[100, 100]}
    //     maxConstraints={[300, 300]}
    //     style={{
    //       position: 'relative',
    //       '& .react-resizable-handle': {
    //         position: 'absolute',
    //         width: 20,
    //         height: 20,
    //         bottom: 0,
    //         right: 0,
    //         background:
    //           "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+')",
    //         'background-position': 'bottom right',
    //         padding: '0 3px 3px 0',
    //         'background-repeat': 'no-repeat',
    //         'background-origin': 'content-box',
    //         'box-sizing': 'border-box',
    //         cursor: 'se-resize',
    //       },
    //     }}
    //   >
    //     <BootstrapDialogTitle
    //       style={{ cursor: 'move' }}
    //       id="customized-dialog-title"
    //       onClose={() => handleModal(null)}
    //       backgroundColor={backgroundColor}
    //     >
    //       {title}
    //     </BootstrapDialogTitle>
    //     <DialogContent className="no-cursor" dividers>
    //       {body}
    //     </DialogContent>
    //     {dialogActions}
    //   </ResizableBox>
    // </BootstrapDialog>
  );
};
