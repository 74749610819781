import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ModalWindowFullScreen from '../../Modals/FullScreen';
import LoadModal from '../../../../views/pages/Loads/Modals/LoadModal';

export default ({ value }) => {
  const [loadModal, setLoadModal] = useState(false);

  return (
    <>
      <ModalWindowFullScreen
        title="Load"
        modal={loadModal}
        handleModal={() => {
          setLoadModal(!loadModal);
        }}
        body={<LoadModal modal={loadModal} id={value.loadId} />}
      />
      <Link
        className="link-in-table"
        onClick={() => {
          setLoadModal(true);
        }}
      >
        {value.loadNumber}
      </Link>
    </>
  );
};
