import React, { useState, useEffect } from 'react';
import {
  // Form,
  Form,
  Button,
  FormGroup,
  Input,
  // Label,
  CustomInput,
  // Row,
  Col,
  Card,
  CardBody,
  // CardTitle,
  CardHeader,
  // Button
} from 'reactstrap';
import * as Icon from 'react-feather';
import { connect } from 'react-redux';
import {
  // ToastContainer,
  toast,
  // Slide,
  // Zoom,
  Flip,
  // Bounce,
} from 'react-toastify';
import ImageUploader from 'react-images-upload';
import InfiniteFileUploader from '../../../../components/main/infiniteFileUploader';
// eslint-disable-next-line import/no-unresolved
import companyService from '../../../../services/companies';
// eslint-disable-next-line import/no-unresolved
import ownedCompanyService from '../../../../services/owned_company';
import fileUploadService from '../../../../services/file_upload';

export default function NewCompany() {
  const [states, setStates] = useState([]);
  const [customerTypes, setCustomerTypes] = useState([]);
  const [logoFileId, setLogoFileId] = useState(null);
  const [newFiles, setNewFiles] = useState([]);
  const [prevFiles, setPrevFiles] = useState([]);
  const [fileIds, setFileIds] = useState(new Map());
  const [currentFile, setCurrentFile] = useState(0);

  const newFile = () => {
    const newFilesData = newFiles;
    const currentFileData = currentFile;

    newFilesData.push(currentFileData);
    setNewFiles(newFilesData);
    setCurrentFile(currentFileData + 1);
  };

  const deletePrevFile = (item) => {
    const prevFilesData = prevFiles;
    const fileIdsData = fileIds;
    fileIdsData.delete(item);
    setFileIds(fileIdsData);

    const obj = prevFilesData.find((el) => el.id === item);
    const index = prevFilesData.indexOf(obj);
    if (index > -1) {
      prevFilesData.splice(index, 1);
    }
    setPrevFiles(prevFilesData);
  };

  const deleteFile = (item) => {
    const newFilesData = newFiles;
    const fileIdsData = fileIds;
    fileIdsData.delete(item);
    setFileIds(fileIdsData);

    const index = newFilesData.indexOf(item);
    if (index > -1) {
      newFilesData.splice(index, 1);
    }
    setNewFiles(newFilesData);
  };

  const uploadFile = (file, item) => {
    const formData = new FormData();
    formData.append('file', file);
    if (file === undefined) {
      return;
    }
    fileUploadService
      .upload('/file/upload', formData)
      .then((data) => {
        const newFilesData = newFiles;
        const newFileData = newFilesData.indexOf(item);
        newFilesData[newFileData] = data;
        const fileIdsData = fileIds;
        fileIdsData.set(data, file.name);
        setFileIds(fileIdsData);
        setNewFiles(newFilesData);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const newCompany = () => {
    const obj = Object.create(null);
    // eslint-disable-next-line no-restricted-syntax
    for (const [k, v] of fileIds) {
      obj[k] = v;
    }

    const data = {
      logoFileId,
      files: obj,
      abbreviation: document.querySelector('#abbreviation').value,
      alternatePhone: document.querySelector('#alternatePhone').value,
      alternatePhoneExtensionNumber:
        document.querySelector('#alternatePhone').value,
      aptSuiteOther: document.querySelector('#alternatePhone').value,
      city: document.querySelector('#city').value,
      name: document.querySelector('#companyName').value,
      contact: document.querySelector('#contact').value,
      email: document.querySelector('#email').value,
      fax: document.querySelector('#fax').value,
      motorCarrierNumber: document.querySelector('#motorCarrierNumber').value,
      notes: document.querySelector('#notes').value,
      phoneExtensionNumber: document.querySelector('#phoneExtensionNumber')
        .value,
      phoneNumber: document.querySelector('#phoneNumber').value,
      stateProvinceId: parseInt(
        document.querySelector('#stateProvince').value,
        10
      ),
      street: document.querySelector('#street').value,
      taxId: document.querySelector('#taxId').value,
      webSite: document.querySelector('#webSite').value,
      zipCode: document.querySelector('#zipCode').value,
    };
    ownedCompanyService
      .create(data)
      .then((res) => {
        if (res) {
          toast.success('Company successfuly added', {
            transition: Flip,
          });
          window.history.back();
        } else {
          toast.error('Something went wrong', { transition: Flip });
          // res.text();
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const onDrop = (pictureFiles, pictureDataURLs) => {
    if (pictureFiles.length === 0) {
      return;
    }
    const formData = new FormData();
    formData.append('file', pictureFiles[0], pictureFiles[0].name);

    fileUploadService
      .upload('/file/upload', formData)
      .then((data) => setLogoFileId(data))
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const componentDidMount = () => {
    companyService
      .getContext()
      .then((data) => {
        setCustomerTypes(data.customer_types);
        setStates(data.state_province);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  useEffect(() => {
    componentDidMount();
  }, []);

  return (
    <>
      <Card>
        <CardHeader>
          <h3 className="mb-0">Adding a new Company</h3>
        </CardHeader>
        <CardBody>
          <div className="d-flex">
            <div style={{ flex: 1, marginRight: 20 }}>
              <Form>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Company Name</span>
                    <span style={{ color: 'red' }}> *</span>
                  </Col>
                  <Col md="8">
                    <Input type="text" id="companyName" />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Abbreviation</span>
                    <span style={{ color: 'red' }}> *</span>
                  </Col>
                  <Col md="8">
                    <Input type="text" id="abbreviation" />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Street</span>
                  </Col>
                  <Col md="8">
                    <Input type="text" id="street" />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>City</span>
                    <span style={{ color: 'red' }}> *</span>
                  </Col>
                  <Col md="8">
                    <Input type="text" id="city" />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>State</span>
                    <span style={{ color: 'red' }}> *</span>
                  </Col>
                  <Col md="8">
                    <CustomInput type="select" name="select" id="stateProvince">
                      {states.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </CustomInput>
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Zip Code</span>
                  </Col>
                  <Col md="8">
                    <Input type="text" id="zipCode" />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Phone Number</span>
                  </Col>
                  <Col md="8">
                    <Input type="text" id="phoneNumber" />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Phone Extension number</span>
                  </Col>
                  <Col md="8">
                    <Input type="text" id="phoneExtensionNumber" />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Alternate Phone</span>
                  </Col>
                  <Col md="8">
                    <Input type="text" id="alternatePhone" />
                  </Col>
                </FormGroup>
              </Form>
            </div>
            <div style={{ width: '50%' }}>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Alternate Phone Extension number</span>
                </Col>
                <Col md="8">
                  <Input type="text" id="alternatePhoneExtensionNumber" />
                </Col>
              </FormGroup>

              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Fax</span>
                </Col>
                <Col md="8">
                  <Input type="text" id="fax" />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Email</span>
                </Col>
                <Col md="8">
                  <Input type="text" id="email" />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Website</span>
                </Col>
                <Col md="8">
                  <Input type="text" id="webSite" />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Contact</span>
                </Col>
                <Col md="8">
                  <Input type="text" id="contact" />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Notes</span>
                </Col>
                <Col md="8">
                  <Input type="textarea" id="notes" maxLength="1000" />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Motor Carrier Number</span>
                </Col>
                <Col md="8">
                  <Input type="text" id="motorCarrierNumber" />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Tax ID (EIN#)</span>
                </Col>
                <Col md="8">
                  <Input type="text" id="taxId" />
                </Col>
              </FormGroup>
            </div>
          </div>
        </CardBody>
      </Card>
      <InfiniteFileUploader
        newFiles={newFiles}
        prevFiles={prevFiles}
        deletePrevFile={deletePrevFile}
        newFile={newFile}
        deleteFile={deleteFile}
        uploadFile={uploadFile}
      />
      <ImageUploader
        withIcon={true}
        withPreview={true}
        buttonText="Choose image"
        onChange={onDrop}
        imgExtension={['.jpg', '.png']}
        maxFileSize={5242880}
        singleImage
        label="Upload Logo"
      />
      <div className="d-flex justify-content-center mt-2">
        <Button
          color="success"
          className="d-flex align-items-center"
          type="button"
          onClick={() => newCompany()}
        >
          <Icon.Check size={22} /> Save
        </Button>
      </div>
    </>
  );
}

// const mapStateToProps = (state) => {
//   return {
//     token: state.auth.login.token,
//   };
// };
// export default connect(mapStateToProps)(NewCompany);
