export function dataSelector(dataResponse, page, size) {
  const filtered = dataResponse.data.map((item, i) => ({
    index: i + 1 + page * size,
    id: item.id,
    trailerNumber: item.trailerNumber,
    status: item.status ? 'Active' : 'Deactive',
    ownedBy: item.ownedBy,
    type: item.type,
    make: item.make,
    makeYear: item.makeYear,
    model: item.model,
    vin: item.vin,
    titleNumber: item.titleNumber,
    licensePlate: item.licensePlate,
    purchaseDate: item.purchaseDate,
    saleDate: item.saleDate,
    financedBy: item.financedBy,
    leasingCompany: item.leasingCompany,
    leaseReference: item.leaseReference,
    inServiceOn: item.inServiceOn,
    tagsExpireOn: item.tagsExpireOn,
    nextInspectionOn: item.nextInspectionOn,
    daysLeft_: item.daysLeft_,
    trackingType: item.trackingType,
    unitSerialNumber: item.unitSerialNumber,
    inflationSystem: item.inflationSystem,
    tireSize: item.tireSize,
    doorType: item.doorType,
    deleted: item.deleted,
    data: item,
  }));
  return { total: dataResponse.total, data: filtered };
}
