/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Row,
  Col,
  // Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  // CardHeader,
  Input,
} from 'reactstrap';
import {
  Box,
  Typography,
  Divider,
  Avatar,
  Grid,
  Paper,
  Card,
  // CardBody,
  CardHeader,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import * as Icon from 'react-feather';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { toast, Flip } from 'react-toastify';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import SweetAlert from '../../../../components/main/SweetAlert';
import DataTable from './DataTable';
import PdfViewer from '../Modals/PdfViewer';
import ModalWindow from '../../../../components/main/Modals';
import ownedCompanyService from '../../../../services/owned_company';
import accountingService from '../../../../services/accounting';
import './style.css';

function useKey(key, cb) {
  const callback = useRef(cb);

  useEffect(() => {
    callback.current = cb;
  });

  useEffect(() => {
    function handle(event) {
      if (event.code === key) {
        callback.current(event);
      } else if (key === 'ctrls' && event.key === 's' && event.ctrlKey) {
        event.preventDefault();
        callback.current(event);
      }
    }

    document.addEventListener('keydown', handle);
    return () => document.removeEventListener('keydown', handle);
  }, [key]);
}

export default function Accounting() {
  const [loading, setLoading] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState(false);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [companyId, setCompanyId] = useState();
  const [companies, setCompanies] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [savedFiles, setSavedFiles] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showNewAccountingModal, setShowNewAccountingModal] = useState(false);
  const [showPdfViewerModal, setShowPdfViewerModal] = useState(false);
  const [fileId, setFileId] = useState();
  const [dayss, setDays] = useState([]);
  const [saveAlert, setSaveAlert] = useState(false);
  const [sendAlert, setSendAlert] = useState(false);
  const [sendData, setSendData] = useState([]);
  const [currentFileName, setCurrentFileName] = useState('');
  const [openDrop, setOpenDrop] = useState(false);
  const [pdfData, setPdfData] = useState([]);
  const [sumFuelDiscount, setSumFuelDiscount] = useState(0);
  const [sumEfsFuel, setSumEfsFuel] = useState(0);
  const [sumEfsFleet, setSumEfsFleet] = useState(0);
  const [sumToll, setSumToll] = useState(0);
  const [sumTotalPay, setSumTotalPay] = useState(0);
  const [sumGross, setSumGross] = useState(0);
  const [sumProfitLoss, setSumProfitLoss] = useState(0);

  const Ref = useRef(null);

  const { id } = useParams();

  const getDateString = (date) => {
    return (
      (new Date(date).getMonth() + 1 < 10
        ? '0' + parseInt(new Date(date).getMonth() + 1, 10)
        : parseInt(new Date(date).getMonth() + 1, 10)) +
      '/' +
      (new Date(date).getDate() < 10
        ? '0' + new Date(date).getDate()
        : new Date(date).getDate()) +
      '/' +
      new Date(date).getFullYear() +
      ' ' +
      (new Date(date).getHours() < 10
        ? '0' + new Date(date).getHours()
        : new Date(date).getHours()) +
      ':' +
      (new Date(date).getMinutes() < 10
        ? '0' + new Date(date).getMinutes()
        : new Date(date).getMinutes())
    );
  };

  const getDayString = (date) => {
    return (
      (new Date(date).getMonth() + 1 < 10
        ? '0' + parseInt(new Date(date).getMonth() + 1, 10)
        : parseInt(new Date(date).getMonth() + 1, 10)) +
      '/' +
      (new Date(date).getDate() < 10
        ? '0' + new Date(date).getDate()
        : new Date(date).getDate()) +
      '/' +
      new Date(date).getFullYear()
    );
  };

  const getOneAccountingFileData = (id, loadingStatus) => {
    if (loadingStatus) {
      setLoading(true);
    }

    accountingService
      .getOneAccountingFile(id)
      .then((data) => {
        setCurrentFileName(data.data.fileName);
        const arr = [];
        let a = 0;
        const arrDay = [];
        arrDay.push(getDayString(data.data.startTime));
        const date = new Date(data.data.startTime);
        arrDay.push(getDayString(date.setDate(date.getDate() + 1)));
        arrDay.push(getDayString(date.setDate(date.getDate() + 1)));
        arrDay.push(getDayString(date.setDate(date.getDate() + 1)));
        arrDay.push(getDayString(date.setDate(date.getDate() + 1)));
        arrDay.push(getDayString(date.setDate(date.getDate() + 1)));
        arrDay.push(getDayString(date.setDate(date.getDate() + 1)));
        setDays(arrDay);

        data.data.data.forEach((element) => {
          arr.push({
            rowId: a,
            number: a + 1,
            companyId: data.data.companyId,
            name: element.name,
            email: element.email,
            address: element.address,
            phone: element.phone,
            unit: element.unit,
            gross: typeof element.gross !== 'number' ? 0 : element.gross,
            dispatcher: element.dispatcher,
            collected_deposit: element.collected_deposit,
            rate: element.rate,
            fuel_discount: element.fuel_discount,
            efs_fuel: element.efs_fuel,
            efs_fleet: element.efs_fleet,
            toll: element.toll,
            miles: element.miles,
            days_0: element.days_0,
            days_1: element.days_1,
            days_2: element.days_2,
            days_3: element.days_3,
            days_4: element.days_4,
            days_5: element.days_5,
            days_6: element.days_6,
            total_day:
              element.days_0 * 1 +
              element.days_1 * 1 +
              element.days_2 * 1 +
              element.days_3 * 1 +
              element.days_4 * 1 +
              element.days_5 * 1 +
              element.days_6 * 1,
            cash_adv: element.cash_adv,
            expenses: element.expenses,
            bonus: element.bonus,
            violation_damage: element.violation_damage,
            total_pay: element.total_pay,
            note: element.note,
            dpm: element.dpm,
            previous_gross: element.previous_gross,
            cash_adv_note: element.cash_adv_note,
            exsp_note: element.exsp_note,
            bonus_note: element.bonus_note,
            violation_damage_note: element.violation_damage_note,
            paid_by_company: element.paid_by_company,
            salary: element.salary,
            fuel_efs_toll: element.fuel_efs_toll,
            dispatch_28: element.dispatch_28,
            insurance: element.insurance,
            lease: element.lease,
            other_exp: element.other_exp,
            profit_loss: element.profit_loss,
          });
          a += 1;
        });
        arr.sort((a, b) => a.unit - b.unit);
        setRowData(arr);
        setLoading(false);
        let efsFuelN = 0;
        let fuelDiscountC = 0;
        let sumEfsFleetC = 0;
        let sumTollC = 0;
        let sumTotalPayC = 0;
        let sumGrossC = 0;
        let sumProfitLossC = 0;

        const newArr = arr.filter(
          (v, i, a) =>
            a.findIndex((v2) => ['unit'].every((k) => v2[k] === v[k])) === i
        );

        newArr.forEach((item) => {
          efsFuelN += item.efs_fuel * 1;
          fuelDiscountC += item.fuel_discount * 1;
          sumEfsFleetC += item.efs_fleet * 1;
          sumTollC += item.toll * 1;
          sumTotalPayC += item.totalPay * 1;
          sumGrossC += item.gross * 1;
          sumProfitLossC +=
            item.gross * 1 -
            item.salary * 1 -
            item.fuel_efs_toll * 1 -
            item.dispatch_28 * 1 -
            item.insurance * 1 -
            item.lease * 1 -
            item.other_exp * 1;
        });
        setSumEfsFuel(efsFuelN);
        setSumFuelDiscount(fuelDiscountC);
        setSumEfsFleet(sumEfsFleetC);
        setSumToll(sumTollC);
        setSumTotalPay(sumTotalPayC);
        setSumGross(sumGrossC);
        setSumProfitLoss(sumProfitLossC);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response);
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const togglePdfViewer = () => {
    setShowPdfViewerModal(!showPdfViewerModal);
  };

  const updateData = () => {
    const data = {
      id,
      data: rowData,
    };
    accountingService
      .updateData(data)
      .then((res) => {
        if (res) {
          toast.success('Data successfuly edited', {
            transition: Flip,
          });
          setSaveAlert(false);
          getOneAccountingFileData(id, false);
        } else {
          toast.error(
            data.error_message ? data.error_message : 'Something went wrong',
            {
              transition: Flip,
            }
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const autoSaveData = () => {
    const data = {
      id,
      data: rowData,
    };
    accountingService
      .updateData(data)
      .then((res) => {
        getOneAccountingFileData(id, false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onRowSelected = (event) => {
    if (event.data) {
      setOpenDrop(true);
      if (event.node.isSelected()) {
        const arr = sendData;
        arr.push(event.node.data);
        setSendData(arr);
      } else {
        const arr = sendData.filter((item) => {
          return item.rowId !== event.node.data.rowId;
        });

        setSendData(arr);
      }
      setOpenDrop(false);
    }
  };

  const sentStatementFile = () => {
    const data = [];
    sendData.forEach((itemS, i) => {
      const element = sendData[i];
      const daysPeriod = [];
      [0, 1, 2, 3, 4, 5, 6].forEach((ol, k) => {
        const item = {
          date: dayss[k],
          daysWorked: element['days_' + k],
        };
        daysPeriod.push(item);
      });

      const item = {
        companyId: element.companyId,
        driverName: element.name,
        unit: element.unit,
        email: element.email,
        phone: element.phone,
        address: element.address,
        daysPeriodTable: daysPeriod,
        totalEarnings: '',
        expenses: element.expenses,
        cashAdvance: element.cash_adv,
        bonus: element.bonus,
        rate: element.rate,
        damageFeeViolation: element.violation_damage,
        miles: element.miles,
        totalPay: '',
      };
      data.push(item);
    });

    setOpenDrop(true);

    setSendAlert(false);
    accountingService.sendStatement(data).then((res) => {
      setPdfData(res);

      setOpenDrop(false);
      if (res) {
        toast.success('Document successfuly send', {
          transition: Flip,
        });
      } else {
        toast.error(
          data.error_message ? data.error_message : 'Something went wrong',
          {
            transition: Flip,
          }
        );
      }
    });
  };

  const handleCloseDrop = () => {
    setOpenDrop(false);
  };

  const getStatementFile = () => {
    const data = [];
    sendData.forEach((itemS, i) => {
      const element = sendData[i];
      const daysPeriod = [];
      [0, 1, 2, 3, 4, 5, 6].forEach((ol, k) => {
        const item = {
          date: dayss[k],
          daysWorked: element['days_' + k],
        };
        daysPeriod.push(item);
      });

      const item = {
        companyId: element.companyId,
        driverName: element.name,
        unit: element.unit,
        email: element.email,
        phone: element.phone,
        address: element.address,
        daysPeriodTable: daysPeriod,
        totalEarnings: '',
        expenses: element.expenses,
        cashAdvance: element.cash_adv,
        bonus: element.bonus,
        rate: element.rate,
        damageFeeViolation: element.violation_damage,
        miles: element.miles,
        totalPay: '',
      };
      data.push(item);
    });

    setOpenDrop(true);

    setSendAlert(false);
    accountingService.getStatement(data).then((res) => {
      setPdfData(res);

      handleCloseDrop();
    });
  };

  const onSelectionChanged = useCallback((event) => {
    const rowCount = event.api.getSelectedNodes().length;
  }, []);

  const handleSendAlert = () => {
    setSendAlert(!sendAlert);
  };

  const validateString = (str) => {
    if (typeof str === 'string') {
      const match = str.match(/\d+(\.\d+)*/g);
      const isArray = Array.isArray(match);
      if (isArray) return match.map(Number);
    }
    return [];
  };

  const resultCallback = () => {
    console.log('transactionApplied() - ');
  };

  const onCellValueChanged = (event) => {
    console.log('data after changes is: ', event);
    console.log(validateString(event.newValue));

    if (event.column.colId === 'miles') {
      if (validateString(event.newValue).length === 1) {
        const datas = event.data;
        // eslint-disable-next-line prefer-destructuring
        datas[event.column.colId] = validateString(event.newValue)[0];

        if (event.column.colId === 'miles') {
          datas.bonus = (
            (validateString(event.newValue)[0] * 0.75) /
            2
          ).toFixed(2);
          if (datas.gross === '-') {
            datas.dpm = '-';
          } else {
            datas.dpm = (
              datas.gross / validateString(event.newValue)[0]
            ).toFixed(2);
          }
        }

        const updatedList = rowData.map((item) => {
          if (item.rowId === event.data.rowId) {
            return { ...item, ...datas };
          }
          return item; // else return unmodified item
        });

        setRowData(updatedList);

        event.api.applyTransactionAsync(
          { update: updatedList },
          resultCallback
        );
      }
    } else {
      const datas = event.data;
      const updatedList = rowData.map((item) => {
        // if (item.unit === event.data.unit) {
        //   // eslint-disable-next-line no-param-reassign
        //   item[event.column.colId] = event.newValue;
        // }
        if (item.rowId === event.data.rowId) {
          return { ...item, ...datas };
        }
        return item; // else return unmodified item
      });

      let totalEfsFuel = 0;
      let totalFuelDiscount = 0;
      let sumEfsFleetC = 0;
      let sumTollC = 0;
      let sumTotalPayC = 0;
      let sumGrossC = 0;
      let sumProfitLossC = 0;

      const newArr = updatedList.filter(
        (v, i, a) =>
          a.findIndex((v2) => ['unit'].every((k) => v2[k] === v[k])) === i
      );
      newArr.forEach((item) => {
        totalEfsFuel += item.efs_fuel * 1;
        totalFuelDiscount += item.fuel_discount * 1;
        sumEfsFleetC += item.efs_fleet * 1;
        sumTollC += item.toll * 1;
        sumTotalPayC += item.total_pay * 1;
        sumGrossC += item.gross * 1;
        sumProfitLossC +=
          item.gross * 1 -
          item.salary * 1 -
          item.fuel_efs_toll * 1 -
          item.dispatch_28 * 1 -
          item.insurance * 1 -
          item.lease * 1 -
          item.other_exp * 1;
      });
      setSumEfsFuel(totalEfsFuel);
      setSumFuelDiscount(totalFuelDiscount);

      setSumEfsFleet(sumEfsFleetC);
      setSumToll(sumTollC);
      setSumTotalPay(sumTotalPayC);
      setSumGross(sumGrossC);
      setSumProfitLoss(sumProfitLossC);

      setRowData(updatedList);
      event.api.applyTransactionAsync({ update: updatedList }, resultCallback);
    }
  };

  const currencyFormatter = (currency, sign) => {
    // console.log(typeof currency);
    if (currency && typeof (currency * 1) === 'number') {
      const formatted = ((currency * 1).toFixed(2) + '').replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ','
      );
      return sign + ` ${formatted}`;
    }
  };

  useKey('ctrls', () => {
    autoSaveData();
  });

  useEffect(() => {
    getOneAccountingFileData(id, true);
  }, []);

  const antIcon = <LoadingOutlined style={{ fontSize: 44 }} spin />;
  return (
    <>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openDrop}
        onClick={handleCloseDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SweetAlert
        title="Are you sure?"
        warning
        show={saveAlert}
        showCancel
        reverseButtons
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, save it!"
        cancelBtnText="Cancel"
        onConfirm={() => {
          updateData();
        }}
        onCancel={() => setSaveAlert(false)}
      >
        {}
      </SweetAlert>
      <SweetAlert
        title="Are you sure?"
        warning
        show={sendAlert}
        showCancel
        reverseButtons
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, send it!"
        cancelBtnText="Cancel"
        onConfirm={() => {
          sentStatementFile();
        }}
        onCancel={() => setSendAlert(false)}
      >
        {}
      </SweetAlert>
      <ModalWindow
        title="Document"
        modal={showPdfViewerModal}
        handleModal={togglePdfViewer}
        maxWidth=""
        body={
          <PdfViewer
            modal={showPdfViewerModal}
            toggle={togglePdfViewer}
            data={pdfData}
            handleSendAlert={handleSendAlert}
          />
        }
      />

      {rowData && (
        <Card style={{ height: '680px', marginRight: 8 }}>
          <div className="d-flex align-items-center mt-2 mx-2 mb-1">
            <Button
              size="sm"
              disabled={!(rowData.length > 0)}
              color="warning"
              className="d-flex align-items-center"
              type="button"
              onClick={() => {
                setSaveAlert(true);
              }}
            >
              <Icon.Save size={20} /> &nbsp; Save
            </Button>
            &nbsp;
            <Button
              size="sm"
              disabled={!(sendData.length > 0)}
              color="info"
              className="d-flex align-items-center"
              type="button"
              onClick={() => {
                getStatementFile();
                setShowPdfViewerModal(true);
              }}
            >
              <Icon.Eye size={20} /> &nbsp; Preview
            </Button>
            &nbsp;
            <Button
              size="sm"
              disabled={!(sendData.length > 0)}
              color="info"
              className="d-flex align-items-center"
              type="button"
              onClick={() => {
                setSendAlert(true);
              }}
            >
              <Icon.Send size={20} /> &nbsp;Send
            </Button>
          </div>
          {/* <div className="d-flex align-items-center mx-2 mb-1">
            <Typography>
              Auto saving <span>{minutes}</span>:<span>{seconds}</span>
            </Typography>
          </div> */}

          <CardBody className="py-0 no-pagination">
            {loading ? (
              <Spin
                indicator={antIcon}
                style={{
                  height: 'calc(100vh - 20rem)',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              />
            ) : (
              <div
                style={{ height: 550, paddingBottom: 10 }}
                className="ag-theme-alpine-dark"
              >
                {currentFileName !== '' ? (
                  <h4>Filename: {currentFileName}</h4>
                ) : null}
                <DataTable
                  rowData={rowData}
                  days={dayss}
                  onCellValueChanged={onCellValueChanged}
                  onRowSelected={onRowSelected}
                  onSelectionChanged={onSelectionChanged}
                  currencyFormatter={currencyFormatter}
                  sumEfsFuel={sumEfsFuel}
                  sumFuelDiscount={sumFuelDiscount}
                  sumEfsFleet={sumEfsFleet}
                  sumToll={sumToll}
                  sumGross={sumGross}
                  sumTotalPay={sumTotalPay}
                  sumProfitLoss={sumProfitLoss}
                />
              </div>
            )}
          </CardBody>
        </Card>
      )}
    </>
  );
}
