import React, { useState, useEffect } from 'react';
import {
  // Form,
  Form,
  Button,
  FormGroup,
  Input,
  Col,
  Card,
  CardBody,
  CardHeader,
  // Button
} from 'reactstrap';
import Table, { Thead, Tbody, Tr, Th, Td } from 'react-row-select-table';
import * as Icon from 'react-feather';
import { connect } from 'react-redux';
import { toast, Flip } from 'react-toastify';
import { Checkbox, TextField } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import jwtDecode from 'jwt-decode';
import tripService from '../../../../services/trips';
import unitService from '../../../../services/units';
import trailerService from '../../../../services/trailer_general';
import stateProvinceService from '../../../../services/state_province';
import loadService from '../../../../services/loads';

let choosedLoadsByNumber = [];
export default function NewTrip() {
  const [states, setStates] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [driver, setDriver] = useState(null);
  const [driverId, setDriverId] = useState(null);
  const [secondDriver, setSecondDriver] = useState(null);
  const [secondDrivers, setSecondDrivers] = useState([]);
  const [ownedCompanies, setOwnedCompanies] = useState([]);
  const [loads, setLoads] = useState([]);
  const [trucks, setTrucks] = useState([]);
  const [truck, setTruck] = useState(null);
  const [truckId, setTruckId] = useState(null);
  const [truckInfo, setTruckInfo] = useState(null);
  const [trailers, setTrailers] = useState([]);
  const [trailerId, setTrailerId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [trailerStatus, setTrailerStatus] = useState(false);

  // state = {
  //   states: [],
  //   drivers: [],
  //   driver: null,
  //   driverId: null,
  //   secondDriver: null,
  //   secondDrivers: [],
  //   ownedCompanies: [],
  //   loads: [],
  //   trucks: [],
  //   truck: null,
  //   truckId: null,
  //   truckInfo: null,
  //   trailers: [],
  //   trailerId: null,
  //   loading: true,
  //   trailer_status: false,
  // };

  const chooseLoad = (value) => {
    choosedLoadsByNumber = value;
  };

  const newTrip = () => {
    const loadsData = [];
    choosedLoadsByNumber.forEach((item) => {
      loadsData.push(loads[item].id);
    });

    if (loadsData.length === 0) {
      toast.error('Select loads', { transition: Flip });
    }
    const secondDriverData = secondDriver == null ? null : secondDriver.value;

    if (driverId !== undefined) {
      const data = {
        driverId,
        loadIds: loadsData,
        truckId,
        trailerId,
        unknownTrailer: trailerStatus
          ? document.querySelector('#trailerNumber').value
          : '',
        driverInstructions: document.querySelector('#driverInstructions').value,
        secondDriverId: secondDriverData,
        loadMl: document.querySelector('#load_ml').value,
        mtMl: document.querySelector('#mt_ml').value,
      };
      tripService
        .create(data)
        .then((res) => {
          if (res) {
            toast.success('Trip successfuly added', {
              transition: Flip,
            });
            window.history.back();
          } else {
            toast.error('Something went wrong', { transition: Flip });
            // res.text();
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            transition: Flip,
          });
        });
    } else {
      toast.error('Driver(s) not found. Contact the Accounting', {
        transition: Flip,
      });
    }
  };

  const loadOptions = (inputValue, callback) => {
    let dispatcher = null;
    const userRole = '';
    if (userRole === 'dispatcher') {
      const token = localStorage.getItem(
        localStorage.getItem('current_account') + '_access_token'
      );
      if (token) {
        const decodedToken = jwtDecode(token.replace('Bearer ', ''));
        dispatcher = decodedToken.userId;
      }
    }

    unitService
      .getSearch(
        `${inputValue}${dispatcher ? `&dispatcherId=${dispatcher}` : ''}`
      )
      .then((data) =>
        callback(
          data.map((el) => {
            return {
              ...el,
              value: el.id,
              label: el.number,
            };
          })
        )
      )
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const loadTrailers = (inputValue, callback) => {
    trailerService
      .getAllSearch(`search=${inputValue}`)
      .then((data) =>
        callback(
          data.data.map((el) => {
            return {
              ...el,
              value: el.id,
              label: el.trailerNumber,
            };
          })
        )
      )
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const getTrucks = () => {
    let dispatcher = null;
    const userRole = '';
    if (userRole === 'dispatcher') {
      const token = localStorage.getItem(
        localStorage.getItem('current_account') + '_access_token'
      );
      if (token) {
        const decodedToken = jwtDecode(token.replace('Bearer ', ''));
        dispatcher = decodedToken.userId;
      }
    }
    unitService
      .getAll(
        `size=1000&page=0${dispatcher ? `&dispatcherId=${dispatcher}` : ''}`
      )
      .then((data) => {
        console.log(data);
        const dataToShow = [];
        data.content.forEach((el, i) => {
          const elToShow = {
            value: el.id,
            label: el.number,
            driverId: el.driverId,
            secondDriverId: el.secondDriverId,
          };
          dataToShow.push(elToShow);
        });
        setTrucks(dataToShow);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const getTrailers = () => {
    trailerService
      .getAll(`size=1000&page=0`)
      .then((data) => {
        const dataToShow = [];
        data.data.forEach((el, i) => {
          const elToShow = {
            value: el.id,
            label: el.trailerNumber,
          };
          dataToShow.push(elToShow);
        });
        setTrailers(dataToShow);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const componentDidMount = () => {
    getTrucks();
    getTrailers();
    stateProvinceService
      .getAll()
      .then((data) => setStates(data))
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
    tripService
      .getContext()
      .then((data) => {
        setDrivers(data.drivers);
        setOwnedCompanies(data.owned_companies);

        const drivers = [];
        data.drivers.forEach((el) => {
          const elToShow = {
            value: el.id,
            label: el.lastName + ' ' + el.firstName,
          };
          drivers.push(elToShow);
        });
        setDrivers(drivers);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });

    loadService
      .getAllForTrip('page=0&size=100&deleted=false')
      .then((data) => {
        setLoads(data.content);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  useEffect(() => {
    componentDidMount();
  }, []);

  useEffect(() => {
    setDriver();
    setDriverId();
    setSecondDriver();
    if (truckInfo && truckInfo.driverId) {
      const result = drivers.filter((obj) => {
        return obj.value === parseInt(truckInfo.driverId, 10);
      });
      if (result) {
        setDriver(result[0]);
        setDriverId(truckInfo.driverId);
      }
    }
    if (truckInfo && truckInfo.secondDriverId) {
      const result = drivers.filter((obj) => {
        return obj.value === parseInt(truckInfo.secondDriverId, 10);
      });
      if (result) {
        setSecondDriver(result[0]);
      }
    }
  }, [truckInfo]);

  return (
    <Card>
      <CardHeader>
        <h3 className="mb-0">Adding a new Trip</h3>
      </CardHeader>
      <CardBody>
        {loading ? (
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 44 }} spin />}
            style={{
              height: 'calc(100vh - 20rem)',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        ) : (
          <>
            {' '}
            <Form className="d-flex">
              <div style={{ width: '50%', marginRight: 20 }}>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Truck</span>
                    <span style={{ color: 'red' }}> *</span>
                  </Col>
                  <Col md="8">
                    <AsyncSelect
                      noOptionsMessage={(value) =>
                        !value.inputValue
                          ? 'type something to search'
                          : 'nothing to show'
                      }
                      placeholder="Search"
                      isClearable={true}
                      defaultValue={truckInfo}
                      defaultOptions={trucks}
                      loadOptions={loadOptions}
                      onChange={(value) => {
                        if (value !== null) {
                          setTruckId(value.value);
                          setTruckInfo(value);
                        } else {
                          setTruckId(null);
                          setTruckInfo(null);
                        }
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary50: '#fe810b',
                          primary25: 'rgb(253, 179, 46)',
                          primary: 'rgb(253, 179, 46)',
                        },
                      })}
                    />
                  </Col>
                </FormGroup>
                {!trailerStatus ? (
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Trailer number</span>
                      <span style={{ color: 'red' }}> *</span>
                    </Col>
                    <Col md="8">
                      <AsyncSelect
                        noOptionsMessage={(value) =>
                          !value.inputValue
                            ? 'type something to search'
                            : 'nothing to show'
                        }
                        placeholder="Search"
                        isClearable={true}
                        defaultValue={null}
                        defaultOptions={trailers}
                        loadOptions={loadTrailers}
                        onChange={(value) => {
                          if (value !== null) {
                            setTrailerId(value.value);
                          } else {
                            setTrailerId(null);
                          }
                        }}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary50: '#fe810b',
                            primary25: 'rgb(253, 179, 46)',
                            primary: 'rgb(253, 179, 46)',
                          },
                        })}
                      />
                    </Col>
                  </FormGroup>
                ) : (
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Trailer number</span>
                      <span style={{ color: 'red' }}> *</span>
                    </Col>
                    <Col md="8">
                      <Input
                        type="text"
                        id="trailerNumber"
                        placeholder="Trailer number"
                      />
                    </Col>
                  </FormGroup>
                )}

                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Unknown trailer ?</span>
                  </Col>
                  <Col>
                    <FormControlLabel
                      id="trailer_status"
                      name="trailer_status"
                      control={<Checkbox />}
                      label=""
                      checked={trailerStatus}
                      onChange={(val) => {
                        setTrailerStatus(!trailerStatus);
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="4">
                    <span>Driver Instructions</span>
                  </Col>
                  <Col md="8">
                    <Input
                      type="textarea"
                      id="driverInstructions"
                      placeholder=""
                      maxLength="1000"
                      rows="3"
                    />
                  </Col>
                </FormGroup>
              </div>
              <div style={{ flex: 1 }}>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Driver</span>
                    <span style={{ color: 'red' }}> *</span>
                  </Col>
                  <Col md="8">
                    <Select
                      className="React"
                      classNamePrefix="select"
                      name="color"
                      options={drivers}
                      value={driver}
                      isClearable={true}
                      onChange={(value) => {
                        if (value !== null) {
                          setDriverId(value.value);
                          setDriver(value);
                        } else {
                          setDriverId(null);
                          setDriver(null);
                        }
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary50: '#fe810b',
                          primary25: 'rgb(253, 179, 46)',
                          primary: 'rgb(253, 179, 46)',
                        },
                      })}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Second driver(optimal)</span>
                  </Col>
                  <Col md="8">
                    <Select
                      className="React"
                      classNamePrefix="select"
                      name="color"
                      value={secondDriver}
                      options={drivers}
                      isClearable={true}
                      onChange={(val) => {
                        if (val == null) {
                          setSecondDriver(null);
                        } else {
                          setSecondDriver(val);
                        }
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary50: '#fe810b',
                          primary25: 'rgb(253, 179, 46)',
                          primary: 'rgb(253, 179, 46)',
                        },
                      })}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Load ml</span>
                  </Col>
                  <Col md="8">
                    <TextField
                      autoComplete="off"
                      fullWidth
                      size="small"
                      id="load_ml"
                      label=""
                      variant="outlined"
                      onChange={(e) => {}}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>MT ml</span>
                  </Col>
                  <Col md="8">
                    <TextField
                      autoComplete="off"
                      fullWidth
                      size="small"
                      id="mt_ml"
                      label=""
                      variant="outlined"
                      onChange={(e) => {}}
                    />
                  </Col>
                </FormGroup>
              </div>
            </Form>
            <div className="mt-3" />
            <Table value={[1, 2]} onCheck={(value) => chooseLoad(value)}>
              <Thead>
                <Tr>
                  <Th>Custom Load Number</Th>
                  <Th>Customer</Th>
                  <Th>Pickup</Th>
                  <Th>Delivery</Th>
                  <Th>From</Th>
                  <Th>To</Th>
                </Tr>
              </Thead>
              <Tbody>
                {loads.map((item) => {
                  return (
                    <Tr key={item.id}>
                      <Td>{item.customLoadNumber}</Td>
                      <Td>{item.customer}</Td>
                      <Td>{item.pickupDateFormatted}</Td>
                      <Td>{item.deliveryDateFormatted}</Td>
                      <Td>{item.from}</Td>
                      <Td>{item.to}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
            <Button
              color="success"
              className="d-flex align-items-center mt-4"
              type="button"
              onClick={() => newTrip()}
            >
              <Icon.Check size={22} /> Save Trip
            </Button>
          </>
        )}
      </CardBody>
    </Card>
  );
}
// const mapStateToProps = (state) => {
//   return {
//     token: state.auth.login.token,
//     userRole: state.auth.login.userRole,
//   };
// };
// export default connect(mapStateToProps)(NewTrip);
