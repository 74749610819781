import React, { useState, useEffect } from 'react';
import { useRoutes, Navigate, useNavigate } from 'react-router-dom';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';
// import jwtDecode from 'jwt-decode';
import { ToastContainer, toast } from 'react-toastify';
import GlobalStyles from './components/main/GlobalStyles';
import theme from './assets/theme';
import 'react-toastify/dist/ReactToastify.css';
import Modal from './components/main/Modals';
// eslint-disable-next-line import/no-named-as-default
import Login from './views/pages/Authentication/Login';

// import Register from './views/pages/Authentication/Register';
import ResetPassword from './views/pages/Authentication/ResetPassword';
import Verification from './views/pages/Authentication/Verification';
// import NewPassword from './views/pages/Authentication/NewPassword';

import Account from './views/pages/Account/Personal';

import Dashboard from './views/pages/Dashboard/List';
import DashboardLayout from './components/main/Layout/DashboardLayout';

import Trips from './views/pages/Trips/List';
import AddTrips from './views/pages/Trips/Add';
import EditTrips from './views/pages/Trips/Edit';
import ViewTrips from './views/pages/Trips/View';

import Loads from './views/pages/Loads/List';
import AddLoad from './views/pages/Loads/Add';
import EditLoad from './views/pages/Loads/Edit';

import LoadCalendar from './views/pages/LoadCalendar/List';

import Drivers from './views/pages/Drivers/List';
import AddDriver from './views/pages/Drivers/Add';
import AddFormDriver from './views/pages/Drivers/AddForm';
import FormSuccessfulDriver from './views/pages/Drivers/AddForm/FormSuccessful';
import EditDriver from './views/pages/Drivers/Edit';
import ViewDriver from './views/pages/Drivers/View';

import Units from './views/pages/Units/List';
import AddUnit from './views/pages/Units/Add';
import EditUnit from './views/pages/Units/Edit';
import ViewUnit from './views/pages/Units/View';

import Fuel from './views/pages/Fuel/List';
// import AddUnit from './views/pages/Units/Add';
// import EditUnit from './views/pages/Units/Edit';
// import ViewUnit from './views/pages/Units/View';

import Trailers from './views/pages/Trailers/General';
import AddTrailer from './views/pages/Trailers/CRUD/Add';
import EditTrailer from './views/pages/Trailers/CRUD/Edit';
import TrailerRepair from './views/pages/Trailers/Repairs';
import TrailerIdles from './views/pages/Trailers/Idles';
import TrailerHistory from './views/pages/Trailers/History';

import Reports from './views/pages/Reports';

import Accounting from './views/pages/Accounting';
import AccountingTable from './views/pages/Accounting/Table';

import Users from './views/pages/Dispatchers/List';
import AddUser from './views/pages/Dispatchers/Add';
import EditUser from './views/pages/Dispatchers/Edit';

import Companies from './views/pages/Companies/List';
import AddCompany from './views/pages/Companies/Add';
import EditCompany from './views/pages/Companies/Edit';

import Customers from './views/pages/Customers/List';
import AddCustomer from './views/pages/Customers/Add';
import EditCustomer from './views/pages/Customers/Edit';

import Locations from './views/pages/Locations/List';
import AddLocation from './views/pages/Locations/Add';

import Logs from './views/pages/Log/List';

import Chats from './views/pages/Chats';

import Settings from './views/pages/Settings';
import Apps from './views/pages/Apps';
import Instruction from './views/pages/Instruction';
import Tasks from './views/pages/Tasks/List';

import ScrollToTop from './components/main/Scroll/ScrollToTop';

import MobilePrivacy from './views/pages/Privacy/AndroidMobile';

const App = () => {
  const token = useSelector((state) => state.authReducer.access_token);
  const modal = useSelector((state) => state.storeReducer.modal);

  const protectedRoutes = [
    {
      element: <DashboardLayout />,
      children: [
        { path: '/app/account', element: <Account /> },
        { path: '/dashboard/list', element: <Dashboard /> },
        { path: '/chats', element: <Chats /> },
        { path: '/trips/list', element: <Trips /> },
        { path: '/trips/new', element: <AddTrips /> },
        { path: '/trips/edit/:id', element: <EditTrips /> },
        { path: '/trips/view/:id', element: <ViewTrips /> },

        { path: '/loads/list', element: <Loads /> },
        { path: '/loads/new', element: <AddLoad /> },
        { path: '/loads/edit/:id', element: <EditLoad /> },

        { path: '/calendar', element: <LoadCalendar /> },

        { path: '/drivers/list', element: <Drivers /> },
        { path: '/drivers/new', element: <AddDriver /> },
        { path: '/drivers/add/newForm/:id', element: <AddFormDriver /> },
        { path: '/drivers/form/successful', element: <FormSuccessfulDriver /> },
        { path: '/drivers/edit/:id', element: <EditDriver /> },
        { path: '/drivers/view/:id', element: <ViewDriver /> },

        { path: '/units/list', element: <Units /> },
        { path: '/units/new', element: <AddUnit /> },
        { path: '/units/edit/:id', element: <EditUnit /> },
        { path: '/units/view/:id', element: <ViewUnit /> },

        { path: '/fuel/list', element: <Fuel /> },

        { path: '/trailers/list', element: <Trailers /> },
        { path: '/trailers/new', element: <AddTrailer /> },
        { path: '/trailers/edit/:id', element: <EditTrailer /> },
        { path: '/trailer_repairs/list', element: <TrailerRepair /> },
        { path: '/trailer_idles/list', element: <TrailerIdles /> },
        { path: '/trailer_history/list', element: <TrailerHistory /> },

        { path: '/reports', element: <Reports /> },

        { path: '/accounting', element: <Accounting /> },
        { path: '/accounting/table/:id', element: <AccountingTable /> },

        { path: '/users/list', element: <Users /> },
        { path: '/users/new', element: <AddUser /> },
        { path: '/users/edit/:id', element: <EditUser /> },

        { path: '/companies/list', element: <Companies /> },
        { path: '/companies/new', element: <AddCompany /> },
        { path: '/companies/edit/:id', element: <EditCompany /> },

        { path: '/customers/list', element: <Customers /> },
        { path: '/customers/new', element: <AddCustomer /> },
        { path: '/customers/edit/:id', element: <EditCustomer /> },

        { path: '/locations', element: <Locations /> },
        { path: '/locations/new', element: <AddLocation /> },

        { path: '/logs', element: <Logs /> },

        { path: '/settings', element: <Settings /> },
        { path: '/tasks', element: <Tasks /> },
        { path: '/apps', element: <Apps /> },
        { path: '/instruction', element: <Instruction /> },

        { path: '*', element: <Navigate to="/dashboard/list" /> },
      ],
    },
  ];

  const publicRoutes = [
    {
      // element: <MainLayout />,
      children: [
        { path: '', element: <Navigate to="/login" /> },
        { path: '/', element: <Login /> },
        { path: '/login', element: <Login /> },
        { path: '/resetPassword', element: <ResetPassword /> },
        { path: '/verify', element: <Verification /> },
        // { path: '/newPassword', element: <NewPassword /> },
        { path: '/drivers/add/newForm/:id', element: <AddFormDriver /> },
        { path: '/drivers/form/successful', element: <FormSuccessfulDriver /> },
        // { path: '404', element: <NotFound /> },
        { path: '/mobile/privacy', element: <MobilePrivacy /> },
        { path: '*', element: <Navigate to="/login" /> },
      ],
    },
  ];

  const content = useRoutes(token ? protectedRoutes : publicRoutes);

  useEffect(() => {
    localStorage.setItem('messages', JSON.stringify([]));
    window.process = {
      ...window.process,
    };
    const el = document.getElementById('loading-screen');
    el.classList.remove('loaded');
    setTimeout(() => el.classList.add('loaded'), 2000);
  }, []);

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ScrollToTop />
          {content}
          <GlobalStyles />
        </ThemeProvider>
        <ToastContainer />
      </StyledEngineProvider>
    </>
  );
};

export default App;
