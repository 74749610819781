import React from 'react';
import PropTypes from 'prop-types';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import {
  colors,
  Box,
  InputAdornment,
  SvgIcon,
  Typography,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
// import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import TablePagination from '@mui/material/TablePagination';
import {
  Container,
  PageButtons,
  ChangeButtons,
  Button,
  ChangeButton,
} from './style';

import { useButtons } from './useButtons';

// const optionData = [
//   { value: 10, label: '10 item view' },
//   { value: 25, label: '25 item view' },
//   { value: 50, label: '50 item view' },
//   { value: 100, label: '100 item view' },
// ];

const Pagination = ({
  canPreviousPage,
  canNextPage,
  total,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  pageSize,
  setPageSize,
  pageIndex,
  hidePagination,
}) => {
  const buttons = useButtons({ pageCount, pageIndex });
  // const { t } = useTranslation();

  const handlePageChange = (title) => {
    if (title !== '...') gotoPage(Number(title));
  };

  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
    localStorage.setItem('page_size', e.target.value);
  };

  const pages = [10, 25, 50, 100, 150];

  const getPageSize = () => {
    if (localStorage.getItem('page_size') !== null) {
      const size = localStorage.getItem('page_size') * 1;
      if (Number.isNaN(size)) {
        return 10;
      }
      return size;
    }
    return 10;
  };

  return (
    <Container>
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth size="small">
          <InputLabel id="demo-simple-select-label">
            Select page size
          </InputLabel>
          <Select
            sx={{ color: 'black' }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={getPageSize()}
            label="Select page size"
            onChange={handlePageSizeChange}
          >
            {pages.map((item, i) => {
              return (
                <MenuItem key={`${i + 1}`} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ width: 20 }} />
      <ChangeButton
        color={!canPreviousPage ? '#C4C4C4' : '#E17436'}
        disabled={!canPreviousPage}
        onClick={previousPage}
      >
        <ChevronLeft
          // color={!canPreviousPage ? '#C4C4C4' : '#262626'}
          fontSize="medium"
        />
      </ChangeButton>
      <PageButtons repeat={pageCount > 7 ? 7 : pageCount}>
        {buttons.map((title, index) => (
          <Button
            key={`${index + 1}`}
            onClick={() => handlePageChange(title)}
            // eslint-disable-next-line eqeqeq
            active={pageIndex == title}
          >
            {title !== '...' ? title + 1 : title}
          </Button>
        ))}
      </PageButtons>
      <ChangeButton
        color={!canNextPage ? '#C4C4C4' : '#E17436'}
        disabled={!canNextPage}
        onClick={nextPage}
      >
        <ChevronRight
          // color={!canPreviousPage ? '#C4C4C4' : '#262626'}
          fontSize="medium"
        />
      </ChangeButton>
      <Box sx={{ width: 20 }} />
      <Typography
        sx={{ fontSize: 14 }}
        my={1}
        align="center"
        color={colors.grey[700]}
      >
        ({pageIndex * pageSize + 1}
        {canNextPage || pageIndex * (pageSize + 1) <= total ? ' - ' : ''}
        {!canNextPage && pageIndex * (pageSize + 1) <= total ? total : ''}
        {!canNextPage ? ' ' : pageSize * (pageIndex + 1)}) (#
        {total})
      </Typography>
    </Container>
  );
};

Pagination.propTypes = {
  pageCount: PropTypes.number,
};
Pagination.defaultProps = {
  pageCount: 40,
};

export default Pagination;
