import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Client } from '@stomp/stompjs';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Grid, TextField, Box, Button } from '@mui/material';
import jwtDecode from 'jwt-decode';
import Sidebar from './Sidebar';
import ChatForm from './ChatForm';
import { setNewNotification } from '../../../redux/modules/store/actions';

export default function Chats() {
  const dispatch = useDispatch();
  const ref = useRef();
  const [messagesData, setMessagesData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [user, setUser] = useState({ id: 0 });
  const [selectUserId, setSelectUserId] = useState();
  const token = localStorage.getItem(
    localStorage.getItem('current_account') + '_access_token'
  );
  const [stompClient, setStompClient] = useState(
    new Client({
      connectHeaders: { Authorization: 'Bearer ' + token },
      brokerURL: process.env.REACT_APP_SOCKET_URL,
    })
  );

  const decodedToken = jwtDecode(token.replace('Bearer ', ''));
  const userId = localStorage.getItem('userId');

  const onDisconnected = () => {
    console.log('Disconnected!!');
  };

  const getMessages = (item) => {
    if (user.id !== item.id) {
      localStorage.setItem('msg_scroll', '0');
      setUser(item);
      setSelectUserId(item.id);
      const pageIndex = localStorage.getItem('pageIndex') * 1;
      const totalPages = localStorage.getItem('totalPages') * 1;
      if (pageIndex <= totalPages) {
        const quote = {
          userId1: userId,
          userId2: item.id,
          page: pageIndex,
          size: 30,
        };
        stompClient.publish({
          destination: '/app/web/getMessages',
          headers: {
            Authorization: localStorage.getItem(
              localStorage.getItem('current_account') + '_access_token'
            ),
          },
          body: JSON.stringify(quote),
        });
      }
    }
  };

  const getMessages2 = () => {
    const pageIndex = localStorage.getItem('pageIndex') * 1;
    const totalPages = localStorage.getItem('totalPages') * 1;
    if (pageIndex <= totalPages) {
      const quote = {
        userId1: userId,
        userId2: selectUserId,
        page: pageIndex,
        size: 30,
      };
      stompClient.publish({
        destination: '/app/web/getMessages',
        headers: {
          Authorization: localStorage.getItem(
            localStorage.getItem('current_account') + '_access_token'
          ),
        },
        body: JSON.stringify(quote),
      });
    }
  };

  const addUser = (client) => {
    const quote = {
      sender: userId,
      messageType: 'JOIN',
    };
    client.publish({
      destination: '/app/topic/newUser/' + userId,
      headers: {
        Authorization: localStorage.getItem(
          localStorage.getItem('current_account') + '_access_token'
        ),
      },
      body: JSON.stringify({ message: quote }),
    });
  };

  const sendMessage = (text) => {
    const quote = {
      senderUserId: userId,
      receiverUserId: user.id,
      text: [{ data: text }],
      userType: 'driver',
      messageType: 'text',
    };
    stompClient.publish({
      destination: '/app/web/sendMessage',
      headers: {
        Authorization: localStorage.getItem(
          localStorage.getItem('current_account') + '_access_token'
        ),
      },
      body: JSON.stringify(quote),
    });
  };

  useEffect(() => {
    localStorage.setItem('pageIndex', '0');
    localStorage.setItem('totalPages', '0');
    const stompClient1 = new Client({
      connectHeaders: { Authorization: 'Bearer ' + token },
      brokerURL: process.env.REACT_APP_SOCKET_URL,
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
      onConnect: () => {
        console.log('Connected!!');
        addUser(stompClient1);
        stompClient1.subscribe('/topic/user/' + userId, (message) => {
          const data = JSON.parse(message.body);
          if (data.receiverId * 1 === userId * 1) {
            dispatch(setNewNotification(true));
          }

          const messageData = JSON.parse(localStorage.getItem('messages'));
          const res = [data];
          setMessagesData([...messageData, ...res]);
          localStorage.setItem(
            'messages',
            JSON.stringify([...messageData, ...res])
          );
          // ref.current.scrollIntoView({ behavior: 'smooth' });
        });

        stompClient1.subscribe('/topic/messages/user/' + userId, (message) => {
          const pageIndex = localStorage.getItem('pageIndex');
          if (pageIndex * 1 === 0) {
            setMessagesData(JSON.parse(message.body).content.reverse());
            localStorage.setItem(
              'messages',
              JSON.stringify(JSON.parse(message.body).content.reverse())
            );
          } else {
            const data = JSON.parse(message.body).content.reverse();
            const messageData = JSON.parse(localStorage.getItem('messages'));

            setMessagesData([...data, ...messageData]);
            localStorage.setItem(
              'messages',
              JSON.stringify([...data, ...messageData])
            );
          }
          localStorage.setItem('pageIndex', pageIndex * 1 + 1);
          localStorage.setItem(
            'totalPages',
            JSON.parse(message.body).totalPages
          );
          ref.current.scrollIntoView({ behavior: 'smooth' });
        });
      },
      onDisconnect: onDisconnected,
    });

    setStompClient(stompClient1);
    stompClient1.activate();
  }, []);

  return (
    <>
      <Helmet>
        <title> Chats </title>
      </Helmet>
      <Box
        sx={{
          paddingRight: 1,
        }}
      >
        <Card style={{ paddingTop: 10, paddingLeft: 5 }}>
          <Grid container spacing={2}>
            <Grid item md={3} xs={12}>
              <Sidebar setUser={setUser} getMessages={getMessages} />
            </Grid>
            <Grid item md={9} xs={12}>
              <ChatForm
                refer={ref}
                page={localStorage.getItem('pageIndex') * 1}
                totalPage={localStorage.getItem('totalPages') * 1}
                getMessages={getMessages2}
                user={user}
                userId={userId}
                messages={messagesData}
                sendMessage={sendMessage}
              />
            </Grid>
          </Grid>
        </Card>
      </Box>
    </>
  );
}
