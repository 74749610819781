/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unknown-property */
import React, {
  useEffect,
  useState,
  useCallback,
  createRef,
  useRef,
  useMemo,
} from 'react';
import {
  Box,
  Container,
  InputAdornment,
  SvgIcon,
  Typography,
  Divider,
  Checkbox,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import { usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import Pagination from './Pagination';
import Tooltip from './Tooltip';
import './style.css';
import './table_style.scss';
import ScrollToTop from '../Scroll/ScrollToTop';

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, color = '', ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <Checkbox
        sx={{
          color: color ? '#fff' : 'rgba(47, 46, 46, 0.65)',
          '&.Mui-checked': {
            color: '#080D85',
          },
        }}
        ref={resolvedRef}
        {...rest}
      />
    );
  }
);

export default function TableData({
  data,
  headers,
  toolTips,
  onChange,
  fetchData,
  load,
  modal,
  deleteModal,
  doubleClick,
  hidePagination,
  columns,
  setColumns,
}) {
  const [selectedRows, setSelectedRows] = useState({});
  const [indexT, setIndexT] = useState(-1);
  const location = useLocation();

  const [activeIndex, setActiveIndex] = useState(null);
  const tableElement = useRef(null);

  const totals = localStorage.getItem('total_data');
  const [pgCount, setPgCount] = useState(0);

  const scrollRef = useRef();
  const [showTopBtn, setShowTopBtn] = useState(false);

  const getPageSize = () => {
    if (localStorage.getItem('page_size') !== null) {
      const size = localStorage.getItem('page_size') * 1;
      if (Number.isNaN(size)) {
        return 10;
      }
      return size;
    }
    return 10;
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns: headers,
      data,
      manualSortBy: true,
      initialState: { pageIndex: 0, pageSize: getPageSize() },
      manualPagination: true,
      pageCount: pgCount,
      autoResetPage: true,
    },
    useSortBy,
    usePagination,
    useRowSelect
    // (hooks) => {
    //   hooks.visibleColumns.push((columns) => [
    //     {
    //       id: 'selection',
    //       Header: ({ getToggleAllPageRowsSelectedProps }) => (
    //         <div>
    //           <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
    //         </div>
    //       ),
    //       Cell: ({ row }) => (
    //         <div>
    //           <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    //         </div>
    //       ),
    //     },
    //     ...columns,
    //   ]);
    // }
  );

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    setSelectedRows(selectedFlatRows.map((item) => item.original.id));
    if (fetchData) fetchData({ pageIndex, pageSize });
    onChange({ pageIndex, pageSize });
    const count = Math.ceil(totals / pageSize);
    setPgCount(count);
  }, [
    setSelectedRows,
    selectedRowIds,
    pageIndex,
    pageSize,
    onChange,
    fetchData,
    totals,
  ]);

  const mouseDown = (index) => {
    // console.log(index);
    setActiveIndex(index);
  };

  const mouseMove = useCallback(
    (e) => {
      const gridColumns = columns.map((col, i) => {
        if (i === activeIndex - 1) {
          // Calculate the column width
          // console.log(col.ref.current.offsetLeft);
          const width = e.clientX - col.ref.current.offsetLeft * i;
          // console.log(e.clientX);

          // if (width >= 100) {
          //   return `${width}px`;
          // }
          // eslint-disable-next-line no-param-reassign
          col.item.width = width;
          return col;
        }
        return col;
        // Otherwise return the previous width (no changes)
      });
      setColumns(gridColumns);

      // Assign the px values to the table
      // tableElement.current.style.gridTemplateColumns = `${gridColumns.join(
      //   ' '
      // )}`;
      // console.log(gridColumns);
    },
    [activeIndex]
  );

  const removeListeners = useCallback(() => {
    window.removeEventListener('mousemove', mouseMove);
    window.removeEventListener('mouseup', removeListeners);
  }, [mouseMove]);

  const mouseUp = useCallback(() => {
    setActiveIndex(null);
    removeListeners();
  }, [setActiveIndex, removeListeners]);

  useEffect(() => {
    if (activeIndex !== null) {
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('mouseup', mouseUp);
    }

    return () => {
      removeListeners();
    };
  }, [activeIndex, mouseMove, mouseUp, removeListeners]);

  const checkLocationPath = () => {
    if (location.pathname === '/dashboard/list') {
      return false;
    }
    if (location.pathname === '/locations') {
      return false;
    }
    if (location.pathname === '/logs') {
      return false;
    }
    return true;
  };

  const handleTop = () => {
    scrollRef.current.scrollTop = 0;
    setShowTopBtn(false);
  };

  const handleScroll = () => {
    if (scrollRef.current.scrollTop > 50) {
      if (!showTopBtn) setShowTopBtn(true);
    } else if (showTopBtn) setShowTopBtn(false);
  };

  useEffect(() => {
    const temp = scrollRef.current;

    temp.addEventListener('scroll', handleScroll);
    return () => temp.removeEventListener('scroll', handleScroll);
  });

  const columnRefs = columns.map(() => createRef());
  const isResizing = useRef(-1);

  function loadColumnInfoLocalStorage() {
    let columnsInfo = localStorage.getItem('columnsInfo');
    if (columnsInfo) {
      columnsInfo = JSON.parse(columnsInfo);
      Object.keys(columnsInfo).forEach((colField, index) => {
        columnRefs[index].current.parentElement.style.width =
          columnsInfo[colField];
      });
    }
  }

  const saveColumnInfoLocalStorage = () => {
    const columnsInfo = {};
    columns.forEach((col, index) => {
      columnsInfo[col.field] = {};
      columnsInfo[col.field] =
        columnRefs[index].current.parentElement.style.width;
    });
    localStorage.setItem('columnsInfo', JSON.stringify(columnsInfo));
  };

  const adjustWidthColumn = (index, width) => {
    const { minWidth } = columns[index];
    const { maxWidth } = columnRefs[index];
    const newWidth =
      // eslint-disable-next-line no-nested-ternary
      width > maxWidth ? maxWidth : width < minWidth ? minWidth : width;

    columnRefs[index].current.parentElement.style.width = newWidth + 'px';
  };

  const setCursorDocument = (isResizing) => {
    // document.body.style.cursor = isResizing ? 'col-resize' : 'auto';
  };

  const handleOnMouseMove = (e) => {
    if (isResizing.current >= 0) {
      const newWidth =
        e.clientX -
        columnRefs[
          isResizing.current
        ].current.parentElement.getBoundingClientRect().left;
      adjustWidthColumn(isResizing.current, newWidth);
    }
  };

  const handleOnMouseUp = () => {
    console.log('end resize');
    isResizing.current = -1;
    saveColumnInfoLocalStorage();
    setCursorDocument(false);
  };

  const onClickResizeColumn = (index) => {
    console.log('start resize');
    isResizing.current = index;
    setCursorDocument(true);
  };

  return (
    <Paper sx={{ width: '100%' }}>
      <ScrollToTop showTopBtn={showTopBtn} handleTop={handleTop} />
      <TableContainer
        ref={scrollRef}
        sx={{
          height: data.length !== 0 ? windowSize[1] - 64 - 230 + 'px' : '100px',
          // overflow: 'auto',
          // overflowY: 'scroll', // added scroll
          '&::-webkit-scrollbar': {
            // scrollBehavior: 'smooth',
            // width: 6,
          },
          '&::-webkit-scrollbar-track': {
            height: 1,
            // boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'darkgrey',
            // outline: '1px solid slategrey',
            borderRadius: 1,
            height: 1,
          },
        }}
      >
        <Table
          className="table"
          stickyHeader={location.pathname !== '/tasks'}
          aria-label="sticky table"
          ref={tableElement}
        >
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {columns.map(({ item, ref }, idx) =>
                  item.Header === 'Actions' ? (
                    <TableCell
                      // className="tableCell resizable"
                      key={`${idx + 1}`}
                      align="center"
                      // className={`resize-handle ${
                      //   activeIndex === idx ? 'active' : 'idle'
                      // }`}
                      sx={{
                        fontSize: '12px',
                        fontWeight: 'bold',
                        minWidth: item.width ? item.width : 200,
                        // paddingLeft: 0,
                        // textAlign: 'right',
                        color: 'white',
                        backgroundColor: '#2A455F',
                      }}
                    >
                      {typeof item.Header === 'string'
                        ? `${item.Header}`.toUpperCase()
                        : ''}
                      <div
                        onMouseDown={() => onClickResizeColumn(idx)}
                        ref={columnRefs[idx]}
                        className="resizeLine"
                      />
                    </TableCell>
                  ) : (
                    <TableCell
                      // className={`resize-handle ${
                      //   activeIndex === idx ? 'active' : 'idle'
                      // }`}
                      key={`${idx + 1}`}
                      align="center"
                      sx={{
                        // display: 'flex',
                        fontSize: '12px',
                        fontWeight: 'bold',
                        minWidth: item.width ? item.width : 200,
                        // paddingLeft: 0,
                        // textAlign: 'left',
                        color: 'white',
                        backgroundColor: '#2A455F',
                      }}
                    >
                      <div
                        style={
                          {
                            // display: 'flex',
                            // flexDirection: 'row',
                            // gap: '10px',
                            // justifyContent: 'flex-end',
                          }
                        }
                      >
                        {typeof item.Header === 'string'
                          ? `${item.Header}`.toUpperCase()
                          : ''}
                        <div
                          onMouseDown={() => onClickResizeColumn(idx)}
                          ref={columnRefs[idx]}
                          className="resizeLine"
                        />
                        {/* <Divider
                          ref={ref}
                          onMouseDown={() => {
                            mouseDown(idx);
                          }}
                          orientation="vertical"
                          flexItem
                          sx={{
                            bgcolor: 'white',
                            width: '1.5px',
                            cursor: 'col-resize',
                          }}
                        /> */}
                      </div>
                    </TableCell>
                  )
                )}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <TableRow
                  key={`${index + 1}`}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    height: '15px',
                  }}
                >
                  {row.cells.map((cell, ind) =>
                    cell.column.Header === 'Actions' ? (
                      <TableCell
                        className="tableCell"
                        key={`${ind + 1}`}
                        align="center"
                        sx={
                          {
                            // textAlign: 'right',
                          }
                        }
                      >
                        <Tooltip
                          indexT={indexT}
                          index={index}
                          itemId={row.original.id}
                          deleteStatus={row.original.deleted}
                          status={row.original.status}
                          data={toolTips}
                          load={load}
                          modal={modal}
                          deleteModal={deleteModal}
                          setIndexT={setIndexT}
                        />
                      </TableCell>
                    ) : (
                      <TableCell
                        className="tableCell"
                        key={`${ind + 1}`}
                        align="center"
                        sx={{
                          // paddingLeft: 0,
                          fontSize: '13px',
                          // textAlign: 'left',
                        }}
                        style={{
                          cursor: checkLocationPath() ? 'pointer' : '',
                        }}
                        onDoubleClick={() => {
                          doubleClick(row.original.id, row.original.table);
                        }}
                      >
                        {cell.column.prefix}
                        <span>{cell.render('Cell')}</span>
                        {` ${cell.column.suffix || ''}`}
                      </TableCell>
                    )
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {data.length !== 0 ? (
        <Box
          sx={{
            backgroundColor: 'background.default',
            // py: 1,
          }}
        >
          <Pagination
            canPreviousPage={canPreviousPage}
            pageOptions={pageOptions}
            canNextPage={canNextPage}
            total={totals}
            pageCount={pageCount}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            setPageSize={setPageSize}
            pageSize={pageSize}
            pageIndex={pageIndex}
            hidePagination={hidePagination}
          />
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: 'background.default',
            // minHeight: '100%',
            py: 1,
          }}
        >
          <Container maxWidth={false}>
            <h2 align="center">No data</h2>
          </Container>
        </Box>
      )}
    </Paper>
  );
}
