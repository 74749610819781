import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Chip,
} from '@mui/material';
import jwtDecode from 'jwt-decode';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
// import InputIcon from '@mui/icons-material/Input';
import Logo from '../../Logo';
// import LogoIcon from '../../Logo/logo';
// import SelectLanguageBtn from '../../Language';
import SelectAccountBtn from '../../Account';
import ProfileBtn from '../../Profile';
import NotificationBtn from '../../Notifications';

const Navbar = ({ onMobileNavOpen, ...rest }) => {
  const matches = useMediaQuery('(min-width:1200px)');
  const token = localStorage.getItem(
    localStorage.getItem('current_account') + '_access_token'
  );

  const decodedToken = jwtDecode(token);

  return (
    <AppBar
      // elevation={1}
      {...rest}
      sx={{
        backgroundColor: 'background.default',
      }}
    >
      <Toolbar>
        {/* <Box sx={{ flexGrow: 1 }} /> */}
        {!matches ? (
          <Hidden>
            <IconButton onClick={onMobileNavOpen} size="small">
              <MenuIcon
                sx={{
                  color: 'darker.default',
                }}
              />
            </IconButton>
          </Hidden>
        ) : (
          <></>
        )}

        {matches ? (
          <Hidden>
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Hidden>
        ) : (
          <></>
        )}

        <Box sx={{ flexGrow: 3 }} />
        <Hidden>
          <NotificationBtn />
        </Hidden>
        <Box sx={{ paddingRight: 1, paddingLeft: 1 }} />
        <Hidden>
          <SelectAccountBtn />
        </Hidden>
        <Box sx={{ paddingRight: 2 }} />
        <Hidden>
          <ProfileBtn />
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Navbar.propTypes = {
  onMobileNavOpen: PropTypes.func,
};

export default Navbar;
