import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { byStatus } from './helper';
import { useStyles } from '../style';
import FilterButton from '../../FormElements/FilterButton';

export default ({ deleted, setDeleted }) => {
  const classes = useStyles();
  const [selectedFilter, setSelectedFilter] = useState(0);

  const handleDeleted = (e) => {
    setDeleted(e.target.value ? 1 : 0);
    if (!deleted) {
      setSelectedFilter((prev) => prev + 1);
    }
  };
  const handleClearFilters = () => {
    setDeleted('');
    setSelectedFilter(0);
  };

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.root}>
        <FormControl size="small">
          <InputLabel id="status"> Filter by delete</InputLabel>
          <Select
            className={classes.input}
            value={deleted}
            onChange={handleDeleted}
            labelId="delete"
            label="Filter by delete"
          >
            {byStatus.map((item, index) => (
              <MenuItem key={`${index + 1}`} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <FilterButton
        selectedFilter={selectedFilter}
        handleClearFilters={handleClearFilters}
      />
    </Box>
  );
};
