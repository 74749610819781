export default {
  TABLE_DRIVER_TRIPS_FETCH_STATUSES: 'TABLE_DRIVER_TRIPS_FETCH_STATUSES',
  TABLE_DRIVER_TRIPS_SET_DATA: 'TABLE_DRIVER_TRIPS_SET_DATA',
  TABLE_DRIVER_TRIPS_SET_TOTAL: 'TABLE_DRIVER_TRIPS_SET_TOTAL',
  TABLE_DRIVER_TRIPS_SET_LOADING: 'TABLE_DRIVER_TRIPS_SET_LOADING',
  TABLE_DRIVER_TRIPS_ERROR: 'TABLE_DRIVER_TRIPS_ERROR',
  TABLE_DRIVER_TRIPS_FETCH_DATA: 'TABLE_DRIVER_TRIPS_FETCH_DATA',
  TABLE_DRIVER_TRIPS_SAGA_ONSEARCH_ACTION:
    'TABLE_DRIVER_TRIPS_SAGA_ONSEARCH_ACTION',
};
