import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
} from 'reactstrap';

export default function ShowEditModal({
  modal,
  toggle,
  addFolder,
  editFolder,
  name,
}) {
  return (
    <>
      <Form>
        <FormGroup>
          <Input
            defaultValue={name || ''}
            type="text"
            id="folder_name"
            placeholder="name"
            onChange={(e) => {
              document.querySelector('#folder_name').value = e.target.value;
            }}
          />
        </FormGroup>
      </Form>

      <Button
        color="success"
        className="d-flex align-items-center"
        type="button"
        onClick={() => {
          if (name) {
            editFolder(document.querySelector('#folder_name').value);
          } else {
            addFolder(document.querySelector('#folder_name').value);
          }

          toggle();
        }}
      >
        OK
      </Button>
    </>
  );
}
