import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast, Flip } from 'react-toastify';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { setRefreshPage } from '../../../../redux/modules/store/actions';
import Approve from './Modals';
import ModalWindowFullScreen from '../../Modals/FullScreen';

export default ({ value }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <ModalWindowFullScreen
        title="Driver approve"
        modal={showModal}
        handleModal={() => {
          setShowModal(!showModal);
          dispatch(setRefreshPage(true));
        }}
        body={<Approve modal={showModal} item={value} />}
      />
      <div style={{}}>
        <Button
          variant="outlined"
          startIcon={
            value.driverAccept ? (
              <CheckCircleIcon style={{ color: 'green' }} />
            ) : (
              <HighlightOffIcon style={{ color: 'red' }} />
            )
          }
          endIcon={
            value.driverApprove ? (
              <CheckCircleIcon style={{ color: 'green' }} />
            ) : (
              <HighlightOffIcon style={{ color: 'red' }} />
            )
          }
          onClick={() => {
            toggleModal();
          }}
        >
          {}
        </Button>
      </div>
    </>
  );
};
