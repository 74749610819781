import React, { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import Moment from 'react-moment';
import {
  Form,
  FormGroup,
  Input,
  Label,
  CustomInput,
  Col,
  Card,
  CardBody,
  CardHeader,
} from 'reactstrap';
import {
  colors,
  Box,
  CardMedia,
  Button,
  Grid,
  CircularProgress,
  FormControl,
  InputLabel,
  Typography,
  FormHelperText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Stack,
  Autocomplete,
  Paper,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import * as Icon from 'react-feather';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast, Flip } from 'react-toastify';
import Flatpickr from 'react-flatpickr';
import Cropper from 'react-cropper';
import statementService from '../../../../../../services/statements';

export default function AdditionForm({
  toggle,
  additionId,
  driverId,
  truckId,
}) {
  const token = localStorage.getItem(
    localStorage.getItem('current_account') + '_access_token'
  );
  const decodedToken = jwtDecode(token);

  const validationSchema = Yup.object().shape({
    date: Yup.string().required('required'),
    amount: Yup.string().required('required'),
    note: Yup.string().required('required'),
  });

  const formik = useFormik({
    initialValues: {
      date: new Date().getTime(),
      amount: '',
      note: '',
      driverId,
      truckId,
    },
    validationSchema,
    onSubmit: (values) => {
      const data = {
        date: new Date(document.querySelector('#date').value).getTime(),
        amount: values.amount,
        note: values.note,
        driverId: values.driverId,
        truckId: values.truckId,
      };
      if (additionId) {
        data.id = additionId;
        statementService.updateAddition(data).then((res) => {
          console.log(res);
          toggle();
        });
      } else {
        statementService.createAddition(data).then((res) => {
          console.log(res);
          toggle();
        });
      }
    },
  });

  const dateFormat = (dateValue) => {
    const date = new Date(dateValue + 1 * 24 * 60 * 60 * 1000);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const dateS = day.toString().padStart(2, '0');

    const monthS = month.toString().padStart(2, '0');

    return `${year}-${monthS}-${dateS}`;
  };

  const componentDidMount = () => {
    statementService
      .getOneAddition(additionId)
      .then((data) => {
        formik.setValues({
          ...formik.values,
          date: data.date,
          amount: data.amount,
          note: data.note,
        });
        if (data.date !== null) {
          document.querySelector('#date').value = dateFormat(data.date);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  useEffect(() => {
    if (additionId) {
      componentDidMount();
    }
  }, []);

  const renderButton = (state, isSubmitting) => {
    if (state) {
      if (isSubmitting) {
        return 'Updating';
      }
      return 'Update';
    }
    if (isSubmitting) {
      return 'Submitting';
    }
    return 'Submit';
  };

  return (
    <>
      <Card>
        <CardHeader>
          <h3 className="mb-0">Adding a new addition</h3>
        </CardHeader>
        <CardBody>
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <Grid
              container
              direction="row"
              // justifyContent="space-between"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={12} md={12} sm={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography
                      variant="h5"
                      align="left"
                      color={colors.grey[700]}
                    >
                      Date
                    </Typography>
                    <Flatpickr
                      id="date"
                      className="form-control"
                      // options={{
                      //   dateFormat: 'Z',
                      //   altInput: true,
                      //   altFormat: 'm-d-Y',
                      // }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12} sm={12}>
                    <Typography
                      variant="h5"
                      align="left"
                      color={colors.grey[700]}
                    >
                      Amount
                    </Typography>
                    <TextField
                      // label="Amount"
                      // className={classes.search}
                      name="amount"
                      autoComplete="namer"
                      type="text"
                      fullWidth
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      variant="outlined"
                      // placeholder={t('enter_full_name')}
                      value={formik.values.amount}
                      error={
                        formik.touched.amount && Boolean(formik.errors.amount)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography
                      variant="h5"
                      align="left"
                      color={colors.grey[700]}
                    >
                      Note
                    </Typography>
                    <TextField
                      // label="Note"
                      required
                      size="small"
                      InputLabelProps={{
                        style: { color: 'black' },
                      }}
                      inputProps={{ maxLength: 256, disableUnderline: true }}
                      name="note"
                      fullWidth
                      multiline
                      rows={4}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      variant="outlined"
                      value={formik.values.note}
                      // error={formik.touched.notes && Boolean(formik.errors.notes)}
                      erorText="Please enter only 12 symbols"
                      helperText={`Limit: ${formik.values.note.length}/256`}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
                py: 2,
              }}
            >
              <Button
                sx={{
                  backgroundColor: '#CFE0F0',
                  color: '#161616',
                  // textTransform: 'none',
                }}
                variant="contained"
                onClick={() => {
                  toggle();
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  // backgroundColor: '#2A455F',
                  color: 'white',
                  // textTransform: 'none',
                }}
                name="add"
                variant="contained"
                type="submit"
                color="primary"
                onClick={() => console.log()}
                startIcon={
                  formik.isSubmitting ? (
                    <CircularProgress size="0.9rem" />
                  ) : undefined
                }
              >
                {renderButton(null, formik.isSubmitting)}
              </Button>
            </Box>
          </form>
        </CardBody>
      </Card>
    </>
  );
}
