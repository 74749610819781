import React, { useState, useEffect, useMemo } from 'react';
import {
  Col,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Button,
  Input,
  Row,
  Form,
  FormGroup,
  Label,
  CardHeader,
} from 'reactstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import { Pagination, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import * as Icon from 'react-feather';
import {
  // Button,
  Card,
  Grid,
  TextField,
  Stack,
  colors,
  Typography,
  IconButton,
} from '@mui/material';
import { toast, Flip } from 'react-toastify';
import jwtDecode from 'jwt-decode';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { AnimatePresence } from 'framer-motion';
import DownloadFilterTools from '../../../../components/main/FilteredBody/TrailerHistoryDownload';
import AnimationDiv from '../../../../components/main/Animations/Transition';
import { fetchData } from '../../../../redux/modules/trailer_history/actions';
import { useQuery } from '../../useQuery';
import tripService from '../../../../services/trips';
import Loading from '../../../../components/main/Loading';
import Table from '../../../../components/main/table';
import trailerGeneralService from '../../../../services/trailer_general';
import { trailerHistoryHeader } from '../../../../redux/constants/table/headers';
import { headerMaker } from '../../../../components/main/table/helper';
import { toolTips } from './helper';

export default function TrailerHistory() {
  const {
    query,
    search,
    trailerStatus,
    trailerIds,
    start,
    end,
    setSearch,
    setTrailerStatus,
    setTrailerIds,
    setStart,
    setEnd,
    handleOnTableChange,
  } = useQuery({ fetchData });

  // const trailerHistoryColumnDefs = [
  //   {
  //     headerName: '#',
  //     field: 'index',
  //     maxWidth: 70,
  //     flex: 1,
  //     filter: false,
  //     cellStyle: (params) => {
  //       return {
  //         fontWeight: '500',
  //       };
  //     },
  //   },
  //   {
  //     headerName: 'Trailer',
  //     field: 'trailerNumber',
  //     filter: 'agTextColumnFilter',
  //     minWidth: 150,
  //     flex: 1,
  //   },
  //   {
  //     headerName: 'Trip',
  //     field: 'id',
  //     minWidth: 140,
  //     filter: 'agTextColumnFilter',
  //     flex: 1,
  //   },
  //   {
  //     headerName: 'Unit',
  //     field: 'unitNumber',
  //     filter: 'agTextColumnFilter',
  //     minWidth: 150,
  //     flex: 1,
  //   },

  //   {
  //     headerName: 'Load №',
  //     field: 'loadNumber',
  //     filter: 'agTextColumnFilter',
  //     minWidth: 150,
  //     flex: 1,
  //     cellRendererFramework: function (params) {
  //       return (
  //         <div className="drivers-col">
  //           <Link
  //             className="link-in-table"
  //             to={`/loads/edit/${params.data.loadId}`}
  //           >
  //             {params.value}
  //           </Link>
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     headerName: 'Pickup',
  //     field: 'pickup',
  //     maxWidth: 150,
  //     minWidth: 150,
  //     filter: false,
  //     flex: 1,
  //   },
  //   {
  //     headerName: 'Delivery',
  //     field: 'delivery',
  //     maxWidth: 150,
  //     minWidth: 150,
  //     filter: false,
  //     flex: 1,
  //   },
  //   {
  //     headerName: 'Driver',
  //     field: 'driver',
  //     minWidth: 300,
  //     maxWidth: 300,
  //     filter: false,
  //     flex: 1,
  //     cellRendererFramework: function (params) {
  //       return (
  //         <div className="drivers-col">
  //           <Link
  //             className="link-in-table"
  //             to={`/driver/view/${params.data.driverId}`}
  //           >
  //             {params.value}
  //           </Link>
  //           {params.data.teammateId !== null && (
  //             <Link
  //               className="link-in-table"
  //               to={`/driver/view/${params.data.teammateId}`}
  //             >
  //               {params.data.teammateName}
  //             </Link>
  //           )}
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     headerName: 'Status',
  //     field: 'statusName',
  //     minWidth: 100,
  //     filter: false,
  //     flex: 1,
  //     cellStyle: function (params) {
  //       return {
  //         fontSize: '13px',
  //         color: params.data.statusColor ? 'white' : 'black',
  //         backgroundColor: params.data.statusColor
  //           ? params.data.statusColor
  //           : 'white',
  //         textAlign: 'center',
  //         textTransform: 'uppercase',
  //       };
  //     },
  //   },
  //   {
  //     headerName: 'Accessory Driver Pay',
  //     field: 'accessoryDriverPay',
  //     maxWidth: 170,
  //     filter: false,
  //     flex: 1,
  //   },
  //   {
  //     headerName: 'Driver Advance',
  //     field: 'driverAdvance',
  //     maxWidth: 140,
  //     filter: false,
  //     flex: 1,
  //   },
  //   {
  //     headerName: 'To',
  //     field: 'to',
  //     minWidth: 150,
  //     filter: false,
  //     flex: 1,
  //     tooltip: (params) => {
  //       return params.value;
  //     },
  //   },
  // ];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isDownloadShow, setDownloadShow] = useState(false);
  const [trailerList, setTrailerList] = useState([]);

  const headers = useMemo(
    () => headerMaker(trailerHistoryHeader),
    [trailerHistoryHeader]
  );

  const [active, setActive] = useState();
  const [page, setPage] = useState(0);
  const [modal, setModal] = useState(false);

  const { data, loading, total } = useSelector(
    (state) => state.trailerHistoryReducer
  );

  localStorage.setItem('total_data', total);

  const createHeaders = (headerData) => {
    const cData = headerData.map((item) => ({
      item,
      // ref: useRef(),
    }));
    // console.log(cData);

    // setColumns(cData);

    return cData;
  };

  const [columns, setColumns] = useState(createHeaders(headers));

  // const [total, setTotal] = useState(0);
  const [trailerHistoryData, setTrailerHistoryData] = useState([]);
  const token = localStorage.getItem(
    localStorage.getItem('current_account') + '_access_token'
  );
  const decodedToken = jwtDecode(token);

  const [tabs, setTabs] = useState([
    { id: 1, name: 'Trailers' },
    { id: 2, name: 'Repairs' },
    { id: 3, name: 'Idles' },
    { id: 4, name: 'History' },
  ]);

  const tabList = [
    '/trailers/list',
    '/trailer_repairs/list',
    '/trailer_idles/list',
    '/trailer_history/list',
  ];

  const checkAllow = (role) => {
    if (role === 'ROLE_ADMIN' || role === 'SUPER_ADMIN') {
      return true;
    }
    return false;
  };

  const getData = (pageIndex = 0) => {
    tripService
      .getAll(
        `${`status_id=3`}&sort=id,DESC&size=100&page=${pageIndex}&exist_trailer=1`
      )
      .then((data) => {
        const dataToShow = [];
        data.content.forEach((el, i) => {
          const elToShow = {
            index: i + 1 + page * 100,
            id: el.id,
            loadNumber: el.loadNumber,
            loadId: el.loadId,
            driverId: el.driverId,
            driver: el.driverName,
            accessoryDriverPay: el.accessoryDriverPay,
            primaryPhone: el.phone,
            driverAdvance: el.driverAdvance,
            to: el.to,
            statusName: el.statusName,
            statusColor: el.statusColor,
            hiredOn: el.hireDate,
            delivery: el.deliveryDateFormatted,
            pickup: el.pickDateFormatted,
            teammateName: el.teammateName,
            teammateId: el.teammateId,
            unitNumber: el.unitNumber,
            trailerNumber: el.trailerNumber
              ? el.trailerNumber
              : el.unknownTrailer,
          };
          dataToShow.push(elToShow);
        });
        setTrailerHistoryData(dataToShow);
        // setTotal(data.total_elements);

        localStorage.setItem('total_data', total);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const getDataSearch = (search) => {
    tripService
      .getAll(
        `${
          search ? `search=` + search + '&' : ''
        }${`status_id=3`}&sort=id,DESC&size=100&page=${page}&exist_trailer=1`
      )
      .then((data) => {
        const dataToShow = [];
        data.content.forEach((el, i) => {
          const elToShow = {
            index: i + 1 + page * 100,
            id: el.id,
            loadNumber: el.loadNumber,
            loadId: el.loadId,
            driverId: el.driverId,
            driver: el.driverName,
            accessoryDriverPay: el.accessoryDriverPay,
            primaryPhone: el.phone,
            driverAdvance: el.driverAdvance,
            to: el.to,
            statusName: el.statusName,
            statusColor: el.statusColor,
            hiredOn: el.hireDate,
            delivery: el.deliveryDateFormatted,
            pickup: el.pickDateFormatted,
            teammateName: el.teammateName,
            teammateId: el.teammateId,
            unitNumber: el.unitNumber,
            trailerNumber: el.trailerNumber
              ? el.trailerNumber
              : el.unknownTrailer,
          };
          dataToShow.push(elToShow);
        });
        setTrailerHistoryData(dataToShow);
        // setTotal(data.total_elements);
        localStorage.setItem('total_data', data.total_elements);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const handleModal = () => {
    setModal(!modal);
  };

  const getFile = (ids) => {
    console.log(ids.length);
    tripService
      .getExcelFile(
        `${
          ids.length > 0
            ? 'deleted=false&trailerIds=' + ids.join(',')
            : 'deleted=false'
        }${start !== null ? '&start=' + start : ''}` +
          `${end !== null ? '&end=' + end : ''}`
      )
      .then((result) => {
        if (result.status === 'success') {
          window.open(result.data, '_blank');
        } else {
          toast.error(result.msg, {
            transition: Flip,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const getTrailers = () => {
    trailerGeneralService
      .getAll('size=10000')
      .then((result) => {
        setTrailerList(result.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  useEffect(() => {
    setTrailerHistoryData(data);
  }, [data]);

  useEffect(() => {
    getTrailers();
  }, []);

  return (
    <>
      <Loading loading={loading} />
      <Card className="agGrid-card" style={{ marginRight: 8 }}>
        <br />
        <CardBody className="py-0 no-pagination">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center ">
              <Nav pills className="dashboard-tabs">
                {tabs.map((item) => (
                  <NavItem key={`${item.id}`}>
                    <NavLink
                      style={{
                        height: 38,
                        backgroundColor: item.id === 4 ? '#00FF7F' : 'white',
                      }}
                      className={classnames(
                        {
                          customActive: active === item.id,
                        },
                        'd-flex align-items-center'
                      )}
                      onClick={() => {
                        localStorage.setItem('trailerTabId', item.id);
                        navigate(tabList[item.id - 1]);
                        // history.push(tabList[item.id - 1]);
                      }}
                    >
                      <h4 className="mb-0">{item.name}</h4>
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </div>

            {checkAllow(decodedToken.role) ? (
              <div>
                <Link
                  to="/trailers/new"
                  className="d-flex align-items-center text-white"
                >
                  <Button
                    size="sm"
                    color="primary"
                    className="d-flex align-items-center"
                    type="button"
                  >
                    <Icon.Plus size={20} />
                    &nbsp; Add new trailer
                  </Button>
                </Link>
              </div>
            ) : null}
          </div>

          <div className="d-flex align-items-center mt-1 mx-0 mb-1">
            <TextField
              fullWidth
              size="small"
              id="search"
              label="Search"
              variant="outlined"
              onChange={(e) => setSearch(e.target.value)}
            />
            {/* <IconButton
              color="primary"
              aria-label="filter"
              onClick={() => {
                setDownloadShow(false);
                setFilterIsShow(!isFilterShow);
              }}
            >
              <FilterListIcon />
            </IconButton> */}
            <IconButton
              color="primary"
              aria-label="download2"
              onClick={() => {
                setDownloadShow(!isDownloadShow);
              }}
            >
              <DownloadForOfflineIcon />
            </IconButton>
          </div>
          <div className={isDownloadShow ? 'align-items-center mb-2 mx-0' : ''}>
            <AnimatePresence>
              {isDownloadShow ? (
                <AnimationDiv>
                  <DownloadFilterTools
                    startTime={start}
                    endTime={end}
                    setStartTime={setStart}
                    setEndTime={setEnd}
                    trailerList={trailerList}
                    setTrailerIds={setTrailerIds}
                    trailerStatus={trailerStatus}
                    setTrailerStatus={setTrailerStatus}
                    getFile={getFile}
                  />
                </AnimationDiv>
              ) : null}
            </AnimatePresence>
          </div>
          <div className="w-100 ag-grid-table with-pagination">
            <Table
              data={trailerHistoryData}
              headers={headers}
              toolTips={toolTips}
              onChange={handleOnTableChange}
              modal={handleModal}
              columns={columns}
              setColumns={setColumns}
              // hidePagination={1}
            />
          </div>
          <br />
        </CardBody>
      </Card>
    </>
  );
}
