import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ModalWindowFullScreen from '../../Modals/FullScreen';
import ViewDriverModal from '../../../../views/pages/Drivers/Modals/DriverModal';

export default ({ value }) => {
  const [driverModal, setDriverModal] = useState(false);
  const [driverId, setDriverId] = useState();

  return (
    <>
      <ModalWindowFullScreen
        title="Driver"
        modal={driverModal}
        handleModal={() => {
          setDriverModal(!driverModal);
        }}
        body={<ViewDriverModal modal={driverModal} id={driverId} />}
      />
      <Link
        className="link-in-table"
        // to={`/drivers/view/${value.driverOneId}`}
        onClick={() => {
          setDriverId(value.driverOneId);
          setDriverModal(true);
        }}
      >
        {value.driverOnePhoneNumber}
      </Link>
      {value.driverTwoId !== null && (
        <Link
          className="link-in-table"
          // to={`/drivers/view/${value.driverTwoId}`}
          onClick={() => {
            setDriverId(value.driverTwoId);
            setDriverModal(true);
          }}
        >
          {value.driverTwoPhoneNumber}
        </Link>
      )}
    </>
  );
};
