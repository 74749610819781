import React from 'react';
import moment from 'moment';

export default ({ item, startTime, endTime }) => {
  const startDate = moment(startTime, 'x');
  const endDate = moment(endTime, 'x');

  return (
    <div
      style={{
        position: 'fixed',
        left: 100,
        bottom: 50,
        background: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        padding: 10,
        fontSize: 20,
        borderRadius: 5,
        zIndex: 85,
      }}
    >
      {`${startDate.format('LLL')} - ${endDate.format('LLL')}`}
    </div>
  );
};
