export const dashboardHeader = [
  {
    Header: '#',
    accessor: 'index',
    show: true,
    width: 50,
  },
  {
    Header: 'Unit #',
    accessor: 'dataUnit',
    show: true,
    width: 100,
    type: 'unit_link',
  },
  {
    Header: 'Drivers',
    accessor: 'dataDriver',
    show: true,
    width: 150,
    type: 'dashboard_driver_link',
  },
  {
    Header: 'Phone numbers',
    accessor: 'dataDriverPhoneNumber',
    show: true,
    width: 120,
    type: 'driver_phone',
  },
  // {
  //   Header: 'UT',
  //   accessor: 'typeOfUnit',
  //   show: true,
  //   width: 50,
  // },
  // {
  //   Header: 'DT',
  //   accessor: 'typeOfDriver',
  //   show: true,
  //   width: 50,
  // },
  {
    Header: 'Driver approve',
    accessor: 'dataDriverApprove',
    show: true,
    width: 50,
    type: 'driver_approve',
  },
  {
    Header: 'Home time request',
    accessor: 'dataAtHomeDate',
    show: true,
    width: 150,
    type: 'atHomeDate',
  },
  // {
  //   Header: 'Leave home date',
  //   accessor: 'dataLeaveHomeDate',
  //   show: true,
  //   width: 190,
  //   type: 'leaveHomeDate',
  // },
  {
    Header: 'Unit Status',
    accessor: 'data',
    show: true,
    width: 150,
    type: 'dashboard_unit_status',
  },
  {
    Header: 'Load #',
    accessor: 'dataLoad',
    show: true,
    width: 150,
    type: 'load_link',
  },
  {
    Header: 'From',
    accessor: 'from',
    show: true,
    width: 100,
  },
  {
    Header: 'To',
    accessor: 'to',
    show: true,
    width: 120,
  },
  {
    Header: 'Destination time',
    accessor: 'endTime',
    show: true,
    width: 150,
    // type: 'sinceTime',
  },

  {
    Header: 'Weekly gross',
    accessor: 'calc',
    show: true,
    width: 120,
  },
  {
    Header: 'Notes',
    accessor: 'dataNotes',
    show: true,
    width: 80,
    type: 'dashboard_note',
  },
];

export const tripsHeader = [
  {
    Header: '#',
    accessor: 'index',
    show: true,
    width: 50,
  },
  {
    Header: 'Trip',
    accessor: 'id',
    show: true,
    width: 100,
  },
  {
    Header: 'Driver approve',
    accessor: 'dataDriverApprove',
    show: true,
    width: 50,
    type: 'driver_approve',
  },
  {
    Header: 'Trailer',
    accessor: 'trailerNumber',
    show: true,
    width: 100,
  },
  {
    Header: 'Unit',
    accessor: 'unitData',
    show: true,
    width: 150,
    type: 'unit_link',
  },
  {
    Header: 'Load №',
    accessor: 'data',
    show: true,
    width: 100,
    type: 'load_link',
  },
  {
    Header: 'Pickup',
    accessor: 'pickup',
    show: true,
    width: 140,
  },
  {
    Header: 'Delivery',
    accessor: 'delivery',
    show: true,
    width: 140,
  },
  {
    Header: 'Driver',
    accessor: 'driverData',
    show: true,
    width: 200,
    type: 'drivers_link',
  },
  {
    Header: 'Status',
    accessor: 'statusData',
    show: true,
    width: 100,
    type: 'show_status',
  },
  {
    Header: 'Accessory Driver Pay',
    accessor: 'accessoryDriverPay',
    show: true,
    width: 100,
  },
  {
    Header: 'Driver Advance',
    accessor: 'driverAdvance',
    show: true,
    width: 100,
  },
  {
    Header: 'To',
    accessor: 'to',
    show: true,
    width: 150,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    show: true,
    width: 100,
  },
];

export const unitTripsHeader = [
  {
    Header: '#',
    accessor: 'index',
    show: true,
    width: 50,
  },
  {
    Header: 'Trip',
    accessor: 'id',
    show: true,
    width: 100,
  },
  {
    Header: 'Trailer',
    accessor: 'trailerNumber',
    show: true,
    width: 150,
  },
  {
    Header: 'Load №',
    accessor: 'data',
    show: true,
    width: 150,
    type: 'load_link',
  },
  {
    Header: 'Pickup',
    accessor: 'pickup',
    show: true,
    width: 150,
  },
  {
    Header: 'Delivery',
    accessor: 'delivery',
    show: true,
    width: 150,
  },
  {
    Header: 'Driver',
    accessor: 'driver',
    show: true,
    width: 150,
  },
  {
    Header: 'From',
    accessor: 'from',
    show: true,
    width: 150,
  },
  {
    Header: 'To',
    accessor: 'to',
    show: true,
    width: 150,
  },
  {
    Header: 'Booked',
    accessor: 'rcPrice',
    show: true,
    width: 150,
  },
  {
    Header: 'Revised RC Price',
    accessor: 'revisedRcPrice',
    show: true,
    width: 150,
  },
];

export const driverTripsHeader = [
  {
    Header: '#',
    accessor: 'index',
    show: true,
    width: 50,
  },
  {
    Header: 'Trip',
    accessor: 'id',
    show: true,
    width: 100,
  },
  {
    Header: 'Trailer',
    accessor: 'trailerNumber',
    show: true,
    width: 150,
  },
  {
    Header: 'Load №',
    accessor: 'data',
    show: true,
    width: 150,
    type: 'load_link',
  },
  {
    Header: 'Pickup',
    accessor: 'pickup',
    show: true,
    width: 150,
  },
  {
    Header: 'Delivery',
    accessor: 'delivery',
    show: true,
    width: 150,
  },
  {
    Header: 'From',
    accessor: 'from',
    show: true,
    width: 150,
  },
  {
    Header: 'To',
    accessor: 'to',
    show: true,
    width: 150,
  },
  {
    Header: 'Booked',
    accessor: 'rcPrice',
    show: true,
    width: 150,
  },
  {
    Header: 'Revised RC Price',
    accessor: 'revisedRcPrice',
    show: true,
    width: 150,
  },
];

export const loadsHeader = [
  {
    Header: '#',
    accessor: 'index',
    show: true,
    width: 50,
  },
  {
    Header: 'Edit status',
    accessor: 'editStatus',
    show: true,
    width: 20,
    type: 'edit_status',
  },
  {
    Header: 'Load #',
    accessor: 'load',
    show: true,
    width: 150,
  },
  {
    Header: 'Trip #',
    accessor: 'data',
    show: true,
    width: 100,
    type: 'trip_link',
  },
  {
    Header: 'Status',
    accessor: 'status',
    show: true,
    width: 100,
    type: 'load_status',
  },
  {
    Header: 'Pickup',
    accessor: 'pickup',
    show: true,
    width: 150,
  },
  {
    Header: 'Delivery',
    accessor: 'delivery',
    show: true,
    width: 150,
  },
  {
    Header: 'Driver',
    accessor: 'driver',
    show: true,
    width: 150,
  },
  {
    Header: 'Truck',
    accessor: 'unitData',
    show: true,
    width: 150,
    type: 'unit_link',
  },
  {
    Header: 'Customer',
    accessor: 'customer',
    show: true,
    width: 250,
  },
  {
    Header: 'From',
    accessor: 'from',
    show: true,
    width: 250,
  },
  {
    Header: 'To',
    accessor: 'to',
    show: true,
    width: 250,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    show: true,
    width: 100,
  },
];

export const driversHeader = [
  {
    Header: '#',
    accessor: 'index',
    show: true,
    width: 50,
  },
  {
    Header: 'Get loads',
    accessor: 'dataStatement',
    show: true,
    width: 50,
    type: 'driver_loads',
  },
  {
    Header: 'Name',
    accessor: 'data',
    show: true,
    width: 150,
    type: 'driver_link',
  },
  {
    Header: 'Address',
    accessor: 'address',
    show: true,
    width: 150,
  },
  {
    Header: 'Primary Phone',
    accessor: 'primaryPhone',
    show: true,
    width: 150,
  },
  {
    Header: 'Alternate phone',
    accessor: 'alternatePhone',
    show: true,
    width: 150,
  },
  {
    Header: 'Payment Type',
    accessor: 'paymentType',
    show: true,
    width: 150,
  },
  {
    Header: 'Status',
    accessor: 'dataDriver',
    show: true,
    width: 150,
    type: 'driver_status',
  },
  {
    Header: 'Hired on',
    accessor: 'hiredOn',
    show: true,
    width: 150,
  },
  {
    Header: 'License exp',
    accessor: 'licenseExp',
    show: true,
    width: 150,
  },
  {
    Header: 'Medical Card Exp',
    accessor: 'medicalCardExp',
    show: true,
    width: 150,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    show: true,
    width: 100,
  },
];

export const driverNotificationsHeader = [
  {
    Header: '#',
    accessor: 'index',
    show: true,
    width: 50,
  },
  {
    Header: 'Title',
    accessor: 'title',
    show: true,
    width: 150,
  },
  {
    Header: 'Description',
    accessor: 'description',
    show: true,
    width: 150,
  },
  {
    Header: 'Is for all',
    accessor: 'isForAll',
    show: true,
    width: 150,
    type: 'notification_status',
  },
  {
    Header: 'Driver',
    accessor: 'driverName',
    show: true,
    width: 150,
  },
  {
    Header: 'Created time',
    accessor: 'createdAt',
    show: true,
    type: 'moment',
    width: 150,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    show: true,
    width: 100,
  },
];

export const unitsHeader = [
  {
    Header: '#',
    accessor: 'index',
    show: true,
    width: 50,
  },
  {
    Header: 'Unit number',
    accessor: 'number',
    show: true,
    width: 150,
  },
  {
    Header: 'VIN',
    accessor: 'vin',
    show: true,
    width: 150,
  },
  {
    Header: 'Type',
    accessor: 'type',
    show: true,
    width: 100,
  },
  {
    Header: 'State',
    accessor: 'state',
    show: true,
    width: 150,
  },
  {
    Header: 'Status',
    accessor: 'data',
    show: true,
    type: 'unit_status',
    width: 150,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    show: true,
    width: 100,
  },
];

export const fuelHeader = [
  {
    Header: '#',
    accessor: 'index',
    show: true,
    width: 50,
  },
  {
    Header: 'Sync',
    accessor: 'sync',
    show: true,
    width: 100,
  },
  {
    Header: 'Location',
    accessor: 'location',
    show: true,
    width: 150,
  },
  {
    Header: 'Unit number',
    accessor: 'truck',
    show: true,
    width: 150,
  },
  {
    Header: 'Unit Status',
    accessor: 'unitStatusData',
    show: true,
    width: 100,
    type: 'fuel_unit_status',
  },
  {
    Header: 'Driver',
    accessor: 'driver',
    show: true,
    width: 150,
  },
  {
    Header: 'Phone',
    accessor: 'phone',
    show: true,
    width: 100,
  },
  // {
  //   Header: 'Status',
  //   accessor: 'data',
  //   show: true,
  //   type: 'unit_status',
  //   width: 150,
  // },
  {
    Header: 'Tank',
    accessor: 'tankData',
    show: true,
    type: 'fuel_tank',
    width: 150,
  },
  {
    Header: 'Fuel',
    accessor: 'fuelData',
    show: true,
    width: 150,
    type: 'fuel_percent',
  },
  {
    Header: 'Order',
    accessor: 'currentBook',
    show: true,
    width: 150,
  },
  {
    Header: 'Last Refill',
    accessor: 'lastSavedBook',
    show: true,
    width: 150,
  },
];

export const fuelBookHeader = [
  {
    Header: '#',
    accessor: 'index',
    show: true,
    width: 50,
  },
  {
    Header: 'Unit number',
    accessor: 'truck',
    show: true,
    width: 150,
  },
  {
    Header: 'Driver',
    accessor: 'driver',
    show: true,
    width: 150,
  },
  {
    Header: 'Fuel Shop',
    accessor: 'fuelShopType',
    show: true,
    width: 100,
  },
  {
    Header: 'Fuel Shop Store',
    accessor: 'fuelShopStore',
    show: true,
    width: 100,
  },
  {
    Header: 'Fuel Shop Address',
    accessor: 'fuelShopAddress',
    show: true,
    width: 100,
  },
  {
    Header: 'Fuel amount',
    accessor: 'fuelAmount',
    show: true,
    width: 150,
  },
  {
    Header: 'Created time',
    accessor: 'createdAt',
    show: true,
    width: 150,
  },
];

export const reportsHeader = [
  {
    Header: '#',
    accessor: 'index',
    show: true,
    width: 50,
  },
  {
    Header: 'Name',
    accessor: 'carrierName',
    show: true,
    width: 150,
  },
  {
    Header: 'RC NO',
    accessor: 'dataLoad',
    show: true,
    width: 250,
    type: 'load_link',
  },
  {
    Header: 'Company',
    accessor: 'shipperCompanyName',
    show: true,
    width: 250,
  },
  {
    Header: 'Start',
    accessor: 'startTimeText',
    show: true,
    // type: 'moment',
    width: 250,
  },
  {
    Header: 'Start Location',
    accessor: 'shipperCompanyLocation',
    show: true,
    width: 250,
  },
  {
    Header: 'End',
    accessor: 'endTimeText',
    show: true,
    // type: 'moment',
    width: 250,
  },
  {
    Header: 'End Location',
    accessor: 'endLocation',
    show: true,
    width: 250,
  },
  {
    Header: 'Truck',
    accessor: 'truckNumber',
    show: true,
    width: 200,
  },
  {
    Header: 'Truck company',
    accessor: 'truckCompany',
    show: true,
    width: 200,
  },
  {
    Header: 'Driver',
    accessor: 'driverOne',
    show: true,
    width: 200,
  },
  {
    Header: 'Booked',
    accessor: 'booked',
    show: true,
    width: 200,
  },
  {
    Header: 'Dispute',
    accessor: 'dispute',
    show: true,
    width: 200,
  },
  {
    Header: 'Detention',
    accessor: 'detention',
    show: true,
    width: 200,
  },
  {
    Header: 'Additional',
    accessor: 'additional',
    show: true,
    width: 200,
  },
  {
    Header: 'Fine',
    accessor: 'fine',
    show: true,
    width: 200,
  },
  {
    Header: 'Revised/Invoice',
    accessor: 'revisedInvoice',
    show: true,
    width: 200,
  },
  {
    Header: 'K - O',
    accessor: 'ko',
    show: true,
    width: 200,
  },
  {
    Header: 'Factoring',
    accessor: 'amount',
    show: true,
    width: 200,
  },
  {
    Header: 'Tafs',
    accessor: 'service',
    show: true,
    width: 200,
  },
  {
    Header: 'Net Paid',
    accessor: 'netPaid',
    show: true,
    width: 200,
  },
  {
    Header: 'Load ml',
    accessor: 'loadMl',
    show: true,
    width: 200,
  },
  {
    Header: 'MT ml',
    accessor: 'mtMl',
    show: true,
    width: 200,
  },
  {
    Header: 'Dispatcher',
    accessor: 'team',
    show: true,
    width: 200,
  },
  {
    Header: 'Note',
    accessor: 'note',
    show: true,
    width: 200,
  },
];

export const trailerGeneralHeader = [
  {
    Header: '#',
    accessor: 'index',
    show: true,
    width: 50,
  },
  {
    Header: 'Trailer number',
    accessor: 'trailerNumber',
    show: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    show: true,
    width: 50,
  },
  {
    Header: 'Owner',
    accessor: 'ownedBy',
    show: true,
  },
  {
    Header: 'Type',
    accessor: 'type',
    show: true,
  },
  {
    Header: 'Make',
    accessor: 'make',
    show: true,
  },
  {
    Header: 'Make year',
    accessor: 'makeYear',
    show: true,
    width: 70,
  },
  // {
  //   Header: 'Model',
  //   accessor: 'model',
  //   show: true,
  //   width: 50,
  // },
  {
    Header: 'Vin',
    accessor: 'vin',
    show: true,
  },
  // {
  //   Header: 'Title number',
  //   accessor: 'titleNumber',
  //   show: true,
  // },
  {
    Header: 'License plate',
    accessor: 'licensePlate',
    show: true,
  },
  // {
  //   Header: 'Purchase date/price',
  //   accessor: 'purchaseDate',
  //   show: true,
  // },
  // {
  //   Header: 'Sale date/price',
  //   accessor: 'saleDate',
  //   show: true,
  // },
  // {
  //   Header: 'Financed by',
  //   accessor: 'financedBy',
  //   show: true,
  // },
  // {
  //   Header: 'Leasing company',
  //   accessor: 'leasingCompany',
  //   show: true,
  // },
  // {
  //   Header: 'Lease reference',
  //   accessor: 'leaseReference',
  //   show: true,
  // },
  // {
  //   Header: 'In service on',
  //   accessor: 'inServiceOn',
  //   show: true,
  // },
  // {
  //   Header: 'Tags expire on',
  //   accessor: 'tagsExpireOn',
  //   show: true,
  // },
  {
    Header: 'Next inspection on',
    accessor: 'nextInspectionOn',
    show: true,
    type: 'moment',
  },
  {
    Header: 'Day left',
    accessor: 'daysLeft_',
    show: true,
  },
  {
    Header: 'Tracking Type',
    accessor: 'trackingType',
    show: true,
  },
  {
    Header: 'Tracking Serial Number',
    accessor: 'unitSerialNumber',
    show: true,
  },
  {
    Header: 'Inflation System',
    accessor: 'inflationSystem',
    show: true,
  },
  {
    Header: 'Tire Size',
    accessor: 'tireSize',
    show: true,
  },
  {
    Header: 'Door Type',
    accessor: 'doorType',
    show: true,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    show: true,
    width: 100,
  },
];

export const trailerRepairHeader = [
  {
    Header: '#',
    accessor: 'index',
    show: true,
    width: 50,
  },
  {
    Header: 'Date',
    accessor: 'date',
    show: true,
    type: 'moment',
  },
  {
    Header: 'User',
    accessor: 'username',
    show: true,
  },
  {
    Header: 'Trailer number',
    accessor: 'trailerName',
    show: true,
  },
  {
    Header: 'Trailer Owner',
    accessor: 'trailerOwned',
    show: true,
  },
  {
    Header: 'Damage Category',
    accessor: 'issueCategory',
    show: true,
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    show: true,
  },
  {
    Header: 'Payment method',
    accessor: 'paymentMethod',
    show: true,
  },
  {
    Header: 'Truck',
    accessor: 'truck',
    show: true,
  },
  // {
  //   Header: 'Purchase date/price',
  //   accessor: 'purchaseDate',
  //   show: true,
  // },
  {
    Header: 'Driver(s)',
    accessor: 'drivers',
    show: true,
  },
  {
    Header: 'Shop name',
    accessor: 'shopServiceName',
    show: true,
  },
  {
    Header: 'Number of attachments',
    accessor: 'numberAttachments',
    show: true,
  },
];

export const trailerIdlesHeader = [
  {
    Header: '#',
    accessor: 'index',
    show: true,
    width: 50,
  },
  {
    Header: 'Trailer',
    accessor: 'trailerNumber',
    show: true,
  },
  {
    Header: 'Idle days',
    accessor: 'idleDays',
    show: true,
  },
  {
    Header: 'Last Delivery Date',
    accessor: 'lastDeliveryDate',
    show: true,
  },
  // {
  //   Header: 'Trailer Owner',
  //   accessor: 'trailerOwned',
  //   show: true,
  // },
  {
    Header: 'Last Delivery Location',
    accessor: 'lastDeliveryLocation',
    show: true,
    width: 200,
  },
  {
    Header: 'Current location',
    accessor: 'currentLocation',
    show: true,
    width: 200,
  },
  {
    Header: 'Last used unit',
    accessor: 'lastUsedUnit',
    show: true,
  },
];

export const trailerHistoryHeader = [
  {
    Header: '#',
    accessor: 'index',
    show: true,
    width: 50,
  },
  {
    Header: 'Trailer',
    accessor: 'trailerNumber',
    show: true,
  },
  {
    Header: 'Trip',
    accessor: 'id',
    show: true,
  },
  {
    Header: 'Unit',
    accessor: 'unitNumber',
    show: true,
  },
  {
    Header: 'Load №',
    accessor: 'loadNumber',
    show: true,
  },
  {
    Header: 'Pickup',
    accessor: 'pickup',
    show: true,
  },
  {
    Header: 'Delivery',
    accessor: 'delivery',
    show: true,
  },
  {
    Header: 'Driver',
    accessor: 'driver',
    show: true,
  },
  {
    Header: 'Status',
    accessor: 'statusName',
    show: true,
  },
  {
    Header: 'Accessory Driver Pay',
    accessor: 'accessoryDriverPay',
    show: true,
  },
  {
    Header: 'Driver Advance',
    accessor: 'driverAdvance',
    show: true,
  },
  {
    Header: 'To',
    accessor: 'to',
    show: true,
  },
];

export const userHeader = [
  {
    Header: '#',
    accessor: 'index',
    show: true,
    width: 50,
  },
  {
    Header: 'ID',
    accessor: 'id',
    show: false,
    width: 200,
  },
  {
    Header: 'Username',
    accessor: 'username',
    show: true,
    width: 150,
  },
  {
    Header: 'Name',
    accessor: 'name',
    show: true,
    width: 150,
  },
  {
    Header: 'Phone number',
    accessor: 'phone',
    show: true,
    width: 100,
  },
  {
    Header: 'Role',
    accessor: 'role',
    show: true,
    width: 100,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    show: true,
    width: 100,
  },
];

export const companyHeader = [
  {
    Header: '#',
    accessor: 'index',
    show: true,
    width: 50,
  },
  {
    Header: 'Name',
    accessor: 'name',
    show: true,
    width: 150,
  },
  {
    Header: 'Abbreviation',
    accessor: 'abbreviation',
    show: true,
    width: 150,
  },
  {
    Header: 'State',
    accessor: 'state',
    show: true,
    width: 150,
  },
  {
    Header: 'City',
    accessor: 'city',
    show: true,
    width: 150,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    show: true,
    width: 100,
  },
];

export const customerHeader = [
  {
    Header: '#',
    accessor: 'index',
    show: true,
    width: 50,
  },
  {
    Header: 'Name',
    accessor: 'name',
    show: true,
    width: 150,
  },
  {
    Header: 'Email',
    accessor: 'email',
    show: true,
    width: 150,
  },
  {
    Header: 'Phone',
    accessor: 'phone',
    show: true,
    width: 150,
  },
  {
    Header: 'City',
    accessor: 'city',
    show: true,
    width: 150,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    show: true,
    width: 100,
  },
];

export const locationHeader = [
  {
    Header: '#',
    accessor: 'index',
    show: true,
    width: 10,
  },
  {
    Header: 'ID',
    accessor: 'id',
    show: false,
    width: 250,
  },
  {
    Header: 'ANSI',
    accessor: 'ansi',
    show: true,
    width: 150,
  },
  {
    Header: 'Name',
    accessor: 'name',
    show: true,
    width: 150,
  },
  {
    Header: 'Parent ANSI',
    accessor: 'parentAnsi',
    show: true,
    width: 150,
  },
  {
    Header: 'First Time Zone',
    accessor: 'firstTimeZone',
    show: true,
    width: 150,
  },
  {
    Header: 'Second Time Zone',
    accessor: 'secondTimeZone',
    show: true,
    width: 150,
  },
  {
    Header: 'Parent Time Zone',
    accessor: 'parentTimeZone',
    show: true,
    width: 150,
  },
  // {
  //   Header: 'Actions',
  //   accessor: 'actions',
  //   show: true,
  //   width: 100,
  // },
];

export const logsHeader = [
  {
    Header: '#',
    accessor: 'index',
    show: true,
    width: 50,
  },
  {
    Header: 'Username',
    accessor: 'username',
    show: true,
    width: 150,
  },
  {
    Header: 'Table name',
    accessor: 'tableName',
    show: true,
    width: 150,
  },
  {
    Header: 'Info',
    accessor: 'item',
    show: true,
    width: 150,
    type: 'info',
  },
  {
    Header: 'Action',
    accessor: 'actionType',
    show: true,
    width: 150,
  },
  {
    Header: 'Time',
    accessor: 'timeStamp',
    show: true,
    width: 150,
    type: 'moment',
  },
];

export const tasksHeader = [
  {
    Header: '#',
    accessor: 'index',
    show: true,
    width: 50,
  },
  {
    Header: 'Show',
    accessor: 'dataShow',
    show: true,
    width: 150,
    type: 'task_show',
  },
  {
    Header: 'Username',
    accessor: 'username',
    show: true,
    width: 150,
  },
  {
    Header: 'Photo',
    accessor: 'data',
    show: true,
    width: 150,
    type: 'task_photo',
  },
  {
    Header: 'Task',
    accessor: 'task',
    show: true,
    width: 150,
  },
  {
    Header: 'Time',
    accessor: 'createdAt',
    show: true,
    width: 150,
    type: 'moment',
  },
];
