import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Col,
} from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import { toast, Flip } from 'react-toastify';

export default function ShowModal({ modal, toggleModal, handleChange, item }) {
  const [atHomeDate, setAtHomeDate] = useState();
  const [leaveHomeDate, setLeaveHomeDate] = useState();

  useEffect(() => {
    setAtHomeDate(item.atHomeDate);
    setLeaveHomeDate(item.leaveHomeDate);
  }, [modal]);

  return (
    <Modal
      centered={true}
      isOpen={modal}
      toggle={toggleModal}
      className="sticky"
      backdrop="static"
    >
      <ModalHeader toggle={() => toggleModal()}>Date</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup className="align-items-center" row>
            <Col md="4">
              <span>At home date:</span>
            </Col>
            <Col md="8">
              <div>
                <Flatpickr
                  className="form-control"
                  data-enable-time
                  options={{
                    dateFormat: 'Z',
                    altInput: true,
                    altFormat: 'm-d-Y H:i',
                  }}
                  value={atHomeDate}
                  onChange={(val) => {
                    setAtHomeDate(new Date(val[0]).getTime());
                  }}
                />
              </div>
            </Col>
          </FormGroup>
          <FormGroup className="align-items-center" row>
            <Col md="4">
              <span>Leave home date:</span>
            </Col>
            <Col md="8">
              <div>
                <Flatpickr
                  className="form-control"
                  data-enable-time
                  options={{
                    dateFormat: 'Z',
                    altInput: true,
                    altFormat: 'm-d-Y H:i',
                  }}
                  value={leaveHomeDate}
                  onChange={(val) => {
                    setLeaveHomeDate(new Date(val[0]).getTime());
                  }}
                />
              </div>
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            if (atHomeDate === null && atHomeDate === undefined) {
              toast.error('Please, select at home date', {
                transition: Flip,
              });
            } else if (leaveHomeDate === null && leaveHomeDate === undefined) {
              toast.error('Please, select leave home date', {
                transition: Flip,
              });
            } else if (atHomeDate > leaveHomeDate) {
              toast.error(
                'Wrong time, at home date is greater than leave home date',
                {
                  transition: Flip,
                }
              );
            } else {
              handleChange(atHomeDate, leaveHomeDate);
            }
          }}
        >
          Save
        </Button>{' '}
      </ModalFooter>
    </Modal>
  );
}
