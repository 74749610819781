import { service } from '.';

export default {
  getAll: (query, data) => service.post(`/company/list?${query}`, data),
  getContext: () => service.get(`/company/context`),
  getOffices: (id) => service.get(`/company/offices/${id}`),
  getSearch: (data) => service.post(`/company/search`, data),
  getOne: (id) => service.get(`/company/${id}`),
  create: (data) => service.post(`/company/new`, data),
  createChild: (data) => service.post(`/company/child`, data),
  update: (data) => service.put(`/company/edit`, data),
  delete: (id) => service.delete(`/company/${id}`),
};
