import React, { useState, useEffect } from 'react';
import {
  Form,
  FormGroup,
  Input,
  Label,
  CustomInput,
  Col,
  Card,
  CardBody,
  CardHeader,
} from 'reactstrap';
import {
  Checkbox,
  Button,
  TextField,
  FormControlLabel,
  Autocomplete,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CircularProgress from '@mui/material/CircularProgress';
import * as Icon from 'react-feather';
import { toast, Flip } from 'react-toastify';

import driverService from '../../../../../services/drivers';
import driverNotificationService from '../../../../../services/driver_notifications';

export default function NewDriverNotification({ toggle, refreshData }) {
  const [driverIds, setDriverIds] = useState([]);
  const [isForAll, setIsForAll] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(false);

  const newNotification = () => {
    if (!isForAll && driverIds.length === 0) {
      toast.error('Please, select drivers', { transition: Flip });
    } else {
      const data = {
        isForAll,
        title: document.querySelector('#title').value,
        description: document.querySelector('#description').value,
        driverIds: isForAll ? [0] : driverIds,
      };

      driverNotificationService
        .create(data)
        .then((res) => {
          if (res) {
            toast.success('Notification successfuly added', {
              transition: Flip,
            });
            toggle();
            refreshData();
          } else {
            toast.error('Something went wrong', { transition: Flip });
            // res.text();
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            transition: Flip,
          });
        });
    }
  };

  const loadOptions = (inputValue) => {
    driverService
      .getAll(`sort=id,DESC&size=100&page=0&search=${inputValue}`)
      .then((data) => {
        setDrivers(
          data.content.map((el) => {
            return {
              ...el,
              value: el.id,
              label: `${el.firstName} ${el.lastName} ${el.searchName}`,
            };
          })
        );
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  useEffect(() => {}, []);

  return (
    <>
      <Card>
        <CardHeader>
          <h3 className="mb-0">New notification</h3>
        </CardHeader>
        <CardBody>
          <Form className="d-flex">
            <div style={{ width: '100%' }}>
              <FormGroup className="align-items-center" row>
                <Col md="3">
                  <span>Is for all ?</span>
                </Col>
                <Col md="9">
                  <FormControlLabel
                    id="is_all"
                    name="is_all"
                    control={<Checkbox />}
                    label=""
                    checked={isForAll}
                    onChange={(val) => {
                      setIsForAll(!isForAll);
                    }}
                  />
                </Col>
              </FormGroup>
              {!isForAll ? (
                <FormGroup className="align-items-center" row>
                  <Col md="3">
                    <span>Driver</span>
                  </Col>
                  <Col md="9">
                    <Autocomplete
                      multiple
                      fullWidth
                      size="small"
                      id="combo-box1"
                      disableCloseOnSelect
                      name="members"
                      // className={classes.search}
                      // value={formik.values.members}
                      options={drivers}
                      onChange={(event, newValue) => {
                        console.log(newValue);
                        setDriverIds(newValue.map((item) => item.id));
                        // formik.setFieldValue('members', newValue);
                      }}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }

                        // Regular option
                        return option.label;
                      }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            key={option.value}
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.label}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Drivers"
                          // error={
                          //   formik.touched.members &&
                          //   Boolean(formik.errors.members)
                          // }
                          onChange={(e) => loadOptions(e.target.value)}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Col>
                </FormGroup>
              ) : null}
              <FormGroup className="align-items-center" row>
                <Col md="3">
                  <span>Title</span>
                  <span style={{ color: 'red' }}> *</span>
                </Col>
                <Col md="9">
                  <Input type="text" id="title" />
                </Col>
              </FormGroup>

              <FormGroup className="align-items-center" row>
                <Col md="3">
                  <span>Description</span>
                  <span style={{ color: 'red' }}> *</span>
                </Col>
                <Col md="9">
                  <Input
                    type="textarea"
                    id="description"
                    placeholder=""
                    maxLength="1000"
                  />
                </Col>
              </FormGroup>
            </div>
          </Form>
          <Button
            size="medium"
            variant="contained"
            onClick={() => {
              newNotification();
            }}
          >
            <Icon.Check size={22} /> Save
          </Button>
        </CardBody>
      </Card>
    </>
  );
}
