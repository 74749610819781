import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useDispatch } from 'react-redux';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Button, OptionButton } from './style';
import { useShowModal } from '../../../../hooks';

export default ({
  data = [],
  itemId,
  deleteStatus,
  status,
  load,
  modal,
  deleteModal,
}) => {
  const refClick = useRef(null);
  const dispatch = useDispatch();
  const { showBlured } = useShowModal();
  const history = useNavigate();
  // const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openT = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        ref={refClick}
        onClick={(event) => {
          handleClick(event);
        }}
      >
        <MoreHorizIcon color="action" />
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openT}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {data.map(({ name, icon, onClick: ItemClick }, positionIndex) =>
          name === 'Delete' ? (
            <OptionButton
              onClick={() =>
                ItemClick(itemId, {
                  history,
                  status,
                  dispatch,
                  showBlured,
                  load,
                  modal,
                  deleteModal,
                })
              }
              key={`${positionIndex + 1}`}
            >
              {deleteStatus ? <LockOpenIcon /> : icon}
              <span>{deleteStatus ? 'Enable' : `${name}`}</span>
            </OptionButton>
          ) : (
            <OptionButton
              onClick={() =>
                ItemClick(itemId, {
                  history,
                  status,
                  dispatch,
                  showBlured,
                  load,
                  modal,
                  deleteModal,
                })
              }
              key={`${positionIndex + 1}`}
            >
              {icon}
              <span>{`${name}`}</span>
            </OptionButton>
          )
        )}
      </Menu>
    </>
  );
};
