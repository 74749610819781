export function dataSelector(dataResponse, page, size) {
  const filtered = dataResponse.content.map((item, i) => ({
    index: i + 1 + page * size,
    id: item.id,
    username: item.username,
    photo: item.photo,
    task: item.task.text,
    createdAt: item.createdAt,
    dataShow: item,
    data: item,
  }));
  return { total: dataResponse.totalElements, data: filtered };
}
