import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  CardHeader,
} from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { byDelete, byStatusState } from './helper';
import { useStyles } from '../style';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default ({
  startTime,
  endTime,
  setStartTime,
  setEndTime,
  trailerList,
  setTrailerIds,
  trailerStatus,
  setTrailerStatus,
  getFile,
}) => {
  const classes = useStyles();
  const [selectedTrailer, setSelectedTrailer] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(0);

  const handleStatus = (e) => {
    setTrailerStatus(e.target.value);
    if (!trailerStatus) {
      setSelectedFilter((prev) => prev + 1);
    }
  };

  const handleClearFilters = () => {
    setTrailerStatus('');
    setSelectedFilter(0);
  };

  return (
    <div className="d-flex align-items-center mt-1 mx-0 mb-1">
      <Col md="2" sm="6">
        <FormGroup>
          <Label for="from">Start</Label>
          <Flatpickr
            id="from"
            className="form-control"
            data-enable-time
            options={{
              dateFormat: 'Z',
              altInput: true,
              altFormat: 'm-d-Y H:i',
            }}
            value={startTime}
            onChange={(val) => setStartTime(Date.parse(val))}
          />
        </FormGroup>
      </Col>
      <Col md="2" sm="6">
        <FormGroup>
          <Label for="lastNameMulti">End</Label>
          <Flatpickr
            id="until"
            className="form-control"
            data-enable-time
            options={{
              dateFormat: 'Z',
              altInput: true,
              altFormat: 'm-d-Y H:i',
            }}
            value={endTime}
            onChange={(val) => setEndTime(Date.parse(val))}
          />
        </FormGroup>
      </Col>
      <Autocomplete
        fullWidth
        multiple
        size="small"
        id="size-small-outlined-multi"
        options={trailerList}
        disableCloseOnSelect
        getOptionLabel={(option) => option.trailerNumber}
        // getOptionSelected={(option, value) => {
        //   console.log(value);
        // }}
        onChange={(event, value) => {
          const arr = value.map((item) => item.id);
          if (arr.length === 0) {
            setTrailerIds();
          } else {
            setTrailerIds(arr.join(','));
          }
          setSelectedTrailer(arr);
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.trailerNumber}
          </li>
        )}
        // style={{ width: 500 }}
        renderInput={(params) => (
          <TextField {...params} label="Trailers" placeholder="" />
        )}
      />
      &nbsp; &nbsp;
      <Button
        // size="small"
        color="primary"
        // className="d-flex align-items-center"
        type="button"
        variant="outlined"
        onClick={() => {
          getFile(selectedTrailer);
        }}
      >
        Download
      </Button>
    </div>
  );
};
