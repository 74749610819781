import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Col,
  Input,
} from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import Select from 'react-select';

export default function NewAccountingModal({
  modal,
  toggle,
  companies,
  getData,
}) {
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [companyId, setCompanyId] = useState();
  const [checkDate, setCheckDate] = useState('');

  useEffect(() => {
    setStartTime();
    setEndTime();
    setCheckDate('');
  }, [modal]);

  return (
    <>
      <h5 style={{ color: 'red' }}>{checkDate}</h5>
      <Form>
        <Col md="12" sm="12">
          <FormGroup>
            <Input
              type="text"
              id="file_name"
              placeholder="name"
              onChange={(e) => {
                document.querySelector('#file_name').value = e.target.value;
              }}
            />
          </FormGroup>
        </Col>
        <Col md="12" sm="12">
          <FormGroup>
            <Label for="from">Start</Label>
            <Flatpickr
              id="from"
              className="form-control"
              data-enable-time
              options={{
                dateFormat: 'Z',
                altInput: true,
                altFormat: 'm-d-Y H:i',
              }}
              value={startTime}
              onChange={(val) => {
                if (new Date(val).getDay() !== 6) {
                  setCheckDate('Wrong date');
                  setStartTime();
                  setEndTime();
                } else {
                  const date = new Date(val);
                  setCheckDate('');
                  setStartTime(date.getTime());
                  setEndTime(date.setDate(date.getDate() + 7));
                }
              }}
            />
          </FormGroup>
        </Col>
        <Col md="12" sm="12">
          <FormGroup>
            <Label for="lastNameMulti">End</Label>
            <Flatpickr
              disabled={true}
              id="until"
              className="form-control"
              data-enable-time
              options={{
                dateFormat: 'Z',
                altInput: true,
                altFormat: 'm-d-Y H:i',
              }}
              value={endTime}
              onChange={(val) => {}}
            />
          </FormGroup>
        </Col>
        <Col md="12" sm="12">
          <FormGroup>
            <Label for="company">Company</Label>
            <Select
              id="company"
              className="React"
              classNamePrefix="select"
              name="color"
              options={companies}
              isClearable={true}
              onChange={(value) => {
                setCompanyId(value.value);
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary50: '#fe810b',
                  primary25: 'rgb(253, 179, 46)',
                  primary: 'rgb(253, 179, 46)',
                },
              })}
            />
          </FormGroup>
        </Col>
      </Form>

      <div>
        <Button
          color="success"
          className="d-flex align-items-center"
          type="button"
          onClick={() => {
            if (document.querySelector('#file_name').value !== '') {
              getData(
                startTime,
                endTime,
                companyId,
                document.querySelector('#file_name').value
              );
            }
          }}
        >
          <Icon.CheckCircle size={22} /> Create
        </Button>
      </div>
    </>
  );
}
