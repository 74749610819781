import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import {
  colors,
  Box,
  Button,
  Card,
  Container,
  TextField,
  FormHelperText,
  Typography,
  Grid,
  InputAdornment,
} from '@mui/material';
import jwtDecode from 'jwt-decode';
import { makeStyles, useTheme } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import KeyIcon from '@mui/icons-material/Key';
import PersonIcon from '@mui/icons-material/Person';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { inputLabelClasses } from '@mui/material/InputLabel';
import auth from '../../../../services/auth';
import { setToken } from '../../../../redux/modules/auth/actions';
import themeConfig from '../../../../configs/themeConfig';
import LBSIcon from '../../../../assets/img/logo/lbs.png';

export default function Login({ toggle }) {
  const theme = useTheme();
  const location = useLocation();

  const dispatch = useDispatch();
  const [isInvalid, setIsInvalid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const schema = Yup.object().shape({
    company: Yup.string().max(255).required('Required'),
    username: Yup.string().max(255).required('Required'),
    password: Yup.string().min(4).max(255).required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      company: '',
      username: '',
      password: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const { username } = values;
      const data = {
        company: values.company,
        username: values.username,
        password: values.password,
      };

      auth
        .getToken(data)
        .then((resp) => {
          if (resp.status === 200 || resp.status === 201) {
            localStorage.setItem('timer', '0');

            const { token } = resp.data;

            const decodedToken = jwtDecode(token.access_token);

            const role =
              // eslint-disable-next-line no-nested-ternary
              decodedToken.role === 'ROLE_ADMIN'
                ? 'admin'
                : // eslint-disable-next-line no-nested-ternary
                decodedToken.role === 'ROLE_ACCOUNTANT'
                ? 'accountant'
                : decodedToken.role === 'ROLE_UPDATER'
                ? 'updater'
                : 'dispatcher';
            localStorage.setItem(
              values.company.toUpperCase() + '_access_token',
              token.access_token
            );
            localStorage.setItem('refresh_token', token.refresh_token);
            localStorage.setItem('username', username);
            localStorage.setItem('role', role);

            const accounts = localStorage.getItem('accounts');
            if (accounts !== null) {
              const acc = JSON.parse(accounts);
              let a = 0;
              acc.forEach((item) => {
                if (item === values.company) {
                  a += 1;
                }
              });
              if (a === 0) {
                acc.push(values.company.toUpperCase());
                localStorage.setItem('accounts', JSON.stringify(acc));
                localStorage.setItem(
                  'current_account',
                  values.company.toUpperCase()
                );
              }
            }
            dispatch(
              setToken({
                data: resp.data,
                company: values.company.toUpperCase(),
              })
            );
            window.open(location.pathname, '_self');
          }
        })
        .catch((err) => {
          console.log(err);
          setIsInvalid(true);
        });
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Container maxWidth="sm">
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          {/* <Box sx={{ my: 1 }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  style={{ borderRadius: '15%' }}
                  src={LBSIcon}
                  alt=""
                  width={70}
                />
              </div>
            </Box> */}
          {isInvalid && (
            <Box sx={{ mb: 4 }} display="flex" justifyContent="center">
              <Typography variant="body1" color="error">
                Invalid company name or username or password
              </Typography>
            </Box>
          )}
          <Grid container my={2}>
            <Grid item xs={12} md={12} sm={12}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ApartmentIcon />
                    </InputAdornment>
                  ),
                  style: {
                    backgroundColor: theme.palette.background.paper,
                    '&:focus': {
                      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
                    },
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: 'orange',
                    [`&.${inputLabelClasses.shrink}`]: {
                      color: 'red',
                    },
                  },
                }}
                error={Boolean(formik.touched.company && formik.errors.company)}
                size="small"
                fullWidth
                helperText={formik.touched.company && formik.errors.company}
                label="Company name"
                name="company"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.company.toUpperCase()}
                variant="outlined"
                autoComplete="off"
              />
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item xs={12} md={12} sm={12}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                  style: {
                    backgroundColor: theme.palette.background.paper,
                    '&:focus': {
                      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
                    },
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: 'orange',
                    [`&.${inputLabelClasses.shrink}`]: {
                      color: 'red',
                    },
                  },
                }}
                error={Boolean(
                  formik.touched.username && formik.errors.username
                )}
                fullWidth
                helperText={formik.touched.username && formik.errors.username}
                size="small"
                label="Username"
                name="username"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.username}
                variant="outlined"
                autoComplete="off"
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12} sm={12}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <KeyIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: {
                    backgroundColor: theme.palette.background.paper,
                    '&:focus': {
                      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
                    },
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: 'orange',
                    [`&.${inputLabelClasses.shrink}`]: {
                      color: 'red',
                    },
                  },
                }}
                error={Boolean(
                  formik.touched.password && formik.errors.password
                )}
                fullWidth
                helperText={formik.touched.password && formik.errors.password}
                size="small"
                label="Password"
                name="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type={showPassword ? 'text' : 'password'}
                value={formik.values.password}
                variant="outlined"
                autoComplete="off"
              />
            </Grid>
          </Grid>
          {/* <Grid display="flex" justifyContent="right">
              <Typography
                component={Link}
                to="/resetPassword"
                mt={1}
                sx={{
                  cursor: 'pointer',
                  color: 'rgba(22, 22, 22, 0.6) ',
                  transition: 'all 0.3s ease-in-out',

                  '&:hover': {
                    color: 'rgba(22, 22, 22, 1)',
                  },
                }}
              >
                {t('forgot_password')}
              </Typography>
            </Grid> */}

          <Box sx={{ pt: 2 }}>
            <Button
              // disabled={formik.isSubmitting}
              fullWidth
              size="medium"
              type="submit"
              variant="contained"
              sx={{
                textTransform: 'none',
                fontSize: 20,
                transition: 'all 0.3s ease-in-out',
                backgroundColor: '#2A455F',
                '&:hover': {
                  backgroundColor: '#2A455F',
                  opacity: 0.95,
                },
              }}
            >
              Login
            </Button>
          </Box>
        </form>
      </Container>
    </>
  );
}
