/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef, useState } from 'react';
import SimplePeer, { Instance, SignalData } from 'simple-peer';
import './style.scss';

const ConnectionStatus = {
  OFFERING: 'OFFERING',
  RECEIVING: 'RECEIVING',
  CONNECTED: 'CONNECTED',
};

const webSocketConnection = new WebSocket(process.env.REACT_APP_VIDEO_CHAT_URL);

export default function VideoCall() {
  const videoSelf = useRef();
  const videoCaller = useRef();
  const [connectionStatus, setConnectionStatus] = useState();
  const [offerSignal, setOfferSignal] = useState();
  const [simplePeer, setSimplePeer] = useState();

  useEffect(() => {
    webSocketConnection.onmessage = (message) => {
      console.log(message);
      const payload = JSON.parse(message.data);
      if (payload.type === 'offer') {
        setOfferSignal(payload);
        setConnectionStatus(ConnectionStatus.RECEIVING);
      } else if (payload.type === 'answer') simplePeer.signal(payload);
    };
  }, [simplePeer]);

  const sendOrAcceptInvitation = (isInitiator, offer) => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((mediaStream) => {
        const video = videoSelf.current;
        video.srcObject = mediaStream;
        video.play();

        const sp = new SimplePeer({
          trickle: false,
          initiator: isInitiator,
          stream: mediaStream,
        });

        if (isInitiator) setConnectionStatus(ConnectionStatus.OFFERING);
        if (offer) sp.signal(offer);

        sp.on('signal', (data) =>
          webSocketConnection.send(JSON.stringify(data))
        );
        sp.on('connect', () => setConnectionStatus(ConnectionStatus.CONNECTED));
        sp.on('stream', (stream) => {
          const video = videoCaller.current;
          video.srcObject = stream;
          video.play();
        });
        setSimplePeer(sp);
      });
  };

  console.log(connectionStatus);

  return (
    <div className="web-rtc-page">
      <button onClick={() => sendOrAcceptInvitation(true)}>CALL</button>

      {connectionStatus === ConnectionStatus.OFFERING && (
        <div className="loader" />
      )}
      {connectionStatus === ConnectionStatus.RECEIVING && (
        <button onClick={() => sendOrAcceptInvitation(false, offerSignal)}>
          ANSWER CALL
        </button>
      )}
      <div className="video-container">
        <video ref={videoSelf} className="video-block" />
        <video ref={videoCaller} className="video-block" />
      </div>
    </div>
  );
}
