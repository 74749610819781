import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import InputMask from 'react-input-mask';
import {
  colors,
  Box,
  Button,
  Card,
  Container,
  TextField,
  Typography,
  Grid,
  FormHelperText,
} from '@mui/material';
import { toast, ToastContainer, Flip } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { useDispatch } from 'react-redux';
import Loading from '../../../../components/main/Loading';
import auth from '../../../../services/auth';
import usersApi from '../../../../services/users';
import { setVerify } from '../../../../redux/modules/auth/actions';
import LBSIcon from '../../../../assets/img/logo/lbs.png';

const ResetPassword = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isInvalid, setIsInvalid] = useState(false);
  const [loading, setLoading] = useState(false);
  const schema = Yup.object().shape({
    company: Yup.string().required(t('Required')),
    email: Yup.string().required(t('Required')),
  });

  const formik = useFormik({
    initialValues: {
      company: '',
      email: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      setLoading(true);
      usersApi
        .sendConfirmationCode2(values.company, { email: values.email })
        .then((res) => {
          localStorage.setItem('verify_company', values.company);
          localStorage.setItem('verify_email', values.email);
          toast.success(res, {
            transition: Flip,
          });

          setLoading(false);
          navigate('/verify');
        })
        .catch((err) => {
          setLoading(false);
          setIsInvalid(true);
          console.log(err.response);
          toast.error(err.response.data, {
            transition: Flip,
          });
        });
    },
  });
  return (
    <>
      <Helmet>
        <title>{t('Reset password')}</title>
      </Helmet>
      <Loading loading={loading} />
      <Box
        className="bg-full-screen-image"
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth="sm">
          <Card sx={{ padding: 2 }}>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ mb: 6 }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="h3" color="text.primary">
                    {t('Reset password')}
                  </Typography>
                </div>
              </Box>
              <Grid container>
                {isInvalid && (
                  <Box sx={{ mb: 4 }} display="flex" justifyContent="center">
                    <Typography variant="body1" color="error">
                      {t('Invalid company or email')}
                    </Typography>
                  </Box>
                )}
                <Grid item xs={2} md={12} sm={12}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    // color={colors.black}
                  >
                    {t('Company')}
                  </Typography>
                  <TextField
                    // className={classes.search}
                    name="company"
                    fullWidth
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    placeholder={t('enter company')}
                    value={formik.values.company.toUpperCase()}
                    error={
                      formik.touched.company && Boolean(formik.errors.company)
                    }
                  />
                  <FormHelperText
                    sx={
                      {
                        // color: colors.red[700],
                      }
                    }
                  >
                    {formik.errors.company &&
                      formik.touched.company &&
                      formik.errors.company}
                  </FormHelperText>
                </Grid>
                <Grid item xs={2} md={12} sm={12}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    // color={colors.black}
                  >
                    {t('Email')}
                  </Typography>
                  <TextField
                    // className={classes.search}
                    name="email"
                    fullWidth
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    placeholder={t('enter email')}
                    value={formik.values.email}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                  />
                  <FormHelperText
                    sx={
                      {
                        // color: colors.red[700],
                      }
                    }
                  >
                    {formik.errors.email &&
                      formik.touched.email &&
                      formik.errors.email}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid display="flex" justifyContent="right">
                <Typography
                  component={Link}
                  to="/login"
                  mt={1}
                  sx={{
                    cursor: 'pointer',
                    color: 'rgba(22, 22, 22, 0.6) ',
                    transition: 'all 0.3s ease-in-out',
                    paddingRight: 2,
                    '&:hover': {
                      color: 'rgba(22, 22, 22, 1)',
                    },
                  }}
                >
                  {t('Login')}
                </Typography>
              </Grid>

              <Box sx={{ py: 2 }}>
                <Button
                  // disabled={formik.isSubmitting}
                  fullWidth
                  size="small"
                  type="submit"
                  variant="contained"
                  sx={{
                    textTransform: 'none',
                    fontSize: 20,
                    transition: 'all 0.3s ease-in-out',
                    backgroundColor: '#2A455F',
                    '&:hover': {
                      backgroundColor: '#2A455F',
                      opacity: 0.8,
                    },
                  }}
                >
                  {t('Confirm')}
                </Button>
              </Box>
            </form>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default ResetPassword;
