import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { CardMedia } from '@mui/material';
import ImageViewer from 'react-simple-image-viewer';
import UploadIcon from '../../../../assets/icons/upload.svg';

export default ({ value }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(value.photo);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      <div
        style={{
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '60%',
        }}
      >
        <CardMedia
          component="img"
          style={{
            width: 210,
            height: 210,
            borderRadius: 5,
            objectFit: 'fill',
          }}
          image={value.photo || UploadIcon}
          alt=""
          onClick={() => openImageViewer()}
        />
        {isViewerOpen && (
          <ImageViewer
            className="sticky"
            src={[value.photo]}
            currentIndex={0}
            onClose={closeImageViewer}
            disableScroll={true}
            backgroundStyle={{
              backgroundColor: 'rgba(0,0,0,0.9)',
            }}
            closeOnClickOutside={true}
          />
        )}
      </div>
    </>
  );
};
