import React, { useState } from 'react';
import Moment from 'react-moment';

export default ({ value }) => {
  return (
    <>
      {value !== null && value !== undefined ? (
        <div>
          <Moment format="YYYY-MM-DD HH:mm">{value}</Moment>
        </div>
      ) : null}
    </>
  );
};
