import React from 'react';
import { Helmet } from 'react-helmet';
import { Card, CardBody } from 'reactstrap';
import { CardHeader, Grid, Box } from '@mui/material';
import AppStoreDownloadIcon from '../../../assets/icons/Download_on_the_App_Store_Badge_US.svg';

export default function Apps() {
  return (
    <>
      <Helmet>
        <title> Apps </title>
      </Helmet>

      {/* <Loading loading={loading} /> */}

      <Box
        sx={{
          backgroundColor: '#000',
          height: 'calc(100vh - 6.5rem)',
          py: 1,
        }}
      >
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <Card style={{ marginRight: 8 }}>
              <CardHeader
                titleTypographyProps={{ variant: 'h4' }}
                title="Mobile app for drivers"
              />
              <CardBody>
                <div style={{ display: 'd-flex' }}>
                  <a href="https://play.google.com/store/apps/details?id=com.lbs.mobile.android&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                    <img
                      alt="Get it on Google Play"
                      src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                      width={300}
                    />
                  </a>
                  <a href="https://testflight.apple.com/join/0uRfu463">
                    <img
                      alt="Get it on App store"
                      src={AppStoreDownloadIcon}
                      width={300}
                      height={80}
                    />
                  </a>
                </div>
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
