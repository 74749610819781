import React, { useState } from 'react';
import {
  NavLink as RouterLink,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/styles';
import {
  ListItemButton,
  Collapse,
  ListItemIcon,
  ListItemText,
  Grid,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { clearToken } from '../../../../redux/modules/auth/actions';
import SweetAlert from '../../SweetAlert';

const NavItem = ({
  index,
  expand,
  href,
  unit,
  icon: Icon,
  title,
  titleName,
  logout,
  subItemsTrip,
  subItemsLoad,
  subItemsTrailer,
  isOpenLoad,
  isOpenTrip,
  isOpenTrailer,
  activeTrip,
  activeLoad,
  activeTrailer,
  handleColapse,
  ...rest
}) => {
  const location = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [deleteAlert, setDeleteAlert] = useState(false);

  const theme = useTheme();

  const handleLogOut = () => {
    const accounts = localStorage.getItem('accounts');
    const accs = [];
    if (accounts !== null) {
      const acc = JSON.parse(accounts);
      if (acc.length === 1) {
        localStorage.clear();
      } else {
        acc.forEach((item) => {
          if (item === localStorage.getItem('current_account')) {
            dispatch(clearToken(item));
            localStorage.removeItem(
              localStorage.getItem('current_account') + '_access_token'
            );
          } else {
            accs.push(item);
          }
        });
        localStorage.setItem('accounts', JSON.stringify(accs));
        localStorage.setItem('current_account', accs[0].toUpperCase());
      }
    }

    window.open(location.pathname, '_self');
  };

  // const active = href ? !!matchPath({ path: href }, location.pathname) : false;
  const active = location.pathname.startsWith(unit);

  if (subItemsTrip || subItemsLoad || subItemsTrailer) {
    return (
      <>
        {subItemsTrip && (
          <div>
            <Tooltip title={titleName} placement="right" arrow>
              <ListItemButton
                key={index}
                onClick={() => {
                  navigate(href);
                }}
                disableGutters
                component={RouterLink}
                sx={{
                  paddingLeft: 0.5,
                  top: 3,
                  bottom: 3,
                  borderTopRightRadius: 3,
                  borderBottomRightRadius: 3,
                  color: '#fff',
                  fontWeight: 'medium',
                  letterSpacing: 0,
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  },
                  ...(activeTrip && {
                    color: '#E17436',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    '&:after': {
                      content: '" "',
                      position: 'absolute',
                      top: 0,
                      left: '-4px',
                      height: '100%',
                      width: '4px',
                      backgroundColor: '#E17436',
                    },
                  }),
                  '& svg': {
                    mr: 1,
                  },
                }}
                {...rest}
                to={href}
              >
                {Icon && <Icon size="20" />}
                {expand ? (
                  <>
                    <ListItemText primary={t(title)} />
                    <ListItemIcon
                      sx={{
                        justifyContent: 'flex-end',
                        color: '#fff',
                      }}
                      onClick={() => handleColapse(index)}
                    >
                      {isOpenTrip ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowRightIcon />
                      )}
                    </ListItemIcon>
                  </>
                ) : null}
              </ListItemButton>
            </Tooltip>
            <>
              {expand ? (
                <Grid container>
                  <Grid item xs={2} md={1} sm={2}>
                    <Divider
                      sx={{
                        height: '100%',
                        width: '0px',
                        backgroundColor: '#fff',
                        color: '#fff',
                        position: 'relative',
                        left: '12px',
                        top: 5,
                        bottom: 5,
                      }}
                      orientation="vertical"
                    />
                  </Grid>
                  <Grid item xs={10} md={11} sm={10}>
                    <Collapse
                      in={isOpenTrip}
                      // timeout="auto"
                      // unmountOnExit
                      sx={{
                        paddingLeft: theme.spacing(10) + 30,
                        width: '100%',
                      }}
                    >
                      {subItemsTrip.map((childrenLink, i) => (
                        <Tooltip
                          key={`${i + index}`}
                          title={childrenLink.title}
                          placement="right"
                          arrow
                        >
                          <ListItemButton
                            onClick={() => {
                              if (
                                childrenLink.href !== '/app/account' &&
                                childrenLink.href !== '/app/settings'
                              ) {
                                localStorage.setItem(
                                  'last_page',
                                  childrenLink.href
                                );
                              }
                            }}
                            key={`${i + index}`}
                            component={RouterLink}
                            disableGutters
                            sx={{
                              top: 5,
                              bottom: 5,
                              p: 0,
                              pl: 0.5,
                              color: '#fff',
                              borderTopRightRadius: 3,
                              borderBottomRightRadius: 3,
                              fontWeight: 'medium',
                              // justifyContent: 'flex-start',
                              letterSpacing: 0,
                              textTransform: 'none',
                              '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                              },
                              ...(location.pathname.startsWith(
                                childrenLink.href
                              ) && {
                                color: '#E17436',
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                '&:after': {
                                  content: '" "',
                                  position: 'absolute',
                                  top: 0,
                                  left: '-4px',
                                  height: '100%',
                                  width: '4px',
                                  backgroundColor: '#E17436',
                                },
                                '&:hover': {
                                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                },
                              }),
                              '& svg': {
                                mr: 1,
                              },
                            }}
                            {...rest}
                            to={childrenLink.href}
                          >
                            <childrenLink.icon>
                              <childrenLink.icon />
                            </childrenLink.icon>
                            {expand ? (
                              <ListItemText
                                sx={{
                                  py: 1,
                                  pl: 0.5,
                                }}
                                primary={t(childrenLink.title)}
                              />
                            ) : null}
                          </ListItemButton>
                        </Tooltip>
                      ))}
                    </Collapse>
                  </Grid>
                </Grid>
              ) : null}{' '}
            </>
          </div>
        )}
        {subItemsLoad && (
          <div>
            <Tooltip title={titleName} placement="right" arrow>
              <ListItemButton
                key={index}
                onClick={() => navigate(href)}
                disableGutters
                component={RouterLink}
                sx={{
                  display: 'flex',
                  color: '#fff',
                  pl: 0.5,
                  borderTopRightRadius: 3,
                  borderBottomRightRadius: 3,
                  top: 3,
                  fontWeight: 'medium',
                  justifyContent: 'flex-start',
                  letterSpacing: 0,
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  },
                  ...(activeLoad && {
                    color: '#E17436',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    '&:after': {
                      content: '" "',
                      position: 'absolute',
                      top: 0,
                      left: '-4px',
                      height: '100%',
                      width: '4px',
                      backgroundColor: '#E17436',
                    },
                  }),
                  '& svg': {
                    mr: 1,
                  },
                }}
                {...rest}
                to={href}
              >
                {Icon && <Icon size="20" />}
                {expand ? (
                  <>
                    <ListItemText sx={{}} primary={t(title)} />
                    <ListItemIcon
                      sx={{
                        justifyContent: 'flex-end',
                        color: '#fff',
                      }}
                      onClick={() => handleColapse(index)}
                    >
                      {isOpenLoad ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowRightIcon />
                      )}
                    </ListItemIcon>
                  </>
                ) : null}
              </ListItemButton>
            </Tooltip>
            <>
              {expand ? (
                <Grid container>
                  <Grid item xs={2} md={1} sm={2}>
                    <Divider
                      sx={{
                        height: '100%',
                        width: '0px',
                        backgroundColor: '#fff',
                        color: '#fff',
                        position: 'relative',
                        left: '12px',
                        top: 5,
                        bottom: 5,
                      }}
                      orientation="vertical"
                    />
                  </Grid>
                  <Grid item xs={10} md={11} sm={10}>
                    <Collapse
                      in={isOpenLoad}
                      // timeout="auto"
                      // unmountOnExit
                      sx={{
                        paddingLeft: theme.spacing(10) + 30,
                        width: '100%',
                      }}
                    >
                      {subItemsLoad.map((childrenLink, i) => (
                        <Tooltip
                          key={`${i + index}`}
                          title={childrenLink.title}
                          placement="right"
                          arrow
                        >
                          <ListItemButton
                            onClick={() => {
                              if (
                                childrenLink.href !== '/app/account' &&
                                childrenLink.href !== '/app/settings'
                              ) {
                                localStorage.setItem(
                                  'last_page',
                                  childrenLink.href
                                );
                              }
                            }}
                            key={`${i + index}`}
                            component={RouterLink}
                            disableGutters
                            sx={{
                              top: 5,
                              bottom: 5,
                              borderTopRightRadius: 3,
                              borderBottomRightRadius: 3,
                              py: 0,
                              pl: 1,
                              color: '#fff',
                              fontWeight: 'medium',
                              letterSpacing: 0,
                              textTransform: 'none',
                              '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                              },
                              ...(location.pathname.startsWith(
                                childrenLink.href
                              ) && {
                                color: '#E17436',
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                '&:after': {
                                  content: '" "',
                                  position: 'absolute',
                                  top: 0,
                                  left: '-4px',
                                  height: '100%',
                                  width: '4px',
                                  backgroundColor: '#E17436',
                                },
                              }),
                              '& svg': {
                                mr: 1,
                              },
                            }}
                            {...rest}
                            to={childrenLink.href}
                          >
                            <childrenLink.icon>
                              <childrenLink.icon />
                            </childrenLink.icon>
                            {expand ? (
                              <ListItemText
                                sx={{
                                  py: 1.0,
                                  pl: 1,
                                }}
                                primary={t(childrenLink.title)}
                              />
                            ) : null}
                          </ListItemButton>
                        </Tooltip>
                      ))}
                    </Collapse>
                  </Grid>
                </Grid>
              ) : null}
            </>
          </div>
        )}
        {subItemsTrailer && (
          <div>
            <Tooltip title={titleName} placement="right" arrow>
              <ListItemButton
                key={index}
                onClick={() => navigate(href)}
                disableGutters
                component={RouterLink}
                sx={{
                  paddingLeft: 0.5,
                  top: 3,
                  bottom: 3,
                  borderTopRightRadius: 3,
                  borderBottomRightRadius: 3,
                  color: '#fff',
                  fontWeight: 'medium',
                  letterSpacing: 0,
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  },
                  ...(activeTrailer && {
                    color: '#E17436',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    '&:after': {
                      content: '" "',
                      position: 'absolute',
                      top: 0,
                      left: '-4px',
                      height: '100%',
                      width: '4px',
                      backgroundColor: '#E17436',
                    },
                  }),
                  '& svg': {
                    mr: 1,
                  },
                }}
                {...rest}
                to={href}
              >
                {Icon && <Icon size="20" />}
                {expand ? (
                  <>
                    <ListItemText primary={t(title)} />
                    <ListItemIcon
                      sx={{
                        justifyContent: 'flex-end',
                        color: '#fff',
                      }}
                      onClick={() => handleColapse(index)}
                    >
                      {isOpenTrailer ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowRightIcon />
                      )}
                    </ListItemIcon>
                  </>
                ) : null}
              </ListItemButton>
            </Tooltip>
            <>
              {expand ? (
                <Grid container>
                  <Grid item xs={2} md={1} sm={2}>
                    <Divider
                      sx={{
                        height: '100%',
                        width: '0px',
                        backgroundColor: '#fff',
                        color: '#fff',
                        position: 'relative',
                        left: '12px',
                        top: 5,
                        bottom: 5,
                      }}
                      orientation="vertical"
                    />
                  </Grid>
                  <Grid item xs={10} md={11} sm={10}>
                    <Collapse
                      in={isOpenTrailer}
                      // timeout="auto"
                      // unmountOnExit
                      sx={{
                        paddingLeft: theme.spacing(10) + 30,
                        width: '100%',
                      }}
                    >
                      {subItemsTrailer.map((childrenLink, i) => (
                        <Tooltip
                          key={`${i + index}`}
                          title={childrenLink.title}
                          placement="right"
                          arrow
                        >
                          <ListItemButton
                            onClick={() => {
                              if (
                                childrenLink.href !== '/app/account' &&
                                childrenLink.href !== '/app/settings'
                              ) {
                                localStorage.setItem(
                                  'last_page',
                                  childrenLink.href
                                );
                              }
                            }}
                            key={`${i + index}`}
                            component={RouterLink}
                            disableGutters
                            sx={{
                              top: 5,
                              bottom: 5,
                              p: 0,
                              pl: 0.5,
                              color: '#fff',
                              borderTopRightRadius: 3,
                              borderBottomRightRadius: 3,
                              fontWeight: 'medium',
                              // justifyContent: 'flex-start',
                              letterSpacing: 0,
                              textTransform: 'none',
                              '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                              },
                              ...(location.pathname.startsWith(
                                childrenLink.href
                              ) && {
                                color: '#E17436',
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                '&:after': {
                                  content: '" "',
                                  position: 'absolute',
                                  top: 0,
                                  left: '-4px',
                                  height: '100%',
                                  width: '4px',
                                  backgroundColor: '#E17436',
                                },
                                '&:hover': {
                                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                },
                              }),
                              '& svg': {
                                mr: 1,
                              },
                            }}
                            {...rest}
                            to={childrenLink.href}
                          >
                            <childrenLink.icon>
                              <childrenLink.icon />
                            </childrenLink.icon>
                            {expand ? (
                              <ListItemText
                                sx={{
                                  py: 1,
                                  pl: 0.5,
                                }}
                                primary={t(childrenLink.title)}
                              />
                            ) : null}
                          </ListItemButton>
                        </Tooltip>
                      ))}
                    </Collapse>
                  </Grid>
                </Grid>
              ) : null}
            </>
          </div>
        )}
      </>
    );
  }

  return href === '/exit' ? (
    <>
      <SweetAlert
        title="Are you sure ?"
        warning
        show={deleteAlert}
        showCancel
        reverseButtons
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, exit!"
        cancelBtnText="Cancel"
        onConfirm={() => {
          handleLogOut();
        }}
        onCancel={() => setDeleteAlert(false)}
      />
      <Tooltip title={titleName} placement="right" arrow>
        <ListItemButton
          key={index}
          onClick={() => setDeleteAlert(true)}
          disableGutters
          sx={{
            display: 'flex',
            pl: 0.5,
            top: 3,
            color: '#fff',
            borderTopRightRadius: 3,
            borderBottomRightRadius: 3,
            fontWeight: 'medium',
            justifyContent: 'flex-start',
            letterSpacing: 0,
            textTransform: 'none',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            },
            ...(active && {
              color: '#E17436',
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              '&:after': {
                content: '" "',
                position: 'absolute',
                top: 0,
                left: '-4px',
                height: '100%',
                width: '4px',
                backgroundColor: '#E17436',
              },
            }),
            '& svg': {
              mr: 1,
            },
          }}
          {...rest}
        >
          {Icon && <Icon size="20" />}
          {expand ? <ListItemText primary={title} /> : null}
        </ListItemButton>
      </Tooltip>
    </>
  ) : (
    <Tooltip title={titleName} placement="right" arrow>
      <ListItemButton
        key={index}
        component={RouterLink}
        onClick={() => {
          if (href !== '/app/account' && href !== '/app/settings') {
            localStorage.setItem('last_page', href);
          }
        }}
        disableGutters
        sx={{
          paddingLeft: 0.5,
          borderTopRightRadius: 3,
          borderBottomRightRadius: 3,
          color: '#fff',
          // color: 'text.secondary',
          fontWeight: 'medium',
          justifyContent: 'flex-start',
          letterSpacing: 0,
          textTransform: 'none',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
          },
          ...(active && {
            color: '#E17436',
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            '&:after': {
              content: '" "',
              position: 'absolute',
              top: 0,
              left: '-4px',
              height: '100%',
              width: '4px',
              backgroundColor: '#E17436',
            },
          }),
          '& svg': {
            mr: 1,
          },
        }}
        {...rest}
        to={href}
      >
        {Icon && <Icon size="20" />}
        {expand ? (
          <ListItemText
            sx={{
              color: '#fff',
              // color: 'text.secondary',
              fontWeight: 'medium',
              justifyContent: 'flex-start',
              letterSpacing: 0,
              textTransform: 'none',
              width: '100%',
              ...(active && {
                color: '#E17436',
                '&:after': {
                  content: '" "',
                  position: 'absolute',
                  top: 0,
                  left: '-4px',
                  height: '100%',
                  width: '4px',
                  backgroundColor: '#E17436',
                },
              }),
              '& svg': {
                mr: 1,
              },
            }}
            primary={title}
          />
        ) : null}
      </ListItemButton>
    </Tooltip>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
  subitems: PropTypes.array,
};

export default NavItem;
