import React from 'react';
import Chip from '@mui/material/Chip';

export default ({ value }) => {
  return (
    <Chip
      size="small"
      style={{
        textAlign: 'center',
        textTransform: 'uppercase',
        color: 'white',
        backgroundColor:
          value === 'NOT DISPATCHED' ? 'rgb(24, 128, 56)' : '#ea5455',
      }}
      label={value}
      variant="outlined"
    />
  );
};
