import { takeLatest, put, delay } from 'redux-saga/effects';
import types from '../../constants/action-types';
import service from '../../../services/accounting';
import { setData, setError, setTotal, setLoading } from './actions';
import { dataSelector } from './selectors';

function* fetchData({ payload }) {
  try {
    if (payload.isSearch) yield delay(500);

    if (payload.query !== 'page=0&size=10&deleted=false&in_process=false') {
      yield put(setLoading(true));
      const res = yield service.getAllInfo(payload.query);

      const { data, total } = dataSelector(
        res,
        payload.pageIndex,
        payload.pageSize
      );
      // console.log(res);
      yield put(setError(''));
      yield put(setData(data));
      yield put(setTotal(total));
      yield put(setLoading(false));
    }
  } catch (error) {
    yield put(setLoading(false));
    yield put(setError(error.message));
  }
}

function* onSearch({ payload }) {
  yield delay(500);
  console.log(payload);
}

export default function* reportsSaga() {
  yield takeLatest(types.TABLE_REPORTS_FETCH_DATA, fetchData);
  yield takeLatest(types.TABLE_REPORTS_SAGA_ONSEARCH_ACTION, onSearch);
}
