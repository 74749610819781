import React, { useState, useEffect } from 'react';
import {
  // Form,
  Form,
  Button,
  FormGroup,
  Input,
  Col,
  Card,
  CardBody,
  CardHeader,
  // Button
} from 'reactstrap';
import * as Icon from 'react-feather';
import { connect } from 'react-redux';
import { toast, Flip } from 'react-toastify';
import Select from 'react-select';
import { TextField, Box, IconButton, Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from "../../../components/@vuexy/checkbox/CheckboxesVuexy";
import DataTable from 'react-data-table-component';
import { Link, useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import jwtDecode from 'jwt-decode';
import trailerService from '../../../../services/trailer_general';
import tripService from '../../../../services/trips';
import unitService from '../../../../services/units';
import loadService from '../../../../services/loads';
import stateProvinceService from '../../../../services/state_province';

export default function EditTrip() {
  const [defaultChecked, setDefaultChecked] = useState([]);

  const [drivers, setDrivers] = useState([]);
  const [driver, setDriver] = useState(null);
  const [driverId, setDriverId] = useState(null);
  const [secondDriver, setSecondDriver] = useState(null);
  const [secondDrivers, setSecondDrivers] = useState([]);
  const [ownedCompanies, setOwnedCompanies] = useState([]);
  const [loads, setLoads] = useState([]);
  const [trucks, setTrucks] = useState([]);
  const [truck, setTruck] = useState(null);
  const [truckId, setTruckId] = useState(null);
  const [truckInfo, setTruckInfo] = useState(null);
  const [trailers, setTrailers] = useState([]);
  const [trailerInfo, setTrailerInfo] = useState(null);
  const [trailerNumber, setTrailerNumber] = useState(null);
  const [trailerId, setTrailerId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [trailerStatus, setTrailerStatus] = useState(false);
  const [loadsOfTrip, setLoadsOfTrip] = useState([]);
  const [prevLoads, setPrevLoads] = useState([]);
  const [choosedLoadsByNumber, setChoosedLoadsByNumber] = useState([]);
  const [loadsColumns, setLoadsColumns] = useState([
    {
      name: 'Custom Load Number',
      selector: 'customLoadNumber',
      sortable: true,
    },
    {
      name: 'Customer',
      selector: 'customer',
      sortable: true,
    },
    {
      name: 'Pickup',
      selector: 'pickupDateFormatted',
      sortable: true,
    },
    {
      name: 'Delivery',
      selector: 'deliveryDateFormatted',
      sortable: true,
    },
    {
      name: 'From',
      selector: 'from',
      sortable: true,
    },
    {
      name: 'To',
      selector: 'to',
      sortable: true,
    },
  ]);

  const { id } = useParams();

  const chooseLoad = (value) => {
    setChoosedLoadsByNumber(value.selectedRows.map((item) => item.id));
  };

  const secondDriverChange = (val) => {
    if (val == null) {
      setSecondDriver(null);
    } else {
      setSecondDriver(val);
    }
  };

  const editTrip = () => {
    if (choosedLoadsByNumber.length === 0) {
      toast.error('Select loads', { transition: Flip });
    }

    const secondDriverData = secondDriver == null ? null : secondDriver.value;
    if (driverId !== undefined) {
      const data = {
        id,
        driverId,
        loadIds: choosedLoadsByNumber,
        truckId,
        trailerId: trailerStatus ? null : trailerId,
        unknownTrailer: trailerStatus
          ? document.querySelector('#trailerNumber').value
          : '',
        driverInstructions: document.querySelector('#driverInstructions').value,
        secondDriverId: secondDriverData,
        loadMl: document.querySelector('#load_ml').value,
        mtMl: document.querySelector('#mt_ml').value,
      };
      tripService
        .update(data)
        .then((res) => {
          if (res) {
            toast.success('Trip successfuly edited', {
              transition: Flip,
            });
            window.history.back();
          } else {
            toast.error('Something went wrong', { transition: Flip });
            // res.text();
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            transition: Flip,
          });
        });
    } else {
      toast.error('Driver(s) not found. Contact the Accounting', {
        transition: Flip,
      });
    }
  };

  const loadOptions = (inputValue, callback) => {
    let dispatcher = null;
    const userRole = '';
    if (userRole === 'dispatcher') {
      const token = localStorage.getItem(
        localStorage.getItem('current_account') + '_access_token'
      );
      if (token) {
        const decodedToken = jwtDecode(token.replace('Bearer ', ''));
        dispatcher = decodedToken.userId;
      }
    }
    unitService
      .getSearch(
        `${inputValue}${dispatcher ? `&dispatcherId=${dispatcher}` : ''}`
      )
      .then((data) =>
        callback(
          data.map((el) => {
            return {
              ...el,
              value: el.id,
              label: el.number,
            };
          })
        )
      )
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const loadTrailers = (inputValue, callback) => {
    trailerService
      .getAllSearch(`search=${inputValue}`)
      .then((data) =>
        callback(
          data.data.map((el) => {
            return {
              ...el,
              value: el.id,
              label: el.trailerNumber,
            };
          })
        )
      )
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const getTrucks = () => {
    let dispatcher = null;
    const userRole = '';
    if (userRole === 'dispatcher') {
      const token = localStorage.getItem(
        localStorage.getItem('current_account') + '_access_token'
      );
      if (token) {
        const decodedToken = jwtDecode(token.replace('Bearer ', ''));
        dispatcher = decodedToken.userId;
      }
    }
    unitService
      .getAll(
        `size=100000&page=0${dispatcher ? `&dispatcherId=${dispatcher}` : ''}`
      )
      .then((data) => {
        const dataToShow = [];
        data.content.forEach((el, i) => {
          const elToShow = {
            value: el.id,
            label: el.number,
            driverId: el.driverId,
            secondDriverId: el.secondDriverId,
          };
          dataToShow.push(elToShow);
        });
        setTrucks(dataToShow);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const getTrailers = () => {
    trailerService
      .getAll(`size=100000&page=0`)
      .then((data) => {
        const dataToShow = [];
        data.data.forEach((el, i) => {
          const elToShow = {
            value: el.id,
            label: el.trailerNumber,
          };
          dataToShow.push(elToShow);
        });
        setTrailers(dataToShow);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const componentDidMount = () => {
    getTrucks();
    getTrailers();
    // stateProvinceService
    //   .getAll()
    //   .then((data) => setStates(data))
    //   .catch((err) => {
    //     toast.error(err.response.data.message, {
    //       transition: Flip,
    //     });
    //   });
    loadService
      .getAllForTrip('size=100&deleted=false')
      .then((lData) => {
        setPrevLoads(lData.content);
        tripService
          .getContext()
          .then((data) => {
            setDrivers(data.drivers);
            setOwnedCompanies(data.owned_companies);

            const driversData = [];
            data.drivers.forEach((el) => {
              const elToShow = {
                value: el.id,
                label: el.lastName + ' ' + el.firstName,
              };
              driversData.push(elToShow);
            });
            setDrivers(driversData);

            tripService
              .updateContext(id)
              .then((data) => {
                setLoading(false);

                const driverData = driversData.filter((obj) => {
                  return obj.value === parseInt(data.trip.driverId, 10);
                });

                const secondDriverData = driversData.filter((obj) => {
                  return obj.value === parseInt(data.trip.teammateId, 10);
                });

                const loadsData = lData.content;
                data.loads.forEach((item) => {
                  // eslint-disable-next-line no-param-reassign
                  item.isSelected = true;
                  loadsData.push(item);
                });
                if (document.getElementById('driverInstructions')) {
                  document.getElementById('driverInstructions').value =
                    data.trip.driverInstructions;
                }

                if (document.getElementById('load_ml')) {
                  document.getElementById('load_ml').value = data.loadMl || '';
                }

                if (document.getElementById('mt_ml')) {
                  document.getElementById('mt_ml').value = data.mtMl || '';
                }

                setSecondDriver(secondDriverData[0]);
                setTruckInfo({
                  value: data.unit_id,
                  label: data.unit_number,
                });
                setTrailerStatus(data.trip.trailerId === null);
                setTruckId(data.trip.truckId);
                setDriver(driverData[0]);
                setDriverId(data.trip.driverId);
                setLoads(loadsData);
                setLoadsOfTrip(data.loads);

                if (data.trip.trailerId === null) {
                  setTrailerNumber(data.trip.unknownTrailer);
                } else {
                  setTrailerId(data.trip.trailerId);
                  setTrailerInfo({
                    value: data.trip.trailerId,
                    label: data.trip.trailerNumber,
                  });
                }
              })
              .catch((err) => {
                console.log(err);
                toast.error(err.response.data.message, {
                  transition: Flip,
                });
              });
          })
          .catch((err) => {
            toast.error(err.response.data.message, {
              transition: Flip,
            });
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  useEffect(() => {
    setDriver();
    setDriverId();
    setSecondDriver();

    if (truckInfo && truckInfo.driverId) {
      const result = drivers.filter((obj) => {
        return obj.value === parseInt(truckInfo.driverId, 10);
      });
      if (result) {
        setDriver(result[0]);
        setDriverId(truckInfo.driverId);
      }
    }
    if (truckInfo && truckInfo.secondDriverId) {
      const result = drivers.filter((obj) => {
        return obj.value === parseInt(truckInfo.secondDriverId, 10);
      });
      if (result) {
        setSecondDriver(result[0]);
      }
    }
  }, [truckInfo]);

  useEffect(() => {
    componentDidMount();
  }, []);

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.truckInfo !== this.state.truckInfo) {
  //     if (this.state.truckInfo && this.state.truckInfo.driverId) {
  //       let result = this.state.drivers.filter((obj) => {
  //         return obj.value === parseInt(this.state.truckInfo.driverId);
  //       });
  //       if (result)
  //         this.setState({
  //           driver: result[0],
  //           driverId: this.state.truckInfo.driverId,
  //         });
  //     }
  //     if (this.state.truckInfo && this.state.truckInfo.secondDriverId) {
  //       let result = this.state.drivers.filter((obj) => {
  //         return obj.value === parseInt(this.state.truckInfo.secondDriverId);
  //       });
  //       if (result) this.setState({ secondDriver: result[0] });
  //     }
  //   }
  // }

  return (
    <Card>
      <CardHeader>
        <h3 className="mb-0">Editing Trip</h3>
      </CardHeader>
      <CardBody>
        {loading ? (
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 44 }} spin />}
            style={{
              height: 'calc(100vh - 20rem)',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        ) : (
          <>
            <Form className="d-flex">
              <div style={{ width: '50%', marginRight: 20 }}>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Truck</span>
                    <span style={{ color: 'red' }}> *</span>
                  </Col>
                  <Col md="8">
                    <AsyncSelect
                      noOptionsMessage={(value) =>
                        !value.inputValue
                          ? 'type something to search'
                          : 'nothing to show'
                      }
                      placeholder="Search"
                      isClearable={true}
                      value={truckInfo}
                      defaultOptions={trucks}
                      loadOptions={loadOptions}
                      onChange={(value) => {
                        if (value !== null) {
                          setTruckId(value.value);
                          setTruckInfo(value);
                        } else {
                          setTruckId(null);
                          setTruckInfo(null);
                        }
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary50: '#fe810b',
                          primary25: 'rgb(253, 179, 46)',
                          primary: 'rgb(253, 179, 46)',
                        },
                      })}
                    />
                  </Col>
                </FormGroup>
                {!trailerStatus ? (
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Trailer number</span>
                      <span style={{ color: 'red' }}> *</span>
                    </Col>
                    <Col md="8">
                      <AsyncSelect
                        noOptionsMessage={(value) =>
                          !value.inputValue
                            ? 'type something to search'
                            : 'nothing to show'
                        }
                        placeholder="Search"
                        isClearable={true}
                        value={trailerInfo}
                        defaultOptions={trailers}
                        loadOptions={loadTrailers}
                        onChange={(value) => {
                          if (value !== null) {
                            setTrailerId(value.value);
                            setTrailerInfo(value);
                          } else {
                            setTrailerId(null);
                            setTrailerInfo(null);
                          }
                        }}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary50: '#fe810b',
                            primary25: 'rgb(253, 179, 46)',
                            primary: 'rgb(253, 179, 46)',
                          },
                        })}
                      />
                    </Col>
                  </FormGroup>
                ) : (
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Trailer number</span>
                      <span style={{ color: 'red' }}> *</span>
                    </Col>
                    <Col md="8">
                      <Input
                        value={trailerNumber}
                        type="text"
                        id="trailerNumber"
                        placeholder="Trailer number"
                      />
                    </Col>
                  </FormGroup>
                )}

                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Unknown trailer ?</span>
                  </Col>
                  <Col>
                    <FormControlLabel
                      id="trailer_status"
                      name="trailer_status"
                      control={<Checkbox />}
                      label=""
                      checked={trailerStatus}
                      onChange={(val) => {
                        setTrailerStatus(!trailerStatus);
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="4">
                    <span>Driver Instructions</span>
                  </Col>
                  <Col md="8">
                    <Input
                      type="textarea"
                      id="driverInstructions"
                      placeholder=""
                      maxLength="1000"
                    />
                  </Col>
                </FormGroup>
              </div>
              <div style={{ flex: 1 }}>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Driver</span>
                    <span style={{ color: 'red' }}> *</span>
                  </Col>
                  <Col md="8">
                    <Select
                      className="React"
                      classNamePrefix="select"
                      name="color"
                      options={drivers}
                      value={driver}
                      isClearable={true}
                      onChange={(value) => {
                        if (value !== null) {
                          setDriverId(value.value);
                          setDriver(value);
                        } else {
                          setDriverId(null);
                          setDriver(null);
                        }
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary50: '#fe810b',
                          primary25: 'rgb(253, 179, 46)',
                          primary: 'rgb(253, 179, 46)',
                        },
                      })}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Second driver(optimal)</span>
                  </Col>
                  <Col md="8">
                    <Select
                      className="React"
                      classNamePrefix="select"
                      name="color"
                      value={secondDriver}
                      options={drivers}
                      isClearable={true}
                      onChange={(val) => {
                        if (val == null) {
                          setSecondDriver(null);
                        } else {
                          setSecondDriver(val);
                        }
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary50: '#fe810b',
                          primary25: 'rgb(253, 179, 46)',
                          primary: 'rgb(253, 179, 46)',
                        },
                      })}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Load ml</span>
                  </Col>
                  <Col md="8">
                    <TextField
                      autoComplete="off"
                      fullWidth
                      size="small"
                      id="load_ml"
                      label=""
                      variant="outlined"
                      onChange={(e) => {}}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>MT ml</span>
                  </Col>
                  <Col md="8">
                    <TextField
                      autoComplete="off"
                      fullWidth
                      size="small"
                      id="mt_ml"
                      label=""
                      variant="outlined"
                      onChange={(e) => {}}
                    />
                  </Col>
                </FormGroup>
              </div>
            </Form>
            <DataTable
              data={loads}
              columns={loadsColumns}
              noHeader
              selectableRows
              onSelectedRowsChange={chooseLoad}
              selectableRowSelected={(row) => row.isSelected}
              selectableRowsPreSelectedField="selected"
              selectableRowsComponent={Checkbox}
              selectableRowsComponentProps={{
                color: 'primary',
                icon: <Icon.Check className="vx-icon" size={20} />,
                label: '',
              }}
            />
            <div className="mt-3">Edit Load</div>
            {loadsOfTrip &&
              loadsOfTrip.map((item, i) => {
                return (
                  <Link key={`${i + 1}`} to={`/loads/edit/${item.id}`}>
                    {item.customLoadNumber} <br />
                  </Link>
                );
              })}
            <Button
              color="success"
              className="d-flex align-items-center mt-4"
              type="button"
              onClick={() => editTrip()}
            >
              <Icon.Check size={22} /> Save Trip
            </Button>
          </>
        )}
      </CardBody>
    </Card>
  );
}

// const mapStateToProps = (state) => {
//   return {
//     token: state.auth.login.token,
//     userRole: state.auth.login.userRole,
//   };
// };
// export default connect(mapStateToProps)(NewTrip);
