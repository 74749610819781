export default {
  TABLE_DRIVER_NOTIFICATIONS_FETCH_STATUSES:
    'TABLE_DRIVER_NOTIFICATIONS_FETCH_STATUSES',
  TABLE_DRIVER_NOTIFICATIONS_SET_DATA: 'TABLE_DRIVER_NOTIFICATIONS_SET_DATA',
  TABLE_DRIVER_NOTIFICATIONS_SET_TOTAL: 'TABLE_DRIVER_NOTIFICATIONS_SET_TOTAL',
  TABLE_DRIVER_NOTIFICATIONS_SET_LOADING:
    'TABLE_DRIVER_NOTIFICATIONS_SET_LOADING',
  TABLE_DRIVER_NOTIFICATIONS_ERROR: 'TABLE_DRIVER_NOTIFICATIONS_ERROR',
  TABLE_DRIVER_NOTIFICATIONS_FETCH_DATA:
    'TABLE_DRIVER_NOTIFICATIONS_FETCH_DATA',
  TABLE_DRIVER_NOTIFICATIONS_SAGA_ONSEARCH_ACTION:
    'TABLE_DRIVER_NOTIFICATIONS_SAGA_ONSEARCH_ACTION',
};
