export function dataSelector(dataResponse, page, size) {
  const filtered = dataResponse.data.map((item, i) => ({
    index: i + 1 + page * size,
    trailerNumber: item.trailerNumber,
    idleDays: item.idleDays,
    lastDeliveryDate: item.lastDeliveryDate,
    // trailerOwned: item.trailerOwned,
    lastDeliveryLocation: item.lastDeliveryLocation,
    currentLocation: item.currentLocation,
    lastUsedUnit: item.lastUsedUnit,
    data: item,
  }));
  return { total: dataResponse.total, data: filtered };
}
