import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Countdown from 'react-countdown';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Container,
  Typography,
  TextField,
  Grid,
  InputAdornment,
  Card,
} from '@mui/material';
import ReactInputVerificationCode from 'react-input-verification-code';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer, Flip } from 'react-toastify';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import KeyIcon from '@mui/icons-material/Key';
import PinIcon from '@mui/icons-material/Pin';
import { makeStyles, useTheme } from '@mui/styles';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '../../../../components/main/Loading';
import auth from '../../../../services/auth';
import { setVerify, clearToken } from '../../../../redux/modules/auth/actions';
import usersApi from '../../../../services/users';

const StyledReactInputVerificationCode = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  --ReactInputVerificationCode-itemWidth: 46px;
  --ReactInputVerificationCode-itemHeight: 46px;
  --ReactInputVerificationCode-itemSpacing: 24px;

  .ReactInputVerificationCode__item {
    font-size: 16px;
    font-weight: 500;
    color: #000;

    background: rgba(229, 238, 247, 0.4);
    border: 1px solid
      ${({ isInvalid }) => (isInvalid ? '#EF6C65' : 'rgba(42, 69, 95, 0.45);')};
    border-radius: 4px;
    box-shadow: none;
  }

  .ReactInputVerificationCode__item.is-active {
    box-shadow: none;
    border: 1px solid #36c6d9;
  }
`;

const Verification = () => {
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const Ref = useRef(null);
  const [timer, setTimer] = useState('00:00');
  // The state for our timer

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);

  const schema = Yup.object().shape({
    confirmation_code: Yup.string().max(255).required('Required'),
    password: Yup.string().max(255).required('Required'),
    password2: Yup.string().min(4).max(255).required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      confirmation_code: '',
      password: '',
      password2: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (values.password !== values.password2) {
        toast.error('Passwords do not match.', {
          transition: Flip,
        });
      } else {
        const company = localStorage.getItem('verify_company');
        const email = localStorage.getItem('verify_email');
        const data = {
          code: values.confirmation_code,
          password: values.password,
          email,
          company: company.toLowerCase(),
        };

        setLoading(true);
        usersApi
          .resetPassword2(data)
          .then((res) => {
            toast.success('Update password', {
              transition: Flip,
            });
            setLoading(false);
            localStorage.clear();
            navigate('/login');
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            toast.error(err.response.data.message, {
              transition: Flip,
            });
          });
      }
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    const { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        (minutes > 9 ? minutes : '0' + minutes) +
          ':' +
          (seconds > 9 ? seconds : '0' + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer('02:00');

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    const deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 120);
    return deadline;
  };

  const resend = (e) => {
    const company = localStorage.getItem('verify_company');
    const email = localStorage.getItem('verify_email');
    setLoading(true);
    usersApi
      .sendConfirmationCode2(company, { email })
      .then((res) => {
        clearTimer(getDeadTime());
        toast.success(res, {
          transition: Flip,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data, {
          transition: Flip,
        });
      });
  };

  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  return (
    <>
      <Helmet>
        <title>Verification</title>
      </Helmet>
      <Loading loading={loading} />
      <Box
        className="bg-full-screen-image"
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <Container sx={{ width: 'fit-content' }}>
          <Card sx={{ padding: 2 }}>
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
              <Box sx={{ mb: 4 }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="h4" color="textPrimary">
                    {t('Verification')}
                  </Typography>
                </div>
              </Box>
              <Grid container my={2}>
                <Grid item xs={12} md={12} sm={12}>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PinIcon />
                        </InputAdornment>
                      ),
                      style: {
                        backgroundColor: theme.palette.background.paper,
                        '&:focus': {
                          boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
                        },
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        color: 'orange',
                        [`&.${inputLabelClasses.shrink}`]: {
                          color: 'red',
                        },
                      },
                    }}
                    error={Boolean(
                      formik.touched.confirmation_code &&
                        formik.errors.confirmation_code
                    )}
                    size="small"
                    fullWidth
                    helperText={
                      formik.touched.confirmation_code &&
                      formik.errors.confirmation_code
                    }
                    label="Confirmation code"
                    name="confirmation_code"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.confirmation_code}
                    variant="outlined"
                    autoComplete="off"
                  />
                </Grid>
              </Grid>
              <Grid container display="flex">
                <Grid item xs={12} md={8} sm={12}>
                  <Typography
                    sx={{
                      color: 'rgba(22, 22, 22, 0.6) ',
                    }}
                  >
                    Enter the code we have sent your email
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sm={12}
                  display="flex"
                  justifyContent="right"
                >
                  <Typography
                    sx={{
                      color: 'rgba(22, 22, 22, 0.6) ',
                    }}
                  >
                    {timer}
                  </Typography>
                </Grid>
              </Grid>
              <Box sx={{ mb: 2 }} display="flex" justifyContent="center">
                <Typography variant="body1" color="error">
                  Password - must contain at least one uppercase and lowercase
                  letter, and at least 6 or more characters.
                </Typography>
              </Box>
              <Grid container my={2}>
                <Grid item xs={12} md={12} sm={12}>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <KeyIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      style: {
                        backgroundColor: theme.palette.background.paper,
                        '&:focus': {
                          boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
                        },
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        color: 'orange',
                        [`&.${inputLabelClasses.shrink}`]: {
                          color: 'red',
                        },
                      },
                    }}
                    error={Boolean(
                      formik.touched.password && formik.errors.password
                    )}
                    fullWidth
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    size="small"
                    label="Password"
                    name="password"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.password}
                    variant="outlined"
                    autoComplete="off"
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={12} sm={12}>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <KeyIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      style: {
                        backgroundColor: theme.palette.background.paper,
                        '&:focus': {
                          boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
                        },
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        color: 'orange',
                        [`&.${inputLabelClasses.shrink}`]: {
                          color: 'red',
                        },
                      },
                    }}
                    error={Boolean(
                      formik.touched.password2 && formik.errors.password2
                    )}
                    fullWidth
                    helperText={
                      formik.touched.password2 && formik.errors.password2
                    }
                    size="small"
                    label="Confirmation password"
                    name="password2"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.password2}
                    variant="outlined"
                    autoComplete="off"
                  />
                </Grid>
              </Grid>

              <Box sx={{ pt: 5 }}>
                <Button
                  fullWidth
                  size="small"
                  type="submit"
                  variant="contained"
                  sx={{
                    textTransform: 'none',
                    fontSize: '16px',
                    transition: 'all 0.3s ease-in-out',
                    backgroundColor: '#2A455F',
                    '&:hover': {
                      backgroundColor: '#2A455F',
                      opacity: 0.8,
                    },
                  }}
                >
                  Confirm
                </Button>
              </Box>
              <Box sx={{ pt: 5 }} display="flex" justifyContent="center">
                <Typography
                  onClick={resend}
                  sx={{
                    color: 'rgba(22, 22, 22, 0.6) ',
                    fontSize: '14px',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                      color: '#2A455F',
                    },
                  }}
                >
                  Resend
                </Typography>
              </Box>
            </form>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Verification;
