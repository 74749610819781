export function dataSelector(dataResponse, page, size) {
  const filtered = dataResponse.content.map((item, i) => ({
    index: i + 1 + page * size,
    id: item.id,
    loadNumber: item.loadNumber,
    loadId: item.loadId,
    driverId: item.driverId,
    driver: item.driverName,
    accessoryDriverPay: item.accessoryDriverPay,
    primaryPhone: item.phone,
    driverAdvance: item.driverAdvance,
    to: item.to,
    statusName: item.statusName,
    statusColor: item.statusColor,
    hiredOn: item.hireDate,
    delivery: item.deliveryDateFormatted,
    pickup: item.pickDateFormatted,
    teammateName: item.teammateName,
    teammateId: item.teammateId,
    unitNumber: item.unitNumber,
    trailerNumber: item.trailerNumber
      ? item.trailerNumber
      : item.unknownTrailer,
    data: item,
  }));
  return { total: dataResponse.total_elements, data: filtered };
}
