import React, { useEffect, useState, useRef } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import { IconButton, Chip, Badge } from '@mui/material';
import {
  NavLink as RouterLink,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import jwtDecode from 'jwt-decode';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { styled, alpha } from '@mui/material/styles';
import ListItemIcon from '@mui/material/ListItemIcon';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MessageIcon from '@mui/icons-material/Message';
import BellAnimationDiv from '../Animations/Bell';
import settingService from '../../../services/settings';
import Loading from '../Loading';
import NotificationSound from './notification-sound.mp3';

export default () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [notifications, setNotifications] = useState([]);
  const notification = useSelector((state) => state.storeReducer.notification);
  const Ref = useRef(null);

  const token = localStorage.getItem(
    localStorage.getItem('current_account') + '_access_token'
  );
  const decodedToken = jwtDecode(token);

  const open = Boolean(anchorEl);

  const playAudioNotification = () => {
    const clickSound = new Audio(NotificationSound);
    clickSound.play().catch((error) => {
      console.log('Chrome cannot play sound without user interaction first');
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const countNotifications = () => {
    let arr = [];
    if (localStorage.getItem('notifications') !== null) {
      arr = JSON.parse(localStorage.getItem('notifications'));
    }
    return arr.length;
  };

  const getNotifications = () => {
    settingService
      .getNotifications()
      .then((res) => {
        let arr = [];
        if (localStorage.getItem('notifications') !== null) {
          arr = JSON.parse(localStorage.getItem('notifications'));
        }

        if (res.length > arr.length) {
          playAudioNotification();
        }
        localStorage.setItem('notifications', JSON.stringify(res));
        setNotifications(res);
      })
      .catch((err) => {});
  };

  const updateNotifications = (id) => {
    settingService
      .updateNotification(id)
      .then((res) => {
        getNotifications();
      })
      .catch((err) => {});
  };

  const loadingNotifications = () => {
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      getNotifications();
    }, 120000);
    Ref.current = id;
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const getZipFile = (id, fileName) => {
    window.open(fileName.replace('//app', '/app'), '_blank');
    updateNotifications(id);
  };

  useEffect(() => {
    localStorage.removeItem('notifications');
    getNotifications();
    loadingNotifications();
  }, []);

  useEffect(() => {
    playAudioNotification();
  }, [notification]);

  const StyledBadge = styled(Badge)(({ theme }) => ({
    color: 'blue',
    '& .MuiBadge-badge': {
      right: -5,
      top: 5,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));

  return (
    <div>
      {countNotifications() > 0 ? (
        <AnimatePresence>
          <BellAnimationDiv>
            <IconButton size="medium" onClick={handleClick}>
              <StyledBadge
                badgeContent={countNotifications()}
                color="secondary"
              >
                <NotificationsIcon />
              </StyledBadge>
            </IconButton>
          </BellAnimationDiv>
        </AnimatePresence>
      ) : (
        <IconButton size="medium" onClick={handleClick}>
          <StyledBadge badgeContent={countNotifications()} color="secondary">
            <NotificationsIcon />
          </StyledBadge>
        </IconButton>
      )}

      <Menu
        anchorEl={anchorEl}
        id="long-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            maxHeight: 40 * 4.5,
            // overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {countNotifications() === 0 ? (
          <MenuItem
            disabled={true}
            key="empty"
            onClick={() => {
              handleClose();
            }}
          >
            <ListItemIcon>
              <DeleteOutlineIcon />
            </ListItemIcon>

            {t('Empty')}
          </MenuItem>
        ) : (
          notifications.map((item, i) => {
            return (
              <MenuItem
                key={`${i + 1}`}
                name={item.id}
                value={item.id}
                onClick={() => {
                  if (item.type === 'download') {
                    getZipFile(item.id, item.filesZipName);
                  }
                }}
              >
                <ListItemIcon>
                  {item.type === 'download' ? (
                    <DownloadIcon />
                  ) : (
                    <MessageIcon />
                  )}
                </ListItemIcon>
                {item.description}
              </MenuItem>
            );
          })
        )}
      </Menu>
    </div>
  );
};
