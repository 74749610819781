import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  navFilterSection: {
    width: '100%',
  },
  search: {
    backgroundColor: theme.palette.background.paper,
  },
  inputmask: {
    height: '40px',
    width: '100%',
    fontSize: '16px',
    borderRadius: 4,
  },
  topBtns: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    justifyContent: 'flex-end',
  },
  verifyEmail: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    justifyContent: 'flex-start',
  },
}));
