import types from '../../constants/action-types';

const defaultState = {
  access_token: localStorage.getItem(
    localStorage.getItem('current_account') + '_access_token'
  ),
  refresh_token: localStorage.getItem('refresh_token'),
  // user: localStorage.getItem('user'),
};

const map = {
  [types.SET_TOKEN]: (state, { payload }) => ({
    ...state,
    access_token: payload.data.token.access_token,
    refresh_token: payload.data.token.refresh_token,
  }),
  [types.SET_VERIFY]: (state, { payload }) => ({
    ...state,
    access_token: payload.token.access_token,
  }),
  [types.CLEAR_TOKEN]: (state) => ({
    ...state,
    refresh_token: undefined,
    access_token: undefined,
  }),
};

export default (state, action) =>
  (map[action.type] && map[action.type](state, action)) ||
  state ||
  defaultState;
