import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
} from 'reactstrap';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import SweetAlert from '../../../../components/main/SweetAlert';
import damageCategoryService from '../../../../services/trailer_damage_category';

const columns = [
  { id: 'id', label: 'Id', minWidth: 70 },
  { id: 'name', label: 'Name', minWidth: 100 },
];

export default function DamageCategoryModal({ modal, toggle, getList }) {
  const [damageCategory, setDamageCategory] = useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [deleteAlert, setDeleteAlert] = useState(false);
  const [deletingId, setDeletingId] = useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getDamageCategory = () => {
    damageCategoryService.getAll('deleted=false').then((data) => {
      setDamageCategory(data);
    });
  };

  const addDamageCategory = () => {
    const data = {
      name: document.querySelector('#damage_category_name').value,
    };
    damageCategoryService.create(data).then((data) => {
      document.querySelector('#damage_category_name').value = '';
      getDamageCategory();
    });
  };

  const deleteDamageCategory = (id) => {
    damageCategoryService.delete(id).then((data) => {
      setDeleteAlert(false);
      toggle();
    });
  };

  useEffect(() => {
    getDamageCategory();
  }, [modal]);

  return (
    <>
      <SweetAlert
        title="Are you sure?"
        warning
        show={deleteAlert}
        showCancel
        reverseButtons
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        onConfirm={() => {
          deleteDamageCategory(deletingId);
        }}
        onCancel={() => setDeleteAlert(false)}
      >
        You won`t be able to revert this!
      </SweetAlert>
      <>
        <Form>
          <FormGroup>
            <Input
              type="text"
              id="damage_category_name"
              placeholder="name"
              onChange={(e) => {
                document.querySelector('#damage_category_name').value =
                  e.target.value.replace(',', '');
              }}
            />
          </FormGroup>
        </Form>
        <Button
          color="success"
          className="d-flex align-items-center"
          type="button"
          onClick={() => {
            addDamageCategory();
          }}
        >
          Add
        </Button>

        <TableContainer sx={{ maxHeight: 240 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell
                  key="actions"
                  align="right"
                  // style={{ minWidth: 70 }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {damageCategory
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={`${i + 1}`}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                      <TableCell key={`${'delete_' + i}`} align="right">
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            setDeletingId(row.id);
                            setDeleteAlert(true);
                            // toggle();
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={damageCategory.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <Button
          color="success"
          className="d-flex align-items-center"
          type="button"
          onClick={() => {
            getList();
            toggle();
          }}
        >
          OK
        </Button>
      </>
    </>
  );
}
