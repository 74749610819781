import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  colors,
  Box,
  Button,
  Card,
  Container,
  TextField,
  FormHelperText,
  Typography,
  Grid,
  InputAdornment,
} from '@mui/material';
import { toast, ToastContainer, Flip } from 'react-toastify';
import jwtDecode from 'jwt-decode';
import { makeStyles, useTheme } from '@mui/styles';
import { useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import KeyIcon from '@mui/icons-material/Key';
import PinIcon from '@mui/icons-material/Pin';
import { inputLabelClasses } from '@mui/material/InputLabel';
import usersApi from '../../../../../../services/users';
import { clearToken } from '../../../../../../redux/modules/auth/actions';

export default function Login({ toggle }) {
  const theme = useTheme();
  const location = useLocation();

  const dispatch = useDispatch();
  const [isInvalid, setIsInvalid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const Ref = useRef(null);

  // The state for our timer
  const [timer, setTimer] = useState('00:00');

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    const { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        (minutes > 9 ? minutes : '0' + minutes) +
          ':' +
          (seconds > 9 ? seconds : '0' + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer('02:00');

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    const deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 120);
    return deadline;
  };

  const schema = Yup.object().shape({
    confirmation_code: Yup.string().max(255).required('Required'),
    password: Yup.string().max(255).required('Required'),
    password2: Yup.string().min(4).max(255).required('Required'),
  });

  const handleLogOut = () => {
    localStorage.clear();
    dispatch(clearToken(localStorage.getItem('current_account')));
    window.open(location.pathname, '_self');
  };

  const formik = useFormik({
    initialValues: {
      confirmation_code: '',
      password: '',
      password2: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (values.password !== values.password2) {
        toast.error('Passwords do not match.', {
          transition: Flip,
        });
      } else {
        const data = {
          code: values.confirmation_code,
          password: values.password,
        };
        usersApi
          .resetPassword(data)
          .then((res) => {
            toast.success('Update password', {
              transition: Flip,
            });
            handleLogOut();
          })
          .catch((err) => {
            console.log(err);
            toast.error(err.response.data.message, {
              transition: Flip,
            });
          });
      }
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  return (
    <>
      <Helmet>
        <title> Account </title>
      </Helmet>
      <Container maxWidth="sm">
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          {isInvalid && (
            <Box sx={{ mb: 4 }} display="flex" justifyContent="center">
              <Typography variant="body1" color="error">
                Invalid confirmation code or password
              </Typography>
            </Box>
          )}
          <Grid container my={2}>
            <Grid item xs={12} md={12} sm={12}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PinIcon />
                    </InputAdornment>
                  ),
                  style: {
                    backgroundColor: theme.palette.background.paper,
                    '&:focus': {
                      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
                    },
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: 'orange',
                    [`&.${inputLabelClasses.shrink}`]: {
                      color: 'red',
                    },
                  },
                }}
                error={Boolean(
                  formik.touched.confirmation_code &&
                    formik.errors.confirmation_code
                )}
                size="small"
                fullWidth
                helperText={
                  formik.touched.confirmation_code &&
                  formik.errors.confirmation_code
                }
                label="Confirmation code"
                name="confirmation_code"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.confirmation_code}
                variant="outlined"
                autoComplete="off"
              />
            </Grid>
          </Grid>
          <Box sx={{ mb: 2 }} display="flex" justifyContent="center">
            <Typography variant="body1">{timer}</Typography>
          </Box>
          <Box sx={{ mb: 2 }} display="flex" justifyContent="center">
            <Typography variant="body1" color="error">
              Password - must contain at least one uppercase and lowercase
              letter, and at least 6 or more characters.
            </Typography>
          </Box>
          <Grid container my={2}>
            <Grid item xs={12} md={12} sm={12}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <KeyIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: {
                    backgroundColor: theme.palette.background.paper,
                    '&:focus': {
                      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
                    },
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: 'orange',
                    [`&.${inputLabelClasses.shrink}`]: {
                      color: 'red',
                    },
                  },
                }}
                error={Boolean(
                  formik.touched.password && formik.errors.password
                )}
                fullWidth
                helperText={formik.touched.password && formik.errors.password}
                size="small"
                label="Password"
                name="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.password}
                variant="outlined"
                autoComplete="off"
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12} sm={12}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <KeyIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: {
                    backgroundColor: theme.palette.background.paper,
                    '&:focus': {
                      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
                    },
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: 'orange',
                    [`&.${inputLabelClasses.shrink}`]: {
                      color: 'red',
                    },
                  },
                }}
                error={Boolean(
                  formik.touched.password2 && formik.errors.password2
                )}
                fullWidth
                helperText={formik.touched.password2 && formik.errors.password2}
                size="small"
                label="Confirmation password"
                name="password2"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.password2}
                variant="outlined"
                autoComplete="off"
              />
            </Grid>
          </Grid>

          <Box sx={{ pt: 2 }}>
            <Button
              // disabled={formik.isSubmitting}
              fullWidth
              size="medium"
              type="submit"
              variant="contained"
              sx={{
                textTransform: 'none',
                fontSize: 20,
                transition: 'all 0.3s ease-in-out',
                backgroundColor: '#2A455F',
                '&:hover': {
                  backgroundColor: '#2A455F',
                  opacity: 0.95,
                },
              }}
            >
              Change
            </Button>
          </Box>
        </form>
      </Container>
    </>
  );
}
