import { service } from '.';

export default {
  getWeeklyGrossDay: () => service.get(`/settings/weekly_gross_day`),
  // getZipFileCompany: (query) =>
  //   service.get(`/settings/getFilesCompany?${query}`),
  // getZipFileTruck: (query) => service.get(`/settings/getFilesTruck?${query}`),
  getSamsaraTokens: () => service.get(`/settings/getSamsaraTokens`),
  createSamsaraToken: (data) =>
    service.post('/settings/samsara_token/save', data),
  updateSamsaraToken: (data) =>
    service.put('/settings/samsara_token/edit', data),
  deleteSamsaraToken: (id) =>
    service.delete(`/settings/samsara_token/delete/${id}`),
  createWeeklyGrossDay: (data) =>
    service.post('/settings/weekly_gross_day/save', data),
  getNotifications: () => service.get(`/settings/getNotifications`),
  updateNotification: (id) => service.put(`/settings/notifications/edit/${id}`),
  createFileZip: (data) => service.post('/settings/files_zip/save', data),
};
