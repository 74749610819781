import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TickIcon from '../../../../../assets/icons/tick.svg';

const ResetSuccessful = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>Successfully</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
          <img
            src={TickIcon}
            alt="Icon"
            style={{
              objectFit: 'contain',
              width: '50px',
            }}
          />
          <Typography
            variant="h3"
            mt={3}
            sx={{
              color: 'text.primary',
              textAlign: 'center',
              //   marginBottom: '2rem',
              fontSize: '20px',
            }}
          >
            Form submitted successfully.
          </Typography>
          {/* <Box sx={{ pt: 5 }}>
            <Button
              fullWidth
              to="/login"
              component={Link}
              size="small"
              variant="contained"
              sx={{
                textTransform: 'none',
                fontSize: '16px',
                transition: 'all 0.3s ease-in-out',
                backgroundColor: '#2A455F',
                '&:hover': {
                  backgroundColor: '#2A455F',
                  opacity: 0.8,
                },
              }}
            >
              {t('go_login')}
            </Button>
          </Box> */}
        </Container>
      </Box>
    </>
  );
};

export default ResetSuccessful;
