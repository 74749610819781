export function dataSelector(dataResponse, page, size) {
  const filtered = dataResponse.content.map((item, i) => ({
    index: i + 1 + page * size,
    id: item.id,
    name: item.name,
    abbreviation: item.abbreviation,
    driverCount: item.driverCount,
    deleted: item.deleted,
    data: item,
  }));
  return { total: dataResponse.totalElements, data: filtered };
}
