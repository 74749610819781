import React, { useContext, useState } from 'react';
import { Card, Grid, TextField, Box, Button } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 200,
    },
  },
};

export default function Search({ getSearchData, handlerUsers }) {
  const [username, setUsername] = useState('');

  const [err, setErr] = useState(false);

  const [list, setList] = useState([
    { label: 'Only chats', value: 'only_chats' },
    { label: 'Accounting', value: 'accounting' },
    { label: 'Dispatchers', value: 'dispatcher' },
    { label: 'Updaters', value: 'updater' },
    { label: 'Drivers', value: 'driver' },
  ]);
  const [user, setUser] = useState();

  return (
    <div style={{ paddingTop: 10 }}>
      <FormControl fullWidth size="small">
        <InputLabel id="demo-simple-select-label">Select</InputLabel>
        <Select
          fullWidth
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={user ? user.value : 'only_chats'}
          label="Select"
          MenuProps={MenuProps}
          onChange={(e) => {
            const { value } = e.target;

            handlerUsers(value);

            list.forEach((el, i) => {
              if (el.value === e.target.value) {
                setUser({
                  value: el.value,
                  label: el.label,
                });
              }
            });
          }}
        >
          {list.map((item, i) => {
            return (
              <MenuItem key={`${i + 1}`} name={item.label} value={item.value}>
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <div style={{ paddingTop: 5 }}>
        <TextField
          fullWidth
          size="small"
          type="text"
          placeholder="Find a user"
          onChange={(e) => {}}
        />
      </div>
      {err && <span>User not found!</span>}
      {/* {user && (
        <div>
          <img src={user.photoURL} alt="" />
          <div className="userChatInfo">
            <span>{user.displayName}</span>
          </div>
        </div>
      )} */}
    </div>
  );
}
