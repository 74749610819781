import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Media,
  Row,
  Col,
  Button,
} from 'reactstrap';
import { toast, Flip } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import '../../../../assets/scss/pages/users.scss';
import Steps, { Step } from 'rc-steps';
import 'rc-steps/assets/index.css';
import '../../../../assets/less/iconfont.css';
import tripService from '../../../../services/trips';

export default function ViewTrip() {
  const [data, setData] = useState({});

  const { id } = useParams();

  const componentDidMount = () => {
    tripService
      .getOne(id)
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  useEffect(() => {
    componentDidMount();
  }, []);

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Trip steps</CardTitle>
        </CardHeader>
        <CardBody>
          <Steps current={0}>
            {data.chronologicalSequence &&
              data.chronologicalSequence.map((item, i) => {
                return (
                  <Step
                    key={`${i + 1}`}
                    title={
                      item.pickupDateFormatted
                        ? item.pickupDateFormatted
                        : item.deliveryDateFormatted
                    }
                    description={item.consigneeNameAndLocation}
                  />
                );
              })}
          </Steps>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>Trip number {id}</CardTitle>
        </CardHeader>
        <CardBody>
          <Row className="mx-0" col="12">
            <Col className="pl-0" sm="12">
              <Media className="d-sm-flex d-block">
                <Media body>
                  <Row>
                    <Col sm="9" md="6" lg="6">
                      <div className="users-page-view-table">
                        <div className="d-flex user-info">
                          <div className="user-info-title font-weight-bold">
                            Customer Name
                          </div>
                          <div>{data.customerName}</div>
                        </div>
                        <div className="d-flex user-info">
                          <div className="user-info-title font-weight-bold">
                            Drivers
                          </div>
                          <div className="text-truncate">
                            <span>
                              <Link to={`/drivers/view/${data.driverId}`}>
                                {data.driverName}
                              </Link>
                              {data.teammateName && (
                                <>
                                  <br />
                                  <Link to={`/drivers/view/${data.teammateId}`}>
                                    {data.teammateName}
                                  </Link>
                                </>
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex user-info">
                          <div className="user-info-title font-weight-bold">
                            Unit Number
                          </div>
                          <div>
                            <Link to={`/units/edit/${data.truckId}`}>
                              {data.unitNumber}
                            </Link>
                          </div>
                        </div>
                        <div className="d-flex user-info">
                          <div className="user-info-title font-weight-bold">
                            Company
                          </div>
                          <div>{data.ownedCompanyName}</div>
                        </div>
                        <div className="d-flex user-info">
                          <div className="user-info-title font-weight-bold">
                            Driver <br /> Instructions
                          </div>
                          <div>{data.driverInstructions}</div>
                        </div>
                      </div>
                    </Col>
                    <Col md="12" lg="6">
                      <div className="users-page-view-table">
                        <div className="d-flex user-info">
                          <div className="user-info-title font-weight-bold">
                            Unit Status
                          </div>
                          <div>{data.unitStatusName}</div>
                        </div>
                        <div className="d-flex user-info">
                          <div className="user-info-title font-weight-bold">
                            Driver Status
                          </div>
                          <div>{data.driverStatusName}</div>
                        </div>
                        <div className="d-flex user-info">
                          <div className="user-info-title font-weight-bold">
                            Accessory Pay
                          </div>
                          <div>
                            <span>{data.accessoryDriverPay}</span>
                          </div>
                        </div>
                        <div className="d-flex user-info">
                          <div className="user-info-title font-weight-bold">
                            From
                          </div>
                          <div>
                            <span>
                              {data.chronologicalSequence &&
                                data.chronologicalSequence[0] &&
                                data.chronologicalSequence[0]
                                  .consigneeNameAndLocation}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex user-info">
                          <div className="user-info-title font-weight-bold">
                            To
                          </div>
                          <div>
                            <span>
                              {data.chronologicalSequence &&
                                data.chronologicalSequence[0] &&
                                data.chronologicalSequence[
                                  data.chronologicalSequence.length - 1
                                ].consigneeNameAndLocation}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Media>
              </Media>
            </Col>
            {/* <Col className="mt-1 pl-0" sm="12">
                <Button.Ripple className="mr-1" color="primary" outline>
                  <Link to={`/driver/edit/${this.props.match.params.id}`}>
                    <Edit size={15} />
                    <span className="align-middle ml-50">Edit</span>
                  </Link>
                </Button.Ripple>
                <Button.Ripple color="danger" outline>
                  <Trash size={15} />
                  <span className="align-middle ml-50">Delete</span>
                </Button.Ripple>
              </Col> */}
          </Row>
          <Link
            to={`/trips/edit/${id}`}
            className="d-flex align-items-center text-white"
          >
            <Button
              color="primary"
              className="d-flex align-items-center"
              type="button"
            >
              Edit trip
            </Button>
          </Link>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>Loads</CardTitle>
        </CardHeader>
        <CardBody>
          {data.loadDtoList &&
            data.loadDtoList.map((item, i) => {
              return (
                <Link key={`${i + 1}`} to={`/loads/edit/${item.id}`}>
                  {item.customLoadNumber} <br />
                </Link>
              );
            })}
        </CardBody>
      </Card>
      <div className="d-flex flex-wrap" style={{ gap: 15 }}>
        {data.chronologicalSequence &&
          data.chronologicalSequence.map((item, i) => {
            if (item.pickupDateFormatted) {
              return (
                <Card key={`${i + 1}`} style={{ width: 'calc(25% - 12px)' }}>
                  <CardHeader>
                    <CardTitle>Pickup</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="mt-1">
                      <h6 className="mb-0">{item.consigneeNameAndLocation}</h6>
                    </div>
                    <div className="mt-1">
                      <h6 className="mb-0">Date:</h6>
                      <p>{item.pickupDateFormatted}</p>
                    </div>
                    <div className="mt-1">
                      <h6 className="mb-0">Driver Instructions:</h6>
                      <p>{item.driverInstructions}</p>
                    </div>
                    <div className="mt-1">
                      <h6 className="mb-0">BOL:</h6>
                      <p>{item.bol}</p>
                    </div>
                    <div className="mt-1">
                      {item.bolId && (
                        <>
                          <h6 className="mb-0">BOL file:</h6>
                          <Button
                            style={{
                              width: 225,
                            }}
                            className="mt-1"
                            type="button"
                            href={`${process.env.REACT_APP_BASE_URL}/file/${item.bolId}`}
                            onclick={() =>
                              window.open(
                                `${process.env.REACT_APP_BASE_URL}/file/${item.bolId}`,
                                '_blank'
                              )
                            }
                          >
                            Download BOL
                          </Button>
                        </>
                      )}
                    </div>
                    <div className="mt-1">
                      <h6 className="mb-0">Customer Required Info:</h6>
                      <p>{item.customRequiredInfo}</p>
                    </div>
                    <div className="mt-1">
                      <h6 className="mb-0">Weight:</h6>
                      <p>{item.weight}</p>
                    </div>
                    <div className="mt-1">
                      <h6 className="mb-0">Quantity:</h6>
                      <p>{item.quantity}</p>
                    </div>
                    <div className="mt-1">
                      <h6 className="mb-0">Notes:</h6>
                      <p>{item.notes}</p>
                    </div>
                    <div className="mt-1">
                      <h6 className="mb-0">Commodity:</h6>
                      <p>{item.commodity}</p>
                    </div>
                  </CardBody>
                </Card>
              );
            }
            return (
              <Card key={`${i + 1}`} style={{ width: 'calc(25% - 12px)' }}>
                <CardHeader>
                  <CardTitle>Delivery</CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="mt-1">
                    <h6 className="mb-0">{item.consigneeNameAndLocation}</h6>
                  </div>
                  <div className="mt-1">
                    <h6 className="mb-0">Date:</h6>
                    <p>{item.deliveryDateFormatted}</p>
                  </div>
                  <div className="mt-1">
                    <h6 className="mb-0">Driver Instructions:</h6>
                    <p>{item.driverInstructions}</p>
                  </div>
                  <div className="mt-1">
                    <h6 className="mb-0">Customer Required Info:</h6>
                    <p>{item.customRequiredInfo}</p>
                  </div>
                  <div className="mt-1">
                    <h6 className="mb-0">Weight:</h6>
                    <p>{item.weight}</p>
                  </div>
                  <div className="mt-1">
                    <h6 className="mb-0">Quantity:</h6>
                    <p>{item.quantity}</p>
                  </div>
                  <div className="mt-1">
                    <h6 className="mb-0">Notes:</h6>
                    <p>{item.notes}</p>
                  </div>
                  <div className="mt-1">
                    <h6 className="mb-0">Commodity:</h6>
                    <p>{item.commodity}</p>
                  </div>
                </CardBody>
              </Card>
            );
          })}
      </div>
    </>
  );
}
