import React, { useState, useEffect } from 'react';
import {
  // Form,
  Form,
  FormGroup,
  Input,
  // Label,
  // Row,
  Col,
  Card,
  CardBody,
  // CardTitle,
  CardHeader,
  // Button
} from 'reactstrap';
import Cropper from 'react-cropper';
import { Box, CardMedia, Button, Modal } from '@mui/material';
import Select from 'react-select';
import { toast, Flip } from 'react-toastify';
import UploadIcon from '../../../../assets/icons/upload.svg';

export default function HandleTask({ taskItem, setterTaskItem, setterPhoto }) {
  const [selfie, setSelfie] = useState();
  const [photo, setPhoto] = useState('');
  const [cropper, setCropper] = useState();
  const [openCrop, setOpenCrop] = useState(false);
  const [image, setImage] = useState('');

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    alignItems: 'center',
    justifyContent: 'right',
    borderRadius: 2,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const imgSelectHandler = (e) => {
    if (e.target.files.length !== 0) {
      setImage(URL.createObjectURL(e.target.files[0]));
      // setPreviewImg(URL.createObjectURL(e.target.files[0]));
      setPhoto(e.target.files[0]);
      setOpenCrop(!openCrop);
    }
  };

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      setSelfie(cropper.getCroppedCanvas().toDataURL());
      cropper.getCroppedCanvas().toBlob((blob) => {
        setPhoto(
          new File([blob], 'image.png', {
            type: 'image/png',
            lastModified: new Date().getTime(),
          })
        );
        setterPhoto(
          new File([blob], 'image.png', {
            type: 'image/png',
            lastModified: new Date().getTime(),
          })
        );
      });
    }
  };

  const handleCropModal = () => {
    setOpenCrop(!openCrop);
  };

  useEffect(() => {}, []);

  return (
    <>
      <Modal hideBackdrop open={openCrop} onClose={handleCropModal}>
        <Box sx={{ ...style }}>
          <h2 id="child-modal-title">Edit image</h2>
          <div style={{ width: '100%' }}>
            <Cropper
              style={{ height: 400, width: '100%' }}
              zoomTo={0}
              initialAspectRatio={1}
              preview=".img-preview"
              src={image}
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
              guides={true}
            />
          </div>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 2,
              py: 2,
            }}
          >
            <Button
              sx={{
                backgroundColor: '#CFE0F0',
                color: '#161616',
                textTransform: 'none',
              }}
              variant="contained"
              onClick={() => handleCropModal()}
            >
              Cancel
            </Button>
            <Button
              sx={{
                backgroundColor: '#2A455F',
                // color: colors.white,
                textTransform: 'none',
              }}
              name="add"
              variant="contained"
              type="submit"
              color="primary"
              // startIcon={
              //   isSubmitting ? <CircularProgress size="0.9rem" /> : undefined
              // }
              onClick={() => {
                getCropData();
                handleCropModal();
              }}
            >
              Ready
            </Button>
          </Box>
        </Box>
      </Modal>
      <Card>
        <CardHeader>
          <h3 className="mb-0">Adding a new task</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <div>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Photo</span>
                </Col>
                <Col md="8">
                  <Box
                    sx={{
                      py: 2,
                    }}
                  >
                    <CardMedia
                      component="img"
                      style={{
                        width: 210,
                        height: 210,
                        borderRadius: 5,
                        objectFit: 'fill',
                      }}
                      image={selfie || UploadIcon}
                      alt=""
                    />
                    <input
                      id="selectFile"
                      type="file"
                      accept=".png,.jpg"
                      style={{ display: 'none' }}
                      onChange={imgSelectHandler.bind(this)}
                    />
                    <Box
                      sx={{
                        py: 0.5,
                      }}
                    />
                    <Button
                      // type="file"
                      fullWidth
                      size="small"
                      variant="contained"
                      sx={{
                        width: 210,
                        textTransform: 'none',
                        fontSize: 20,
                        transition: 'all 0.3s ease-in-out',
                        backgroundColor: '#2A455F',
                        '&:hover': {
                          backgroundColor: '#2A455F',
                          opacity: 0.8,
                        },
                      }}
                      color="error"
                      onClick={() => {
                        document.getElementById('selectFile').click();
                      }}
                    >
                      Select
                    </Button>
                  </Box>
                </Col>
              </FormGroup>

              <FormGroup>
                <Input
                  type="textarea"
                  id="notes"
                  placeholder="Notes"
                  style={{ minHeight: 100 }}
                  onChange={(e) => {
                    // eslint-disable-next-line no-param-reassign
                    taskItem.task.text = e.target.value;
                    console.log(taskItem);
                    setterTaskItem(taskItem);
                    console.log(e.target.value);
                  }}
                />
              </FormGroup>
            </div>
          </Form>
        </CardBody>
      </Card>
    </>
  );
}
