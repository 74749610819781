import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Card,
  CardTitle,
  CardBody,
  Media,
  Row,
  Col,
  Button,
  CustomInput,
} from 'reactstrap';
import * as Icon from 'react-feather';
import { Edit } from 'react-feather';
import jwtDecode from 'jwt-decode';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import {
  MenuItem,
  Divider,
  Select,
  TextField,
  CardHeader,
  Grid,
  Box,
} from '@mui/material';
import { toast, Flip } from 'react-toastify';
import '../../../assets/scss/pages/users.scss';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import settingService from '../../../services/settings';
import unitService from '../../../services/units';
import companyService from '../../../services/owned_company';
import SamsaraTokenModal from './Modals/SamsaraTokens';
import SweetAlert from '../../../components/main/SweetAlert';
import ModalWindow from '../../../components/main/Modals';
import Loading from '../../../components/main/Loading';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

export default function Settings() {
  const weekdays = [
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
    'SUNDAY',
  ];
  const [selectStartGrossDay, setSelectStartGrossDay] = useState('SATURDAY');
  const [selectEndGrossDay, setSelectEndGrossDay] = useState('FRIDAY');
  const [loading, setLoading] = useState(true);
  const [truckInfo, setTruckInfo] = useState(null);
  const [trucks, setTrucks] = useState([]);
  const [truckId, setTruckId] = useState(null);
  const [tokenId, setTokenId] = useState(null);
  const [tokenName, setTokenName] = useState('');

  const [tokenModal, setTokenModal] = useState(false);
  const [samsaraTokens, setSamsaraTokens] = useState([]);

  const [companies, setCompanies] = useState([]);
  const [companyId, setCompanyId] = useState('');

  const [deleteAlertToken, setDeleteAlertToken] = useState(false);

  const token = localStorage.getItem(
    localStorage.getItem('current_account') + '_access_token'
  );
  const decodedToken = jwtDecode(token.replace('Bearer ', ''));

  const componentDidMount = () => {
    setLoading(false);
    settingService
      .getWeeklyGrossDay()
      .then((data) => {
        setSelectStartGrossDay(data.startDay);
        setSelectEndGrossDay(data.endDay);
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          toast.error(err.response.data.message, {
            transition: Flip,
          });
        }
      });
  };

  const saveWeeklyGross = () => {
    setLoading(false);
    const data = {
      startDay: selectStartGrossDay,
      endDay: selectEndGrossDay,
    };
    settingService
      .createWeeklyGrossDay(data)
      .then((data) => {
        toast.success('Saved', {
          transition: Flip,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          toast.error(err.response.data.message, {
            transition: Flip,
          });
        }
      });
  };

  // const getZipFileCompany = () => {
  //   setLoading(false);
  //   settingService
  //     .getZipFileCompany(`${'companyId=' + companyId}`)
  //     .then((result) => {
  //       if (result.status === 'success') {
  //         window.open(result.data.replace('//app', '/app'), '_blank');
  //       } else {
  //         toast.error(result.msg, {
  //           transition: Flip,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       if (err.response) {
  //         toast.error(err.response.data.message, {
  //           transition: Flip,
  //         });
  //       }
  //     });
  // };

  const loadOptions = (inputValue, callback) => {
    let dispatcher = null;
    const userRole = '';
    if (userRole === 'dispatcher') {
      const token = localStorage.getItem(
        localStorage.getItem('current_account') + '_access_token'
      );
      if (token) {
        const decodedToken = jwtDecode(token.replace('Bearer ', ''));
        dispatcher = decodedToken.userId;
      }
    }

    unitService
      .getSearch(
        `${inputValue}${dispatcher ? `&dispatcherId=${dispatcher}` : ''}`
      )
      .then((data) =>
        callback(
          data.map((el) => {
            return {
              ...el,
              value: el.id,
              label: el.number,
            };
          })
        )
      )
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const getCompanies = () => {
    companyService
      .getAllCompany('deleted=false')
      .then((data) => {
        setCompanies(data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const getSamsaraTokens = () => {
    setLoading(true);
    settingService
      .getSamsaraTokens()
      .then((result) => {
        setLoading(false);
        setSamsaraTokens(result);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        if (err.response) {
          toast.error(err.response.data.message, {
            transition: Flip,
          });
        }
      });
  };

  const addSamsaraToken = (text) => {
    const data = {
      token: text,
    };
    settingService.createSamsaraToken(data).then((res) => {
      if (res) {
        toast.success('Token successfuly added', {
          transition: Flip,
        });
        getSamsaraTokens();
      } else {
        toast.error('Something went wrong', { transition: Flip });
        // res.text();
      }
    });
  };

  const editSamsaraToken = (text) => {
    const data = {
      id: tokenId,
      token: text,
    };
    settingService.updateSamsaraToken(data).then((res) => {
      if (res) {
        toast.success('Token successfuly edited', {
          transition: Flip,
        });
        getSamsaraTokens();
      } else {
        toast.error('Something went wrong', { transition: Flip });
        // res.text();
      }
    });
  };

  const deleteSamsaraToken = (id) => {
    settingService.deleteSamsaraToken(id).then((res) => {
      if (res) {
        toast.success('Token successfuly deleted', {
          transition: Flip,
        });
        setDeleteAlertToken(false);
        getSamsaraTokens();
      } else {
        toast.error('Something went wrong', { transition: Flip });
        // res.text();
      }
    });
  };

  const addFilesZipTruck = () => {
    const data = {
      truckId,
    };
    settingService.createFileZip(data).then((res) => {
      if (res) {
        toast.success('Notifications added', {
          transition: Flip,
        });
      } else {
        toast.error('Something went wrong', { transition: Flip });
        // res.text();
      }
    });
  };

  const addFilesZipCompany = () => {
    const data = {
      companyId,
    };
    settingService.createFileZip(data).then((res) => {
      if (res) {
        toast.success('Notifications added', {
          transition: Flip,
        });
      } else {
        toast.error('Something went wrong', { transition: Flip });
        // res.text();
      }
    });
  };

  const toggleTokenModal = () => {
    setTokenModal(!tokenModal);
  };

  useEffect(() => {
    setLoading(false);
    componentDidMount();
    getCompanies();
    getSamsaraTokens();
  }, []);

  return (
    <>
      <Helmet>
        <title> Settings </title>
      </Helmet>
      <SweetAlert
        title="Are you sure?"
        warning
        show={deleteAlertToken}
        showCancel
        reverseButtons
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes"
        cancelBtnText="Cancel"
        onConfirm={() => {
          deleteSamsaraToken(tokenId);
        }}
        onCancel={() => setDeleteAlertToken(false)}
      >
        {}
      </SweetAlert>
      <ModalWindow
        title="Token modal"
        modal={tokenModal}
        handleModal={toggleTokenModal}
        maxWidth="sm"
        body={
          <SamsaraTokenModal
            modal={tokenModal}
            toggle={toggleTokenModal}
            addSamsaraToken={addSamsaraToken}
            editSamsaraToken={editSamsaraToken}
            token={tokenName}
          />
        }
      />
      <Loading loading={loading} />

      <Box
        sx={{
          backgroundColor: '#000',
          height: 'calc(100vh - 6.5rem)',
          py: 1,
        }}
      >
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Card style={{ marginRight: 8 }}>
              <CardHeader
                titleTypographyProps={{ variant: 'h4' }}
                title="Get load files"
              />
              <CardBody>
                <div className="users-page-view-table">
                  <FormControl
                    fullWidth
                    sx={{ paddingBottom: '5px' }}
                    size="small"
                  >
                    <InputLabel id="demo-simple-select-label-company">
                      Companies
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label-company"
                      id="demo-simple-select-company"
                      value={companyId}
                      label="Companies"
                      MenuProps={MenuProps}
                      onChange={(e) => {
                        const { value } = e.target;
                        setCompanyId(value);
                      }}
                    >
                      {companies.map((item, i) => {
                        return (
                          <MenuItem key={`${i + 1}`} value={item.id}>
                            {item.abbreviation}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <div style={{ paddingBottom: '10px' }}>
                    <Button
                      disabled={companyId === ''}
                      size="sm"
                      color="warning"
                      className="d-flex align-items-center"
                      type="button"
                      onClick={() => {
                        addFilesZipCompany();
                      }}
                    >
                      <Icon.Download size={20} /> &nbsp; All files download
                    </Button>
                  </div>
                  <Divider />
                  <div style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                    <AsyncSelect
                      noOptionsMessage={(value) =>
                        !value.inputValue
                          ? 'type something to search'
                          : 'nothing to show'
                      }
                      placeholder="Search truck"
                      isClearable={true}
                      defaultValue={truckInfo}
                      defaultOptions={trucks}
                      loadOptions={loadOptions}
                      onChange={(value) => {
                        if (value !== null) {
                          setTruckId(value.value);
                          setTruckInfo(value);
                        } else {
                          setTruckId(null);
                          setTruckInfo(null);
                        }
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary50: '#fe810b',
                          primary25: 'rgb(253, 179, 46)',
                          primary: 'rgb(253, 179, 46)',
                        },
                      })}
                    />
                  </div>
                  <div>
                    <Button
                      disabled={truckId === null}
                      size="sm"
                      color="warning"
                      className="d-flex align-items-center"
                      type="button"
                      onClick={() => {
                        addFilesZipTruck();
                      }}
                    >
                      <Icon.Download size={20} /> &nbsp; Download
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Grid>
          <Grid item md={6} xs={12}>
            <Card style={{ marginRight: 8 }}>
              <CardHeader
                titleTypographyProps={{ variant: 'h4' }}
                title="Weekly gross days"
              />

              <CardBody>
                <Row>
                  <Col sm="6" md="6">
                    <div className="users-page-view-table mb-2">
                      <FormControl sx={{ width: 200 }} size="small">
                        <InputLabel id="demo-simple-select-label">
                          Start
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select1"
                          value={selectStartGrossDay}
                          label="Start"
                          MenuProps={MenuProps}
                          onChange={(e) => {
                            const { value } = e.target;
                            // const index = weekdays.indexOf(value);
                            // console.log(index);
                            setSelectStartGrossDay(value);
                            if (value === 'MONDAY') {
                              setSelectEndGrossDay('SUNDAY');
                            }
                            if (value === 'TUESDAY') {
                              setSelectEndGrossDay('MONDAY');
                            }
                            if (value === 'WEDNESDAY') {
                              setSelectEndGrossDay('TUESDAY');
                            }
                            if (value === 'THURSDAY') {
                              setSelectEndGrossDay('WEDNESDAY');
                            }
                            if (value === 'FRIDAY') {
                              setSelectEndGrossDay('THURSDAY');
                            }
                            if (value === 'SATURDAY') {
                              setSelectEndGrossDay('FRIDAY');
                            }
                            if (value === 'SUNDAY') {
                              setSelectEndGrossDay('SATURDAY');
                            }
                          }}
                        >
                          {weekdays.map((item, i) => {
                            return (
                              <MenuItem key={`${i + 1}`} value={item}>
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </Col>

                  <Col md="6" sm="6">
                    <div className="users-page-view-table mb-2">
                      <FormControl sx={{ width: 200 }} size="small">
                        <InputLabel id="demo-simple-select-label-end">
                          End
                        </InputLabel>
                        <Select
                          disabled={true}
                          labelId="demo-simple-select-label-end"
                          id="demo-simple-select2"
                          value={selectEndGrossDay}
                          label="End"
                          MenuProps={MenuProps}
                          onChange={(e) => {
                            const { value } = e.target;
                            if (selectStartGrossDay !== value) {
                              setSelectEndGrossDay(value);
                            } else {
                              toast.info('Please, choose another day', {
                                transition: Flip,
                              });
                            }
                          }}
                        >
                          {weekdays.map((item, i) => {
                            return (
                              <MenuItem key={`${i + 1}`} value={item}>
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </Col>
                </Row>
                <Row className="mx-0" col="12">
                  <Button
                    size="sm"
                    color="warning"
                    className="d-flex align-items-center"
                    type="button"
                    onClick={() => {
                      saveWeeklyGross();
                    }}
                  >
                    <Icon.Save size={20} /> &nbsp; Save
                  </Button>
                </Row>
              </CardBody>
            </Card>
          </Grid>

          <Grid item md={6} xs={12}>
            <Card style={{ marginRight: 8 }}>
              <div className="mr-1">
                <CardHeader
                  titleTypographyProps={{ variant: 'h4' }}
                  action={
                    <Button
                      size="sm"
                      color="success"
                      className="d-flex align-items-center"
                      type="button"
                      onClick={() => {
                        setTokenId(null);
                        setTokenName('');
                        toggleTokenModal();
                      }}
                    >
                      <Icon.PlusCircle size={20} /> New
                    </Button>
                  }
                  title="Samsara tokens"
                />
              </div>
              <CardBody>
                {samsaraTokens.map((item, i) => {
                  return (
                    <div key={`${i + 1}`} className="d-flex mb-1 mx-0">
                      <h2>{i + 1}. </h2> &nbsp;
                      <TextField
                        disabled
                        fullWidth
                        size="small"
                        id="tokenData"
                        label="Token"
                        variant="outlined"
                        value={item.token}
                        onChange={(e) => {}}
                      />
                      &nbsp;
                      <Button
                        size="sm"
                        color="primary"
                        className="d-flex align-items-center"
                        type="button"
                        onClick={() => {
                          setTokenId(item.id);
                          setTokenName(item.token);
                          toggleTokenModal();
                        }}
                      >
                        <Icon.Edit size={20} />
                      </Button>
                      &nbsp;
                      <Button
                        size="sm"
                        color="danger"
                        className="d-flex align-items-center"
                        type="button"
                        onClick={() => {
                          setTokenId(item.id);
                          setDeleteAlertToken(true);
                        }}
                      >
                        <Icon.Delete size={20} />
                      </Button>
                    </div>
                  );
                })}
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
