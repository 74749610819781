import React from 'react';
import Chip from '@mui/material/Chip';

export default ({ value }) => {
  return (
    <Chip
      size="small"
      style={{
        fontSize: '13px',
        color: value.driverStatusColor ? 'white' : 'black',
        backgroundColor: value.driverStatusColor
          ? value.driverStatusColor
          : 'white',
        textAlign: 'center',
        textTransform: 'uppercase',
      }}
      label={value.driverStatus}
      variant="outlined"
    />
  );
};
