import React, { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { Box } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import userService from '../../../../../services/users';
import Loading from '../../../../../components/main/Loading';

export default function TableHeaders({
  isChecked,
  checkedAll,
  handleChange1,
  headers,
  isCheckboxChecked,
}) {
  useEffect(() => {}, []);

  return (
    <>
      <div>
        <FormControlLabel
          label="All"
          control={
            <Checkbox
              checked={!checkedAll}
              //   indeterminate={(isChecked[0] !== isChecked[1]) !== isChecked[2]}
              onChange={handleChange1}
            />
          }
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
          <FormGroup>
            {headers.map((item, i) => (
              <FormControlLabel
                key={`${i + 1}`}
                label={item.Header}
                control={
                  <Checkbox
                    checked={isChecked[i]}
                    onChange={(e) => isCheckboxChecked(i, e.target.checked)}
                    name={`${i}`}
                  />
                }
              />
            ))}
          </FormGroup>
        </Box>
      </div>
    </>
  );
}
