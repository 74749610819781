import React, { useState, useEffect } from 'react';
import {
  // Form,
  Form,
  Button,
  FormGroup,
  Input,
  CustomInput,
  Col,
  Card,
  CardBody,
  CardHeader,
  // Button
} from 'reactstrap';
import * as Icon from 'react-feather';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { toast, Flip } from 'react-toastify';
import ImageUploader from 'react-images-upload';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import InfiniteFileUploader from '../../../../components/main/infiniteFileUploader';
import companyService from '../../../../services/companies';
import ownedCompanyService from '../../../../services/owned_company';
import fileUploadService from '../../../../services/file_upload';

export default function EditCompany() {
  const [loading, setLoading] = useState(true);
  const [logoFromPast, setLogoFromPast] = useState(false);
  const [states, setStates] = useState([]);
  const [customerTypes, setCustomerTypes] = useState([]);
  const [logoFileId, setLogoFileId] = useState(null);
  const [newFiles, setNewFiles] = useState([]);
  const [prevFiles, setPrevFiles] = useState([]);
  const [fileIds, setFileIds] = useState(new Map());
  const [currentFile, setCurrentFile] = useState(0);

  const { id } = useParams();

  const newFile = () => {
    const newFilesData = newFiles;
    const currentFileData = currentFile;
    newFilesData.push(currentFileData);
    setNewFiles(newFilesData);
    setCurrentFile(currentFileData + 1);
  };

  const deleteFile = (item) => {
    const newFilesData = newFiles;
    const fileIdsData = fileIds;
    fileIdsData.delete(item);
    setFileIds(fileIdsData);

    const index = newFiles.indexOf(item);
    if (index > -1) {
      newFilesData.splice(index, 1);
    }
    setNewFiles(newFilesData);
  };

  const deletePrevFile = (item) => {
    const prevFilesData = prevFiles;
    const fileIdsData = fileIds;
    fileIdsData.delete(item);
    setFileIds(fileIdsData);

    const obj = prevFilesData.find((el) => el.id === item);
    const index = prevFilesData.indexOf(obj);
    if (index > -1) {
      prevFilesData.splice(index, 1);
    }
    setPrevFiles(prevFilesData);
  };

  const uploadFile = (file, item) => {
    const formData = new FormData();
    formData.append('file', file);
    if (file === undefined) {
      return;
    }
    fileUploadService
      .upload('/file/upload', formData)
      .then((data) => {
        const newFilesData = newFiles;
        const newFile = newFilesData.indexOf(item);
        newFilesData[newFile] = data;
        const fileIdsData = fileIds;
        fileIdsData.set(data, file.name);
        setFileIds(fileIdsData);
        setNewFiles(newFilesData);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const deleteLogo = () => {
    setLogoFileId(null);
  };

  const editCompany = () => {
    const obj = Object.create(null);
    // eslint-disable-next-line no-restricted-syntax
    for (const [k, v] of fileIds) {
      obj[k] = v;
    }

    const data = {
      id,
      files: obj,
      logoFileId,
      abbreviation: document.querySelector('#abbreviation').value,
      alternatePhone: document.querySelector('#alternatePhone').value,
      alternatePhoneExtensionNumber:
        document.querySelector('#alternatePhone').value,
      aptSuiteOther: document.querySelector('#alternatePhone').value,
      city: document.querySelector('#city').value,
      name: document.querySelector('#companyName').value,
      contact: document.querySelector('#contact').value,
      email: document.querySelector('#email').value,
      fax: document.querySelector('#fax').value,
      motorCarrierNumber: document.querySelector('#motorCarrierNumber').value,
      notes: document.querySelector('#notes').value,
      phoneExtensionNumber: document.querySelector('#phoneExtensionNumber')
        .value,
      phoneNumber: document.querySelector('#phoneNumber').value,
      stateProvinceId: parseInt(
        document.querySelector('#stateProvince').value,
        10
      ),
      street: document.querySelector('#street').value,
      taxId: document.querySelector('#taxId').value,
      webSite: document.querySelector('#webSite').value,
      zipCode: document.querySelector('#zipCode').value,
    };

    ownedCompanyService
      .update(data)
      .then((res) => {
        if (res) {
          toast.success('Company successfuly edited', {
            transition: Flip,
          });
          window.history.back();
        } else {
          toast.error('Something went wrong', { transition: Flip });
          // res.text();
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const onDrop = (pictureFiles, pictureDataURLs) => {
    if (pictureFiles.length === 0) {
      setLogoFileId(null);
      return;
    }
    const formData = new FormData();
    formData.append('file', pictureFiles[0], pictureFiles[0].name);

    fileUploadService
      .upload('/file/upload', formData)
      .then((data) => {
        setLogoFileId(data);
        setLogoFromPast(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const componentDidMount = () => {
    companyService
      .getContext()
      .then((data) => {
        setCustomerTypes(data.customer_types);
        setStates(data.state_province);

        ownedCompanyService
          .getOne(id)
          .then((data) => {
            setLogoFileId(data.logoFileId);
            setLogoFromPast(true);
            setLoading(false);

            document.querySelector('#abbreviation').value = data.abbreviation;
            document.querySelector('#alternatePhone').value =
              data.alternatePhone;
            document.querySelector('#alternatePhone').value =
              data.alternatePhoneExtensionNumber;
            document.querySelector('#alternatePhone').value =
              data.alternatePhone;
            document.querySelector('#city').value = data.city;
            document.querySelector('#companyName').value = data.name;
            document.querySelector('#contact').value = data.contact;
            document.querySelector('#email').value = data.email;
            document.querySelector('#fax').value = data.fax;
            document.querySelector('#motorCarrierNumber').value =
              data.motorCarrierNumber;
            document.querySelector('#notes').value = data.notes;
            document.querySelector('#phoneExtensionNumber').value =
              data.phoneExtensionNumber;
            document.querySelector('#phoneNumber').value = data.phoneNumber;
            document.querySelector('#stateProvince').value =
              data.stateProvinceId;
            document.querySelector('#street').value = data.street;
            document.querySelector('#taxId').value = data.taxId;
            document.querySelector('#webSite').value = data.webSite;
            document.querySelector('#zipCode').value = data.zipCode;

            if (data.files !== null && typeof data.files !== 'object') {
              const fileIdsData = new Map();
              const prevFilesData = [];
              // eslint-disable-next-line no-restricted-syntax, guard-for-in
              for (const key in data.files) {
                prevFilesData.push({
                  id: parseInt(key, 10),
                  name: data.files[key],
                });
                fileIdsData.set(parseInt(key, 10), data.files[key]);
              }
              setFileIds(fileIdsData);
              setPrevFiles(prevFilesData);
            }
          })
          .catch((err) => {
            toast.error(err.response.data.message, {
              transition: Flip,
            });
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  useEffect(() => {
    componentDidMount();
  }, []);

  return (
    <>
      <Card>
        <CardHeader>
          <h3 className="mb-0">Edit Company</h3>
        </CardHeader>
        <CardBody>
          {loading ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 44 }} spin />}
              style={{
                height: 'calc(100vh - 20rem)',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            />
          ) : (
            <>
              <div className="d-flex">
                <div style={{ flex: 1, marginRight: 20 }}>
                  <Form>
                    <FormGroup className="align-items-center" row>
                      <Col md="4">
                        <span>Company Name</span>
                        <span style={{ color: 'red' }}> *</span>
                      </Col>
                      <Col md="8">
                        <Input type="text" id="companyName" />
                      </Col>
                    </FormGroup>
                    <FormGroup className="align-items-center" row>
                      <Col md="4">
                        <span>Abbreviation</span>
                        <span style={{ color: 'red' }}> *</span>
                      </Col>
                      <Col md="8">
                        <Input type="text" id="abbreviation" />
                      </Col>
                    </FormGroup>
                    <FormGroup className="align-items-center" row>
                      <Col md="4">
                        <span>Street</span>
                      </Col>
                      <Col md="8">
                        <Input type="text" id="street" />
                      </Col>
                    </FormGroup>
                    <FormGroup className="align-items-center" row>
                      <Col md="4">
                        <span>City</span>
                        <span style={{ color: 'red' }}> *</span>
                      </Col>
                      <Col md="8">
                        <Input type="text" id="city" />
                      </Col>
                    </FormGroup>
                    <FormGroup className="align-items-center" row>
                      <Col md="4">
                        <span>State</span>
                        <span style={{ color: 'red' }}> *</span>
                      </Col>
                      <Col md="8">
                        <CustomInput
                          type="select"
                          name="select"
                          id="stateProvince"
                        >
                          {states.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </CustomInput>
                      </Col>
                    </FormGroup>
                    <FormGroup className="align-items-center" row>
                      <Col md="4">
                        <span>Zip Code</span>
                      </Col>
                      <Col md="8">
                        <Input type="text" id="zipCode" />
                      </Col>
                    </FormGroup>
                    <FormGroup className="align-items-center" row>
                      <Col md="4">
                        <span>Phone Number</span>
                      </Col>
                      <Col md="8">
                        <Input type="text" id="phoneNumber" />
                      </Col>
                    </FormGroup>
                    <FormGroup className="align-items-center" row>
                      <Col md="4">
                        <span>Phone Extension number</span>
                      </Col>
                      <Col md="8">
                        <Input type="text" id="phoneExtensionNumber" />
                      </Col>
                    </FormGroup>
                    <FormGroup className="align-items-center" row>
                      <Col md="4">
                        <span>Alternate Phone</span>
                      </Col>
                      <Col md="8">
                        <Input type="text" id="alternatePhone" />
                      </Col>
                    </FormGroup>
                  </Form>
                </div>
                <div style={{ width: '50%' }}>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Alternate Phone Extension number</span>
                    </Col>
                    <Col md="8">
                      <Input type="text" id="alternatePhoneExtensionNumber" />
                    </Col>
                  </FormGroup>

                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Fax</span>
                    </Col>
                    <Col md="8">
                      <Input type="text" id="fax" />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Email</span>
                    </Col>
                    <Col md="8">
                      <Input type="text" id="email" />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Website</span>
                    </Col>
                    <Col md="8">
                      <Input type="text" id="webSite" />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Contact</span>
                    </Col>
                    <Col md="8">
                      <Input type="text" id="contact" />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Notes</span>
                    </Col>
                    <Col md="8">
                      <Input type="textarea" id="notes" maxLength="1000" />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Motor Carrier Number</span>
                    </Col>
                    <Col md="8">
                      <Input type="text" id="motorCarrierNumber" />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Tax ID (EIN#)</span>
                    </Col>
                    <Col md="8">
                      <Input type="text" id="taxId" />
                    </Col>
                  </FormGroup>
                </div>
              </div>
            </>
          )}
        </CardBody>
      </Card>

      <InfiniteFileUploader
        newFiles={newFiles}
        prevFiles={prevFiles}
        deletePrevFile={deletePrevFile}
        newFile={newFile}
        deleteFile={deleteFile}
        uploadFile={uploadFile}
      />

      {logoFileId && logoFromPast ? (
        <Card
          className="d-flex align-items-center justify-content-center"
          style={{ flexDirection: 'initial', paddingBottom: 15 }}
        >
          <Button
            style={{ width: 225 }}
            className="mt-1"
            type="button"
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_BASE_URL}/file/${logoFileId}`,
                '_blank'
              )
            }
          >
            Download Logo
          </Button>
          <Button
            className="btn-icon mt-1"
            color="red"
            type="button"
            onClick={() => deleteLogo()}
          >
            <Icon.Trash2 />
          </Button>
        </Card>
      ) : (
        <ImageUploader
          withIcon={true}
          withPreview={true}
          buttonText="Choose image"
          onChange={onDrop}
          imgExtension={['.jpg', '.png', '.svg']}
          maxFileSize={5242880}
          singleImage
          label="Upload Logo"
        />
      )}
      <div className="d-flex justify-content-center mt-2">
        <Button
          color="success"
          className="d-flex align-items-center"
          type="button"
          onClick={() => editCompany()}
        >
          <Icon.Check size={22} /> Save
        </Button>
      </div>
    </>
  );
}

// const mapStateToProps = (state) => {
//   return {
//     token: state.auth.login.token,
//   };
// };
// export default connect(mapStateToProps)(NewCompany);
