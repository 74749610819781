import React, { useState, useEffect, useMemo } from 'react';
import {
  Col,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Button,
  Input,
  Row,
  Form,
  FormGroup,
  Label,
  CardHeader,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import jwtDecode from 'jwt-decode';
import { LoadingOutlined } from '@ant-design/icons';
import * as Icon from 'react-feather';
import {
  // Button,
  Card,
  IconButton,
  Box,
  TextField,
} from '@mui/material';
import FileSaver from 'file-saver';
import { toast, Flip } from 'react-toastify';
import { AnimatePresence } from 'framer-motion';
import FilterListIcon from '@mui/icons-material/FilterList';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import SweetAlert from '../../../../components/main/SweetAlert';
import { fetchData } from '../../../../redux/modules/trailer_general/actions';
import { useQuery } from '../../useQuery';
import FilterTools from '../../../../components/main/FilteredBody/Trailers';
import DownloadFilterTools from '../../../../components/main/FilteredBody/TrailersDownload';
import AnimationDiv from '../../../../components/main/Animations/Transition';
import Loading from '../../../../components/main/Loading';
import Table from '../../../../components/main/table';
import trailerGeneralService from '../../../../services/trailer_general';
import { trailerGeneralHeader } from '../../../../redux/constants/table/headers';
import { headerMaker } from '../../../../components/main/table/helper';
import { toolTipsUser, toolTipsAdmin } from './helper';
import { useStyles } from './style';

export default function TrailerGeneral() {
  const {
    query,
    search,
    deleted,
    pageIndex,
    pageSize,
    trailerStatus,
    setPageIndex,
    setPageSize,
    setSearch,
    setDeleted,
    setRefresh,
    setTrailerStatus,
    handleOnTableChange,
  } = useQuery({ fetchData });

  const headers = useMemo(
    () => headerMaker(trailerGeneralHeader),
    [trailerGeneralHeader]
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const classes = useStyles();

  const [isFilterShow, setFilterIsShow] = useState(false);
  const [isDownloadShow, setDownloadShow] = useState(false);

  const [active, setActive] = useState();

  const { data, loading, total } = useSelector(
    (state) => state.trailerGeneralReducer
  );
  localStorage.setItem('total_data', total);

  const [generalData, setGeneralData] = useState([]);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [trailerId, setTrailerId] = useState();

  const token = localStorage.getItem(
    localStorage.getItem('current_account') + '_access_token'
  );
  const decodedToken = jwtDecode(token);

  const createHeaders = (headerData) => {
    const cData = headerData.map((item) => ({
      item,
      // ref: useRef(),
    }));
    // console.log(cData);

    // setColumns(cData);

    return cData;
  };

  const [columns, setColumns] = useState(createHeaders(headers));

  const [modal, setModal] = useState(false);

  const [tabs, setTabs] = useState([
    { id: 1, name: 'Trailers' },
    { id: 2, name: 'Repairs' },
    { id: 3, name: 'Idles' },
    { id: 4, name: 'History' },
  ]);

  const tabList = [
    '/trailers/list',
    '/trailer_repairs/list',
    '/trailer_idles/list',
    '/trailer_history/list',
  ];

  // const generalColumnDefs = [
  //   {
  //     headerName: '#',
  //     // field: "index",
  //     minWidth: 70,
  //     filter: false,
  //     flex: 1,
  //     cellStyle: function (params) {
  //       return {
  //         fontWeight: '500',
  //       };
  //     },
  //     cellRendererFramework: (params) => {
  //       return params.rowIndex + 1;
  //     },
  //   },
  //   {
  //     headerName: 'Trailer number',
  //     field: 'trailerNumber',
  //     minWidth: 180,
  //     pivot: true,
  //     enablePivot: true,
  //     filter: 'agTextColumnFilter',
  //     // flex: 1,
  //   },
  //   {
  //     headerName: 'Type',
  //     field: 'type',
  //     maxWidth: 100,
  //     filter: false,
  //     // flex: 1,
  //   },
  //   {
  //     headerName: 'Make',
  //     field: 'make',
  //     minWidth: 150,
  //     filter: 'agTextColumnFilter',
  //     // flex: 1,
  //   },
  //   {
  //     headerName: 'Make year',
  //     field: 'makeYear',
  //     minWidth: 150,
  //     filter: 'agTextColumnFilter',
  //     // flex: 1,
  //   },
  //   {
  //     headerName: 'Model',
  //     field: 'model',
  //     filter: 'agTextColumnFilter',
  //     minWidth: 150,
  //     // flex: 1,
  //   },
  //   {
  //     headerName: 'VIN',
  //     field: 'vin',
  //     minWidth: 190,
  //     filter: 'agTextColumnFilter',
  //     // flex: 1,
  //   },
  //   // {
  //   //     headerName: "Status",
  //   //     field: "status",
  //   //     width: 120,
  //   //     flex: 1,
  //   //     cellStyle: function (params) {
  //   //         return {
  //   //             fontSize: "13px",
  //   //             color: params.data.unitStatusColor ? "white" : "black",
  //   //             backgroundColor: params.data.unitStatusColor
  //   //                 ? params.data.unitStatusColor
  //   //                 : "white",
  //   //             textTransform: "uppercase",
  //   //             textAlign: "center",
  //   //         };
  //   //     },
  //   // },
  //   {
  //     headerName: 'Title number',
  //     field: 'titleNumber',
  //     minWidth: 100,
  //     filter: 'agTextColumnFilter',
  //     // flex: 1,
  //     cellStyle: function (params) {
  //       return { textAlign: 'center' };
  //       // if (params.value == "TEAM" || params.value == "OWNER TEAM") {
  //       //   return { color: "white", backgroundColor: "#606060" };
  //       // } else {
  //       //   return { color: "white", backgroundColor: "#808080" };
  //       // }
  //     },
  //   },
  //   {
  //     headerName: 'License plate',
  //     field: 'licensePlate',
  //     minWidth: 100,
  //     filter: false,
  //     // flex: 1,
  //   },
  //   {
  //     headerName: 'Purchase date/price',
  //     field: 'purchaseDate',
  //     minWidth: 100,
  //     filter: false,
  //     // flex: 1,
  //     cellRendererFramework: (params) => {
  //       return (
  //         <div>
  //           {params.data && params.data.purchaseDate && (
  //             <p>
  //               {new Date(params.data.purchaseDate).getMonth() + 1 < 10
  //                 ? '0' +
  //                   parseInt(new Date(params.data.purchaseDate).getMonth() + 1)
  //                 : new Date(params.data.purchaseDate).getMonth() + 1}
  //               -
  //               {new Date(params.data.purchaseDate).getDate() < 10
  //                 ? '0' + new Date(params.data.purchaseDate).getDate()
  //                 : new Date(params.data.purchaseDate).getDate()}
  //               -{new Date(params.data.purchaseDate).getFullYear()}{' '}
  //               {new Date(params.data.purchaseDate).getHours() < 10
  //                 ? '0' + new Date(params.data.purchaseDate).getHours()
  //                 : new Date(params.data.purchaseDate).getHours()}
  //               :
  //               {new Date(params.data.purchaseDate).getMinutes() < 10
  //                 ? '0' + new Date(params.data.purchaseDate).getMinutes()
  //                 : new Date(params.data.purchaseDate).getMinutes()}
  //             </p>
  //           )}
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     headerName: 'Sale date/price',
  //     field: 'saleDate',
  //     minWidth: 100,
  //     filter: false,
  //     // flex: 1,
  //     cellRendererFramework: (params) => {
  //       return (
  //         <div>
  //           {params.data && params.data.saleDate && (
  //             <p>
  //               {new Date(params.data.saleDate).getMonth() + 1 < 10
  //                 ? '0' +
  //                   parseInt(new Date(params.data.saleDate).getMonth() + 1)
  //                 : new Date(params.data.saleDate).getMonth() + 1}
  //               -
  //               {new Date(params.data.saleDate).getDate() < 10
  //                 ? '0' + new Date(params.data.saleDate).getDate()
  //                 : new Date(params.data.saleDate).getDate()}
  //               -{new Date(params.data.saleDate).getFullYear()}{' '}
  //               {new Date(params.data.saleDate).getHours() < 10
  //                 ? '0' + new Date(params.data.saleDate).getHours()
  //                 : new Date(params.data.saleDate).getHours()}
  //               :
  //               {new Date(params.data.saleDate).getMinutes() < 10
  //                 ? '0' + new Date(params.data.saleDate).getMinutes()
  //                 : new Date(params.data.saleDate).getMinutes()}
  //             </p>
  //           )}
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     headerName: 'Financed by',
  //     field: 'financedBy',
  //     minWidth: 100,
  //     filter: 'agTextColumnFilter',
  //     // flex: 1,
  //   },
  //   {
  //     headerName: 'Leasing company',
  //     field: 'leasingCompany',
  //     filter: 'agTextColumnFilter',
  //     minWidth: 100,
  //     // flex: 1,
  //   },
  //   {
  //     headerName: 'Lease reference',
  //     field: 'leaseReference',
  //     filter: 'agTextColumnFilter',
  //     minWidth: 100,
  //     // flex: 1,
  //   },
  //   {
  //     headerName: 'In service on',
  //     field: 'inServiceOn',
  //     minWidth: 100,
  //     filter: 'agTextColumnFilter',
  //     // flex: 1,
  //     cellRendererFramework: (params) => {
  //       return (
  //         <div>
  //           {params.data && params.data.inServiceOn && (
  //             <p>
  //               {new Date(params.data.inServiceOn).getMonth() + 1 < 10
  //                 ? '0' +
  //                   parseInt(new Date(params.data.inServiceOn).getMonth() + 1)
  //                 : new Date(params.data.inServiceOn).getMonth() + 1}
  //               -
  //               {new Date(params.data.inServiceOn).getDate() < 10
  //                 ? '0' + new Date(params.data.inServiceOn).getDate()
  //                 : new Date(params.data.inServiceOn).getDate()}
  //               -{new Date(params.data.inServiceOn).getFullYear()}{' '}
  //               {new Date(params.data.inServiceOn).getHours() < 10
  //                 ? '0' + new Date(params.data.inServiceOn).getHours()
  //                 : new Date(params.data.inServiceOn).getHours()}
  //               :
  //               {new Date(params.data.inServiceOn).getMinutes() < 10
  //                 ? '0' + new Date(params.data.inServiceOn).getMinutes()
  //                 : new Date(params.data.inServiceOn).getMinutes()}
  //             </p>
  //           )}
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     headerName: 'Tags expire on',
  //     field: 'tagsExpireOn',
  //     minWidth: 100,
  //     filter: false,
  //     // flex: 1,
  //     cellRendererFramework: (params) => {
  //       return (
  //         <div>
  //           {params.data && params.data.tagsExpireOn && (
  //             <p>
  //               {new Date(params.data.tagsExpireOn).getMonth() + 1 < 10
  //                 ? '0' +
  //                   parseInt(new Date(params.data.tagsExpireOn).getMonth() + 1)
  //                 : new Date(params.data.tagsExpireOn).getMonth() + 1}
  //               -
  //               {new Date(params.data.tagsExpireOn).getDate() < 10
  //                 ? '0' + new Date(params.data.tagsExpireOn).getDate()
  //                 : new Date(params.data.tagsExpireOn).getDate()}
  //               -{new Date(params.data.tagsExpireOn).getFullYear()}{' '}
  //               {new Date(params.data.tagsExpireOn).getHours() < 10
  //                 ? '0' + new Date(params.data.tagsExpireOn).getHours()
  //                 : new Date(params.data.tagsExpireOn).getHours()}
  //               :
  //               {new Date(params.data.tagsExpireOn).getMinutes() < 10
  //                 ? '0' + new Date(params.data.tagsExpireOn).getMinutes()
  //                 : new Date(params.data.tagsExpireOn).getMinutes()}
  //             </p>
  //           )}
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     headerName: 'Next inspection on',
  //     field: 'nextInspectionOn',
  //     minWidth: 100,
  //     filter: false,
  //     // flex: 1,
  //     cellRendererFramework: (params) => {
  //       return (
  //         <div>
  //           {params.data && params.data.nextInspectionOn && (
  //             <p>
  //               {new Date(params.data.nextInspectionOn).getMonth() + 1 < 10
  //                 ? '0' +
  //                   parseInt(
  //                     new Date(params.data.nextInspectionOn).getMonth() + 1
  //                   )
  //                 : new Date(params.data.nextInspectionOn).getMonth() + 1}
  //               -
  //               {new Date(params.data.nextInspectionOn).getDate() < 10
  //                 ? '0' + new Date(params.data.nextInspectionOn).getDate()
  //                 : new Date(params.data.nextInspectionOn).getDate()}
  //               -{new Date(params.data.nextInspectionOn).getFullYear()}{' '}
  //               {new Date(params.data.nextInspectionOn).getHours() < 10
  //                 ? '0' + new Date(params.data.nextInspectionOn).getHours()
  //                 : new Date(params.data.nextInspectionOn).getHours()}
  //               :
  //               {new Date(params.data.nextInspectionOn).getMinutes() < 10
  //                 ? '0' + new Date(params.data.nextInspectionOn).getMinutes()
  //                 : new Date(params.data.nextInspectionOn).getMinutes()}
  //             </p>
  //           )}
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     headerName: 'Days Left',
  //     field: 'daysLeft_',
  //     minWidth: 100,
  //     filter: false,
  //     // flex: 1,
  //   },
  //   {
  //     minWidth: 120,
  //     headerName: 'Actions',
  //     field: 'actions',
  //     sortable: false,
  //     editable: false,
  //     suppressMenu: false,
  //     filter: false,
  //     flex: 1,
  //     cellRendererFramework: (params) => {
  //       return (
  //         <div>
  //           <Link to={`/trailers/edit/${params.data ? params.data.id : null}`}>
  //             <Icon.Edit
  //               className="icon-button ml-1"
  //               size={20}
  //               color="darkgray"
  //             />
  //           </Link>
  //           {checkAllow(decodedToken.role) && (
  //             <Icon.Delete
  //               onClick={() => {
  //                 setTrailerId(params.data ? params.data.id : null);
  //                 setDeleteAlert(true);
  //               }}
  //               className="icon-button ml-1"
  //               size={20}
  //               color="darkgray"
  //             />
  //           )}
  //         </div>
  //       );
  //     },
  //   },
  // ];

  useEffect(() => {
    setGeneralData(data);
  }, [data]);

  const handleModal = () => {
    setModal(!modal);
  };

  const checkAllow = (role) => {
    if (role === 'ROLE_ADMIN' || role === 'SUPER_ADMIN') {
      return true;
    }
    return false;
  };

  const deleteTrailer = (id) => {
    trailerGeneralService
      .deleteTrailer(id)
      .then((res) => {
        setDeleteAlert(false);
        setRefresh(true);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const doubleClick = (id) => {
    navigate(`/trailers/edit/${id}`);
  };

  const getFile = () => {
    trailerGeneralService
      .getExcelFile(
        trailerStatus !== null ? `trailer_status=${trailerStatus}` : ''
      )
      .then((result) => {
        if (result.status === 'success') {
          window.open(result.data, '_blank');
        } else {
          toast.error(result.msg, {
            transition: Flip,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  return (
    <>
      <Helmet>
        <title> Trailers </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: '#000',
          // height: 'calc(100vh - 6.5rem)',
          // py: 1,
        }}
      >
        <Loading loading={loading} />
        <SweetAlert
          title="Are you sure?"
          warning
          show={deleteAlert}
          showCancel
          reverseButtons
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes"
          cancelBtnText="Cancel"
          onConfirm={() => {
            deleteTrailer(trailerId);
          }}
          onCancel={() => setDeleteAlert(false)}
        >
          {}
        </SweetAlert>
        <Card style={{ marginRight: 8 }}>
          <br />
          <CardBody className="py-0 no-pagination">
            <div className="d-flex justify-content-between ">
              <div className="d-flex align-items-center ">
                <Nav pills className="dashboard-tabs">
                  {tabs.map((item) => (
                    <NavItem key={`${item.id}`}>
                      <NavLink
                        style={{
                          height: 38,
                          backgroundColor: item.id === 1 ? '#00FF7F' : 'white',
                        }}
                        className={classnames(
                          {
                            customActive: active === item.id,
                          },
                          'd-flex align-items-center'
                        )}
                        onClick={() => {
                          navigate(tabList[item.id - 1]);
                        }}
                      >
                        <h4 className="mb-0">{item.name}</h4>
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </div>
              {checkAllow(decodedToken.role) ? (
                <div>
                  <Link
                    to="/trailers/new"
                    className="d-flex align-items-center text-white"
                  >
                    <Button
                      size="sm"
                      color="primary"
                      className="d-flex align-items-center"
                      type="button"
                    >
                      <Icon.Plus size={20} /> &nbsp; Add new trailer
                    </Button>
                  </Link>
                </div>
              ) : null}
            </div>
            <div className="d-flex align-items-center mt-1 mx-0 mb-1">
              <TextField
                fullWidth
                size="small"
                id="search"
                label="Search"
                variant="outlined"
                onChange={(e) => setSearch(e.target.value)}
              />
              <IconButton
                color="primary"
                aria-label="filter"
                onClick={() => {
                  setDownloadShow(false);
                  setFilterIsShow(!isFilterShow);
                }}
              >
                <FilterListIcon />
              </IconButton>
              <IconButton
                color="primary"
                aria-label="download2"
                onClick={() => {
                  setFilterIsShow(false);
                  setDownloadShow(!isDownloadShow);
                }}
              >
                <DownloadForOfflineIcon />
              </IconButton>
            </div>
            <div className={isFilterShow ? 'align-items-center mb-2 mx-0' : ''}>
              <AnimatePresence className={classes.filters}>
                {isFilterShow ? (
                  <AnimationDiv>
                    <FilterTools
                      deleted={deleted}
                      setDeleted={setDeleted}
                      trailerStatus={trailerStatus}
                      setTrailerStatus={setTrailerStatus}
                    />
                  </AnimationDiv>
                ) : null}
              </AnimatePresence>
            </div>
            <div
              className={isDownloadShow ? 'align-items-center mb-2 mx-0' : ''}
            >
              <AnimatePresence className={classes.filters}>
                {isDownloadShow ? (
                  <AnimationDiv>
                    <DownloadFilterTools
                      trailerStatus={trailerStatus}
                      setTrailerStatus={setTrailerStatus}
                      getFile={getFile}
                    />
                  </AnimationDiv>
                ) : null}
              </AnimatePresence>
            </div>
            <div className="w-100 ag-grid-table with-pagination">
              <Table
                data={generalData}
                headers={headers}
                toolTips={
                  decodedToken.role === 'ROLE_ADMIN' ||
                  decodedToken.role === 'SUPER_ADMIN'
                    ? toolTipsAdmin
                    : toolTipsUser
                }
                onChange={handleOnTableChange}
                modal={handleModal}
                deleteModal={(id) => {
                  setTrailerId(id);
                  setDeleteAlert(true);
                }}
                doubleClick={doubleClick}
                columns={columns}
                setColumns={setColumns}
                // hidePagination={1}
              />
            </div>
            <br />
          </CardBody>
        </Card>
      </Box>
    </>
  );
}
