import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
  backgroundColor: '#000',
  // display: 'flex',
  height: '100%',
  overflow: 'auto',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    width: 0,
  },
  '&::-webkit-scrollbar-track': {
    // boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'darkgrey',
    outline: '1px solid slategrey',
    borderRadius: 2,
  },
  width: '100%',
}));

export const Content = styled('div')({
  backgroundColor: '#000',
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  paddingTop: 20,
  paddingLeft: 5,
});
