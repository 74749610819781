import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 30,
  borderRadius: 3,
  paddingLeft: 15,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 3,
    backgroundColor: theme.palette.mode === 'dark' ? '#1a90ff' : 'green',
  },
}));

export default ({ value }) => {
  return (
    <div>
      {value.fuelPercent ? value.fuelPercent + ' %' : '0 %'}
      <BorderLinearProgress
        variant="determinate"
        value={value.fuelPercent ? value.fuelPercent : 0}
      />
      {value.fuelPercentTime}
    </div>
  );
};
