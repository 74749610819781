import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Input } from 'reactstrap';
import { toast, Flip } from 'react-toastify';
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { setRefreshPage } from '../../../../redux/modules/store/actions';
import fuelService from '../../../../services/fuel';

export default ({ value }) => {
  const dispatch = useDispatch();

  const sendData = () => {
    if (
      document.querySelector('#tank' + value.id).value === '' ||
      document.querySelector('#tank' + value.id).value === null
    ) {
      toast.error('Enter value', { transition: Flip });
    } else {
      const data = {
        id: value.id,
        type: 'tank',
        data: document.querySelector('#tank' + value.id).value,
      };

      fuelService
        .updateFuelManagement(data)
        .then((data) => {
          toast.success('Successfuly edited', {
            transition: Flip,
          });
          dispatch(setRefreshPage(true));
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            transition: Flip,
          });
          // toast.error("Something went wrong", { transition: Flip });
          return Promise.reject();
        });
    }
  };

  useEffect(() => {
    document.querySelector('#tank' + value.id).value = value.tank
      ? value.tank
      : 0;
  }, [value.id]);

  return (
    <div style={{ display: 'flex' }}>
      &nbsp;
      <Input
        type="text"
        autoComplete="off"
        // value={value.tank ? value.tank : 0}
        id={'tank' + value.id}
        onChange={(e) => {
          const re = /^[0-9\b]+$/;
          if (e.target.value === '' || re.test(e.target.value)) {
            document.querySelector('#tank' + value.id).value = e.target.value;
          }
        }}
      />
      <IconButton
        aria-label="check"
        onClick={() => {
          sendData();
        }}
      >
        <CheckCircleIcon color="success" />
      </IconButton>
    </div>
  );
};
