import React, { useState, useEffect } from 'react';
import {
  // Form,
  Form,
  Button,
  FormGroup,
  Input,
  CustomInput,
  Col,
  Card,
  CardBody,
  CardHeader,
  Label,
  // Button
} from 'reactstrap';
import * as Icon from 'react-feather';
import jwtDecode from 'jwt-decode';
import { useLocation, useParams } from 'react-router-dom';
import { toast, Flip } from 'react-toastify';
import Select from 'react-select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import userService from '../../../../services/users';
import fuelService from '../../../../services/fuel';
import Loading from '../../../../components/main/Loading';

export default function EditDispatcher() {
  const [loading, setLoading] = useState(true);
  const [dispatcherStatus, setDispatcherStatus] = useState(false);
  const [states, setStates] = useState([]);

  const [availableCompanies, setAvailableCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedCompanyOptions, setSelectedCompanyOptions] = useState([]);

  const [availableUnits, setAvailableUnits] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [selectedUnitOptions, setSelectedUnitOptions] = useState([]);

  const [customerTypes, setCustomerTypes] = useState([]);
  const [logoFileId, setLogoFileId] = useState(null);
  const [username, setUsername] = useState([]);
  const [role, setRole] = useState(null);

  const { id } = useParams();

  const token = localStorage.getItem(
    localStorage.getItem('current_account') + '_access_token'
  );

  const decodedToken = jwtDecode(token);

  const newUser = () => {
    const data = {
      id: parseInt(id, 10),
      username: document.querySelector('#username').value,
      password: document.querySelector('#password').value,
      roleId: parseInt(document.querySelector('#role').value, 10),
      name: document.querySelector('#name').value,
      email: document.querySelector('#email').value,
      phone: document.querySelector('#phoneNumber').value,
      visibleIds: selectedCompanies,
      dispatcherStatus,
      visibleUnitIds: selectedUnits,
    };

    userService
      .update(data)
      .then((res) => {
        if (res) {
          toast.success('User successfuly edited', {
            transition: Flip,
          });
          window.history.back();
        } else {
          toast.error('Something went wrong', { transition: Flip });
          // res.text();
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const handleCompanySelect = (array) => {
    let letMappedCompanies = [];
    if (array != null) {
      letMappedCompanies = array.map((element) => {
        return element.value;
      });
    }

    setSelectedCompanies(letMappedCompanies);
    setSelectedCompanyOptions(array);
  };

  const handleUnitSelect = (array) => {
    let letMappedCompanies = [];
    if (array != null) {
      letMappedCompanies = array.map((element) => {
        return element.value;
      });
    }

    setSelectedUnits(letMappedCompanies);
    setSelectedUnitOptions(array);
  };

  const handleTeamSelect = (array) => {
    let mappedTeams = [];
    if (array != null) {
      mappedTeams = array.map((team) => {
        return team.value;
      });
    }

    // this.setState({
    //   selectedTeams: mappedTeams,
    //   selectedTeamOptions: array,
    // });
  };

  const componentDidMount = () => {
    userService
      .getUserData(id)
      .then((data) => {
        setLoading(false);

        userService.getContext().then((datas) => {
          const mappedCompaniesData = datas.data.companies.map((elem) => {
            return {
              value: elem.id,
              label: elem.name,
            };
          });

          setAvailableCompanies(mappedCompaniesData);

          // eslint-disable-next-line array-callback-return, consistent-return
          const selectedOptions = [];
          data.visibleIds.forEach((ids) => {
            // eslint-disable-next-line array-callback-return, consistent-return
            datas.data.companies.forEach((item, i) => {
              if (item.id === ids) {
                selectedOptions.push({
                  value: ids,
                  label: item.name,
                });
              }
            });
            // for (let i = 0; i < data.data.companies.length; i + 1) {

            // }
          });
          setSelectedCompanyOptions(selectedOptions);
        });

        fuelService.getAllUnitList().then((datas) => {
          const mappedUnitData = datas.map((elem) => {
            return {
              value: elem.vehicleId,
              label: elem.vehicle,
            };
          });

          setAvailableUnits(mappedUnitData);

          // eslint-disable-next-line array-callback-return, consistent-return
          const selectedOptionsUnit = [];
          datas.forEach((item, i) => {
            if (item.employeeId * 1 === id * 1) {
              selectedOptionsUnit.push({
                value: item.vehicleId,
                label: item.vehicle,
              });
            }
          });
          setSelectedUnitOptions(selectedOptionsUnit);
        });

        // const mappedCompanyData = data.availableCompanies.map((company) => {
        //   return {
        //     value: company.id,
        //     label: company.name,
        //   };
        // });

        // let selectedTeamOptions = data.visibleTeamIds.map((id) => {
        //     for (let i = 0; i < data.availableTeams.length; i++) {
        //         if (data.availableTeams[i].id === id)
        //             return {
        //                 value: id,
        //                 label: data.availableTeams[i].name,
        //             };
        //     }
        // });

        // let mappedTeamData = data.availableTeams.map((team) => {
        //     return {
        //         value: team.id,
        //         label: team.name,
        //     };
        // });

        setRole(parseInt(data.role, 10));
        setDispatcherStatus(data.dispatcherStatus);

        setSelectedCompanies(data.visibleIds);

        document.querySelector('#username').value = data.username.toLowerCase();
        document.querySelector('#role').value = data.role;
        document.querySelector('#name').value = data.name;
        document.querySelector('#email').value = data.email;
        document.querySelector('#phoneNumber').value = data.phone;
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  useEffect(() => {
    localStorage.setItem('pageIndex', 0);
    componentDidMount();
  }, []);

  return (
    <>
      <Loading loading={loading} />
      <Card>
        <CardHeader>
          <h3 className="mb-0">Editing user</h3>
        </CardHeader>
        <CardBody>
          <>
            <div className="d-flex">
              <div style={{ flex: 1, marginRight: 20 }}>
                <Form>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Username</span>
                    </Col>
                    <Col md="8">
                      <Input
                        disabled={true}
                        type="text"
                        id="username"
                        onChange={(e) => {
                          document.querySelector('#username').value =
                            e.target.value.toLowerCase();
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Password</span>
                    </Col>
                    <Col md="8">
                      <Input type="text" id="password" />
                      <Label for="password">
                        Must contain at least one uppercase and lowercase
                        letter, and at least 6 or more characters
                      </Label>
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Role</span>
                    </Col>
                    <Col md="8">
                      <CustomInput
                        type="select"
                        name="select"
                        id="role"
                        onChange={(val) => {
                          setRole(
                            parseInt(document.querySelector('#role').value, 10)
                          );
                        }}
                      >
                        {decodedToken.role === 'SUPER_ADMIN' ? (
                          <option key={1} value={1}>
                            Accounting
                          </option>
                        ) : null}
                        <option key={2} value={2}>
                          Updater
                        </option>
                        <option key={3} value={3}>
                          Dispatcher
                        </option>
                        <option key={4} value={4}>
                          Fuel department
                        </option>
                      </CustomInput>
                    </Col>
                  </FormGroup>
                  {role === 3 ? (
                    <FormGroup className="align-items-center" row>
                      <Col md="4">
                        <span>Dispatcher status</span>
                      </Col>
                      <Col>
                        <FormControlLabel
                          id="dispatcher_status"
                          name="dispatcher_status"
                          control={<Checkbox />}
                          label=""
                          checked={dispatcherStatus}
                          onChange={(val) => {
                            setDispatcherStatus(!dispatcherStatus);
                          }}
                        />
                      </Col>
                    </FormGroup>
                  ) : null}
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Visible companies</span>
                    </Col>
                    <Col>
                      <Select
                        isMulti
                        name="colors"
                        className="visible"
                        classNamePrefix="select"
                        options={availableCompanies}
                        onChange={handleCompanySelect}
                        value={selectedCompanyOptions}
                      />
                    </Col>
                  </FormGroup>

                  {role === 4 ? (
                    <FormGroup className="align-items-center" row>
                      <Col md="4">
                        <span>Units</span>
                      </Col>
                      <Col>
                        <Select
                          isMulti
                          name="colors"
                          className="visible"
                          classNamePrefix="select"
                          options={availableUnits}
                          onChange={handleUnitSelect}
                          value={selectedUnitOptions}
                        />
                      </Col>
                    </FormGroup>
                  ) : null}
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Name</span>
                    </Col>
                    <Col md="8">
                      <Input type="text" id="name" />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Email</span>
                    </Col>
                    <Col md="8">
                      <Input type="text" id="email" />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Phone Number</span>
                    </Col>
                    <Col md="8">
                      <Input type="text" id="phoneNumber" />
                    </Col>
                  </FormGroup>
                </Form>
              </div>
              <div style={{ width: '50%' }} />
            </div>
            <Button
              color="success"
              className="d-flex align-items-center"
              type="button"
              onClick={() => newUser()}
            >
              <Icon.Check size={22} /> Save
            </Button>
          </>
        </CardBody>
      </Card>
    </>
  );
}

// const mapStateToProps = (state) => {
//   return {
//     token: state.auth.login.token,
//   };
// };
// export default connect(mapStateToProps)(EditUser);
