export function dataSelector(dataResponse, page, size) {
  const filtered = dataResponse.content.map((item, i) => ({
    index: i + 1 + page * size,
    sync: item.locationUpdateTime,
    id: item.id,
    truck: item.vehicle,
    driver: item.driver,
    phone: item.phone,
    location: item.location,
    locationUpdateTime: item.locationUpdateTime,
    fuel: item.fuelPercent ? item.fuelPercent + ' %' : '',
    fuelPercentTime: item.fuelPercentTime,
    currentBook: item.currentBook,
    lastSavedBook: item.lastSavedBook,
    data: item,
    unitStatusData: item,
    unitData: item,
    tankData: item,
    fuelData: item,
  }));
  return { total: dataResponse.totalElements, data: filtered };
}
