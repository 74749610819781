import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  CardHeader,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import {
  TextField,
  Box,
  IconButton,
  DialogActions,
  Button,
  Checkbox,
} from '@mui/material';
import jwtDecode from 'jwt-decode';
import { toast, Flip } from 'react-toastify';
import Backdrop from '@mui/material/Backdrop';
import FilterListIcon from '@mui/icons-material/FilterList';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import DownloadIcon from '@mui/icons-material/Download';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import { MDBRadio } from 'mdb-react-ui-kit';
import ownedCompanyService from '../../../services/owned_company';
import unitService from '../../../services/units';
import driverService from '../../../services/drivers';
import accountingService from '../../../services/accounting';
import dispatcherService from '../../../services/users';
import { fetchData } from '../../../redux/modules/reports/actions';
import { useQuery } from '../useQuery';
import Loading from '../../../components/main/Loading';
import Table from '../../../components/main/table';
import { reportsHeader } from '../../../redux/constants/table/headers';
import { headerMaker } from '../../../components/main/table/helper';
import ModalWindow from '../../../components/main/Modals';
import TableHeaders from './Modals/TableHeader';

export default function Reports() {
  const {
    query,
    search,
    deleted,
    pageIndex,
    pageSize,
    setPageIndex,
    setPageSize,
    setSearch,
    setDeleted,
    setStart,
    setEnd,
    setRefresh,
    setDispatcherId,
    setCompanysTruck,
    setGetReport,
    setWeekly,
    setCarrier,
    setTruckId,
    driverId,
    setDriverId,
    setDriverReport,
    handleOnTableChange,
  } = useQuery({ fetchData });

  const [isActive, setIsActive] = useState(false);
  const [driver, setDriver] = useState(null);
  const [unit, setUnit] = useState(null);

  const [team, setTeam] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [companysTruckData, setCompanysTruckData] = useState(null);
  const [company, setCompany] = useState(null);
  const [startTime, setStartTime] = useState([]);
  const [endTime, setEndTime] = useState([]);

  const [monthly, setMonthly] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [dispatchers, setDispatchers] = useState([]);
  const [dispatcher, setDispatcher] = useState(null);

  const [modal, setModal] = useState(false);
  const [isFilterShow, setFilterIsShow] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const actions = [{ icon: <DownloadIcon />, name: 'Download' }];

  const headers = useMemo(() => headerMaker(reportsHeader), [reportsHeader]);

  const createHeaders = (headerData) => {
    const cData = headerData.map((item) => ({
      item,
      // ref: useRef(),
    }));
    // console.log(cData);

    // setColumns(cData);

    return cData;
  };

  const [columns, setColumns] = useState(createHeaders(headers));

  const { data, loading, total } = useSelector((state) => state.reportsReducer);
  localStorage.setItem('total_data', total);

  const [oldHeader, setOldHeader] = useState();
  const [headerData, setHeaderData] = useState(headers);
  const [headerDataTable, setHeaderDataTable] = useState(headers);

  const [driverReportStatus, setDriverReportStatus] = useState(false);

  const [checkedAll, setCheckedAll] = useState(false);
  const [isChecked, setIsChecked] = useState(() =>
    headerDataTable.map((item) => item.show)
  );

  const handleChange1 = (event) => {
    setCheckedAll(!checkedAll);
    setIsChecked((isChecked) => {
      return isChecked.map((c, i) => {
        if (i === 0) {
          return true;
        }
        return checkedAll;
      });
    });
  };

  const isCheckboxChecked = (index, checked) => {
    if (index !== 0) {
      setIsChecked((isChecked) => {
        return isChecked.map((c, i) => {
          if (i === index) return checked;
          return c;
        });
      });

      headerDataTable[index].show = !headerDataTable[index].show;
      setHeaderData(headerMaker(headerDataTable));
      setHeaderDataTable(headerDataTable);
    }
  };

  const loadingHeader = () => {
    const data = {
      reportHeader: reportsHeader,
    };
    dispatcherService
      .createTableHeader(data)
      .then((res) => {
        setOldHeader(res);
        if (res.reportHeader) {
          setHeaderDataTable(res.reportHeader);
          setHeaderData(headerMaker(res.reportHeader));
          setIsChecked(() => res.reportHeader.map((item) => item.show));
          setColumns(createHeaders(headerMaker(res.reportHeader)));
        }
      })
      .catch((err) => {});
  };

  const postHeader = () => {
    if (oldHeader) {
      oldHeader.reportHeader = headerDataTable;
      dispatcherService
        .updateTableHeader(oldHeader)
        .then((res) => {
          setFilterIsShow(false);
          setOldHeader(res);
          if (res.reportHeader) {
            setHeaderDataTable(res.reportHeader);
            setHeaderData(headerMaker(res.reportHeader));
            setIsChecked(() => res.reportHeader.map((item) => item.show));
            setColumns(createHeaders(headerMaker(res.reportHeader)));
          }
        })
        .catch((err) => {
          setFilterIsShow(false);
        });
    } else {
      const data = {
        reportHeader: headerDataTable,
      };
      dispatcherService
        .updateTableHeader(data)
        .then((res) => {
          setFilterIsShow(false);
          setOldHeader(res);
          if (res.reportHeader) {
            setHeaderDataTable(res.reportHeader);
            setHeaderData(headerMaker(res.reportHeader));
            setIsChecked(() => res.reportHeader.map((item) => item.show));
            setColumns(createHeaders(headerMaker(res.reportHeader)));
          }
        })
        .catch((err) => {
          setFilterIsShow(false);
        });
    }
  };

  //   buttonLoading: false,
  //   data: null,
  //   dispatchers: [],
  //   dispatcher: null,
  //   defaultColDef: {
  //     sortable: true,
  //     resizable: true,
  //     suppressMenu: false,
  //     wrapText: true,
  //     autoHeight: true,
  //     filter: true,
  //     tooltip: (params) => {
  //       return params.value;
  //     },
  //   },
  //   columnDefs: [
  //     {
  //       headerName: '#',
  //       field: 'serialNumber',
  //       maxWidth: 50,
  //       flex: 1,
  //       filter: false,
  //       cellStyle: function (params) {
  //         return {
  //           fontWeight: '500',
  //         };
  //       },
  //     },
  //     {
  //       headerName: 'Carrier',
  //       children: [
  //         {
  //           headerName: 'Name',
  //           field: 'carrierName',
  //           minWidth: 90,
  //           flex: 1,
  //           filter: true,
  //         },
  //       ],
  //     },
  //     {
  //       headerName: 'Booked',
  //       children: [
  //         {
  //           headerName: 'RC NO',
  //           field: 'rc',
  //           filter: true,
  //           minWidth: 160,
  //           maxWidth: 170,
  //           flex: 1,
  //         },
  //         {
  //           headerName: 'Company',
  //           field: 'shipperCompanyName',
  //           filter: true,
  //           minWidth: 200,
  //           flex: 1,
  //         },
  //       ],
  //     },
  //     {
  //       headerName: 'Start',
  //       children: [
  //         {
  //           headerName: 'Date/Time',
  //           field: 'timeStart',
  //           filter: true,
  //           minWidth: 130,
  //           maxWidth: 140,
  //           flex: 1,
  //           cellRendererFramework: (params) => {
  //             return <Moment format="YYYY-MM-DD HH:mm">{params.value}</Moment>;
  //           },
  //         },
  //         {
  //           headerName: 'Location',
  //           field: 'shipperCompanyLocation',
  //           filter: true,
  //           minWidth: 240,
  //           flex: 2,
  //         },
  //       ],
  //     },
  //     {
  //       headerName: 'End',
  //       children: [
  //         {
  //           headerName: 'Date/Time',
  //           field: 'endTime',
  //           filter: true,
  //           minWidth: 130,
  //           maxWidth: 140,
  //           flex: 1,
  //           cellRendererFramework: (params) => {
  //             return <Moment format="YYYY-MM-DD HH:mm">{params.value}</Moment>;
  //           },
  //         },
  //         {
  //           headerName: 'Location',
  //           field: 'endLocation',
  //           filter: true,
  //           minWidth: 240,
  //           flex: 2,
  //         },
  //       ],
  //     },
  //     {
  //       headerName: 'Truck',
  //       children: [
  //         {
  //           headerName: 'Number',
  //           field: 'truckNumber',
  //           filter: true,
  //           minWidth: 120,
  //           flex: 1,
  //         },
  //       ],
  //     },
  //     {
  //       headerName: 'Price',
  //       children: [
  //         {
  //           headerName: 'Booked',
  //           field: 'booked',
  //           filter: true,
  //           minWidth: 100,
  //           flex: 1,
  //           cellRendererFramework: function (params) {
  //             return `${params.value} $`;
  //           },
  //         },
  //         {
  //           headerName: 'Dispute',
  //           field: 'dispute',
  //           filter: true,
  //           minWidth: 100,
  //           flex: 1,
  //           cellRendererFramework: function (params) {
  //             return `${params.value} $`;
  //           },
  //         },
  //         {
  //           headerName: 'Detention',
  //           field: 'detention',
  //           filter: true,
  //           minWidth: 100,
  //           flex: 1,
  //           cellRendererFramework: function (params) {
  //             return `${params.value} $`;
  //           },
  //         },
  //         {
  //           headerName: 'Additional',
  //           field: 'additional',
  //           filter: true,
  //           minWidth: 100,
  //           flex: 1,
  //           cellRendererFramework: function (params) {
  //             return `${params.value} $`;
  //           },
  //         },
  //         {
  //           headerName: 'Fine',
  //           field: 'fine',
  //           filter: true,
  //           minWidth: 100,
  //           flex: 1,
  //           cellRendererFramework: function (params) {
  //             return `${params.value} $`;
  //           },
  //         },
  //         {
  //           headerName: 'Revised/Invoice',
  //           field: 'revisedInvoice',
  //           filter: true,
  //           minWidth: 140,
  //           flex: 1,
  //           cellRendererFramework: function (params) {
  //             return `${params.value} $`;
  //           },
  //         },
  //         {
  //           headerName: 'K - O',
  //           field: 'ko',
  //           filter: true,
  //           minWidth: 100,
  //           flex: 1,
  //           cellRendererFramework: function (params) {
  //             return `${params.value} $`;
  //           },
  //         },
  //       ],
  //     },
  //     {
  //       headerName: 'Factoring',
  //       children: [
  //         {
  //           headerName: 'Factoring',
  //           field: 'amount',
  //           filter: true,
  //           minWidth: 100,
  //           flex: 1,
  //           cellRendererFramework: function (params) {
  //             return `${params.value} $`;
  //           },
  //         },
  //         {
  //           headerName: 'Tafs',
  //           field: 'service',
  //           filter: true,
  //           minWidth: 100,
  //           flex: 1,
  //           cellRendererFramework: function (params) {
  //             return `${params.value} $`;
  //           },
  //         },
  //         {
  //           headerName: 'Net Paid',
  //           field: 'netPaid',
  //           filter: true,
  //           minWidth: 100,
  //           flex: 1,
  //           cellRendererFramework: function (params) {
  //             return `${params.value} $`;
  //           },
  //         },
  //       ],
  //     },
  //     {
  //       headerName: 'Team',
  //       children: [
  //         {
  //           headerName: 'Name',
  //           field: 'team',
  //           filter: true,
  //           minWidth: 100,
  //           flex: 1,
  //         },
  //       ],
  //     },
  //     {
  //       headerName: 'Note',
  //       children: [
  //         {
  //           headerName: 'Note',
  //           field: 'note',
  //           filter: true,
  //           minWidth: 200,
  //           flex: 1,
  //         },
  //       ],
  //     },
  //   ],
  // };

  const loadOptions = (inputValue, callback) => {
    unitService
      .getSearch(inputValue)
      .then((data) =>
        callback(
          data.map((el) => {
            return {
              ...el,
              value: el.id,
              label: el.number,
            };
          })
        )
      )
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const loadDrivers = (inputValue, callback) => {
    driverService
      .getAll(`sort=id,DESC&size=10000&page=0&search=${inputValue}`)
      .then((data) =>
        callback(
          data.content.map((el) => {
            return {
              ...el,
              value: el.id,
              label: `${el.lastName} ${el.firstName}`,
            };
          })
        )
      )
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  // const getTable = () => {
  //   // setLoading(true);
  //   // setData([]);

  //   accountingService
  //     .getAllInfo(
  //       `${start ? `startTime=${Date.parse(start)}` : ''}${
  //         end ? `&endTime=${Date.parse(end)}` : ''
  //       }${driver ? `&driver_id=${driver}` : ''}${
  //         unit ? `&truck_number=${unit}` : ''
  //       }${dispatcher ? `&dispatcher_id=${dispatcher.value}` : ''}${
  //         company ? `&carrier=${company}` : ''
  //       }${companysTruck ? `&all_by_companys_truck=${companysTruck}` : ''}`
  //     )
  //     .then((data) => {
  //       // setLoading(false);
  //       // setData(data);
  //     })
  //     .catch((err) => {
  //       toast.error(err.response.data.message, {
  //         transition: Flip,
  //       });
  //       console.log(err);
  //     });
  // };

  // componentDidUpdate(prevProps, prevState) {
  //   let { driver, unit, dispatcher, company, companysTruck, start, end } =
  //     this.state;
  //   if (
  //     start !== prevState.start ||
  //     end !== prevState.end ||
  //     driver !== prevState.driver ||
  //     unit !== prevState.unit ||
  //     dispatcher !== prevState.dispatcher ||
  //     company !== prevState.company ||
  //     companysTruck !== prevState.companysTruck
  //   ) {
  //     if (
  //       start.length &&
  //       end.length &&
  //       (driver || unit || dispatcher || company || companysTruck)
  //     ) {
  //       this.getTable();
  //     }
  //   }
  // }

  const getDataDispatchers = (company) => {
    setDispatcher(null);
    setDispatchers([]);

    dispatcherService
      .getAllDispatchers(
        `size=10000&roleId=3${company ? `&currentEmployerId=${company}` : ''}`
      )
      .then((data) => {
        const dataToShow = [];
        data.content.forEach((item) => {
          const elToShow = {
            value: item.id,
            label: item.username,
          };
          dataToShow.push(elToShow);

          let dispatcher = null;
          if (team !== null) {
            dispatcher = dataToShow.find((item) => {
              return item.value === team;
            });
          }

          setDispatcher(dispatcher);
        });
        setDispatchers(dataToShow);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const getFile = () => {
    setButtonLoading(true);

    const weeklyData = document.getElementById('weekly').checked;
    accountingService
      .getAll(
        `${startTime ? `startTime=${Date.parse(startTime)}` : ''}${
          endTime ? `&endTime=${Date.parse(endTime)}` : ''
        }${driver ? `&driver_id=${driver}` : ''}${
          unit ? `&truck_id=${unit}` : ''
        }${dispatcher ? `&dispatcher_id=${dispatcher.value}` : ''}${
          company ? `&carrier=${company}` : ''
        }${
          companysTruckData ? `&all_by_companys_truck=${companysTruckData}` : ''
        }${
          weeklyData ? `&weekly=${weeklyData}` : ''
        }${`&driver_report=${driverReportStatus}`}`
      )
      .then((blob) => {
        const url = window.URL.createObjectURL(
          new Blob([blob], {
            type: 'application/octet-stream',
          })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'report.xlsx');
        link.click();

        setButtonLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setButtonLoading(false);

        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const componentDidMount = () => {
    ownedCompanyService
      .getAllVisible('sort=id,DESC&size=10000')
      .then((data) => {
        const companiesData = data.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        setCompanies(companiesData);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
    getDataDispatchers();
  };

  useEffect(() => {
    loadingHeader();
    localStorage.setItem('pageIndex', 0);
    componentDidMount();
  }, []);

  const handleModal = () => {
    setModal(!modal);
  };

  const handleChange = (value) => {
    const timer = setTimeout(() => {
      const results = data.filter((item) => {
        if (value === '') return data;
        return (
          item.rc.toLowerCase().includes(value.toLowerCase()) ||
          item.truckNumber.toLowerCase().includes(value.toLowerCase())
        );
      });
      // setReportData(results);
      clearTimeout(timer);
    }, 2000);
  };

  const checkPermission = () => {
    try {
      const token = localStorage.getItem(
        localStorage.getItem('current_account') + '_access_token'
      );
      const decodedToken = jwtDecode(token);
      if (decodedToken.role === 'SUPER_ADMIN') {
        return true;
      }
      if (decodedToken.role === 'ROLE_ADMIN') {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  return (
    <>
      <Helmet>
        <title> Reports </title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: '#000',
          height: 'calc(100vh - 6.5rem)',
        }}
      >
        <Loading loading={loading} />
        <Loading loading={buttonLoading} />
        <ModalWindow
          title="Headers"
          modal={isFilterShow}
          handleModal={() => {
            loadingHeader();
            setFilterIsShow(false);
          }}
          dialogActions={
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                autoFocus
                onClick={() => {
                  postHeader();
                }}
              >
                Accept
              </Button>
            </DialogActions>
          }
          maxWidth="sm"
          body={
            <TableHeaders
              modal={isFilterShow}
              isChecked={isChecked}
              checkedAll={checkedAll}
              handleChange1={handleChange1}
              isCheckboxChecked={isCheckboxChecked}
              headers={headerDataTable}
            />
          }
        />
        <Card>
          <CardHeader
            style={{
              paddingBottom: '1.5rem',
              borderBottom: '1px solid #ededed',
            }}
          >
            <h1 className="invoice-logo mb-0 mr-1">Get Report</h1>
            {/* <Icon.Clipboard size={25} /> */}
          </CardHeader>
          <CardBody className="invoice-padding pb-2">
            <Form>
              <Row>
                <Col md="6" sm="12">
                  <FormGroup>
                    <Label for="from">Start</Label>
                    <Flatpickr
                      id="from"
                      className="form-control"
                      data-enable-time
                      options={{
                        dateFormat: 'Z',
                        altInput: true,
                        altFormat: 'm-d-Y H:i',
                      }}
                      value={startTime}
                      onChange={(val) => setStartTime(val)}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" sm="12">
                  <FormGroup>
                    <Label for="lastNameMulti">End</Label>
                    <Flatpickr
                      id="until"
                      className="form-control"
                      data-enable-time
                      options={{
                        dateFormat: 'Z',
                        altInput: true,
                        altFormat: 'm-d-Y H:i',
                      }}
                      value={endTime}
                      onChange={(val) => setEndTime(val)}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" sm="12">
                  <FormGroup>
                    <Label for="cityMulti">Truck</Label>
                    <AsyncSelect
                      noOptionsMessage={(value) =>
                        !value.inputValue
                          ? 'type something to search'
                          : 'nothing to show'
                      }
                      placeholder="Search"
                      isClearable={true}
                      defaultValue={null}
                      loadOptions={loadOptions}
                      onChange={(value) => {
                        if (value !== null) {
                          setUnit(value.value);
                          if (startTime !== null && endTime !== null) {
                            setStart(Date.parse(startTime));
                            setEnd(Date.parse(endTime));
                            setTruckId(value.value);
                            setGetReport(true);
                            setButtonLoading(false);
                            setIsActive(true);
                          }
                        } else {
                          setTruckId(null);
                          setUnit(null);
                        }
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary50: '#fe810b',
                          primary25: 'rgb(253, 179, 46)',
                          primary: 'rgb(253, 179, 46)',
                        },
                      })}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" sm="12">
                  <FormGroup>
                    <Label for="driver">Drivers</Label>
                    <AsyncSelect
                      noOptionsMessage={(value) =>
                        !value.inputValue
                          ? 'type something to search'
                          : 'nothing to show'
                      }
                      placeholder="Search"
                      isClearable={true}
                      defaultValue={null}
                      loadOptions={loadDrivers}
                      onChange={(value) => {
                        if (value !== null) {
                          setDriver(value.value);
                          if (startTime !== null && endTime !== null) {
                            setStart(Date.parse(startTime));
                            setEnd(Date.parse(endTime));
                            setDriverId(value.value);
                            setGetReport(true);
                            setButtonLoading(false);
                            setIsActive(true);
                          }
                        } else {
                          setDriver(null);
                          setDriverId(null);
                        }
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary50: '#fe810b',
                          primary25: 'rgb(253, 179, 46)',
                          primary: 'rgb(253, 179, 46)',
                        },
                      })}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" sm="12">
                  <FormGroup>
                    <Label for="company">Company</Label>
                    <Select
                      id="company"
                      className="React"
                      classNamePrefix="select"
                      name="color"
                      options={companies}
                      isClearable={true}
                      onChange={(value) => {
                        if (value !== null) {
                          setCompany(value.value);
                          if (startTime !== null && endTime !== null) {
                            setStart(Date.parse(startTime));
                            setEnd(Date.parse(endTime));
                            setCarrier(value.value);
                            setGetReport(true);
                            setButtonLoading(false);
                            setIsActive(true);
                          }
                        } else {
                          setCarrier(null);
                          setCompany(null);
                        }
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary50: '#fe810b',
                          primary25: 'rgb(253, 179, 46)',
                          primary: 'rgb(253, 179, 46)',
                        },
                      })}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" sm="12">
                  <FormGroup>
                    <Label for="team">Dispatchers</Label>
                    <Select
                      id="team"
                      className="React"
                      classNamePrefix="select"
                      name="color"
                      options={dispatchers}
                      isClearable={true}
                      onChange={(value) => {
                        if (value !== null) {
                          setDispatcher(value);
                          if (startTime !== null && endTime !== null) {
                            setStart(Date.parse(startTime));
                            setEnd(Date.parse(endTime));
                            setDispatcherId(value.value);
                            setGetReport(true);
                            setButtonLoading(false);
                            setIsActive(true);
                          }
                        } else {
                          setDispatcherId(null);
                          setDispatcher(null);
                        }
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary50: '#fe810b',
                          primary25: 'rgb(253, 179, 46)',
                          primary: 'rgb(253, 179, 46)',
                        },
                      })}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" sm="12">
                  <FormGroup>
                    <Label for="company">Company`s truck</Label>
                    <Select
                      id="company"
                      className="React"
                      classNamePrefix="select"
                      name="color"
                      options={companies}
                      isClearable={true}
                      onChange={(value) => {
                        if (value !== null) {
                          setCompanysTruckData(value.value);
                          if (startTime !== null && endTime !== null) {
                            setStart(Date.parse(startTime));
                            setEnd(Date.parse(endTime));
                            setCompanysTruck(value.value);
                            setGetReport(true);
                            setButtonLoading(false);
                            setIsActive(true);
                          }
                        } else {
                          setCompanysTruck(null);
                          setGetReport(true);
                          setCompanysTruckData(null);
                        }
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary50: '#fe810b',
                          primary25: 'rgb(253, 179, 46)',
                          primary: 'rgb(253, 179, 46)',
                        },
                      })}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="team">Type</Label>
                    <MDBRadio
                      name="flexRadioDefault"
                      id="weekly"
                      label="weekly"
                      defaultChecked
                      onChange={(e) => {
                        setWeekly(true);
                      }}
                    />
                    <MDBRadio
                      name="flexRadioDefault"
                      id="monthly"
                      label="monthly"
                      onChange={(e) => {
                        setWeekly(false);
                      }}
                    />
                  </FormGroup>
                  {checkPermission() ? (
                    <FormGroup className="align-items-center" row>
                      <Col md="3">
                        <span>Delivered report</span>
                      </Col>
                      <Col md="9">
                        <Checkbox
                          checked={driverReportStatus}
                          onChange={(e) => {
                            console.log(startTime);
                            if (
                              startTime !== null &&
                              endTime !== null &&
                              startTime.length > 0 &&
                              endTime.length > 0
                            ) {
                              setDriverReportStatus(!driverReportStatus);
                              setDriverReport(!driverReportStatus);
                              setGetReport(true);
                            }
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </Col>
                    </FormGroup>
                  ) : null}
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>

        {/* <Col md={3} sm={12}>
          <Card>
            <CardBody className="d-flex justify-content-center align-items-center">
              <Button
                onClick={() => getFile()}
                color={isActive && !buttonLoading ? 'primary' : 'secondary'}
                disabled={!isActive}
                outline={isActive && !buttonLoading}
              >
                {buttonLoading ? 'Loading...' : 'Download'}
              </Button>
            </CardBody>
          </Card>
        </Col> */}

        <Card>
          <div className="d-flex justify-content-between align-items-center mt-2 mx-2 mb-1">
            <h3 className="mb-0">Report</h3>
          </div>

          <div className="d-flex align-items-center mb-0 mx-2">
            <TextField
              fullWidth
              size="small"
              id="search"
              label="Search (RC NO)"
              variant="outlined"
              onChange={(e) => setSearch(e.target.value)}
            />
            <IconButton
              color="primary"
              aria-label="filter"
              onClick={() => {
                setFilterIsShow(!isFilterShow);
              }}
            >
              <FilterListIcon />
            </IconButton>
          </div>

          <CardBody>
            <div>
              <Table
                data={data}
                headers={headerData}
                // toolTips={null}
                onChange={handleOnTableChange}
                modal={handleModal}
                // hidePagination={1}
                columns={columns}
                setColumns={setColumns}
              />
            </div>
          </CardBody>
        </Card>
        <>
          <Backdrop open={open} />
          <SpeedDial
            disabled={isActive}
            ariaLabel="SpeedDial"
            sx={{ position: 'absolute', top: 80, right: 20 }}
            icon={<DownloadIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            direction="down"
          >
            {actions.map((action) => (
              <SpeedDialAction
                disabled={isActive}
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                tooltipOpen
                onClick={() => {
                  if (isActive) {
                    handleClose();
                    getFile();
                  } else {
                    toast.error('Select the required sections.', {
                      transition: Flip,
                    });
                  }
                }}
              />
            ))}
          </SpeedDial>
        </>
      </Box>
    </>
  );
}
