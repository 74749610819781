import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import App from './App';
import './index.scss';
import './style.css';
import 'react-calendar-timeline/lib/Timeline.css';
// import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-fresh/sass/ag-theme-fresh.scss';
import 'ag-grid-community/styles/ag-grid.css';
import 'cropperjs/dist/cropper.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './i18n';
import stores from './redux/store';

// configureDatabase()
ReactDOM.render(
  <Provider store={stores}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
