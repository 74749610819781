import React, { useState } from 'react';
import { Fab, Paper, InputBase } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DownloadIcon from '@mui/icons-material/Download';

export default function AttachmentItem({
  downloadStatus,
  id,
  name,
  size,
  i,
  filesUpload,
  setFilesUpload,
  deleteHandler,
}) {
  const [fileName, setFileName] = useState('');
  const [fileId, setFileId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const checkImageFile = (file) => {
    const index1 = file.toLowerCase().indexOf('.jpg');
    const index2 = file.toLowerCase().indexOf('.png');
    if (index1 > -1 || index2 > -1) {
      return true;
    }
    return false;
  };

  const humanFileSize = (bytes, dp = 1) => {
    const thresh = 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    let u = -1;
    const r = 10 ** dp;

    do {
      // eslint-disable-next-line no-param-reassign
      bytes /= thresh;
      // eslint-disable-next-line no-plusplus
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(dp) + ' ' + units[u];
  };

  return (
    <div
      key={id}
      style={{
        padding: 10,
        // maxWidth: "20px",
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          key={id}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          {i + 1}.
          {checkImageFile(name) ? (
            <div
              style={{
                width: '50px',
                height: '50px',
              }}
            >
              <Fab
                color="primary"
                aria-label="add"
                style={{
                  borderRadius: 30,
                }}
                onClick={() => {}}
              >
                <ImageIcon />
              </Fab>
            </div>
          ) : (
            <div
              style={{
                width: '50px',
                height: '50px',
              }}
            >
              <Fab
                color="primary"
                aria-label="add"
                style={{
                  borderRadius: 30,
                }}
                onClick={() => {}}
              >
                <AttachFileIcon />
              </Fab>
            </div>
          )}
          &nbsp; &nbsp;{' '}
          {fileId === id ? (
            <Paper
              component="form"
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: 300,
              }}
            >
              <InputBase
                sx={{
                  ml: 1,
                  flex: 1,
                }}
                value={fileName}
                placeholder=""
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    const updatedList = filesUpload.map((element) => {
                      if (element.id === id) {
                        // eslint-disable-next-line no-param-reassign
                        element.name = fileName;
                        return element;
                      }
                      return element;
                    });

                    setFileId();
                    setFilesUpload(updatedList);
                  }
                }}
                onChange={(e) => {
                  setFileName(e.target.value);
                }}
              />

              <Divider
                sx={{
                  height: 28,
                  m: 0.5,
                }}
                orientation="vertical"
              />
              <IconButton
                color="primary"
                sx={{
                  p: '10px',
                }}
                aria-label="directions"
                onClick={() => {
                  const updatedList = filesUpload.map((element) => {
                    if (element.id === id) {
                      // eslint-disable-next-line no-param-reassign
                      element.name = fileName;
                      return element;
                    }
                    return element;
                  });

                  setFileId();
                  setFilesUpload(updatedList);
                }}
              >
                <CheckCircleIcon />
              </IconButton>
            </Paper>
          ) : (
            name
          )}
          <br />
          {fileId !== id ? (
            <>
              &nbsp; &nbsp; {humanFileSize(size)} <br />
              <br />
            </>
          ) : null}
        </div>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <MoreHorizIcon />
          </IconButton>
        </Tooltip>
      </div>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 7px 7px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
      >
        <MenuItem
          onClick={() => {
            console.log(id);
            setFileId(() => id);
            setFileName(name);
            // setRename(true);
          }}
        >
          <ListItemIcon>
            <DriveFileRenameOutlineIcon fontSize="small" />
          </ListItemIcon>
          Rename
        </MenuItem>
        <MenuItem
          onClick={() => {
            deleteHandler(id);
            // const fileListUpload = [];
            // Array.from(filesUpload).forEach((data) => {
            //   if (id !== data.id) {
            //     fileListUpload.push(data);
            //   }
            // });

            // setFilesUpload(fileListUpload);
          }}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          Delete
        </MenuItem>
        {downloadStatus ? (
          <MenuItem
            onClick={() => {
              fetch(`${process.env.REACT_APP_BASE_URL}/file/${id}`, {
                method: 'GET',
                headers: {
                  Authorization:
                    'Bearer ' +
                    localStorage.getItem(
                      localStorage.getItem('current_account') + '_access_token'
                    ),
                },
              }).then((response) => {
                response.blob().then((blob) => {
                  const spl = response.headers
                    .get('content-disposition')
                    .split(';');

                  const filename = spl[1].split('=')[1].replace('"', '');
                  const fileName2 = filename.replace('"', '');
                  // Creating new object of PDF file
                  const fileURL = window.URL.createObjectURL(blob);

                  const alink = document.createElement('a');
                  alink.href = fileURL;
                  alink.target = '_blank';
                  alink.download = fileName2;
                  alink.click();
                });
              });
            }}
          >
            <ListItemIcon>
              <DownloadIcon fontSize="small" />
            </ListItemIcon>
            Download
          </MenuItem>
        ) : null}
      </Menu>
      <Divider />
    </div>
  );
}
