import axios from 'axios';
import types from '../../constants/action-types';

export const setTotals = (payload) => {
  return {
    type: types.TABLE_SET_TOTAL,
    payload,
  };
};

export const setProfileImage = (payload) => {
  return {
    type: types.SET_PROFILE_IMAGE,
    payload,
  };
};

export const setModal = (payload) => {
  return {
    type: types.SET_MODAL,
    payload,
  };
};

export const setHeaderTable = (payload) => {
  return {
    type: types.SET_HEADERS,
    payload,
  };
};

export const setNewNotification = (payload) => {
  return {
    type: types.SET_NOTIFICATION,
    payload,
  };
};

export const setMessages = (payload) => {
  return {
    type: types.SET_MESSAGES,
    payload,
  };
};

export const setRefreshPage = (payload) => {
  return {
    type: types.SET_REFRESH_PAGE,
    payload,
  };
};

export const setRefreshPage1 = (payload) => {
  return {
    type: types.SET_REFRESH_PAGE_1,
    payload,
  };
};
