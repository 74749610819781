import { service } from '.';

export default {
  getAllAdditions: (query) => service.get(`/statement/additions/list?${query}`),
  getOneAddition: (id) => service.get(`/statement/additions/${id}`),
  createAddition: (data) => service.post('/statement/additions/new', data),
  updateAddition: (data) => service.put('/statement/additions/edit', data),
  deleteAddition: (id) => service.delete(`/statement/additions/${id}`),

  getAllDeductions: (query) =>
    service.get(`/statement/deductions/list?${query}`),
  getOneDeduction: (id) => service.get(`/statement/deductions/${id}`),
  createDeduction: (data) => service.post('/statement/deductions/new', data),
  updateDeduction: (data) => service.put('/statement/deductions/edit', data),
  deleteDeduction: (id) => service.delete(`/statement/deductions/${id}`),
};
