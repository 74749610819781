import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  FormGroup,
  Input,
  Label,
  CustomInput,
  Col,
  Card,
  CardBody,
  CardHeader,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import * as Icon from 'react-feather';
import jwtDecode from 'jwt-decode';
import { connect } from 'react-redux';
import { toast, Flip } from 'react-toastify';
import { FormControlLabel, Box, Checkbox } from '@mui/material';
import Select from 'react-select';
import userService from '../../../../services/users';
import fuelService from '../../../../services/fuel';

export default function NewDispatchers() {
  const [states, setStates] = useState([]);
  const [customerTypes, setCustomerTypes] = useState([]);
  const [logoFileId, setLogoFileId] = useState(null);
  const [userName, setUsername] = useState([]);
  const [availableCompanies, setAvailableCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedCompanyOptions, setSelectedCompanyOptions] = useState([]);

  const [availableUnits, setAvailableUnits] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [selectedUnitOptions, setSelectedUnitOptions] = useState([]);

  const [availableTeams, setAvailableTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedTeamOptions, setSelectedTeamOptions] = useState([]);
  const [dispatcherStatus, setDispatcherStatus] = useState(false);
  const [role, setRole] = useState(null);

  const token = localStorage.getItem(
    localStorage.getItem('current_account') + '_access_token'
  );

  const decodedToken = jwtDecode(token);

  const handleSelect = (array) => {
    let mappedCompanies = [];
    if (array != null) {
      mappedCompanies = array.map((element) => {
        return element.value;
      });
    }
    setSelectedCompanies(mappedCompanies);
    setSelectedCompanyOptions(array);
  };

  const handleTeamSelect = (array) => {
    let mappedTeams = [];
    if (array != null) {
      mappedTeams = array.map((element) => {
        return element.value;
      });
    }

    setSelectedTeams(mappedTeams);
    setSelectedTeamOptions(array);
  };

  const componentDidMount = () => {
    userService.getContext().then((data) => {
      const mappedCompaniesData = data.data.companies.map((elem) => {
        return {
          value: elem.id,
          label: elem.name,
        };
      });

      setAvailableCompanies(mappedCompaniesData);
    });

    fuelService.getAllUnitList().then((datas) => {
      const mappedUnitData = datas.map((elem) => {
        return {
          value: elem.vehicleId,
          label: elem.vehicle,
        };
      });

      setAvailableUnits(mappedUnitData);
    });

    // let mappedTeamsData = data.teams.map((elem) => {
    //     return {
    //         value: elem.id,
    //         label: elem.name,
    //     };
    // });

    // this.setState({
    //   availableCompanies: mappedCompaniesData,
    //   // availableTeams: mappedTeamsData,
    // });
  };

  const newUser = () => {
    const data = {
      username: document.querySelector('#username').value.toLowerCase(),
      password: document.querySelector('#password').value,
      roleId: parseInt(document.querySelector('#role').value, 10),
      name: document.querySelector('#name').value,
      email: document.querySelector('#email').value,
      phone: document.querySelector('#phoneNumber').value,
      visibleIds: selectedCompanies,
      // visibleTeamIds: this.state.selectedTeams,
      dispatcherStatus,
      visibleUnitIds: selectedUnits,
    };

    userService
      .create(data)
      .then((res) => {
        if (res) {
          toast.success('User successfully added', {
            transition: Flip,
          });
          window.history.back();
        } else {
          toast.error('Something went wrong', { transition: Flip });
          // res.text();
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const handleUnitSelect = (array) => {
    let letMappedCompanies = [];
    if (array != null) {
      letMappedCompanies = array.map((element) => {
        return element.value;
      });
    }

    setSelectedUnits(letMappedCompanies);
    setSelectedUnitOptions(array);
  };

  useEffect(() => {
    componentDidMount();
  }, []);

  return (
    <>
      <Helmet>
        <title> Users </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: '#000',
          height: 'calc(100vh - 6.5rem)',
          py: 1,
        }}
      >
        <Card>
          <CardHeader>
            <h3 className="mb-0">Adding a new user</h3>
          </CardHeader>
          <CardBody>
            <div className="d-flex">
              <div style={{ flex: 1, marginRight: 20 }}>
                <Form>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Username</span>
                    </Col>
                    <Col md="8">
                      <Input
                        type="text"
                        id="username"
                        onChange={(e) => {
                          document.querySelector('#username').value =
                            e.target.value.toLowerCase();
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Password</span>
                    </Col>
                    <Col md="8">
                      <Input type="text" id="password" />
                      <Label for="password">
                        Must contain at least one uppercase and lowercase
                        letter, and at least 6 or more characters
                      </Label>
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Role</span>
                    </Col>
                    <Col md="8">
                      <CustomInput
                        type="select"
                        name="select"
                        id="role"
                        onChange={(val) => {
                          setRole(
                            parseInt(document.querySelector('#role').value, 10)
                          );
                        }}
                      >
                        {decodedToken.role === 'SUPER_ADMIN' ? (
                          <option key={1} value={1}>
                            Accounting
                          </option>
                        ) : null}
                        <option key={2} value={2}>
                          Updater
                        </option>
                        <option key={3} value={3}>
                          Dispatcher
                        </option>
                        <option key={4} value={4}>
                          Fuel department
                        </option>
                      </CustomInput>
                    </Col>
                  </FormGroup>
                  {role === 3 ? (
                    <FormGroup className="align-items-center" row>
                      <Col md="4">
                        <span>Dispatcher status</span>
                      </Col>
                      <Col>
                        <FormControlLabel
                          id="dispatcher_status"
                          name="dispatcher_status"
                          control={<Checkbox />}
                          label=""
                          checked={dispatcherStatus}
                          onChange={(val) => {
                            setDispatcherStatus(!dispatcherStatus);
                          }}
                        />
                      </Col>
                    </FormGroup>
                  ) : null}
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Visible companies</span>
                    </Col>
                    <Col>
                      <Select
                        isMulti
                        name="colors"
                        className="visible"
                        classNamePrefix="select"
                        options={availableCompanies}
                        onChange={handleSelect}
                        value={selectedCompanyOptions}
                      />
                    </Col>
                  </FormGroup>
                  {role === 4 ? (
                    <FormGroup className="align-items-center" row>
                      <Col md="4">
                        <span>Units</span>
                      </Col>
                      <Col>
                        <Select
                          isMulti
                          name="colors"
                          className="visible"
                          classNamePrefix="select"
                          options={availableUnits}
                          onChange={handleUnitSelect}
                          value={selectedUnitOptions}
                        />
                      </Col>
                    </FormGroup>
                  ) : null}
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Name</span>
                    </Col>
                    <Col md="8">
                      <Input type="text" id="name" />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Email</span>
                    </Col>
                    <Col md="8">
                      <Input type="text" id="email" />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Phone Number</span>
                    </Col>
                    <Col md="8">
                      <Input type="text" id="phoneNumber" />
                    </Col>
                  </FormGroup>
                </Form>
              </div>
              <div style={{ width: '50%' }} />
            </div>
            <Button
              color="success"
              className="d-flex align-items-center"
              type="button"
              onClick={() => newUser()}
            >
              <Icon.Check size={22} /> Save user
            </Button>
          </CardBody>
        </Card>
      </Box>
    </>
  );
}
