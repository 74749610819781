export const byStatusState = [
  {
    name: 'Active',
    value: true,
  },
  {
    name: 'Deactive',
    value: false,
  },
];
export const byDelete = [
  {
    name: 'true',
    value: 1,
  },
  {
    name: 'false',
    value: 0,
  },
];
