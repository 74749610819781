import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { byStatus } from './helper';
import { useStyles } from '../style';
import FilterButton from '../../FormElements/FilterButton';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

export default ({
  deleted,
  setDeleted,
  ownedCompanies,
  setCurrentEmployerId,
  setUnitStatusId,
  unitStatuses,
}) => {
  const classes = useStyles();
  const [selectedFilter, setSelectedFilter] = useState(0);
  const [unitStatus, setUnitStatus] = useState(null);
  const [ownedCompany, setOwnedCompany] = useState(null);

  const handleCompany = (e) => {
    const { value } = e.target;
    setOwnedCompany(value);
    if (value === 0) {
      setCurrentEmployerId(null);
    } else {
      setCurrentEmployerId(value);
    }
    if (ownedCompany === null) {
      setSelectedFilter((prev) => prev + 1);
    }
  };

  const handleUnitStatus = (e) => {
    const { value } = e.target;

    setUnitStatus(value);
    if (value === 0) {
      setUnitStatusId(null);
    } else {
      setUnitStatusId(value);
    }

    if (unitStatus === null) {
      setSelectedFilter((prev) => prev + 1);
    }
  };

  const handleDeleted = (e) => {
    setDeleted(e.target.value);
    if (deleted === '') {
      setSelectedFilter((prev) => prev + 1);
    }
  };
  const handleClearFilters = () => {
    setDeleted('');
    setUnitStatusId();
    setUnitStatus(null);
    setCurrentEmployerId();
    setOwnedCompany(null);
    setSelectedFilter(0);
  };

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.root}>
        <FormControl size="small">
          <InputLabel id="demo-simple-select-label2">Select company</InputLabel>
          <Select
            className={classes.input}
            labelId="demo-simple-select-label2"
            id="demo-simple-select"
            value={ownedCompany === null ? 0 : ownedCompany}
            label="Select company"
            MenuProps={MenuProps}
            onChange={(e) => {
              handleCompany(e);
            }}
          >
            {ownedCompanies.map((item, i) => {
              return (
                <MenuItem key={`${i + 1}`} value={item.id}>
                  {item.abbreviation}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl size="small">
          <InputLabel id="demo-simple-select-label1">
            Select unit status
          </InputLabel>
          <Select
            className={classes.input}
            labelId="demo-simple-select-label1"
            id="demo-simple-select"
            value={unitStatus === null ? 0 : unitStatus}
            label="Select unit status"
            MenuProps={MenuProps}
            onChange={(e) => {
              handleUnitStatus(e);
            }}
          >
            {unitStatuses.map((item, i) => {
              return (
                <MenuItem key={`${i + 1}`} value={item.id}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl size="small">
          <InputLabel id="status"> Filter by delete</InputLabel>
          <Select
            className={classes.input}
            value={deleted}
            onChange={handleDeleted}
            labelId="delete"
            label="Filter by delete"
          >
            {byStatus.map((item, index) => (
              <MenuItem key={`${index + 1}`} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <FilterButton
        selectedFilter={selectedFilter}
        handleClearFilters={handleClearFilters}
      />
    </Box>
  );
};
