import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import { CardMedia, Chip } from '@mui/material';
import {
  NavLink as RouterLink,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { deepOrange } from '@mui/material/colors';
import jwtDecode from 'jwt-decode';
import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import GradeIcon from '@mui/icons-material/Grade';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { styled, alpha } from '@mui/material/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ListItemIcon from '@mui/material/ListItemIcon';
import { setLanguage } from '../../../redux/modules/lang/actions';
import { clearToken } from '../../../redux/modules/auth/actions';
import auth from '../../../services/auth';
import usersApi from '../../../services/users';
import fileApi from '../../../services/file_upload';

export default () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [previewImg, setPreviewImg] = useState();
  const image = useSelector((state) => state.storeReducer.image);

  const token = localStorage.getItem(
    localStorage.getItem('current_account') + '_access_token'
  );
  const decodedToken = jwtDecode(token);

  const handleLogOut = () => {
    const accounts = localStorage.getItem('accounts');
    const accs = [];
    if (accounts !== null) {
      const acc = JSON.parse(accounts);
      if (acc.length === 1) {
        localStorage.clear();
      } else {
        acc.forEach((item) => {
          if (item === localStorage.getItem('current_account')) {
            dispatch(clearToken(item));
            localStorage.removeItem(
              localStorage.getItem('current_account') + '_access_token'
            );
          } else {
            accs.push(item);
          }
        });
        localStorage.setItem('accounts', JSON.stringify(accs));
        localStorage.setItem('current_account', accs[0].toUpperCase());
      }
    }

    window.open(location.pathname, '_self');
  };

  // const active = href ? !!matchPath({ path: href }, location.pathname) : false;
  const active = location.pathname.startsWith('#');
  const paths = location.pathname;

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeLang = (item) => {
    dispatch(setLanguage(item));
  };

  const loadingData = () => {
    usersApi
      .getProfileData()
      .then((res) => {
        if (res.photo) {
          fileApi
            .download('/file/image/' + res.photo)
            .then((image) => {
              setPreviewImg('data:image/png;base64,' + image);
            })
            .catch((err) => {});
        }
      })
      .catch((err) => {
        try {
          if (err.response.status === 401) {
            setTimeout(() => {
              loadingData();
            }, 5000);
          }
        } catch (error) {
          //
        }
      });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    loadingData();
  }, [image]);

  return (
    <div>
      {previewImg ? (
        <CardMedia
          onClick={handleClick}
          component="img"
          style={{
            width: 40,
            height: 40,
            borderRadius: 5,
            objectFit: 'fill',
            cursor: 'point',
          }}
          image={previewImg}
          alt=""
        />
      ) : (
        <Avatar
          onClick={handleClick}
          sx={{ borderRadius: 5, bgcolor: '#E5E8EC' }}
        >
          <AccountCircleIcon
            sx={{ borderRadius: 5, bgcolor: '#000', cursor: 'point' }}
          />
          {/* <PersonOutlineIcon onClick={handleClick} /> */}
        </Avatar>
      )}
      <Menu
        anchorEl={anchorEl}
        id="account-menu1"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem key="role">
          <ListItemIcon>
            {' '}
            <GradeIcon />{' '}
          </ListItemIcon>
          <Chip
            size="small"
            sx={{ height: 25 }}
            label={decodedToken.role.replace('ROLE_', '')}
            color="primary"
          />
        </MenuItem>
        {paths === '/app/account' || paths === '/app/settings' ? (
          <MenuItem
            key="home"
            onClick={() => {
              handleClose();
              navigate(
                localStorage.getItem('last_page')
                  ? localStorage.getItem('last_page')
                  : '/dashboard/list'
              );
            }}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            {t('Home')}
          </MenuItem>
        ) : (
          <MenuItem
            key="account"
            onClick={() => {
              handleClose();
              navigate('/app/account');
            }}
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            {t('Profile')}
          </MenuItem>
        )}
        <MenuItem
          key="exit"
          onClick={() => {
            handleClose();
            handleLogOut();
          }}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          {t('Exit')}
        </MenuItem>
      </Menu>
    </div>
  );
};
