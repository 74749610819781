import React, { useState, useMemo, useEffect } from 'react';
import { Card, CardBody, Input, Button } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { IconButton, Box, TextField } from '@mui/material';
import * as Icon from 'react-feather';
import { toast, Flip } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { AnimatePresence } from 'framer-motion';
import FilterListIcon from '@mui/icons-material/FilterList';
import SweetAlert from '../../../../components/main/SweetAlert';
import FilterTools from '../../../../components/main/FilteredBody/Fuel';
import AnimationDiv from '../../../../components/main/Animations/Transition';
import unitService from '../../../../services/units';
import fuelService from '../../../../services/fuel';
import { fetchData } from '../../../../redux/modules/fuel_book/actions';
import { useQuery } from '../../useQuery';
import Loading from '../../../../components/main/Loading';
import Table from '../../../../components/main/table';
import { fuelBookHeader } from '../../../../redux/constants/table/headers';
import { setRefreshPage } from '../../../../redux/modules/store/actions';
import { headerMaker } from '../../../../components/main/table/helper';
import { toolTipsUser, toolTipsAdmin } from './helper';
import { useStyles } from './style';
import ModalWindow from '../../../../components/main/Modals';
import BookModal from '../Modals/Book';
import PilotFuelPricesModal from '../Modals/PilotFuelPrices';
import LovesFuelPricesModal from '../Modals/LovesFuelPrices';

export default function FuelBookList() {
  const {
    query,
    search,
    deleted,
    pageIndex,
    pageSize,
    unitStatusId,
    setPageIndex,
    setPageSize,
    setSearch,
    setDeleted,
    setRefresh,
    setUnitStatusId,
    setCurrentEmployerId,
    handleOnTableChange,
  } = useQuery({ fetchData });

  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isFilterShow, setFilterIsShow] = useState(false);

  const [showBookModal, setShowBookModal] = useState(false);
  const [showPilotFuelPriceModal, setShowPilotFuelPriceModal] = useState(false);
  const [showLovesFuelPriceModal, setShowLovesFuelPriceModal] = useState(false);

  const [deleteAlert, setDeleteAlert] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [states, setStates] = useState([]);
  const [modal, setModal] = useState(false);
  const [unitStatuses, setUnitStatuses] = useState([]);
  const [ownedCompanies, setOwnedCompanies] = useState([]);

  const headers = useMemo(() => headerMaker(fuelBookHeader), [fuelBookHeader]);
  const { data, loading, total, error } = useSelector(
    (state) => state.fuelBookReducer
  );
  localStorage.setItem('total_data', total);

  const { refreshPage } = useSelector((state) => state.storeReducer);

  const token = localStorage.getItem(
    localStorage.getItem('current_account') + '_access_token'
  );
  const decodedToken = jwtDecode(token.replace('Bearer ', ''));

  const createHeaders = (headerData) => {
    const cData = headerData.map((item) => ({
      item,
      // ref: useRef(),
    }));
    // console.log(cData);

    // setColumns(cData);

    return cData;
  };

  const [columns, setColumns] = useState(createHeaders(headers));

  const nominateToDelete = (id) => {
    setDeletingId(id);
    setDeleteAlert(true);
  };

  const deleteUnit = () => {
    unitService.delete(`${deletingId}`).then((res) => {
      setRefresh(true);
    });
    setDeletingId(null);
    setDeleteAlert(false);
  };

  const handleModal = () => {
    setModal(!modal);
  };

  const doubleClick = (id) => {
    // navigate(`/fuel/view/${id}`);
  };

  const loadTruckOptions = (inputValue, callback) => {
    fuelService
      .getSearch(inputValue + '&page=0&size=10000')
      .then((data) =>
        callback(
          data.map((el) => {
            return {
              ...el,
              value: el.vehicleId,
              label: el.vehicle,
              driver: el.driver,
              driverId: el.driverId,
            };
          })
        )
      )
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const loadShopOptions = (inputValue, callback) => {
    fuelService
      .getFuelPrices('store=pilot&search=' + inputValue + '&page=0&size=10000')
      .then((data) =>
        callback(
          data.content.map((el) => {
            return {
              ...el,
              value: el.id,
              label: el.store,
            };
          })
        )
      )
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  return (
    <>
      <Helmet>
        <title> Fuel </title>
      </Helmet>
      <ModalWindow
        title="Book modal"
        modal={showBookModal}
        handleModal={() => {
          setShowBookModal(false);
        }}
        maxWidth="md"
        body={
          <BookModal
            modal={showBookModal}
            toggle={() => {
              setShowBookModal(false);
            }}
            loadTruckOptions={loadTruckOptions}
            loadShopOptions={loadShopOptions}
          />
        }
      />
      <ModalWindow
        title="Pilot fuel prices modal"
        modal={showPilotFuelPriceModal}
        handleModal={() => {
          setShowPilotFuelPriceModal(false);
        }}
        maxWidth="md"
        body={
          <PilotFuelPricesModal
            modal={showPilotFuelPriceModal}
            toggle={() => {
              setShowPilotFuelPriceModal(false);
            }}
          />
        }
      />
      <ModalWindow
        title="Loves fuel prices modal"
        modal={showLovesFuelPriceModal}
        handleModal={() => {
          setShowLovesFuelPriceModal(false);
        }}
        maxWidth="md"
        body={
          <LovesFuelPricesModal
            modal={showLovesFuelPriceModal}
            toggle={() => {
              setShowLovesFuelPriceModal(false);
            }}
          />
        }
      />
      <Box
        sx={{
          backgroundColor: '#000',
          height: 'calc(100vh)',
          py: 1,
        }}
      >
        <Loading loading={loading} />
        <SweetAlert
          title="Are you sure?"
          warning
          show={deleteAlert}
          showCancel
          reverseButtons
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          onConfirm={() => {
            deleteUnit(deletingId);
          }}
          onCancel={() => setDeleteAlert(false)}
        >
          You won`t be able to revert this!
        </SweetAlert>
        <Card
          className="overflow-hidden agGrid-card"
          style={{ marginRight: 8 }}
        >
          <div className="d-flex justify-content-between align-items-center mt-2 mx-2 mb-1">
            <h3 className="mb-0">All fuel list</h3>
            <div style={{ display: 'flex' }}>
              <Button
                size="sm"
                color="success"
                className="d-flex align-items-center"
                type="button"
                onClick={() => {
                  setShowBookModal(true);
                }}
              >
                New Book
              </Button>
            </div>
          </div>
          <div className="d-flex align-items-center mx-2 mb-1">
            <TextField
              fullWidth
              size="small"
              id="search"
              label="Search"
              variant="outlined"
              onChange={(e) => setSearch(e.target.value)}
            />
            <IconButton
              color="primary"
              aria-label="filter"
              onClick={() => {
                setFilterIsShow(!isFilterShow);
              }}
            >
              <FilterListIcon />
            </IconButton>
          </div>
          <div className={isFilterShow ? 'align-items-center mb-2 mx-2' : ''}>
            <AnimatePresence className={classes.filters}>
              {isFilterShow ? (
                <AnimationDiv>
                  <FilterTools
                    deleted={deleted}
                    setDeleted={setDeleted}
                    ownedCompanies={ownedCompanies}
                    setCurrentEmployerId={setCurrentEmployerId}
                    setUnitStatusId={setUnitStatusId}
                    unitStatuses={unitStatuses}
                  />
                </AnimationDiv>
              ) : null}
            </AnimatePresence>
          </div>
          <CardBody className="py-0 no-pagination">
            <div className="w-100 ag-grid-table with-pagination">
              <Table
                data={data}
                headers={headers}
                toolTips={
                  decodedToken.role === 'ROLE_ADMIN' ||
                  decodedToken.role === 'SUPER_ADMIN'
                    ? toolTipsAdmin
                    : toolTipsUser
                }
                onChange={handleOnTableChange}
                modal={handleModal}
                deleteModal={nominateToDelete}
                doubleClick={doubleClick}
                columns={columns}
                setColumns={setColumns}
                // hidePagination={1}
              />
            </div>
            <br />
          </CardBody>
        </Card>
      </Box>
    </>
  );
}
