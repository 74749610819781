import { service } from '.';

export default {
  getAll: (query) => service.get(`/trailer_general/list/idles?${query}`),
  getOne: (id) => service.get(`/trailer_idles/${id}`),
  getExcelFile: () => service.get(`/trailer_general/idles/get_excel`),
  getOneByTrailerId: (query) =>
    service.get(`/trailer_idles/byTrailerId?${query}`),
  getIdleDays: () => service.get(`/trailer_general/idles/bar_data`),
};
