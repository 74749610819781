/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import {
  // Form,
  Form,
  Button,
  FormGroup,
  Input,
  CustomInput,
  Col,
  Card,
  CardBody,
  CardHeader,
  // Button
} from 'reactstrap';
import * as Icon from 'react-feather';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { toast, Flip } from 'react-toastify';
import AsyncSelect from 'react-select/async';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import OfficeModal from '../Components/officeModal';
import companyService from '../../../../services/companies';
import fileUploadService from '../../../../services/file_upload';
import locationService from '../../../../services/locations';

export default function EditCompany() {
  const [states, setStates] = useState([]);
  const [customerTypes, setCustomerTypes] = useState([]);
  const [logoFileId, setLogoFileId] = useState(null);
  const [stateProvince, setStateProvince] = useState(null);
  const [defaultStateProvince, setDefaultStateProvince] = useState(null);
  const [showOfficeModal, setShowOfficeModal] = useState(false);
  const [offices, setOffices] = useState([]);
  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  const newCompany = () => {
    const data = {
      id,
      alternatePhone: document.querySelector('#alternatePhone').value,
      alternatePhoneExtensionNumber: document.querySelector(
        '#alternatePhoneExtensionNumber'
      ).value,
      aptSuiteOther: document.querySelector('#aptSuiteOther').value,
      companyName: document.querySelector('#companyName').value,
      contact: document.querySelector('#contact').value,
      customerTypeId: parseInt(
        document.querySelector('#customerType').value,
        10
      ),
      email: document.querySelector('#email').value,
      fax: document.querySelector('#fax').value,
      motorCarrierNumber: document.querySelector('#motorCarrierNumber').value,
      notes: document.querySelector('#notes').value,
      phoneExtensionNumber: document.querySelector('#phoneExtensionNumber')
        .value,
      phoneNumber: document.querySelector('#phoneNumber').value,
      locationId: stateProvince,
      street: document.querySelector('#street').value,
      taxId: document.querySelector('#taxId').value,
      webSite: document.querySelector('#webSite').value,
      zipCode: document.querySelector('#zipCode').value,
    };
    companyService
      .update(data)
      .then((res) => {
        if (res) {
          toast.success('Company successfuly edited', {
            transition: Flip,
          });
          window.history.back();
        } else {
          toast.error('Something went wrong', { transition: Flip });
          // res.text();
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const onDrop = (pictureFiles, pictureDataURLs) => {
    if (pictureFiles.length === 0) {
      return;
    }
    const formData = new FormData();
    formData.append('file', pictureFiles[0], pictureFiles[0].name);

    fileUploadService
      .upload('/file/upload', formData)
      .then((data) => setLogoFileId(data))
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const updateOffices = () => {
    companyService
      .getOffices(id)
      .then((data) => {
        setOffices(data.offices);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const componentDidMount = () => {
    companyService
      .getContext()
      .then((data) => {
        setCustomerTypes(data.customer_types);
        setStates(data.state_province);

        companyService.getOne(id).then((data) => {
          setLoading(false);

          document.querySelector('#alternatePhone').value = data.alternatePhone;
          document.querySelector('#alternatePhoneExtensionNumber').value =
            data.alternatePhoneExtensionNumber;
          document.querySelector('#aptSuiteOther').value = data.aptSuiteOther;
          document.querySelector('#companyName').value = data.companyName;
          document.querySelector('#contact').value = data.contact;
          document.querySelector('#customerType').value = data.customerTypeId;
          document.querySelector('#email').value = data.email;
          document.querySelector('#fax').value = data.fax;
          document.querySelector('#motorCarrierNumber').value =
            data.motorCarrierNumber;
          document.querySelector('#notes').value = data.notes;
          document.querySelector('#phoneExtensionNumber').value =
            data.phoneExtensionNumber;
          document.querySelector('#phoneNumber').value = data.phoneNumber;
          document.querySelector('#street').value = data.street;
          document.querySelector('#taxId').value = data.taxId;
          document.querySelector('#webSite').value = data.webSite;
          document.querySelector('#zipCode').value = data.zipCode;
          setStateProvince(data.locationId);

          if (data.locationId) {
            locationService.getOne(data.locationId).then((data) => {
              setDefaultStateProvince({
                value: data.id,
                label: data.nameWithParentAnsi,
              });
            });
          }
        });
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });

    updateOffices();
  };

  const deleteOffice = (id) => {
    companyService
      .delete(id)
      .then((res) => {
        if (res) {
          updateOffices();
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const toggleOfficeModal = () => {
    setShowOfficeModal(!showOfficeModal);
  };

  const addOffice = (stateProvinceId, city, id) => {
    const array = [...offices];
    const obj = {
      stateProvinceId,
      city,
      id,
    };
    array.push(obj);
    setOffices(array);

    toggleOfficeModal();
  };

  const loadOptions = (inputValue, callback) => {
    const req = { search: inputValue };
    locationService
      .getSearch(inputValue)
      .then((data) =>
        callback(
          data.data.map((el) => {
            return {
              value: el.id,
              label: el.nameWithParentAnsi,
            };
          })
        )
      )
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  useEffect(() => {
    localStorage.setItem('pageIndex', 0);
    componentDidMount();
  }, []);

  return (
    <>
      <Card>
        <CardHeader>
          <h3 className="mb-0">Edit Customer</h3>
        </CardHeader>
        <CardBody className="d-flex">
          {loading ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 44 }} spin />}
              style={{
                height: 'calc(100vh - 20rem)',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            />
          ) : (
            <>
              <div style={{ marginRight: 20, flex: 1 }}>
                <Form>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Company Name</span>
                      <span style={{ color: 'red' }}> *</span>
                    </Col>
                    <Col md="8">
                      <Input
                        type="text"
                        id="companyName"
                        onChange={(e) => {
                          document.querySelector('#companyName').value =
                            e.target.value.toUpperCase();
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Customer Type</span>
                    </Col>
                    <Col md="8">
                      <CustomInput
                        type="select"
                        name="select"
                        id="customerType"
                      >
                        {customerTypes.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </CustomInput>
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Street</span>
                    </Col>
                    <Col md="8">
                      <Input type="text" id="street" />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Apt/Suite/Other</span>
                    </Col>
                    <Col md="8">
                      <Input type="text" id="aptSuiteOther" />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>City</span>
                      <span style={{ color: 'red' }}> *</span>
                    </Col>
                    <Col md="8">
                      <AsyncSelect
                        noOptionsMessage={(value) =>
                          !value.inputValue
                            ? 'type something to search'
                            : 'nothing to show'
                        }
                        placeholder="Search"
                        isClearable={true}
                        value={defaultStateProvince}
                        loadOptions={loadOptions}
                        onChange={(value) => {
                          if (value !== null) {
                            setStateProvince(value.value);
                            setDefaultStateProvince(value);
                          } else {
                            setStateProvince(null);
                            setDefaultStateProvince(null);
                          }
                        }}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary50: '#fe810b',
                            primary25: 'rgb(253, 179, 46)',
                            primary: 'rgb(253, 179, 46)',
                          },
                        })}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Zip Code</span>
                    </Col>
                    <Col md="8">
                      <Input type="text" id="zipCode" />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Phone Number</span>
                    </Col>
                    <Col md="8">
                      <Input type="text" id="phoneNumber" />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Phone Extension number</span>
                    </Col>
                    <Col md="8">
                      <Input type="text" id="phoneExtensionNumber" />
                    </Col>
                  </FormGroup>
                  <FormGroup className="align-items-center" row>
                    <Col md="4">
                      <span>Alternate Phone</span>
                    </Col>
                    <Col md="8">
                      <Input type="text" id="alternatePhone" />
                    </Col>
                  </FormGroup>
                </Form>
                <Button
                  color="success"
                  className="d-flex align-items-center"
                  type="button"
                  onClick={() => newCompany()}
                >
                  <Icon.Check size={22} /> Save
                </Button>
              </div>
              <div style={{ width: '50%' }}>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Alternate Phone Extension number</span>
                  </Col>
                  <Col md="8">
                    <Input type="text" id="alternatePhoneExtensionNumber" />
                  </Col>
                </FormGroup>

                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Fax</span>
                  </Col>
                  <Col md="8">
                    <Input type="text" id="fax" />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Email</span>
                  </Col>
                  <Col md="8">
                    <Input type="text" id="email" />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Website</span>
                  </Col>
                  <Col md="8">
                    <Input type="text" id="webSite" />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Contact</span>
                  </Col>
                  <Col md="8">
                    <Input type="text" id="contact" />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Notes</span>
                  </Col>
                  <Col md="8">
                    <Input type="textarea" id="notes" maxLength="1000" />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Motor Carrier Number</span>
                  </Col>
                  <Col md="8">
                    <Input type="text" id="motorCarrierNumber" />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Tax ID (EIN#)</span>
                  </Col>
                  <Col md="8">
                    <Input type="text" id="taxId" />
                  </Col>
                </FormGroup>
              </div>
            </>
          )}
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <div className="d-flex flex-wrap">
            {offices.map((item, i) => {
              if (i > 0) {
                return (
                  <React.Fragment key={item.id}>
                    <div
                      key={item.id}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <div className="mt-1">
                        {
                          states.find((el) => el.id === item.stateProvinceId)
                            .name
                        }
                      </div>
                      <div>{item.city}</div>
                    </div>
                    <Button
                      className="btn-icon mt-1 mr-2"
                      color="red"
                      type="button"
                      onClick={() => deleteOffice(item.id)}
                    >
                      <Icon.Trash2 />
                    </Button>
                  </React.Fragment>
                );
              }
            })}
          </div>

          <div style={{ width: '100%' }}>
            <Button
              color="success"
              className="d-flex align-items-center mt-2"
              type="button"
              onClick={() => toggleOfficeModal(null)}
            >
              New office
            </Button>
          </div>
        </CardBody>
        <OfficeModal
          modal={showOfficeModal}
          states={states}
          toggleOfficeModal={toggleOfficeModal}
          parentId={id}
          addOffice={addOffice}
        />
      </Card>
    </>
  );
}

// const mapStateToProps = (state) => {
//   return {
//     token: state.auth.login.token,
//   };
// };
// export default connect(mapStateToProps)(NewCompany);
