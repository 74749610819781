/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from 'react';
import { toast, Flip } from 'react-toastify';
import {
  Grid,
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Container,
  Typography,
  Snackbar,
  Stack,
  InputLabel,
  Divider,
  IconButton,
  TextField,
  CardMedia,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Loading from '../../../Loading';
import tripService from '../../../../../services/trips';
import fileUploadService from '../../../../../services/file_upload';
import Logo from '../../../../../assets/img/logo/lbs.png';

export default function ShowModal({ modal, toggleModal, handleChange, item }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [bolFile, setBolFile] = useState();
  const [rightSide, setRightSide] = useState();
  const [leftSide, setLeftSide] = useState();
  const [frontSide, setFrontSide] = useState();
  const [backSideOpened, setBackSideOpened] = useState();
  const [backSideClosed, setBackSideClosed] = useState();

  const getFile = (id, file) => {
    fileUploadService
      .download('/file/image/' + id)
      .then((image) => {
        if (file === 'bolFile') {
          setBolFile('data:image/png;base64,' + image);
        } else if (file === 'rightSide') {
          setRightSide('data:image/png;base64,' + image);
        } else if (file === 'leftSide') {
          setLeftSide('data:image/png;base64,' + image);
        } else if (file === 'frontSide') {
          setFrontSide('data:image/png;base64,' + image);
        } else if (file === 'backSideOpened') {
          setBackSideOpened('data:image/png;base64,' + image);
        } else if (file === 'backSideClosed') {
          setBackSideClosed('data:image/png;base64,' + image);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const loadData = () => {
    setLoading(true);
    tripService
      .getOne(
        item.tripId === null || item.tripId === undefined
          ? item.id
          : item.tripId
      )
      .then((res) => {
        setData(res);
        if (res.driverPickupUploads !== null) {
          if (
            res.driverPickupUploads[0].values.bolFile !== null &&
            res.driverPickupUploads[0].values.bolFile !== undefined
          ) {
            getFile(res.driverPickupUploads[0].values.bolFile, 'bolFile');
          }
          if (
            res.driverPickupUploads[0].values.rightSide !== null &&
            res.driverPickupUploads[0].values.rightSide !== undefined
          ) {
            getFile(res.driverPickupUploads[0].values.rightSide, 'rightSide');
          }
          if (
            res.driverPickupUploads[0].values.frontSide !== null &&
            res.driverPickupUploads[0].values.frontSide !== undefined
          ) {
            getFile(res.driverPickupUploads[0].values.frontSide, 'frontSide');
          }
          if (
            res.driverPickupUploads[0].values.leftSide !== null &&
            res.driverPickupUploads[0].values.leftSide !== undefined
          ) {
            getFile(res.driverPickupUploads[0].values.leftSide, 'leftSide');
          }
          if (
            res.driverPickupUploads[0].values.backSideOpened !== null &&
            res.driverPickupUploads[0].values.backSideOpened !== undefined
          ) {
            getFile(
              res.driverPickupUploads[0].values.backSideOpened,
              'backSideOpened'
            );
          }
          if (
            res.driverPickupUploads[0].values.backSideClosed !== null &&
            res.driverPickupUploads[0].values.backSideClosed !== undefined
          ) {
            getFile(
              res.driverPickupUploads[0].values.backSideClosed,
              'backSideClosed'
            );
          }
        }
        console.log(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const approveLoad = (status) => {
    console.log(item);
    setLoading(true);
    const req = {
      driverOneId: item.driverOneId,
      driverTwoId: item.driverTwoId,
      tripId:
        item.tripId === null || item.tripId === undefined
          ? item.id
          : item.tripId,
      approve: status,
    };
    tripService
      .updateApprove(req)
      .then((res) => {
        loadData();

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  useEffect(() => {
    loadData();
  }, [modal]);

  return (
    <>
      <Loading loading={loading} />
      <Grid container spacing={1}>
        <Grid item xs={4} md={4} sm={4}>
          <Card sx={{ marginRight: 1, marginBottom: 1 }}>
            <CardContent>
              <Typography variant="h5" color="textPrimary">
                Unit number: {data.truckNumber}
              </Typography>
              <Typography variant="h5" color="textPrimary">
                Driver accept:{' '}
                {data.driverAccept ? (
                  <CheckCircleIcon style={{ color: 'green' }} />
                ) : (
                  <HighlightOffIcon style={{ color: 'red' }} />
                )}
              </Typography>
              <Typography variant="h5" color="textPrimary">
                Approve:{' '}
                {data.driverApprove ? (
                  <IconButton
                    color="primary"
                    aria-label="approve"
                    component="label"
                    onClick={() => {
                      approveLoad(false);
                      //  if (
                      //    decodedToken.role === 'ROLE_ADMIN' ||
                      //    decodedToken.role === 'SUPER_ADMIN'
                      //  ) {
                      //    setEditStatus(false);
                      //  }
                    }}
                  >
                    <CheckCircleIcon style={{ color: 'green' }} />
                  </IconButton>
                ) : (
                  <IconButton
                    color="primary"
                    aria-label="approve"
                    component="label"
                    onClick={() => {
                      approveLoad(true);
                      //  if (
                      //    decodedToken.role === 'ROLE_ADMIN' ||
                      //    decodedToken.role === 'SUPER_ADMIN'
                      //  ) {
                      //    setEditStatus(false);
                      //  }
                    }}
                  >
                    <HighlightOffIcon style={{ color: 'red' }} />
                  </IconButton>
                )}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={12} sm={12}>
          <Card sx={{ marginRight: 1, marginBottom: 1 }}>
            <Typography variant="h3" color="textPrimary" mt={2} ml={2}>
              Trailer photo
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={4} md={4} sm={4}>
                <CardContent>
                  <Typography variant="h5" color="textPrimary">
                    Bol file
                  </Typography>
                  <CardMedia
                    component="img"
                    style={{
                      width: 210,
                      height: 210,
                      borderRadius: 5,
                      objectFit: 'fill',
                    }}
                    image={bolFile || Logo}
                    alt=""
                  />
                </CardContent>
              </Grid>
              <Grid item xs={4} md={4} sm={4}>
                <CardContent>
                  <Typography variant="h5" color="textPrimary">
                    Front side
                  </Typography>
                  <CardMedia
                    component="img"
                    style={{
                      width: 210,
                      height: 210,
                      borderRadius: 5,
                      objectFit: 'fill',
                    }}
                    image={frontSide || Logo}
                    alt=""
                  />
                </CardContent>
              </Grid>
              <Grid item xs={4} md={4} sm={4}>
                <CardContent>
                  <Typography variant="h5" color="textPrimary">
                    Right side
                  </Typography>
                  <CardMedia
                    component="img"
                    style={{
                      width: 210,
                      height: 210,
                      borderRadius: 5,
                      objectFit: 'fill',
                    }}
                    image={rightSide || Logo}
                    alt=""
                  />
                </CardContent>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={4} md={4} sm={4}>
                <CardContent>
                  <Typography variant="h5" color="textPrimary">
                    Left side
                  </Typography>
                  <CardMedia
                    component="img"
                    style={{
                      width: 210,
                      height: 210,
                      borderRadius: 5,
                      objectFit: 'fill',
                    }}
                    image={leftSide || Logo}
                    alt=""
                  />
                </CardContent>
              </Grid>
              <Grid item xs={4} md={4} sm={4}>
                <CardContent>
                  <Typography variant="h5" color="textPrimary">
                    Back side opened
                  </Typography>
                  <CardMedia
                    component="img"
                    style={{
                      width: 210,
                      height: 210,
                      borderRadius: 5,
                      objectFit: 'fill',
                    }}
                    image={backSideOpened || Logo}
                    alt=""
                  />
                </CardContent>
              </Grid>
              <Grid item xs={4} md={4} sm={4}>
                <CardContent>
                  <Typography variant="h5" color="textPrimary">
                    Back side closed
                  </Typography>
                  <CardMedia
                    component="img"
                    style={{
                      width: 210,
                      height: 210,
                      borderRadius: 5,
                      objectFit: 'fill',
                    }}
                    image={backSideClosed || Logo}
                    alt=""
                  />
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
