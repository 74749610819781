/* eslint-disable react/jsx-indent */
import React, { useState, useEffect, useMemo } from 'react';
import { Card, CardBody, Button } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { TextField, Box } from '@mui/material';
import * as Icon from 'react-feather';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Spin } from 'antd';
import { toast, Flip } from 'react-toastify';
import { LoadingOutlined } from '@ant-design/icons';
import SweetAlert from '../../../../components/main/SweetAlert';
import stateProvinceService from '../../../../services/state_province';
import ownedCompanyService from '../../../../services/owned_company';
import { setLoading } from '../../../../redux/modules/dashboard/actions';
import { fetchData } from '../../../../redux/modules/owned_company/actions';
import { useQuery } from '../../useQuery';
import Loading from '../../../../components/main/Loading';
import Table from '../../../../components/main/table';
import { companyHeader } from '../../../../redux/constants/table/headers';
import { headerMaker } from '../../../../components/main/table/helper';
import { toolTips } from './helper';

export default function Companies() {
  const {
    query,
    search,
    status,
    pageIndex,
    pageSize,
    setPageIndex,
    setPageSize,
    setSearch,
    setStatus,
    setRefresh,
    handleOnTableChange,
  } = useQuery({ fetchData });

  const navigate = useNavigate();

  const [deletingId, setDeletingId] = useState(null);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [states, setStates] = useState([]);
  const [modal, setModal] = useState(false);

  const headers = useMemo(() => headerMaker(companyHeader), [companyHeader]);

  const { data, loading, total } = useSelector(
    (state) => state.companiesReducer
  );
  localStorage.setItem('total_data', total);

  const createHeaders = (headerData) => {
    const cData = headerData.map((item) => ({
      item,
      // ref: useRef(),
    }));
    // console.log(cData);

    // setColumns(cData);

    return cData;
  };

  const [columns, setColumns] = useState(createHeaders(headers));

  // state = {
  //   loading: false,
  //   paginationPageSize: 1000000,
  //   defaultColDef: {
  //     sortable: true,
  //     resizable: true,
  //     // suppressMenu: true,
  //     // wrapText: true,
  //     autoHeight: true,
  //     floatingFilter: true,
  //     tooltipValueGetter: (params) => {
  //       return params.value;
  //     },
  //   },
  //   columnDefs: [
  //     {
  //       headerName: 'Name',
  //       field: 'name',
  //       minWidth: 100,
  //       filter: 'agTextColumnFilter',
  //       flex: 2,
  //     },
  //     {
  //       headerName: 'Abbreviation',
  //       field: 'abbreviation',
  //       minWidth: 100,
  //       filter: 'agTextColumnFilter',
  //       flex: 1,
  //     },
  //     {
  //       headerName: 'State',
  //       field: 'state',
  //       minWidth: 100,
  //       filter: false,
  //       flex: 1,
  //     },
  //     {
  //       headerName: 'City',
  //       field: 'city',
  //       minWidth: 100,
  //       filter: false,
  //       flex: 1,
  //     },
  //     {
  //       maxWidth: 120,
  //       minWidth: 120,
  //       // flex: 1,
  //       headerName: 'Actions',
  //       field: 'actions',
  //       sortable: false,
  //       editable: false,
  //       suppressMenu: false,
  //       cellRendererFramework: (params) => {
  //         return (
  //           <div>
  //             <Link to={`/companies/edit/${params.data.id}`}>
  //               <Icon.Edit
  //                 className="icon-button ml-1"
  //                 size={20}
  //                 color="darkgray"
  //               />
  //             </Link>
  //             <Icon.Delete
  //               onClick={() => this.nominateToDelete(params.data.id)}
  //               className="icon-button ml-1"
  //               size={20}
  //               color="darkgray"
  //             />
  //           </div>
  //         );
  //       },
  //     },
  //   ],
  // };
  const nominateToDelete = (id) => {
    setDeletingId(id);
    setDeleteAlert(true);
  };

  const updateInfo = () => {
    ownedCompanyService
      .getAllCompany('sort=id,DESC&size=400')
      .then((data) => {
        const dataToShow = [];
        data.forEach((el, i) => {
          // eslint-disable-next-line array-callback-return, consistent-return
          const stateId = states.filter((obj) => {
            if (el.initialLocation !== null) {
              return obj.id === el.stateProvinceId;
            }
          });

          const elToShow = {
            id: el.id,
            state: stateId[0] === undefined ? '' : stateId[0].name,
            name: el.name,
            city: el.city,
            abbreviation: el.abbreviation,
          };
          dataToShow.push(elToShow);
        });
        // setData(dataToShow);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const deleteCompany = () => {
    ownedCompanyService
      .delete(deletingId)
      .then((res) => {
        setRefresh(true);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
    setDeletingId(null);
    setDeleteAlert(false);
  };

  const componentDidMount = () => {
    setLoading(true);

    stateProvinceService
      .getAll()
      .then((data) => {
        setStates(data);
        updateInfo();
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const doubleClick = (id) => {
    navigate(`/companies/edit/${id}`);
  };

  useEffect(() => {
    // componentDidMount();
  }, []);

  const handleModal = () => {
    setModal(!modal);
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 44 }} spin />;

  return (
    <>
      <Helmet>
        <title> Companies </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: '#000',
          height: 'calc(100vh - 6.5rem)',
          py: 1,
        }}
      >
        <Loading loading={loading} />
        <SweetAlert
          title="Are you sure?"
          warning
          show={deleteAlert}
          showCancel
          reverseButtons
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          onConfirm={() => {
            deleteCompany(deletingId);
          }}
          onCancel={() => setDeleteAlert(false)}
        >
          You won`t be able to revert this!
        </SweetAlert>
        <Card
          className="overflow-hidden agGrid-card"
          style={{ marginRight: 8 }}
        >
          <div className="d-flex justify-content-between mt-2 mx-2 mb-1">
            <h3>All companies list</h3>
            <div>
              <Link
                to="/companies/new"
                className="d-flex align-items-center text-white"
              >
                <Button
                  color="success"
                  className="d-flex align-items-center"
                  type="button"
                  size="sm"
                >
                  <Icon.Plus size={20} /> Add new company
                </Button>
              </Link>
            </div>
          </div>
          <CardBody className="py-0 no-pagination">
            <div className="w-100 ag-grid-table with-pagination">
              <Table
                data={data}
                headers={headers}
                toolTips={toolTips}
                onChange={handleOnTableChange}
                modal={handleModal}
                deleteModal={nominateToDelete}
                doubleClick={doubleClick}
                columns={columns}
                setColumns={setColumns}
                // hidePagination={1}
              />
            </div>
          </CardBody>
        </Card>
      </Box>
    </>
  );
}
