import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import {
  Box,
  CardMedia,
  colors,
  FormHelperText,
  Switch,
  CircularProgress,
  Modal,
} from '@mui/material';
import jwtDecode from 'jwt-decode';
import { styled, useTheme } from '@mui/material/styles';
import { toast, ToastContainer, Flip } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import Cropper from 'react-cropper';
import usersApi from '../../../../services/users';
import fileApi from '../../../../services/file_upload';
import { setProfileImage } from '../../../../redux/modules/store/actions';
import styles from './style.module.css';
import { useStyles } from './style';
import UploadIcon from '../../../../assets/icons/upload.svg';
import ModalWindow from '../../../../components/main/Modals';
import Loading from '../../../../components/main/Loading';
import ResetPasswordModal from './Modals/ResetPassword';

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const theme = useTheme();
  const language = useSelector((state) => state.langsReducer.language);
  const { state } = useLocation();
  const { t } = useTranslation();
  const [previewImg, setPreviewImg] = useState();
  const [photo, setPhoto] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [image, setImage] = useState('');
  const [id, setId] = useState();
  const [roleId, setRoleId] = useState();
  const [username, setUsername] = useState();
  const [cropData, setCropData] = useState('#');
  const [cropper, setCropper] = useState();
  const [openCrop, setOpenCrop] = useState(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    alignItems: 'center',
    justifyContent: 'right',
    borderRadius: 2,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  // const localeMap = {
  //   en: enLocale,
  //   uz: uzLocale,
  //   ru: ruLocale,
  // };

  const token = localStorage.getItem(
    localStorage.getItem('current_account') + '_access_token'
  );
  const decodedToken = jwtDecode(token);

  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required(t('required')),
    phone: Yup.string().required(t('required')),
    email: Yup.string().required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      full_name: '',
      phone: '',
      username: '',
      email: '',
    },
    validationSchema,
    onSubmit: (values) => {
      if (photo !== '') {
        const photoData = new FormData();
        photoData.append('file', photo);

        fileApi
          .upload('/file/upload/user_photo', photoData)
          .then((res) => {
            dispatch(setProfileImage(new Date()));

            formik.setSubmitting(false);
          })
          .catch((err) => {
            formik.setSubmitting(false);
            console.log(err);
          });
      }

      // const data = new FormData();

      // data.append('name', values.full_name);
      // data.append('phone', values.phone);
      // data.append('email', values.email);

      const data = {
        name: values.full_name,
        phone: values.phone,
        email: values.email,
      };

      usersApi
        .updateUserInfo(data)
        .then((res) => {
          setSnackbarMessage('Personal info updated successfully');
          setOpen(true);
          formik.setSubmitting(false);
        })
        .catch((err) => {
          formik.setSubmitting(false);
          console.log(err);
        });
    },
  });

  const imgSelectHandler = (e) => {
    if (e.target.files.length !== 0) {
      setImage(URL.createObjectURL(e.target.files[0]));
      // setPreviewImg(URL.createObjectURL(e.target.files[0]));
      setPhoto(e.target.files[0]);
      setOpenCrop(!openCrop);
    }
  };

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      setCropData(cropper.getCroppedCanvas().toDataURL());
      setPreviewImg(cropper.getCroppedCanvas().toDataURL());
      cropper.getCroppedCanvas().toBlob((blob) => {
        setPhoto(
          new File([blob], 'image.png', {
            type: 'image/png',
            lastModified: new Date().getTime(),
          })
        );
      });
    }
  };

  const handleCropModal = () => {
    setOpenCrop(!openCrop);
  };

  const loadingData = () => {
    usersApi
      .getProfileData()
      .then((res) => {
        setId(res.id);
        setRoleId(res.role);
        setUsername(res.username);
        formik.setValues({
          ...formik.values,
          full_name: res.name,
          phone: res.phone,
          username: res.username,
          email: res.email,
        });
        if (res.photo) {
          fileApi
            .download('/file/image/' + res.photo)
            .then((image) => {
              setPreviewImg('data:image/png;base64,' + image);
            })
            .catch((err) => {
              toast.error(err.response.data.message, {
                transition: Flip,
              });
            });
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    loadingData();
  }, [state]);

  const renderButton = (state, isSubmitting) => {
    if (state) {
      if (isSubmitting) {
        return t('Updating');
      }
      return t('Update');
    }
    if (isSubmitting) {
      return t('Submitting');
    }
    return t('Submit');
  };

  const [showResetPassword, setShowResetPassword] = useState(false);

  const toggle = () => {
    setShowResetPassword(!showResetPassword);
  };

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        // minHeight: '100vh',
        p: 3,
      }}
    >
      <Loading loading={loading} />
      <ModalWindow
        title="Reset password"
        modal={showResetPassword}
        handleModal={toggle}
        maxWidth="sm"
        body={<ResetPasswordModal toggle={toggle} />}
      />
      <Container>
        <Modal hideBackdrop open={openCrop} onClose={handleCropModal}>
          <Box sx={{ ...style }}>
            <h2 id="child-modal-title">{t('Edit image')}</h2>
            <div style={{ width: '100%' }}>
              <Cropper
                style={{ height: 400, width: '100%' }}
                zoomTo={0}
                initialAspectRatio={1}
                preview=".img-preview"
                src={image}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={true}
              />
            </div>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
                py: 2,
              }}
            >
              <Button
                sx={{
                  backgroundColor: '#CFE0F0',
                  color: '#161616',
                  textTransform: 'none',
                }}
                variant="contained"
                onClick={() => handleCropModal()}
              >
                {t('Cancel')}
              </Button>
              <Button
                sx={{
                  backgroundColor: '#2A455F',
                  // color: colors.white,
                  textTransform: 'none',
                }}
                name="add"
                variant="contained"
                type="submit"
                color="primary"
                startIcon={
                  isSubmitting ? <CircularProgress size="0.9rem" /> : undefined
                }
                onClick={() => {
                  getCropData();
                  handleCropModal();
                }}
              >
                {t('Ready')}
              </Button>
            </Box>
          </Box>
        </Modal>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <MuiAlert
              elevation={6}
              // ref={ref}
              variant="filled"
              onClose={handleClose}
              severity="success"
              sx={{ width: '100%' }}
            >
              {snackbarMessage}
            </MuiAlert>
          </Snackbar>
        </Stack>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} md={6} sm={6}>
            <Typography variant="h2" color="textPrimary" gutterBottom mt={2}>
              {t('Personal informations')}
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md={3} xs={12}>
            <Box
              sx={{
                py: 2,
              }}
            >
              <CardMedia
                component="img"
                style={{
                  width: 210,
                  height: 210,
                  borderRadius: 5,
                  objectFit: 'fill',
                }}
                image={previewImg || UploadIcon}
                alt=""
              />
              <input
                id="selectFile"
                type="file"
                accept=".png,.jpg"
                style={{ display: 'none' }}
                onChange={imgSelectHandler.bind(this)}
              />
              <Box
                sx={{
                  py: 0.5,
                }}
              />
              <Button
                type="file"
                fullWidth
                size="small"
                variant="contained"
                sx={{
                  width: 210,
                  textTransform: 'none',
                  fontSize: 20,
                  transition: 'all 0.3s ease-in-out',
                  backgroundColor: '#2A455F',
                  '&:hover': {
                    backgroundColor: '#2A455F',
                    opacity: 0.8,
                  },
                }}
                color="error"
                onClick={() => {
                  document.getElementById('selectFile').click();
                }}
              >
                {previewImg ? t('Change') : t('Select image')}
              </Button>
            </Box>
          </Grid>

          <Grid item md={9} xs={12}>
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} sm={6}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    // color={colors.grey[700]}
                  >
                    {t('Full name')}
                  </Typography>
                  <TextField
                    className={classes.search}
                    name="full_name"
                    fullWidth
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    placeholder={t('enter full name')}
                    value={formik.values.full_name}
                    error={
                      formik.touched.full_name &&
                      Boolean(formik.errors.full_name)
                    }
                  />
                  <FormHelperText
                    sx={
                      {
                        // color: colors.red[700],
                      }
                    }
                  >
                    {formik.errors.full_name &&
                      formik.touched.full_name &&
                      formik.errors.full_name}
                  </FormHelperText>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={12} md={6} sm={6}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    // color={colors.grey[700]}
                  >
                    {t('Username')}
                  </Typography>
                  <TextField
                    disabled={true}
                    className={classes.search}
                    name="username"
                    fullWidth
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    // placeholder={t('enter phone')}
                    value={formik.values.username}
                    error={
                      formik.touched.username && Boolean(formik.errors.username)
                    }
                  />
                  {/* <FormHelperText
                    sx={
                      {
                        // color: colors.red[700],
                      }
                    }
                  >
                    {formik.errors.phone &&
                      formik.touched.phone &&
                      formik.errors.phone}
                  </FormHelperText> */}
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    // color={colors.grey[700]}
                  >
                    {t('Phone')}
                  </Typography>
                  <TextField
                    className={classes.search}
                    name="phone"
                    fullWidth
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    placeholder={t('enter phone')}
                    value={formik.values.phone}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                  />
                  <FormHelperText
                    sx={
                      {
                        // color: colors.red[700],
                      }
                    }
                  >
                    {formik.errors.phone &&
                      formik.touched.phone &&
                      formik.errors.phone}
                  </FormHelperText>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} md={12} sm={6}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    // color={colors.grey[700]}
                  >
                    {t('Email')}
                  </Typography>
                  <TextField
                    className={classes.search}
                    name="email"
                    fullWidth
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    placeholder={t('enter email')}
                    value={formik.values.email}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                  />
                  <FormHelperText
                    sx={
                      {
                        // color: colors.red[700],
                      }
                    }
                  >
                    {formik.errors.email &&
                      formik.touched.email &&
                      formik.errors.email}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} sm={6}>
                  <Button
                    sx={{
                      backgroundColor: '#CFE0F0',
                      color: '#161616',
                      textTransform: 'none',
                    }}
                    variant="contained"
                    onClick={() => {
                      if (
                        formik.values.email === null ||
                        formik.values.email === undefined ||
                        formik.values.email === ''
                      ) {
                        toast.error('Please, enter email and save.', {
                          transition: Flip,
                        });
                      } else {
                        setLoading(true);
                        usersApi
                          .sendConfirmationCode({ email: formik.values.email })
                          .then((res) => {
                            console.log(res);
                            toast.success(res, {
                              transition: Flip,
                            });
                            setShowResetPassword(true);
                            setLoading(false);
                          })
                          .catch((err) => {
                            setLoading(false);
                            console.log(err);
                          });
                      }
                    }}
                  >
                    {t('Change password')}
                  </Button>
                </Grid>
              </Grid>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: 2,
                  py: 2,
                }}
              >
                <Button
                  sx={{
                    backgroundColor: '#2A455F',
                    // color: colors.white,
                    textTransform: 'none',
                  }}
                  name="add"
                  variant="contained"
                  type="submit"
                  color="primary"
                  onClick={() => console.log()}
                  startIcon={
                    formik.isSubmitting ? (
                      <CircularProgress size="0.9rem" />
                    ) : undefined
                  }
                >
                  {renderButton(decodedToken, formik.isSubmitting)}
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
    </Box>
  );
};
