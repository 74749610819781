import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  CustomInput,
  Col,
} from 'reactstrap';
import { toast, Flip } from 'react-toastify';
import AsyncSelect from 'react-select/async';
import locationService from '../../../../../services/locations';
import companyService from '../../../../../services/companies';
import fileUploadService from '../../../../../services/file_upload';

export default function NewCompany({ modal, toggleNewCompany }) {
  const [shipper, setShipper] = useState(null);
  const [shipperName, setShipperName] = useState('');
  const [states, setStates] = useState([]);
  const [customerTypes, setCustomerTypes] = useState([]);
  const [stateProvince, setStateProvince] = useState(null);

  const componentDidMount = () => {
    companyService.getContext().then((data) => {
      setCustomerTypes(data.customer_types);
      setStates(data.state_province);
    });
  };

  const newCompany = () => {
    const data = {
      alternatePhone: document.querySelector('#alternatePhone').value,
      alternatePhoneExtensionNumber:
        document.querySelector('#alternatePhone').value,
      aptSuiteOther: document.querySelector('#alternatePhone').value,
      companyName: document.querySelector('#companyName').value,
      contact: document.querySelector('#contact').value,
      customerTypeId: parseInt(
        document.querySelector('#customerType').value,
        10
      ),
      email: document.querySelector('#email').value,
      fax: document.querySelector('#fax').value,
      motorCarrierNumber: document.querySelector('#motorCarrierNumber').value,
      notes: document.querySelector('#notes').value,
      phoneExtensionNumber: document.querySelector('#phoneExtensionNumber')
        .value,
      phoneNumber: document.querySelector('#phoneNumber').value,
      locationId: stateProvince,
      street: document.querySelector('#street').value,
      taxId: document.querySelector('#taxId').value,
      webSite: document.querySelector('#webSite').value,
      zipCode: document.querySelector('#zipCode').value,
    };
    companyService
      .create(data)
      .then((res) => {
        if (res) {
          toast.success('Company successfuly added', {
            transition: Flip,
          });
        } else {
          toast.error('Something went wrong', { transition: Flip });
          // res.text();
        }
        toggleNewCompany();
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const loadOptions = (inputValue, callback) => {
    locationService.getSearch(inputValue).then((data) =>
      callback(
        data.data.map((el) => {
          return {
            value: el.id,
            label: el.nameWithParentAnsi,
          };
        })
      )
    );
  };

  useEffect(() => {
    componentDidMount();
  }, []);

  return (
    <>
      <div style={{ width: '100%' }}>
        <Form>
          <FormGroup className="align-items-center" row>
            <Col md="4">
              <span>Company Name</span>
              <span style={{ color: 'red' }}> *</span>
            </Col>
            <Col md="8">
              <Input
                type="text"
                id="companyName"
                onChange={(e) => {
                  document.querySelector('#companyName').value =
                    e.target.value.toUpperCase();
                }}
              />
            </Col>
          </FormGroup>
          <FormGroup className="align-items-center" row>
            <Col md="4">
              <span>Customer Type</span>
            </Col>
            <Col md="8">
              <CustomInput type="select" name="select" id="customerType">
                {customerTypes.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </CustomInput>
            </Col>
          </FormGroup>
          <FormGroup className="align-items-center" row>
            <Col md="4">
              <span>Street</span>
            </Col>
            <Col md="8">
              <Input type="text" id="street" />
            </Col>
          </FormGroup>
          <FormGroup className="align-items-center" row>
            <Col md="4">
              <span>Apt/Suite/Other</span>
            </Col>
            <Col md="8">
              <Input type="text" id="aptSuiteOther" />
            </Col>
          </FormGroup>
          <FormGroup className="align-items-center" row>
            <Col md="4">
              <span>City</span>
              <span style={{ color: 'red' }}> *</span>
            </Col>
            <Col md="8">
              <AsyncSelect
                noOptionsMessage={(value) =>
                  !value.inputValue
                    ? 'type something to search'
                    : 'nothing to show'
                }
                placeholder="Search"
                isClearable={true}
                defaultValue={null}
                loadOptions={loadOptions}
                onChange={(value) => {
                  if (value !== null) {
                    setStateProvince(value.value);
                  } else {
                    setStateProvince(null);
                  }
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary50: '#fe810b',
                    primary25: 'rgb(253, 179, 46)',
                    primary: 'rgb(253, 179, 46)',
                  },
                })}
              />
            </Col>
          </FormGroup>
          <FormGroup className="align-items-center" row>
            <Col md="4">
              <span>Zip Code</span>
            </Col>
            <Col md="8">
              <Input type="text" id="zipCode" />
            </Col>
          </FormGroup>
          <FormGroup className="align-items-center" row>
            <Col md="4">
              <span>Phone Number</span>
            </Col>
            <Col md="8">
              <Input type="text" id="phoneNumber" />
            </Col>
          </FormGroup>
          <FormGroup className="align-items-center" row>
            <Col md="4">
              <span>Phone Extension number</span>
            </Col>
            <Col md="8">
              <Input type="text" id="phoneExtensionNumber" />
            </Col>
          </FormGroup>
          <FormGroup className="align-items-center" row>
            <Col md="4">
              <span>Alternate Phone</span>
            </Col>
            <Col md="8">
              <Input type="text" id="alternatePhone" />
            </Col>
          </FormGroup>
        </Form>
      </div>
      <div style={{ width: '100%' }}>
        <FormGroup className="align-items-center" row>
          <Col md="4">
            <span>Alternate Phone Extension number</span>
          </Col>
          <Col md="8">
            <Input type="text" id="alternatePhoneExtensionNumber" />
          </Col>
        </FormGroup>

        <FormGroup className="align-items-center" row>
          <Col md="4">
            <span>Fax</span>
          </Col>
          <Col md="8">
            <Input type="text" id="fax" />
          </Col>
        </FormGroup>
        <FormGroup className="align-items-center" row>
          <Col md="4">
            <span>Email</span>
          </Col>
          <Col md="8">
            <Input type="text" id="email" />
          </Col>
        </FormGroup>
        <FormGroup className="align-items-center" row>
          <Col md="4">
            <span>Website</span>
          </Col>
          <Col md="8">
            <Input type="text" id="webSite" />
          </Col>
        </FormGroup>
        <FormGroup className="align-items-center" row>
          <Col md="4">
            <span>Contact</span>
          </Col>
          <Col md="8">
            <Input type="text" id="contact" />
          </Col>
        </FormGroup>
        <FormGroup className="align-items-center" row>
          <Col md="4">
            <span>Notes</span>
          </Col>
          <Col md="8">
            <Input type="textarea" id="notes" maxLength="1000" />
          </Col>
        </FormGroup>
        <FormGroup className="align-items-center" row>
          <Col md="4">
            <span>Motor Carrier Number</span>
          </Col>
          <Col md="8">
            <Input type="text" id="motorCarrierNumber" />
          </Col>
        </FormGroup>
        <FormGroup className="align-items-center" row>
          <Col md="4">
            <span>Tax ID (EIN#)</span>
          </Col>
          <Col md="8">
            <Input type="text" id="taxId" />
          </Col>
        </FormGroup>
        <FormGroup className="align-items-center" row>
          <Col md="10">
            <span hidden>s</span>
          </Col>
          <Col md="2">
            <Button color="primary" onClick={() => newCompany()}>
              Accept
            </Button>
          </Col>
        </FormGroup>
      </div>
    </>
  );
}
