import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Card, CardBody } from 'reactstrap';
import jwtDecode from 'jwt-decode';
import { CardHeader, Grid, TextField, Box } from '@mui/material';
import { toast, Flip } from 'react-toastify';
import '../../../assets/scss/pages/users.scss';
import Loading from '../../../components/main/Loading';

export default function Instruction() {
  const [loading, setLoading] = useState(true);

  const token = localStorage.getItem(
    localStorage.getItem('current_account') + '_access_token'
  );
  const decodedToken = jwtDecode(token.replace('Bearer ', ''));

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      <Helmet>
        <title> Instruction </title>
      </Helmet>

      <Loading loading={loading} />

      <Box
        sx={{
          backgroundColor: '#000',
          height: 'calc(100vh - 6.5rem)',
          py: 1,
        }}
      >
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <Card style={{ marginRight: 8 }}>
              <CardHeader
                titleTypographyProps={{ variant: 'h4' }}
                title="Instructions"
              />
              <CardBody>
                <div className="users-page-view-table">
                  <div className="d-flex align-items-center mb-1">
                    <TextField
                      fullWidth
                      size="small"
                      id="search"
                      label="Search"
                      variant="outlined"
                      onChange={(e) => {
                        // setSearch(e.target.value)
                      }}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
