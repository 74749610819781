import React, {
  useState,
  forwardRef,
  useEffect,
  useRef,
  useImperativeHandle,
} from 'react';

const MakeSelector = forwardRef((props, ref) => {
  const options = ['0', '0.25', '0.5', '0.75', '1'];

  const [make, setMake] = useState(props.value);
  const [editing, setEditing] = useState(true);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return make;
      },
    };
  });

  const handleMakeChange = (e) => {
    console.log('from handleMakeChange');
    setMake(e.target.value);
    setEditing(false);
  };

  useEffect(() => {
    if (!editing) {
      console.log('stop editing');
      props.api.stopEditing();
    }
  }, [editing]);

  return (
    <select
      className="w-100"
      onChange={(e) => handleMakeChange(e)}
      // className="browser-default custom-select"
    >
      {options.map((make) => (
        <option key={make} value={make}>
          {make}
        </option>
      ))}
    </select>
  );
});

export { MakeSelector };
