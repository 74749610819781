export default {
  TABLE_TRAILER_REPAIRS_FETCH_STATUSES: 'TABLE_TRAILER_REPAIRS_FETCH_STATUSES',
  TABLE_TRAILER_REPAIRS_SET_DATA: 'TABLE_TRAILER_REPAIRS_SET_DATA',
  TABLE_TRAILER_REPAIRS_SET_TOTAL: 'TABLE_TRAILER_REPAIRS_SET_TOTAL',
  TABLE_TRAILER_REPAIRS_SET_LOADING: 'TABLE_TRAILER_REPAIRS_SET_LOADING',
  TABLE_TRAILER_REPAIRS_ERROR: 'TABLE_TRAILER_REPAIRS_ERROR',
  TABLE_TRAILER_REPAIRS_FETCH_DATA: 'TABLE_TRAILER_REPAIRS_FETCH_DATA',
  TABLE_TRAILER_REPAIRS_SAGA_ONSEARCH_ACTION:
    'TABLE_TRAILER_REPAIRS_SAGA_ONSEARCH_ACTION',
};
