import React, { useEffect, useRef, useState } from 'react';
import {
  Stack,
  Avatar,
  CardMedia,
  IconButton,
  Typography,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { toast, Flip } from 'react-toastify';
import fileUploadService from '../../../../services/file_upload';
import Logo from '../../../../assets/img/logo/lbs.png';

export default function Message({ refer, page, user, userId, message }) {
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (message.messageType === 'file') {
      setFileList([]);
      const list = [];
      message.files.forEach((item, i) => {
        if (
          item.fileInfo.fileType === 'image/jpeg' ||
          item.fileInfo.fileType === 'image/png' ||
          item.fileInfo.originalFileName.split('.').pop() === 'jpg' ||
          item.fileInfo.originalFileName.split('.').pop() === 'jpeg' ||
          item.fileInfo.originalFileName.split('.').pop() === 'png'
        ) {
          fileUploadService
            .download('/file/image/' + item.fileInfo.id)
            .then((resImage) => {
              const value = {
                fileId: item.fileInfo.id,
                image: true,
                data: 'data:image/png;base64,' + resImage,
              };
              // list.push(value);
              const arr = [value];
              setFileList([...list, ...arr]);
              // setImage('data:image/png;base64,' + resImage);
            });
        } else {
          const value = {
            fileId: item.fileInfo.id,
            image: false,
            data: item.fileInfo.originalFileName,
          };
          // list.push(value);
          const arr = [value];
          setFileList([...list, ...arr]);
        }
      });
      // console.log(list);
      // setFileList(list);
    }
    if (page === 0) {
      // refer.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [message]);

  const getSliceText = (item, len) => {
    const { name } = user;
    if (name === null || name === undefined || name === '') {
      return 'Unknown';
    }
    if (name.length > len) {
      return name.slice(0, len) + '...';
    }
    return name.slice(0, len);
  };

  // console.log(message);

  return (
    <div
      ref={refer}
      className={`message ${message.senderId === userId * 1 && 'owner'}`}
    >
      {message.senderId && message.senderId !== userId * 1 ? (
        <div className="messageInfo">
          <Avatar alt={getSliceText(message, 15)} src="" />
        </div>
      ) : null}
      <div className="messageContent">
        {message.messageType === 'text' ? (
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html:
                '<p>' +
                message.messageText[0].data.replace('\n', '<br>') +
                `</p> <b>${message.time}</b>`,
            }}
          />
        ) : (
          fileList.map((item, i) =>
            item.image ? (
              <CardMedia
                key={`${i + 1}`}
                component="img"
                style={{
                  width: 210,
                  height: 210,
                  borderRadius: 5,
                  objectFit: 'fill',
                }}
                image={item.data || Logo}
                alt=""
              />
            ) : (
              <div key={`${i + 1}`}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="h5"
                    color="text.primary"
                  >
                    {item.data}
                  </Typography>
                  <IconButton
                    aria-label="download"
                    size="large"
                    onClick={() => {
                      fetch(
                        `${process.env.REACT_APP_BASE_URL}/file/${item.fileId}`,
                        {
                          method: 'GET',
                          headers: {
                            Authorization:
                              'Bearer ' +
                              localStorage.getItem(
                                localStorage.getItem('current_account') +
                                  '_access_token'
                              ),
                          },
                        }
                      ).then((response) => {
                        response.blob().then((blob) => {
                          const spl = response.headers
                            .get('content-disposition')
                            .split(';');

                          const filename = spl[1]
                            .split('=')[1]
                            .replace('"', '');
                          const fileName2 = filename.replace('"', '');
                          // Creating new object of PDF file
                          const fileURL = window.URL.createObjectURL(blob);

                          const alink = document.createElement('a');
                          alink.href = fileURL;
                          alink.target = '_blank';
                          alink.download = fileName2;
                          alink.click();
                        });
                      });
                    }}
                  >
                    <DownloadIcon fontSize="inherit" />
                  </IconButton>
                </Stack>
                <Typography
                  sx={{ display: 'inline', color: 'black' }}
                  component="span"
                  variant="h6"
                  color="text.primary"
                >
                  {message.time}
                </Typography>
              </div>
            )
          )
        )}
      </div>
    </div>
  );
}
