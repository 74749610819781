import { service } from '.';

export default {
  getAllDashboard: (query) =>
    service.get(`/owned_company/all_for_dashboard?${query}`),
  getAllVisible: (query) =>
    service.get(`/owned_company/all_by_visibility?${query}`),
  getAllCompany: (query) => service.get(`/owned_company/all?${query}`),
  getOne: (id) => service.get(`/owned_company/${id}`),
  create: (data) => service.post(`/owned_company/create`, data),
  update: (data) => service.put(`/owned_company/edit`, data),
  delete: (id) => service.delete(`/owned_company/${id}`),
};
