import React, { useState, useEffect, useMemo } from 'react';
import { Card, CardBody } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { TextField, Box } from '@mui/material';
import { useSelector } from 'react-redux';
// import { Link } from "react-router-dom";
import Moment from 'react-moment';
import { Pagination, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import logService from '../../../../services/users';
import { fetchData } from '../../../../redux/modules/logs/actions';
import { useQuery } from '../../useQuery';
import Loading from '../../../../components/main/Loading';
import Table from '../../../../components/main/table';
import { logsHeader } from '../../../../redux/constants/table/headers';
import { headerMaker } from '../../../../components/main/table/helper';
import { toolTips } from './helper';

export default function Logs() {
  const {
    query,
    search,
    deleted,
    pageIndex,
    pageSize,
    setPageIndex,
    setPageSize,
    setSearch,
    setDeleted,
    handleOnTableChange,
  } = useQuery({ fetchData });

  // const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(null);

  // const [data, setData] = useState([]);
  // const [total, setTotal] = useState(1);

  const [modal, setModal] = useState(false);
  // const [total, setTotal] = useState(1);
  // const [loading, setLoading] = useState(false);

  const headers = useMemo(() => headerMaker(logsHeader), [logsHeader]);

  const { data, loading, total } = useSelector((state) => state.logsReducer);
  localStorage.setItem('total_data', total);

  const createHeaders = (headerData) => {
    const cData = headerData.map((item) => ({
      item,
      // ref: useRef(),
    }));
    // console.log(cData);

    // setColumns(cData);

    return cData;
  };

  const [columns, setColumns] = useState(createHeaders(headers));

  // state = {
  //   data: [],
  //   page: null,
  //   total: 1,
  //   loading: false,
  //   paginationPageSize: 10,
  //   defaultColDef: {
  //     sortable: true,
  //     resizable: true,
  //     suppressMenu: false,
  //     tooltip: (params) => {
  //       return params.value;
  //     },
  //   },
  //   columnDefs: [
  //     {
  //       headerName: 'Username',
  //       field: 'username',
  //       minWidth: 100,
  //       maxWidth: 300,
  //     },
  //     {
  //       headerName: 'Table name',
  //       field: 'tableName',
  //       minWidth: 100,
  //       flex: 1,
  //     },
  //     {
  //       headerName: 'Entity id',
  //       field: 'entityId',
  //       minWidth: 100,
  //       flex: 1,
  //     },
  //     {
  //       headerName: 'Action',
  //       field: 'actionType',
  //       minWidth: 100,
  //       flex: 1,
  //     },
  //     {
  //       headerName: 'Time',
  //       field: 'time',
  //       minWidth: 100,
  //       flex: 1,
  //       cellRendererFramework: (params) => {
  //         return <Moment fromNow>{params.data.timeStamp}</Moment>;
  //       },
  //     },
  //   ],
  // };

  // componentDidUpdate(prevProps, prevState) {
  //   if (this.state.page !== prevState.page) {
  //     this.setState({
  //       loading: true,
  //     });
  //     logService
  //       .getLogs(`sort=id,DESC&size=40&page=${this.state.page}`)
  //       .then((data) => {
  //         let dataToShow = [];
  //         data.content.forEach((el) => {
  //           let elToShow = {
  //             username: el.username,
  //             tableName: el.tableName,
  //             actionType: el.actionType,
  //             entityId: el.entityId,
  //             timeStamp: el.timeStamp,
  //           };
  //           dataToShow.push(elToShow);
  //         });
  //         this.setState({
  //           data: dataToShow,
  //           loading: false,
  //         });
  //       });
  //   }
  // }

  // const componentDidMount = () => {
  //   // setLoading(true);

  //   logService.getLogs('sort=id,DESC&size=40').then((data) => {
  //     const dataToShow = [];
  //     data.content.forEach((el) => {
  //       const elToShow = {
  //         username: el.username,
  //         tableName: el.tableName,
  //         actionType: el.actionType,
  //         entityId: el.entityId,
  //         timeStamp: el.timeStamp,
  //       };
  //       dataToShow.push(elToShow);
  //     });
  //     // setData(dataToShow);
  //     // setLoading(false);
  //     // setTotal(data.totalElements);
  //   });
  // };

  useEffect(() => {
    // componentDidMount();
  }, []);

  const handleModal = () => {
    setModal(!modal);
  };

  return (
    <>
      <Helmet>
        <title> Logs </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: '#000',
          height: 'calc(100vh - 6.5rem)',
          py: 1,
        }}
      >
        <Loading loading={loading} />
        <Card
          className="overflow-hidden agGrid-card"
          style={{ marginRight: 8 }}
        >
          <CardBody className="py-0 no-pagination">
            <div className="d-flex justify-content-between my-2">
              <h3>Logs</h3>
            </div>
            <div className="d-flex align-items-center mb-1 mx-0">
              <TextField
                fullWidth
                size="small"
                id="search"
                label="Search"
                variant="outlined"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="w-100 ag-grid-table with-pagination">
              <Table
                data={data}
                headers={headers}
                toolTips={toolTips}
                onChange={handleOnTableChange}
                modal={handleModal}
                columns={columns}
                setColumns={setColumns}
                // hidePagination={1}
              />
            </div>
          </CardBody>
        </Card>
      </Box>
    </>
  );
}
