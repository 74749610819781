/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import {
  Card,
  Col,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Button,
  Input,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Card as CardMui,
  CardActions as CardActionsMui,
  CardContent as CardContentMui,
  TextField,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toast, Flip } from 'react-toastify';
import truckNotesService from '../../../../services/truck_notes';
import { setRefreshPage1 } from '../../../../redux/modules/store/actions';

export default function DashboardNote({ value }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [truckId, setTruckId] = useState();
  const [notesData, setNotesData] = useState([]);
  const [note, setNote] = useState('');
  const [notesTotalElements, setNotesTotalElements] = useState();
  const [notesTotalPages, setNotesTotalPages] = useState();

  const getNotes = (truckId) => {
    truckNotesService
      .getAll(`truck_id=${truckId}&page=0&size=1000000`)
      .then((data) => {
        setNotesData(data.data.notes);
        setNotesTotalElements(data.data.totalElements);
        setNotesTotalPages(data.data.totalPages);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const addNote = () => {
    const data = {
      content: note,
      truckId,
    };
    truckNotesService
      .create(data)
      .then((data) => {
        // eslint-disable-next-line no-use-before-define
        getNotes(truckId);
        setNote('');
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const handleToggle = (id) => {
    setOpen(!open);
    setTruckId(id);
    getNotes(id);
  };

  const handleClose = () => {
    setOpen(false);
    setTruckId(null);
    setNote(null);
    setNotesData([]);
    dispatch(setRefreshPage1(true));
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      addNote();
    }
  };

  const handleChangeNote = (event) => {
    setNote(event.target.value);
  };

  return (
    <>
      <div className="notes-col">
        {!value.notes ? (
          <Button
            style={{ width: 120 }}
            className="d-flex align-items-center"
            type="button"
            onClick={() => handleToggle(value.truckId)}
          >
            Add note
          </Button>
        ) : (
          <div
            onClick={() => {
              handleToggle(value.truckId);
            }}
          >
            {value.notes}
          </div>
        )}
      </div>

      <Backdrop
        transitionDuration={350}
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open}
        id="backdrop"
        // onClick={this.handleClose}
      >
        <CardMui
          style={{ marginLeft: 'auto', height: '100%' }}
          sx={{ minWidth: 475 }}
        >
          <CardContentMui>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <CloseIcon onClick={handleClose} />
              <h1
                style={{
                  marginLeft: '15px',
                  marginBottom: 0,
                }}
              >
                Notes
              </h1>
            </div>
            <TextField
              style={{ width: '100%' }}
              value={note}
              onChange={handleChangeNote}
              onKeyPress={handleKeyPress}
              label="Add note"
              variant="standard"
            />
            <div
              style={{
                height: document.getElementById('backdrop')
                  ? parseInt(
                      document.getElementById('backdrop').clientHeight,
                      10
                    )
                  : 0 - 140 + 'px',
                overflow: 'auto',
              }}
            >
              {notesData.map((item, index) => {
                return (
                  <div
                    key={`${index + 1}`}
                    style={{
                      border: '1px solid silver',
                      borderRadius: '10px',
                      padding: '10px',
                      marginBottom: '10px',
                      marginTop: index === 0 ? '10px' : '0',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                      }}
                    >
                      <p>{item.author}</p>
                      <p>{new Date(item.postedDate).toLocaleString()}</p>
                    </div>
                    <div style={{ fontStyle: 'italic' }}>{item.content}</div>
                  </div>
                );
              })}
            </div>
          </CardContentMui>
          <CardActionsMui />
        </CardMui>
      </Backdrop>
    </>
  );
}
