import React, { useState, useMemo, useEffect } from 'react';
import { Card, CardBody, Input } from 'reactstrap';
import { Helmet } from 'react-helmet';
import {
  IconButton,
  Box,
  TextField,
  DialogActions,
  Button,
} from '@mui/material';
import * as Icon from 'react-feather';
import { toast, Flip } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { AnimatePresence } from 'framer-motion';
import FilterListIcon from '@mui/icons-material/FilterList';
import SweetAlert from '../../../../components/main/SweetAlert';
import FilterTools from '../../../../components/main/FilteredBody/Units';
import AnimationDiv from '../../../../components/main/Animations/Transition';
import taskService from '../../../../services/tasks';
import fileUploadService from '../../../../services/file_upload';
import { fetchData } from '../../../../redux/modules/tasks/actions';
import { useQuery } from '../../useQuery';
import Loading from '../../../../components/main/Loading';
import Table from '../../../../components/main/table';
import { tasksHeader } from '../../../../redux/constants/table/headers';
import { headerMaker } from '../../../../components/main/table/helper';
import { toolTipsUser, toolTipsAdmin } from './helper';
import { useStyles } from './style';
import ModalWindow from '../../../../components/main/Modals';
import HandleTask from '../Modal';

export default function Tasks() {
  const {
    query,
    search,
    deleted,
    pageIndex,
    pageSize,
    unitStatusId,
    setPageIndex,
    setPageSize,
    setSearch,
    setDeleted,
    setRefresh,
    setUnitStatusId,
    setCurrentEmployerId,
    handleOnTableChange,
  } = useQuery({ fetchData });

  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isFilterShow, setFilterIsShow] = useState(false);

  const [deleteAlert, setDeleteAlert] = useState(false);
  const [deletingId, setDeletingId] = useState(null);

  const [modal, setModal] = useState(false);

  const [taskModal, setTaskModal] = useState(false);
  const [photo, setPhoto] = useState();

  const [taskItem, setTaskItem] = useState({ fileId: '', task: { text: '' } });

  const headers = useMemo(() => headerMaker(tasksHeader), [tasksHeader]);
  const { data, loading, total, error } = useSelector(
    (state) => state.tasksReducer
  );
  localStorage.setItem('total_data', total);

  const token = localStorage.getItem(
    localStorage.getItem('current_account') + '_access_token'
  );
  const decodedToken = jwtDecode(token.replace('Bearer ', ''));

  const createHeaders = (headerData) => {
    const cData = headerData.map((item) => ({
      item,
      // ref: useRef(),
    }));
    // console.log(cData);

    // setColumns(cData);

    return cData;
  };

  const [columns, setColumns] = useState(createHeaders(headers));

  const doubleClick = (id) => {};

  const newTask = () => {
    console.log(photo);
    const formData = new FormData();
    formData.append('file', photo);
    // if (photo === undefined || photo === null) {
    //   return;
    // }
    fileUploadService
      .upload('/file/upload/task_photo', formData)
      .then((data) => {
        console.log(data);
        console.log(taskItem);
        taskItem.fileId = data;
        console.log(taskItem);
        taskService
          .create(taskItem)
          .then((data) => {
            console.log(data);
            setRefresh(true);
            setTaskModal(false);
          })
          .catch((err) => {
            toast.error(err.response.data.message, {
              transition: Flip,
            });
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  useEffect(() => {}, []);

  useEffect(() => {
    if (error !== '') {
      toast.error(error, {
        transition: Flip,
      });
    }
  }, [error]);

  return (
    <>
      <Helmet>
        <title> Tasks </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: '#000',
          height: 'calc(100vh)',
          py: 1,
        }}
      >
        <Loading loading={loading} />

        <ModalWindow
          title="Task modal"
          modal={taskModal}
          handleModal={() => {
            setTaskModal(false);
          }}
          maxWidth="md"
          body={
            <HandleTask
              modal={taskModal}
              toggle={() => {
                setTaskModal(false);
              }}
              taskItem={taskItem}
              setterTaskItem={setTaskItem}
              setterPhoto={setPhoto}
            />
          }
          dialogActions={
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                autoFocus
                onClick={() => {
                  newTask();
                }}
              >
                Accept
              </Button>
            </DialogActions>
          }
        />
        <Card
          className="overflow-hidden agGrid-card"
          style={{ marginRight: 8 }}
        >
          <div className="d-flex justify-content-between align-items-center mt-2 mx-2 mb-1">
            <h3 className="mb-0">All task list</h3>
            <div>
              <Button
                onClick={() => {
                  setTaskModal(true);
                }}
                size="sm"
                color="success"
                variant="contained"
                className="d-flex align-items-center"
                type="button"
              >
                <Icon.Plus size={20} /> Add new task
              </Button>
            </div>
          </div>
          <div className="d-flex align-items-center mx-2 mb-1">
            <TextField
              fullWidth
              size="small"
              id="search"
              label="Search"
              variant="outlined"
              onChange={(e) => setSearch(e.target.value)}
            />
            {/* <IconButton
              color="primary"
              aria-label="filter"
              onClick={() => {
                setFilterIsShow(!isFilterShow);
              }}
            >
              <FilterListIcon />
            </IconButton> */}
          </div>
          {/* <div className={isFilterShow ? 'align-items-center mb-2 mx-2' : ''}>
            <AnimatePresence className={classes.filters}>
              {isFilterShow ? (
                <AnimationDiv>
                  <FilterTools
                    deleted={deleted}
                    setDeleted={setDeleted}
                    ownedCompanies={ownedCompanies}
                    setCurrentEmployerId={setCurrentEmployerId}
                    setUnitStatusId={setUnitStatusId}
                    unitStatuses={unitStatuses}
                  />
                </AnimationDiv>
              ) : null}
            </AnimatePresence>
          </div> */}
          <CardBody className="py-0 no-pagination">
            <div className="w-100 ag-grid-table with-pagination">
              <Table
                data={data}
                headers={headers}
                toolTips={
                  decodedToken.role === 'ROLE_ADMIN' ||
                  decodedToken.role === 'SUPER_ADMIN'
                    ? toolTipsAdmin
                    : toolTipsUser
                }
                onChange={handleOnTableChange}
                modal={() => {
                  setModal(!modal);
                }}
                doubleClick={doubleClick}
                columns={columns}
                setColumns={setColumns}
                // hidePagination={1}
              />
            </div>
            <br />
          </CardBody>
        </Card>
      </Box>
    </>
  );
}
