export function dataSelector(dataResponse, page, size) {
  const getSecondDriver = (item) => {
    return item.driverTwo === '' || item.driverTwo === null
      ? ''
      : ', (' + item.driverTwo + ')';
  };
  const filtered = dataResponse.content.map((item, i) => ({
    index: i + 1 + page * size,
    serialNumber: item.serialNumber,
    carrierName: item.carrierName,
    rc: item.rc,
    shipperCompanyName: item.shipperCompanyName,
    startTime: item.startTime,
    startTimeText: item.startTimeText,
    shipperCompanyLocation: item.shipperCompanyLocation,
    endTime: item.endTime,
    endTimeText: item.endTimeText,
    endLocation: item.endLocation,
    truckNumber: item.truckNumber,
    truckCompany: item.truckCompany,
    driverOne: item.driverOne + getSecondDriver(item),
    booked: item.booked,
    dispute: item.dispute,
    detention: item.detention,
    additional: item.additional,
    fine: item.fine,
    revisedInvoice: item.revisedInvoice,
    ko: item.ko,
    amount: item.amount,
    service: item.service,
    netPaid: item.netPaid,
    loadMl: item.loadMl,
    mtMl: item.mtMl,
    team: item.team,
    note: item.note,
    data: item,
    dataLoad: item,
  }));
  return { total: dataResponse.total_elements, data: filtered };
}
