export default {
  TABLE_UNIT_STATUS_FETCH_STATUSES: 'TABLE_UNIT_STATUS_FETCH_STATUSES',
  TABLE_UNIT_STATUS_SET_DATA: 'TABLE_UNIT_STATUS_SET_DATA',
  TABLE_UNIT_STATUS_SET_TOTAL: 'TABLE_UNIT_STATUS_SET_TOTAL',
  TABLE_UNIT_STATUS_SET_ALL: 'TABLE_UNIT_STATUS_SET_ALL',
  TABLE_UNIT_STATUS_SET_LOADING: 'TABLE_UNIT_STATUS_SET_LOADING',
  TABLE_UNIT_STATUS_ERROR: 'TABLE_UNIT_STATUS_ERROR',
  TABLE_UNIT_STATUS_FETCH_DATA: 'TABLE_UNIT_STATUS_FETCH_DATA',
  TABLE_UNIT_STATUS_SAGA_ONSEARCH_ACTION:
    'TABLE_UNIT_STATUS_SAGA_ONSEARCH_ACTION',
};
