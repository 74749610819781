export const byStatus = [
  {
    name: 'Active',
    value: 0,
  },
  {
    name: 'Terminated',
    value: 1,
  },
  {
    name: 'In process',
    value: 2,
  },
];
