import React, { useState, useEffect, useMemo } from 'react';
import {
  Col,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Button,
  Input,
  Row,
  Form,
  FormGroup,
  Label,
  CardHeader,
} from 'reactstrap';
import jwtDecode from 'jwt-decode';
import { toast, Flip } from 'react-toastify';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import { LoadingOutlined } from '@ant-design/icons';
import FilterListIcon from '@mui/icons-material/FilterList';
import * as Icon from 'react-feather';
import {
  // Button,
  Card,
  Grid,
  TextField,
  Stack,
  colors,
  Typography,
  IconButton,
  Box,
} from '@mui/material';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { AnimatePresence } from 'framer-motion';
import DownloadFilterTools from '../../../../components/main/FilteredBody/TrailerRepairsDownload';
import AnimationDiv from '../../../../components/main/Animations/Transition';
import Loading from '../../../../components/main/Loading';
import Table from '../../../../components/main/table';
import { fetchData } from '../../../../redux/modules/trailer_repairs/actions';
import { trailerRepairHeader } from '../../../../redux/constants/table/headers';
import { headerMaker } from '../../../../components/main/table/helper';
import trailerRepairService from '../../../../services/trailer_repairs';
import trailerGeneralService from '../../../../services/trailer_general';
import { useQuery } from '../../useQuery';
import { toolTips } from './helper';
import { useStyles } from './style';

export default function TrailerRepairs() {
  const {
    query,
    search,
    deleted,
    trailerStatus,
    trailerIds,
    start,
    end,
    setSearch,
    setDeleted,
    setTrailerStatus,
    setTrailerIds,
    setStart,
    setEnd,
    handleOnTableChange,
  } = useQuery({ fetchData });

  const [isFilterShow, setFilterIsShow] = useState(false);

  const headers = useMemo(
    () => headerMaker(trailerRepairHeader),
    [trailerRepairHeader]
  );

  const classes = useStyles();

  const createHeaders = (headerData) => {
    const cData = headerData.map((item) => ({
      item,
      // ref: useRef(),
    }));
    // console.log(cData);

    // setColumns(cData);

    return cData;
  };

  const [columns, setColumns] = useState(createHeaders(headers));

  // const ownershipColumnDefs = [
  //   {
  //     headerName: '#',
  //     // field: "index",
  //     minWidth: 60,
  //     filter: false,
  //     flex: 1,
  //     cellStyle: function (params) {
  //       return {
  //         fontWeight: '500',
  //       };
  //     },
  //     cellRendererFramework: (params) => {
  //       return params.rowIndex + 1;
  //     },
  //   },
  //   {
  //     headerName: 'Date',
  //     field: 'date',
  //     minWidth: 150,
  //     // flex: 1,
  //     cellRendererFramework: (params) => {
  //       return (
  //         <div style={{ paddingTop: '15px' }}>
  //           {params.data && params.data.date && (
  //             <p>{getDateString(params.data.date)}</p>
  //           )}
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     headerName: 'User',
  //     field: 'username',
  //     minWidth: 150,
  //     filter: 'agTextColumnFilter',
  //     // flex: 1,
  //   },
  //   {
  //     headerName: 'Trailer Number',
  //     field: 'trailerName',
  //     minWidth: 150,
  //     filter: 'agTextColumnFilter',
  //     // flex: 1,
  //   },
  //   {
  //     headerName: 'Trailer Owner',
  //     field: 'trailerOwned',
  //     minWidth: 150,
  //     filter: 'agTextColumnFilter',
  //     flex: 1,
  //   },
  //   {
  //     headerName: 'Damage Category',
  //     field: 'issueCategory',
  //     minWidth: 150,
  //     filter: 'agTextColumnFilter',
  //     flex: 1,
  //   },
  //   {
  //     headerName: 'Amount',
  //     field: 'amount',
  //     minWidth: 150,
  //     filter: false,
  //     flex: 1,
  //   },
  //   {
  //     headerName: 'Payment method',
  //     field: 'paymentMethod',
  //     minWidth: 150,
  //     filter: 'agTextColumnFilter',
  //     flex: 1,
  //   },
  //   {
  //     headerName: 'Truck',
  //     field: 'truck',
  //     minWidth: 150,
  //     filter: 'agTextColumnFilter',
  //     flex: 1,
  //   },
  //   {
  //     headerName: 'Driver(s)',
  //     field: 'drivers',
  //     minWidth: 150,
  //     filter: 'agTextColumnFilter',
  //     flex: 1,
  //   },
  //   {
  //     headerName: 'Shop name',
  //     field: 'shopServiceName',
  //     minWidth: 160,
  //     filter: 'agTextColumnFilter',
  //     flex: 1,
  //   },
  //   {
  //     headerName: 'Number of attachments',
  //     field: 'numberAttachments',
  //     minWidth: 150,
  //     filter: false,
  //     flex: 1,
  //   },
  // ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDownloadShow, setDownloadShow] = useState(false);
  const [trailerList, setTrailerList] = useState([]);

  const [active, setActive] = useState();

  const { data, loading, total } = useSelector(
    (state) => state.trailerRepairsReducer
  );
  localStorage.setItem('total_data', total);

  const [ownershipData, setOwnershipData] = useState([]);

  const token = localStorage.getItem(
    localStorage.getItem('current_account') + '_access_token'
  );
  const decodedToken = jwtDecode(token);
  const [modal, setModal] = useState(false);

  const [tabs, setTabs] = useState([
    { id: 1, name: 'Trailers' },
    { id: 2, name: 'Repairs' },
    { id: 3, name: 'Idles' },
    { id: 4, name: 'History' },
  ]);

  const tabList = [
    '/trailers/list',
    '/trailer_repairs/list',
    '/trailer_idles/list',
    '/trailer_history/list',
  ];

  const handleModal = () => {
    setModal(!modal);
  };

  const getDateString = (date) => {
    return (
      (new Date(date).getMonth() + 1 < 10
        ? '0' + parseInt(new Date(date).getMonth() + 1, 10)
        : parseInt(new Date(date).getMonth() + 1, 10)) +
      '/' +
      (new Date(date).getDate() < 10
        ? '0' + new Date(date).getDate()
        : new Date(date).getDate()) +
      '/' +
      new Date(date).getFullYear() +
      ' ' +
      (new Date(date).getHours() < 10
        ? '0' + new Date(date).getHours()
        : new Date(date).getHours()) +
      ':' +
      (new Date(date).getMinutes() < 10
        ? '0' + new Date(date).getMinutes()
        : new Date(date).getMinutes())
    );
  };

  const checkAllow = (role) => {
    if (role === 'ROLE_ADMIN' || role === 'SUPER_ADMIN') {
      return true;
    }
    return false;
  };

  const getFile = (ids) => {
    trailerRepairService
      .getExcelFile(
        ids.length > 0
          ? 'trailerIds='
          : '' +
              ids.join(',') +
              `${start !== null ? '&start=' + start : ''}` +
              `${end !== null ? '&end=' + end : ''}`
      )
      .then((result) => {
        if (result.status === 'success') {
          window.open(result.data, '_blank');
        } else {
          toast.error(result.msg, {
            transition: Flip,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const getTrailers = () => {
    trailerGeneralService
      .getAll('size=10000')
      .then((result) => {
        setTrailerList(result.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  useEffect(() => {
    setOwnershipData(data);
  }, [data]);

  useEffect(() => {
    getTrailers();
  }, []);

  return (
    <>
      <Loading loading={loading} />
      <Card className="agGrid-card" style={{ marginRight: 8 }}>
        <br />
        <CardBody className="py-0 no-pagination">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center ">
              <Nav pills className="dashboard-tabs">
                {tabs.map((item) => (
                  <NavItem key={`${item.id}`}>
                    <NavLink
                      style={{
                        height: 38,
                        backgroundColor: item.id === 2 ? '#00FF7F' : 'white',
                      }}
                      className={classnames(
                        {
                          customActive: active === item.id,
                        },
                        'd-flex align-items-center'
                      )}
                      onClick={() => {
                        navigate(tabList[item.id - 1]);
                        // history.push(tabList[item.id - 1]);
                      }}
                    >
                      <h4 className="mb-0">{item.name}</h4>
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </div>

            {checkAllow(decodedToken.role) ? (
              <div>
                <Link
                  to="/trailers/new"
                  className="d-flex align-items-center text-white"
                >
                  <Button
                    size="sm"
                    color="primary"
                    className="d-flex align-items-center"
                    type="button"
                  >
                    <Icon.Plus size={20} /> &nbsp; Add new trailer
                  </Button>
                </Link>
              </div>
            ) : null}
          </div>
          <div className="d-flex align-items-center mt-1 mx-0 mb-1">
            <TextField
              fullWidth
              size="small"
              id="search"
              label="Search"
              variant="outlined"
              onChange={(e) => setSearch(e.target.value)}
            />
            {/* <IconButton
              color="primary"
              aria-label="filter"
              onClick={() => {
                setDownloadShow(false);
                setFilterIsShow(!isFilterShow);
              }}
            >
              <FilterListIcon />
            </IconButton> */}
            <IconButton
              color="primary"
              aria-label="download2"
              onClick={() => {
                setFilterIsShow(false);
                setDownloadShow(!isDownloadShow);
              }}
            >
              <DownloadForOfflineIcon />
            </IconButton>
          </div>
          <div className={isDownloadShow ? 'align-items-center mb-2 mx-0' : ''}>
            <AnimatePresence>
              {isDownloadShow ? (
                <AnimationDiv>
                  <DownloadFilterTools
                    startTime={start}
                    endTime={end}
                    setStartTime={setStart}
                    setEndTime={setEnd}
                    trailerList={trailerList}
                    setTrailerIds={setTrailerIds}
                    trailerStatus={trailerStatus}
                    setTrailerStatus={setTrailerStatus}
                    getFile={getFile}
                  />
                </AnimationDiv>
              ) : null}
            </AnimatePresence>
          </div>

          <div className="w-100 align-items-center">
            <Table
              data={ownershipData}
              headers={headers}
              toolTips={toolTips}
              onChange={handleOnTableChange}
              modal={handleModal}
              columns={columns}
              setColumns={setColumns}
              // hidePagination={1}
            />
          </div>
          <br />
        </CardBody>
      </Card>
    </>
  );
}
