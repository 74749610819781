import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  CustomInput,
  Col,
} from 'reactstrap';
import * as Icon from 'react-feather';
import Flatpickr from 'react-flatpickr';
// import 'flatpickr/dist/themes/material_blue.css';
import AsyncSelect from 'react-select/async';
import { toast, Flip } from 'react-toastify';
import moment from 'moment';
import pickupService from '../../../../../services/pickup';
import companyService from '../../../../../services/companies';
import fileUploadService from '../../../../../services/file_upload';

export default function PickupModal({
  modal,
  disabled,
  canBeChanged,
  className,
  editingChildId,
  addPickup,
  togglePickupModal,
  loadOptions,
  toggleNewCompany,
}) {
  const [shipper, setShipper] = useState(null);
  const [shipperName, setShipperName] = useState('');
  const [shipperSelected, setShipperSelected] = useState(null);
  const [bolId, setBolId] = useState(null);
  const [pickupDate_, setPickupDate_] = useState();
  const [pickupDateWithOffset, setPickupDateWithOffset] = useState(null);
  const [prevBolId, setPrevBolId] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [searchVal, setSearchVal] = useState(null);

  // const companySearch = (val) => {
  //   if (val) {
  //     setSearchVal(val);

  //     companyService
  //       .getSearch(val)
  //       .then((data) => {
  //         if (val === searchVal) {
  //           const dataToShow = [];
  //           data.forEach((el) => {
  //             const elToShow = {
  //               value: el.id,
  //               label:
  //                 el.companyName +
  //                 ', ' +
  //                 (el.cityDto && el.cityDto.nameWithParentAnsi),
  //             };
  //             dataToShow.push(elToShow);
  //           });
  //           setCompanies(dataToShow);
  //         }
  //       })
  //       .catch((err) => {
  //         toast.error(err.response.data.message, {
  //           transition: Flip,
  //         });
  //       });
  //   } else {
  //     setCompanies([]);
  //   }
  // };

  // const componentDidUpdate (prevProps) {
  //   if (
  //     this.props.modal !== prevProps.modal &&
  //     this.props.modal &&
  //     this.props.disabled &&
  //     this.props.editingChildId
  //   ) {
  //     pickupService
  //       .getOne(this.props.editingChildId)
  //       .then((data) => {
  //         let selectedValue = {
  //           value: data.shipperCompanyId,
  //           label: data.shipperCompany,
  //         };
  //         // let d = new Date();
  //         // let utc = data.pickupDate_ + d.getTimezoneOffset() * 60000;
  //         // let nd = utc + 3600000 * 5;

  //         this.setState({
  //           shipperSelected: selectedValue,
  //           pickupDate_: data.pickupDate_,
  //           pickupDateWithOffset: data.pickupDate_,
  //         });
  //         this.shipperChange(selectedValue);
  //         document.querySelector('#driverInstructions').value =
  //           data.driverInstructions;
  //         document.querySelector('#bol').value = data.bol;
  //         document.querySelector('#customerRequiredInfo').value =
  //           data.customRequiredInfo;
  //         document.querySelector('#weight').value = data.weight;
  //         document.querySelector('#quantity').value = data.quantity;
  //         document.querySelector('#notes').value = data.notes;
  //         document.querySelector('#commodity').value = data.commodity;

  //         if (data.bolId) {
  //           this.setState({ prevBolId: data.bolId });
  //         } else {
  //           this.setState({ prevBolId: null });
  //         }
  //       })
  //       .catch((err) => {
  //         toast.error(err.response.data.message, {
  //           transition: Flip,
  //         });
  //       });
  //   } else if (
  //     this.props.modal !== prevProps.modal &&
  //     this.props.modal &&
  //     !this.props.editingChildId
  //   ) {
  //     this.setState({
  //       shipper: null,
  //       shipperName: '',
  //       shipperSelected: null,
  //       bolId: null,
  //       pickupDate_: null,
  //       pickupDateWithOffset: null,
  //     });
  //     setTimeout(() => {
  //       document.querySelector('#pickupDate').value = '';
  //       document.querySelector('#driverInstructions').value = '';
  //       document.querySelector('#bol').value = '';
  //       document.querySelector('#customerRequiredInfo').value = '';
  //       document.querySelector('#weight').value = '';
  //       document.querySelector('#quantity').value = '';
  //       document.querySelector('#notes').value = '';
  //       document.querySelector('#commodity').value = '';
  //     }, 300);
  //   }
  // }

  const deletePrevBol = () => {
    setBolId(null);
    setPrevBolId(null);
  };

  const newPickup = () => {
    const sendingData = {
      shipperCompanyId: shipper,
      pickupDate_: pickupDateWithOffset,
      driverInstructions: document.querySelector('#driverInstructions').value,
      bol: document.querySelector('#bol').value,
      customRequiredInfo: document.querySelector('#customerRequiredInfo').value,
      weight: parseInt(document.querySelector('#weight').value, 10),
      quantity: parseInt(document.querySelector('#quantity').value, 10),
      notes: document.querySelector('#notes').value,
      commodity: document.querySelector('#commodity').value,
      bolId: prevBolId || bolId,
    };

    if (editingChildId !== null && editingChildId !== undefined) {
      sendingData.id = editingChildId;
      pickupService
        .update(sendingData)
        .then((res) => {
          if (!res) {
            throw new Error(res);
          }
          return res;
        })
        .then((data) => {
          pickupService.getResolved(sendingData.pickupDate_).then((time) => {
            toast.success('Pickup successfuly edited', {
              transition: Flip,
            });
            addPickup(shipperName, time, editingChildId);
          });
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            transition: Flip,
          });
          // toast.error("Something went wrong", { transition: Flip });
          return Promise.reject();
        });
    } else {
      pickupService
        .create(sendingData)
        .then((res) => {
          if (!res) {
            throw new Error(res);
          }
          return res;
        })
        .then((data) => {
          pickupService.getResolved(sendingData.pickupDate_).then((time) => {
            toast.success('Pickup successfuly added', {
              transition: Flip,
            });
            addPickup(shipperName, time, data.id, data.has_attachment);
          });
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            transition: Flip,
          });
          // toast.error("Something went wrong", { transition: Flip });
          return Promise.reject();
        });
    }
  };

  const uploadBol = (file, item) => {
    const formData = new FormData();
    formData.append('file', file);
    if (file === undefined) {
      return;
    }
    fileUploadService
      .upload('/file/upload', formData)
      .then((data) => {
        setBolId(data);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const shipperChange = (value) => {
    if (value == null) {
      setShipper(null);
      setShipperName('');
      setShipperSelected(null);
    } else {
      setShipper(value.value);
      setShipperName(value.label);
      setShipperSelected(value);
    }
  };

  // const parseDate = (dateString, format) => {
  //   console.log(dateString, format);
  //   const timeZone = 'America/Chicago';
  //   const timezonedDate = new moment.tz(dateString, format, timeZone);

  //   return timezonedDate.toDate();
  // };

  useEffect(() => {
    if (editingChildId) {
      pickupService
        .getOne(editingChildId)
        .then((data) => {
          const selectedValue = {
            value: data.shipperCompanyId,
            label: data.shipperCompany,
          };
          // let d = new Date();
          // let utc = data.pickupDate_ + d.getTimezoneOffset() * 60000;
          // let nd = utc + 3600000 * 5;
          setShipperSelected(selectedValue);
          setPickupDateWithOffset(data.pickupDate_);
          setPickupDate_(data.pickupDate_);

          shipperChange(selectedValue);
          document.querySelector('#driverInstructions').value =
            data.driverInstructions;
          document.querySelector('#bol').value = data.bol;
          document.querySelector('#customerRequiredInfo').value =
            data.customRequiredInfo;
          document.querySelector('#weight').value = data.weight;
          document.querySelector('#quantity').value = data.quantity;
          document.querySelector('#notes').value = data.notes;
          document.querySelector('#commodity').value = data.commodity;

          if (data.bolId) {
            setPrevBolId(data.bolId);
          } else {
            setPrevBolId(null);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            transition: Flip,
          });
        });
    } else {
      setShipper(null);
      setShipperName('');
      setShipperSelected(null);
      setBolId(null);
      setPickupDate_(null);
      setPickupDateWithOffset(null);

      setTimeout(() => {
        document.querySelector('#pickupDate').value = '';
        document.querySelector('#driverInstructions').value = '';
        document.querySelector('#bol').value = '';
        document.querySelector('#customerRequiredInfo').value = '';
        document.querySelector('#weight').value = '';
        document.querySelector('#quantity').value = '';
        document.querySelector('#notes').value = '';
        document.querySelector('#commodity').value = '';
      }, 300);
    }
  }, [editingChildId]);

  return (
    <ModalBody>
      <Form>
        <FormGroup className="align-items-center" row>
          <Col md="4">
            <span>Shipper</span>
            <span style={{ color: 'red' }}> *</span>
          </Col>
          <Col md={disabled ? '6' : '8'} style={{ zIndex: 10000 }}>
            <Input
              disabled={true}
              style={{
                display: !disabled ? '' : 'none',
              }}
              value={shipperSelected ? shipperSelected.label : {}}
              onChange={(e) => {}}
            />
            <div
              style={{
                display: !disabled ? 'none' : '',
              }}
            >
              <AsyncSelect
                disabled={!disabled}
                noOptionsMessage={(value) =>
                  !value.inputValue
                    ? 'type something to search'
                    : 'nothing to show'
                }
                placeholder="Search"
                isClearable={true}
                defaultValue={null}
                loadOptions={loadOptions}
                value={shipperSelected}
                onChange={shipperChange}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary50: '#fe810b',
                    primary25: 'rgb(253, 179, 46)',
                    primary: 'rgb(253, 179, 46)',
                  },
                })}
              />
            </div>
          </Col>
          {disabled ? (
            <Col md="2 pl-0">
              <Button
                size="sm"
                color="success"
                type="button"
                onClick={() => toggleNewCompany()}
              >
                <Icon.Plus size={22} />
              </Button>
            </Col>
          ) : null}
        </FormGroup>
        <FormGroup className="align-items-center" row>
          <Col md="4">
            <span>Pickup Date</span>
          </Col>
          <Col md="8">
            <Flatpickr
              disabled={!disabled}
              data-input
              id="pickupDate"
              className="form-control"
              data-enable-time
              options={{
                enableTime: true,
                dateFormat: 'Z',
                altInput: true,
                allowInput: true,
                altFormat: 'm-d-Y H:i',
                clickOpens: canBeChanged,
                // parseDate,
              }}
              value={pickupDate_}
              onChange={(e, date, fbo) => {
                setPickupDate_(Date.parse(e[0]));
                setPickupDateWithOffset(e[0].getTime());
                // fbo.close();
              }}
            />
          </Col>
        </FormGroup>

        <FormGroup className="align-items-center" row>
          <Col md="4">
            <span>Driver Instructions</span>
          </Col>
          <Col md="8">
            <Input disabled={!disabled} type="text" id="driverInstructions" />
          </Col>
        </FormGroup>

        <FormGroup className="align-items-center" row>
          <Col md="4">
            <span>BOL</span>
          </Col>
          <Col md="8">
            <Input disabled={!disabled} type="text" id="bol" />
          </Col>
        </FormGroup>
        <FormGroup className="align-items-center" row>
          <Col md="4">
            <span>BOL File</span>
          </Col>

          {prevBolId ? (
            <>
              <Col md="8">
                <div className="d-flex align-items-center justify-content-end">
                  <Button
                    style={{ width: 225 }}
                    className="mt-1"
                    type="button"
                    // href={`${process.env.REACT_APP_BASE_URL}/file/${this.state.prevBolId}`}
                    onClick={() => {
                      fetch(
                        `${process.env.REACT_APP_BASE_URL}/file/${prevBolId}`,
                        {
                          method: 'GET',
                          headers: {
                            Authorization:
                              'Bearer ' +
                              localStorage.getItem(
                                localStorage.getItem('current_account') +
                                  '_access_token'
                              ),
                          },
                        }
                      ).then((response) => {
                        response.blob().then((blob) => {
                          const spl = response.headers
                            .get('content-disposition')
                            .split(';');

                          const filename = spl[1]
                            .split('=')[1]
                            .replace('"', '');
                          const fileName2 = filename.replace('"', '');
                          // Creating new object of PDF file
                          const fileURL = window.URL.createObjectURL(blob);
                          // Setting various property values
                          const alink = document.createElement('a');
                          alink.href = fileURL;
                          alink.target = '_blank';
                          alink.download = fileName2;
                          alink.click();
                        });
                      });
                      // window.open(
                      //     `${process.env.REACT_APP_BASE_URL}/file/${this.state.prevBolId}`,
                      //     "_blank"
                      // )
                    }}
                  >
                    Download BOL file
                  </Button>
                  <Button
                    className="btn-icon mt-1"
                    color="red"
                    type="button"
                    onClick={() => deletePrevBol()}
                  >
                    <Icon.Trash2 />
                  </Button>
                </div>
              </Col>
            </>
          ) : (
            <Col md="8">
              <CustomInput
                disabled={!disabled}
                id="file"
                type="file"
                onInput={(e) => uploadBol(e.target.files[0])}
              />
            </Col>
          )}
        </FormGroup>

        <FormGroup className="align-items-center" row>
          <Col md="4">
            <span>Customer Required Info</span>
          </Col>
          <Col md="8">
            <Input disabled={!disabled} type="text" id="customerRequiredInfo" />
          </Col>
        </FormGroup>
        <FormGroup className="align-items-center" row>
          <Col md="4">
            <span>Weight</span>
          </Col>
          <Col md="8">
            <Input disabled={!disabled} type="text" id="weight" />
          </Col>
        </FormGroup>
        <FormGroup className="align-items-center" row>
          <Col md="4">
            <span>Quantity</span>
          </Col>
          <Col md="8">
            <Input disabled={!disabled} type="text" id="quantity" />
          </Col>
        </FormGroup>
        <FormGroup className="align-items-center" row>
          <Col md="4">
            <span>Notes</span>
          </Col>
          <Col md="8">
            <Input disabled={!disabled} type="text" id="notes" />
          </Col>
        </FormGroup>
        <FormGroup className="align-items-center" row>
          <Col md="4">
            <span>Commodity</span>
          </Col>
          <Col md="8">
            <Input disabled={!disabled} type="text" id="commodity" />
          </Col>
        </FormGroup>
        <FormGroup className="align-items-right" row>
          <Button
            disabled={!disabled}
            color="primary"
            onClick={() => newPickup()}
          >
            Accept
          </Button>
        </FormGroup>
      </Form>
    </ModalBody>
  );
}
