import React, { useState, useMemo, useEffect } from 'react';
import { Card, CardBody, Input, Button } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { IconButton, Box, TextField } from '@mui/material';
import * as Icon from 'react-feather';
import { toast, Flip } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { AnimatePresence } from 'framer-motion';
import FilterListIcon from '@mui/icons-material/FilterList';
import SweetAlert from '../../../../components/main/SweetAlert';
import FilterTools from '../../../../components/main/FilteredBody/Units';
import AnimationDiv from '../../../../components/main/Animations/Transition';
import unitService from '../../../../services/units';
import stateProvinceService from '../../../../services/state_province';
import ownedCompanyService from '../../../../services/owned_company';
import { fetchData } from '../../../../redux/modules/units/actions';
import { useQuery } from '../../useQuery';
import UnitStatusModal from '../Modals/UnitStatusModal';
import OwnershipTypeModal from '../Modals/OwnershipTypeModal';
import ModalWindowFullScreen from '../../../../components/main/Modals/FullScreen';
import Loading from '../../../../components/main/Loading';
import Table from '../../../../components/main/table';
import { unitsHeader } from '../../../../redux/constants/table/headers';
import { setRefreshPage } from '../../../../redux/modules/store/actions';
import { headerMaker } from '../../../../components/main/table/helper';
import { toolTipsUser, toolTipsAdmin } from './helper';
import { useStyles } from './style';

export default function Units() {
  const {
    query,
    search,
    deleted,
    pageIndex,
    pageSize,
    unitStatusId,
    setPageIndex,
    setPageSize,
    setSearch,
    setDeleted,
    setRefresh,
    setUnitStatusId,
    setCurrentEmployerId,
    handleOnTableChange,
  } = useQuery({ fetchData });

  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isFilterShow, setFilterIsShow] = useState(false);

  const [deleteAlert, setDeleteAlert] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [states, setStates] = useState([]);
  const [modal, setModal] = useState(false);
  const [unitStatusModal, setUnitStatusModal] = useState(false);
  const [ownershipTypeModal, setOwnershipTypeModal] = useState(false);
  const [unitStatuses, setUnitStatuses] = useState([]);
  const [ownedCompanies, setOwnedCompanies] = useState([]);

  const headers = useMemo(() => headerMaker(unitsHeader), [unitsHeader]);
  const { data, loading, total, error } = useSelector(
    (state) => state.unitsReducer
  );
  localStorage.setItem('total_data', total);

  const { refreshPage } = useSelector((state) => state.storeReducer);

  const token = localStorage.getItem(
    localStorage.getItem('current_account') + '_access_token'
  );
  const decodedToken = jwtDecode(token.replace('Bearer ', ''));

  const createHeaders = (headerData) => {
    const cData = headerData.map((item) => ({
      item,
      // ref: useRef(),
    }));
    // console.log(cData);

    // setColumns(cData);

    return cData;
  };

  const [columns, setColumns] = useState(createHeaders(headers));

  // state = {

  //   states: [],
  //   loading: false,
  //   paginationPageSize: 100000,
  //   defaultColDef: {
  //     sortable: true,
  //     resizable: true,
  //     // suppressMenu: false,
  //     // wrapText: true,
  //     autoHeight: true,
  //     floatingFilter: true,
  //     tooltipValueGetter: (params) => {
  //       return params.value;
  //     },
  //   },
  //   page: null,
  //   total: 1,
  //   columnDefs: [
  //     {
  //       headerName: '#',
  //       field: 'index',
  //       maxWidth: 70,
  //       filter: false,
  //       flex: 1,
  //       cellStyle: function (params) {
  //         return {
  //           fontWeight: '500',
  //         };
  //       },
  //     },
  //     {
  //       headerName: 'Unit Number',
  //       field: 'number',
  //       minWidth: 160,
  //       filter: 'agTextColumnFilter',
  //       flex: 1,
  //     },
  //     {
  //       headerName: 'VIN',
  //       field: 'vin',
  //       minWidth: 170,
  //       filter: 'agTextColumnFilter',
  //       flex: 1,
  //     },
  //     {
  //       headerName: 'Type',
  //       field: 'type',
  //       filter: false,
  //       minWidth: 100,
  //       flex: 1,
  //     },
  //     {
  //       headerName: 'State',
  //       field: 'state',
  //       minWidth: 150,
  //       filter: 'agTextColumnFilter',
  //       flex: 1,
  //     },
  //     {
  //       headerName: 'Status',
  //       field: 'status',
  //       minWidth: 120,
  //       filter: false,
  //       // flex: 1,
  //       cellStyle: function (params) {
  //         return {
  //           fontSize: '13px',
  //           color: params.data.unitStatusColor ? 'white' : 'black',
  //           backgroundColor: params.data.unitStatusColor
  //             ? params.data.unitStatusColor
  //             : 'white',
  //           textTransform: 'uppercase',
  //           textAlign: 'center',
  //         };
  //       },
  //     },
  //     {
  //       headerName: 'Ownership type',
  //       field: 'ownership',
  //       minWidth: 100,
  //       filter: false,
  //       flex: 1,
  //       cellStyle: function (params) {
  //         return { textAlign: 'center' };
  //         // if (params.value == "TEAM" || params.value == "OWNER TEAM") {
  //         //   return { color: "white", backgroundColor: "#606060" };
  //         // } else {
  //         //   return { color: "white", backgroundColor: "#808080" };
  //         // }
  //       },
  //     },
  //     {
  //       headerName: 'License Expiration',
  //       field: 'licenseExpiration',
  //       minWidth: 130,
  //       filter: false,
  //       flex: 1,
  //     },
  //     {
  //       headerName: 'Inspection Exp',
  //       field: 'inspectation',
  //       minWidth: 130,
  //       filter: false,
  //       flex: 1,
  //     },
  //     {
  //       minWidth: 130,
  //       maxWidth: 130,
  //       headerName: '',
  //       field: 'actions',
  //       sortable: false,
  //       editable: false,
  //       suppressMenu: false,
  //       // flex: 1,
  //       cellRendererFramework: (params) => {
  //         return (
  //           <div>
  //             <Link to={`/unit/view/${params.data.number}`}>
  //               <Icon.Eye className="icon-button" size={20} color="darkgray" />
  //             </Link>
  //             {this.props.userRole !== 'dispatcher' &&
  //               this.props.userRole !== 'updater' && (
  //                 <Link to={`/unit/edit/${params.data.number}`}>
  //                   <Icon.Edit
  //                     className="icon-button ml-1"
  //                     size={20}
  //                     color="darkgray"
  //                   />
  //                 </Link>
  //               )}
  //             {this.props.userRole !== 'dispatcher' &&
  //               this.props.userRole !== 'updater' && (
  //                 <Icon.Delete
  //                   onClick={() => this.nominateToDelete(params.data.id)}
  //                   className="icon-button ml-1"
  //                   size={20}
  //                   color="darkgray"
  //                 />
  //               )}
  //           </div>
  //         );
  //       },
  //     },
  //   ],
  // };

  const nominateToDelete = (id) => {
    setDeletingId(id);
    setDeleteAlert(true);
  };

  const deleteUnit = () => {
    unitService.delete(`${deletingId}`).then((res) => {
      setRefresh(true);
    });
    setDeletingId(null);
    setDeleteAlert(false);
  };

  const componentDidMount = () => {
    // setLoading(true);
    stateProvinceService
      .getAll()
      .then((data) => {
        setStates(data);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const handleModal = () => {
    setModal(!modal);
  };

  const doubleClick = (id) => {
    navigate(`/units/view/${id}`);
  };

  const getUnitStatuses = () => {
    unitService
      .getContext()
      .then((data) => {
        const item = { id: 0, name: 'All' };
        data.unit_statuses.unshift(item);
        setUnitStatuses(data.unit_statuses);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const getCompanies = () => {
    ownedCompanyService
      .getAllDashboard('sort=id,DESC&size=10000')
      .then((data) => {
        const item = { id: 0, abbreviation: 'All' };
        data.unshift(item);
        setOwnedCompanies(data);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  useEffect(() => {
    getUnitStatuses();
    getCompanies();
  }, []);

  useEffect(() => {
    // localStorage.setItem('pageIndex', 0);
    if (error !== '') {
      toast.error(error, {
        transition: Flip,
      });
    }
  }, [error]);

  useEffect(() => {
    if (refreshPage !== undefined) {
      dispatch(setRefreshPage());
      setRefresh(true);
      toast.success('Status successfully edit', {
        transition: Flip,
      });
    }
  }, [refreshPage]);

  return (
    <>
      <Helmet>
        <title> Units </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: '#000',
          height: 'calc(100vh)',
          py: 1,
        }}
      >
        <Loading loading={loading} />
        <ModalWindowFullScreen
          title="Unit Status"
          modal={unitStatusModal}
          handleModal={() => {
            setUnitStatusModal(false);
          }}
          maxWidth="md"
          body={<UnitStatusModal modal={unitStatusModal} toggle={() => {}} />}
        />
        <ModalWindowFullScreen
          title="Ownership type"
          modal={ownershipTypeModal}
          handleModal={() => {
            setOwnershipTypeModal(false);
          }}
          maxWidth="md"
          body={
            <OwnershipTypeModal modal={ownershipTypeModal} toggle={() => {}} />
          }
        />
        <SweetAlert
          title="Are you sure?"
          warning
          show={deleteAlert}
          showCancel
          reverseButtons
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          onConfirm={() => {
            deleteUnit(deletingId);
          }}
          onCancel={() => setDeleteAlert(false)}
        >
          You won`t be able to revert this!
        </SweetAlert>
        <Card
          className="overflow-hidden agGrid-card"
          style={{ marginRight: 8 }}
        >
          <div className="d-flex justify-content-between mt-2 mx-2 mb-1">
            <div
              style={{
                paddingRight: '15px',
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                justifyContent: 'flex-start',
              }}
            >
              <h3 className="mb-0">All units list</h3>
            </div>
            <div
              style={{
                paddingRight: '15px',
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                justifyContent: 'flex-end',
              }}
            >
              <div>
                <Link
                  to="/units/new"
                  className="d-flex align-items-center text-white"
                >
                  <Button
                    size="sm"
                    color="success"
                    className="d-flex align-items-center"
                    type="button"
                  >
                    <Icon.Plus size={20} /> Add new unit
                  </Button>
                </Link>
              </div>
              <div>
                <Button
                  size="sm"
                  color="success"
                  className="d-flex align-items-center"
                  type="button"
                  onClick={() => {
                    setUnitStatusModal(true);
                  }}
                >
                  <Icon.List size={20} /> Unit status
                </Button>
              </div>
              <div>
                <Button
                  size="sm"
                  color="success"
                  className="d-flex align-items-center"
                  type="button"
                  onClick={() => {
                    setOwnershipTypeModal(true);
                  }}
                >
                  <Icon.Type size={20} /> Ownership type
                </Button>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center mx-2 mb-1">
            <TextField
              fullWidth
              size="small"
              id="search"
              label="Search"
              variant="outlined"
              onChange={(e) => setSearch(e.target.value)}
            />
            <IconButton
              color="primary"
              aria-label="filter"
              onClick={() => {
                setFilterIsShow(!isFilterShow);
              }}
            >
              <FilterListIcon />
            </IconButton>
          </div>
          <div className={isFilterShow ? 'align-items-center mb-2 mx-2' : ''}>
            <AnimatePresence className={classes.filters}>
              {isFilterShow ? (
                <AnimationDiv>
                  <FilterTools
                    deleted={deleted}
                    setDeleted={setDeleted}
                    ownedCompanies={ownedCompanies}
                    setCurrentEmployerId={setCurrentEmployerId}
                    setUnitStatusId={setUnitStatusId}
                    unitStatuses={unitStatuses}
                  />
                </AnimationDiv>
              ) : null}
            </AnimatePresence>
          </div>
          <CardBody className="py-0 no-pagination">
            <div className="w-100 ag-grid-table with-pagination">
              <Table
                data={data}
                headers={headers}
                toolTips={
                  decodedToken.role === 'ROLE_ADMIN' ||
                  decodedToken.role === 'SUPER_ADMIN'
                    ? toolTipsAdmin
                    : toolTipsUser
                }
                onChange={handleOnTableChange}
                modal={handleModal}
                deleteModal={nominateToDelete}
                doubleClick={doubleClick}
                columns={columns}
                setColumns={setColumns}
                // hidePagination={1}
              />
            </div>
            <br />
          </CardBody>
        </Card>
      </Box>
    </>
  );
}
