export default {
  TABLE_TRAILER_OPTIONS_FETCH_STATUSES: 'TABLE_TRAILER_OPTIONS_FETCH_STATUSES',
  TABLE_TRAILER_OPTIONS_SET_DATA: 'TABLE_TRAILER_OPTIONS_SET_DATA',
  TABLE_TRAILER_OPTIONS_SET_TOTAL: 'TABLE_TRAILER_OPTIONS_SET_TOTAL',
  TABLE_TRAILER_OPTIONS_SET_LOADING: 'TABLE_TRAILER_OPTIONS_SET_LOADING',
  TABLE_TRAILER_OPTIONS_ERROR: 'TABLE_TRAILER_OPTIONS_ERROR',
  TABLE_TRAILER_OPTIONS_FETCH_DATA: 'TABLE_TRAILER_OPTIONS_FETCH_DATA',
  TABLE_TRAILER_OPTIONS_SAGA_ONSEARCH_ACTION:
    'TABLE_TRAILER_OPTIONS_SAGA_ONSEARCH_ACTION',
};
