import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast, Flip } from 'react-toastify';
import Moment from 'react-moment';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import ClearIcon from '@mui/icons-material/Clear';
import SweetAlert from '../../SweetAlert';
import unitService from '../../../../services/units';
import { setRefreshPage } from '../../../../redux/modules/store/actions';
import ShowModal from './Modals/ShowModal';

export default ({ value }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [deleteTime, setDeleteTime] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const saveStatus = (statusId, data) => {
    unitService
      .updateStatus(value.truckId, statusId, data)
      .then(() => {
        dispatch(setRefreshPage(true));
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const clearAtHomeTime = () => {
    unitService
      .clearAtHomeTime(value.truckId, {})
      .then(() => {
        setDeleteTime(false);
        dispatch(setRefreshPage(true));
      })
      .catch((err) => {
        setDeleteTime(false);
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const handleAtHome = (atHomeDate, leaveHomeDate) => {
    const data = {
      atHomeDate,
      leaveHomeDate,
    };

    toggleModal();
    saveStatus(0, data);
  };

  return (
    <>
      <SweetAlert
        title="Are you sure?"
        warning
        show={deleteTime}
        showCancel
        reverseButtons
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        onConfirm={() => {
          clearAtHomeTime();
        }}
        onCancel={() => setDeleteTime(false)}
      >
        You won`t be able to revert this!
      </SweetAlert>
      <ShowModal
        modal={showModal}
        toggleModal={toggleModal}
        handleChange={handleAtHome}
        item={value}
      />
      {value.atHomeDate !== null && value.atHomeDate !== undefined ? (
        <div style={{ display: 'flex' }}>
          <Button
            variant="outlined"
            startIcon={<HomeIcon />}
            onClick={() => {
              toggleModal();
            }}
          >
            <Moment format="YYYY-MM-DD">{value.atHomeDate}</Moment>
          </Button>
          <IconButton
            aria-label="delete"
            onClick={() => {
              setDeleteTime(true);
            }}
          >
            <ClearIcon />
          </IconButton>
        </div>
      ) : (
        <Button
          variant="outlined"
          startIcon={<HomeIcon />}
          onClick={() => {
            toggleModal();
          }}
        >
          {null}
        </Button>
      )}
    </>
  );
};
