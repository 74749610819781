import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ModalWindowFullScreen from '../../Modals/FullScreen';
import ViewDriverModal from '../../../../views/pages/Drivers/Modals/DriverModal';

export default ({ value }) => {
  const [driverModal, setDriverModal] = useState(false);

  return (
    <>
      <ModalWindowFullScreen
        title="Driver"
        modal={driverModal}
        handleModal={() => {
          setDriverModal(!driverModal);
        }}
        body={<ViewDriverModal modal={driverModal} id={value.id} />}
      />
      <Link
        className="link-in-table"
        onClick={() => {
          setDriverModal(true);
        }}
        // to={`/drivers/view/${value.id}`}
      >
        {value.lastName + ' ' + value.firstName}
      </Link>
    </>
  );
};
