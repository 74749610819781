import React, { useState, useEffect } from 'react';
import {
  // Form,
  Form,
  Button,
  FormGroup,
  Input,
  // Label,
  CustomInput,
  // Row,
  Col,
  Card,
  CardBody,
  // CardTitle,
  CardHeader,
  // Button
} from 'reactstrap';
import * as Icon from 'react-feather';
import { connect } from 'react-redux';
import {
  // ToastContainer,
  toast,
  // Slide,
  // Zoom,
  Flip,
  // Bounce,
} from 'react-toastify';
import locationService from '../../../../services/locations';

export default function NewLocation() {
  const [states, setStates] = useState([]);
  const [timeZones, setTimeZones] = useState([]);
  const [logoFileId, setLogoFileId] = useState(null);
  const [currentState, setCurrentState] = useState(null);
  const [parentAnsi, setParentAnsi] = useState(null);

  const newLocation = () => {
    const data = {
      name: document.querySelector('#name').value,
      parentAnsi,
      parentTimeZone: document.querySelector('#timeZone').value,
    };
    locationService
      .create(data)
      .then((res) => {
        if (res) {
          toast.success('Location successfuly added', {
            transition: Flip,
          });
          window.history.back();
        } else {
          toast.error('Something went wrong', { transition: Flip });
          // res.text();
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  // componentDidUpdate(prevProps, prevState) {
  //   if (this.state.currentState !== prevState.currentState) {
  //     let state = this.state.states.find(
  //       (item) => item.id == this.state.currentState
  //     );
  //     let timeZones = [];
  //     timeZones.push(state.firstTimeZone);
  //     if (state.secondTimeZone) timeZones.push(state.secondTimeZone);
  //     this.setState({
  //       timeZones,
  //       parentAnsi: state.ansi,
  //     });
  //   }
  // }

  const componentDidMount = () => {
    locationService
      .getContext()
      .then((data) => {
        setStates(data.states);
        setCurrentState(data.states[0].id);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  useEffect(() => {
    if (states.length > 0) {
      const state = states.find((item) => item.id === currentState);
      const timeZonesData = [];
      timeZonesData.push(state.firstTimeZone);
      if (state.secondTimeZone) timeZonesData.push(state.secondTimeZone);
      setTimeZones(timeZonesData);
      setParentAnsi(state.ansi);
    }
  }, [currentState]);

  useEffect(() => {
    componentDidMount();
  }, []);

  return (
    <Card>
      <CardHeader>
        <h3 className="mb-0">Adding a new Location</h3>
      </CardHeader>
      <CardBody className="d-flex">
        <div style={{ marginRight: 20, flex: 1 }}>
          <Form>
            <FormGroup className="align-items-center" row>
              <Col md="4">
                <span>Name</span>
              </Col>
              <Col md="8">
                <Input type="text" id="name" />
              </Col>
            </FormGroup>
            <FormGroup className="align-items-center" row>
              <Col md="4">
                <span>State</span>
                <span style={{ color: 'red' }}> *</span>
              </Col>
              <Col md="8">
                <CustomInput
                  type="select"
                  name="select"
                  id="state"
                  value={currentState || ''}
                  onChange={(val) =>
                    setCurrentState(parseInt(val.target.value, 10))
                  }
                >
                  {states.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </CustomInput>
              </Col>
            </FormGroup>
            <FormGroup className="align-items-center" row>
              <Col md="4">
                <span>Time Zone</span>
              </Col>
              <Col md="8">
                <CustomInput type="select" name="select" id="timeZone">
                  {timeZones.map((item, i) => (
                    <option key={item} value={i + 1}>
                      {item}
                    </option>
                  ))}
                </CustomInput>
              </Col>
            </FormGroup>
          </Form>
          <Button
            color="success"
            className="d-flex align-items-center"
            type="button"
            onClick={() => newLocation()}
          >
            <Icon.Check size={22} /> Save location
          </Button>
        </div>
        <div style={{ width: '50%' }} />
      </CardBody>
    </Card>
  );
}

// const mapStateToProps = (state) => {
//   return {
//     token: state.auth.login.token,
//   };
// };
// export default connect(mapStateToProps)(NewLocation);
