import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  FormGroup,
  Input,
  Label,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
} from 'reactstrap';
import * as Icon from 'react-feather';
import AsyncSelect from 'react-select/async';
import { toast, Flip } from 'react-toastify';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// import { AiOutlineUnlock, AiOutlineLock } from "react-icons/ai";
import { IconButton, DialogActions } from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import jwtDecode from 'jwt-decode';
import { Link, useParams } from 'react-router-dom';
import DeliveryModal from '../Delivery';
import ActiveDelivery from '../../Components/ActiveDelivery';
import NewCompany from '../NewCompany';
import ActivePickup from '../../Components/ActivePickup';
import PickupModal from '../Pickup';
import loadService from '../../../../../services/loads';
import stateProvinceService from '../../../../../services/state_province';
import companyService from '../../../../../services/companies';
import fileUploadService from '../../../../../services/file_upload';
import countingService from '../../../../../services/counting';
import ModalWindow from '../../../../../components/main/Modals';
import ModalWindowSimple from '../../../../../components/main/Modals/Simple';

export default function LoadModal({ id }) {
  const [abbreviation, setAbbreviation] = useState('');
  const [states, setStates] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [customerSelected, setCustomerSelected] = useState(null);

  const [showPickupModal, setShowPickupModal] = useState(false);
  const [showNewCompanyModal, setShowNewCompanyModal] = useState(false);
  const [showDeliveryModal, setShowDeliveryModal] = useState(false);

  const [activePickups, setActivePickups] = useState([]);
  const [canBeChanged, setCanBeChanged] = useState(true);
  const [activeDeliveries, setActiveDeliveries] = useState([]);
  const [ownedCompanies, setOwnedCompanies] = useState([]);
  const [ownedCompany, setOwnedCompany] = useState(null);

  const [editingChildId, setEditingChildId] = useState(null);
  const [rateConfirmationId, setRateConfirmationId] = useState(null);
  const [prevRateConfirmationId, setPrevRateConfirmationId] = useState(null);
  const [revisedRateConfirmationId, setRevisedRateConfirmationId] =
    useState(null);
  const [prevRevisedRateConfirmationId, setPrevRevisedRateConfirmationId] =
    useState(null);
  const [editStatus, setEditStatus] = useState(false);
  const [employeer, setEmployeer] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchVal, setSearchVal] = useState(null);

  const token = localStorage.getItem(
    localStorage.getItem('current_account') + '_access_token'
  );
  const decodedToken = jwtDecode(token.replace('Bearer ', ''));

  const uploadFile = (file, item) => {
    const formData = new FormData();
    formData.append('file', file);
    if (file === undefined) {
      return;
    }
    fileUploadService
      .upload('/file/upload', formData)
      .then((data) => {
        if (item === 'rc') setRateConfirmationId(data);
        if (item === 'rrc') setRevisedRateConfirmationId(data);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const deletePickup = (id) => {
    const array = [...activePickups];
    array.forEach((item, i) => {
      if (array[i].id === id) {
        array.splice(i, 1);
      }
    });
    setActivePickups(array);
  };

  const deleteDelivery = (id) => {
    const array = [...activeDeliveries];
    console.log(array);
    array.forEach((item, i) => {
      if (array[i].id === id) {
        array.splice(i, 1);
      }
    });
    setActiveDeliveries(array);
  };

  const newLoad = () => {
    const deliveries = activeDeliveries.map((el) => el.id);
    const pickups = activePickups.map((el) => el.id);
    const data = {
      id: parseInt(id, 10),
      customLoadNumber:
        document.querySelector('#customId').value + '-' + abbreviation,
      customerId,
      deliveries,
      pickups,
      editStatus,
      ownedCompanyId: parseInt(
        document.querySelector('#owned_company').value,
        10
      ),
      rateConfirmationId: rateConfirmationId || prevRateConfirmationId,
      revisedRateConfirmationId:
        revisedRateConfirmationId || prevRevisedRateConfirmationId,
      rcPrice: document.querySelector('#rc_price').value,
      revisedRcPrice: document.querySelector('#rrc_price').value,
    };
    loadService
      .update(data)
      .then((res) => {
        if (res) {
          toast.success('Load successfuly edited', {
            transition: Flip,
          });
          window.history.back();
        } else {
          const result = res.json();
          try {
            result.then((data) => {
              toast.error(
                data.error_message
                  ? data.error_message
                  : 'Something went wrong',
                {
                  transition: Flip,
                }
              );
            });
          } catch (err) {
            console.log(err);
          }
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const newLoadOnCloseModal = () => {
    const deliveries = activeDeliveries.map((el) => el.id);
    const pickups = activePickups.map((el) => el.id);
    const data = {
      id: parseInt(id, 10),
      customLoadNumber:
        document.querySelector('#customId').value + '-' + abbreviation,
      customerId,
      deliveries,
      pickups,
      ownedCompanyId: parseInt(
        document.querySelector('#owned_company').value,
        10
      ),
      rateConfirmationId: rateConfirmationId || prevRateConfirmationId,
      revisedRateConfirmationId:
        revisedRateConfirmationId || prevRevisedRateConfirmationId,
      rcPrice: document.querySelector('#rc_price').value,
      revisedRcPrice: document.querySelector('#rrc_price').value,
    };
    loadService
      .update(data)
      .then((res) => {
        if (res) {
          toast.success('Load successfuly edited', {
            transition: Flip,
          });
        } else {
          const result = res.json();
          try {
            result.then((data) => {
              toast.error(
                data.error_message
                  ? data.error_message
                  : 'Something went wrong',
                {
                  transition: Flip,
                }
              );
            });
          } catch (err) {
            console.log(err);
          }
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const calculate = (type) => {
    const additional = document.querySelector('#additional').value
      ? parseFloat(document.querySelector('#additional').value)
      : 0;
    const booked = document.querySelector('#booked').value
      ? parseFloat(document.querySelector('#booked').value)
      : 0;
    const detention = document.querySelector('#detention').value
      ? parseFloat(document.querySelector('#detention').value)
      : 0;
    const dispute = document.querySelector('#dispute').value
      ? parseFloat(document.querySelector('#dispute').value)
      : 0;
    const factoring = document.querySelector('#factoring').value
      ? parseFloat(document.querySelector('#factoring').value)
      : 0;
    const fine = document.querySelector('#fine').value
      ? parseFloat(document.querySelector('#fine').value)
      : 0;
    const tafs = document.querySelector('#tafs').value
      ? parseFloat(document.querySelector('#tafs').value)
      : 0;

    if (type === 0) {
      const result = booked + dispute + detention + additional - fine;
      document.querySelector('#revisedInvoice').value = result;
      return;
    }
    const result = (factoring - tafs).toFixed(3);
    document.querySelector('#netPaid').value = parseFloat(result);
  };

  const postPayments = () => {
    const data = {
      loadId: id,
      additional: document.querySelector('#additional').value
        ? document.querySelector('#additional').value
        : 0,
      booked: document.querySelector('#booked').value
        ? document.querySelector('#booked').value
        : 0,
      detention: document.querySelector('#detention').value
        ? document.querySelector('#detention').value
        : 0,
      dispute: document.querySelector('#dispute').value
        ? document.querySelector('#dispute').value
        : 0,
      factoring: document.querySelector('#factoring').value
        ? document.querySelector('#factoring').value
        : 0,
      fine: document.querySelector('#fine').value
        ? document.querySelector('#fine').value
        : 0,
      netPaid: document.querySelector('#netPaid').value
        ? document.querySelector('#netPaid').value
        : 0,
      revisedInvoice: document.querySelector('#revisedInvoice').value
        ? document.querySelector('#revisedInvoice').value
        : 0,
      tafs: document.querySelector('#tafs').value
        ? document.querySelector('#tafs').value
        : 0,
    };
    countingService
      .onLoad(data)
      .then((res) => {
        if (res) {
          toast.success('Payments successfuly edited', {
            transition: Flip,
          });
        } else {
          const result = res.json();
          try {
            result.then((data) => {
              toast.error(
                data.error_message
                  ? data.error_message
                  : 'Something went wrong',
                {
                  transition: Flip,
                }
              );
            });
          } catch (err) {
            toast.error(
              data.error_message ? data.error_message : 'Something went wrong',
              {
                transition: Flip,
              }
            );
          }
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const setterAbbreviation = (e) => {
    const abbreviation = ownedCompanies.filter((item) => item.id === e * 1);
    setAbbreviation(abbreviation && abbreviation[0].abbreviation);
  };

  const componentDidMount = () => {
    stateProvinceService
      .getAll()
      .then((data) => {
        setStates(data);

        loadService
          .getContext()
          .then((cData) => {
            setOwnedCompanies(cData.owned_companies);

            loadService
              .getOne(id)
              .then((data) => {
                const pickups = [];
                const deliveries = [];
                data.pickupsInitialized.forEach((item) => {
                  const obj = {
                    shipper: item.consigneeNameAndLocation,
                    date: item.pickupDateFormatted,
                    id: item.id,
                    has_attachment: item.bolId,
                  };
                  pickups.push(obj);
                });

                data.deliveriesInitialized.forEach((item) => {
                  const obj = {
                    consignee: item.consigneeNameAndLocation,
                    date: item.deliveryDateFormatted,
                    id: item.id,
                  };
                  deliveries.push(obj);
                });
                const selectedValue = companies.find(
                  (item) => item.value === data.customerId
                );

                if (
                  decodedToken.role !== 'ROLE_ADMIN' &&
                  decodedToken.role !== 'SUPER_ADMIN'
                ) {
                  setEmployeer(true);
                }
                setActivePickups(pickups);
                setActiveDeliveries(deliveries);
                setCustomerId(data.customerId);
                setCustomerSelected(selectedValue);
                setCanBeChanged(
                  data.canBeChanged ||
                    decodedToken.role === 'ROLE_ADMIN' ||
                    decodedToken.role === 'SUPER_ADMIN'
                );
                setEditStatus(data.editStatus);
                setPrevRateConfirmationId(data.rateConfirmationId);
                setPrevRevisedRateConfirmationId(
                  data.revisedRateConfirmationId
                );
                setLoading(false);

                const abbreviationData = cData.owned_companies.filter(
                  (item) => item.id === data.ownedCompanyId * 1
                );
                setAbbreviation(
                  abbreviationData && abbreviationData[0].abbreviation
                );
                document.querySelector('#customId').value =
                  data.customLoadNumber_;
                document.querySelector('#owned_company').value =
                  data.ownedCompanyId;
                document.querySelector('#rc_price').value = data.rcPrice;
                document.querySelector('#rrc_price').value =
                  data.revisedRcPrice;

                const company = {
                  value: data.customerId,
                  label: data.customer,
                };
                setCustomerSelected(company);

                countingService
                  .onLoadGetOne(id)
                  .then((data) => {
                    if (data && data.length !== 0) {
                      document.querySelector('#additional').value =
                        data.additional;
                      document.querySelector('#booked').value = data.booked;
                      document.querySelector('#detention').value =
                        data.detention;
                      document.querySelector('#dispute').value = data.dispute;
                      document.querySelector('#factoring').value =
                        data.factoring;
                      document.querySelector('#fine').value = data.fine;
                      document.querySelector('#netPaid').value = data.netPaid;
                      document.querySelector('#revisedInvoice').value =
                        data.revisedInvoice;
                      document.querySelector('#tafs').value = data.tafs;
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    toast.error(err.response.data.message, {
                      transition: Flip,
                    });
                  });
              })
              .catch((err) => {
                console.log(err);
                toast.error(err.response.data.message, {
                  transition: Flip,
                });
              });
          })
          .catch((err) => {
            toast.error(err.response.data.message, {
              transition: Flip,
            });
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const togglePickupModal = (editingChildId) => {
    setShowPickupModal(!showPickupModal);
    setEditingChildId(editingChildId);
    componentDidMount();
  };

  const toggleNewCompany = () => {
    setShowNewCompanyModal(!showNewCompanyModal);
    componentDidMount();
  };

  const toggleDeliveryModal = (editingChildId) => {
    setShowDeliveryModal(!showDeliveryModal);
    setEditingChildId(editingChildId);
    componentDidMount();
  };

  const addPickup = (shipper, date, id, hasAttachment) => {
    const array = [...activePickups];
    const existing = array.find((el) => el.id === id);
    const index = array.indexOf(existing);
    if (index > -1) {
      array.forEach((item, i) => {
        if (array[i].id === id) {
          array.splice(i, 1);
        }
      });
    }
    const obj = {
      shipper,
      date,
      id,
      hasAttachment,
    };
    array.push(obj);
    setActivePickups(array);

    togglePickupModal();
    newLoadOnCloseModal();
  };

  const addDelivery = (consignee, date, id) => {
    const array = [...activeDeliveries];
    const existing = array.find((el) => el.id === id);
    const index = array.indexOf(existing);
    if (index > -1) {
      array.forEach((item, i) => {
        if (array[i].id === id) {
          array.splice(i, 1);
        }
      });
    }
    const obj = {
      consignee,
      date,
      id,
    };
    array.push(obj);
    setActiveDeliveries(array);

    toggleDeliveryModal(null);
    newLoadOnCloseModal();
  };

  // const companySearch = (val) => {
  //   if (val) {
  //     setSearchVal(val);
  //     companyService
  //       .getSearch(val)
  //       .then((data) => {
  //         if (val === searchVal) {
  //           const dataToShow = [];
  //           data.forEach((el) => {
  //             const elToShow = {
  //               value: el.id,
  //               label:
  //                 el.companyName +
  //                 ', ' +
  //                 (el.cityDto && el.cityDto.nameWithParentAnsi),
  //             };
  //             dataToShow.push(elToShow);
  //           });
  //           setCompanies(dataToShow);
  //         }
  //       })
  //       .catch((err) => {
  //         toast.error(err.response.data.message, {
  //           transition: Flip,
  //         });
  //       });
  //   } else {
  //     setCompanies([]);
  //   }
  // };

  const loadOptions = (inputValue, callback) => {
    const req = { search: inputValue };
    companyService
      .getSearch(req)
      .then((data) =>
        callback(
          data.map((el) => {
            return {
              ...el,
              value: el.id,
              label:
                el.companyName +
                ', ' +
                (el.cityDto && el.cityDto.nameWithParentAnsi),
            };
          })
        )
      )
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  useEffect(() => {
    componentDidMount();
  }, []);

  return (
    <>
      <Card>
        <CardHeader>
          <h3 className="mb-0">
            Editing Load{' '}
            {editStatus ? (
              <IconButton
                disabled={!!(employeer && editStatus === false)}
                color="primary"
                aria-label="upload picture"
                component="label"
                onClick={() => {
                  if (
                    decodedToken.role === 'ROLE_ADMIN' ||
                    decodedToken.role === 'SUPER_ADMIN'
                  ) {
                    setEditStatus(false);
                  }
                }}
              >
                <LockOpenIcon />
              </IconButton>
            ) : (
              <IconButton
                disabled={!!(employeer && editStatus === false)}
                color="primary"
                aria-label="upload picture"
                component="label"
                onClick={() => {
                  if (
                    decodedToken.role === 'ROLE_ADMIN' ||
                    decodedToken.role === 'SUPER_ADMIN'
                  ) {
                    setEditStatus(true);
                  }
                }}
              >
                <LockIcon />
              </IconButton>
            )}
          </h3>
        </CardHeader>
        <CardBody>
          {loading ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 44 }} spin />}
              style={{
                height: 'calc(100vh - 20rem)',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            />
          ) : (
            <Form>
              <h4 className="mb-0">Basic Details</h4>
              <FormGroup className="align-items-center mt-2" row>
                <Col md="4">
                  <span>Company</span>
                  <span style={{ color: 'red' }}> *</span>
                </Col>
                <Col md="8">
                  <CustomInput
                    disabled={!!(employeer && editStatus === false)}
                    type="select"
                    name="select"
                    id="owned_company"
                    onChange={(e) => {
                      setterAbbreviation(e.target.value);
                    }}
                  >
                    {ownedCompanies.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </CustomInput>
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Custom Load Number</span>
                  <span style={{ color: 'red' }}> *</span>
                </Col>
                <Col md="8">
                  <InputGroup>
                    <Input
                      disabled={!!(employeer && editStatus === false)}
                      placeholder=""
                      id="customId"
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>{abbreviation}</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
              </FormGroup>

              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Customer</span>
                  <span style={{ color: 'red' }}> *</span>
                </Col>
                <Col md="6" style={{ zIndex: 100 }}>
                  <Input
                    disabled={true}
                    style={{
                      display: employeer && editStatus === false ? '' : 'none',
                    }}
                    value={customerSelected ? customerSelected.label : {}}
                    onChange={(e) => {}}
                  />
                  <div
                    style={{
                      display: employeer && editStatus === false ? 'none' : '',
                    }}
                  >
                    <AsyncSelect
                      noOptionsMessage={(value) =>
                        !value.inputValue
                          ? 'type something to search'
                          : 'nothing to show'
                      }
                      placeholder="Search"
                      isClearable={true}
                      defaultValue={null}
                      loadOptions={loadOptions}
                      value={customerSelected}
                      onChange={(value) => {
                        if (value !== null) {
                          setCustomerId(value.value);
                          setCustomerSelected(value);
                        } else {
                          setCustomerId(null);
                          setCustomerSelected(null);
                        }
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary50: '#fe810b',
                          primary25: 'rgb(253, 179, 46)',
                          primary: 'rgb(253, 179, 46)',
                        },
                      })}
                    />
                  </div>
                </Col>
                <Col md="2 pl-0">
                  <Button
                    size="sm"
                    disabled={!!(employeer && editStatus === false)}
                    color="success"
                    type="button"
                    onClick={() => toggleNewCompany()}
                  >
                    <Icon.Plus size={22} />
                  </Button>
                </Col>
              </FormGroup>

              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Rate Confirmation</span>
                </Col>
                {prevRateConfirmationId ? (
                  <>
                    <Col md="2">
                      <div className="d-flex align-items-center justify-content-start">
                        <Button
                          disabled={!!(employeer && editStatus === false)}
                          style={{ width: 250 }}
                          className="mt-1"
                          type="button"
                          // href={`${process.env.REACT_APP_BASE_URL}/file/${prevRateConfirmationId}`}
                          onClick={() => {
                            fetch(
                              `${process.env.REACT_APP_BASE_URL}/file/${prevRateConfirmationId}`,
                              {
                                method: 'GET',
                                headers: {
                                  Authorization:
                                    'Bearer ' +
                                    localStorage.getItem(
                                      localStorage.getItem('current_account') +
                                        '_access_token'
                                    ),
                                },
                              }
                            ).then((response) => {
                              response.blob().then((blob) => {
                                const spl = response.headers
                                  .get('content-disposition')
                                  .split(';');

                                const filename = spl[1]
                                  .split('=')[1]
                                  .replace('"', '');
                                const fileName2 = filename.replace('"', '');

                                // Creating new object of PDF file
                                const fileURL =
                                  window.URL.createObjectURL(blob);
                                // Setting various property values
                                const alink = document.createElement('a');
                                alink.href = fileURL;
                                alink.target = '_blank';
                                alink.download = fileName2;
                                alink.click();
                              });
                            });
                          }}
                        >
                          Download Rate Confirmation
                        </Button>
                        <Button
                          disabled={!!(employeer && editStatus === false)}
                          className="btn-icon mt-1"
                          color="red"
                          type="button"
                          onClick={() => setPrevRateConfirmationId(null)}
                        >
                          <Icon.Trash2 />
                        </Button>
                      </div>
                    </Col>
                  </>
                ) : (
                  <Col md="2">
                    <CustomInput
                      disabled={!!(employeer && editStatus === false)}
                      id="file1"
                      type="file"
                      onInput={(e) => uploadFile(e.target.files[0], 'rc')}
                    />
                  </Col>
                )}
                <Col md="2" className="text-right">
                  <span>Revised RC</span>
                </Col>
                {prevRevisedRateConfirmationId ? (
                  <>
                    <Col md="2">
                      <div className="d-flex align-items-center justify-content-start">
                        <Button
                          disabled={!!(employeer && editStatus === false)}
                          style={{ width: 250 }}
                          className="mt-1"
                          type="button"
                          // href={`${process.env.REACT_APP_BASE_URL}/file/${prevRevisedRateConfirmationId}`}
                          onClick={() => {
                            fetch(
                              `${process.env.REACT_APP_BASE_URL}/file/${prevRevisedRateConfirmationId}`,
                              {
                                method: 'GET',
                                headers: {
                                  Authorization:
                                    'Bearer ' +
                                    localStorage.getItem(
                                      localStorage.getItem('current_account') +
                                        '_access_token'
                                    ),
                                },
                              }
                            ).then((response) => {
                              response.blob().then((blob) => {
                                const spl = response.headers
                                  .get('content-disposition')
                                  .split(';');

                                const filename = spl[1]
                                  .split('=')[1]
                                  .replace('"', '');
                                const fileName2 = filename.replace('"', '');
                                // Creating new object of PDF file
                                const fileURL =
                                  window.URL.createObjectURL(blob);

                                const alink = document.createElement('a');
                                alink.href = fileURL;
                                alink.target = '_blank';
                                alink.download = fileName2;
                                alink.click();
                              });
                            });
                          }}
                        >
                          Download Revised Rate Confirmation
                        </Button>
                        <Button
                          disabled={!!(employeer && editStatus === false)}
                          className="btn-icon mt-1"
                          color="red"
                          type="button"
                          onClick={() => setPrevRevisedRateConfirmationId(null)}
                        >
                          <Icon.Trash2 />
                        </Button>
                      </div>
                    </Col>
                  </>
                ) : (
                  <Col md="2">
                    <CustomInput
                      disabled={!!(employeer && editStatus === false)}
                      id="file2"
                      type="file"
                      onInput={(e) => uploadFile(e.target.files[0], 'rrc')}
                    />
                  </Col>
                )}
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>RC Price</span>
                </Col>
                <Col md="2">
                  <FormGroup className="position-relative has-icon-left input-divider-left mb-0">
                    <Input
                      disabled={!!(employeer && editStatus === false)}
                      type="number"
                      placeholder="0.00"
                      id="rc_price"
                    />
                    <div className="form-control-position">
                      <Icon.DollarSign />
                    </div>
                  </FormGroup>
                </Col>
                <Col md="2" className="text-right">
                  <span>Revised RC Price</span>
                </Col>
                <Col md="2">
                  <FormGroup className="position-relative has-icon-left input-divider-left mb-0">
                    <Input
                      disabled={!!(employeer && editStatus === false)}
                      type="number"
                      placeholder="0.00"
                      id="rrc_price"
                    />
                    <div className="form-control-position">
                      <Icon.DollarSign />
                    </div>
                  </FormGroup>
                </Col>
              </FormGroup>
            </Form>
          )}
        </CardBody>
      </Card>
      <Row>
        <Col sm="12" md="6">
          <Card>
            <CardHeader>
              <CardTitle>Pickup</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="flex-column">
                {activePickups.map((item) => (
                  <ActivePickup
                    disabled={!!(employeer && editStatus === false)}
                    key={item.id}
                    data={item}
                    deletePickup={(id) => deletePickup(id)}
                    editPickup={togglePickupModal}
                    canBeChanged={canBeChanged}
                    has_attachment={item.has_attachment}
                  />
                ))}
              </div>
              <Button
                size="sm"
                disabled={!!(employeer && editStatus === false)}
                color="success"
                className="d-flex align-items-center"
                type="button"
                onClick={() => togglePickupModal(null)}
              >
                <Icon.Plus size={22} />
                Add new pickup
              </Button>

              <ModalWindowSimple
                title="Pickup modal"
                modal={showPickupModal}
                handleModal={togglePickupModal}
                maxWidth="md"
                body={
                  <PickupModal
                    disabled={!(employeer && editStatus === false)}
                    modal={showPickupModal}
                    togglePickupModal={togglePickupModal}
                    toggleNewCompany={toggleNewCompany}
                    companies={companies}
                    // token={this.props.token}
                    canBeChanged={canBeChanged}
                    addPickup={addPickup}
                    editingChildId={editingChildId}
                    loadOptions={loadOptions}
                  />
                }
              />

              <ModalWindowSimple
                title="Company modal"
                modal={showNewCompanyModal}
                handleModal={toggleNewCompany}
                maxWidth="md"
                // dialogActions={
                //   <DialogActions>
                //     <Button
                //       color="primary"
                //       variant="contained"
                //       autoFocus
                //       onClick={() => {
                //         toggleNewCompany();
                //       }}
                //     >
                //       Accept
                //     </Button>
                //   </DialogActions>
                // }
                body={
                  <NewCompany
                    modal={showNewCompanyModal}
                    toggleNewCompany={toggleNewCompany}
                    // token={this.props.token}
                  />
                }
              />
            </CardBody>
          </Card>
        </Col>
        <Col sm="12" md="6">
          <Card>
            <CardHeader>
              <CardTitle>Delivery</CardTitle>
            </CardHeader>
            <CardBody>
              {activeDeliveries.map((item) => (
                <ActiveDelivery
                  disabled={!!(employeer && editStatus === false)}
                  key={item.id}
                  data={item}
                  canBeChanged={canBeChanged}
                  deleteDelivery={(id) => deleteDelivery(id)}
                  editDelivery={toggleDeliveryModal}
                />
              ))}
              <Button
                size="sm"
                disabled={!!(employeer && editStatus === false)}
                color="success"
                className="d-flex align-items-center"
                type="button"
                onClick={() => toggleDeliveryModal(null)}
              >
                <Icon.Plus size={22} />
                Add new delivery
              </Button>

              <ModalWindowSimple
                title="Delivery modal"
                modal={showDeliveryModal}
                handleModal={toggleDeliveryModal}
                maxWidth="md"
                body={
                  <DeliveryModal
                    disabled={!(employeer && editStatus === false)}
                    loadOptions={loadOptions}
                    modal={showDeliveryModal}
                    toggleDeliveryModal={toggleDeliveryModal}
                    companies={companies}
                    // token={this.props.token}
                    addDelivery={addDelivery}
                    canBeChanged={canBeChanged}
                    toggleNewCompany={toggleNewCompany}
                    editingChildId={editingChildId}
                  />
                }
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <div className="d-flex justify-content-center">
        <Button
          disabled={!!(employeer && editStatus === false)}
          color="success"
          className="d-flex align-items-center"
          type="button"
          onClick={() => newLoad()}
        >
          <Icon.Check size={22} />
          Save
        </Button>
      </div>
      {decodedToken.role === 'ROLE_ADMIN' ||
      decodedToken.role === 'SUPER_ADMIN' ? (
        <Row className="justify-content-center" style={{ marginTop: '2.2rem' }}>
          <Col md="6" sm="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Payments</CardTitle>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 44 }} spin />
                    }
                    style={{
                      height: 'calc(100vh - 20rem)',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  />
                ) : (
                  <Form>
                    <Row>
                      <Col sm="6">
                        <Label for="nameVertical">Booked</Label>
                        <FormGroup className="position-relative has-icon-left input-divider-left mb-0">
                          <Input
                            type="number"
                            placeholder="0.00"
                            id="booked"
                            onChange={() => calculate(0)}
                          />
                          <div className="form-control-position">
                            <Icon.DollarSign />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <Label for="nameVertical">Dispute</Label>
                        <FormGroup className="position-relative has-icon-left input-divider-left mb-0">
                          <Input
                            type="number"
                            placeholder="0.00"
                            id="dispute"
                            onChange={() => calculate(0)}
                          />
                          <div className="form-control-position">
                            <Icon.DollarSign />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <Label for="nameVertical">Detention</Label>
                        <FormGroup className="position-relative has-icon-left input-divider-left mb-0">
                          <Input
                            type="number"
                            placeholder="0.00"
                            id="detention"
                            onChange={() => calculate(0)}
                          />
                          <div className="form-control-position">
                            <Icon.DollarSign />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <Label for="nameVertical">Additional</Label>
                        <FormGroup className="position-relative has-icon-left input-divider-left mb-0">
                          <Input
                            type="number"
                            placeholder="0.00"
                            id="additional"
                            onChange={() => calculate(0)}
                          />
                          <div className="form-control-position">
                            <Icon.DollarSign />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <Label for="nameVertical">Fine</Label>
                        <FormGroup className="position-relative has-icon-left input-divider-left mb-0">
                          <Input
                            type="number"
                            placeholder="0.00"
                            id="fine"
                            onChange={() => calculate(0)}
                          />
                          <div className="form-control-position">
                            <Icon.DollarSign />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <Label for="nameVertical">Revised / Invoice</Label>
                        <FormGroup className="position-relative has-icon-left input-divider-left mb-0">
                          <Input
                            disabled
                            type="number"
                            placeholder="0.00"
                            id="revisedInvoice"
                          />
                          <div className="form-control-position">
                            <Icon.DollarSign />
                          </div>
                        </FormGroup>
                      </Col>
                      <hr className="w-100 mt-2" />
                      <Col sm="6">
                        <Label for="nameVertical">Factoring</Label>
                        <FormGroup className="position-relative has-icon-left input-divider-left mb-0">
                          <Input
                            type="number"
                            placeholder="0.00"
                            id="factoring"
                            onChange={() => calculate(1)}
                          />
                          <div className="form-control-position">
                            <Icon.DollarSign />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <Label for="nameVertical">Tafs</Label>
                        <FormGroup className="position-relative has-icon-left input-divider-left mb-0">
                          <Input
                            type="number"
                            placeholder="0"
                            id="tafs"
                            onChange={() => calculate(1)}
                          />
                          <div className="form-control-position">
                            <Icon.DollarSign />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <Label for="nameVertical" className="bold">
                          Net Paid
                        </Label>
                        <FormGroup className="position-relative has-icon-left input-divider-left mb-0">
                          <Input
                            disabled
                            type="number"
                            placeholder="0.00"
                            id="netPaid"
                          />
                          <div className="form-control-position">
                            <Icon.DollarSign />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="12">
                        <FormGroup className="d-flex mb-0">
                          <div className="mt-2">
                            <Button
                              color="primary"
                              type="button"
                              onClick={() => postPayments()}
                            >
                              Submit
                            </Button>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : null}
    </>
  );
}
