import React, { useState } from 'react';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import CallIcon from '@mui/icons-material/Call';
import Messages from './Messages';
import Input from './Input';
import ModalWindowFullScreen from '../../../../components/main/Modals/FullScreen';
import VideoCall from '../VideoCall';

export default function Chat({
  refer,
  getMessages,
  user,
  userId,
  messages,
  sendMessage,
  page,
  totalPage,
}) {
  const [modal, setModal] = useState(false);

  return (
    <div>
      <ModalWindowFullScreen
        title="Video Call"
        modal={modal}
        handleModal={() => {
          setModal(false);
        }}
        body={
          <VideoCall
            modal={modal}
            toggle={() => {
              setModal(false);
            }}
          />
        }
      />
      <div>
        <Typography variant="h4" align="left">
          {user ? user.name : ''}
        </Typography>
        {/* <Button
          variant="contained"
          startIcon={<CallIcon />}
          onClick={() => {
            setModal(true);
          }}
        >
          Call
        </Button> */}
      </div>
      <Messages
        refer={refer}
        page={page}
        totalPage={totalPage}
        getMessages={getMessages}
        user={user}
        userId={userId}
        messages={messages}
      />
      <Input userId={userId} user={user} sendMessage={sendMessage} />
    </div>
  );
}
