import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Col,
} from 'reactstrap';
import * as Icon from 'react-feather';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';
import AsyncSelect from 'react-select/async';
import { toast, Flip } from 'react-toastify';
import deliveryService from '../../../../../services/delivery';
import pickupService from '../../../../../services/pickup';
import companyService from '../../../../../services/companies';

export default function DeliveryModal({
  modal,
  disabled,
  className,
  editingChildId,
  addDelivery,
  toggleDeliveryModal,
  loadOptions,
  toggleNewCompany,
  canBeChanged,
}) {
  const [consignee, setConsignee] = useState(null);
  const [consigneeName, setConsigneeName] = useState('');
  const [consigneeSelected, setConsigneeSelected] = useState(null);
  const [deliveryDate_, setDeliveryDate_] = useState(null);
  const [deliveryDateWithOffset, setDeliveryDateWithOffset] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [searchVal, setSearchVal] = useState(null);

  // const companySearch = (val) => {
  //   if (val) {
  //     setSearchVal(val);
  //     companyService
  //       .getSearch(val)
  //       .then((data) => {
  //         if (val === searchVal) {
  //           const dataToShow = [];
  //           data.forEach((el) => {
  //             const elToShow = {
  //               value: el.id,
  //               label:
  //                 el.companyName +
  //                 ', ' +
  //                 (el.cityDto && el.cityDto.nameWithParentAnsi),
  //             };
  //             dataToShow.push(elToShow);
  //           });
  //           setCompanies(dataToShow);
  //         }
  //       })
  //       .catch((err) => {
  //         toast.error(err.response.data.message, {
  //           transition: Flip,
  //         });
  //       });
  //   } else {
  //     setCompanies([]);
  //   }
  // };

  const newDelivery = () => {
    const sendingData = {
      consigneeCompanyId: consignee,
      deliveryDate_: deliveryDateWithOffset,
      driverInstructions: document.querySelector('#driverInstructions').value,
      customRequiredInfo: document.querySelector('#customerRequiredInfo').value,
      weight: parseInt(document.querySelector('#weight').value, 10),
      quantity: parseInt(document.querySelector('#quantity').value, 10),
      notes: document.querySelector('#notes').value,
      commodity: document.querySelector('#commodity').value,
    };
    if (editingChildId !== null && editingChildId !== undefined) {
      sendingData.id = editingChildId;
      deliveryService
        .update(sendingData)
        .then((data) => {
          pickupService.getResolved(sendingData.deliveryDate_).then((time) => {
            toast.success('Delivery successfuly edited', {
              transition: Flip,
            });
            addDelivery(consigneeName, time, editingChildId);
          });
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            transition: Flip,
          });
          // toast.error("Something went wrong", { transition: Flip });
          return Promise.reject();
        });
    } else {
      deliveryService
        .create(sendingData)
        .then((res) => {
          if (!res) {
            throw new Error(res);
          }
          return res;
        })
        .then((data) => {
          pickupService.getResolved(sendingData.deliveryDate_).then((time) => {
            toast.success('Delivery successfuly added', {
              transition: Flip,
            });
            addDelivery(consigneeName, time, data);
          });
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            transition: Flip,
          });
          // toast.error("Something went wrong", { transition: Flip });
          return Promise.reject();
        });
    }
  };

  const shipperChange = (value) => {
    if (value == null) {
      setConsignee(null);
      setConsigneeName('');
      setConsigneeSelected(null);
    } else {
      setConsignee(value.value);
      setConsigneeName(value.label);
      setConsigneeSelected(value);
    }
  };

  useEffect(() => {
    if (editingChildId) {
      deliveryService
        .getOne(editingChildId)
        .then((data) => {
          const selectedValue = {
            value: data.consigneeCompanyId,
            label: data.consigneeCompany,
          };

          setConsigneeSelected(selectedValue);
          setDeliveryDate_(data.deliveryDate_);
          setDeliveryDateWithOffset(data.deliveryDate_);

          shipperChange(selectedValue);
          document.querySelector('#driverInstructions').value =
            data.driverInstructions;
          document.querySelector('#customerRequiredInfo').value =
            data.customRequiredInfo;
          document.querySelector('#weight').value = data.weight;
          document.querySelector('#quantity').value = data.quantity;
          document.querySelector('#notes').value = data.notes;
          document.querySelector('#commodity').value = data.commodity;
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            transition: Flip,
          });
        });
    } else {
      setConsignee(null);
      setConsigneeName('');
      setConsigneeSelected(null);
      setDeliveryDate_(null);
      setDeliveryDateWithOffset(null);

      setTimeout(() => {
        document.querySelector('#deliveryDate').value = '';
        document.querySelector('#driverInstructions').value = '';
        document.querySelector('#customerRequiredInfo').value = '';
        document.querySelector('#weight').value = '';
        document.querySelector('#quantity').value = '';
        document.querySelector('#notes').value = '';
        document.querySelector('#commodity').value = '';
      }, 300);
    }
  }, [editingChildId]);

  return (
    <Form>
      <FormGroup className="align-items-center" row>
        <Col md="4">
          <span>Consignee</span>
          <span style={{ color: 'red' }}> *</span>
        </Col>
        <Col md={disabled ? '6' : '8'} style={{ zIndex: 10000 }}>
          <Input
            disabled={true}
            style={{
              display: !disabled ? '' : 'none',
            }}
            value={consigneeSelected ? consigneeSelected.label : {}}
            onChange={(e) => {}}
          />
          <div
            style={{
              display: !disabled ? 'none' : '',
            }}
          >
            <AsyncSelect
              noOptionsMessage={(value) =>
                !value.inputValue
                  ? 'type something to search'
                  : 'nothing to show'
              }
              placeholder="Search"
              isClearable={true}
              defaultValue={null}
              loadOptions={loadOptions}
              value={consigneeSelected}
              onChange={shipperChange}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary50: '#fe810b',
                  primary25: 'rgb(253, 179, 46)',
                  primary: 'rgb(253, 179, 46)',
                },
              })}
            />
          </div>
        </Col>
        {disabled ? (
          <Col md="2 pl-0">
            <Button
              size="sm"
              color="success"
              type="button"
              onClick={() => toggleNewCompany()}
            >
              <Icon.Plus size={22} />
            </Button>
          </Col>
        ) : null}
      </FormGroup>
      <FormGroup className="align-items-center" row aria-readonly={false}>
        <Col md="4">
          <span>Delivery Date</span>
        </Col>
        <Col md="8">
          <Flatpickr
            disabled={!disabled}
            data-input
            id="deliveryDate"
            className="form-control"
            data-enable-time
            options={{
              enableTime: true,
              dateFormat: 'Z',
              altInput: true,
              allowInput: true,
              clickOpens: canBeChanged,
              altFormat: 'm-d-Y H:i',
            }}
            value={deliveryDate_}
            onChange={(e) => {
              setDeliveryDate_(Date.parse(e[0]));
              setDeliveryDateWithOffset(e[0].getTime());
            }}
          />
        </Col>
      </FormGroup>

      <FormGroup className="align-items-center" row>
        <Col md="4">
          <span>Driver Instructions</span>
        </Col>
        <Col md="8">
          <Input disabled={!disabled} type="text" id="driverInstructions" />
        </Col>
      </FormGroup>

      <FormGroup className="align-items-center" row>
        <Col md="4">
          <span>Customer Required Info</span>
        </Col>
        <Col md="8">
          <Input disabled={!disabled} type="text" id="customerRequiredInfo" />
        </Col>
      </FormGroup>
      <FormGroup className="align-items-center" row>
        <Col md="4">
          <span>Weight</span>
        </Col>
        <Col md="8">
          <Input disabled={!disabled} type="text" id="weight" />
        </Col>
      </FormGroup>
      <FormGroup className="align-items-center" row>
        <Col md="4">
          <span>Quantity</span>
        </Col>
        <Col md="8">
          <Input disabled={!disabled} type="text" id="quantity" />
        </Col>
      </FormGroup>
      <FormGroup className="align-items-center" row>
        <Col md="4">
          <span>Notes</span>
        </Col>
        <Col md="8">
          <Input disabled={!disabled} type="text" id="notes" />
        </Col>
      </FormGroup>
      <FormGroup className="align-items-center" row>
        <Col md="4">
          <span>Commodity</span>
        </Col>
        <Col md="8">
          <Input disabled={!disabled} type="text" id="commodity" />
        </Col>
      </FormGroup>
      <FormGroup className="align-items-right" row>
        <Button
          disabled={!disabled}
          color="primary"
          onClick={() => newDelivery()}
        >
          Accept
        </Button>
      </FormGroup>
    </Form>
    //   </ModalBody>
    //   <ModalFooter>
    //     <Button color="primary" onClick={() => newDelivery()}>
    //       Accept
    //     </Button>{' '}
    //   </ModalFooter>
    // </Modal>
  );
}
