import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ModalWindowFullScreen from '../../Modals/FullScreen';
import ViewTripModal from '../../../../views/pages/Trips/Modals/ViewModal';

export default ({ value }) => {
  const [tripModal, setTripModal] = useState(false);

  return (
    <>
      <ModalWindowFullScreen
        title="Trip"
        modal={tripModal}
        handleModal={() => {
          setTripModal(!tripModal);
        }}
        body={<ViewTripModal modal={tripModal} id={value.tripId} />}
      />
      {value.tripId !== null && (
        <Link
          className="link-in-table"
          onClick={() => {
            setTripModal(true);
            // currentSubs.unsubscribe();
          }}
          // to={`/trips/view/${value.tripId}`}
        >
          {value.tripId}
        </Link>
      )}
    </>
  );
};
