import { service } from '.';

export default {
  getAll: (query) => service.get(`/trip/list?${query}`),
  getContext: () => service.get(`/trip/context`),
  getOne: (id) => service.get(`/trip/${id}`),
  getSearch: (value) => service.get(`/trip/search_by_number?q=${value}`),
  getExcelFile: (query) => service.get(`/trip/get_excel?${query}`),
  create: (data) => service.post('/trip/new', data),
  update: (data) => service.put(`/trip`, data),
  updateApprove: (data) => service.put(`/trip/update_driver_approve`, data),
  updateContext: (id) => service.get(`/trip/edit_context/${id}`),
  updateStatus: (status, id, data) =>
    service.put(`/trip/update_status/${status}/${id}`, data),
  delete: (id) => service.delete(`/trip/${id}`),
};
