/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import { Col, CardHeader, FormGroup } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  colors,
  Box,
  CardMedia,
  Button,
  Grid,
  TextField,
  FormHelperText,
  Container,
  Card,
  IconButton,
  Typography,
  Fab,
  Stack,
} from '@mui/material';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import ImageIcon from '@mui/icons-material/Image';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InputMask from 'react-input-mask';
import Autocomplete from '@mui/material/Autocomplete';
import * as Icon from 'react-feather';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useParams, useNavigate } from 'react-router-dom';
import { toast, Flip } from 'react-toastify';
import Flatpickr from 'react-flatpickr';
import fileUploadService from '../../../../services/file_upload';
import stateProvinceService from '../../../../services/state_province';
import driverService from '../../../../services/drivers';
import usersApi from '../../../../services/users';
import Camera from '../Modals/Camera';
import ModalWindow from '../../../../components/main/Modals';
import Loading from '../../../../components/main/Loading';
import UploadIcon from '../../../../assets/icons/camera.svg';
import DragAndDrop from '../components/DragAndDrop';
import AttachmentItem from '../components/AttachmentItem';
import { nationalities } from '../../../../redux/constants/nationalities';
import { useStyles } from './style';

const CustomInputDate = ({
  value,
  classes,
  name,
  formik,
  formikValue,
  formikTouched,
  formikError,
  defaultValue,
  inputRef,
  labelText,
  ...props
}) => {
  return (
    <TextField
      disabled
      ref={inputRef}
      size="small"
      {...props}
      label={labelText}
      className={classes.search}
      name={name}
      autoComplete="namer"
      type="text"
      fullWidth
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      variant="outlined"
      value={formikValue}
      onKeyDown={(e) => {
        e.preventDefault();
      }}
    />
  );
};

export default function NewFormDriver() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [states, setStates] = useState([]);

  const [licenseFileId, setLicenseFileId] = useState(null);
  const [medicalCardFileId, setMedicalCardFileId] = useState(null);

  const [socialSecurityFileId, setSocialSecurityFileId] = useState(null);
  const [showCameraModal, setShowCameraModal] = useState(false);
  const [stream, setStream] = useState();
  const [selfie, setSelfie] = useState();

  const [medicalCartFile, setMedicalCartFile] = useState();
  const [ssnFile, setSsnFile] = useState();
  const [licenseFile, setLicenseFile] = useState();

  const [sendCodeEmail, setSendCodeEmail] = useState(false);
  const [confirmEmailDuring, setConfirmEmailDuring] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState(false);

  const videoRef = useRef(null);
  const photoRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const [openDrop, setOpenDrop] = useState(false);

  const [filesUpload, setFilesUpload] = useState([]);

  const { id } = useParams();

  const Ref = useRef(null);

  // The state for our timer
  const [timer, setTimer] = useState('00:00');

  const dateFormat = (dateValue) => {
    const date = new Date(dateValue + 1 * 24 * 60 * 60 * 1000);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const dateS = day.toString().padStart(2, '0');

    const monthS = month.toString().padStart(2, '0');
    return `${year}-${monthS}-${dateS}`;
  };

  const checkImageFile = (file) => {
    const indexJPG = file.toLowerCase().indexOf('.jpg');
    const indexPNG = file.toLowerCase().indexOf('.png');
    if (indexJPG > -1 || indexPNG > -1) {
      return true;
    }
    return false;
  };

  const handleToggle = () => {
    setOpenDrop(!openDrop);
  };

  // const uploadFile = async (file) => {
  //   const formData = new FormData();
  //   formData.append('file', file);
  //   if (file === undefined) {
  //     return;
  //   }
  //   await axios.post(
  //     process.env.REACT_APP_BASE_URL + '/file/upload/trailer',
  //     formData,
  //     {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //         Authorization:
  //           'Bearer ' +
  //           localStorage.getItem(
  //             localStorage.getItem('current_account') + '_access_token'
  //           ),
  //       },
  //       onUploadProgress: (progressEvent) => {
  //         const progress = (progressEvent.loaded / progressEvent.total) * 100;
  //         // this.setState({
  //         //     progress,
  //         // });
  //         // console.log(progressEvent);
  //         //   setProgress(progress);
  //       },
  //       onDownloadProgress: (progressEvent) => {
  //         const progress = (progressEvent.loaded / progressEvent.total) * 100;
  //         console.log(progressEvent);

  //         const fileList = files;
  //         const data = JSON.parse(progressEvent.target.response);
  //         fileList.push({
  //           id: data.id,
  //           url: data.originalFileName,
  //           originalFileName: data.originalFileName,
  //           size: data.size,
  //           uploadedAt: data.uploadedAt,
  //         });
  //         setFiles(fileList);
  //         filesUpload.push(data);
  //         setFilesUpload(filesUpload);
  //         setOpenDrop(false);
  //       },
  //     }
  //   );
  // };

  const uploadLicense = (driverId) => {
    filesUpload.forEach((item, i) => {
      if (item.file) {
        const formData = new FormData();
        formData.append('file', item.file, item.name);
        formData.append('fileStatus', 'license');

        fileUploadService
          .upload('/file/upload/form/' + id + '/' + driverId, formData)
          .then((data) => {
            setLoading(false);
            if (i === filesUpload.length - 1) {
              setLicenseFileId(data);
              navigate('/drivers/form/successful');
              toast.success('Form successfuly saved', {
                transition: Flip,
              });
            }
          })
          .catch((err) => {
            setLoading(false);
            toast.error(err.response.data.message, {
              transition: Flip,
            });
          });
      }
    });
  };

  const uploadSSN = (driverId) => {
    const formData = new FormData();
    formData.append('file', ssnFile, 'ssn');
    formData.append('fileStatus', 'ssn');

    fileUploadService
      .upload('/file/upload/form/' + id + '/' + driverId, formData)
      .then((data) => {
        setSocialSecurityFileId(data);
        uploadLicense(driverId);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const uploadMedicalCart = (driverId) => {
    const formData = new FormData();
    formData.append('file', medicalCartFile, 'cart');
    formData.append('fileStatus', 'medical');

    fileUploadService
      .upload('/file/upload/form/' + id + '/' + driverId, formData)
      .then((data) => {
        setMedicalCardFileId(data);
        uploadSSN(driverId);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const uploadSelfie = (driverId) => {
    console.log(selfie);
    fetch(selfie)
      .then((response) => response.blob())
      .then((base64Response) => {
        const imageBlob = base64Response;

        const file = new File([imageBlob], 'image.png', {
          type: 'image/png',
          lastModified: new Date().getTime(),
        });
        const formData = new FormData();
        formData.append('file', file, new Date().getTime());
        formData.append('fileStatus', 'photo');

        fileUploadService
          .upload('/file/upload/form/' + id + '/' + driverId, formData)
          .then((data) => {
            uploadMedicalCart(driverId);
          })
          .catch((err) => {
            setLoading(false);
            toast.error(err.response.data.message, {
              transition: Flip,
            });
          });
      });
  };

  const newDriver = (values) => {
    const data = {
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: values.middleName,
      birthDate: new Date(values.birthDate).getTime(),
      nationality: values.nationality,
      street: values.street,
      address: values.address,
      city: values.city,
      stateProvinceId: values.stateProvinceId,
      zipCode: values.zipcode,
      phone: values.phone,
      alternatePhone: values.alternatePhone,
      email: values.email,
      socialSecurityNumber: values.socialSecurityNumber,
      licenseIssuedJurisdictionId: values.licenseIssuedJurisdictionId,
      licenseNumber: values.licenseNumber,
      licenseExpiration: values.licenseExpiration,
      licenseIssueTime: new Date(values.licenseIssueTime).getTime(),
      medicalCardRenewal: values.medicalCardRenewal,
      licenseFileId,
      medicalCardFileId,
      socialSecurityFileId,
    };
    setLoading(true);
    driverService
      .createForm(id, data)
      .then((res) => {
        if (res) {
          if (selfie) {
            uploadSelfie(res.id);
          } else {
            uploadMedicalCart(res.id);
          }
        } else {
          setLoading(false);
          toast.error('Something went wrong', { transition: Flip });
          // res.text();
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error('Something went wrong', { transition: Flip });
        // toast.error(err.response.data.message, {
        //   transition: Flip,
        // });
      });
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('required'),
    lastName: Yup.string().required('required'),
    birthDate: Yup.string().required('required'),
    nationality: Yup.string().required('required'),
    street: Yup.string().required('required'),
    city: Yup.string().required('required'),
    stateProvinceId: Yup.number().required('required'),
    zipcode: Yup.number().min(5).required('required'),
    phone: Yup.string().required('required'),
    email: Yup.string().email().required('required'),
    socialSecurityNumber: Yup.string().required('required'),
    licenseIssuedJurisdictionId: Yup.number().required('required'),
    licenseNumber: Yup.string().required('required'),
    licenseExpiration: Yup.string().required('required'),
    licenseIssueTime: Yup.string().required('required'),
    medicalCardRenewal: Yup.string().required('required'),
    licenseFiles: Yup.array().min(1).required('required'),
    medicalFile: Yup.string().required('required'),
    ssnFile: Yup.string().required('required'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      middleName: '',
      birthDate: '',
      nationality: '',
      street: '',
      address: '',
      city: '',
      stateProvinceId: '',
      zipcode: '',
      phone: '',
      alternatePhone: '',
      email: '',
      socialSecurityNumber: '',
      licenseIssuedJurisdictionId: '',
      licenseNumber: '',
      licenseExpiration: '',
      licenseIssueTime: '',
      medicalCardRenewal: '',
      licenseFiles: [],
      medicalFile: '',
      ssnFile: '',
      photoFile: '',
    },
    validationSchema,
    onSubmit: (values) => {
      newDriver(values);
      // if (confirmEmail) {

      // } else {
      //   toast.error('Please, email verify', {
      //     transition: Flip,
      //   });
      // }
    },
  });

  const handleDrop = (filesData) => {
    handleToggle();

    const fileListUpload = filesUpload;
    Array.from(filesData).forEach((data) => {
      const a = fileListUpload.length + 1;
      const filterList = fileListUpload.filter((element) => {
        return element.name === data.name;
      });

      if (filterList.length > 0) {
        const spName = data.name.split('.');
        let name = '';
        spName.forEach((val, i) => {
          if (i < spName.length - 1) {
            name += val + '-';
          }
        });
        fileListUpload.push({
          id: a,
          name:
            name +
            'Copy(' +
            filterList.length +
            ').' +
            data.name.split('.').pop(),
          file: data,
        });
      } else {
        fileListUpload.push({
          id: a,
          name: data.name,
          file: data,
        });
      }
    });
    console.log(fileListUpload);

    setFilesUpload(fileListUpload);
    formik.setFieldValue('licenseFiles', fileListUpload);
  };

  const componentDidMount = () => {
    stateProvinceService
      .getAllOpen()
      .then((data) => setStates(data))
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const stopCamera = () => {
    stream.getTracks().forEach((track) => {
      track.stop();
    });
  };

  const toggleCamera = () => {
    setShowCameraModal(!showCameraModal);
    if (videoRef.current !== null) {
      stopCamera();
    }
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    const { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        (minutes > 9 ? minutes : '0' + minutes) +
          ':' +
          (seconds > 9 ? seconds : '0' + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer('02:00');

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    const deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 120);
    return deadline;
  };

  const resend = (e) => {
    setLoading(true);
    usersApi
      .sendConfirmationCode2(id, {
        email: formik.values.email,
      })
      .then((res) => {
        clearTimer(getDeadTime());
        toast.success(res, {
          transition: Flip,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data, {
          transition: Flip,
        });
      });
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  useEffect(() => {
    componentDidMount();
  }, []);

  return (
    <>
      <ModalWindow
        title="Selfie"
        modal={showCameraModal}
        handleModal={toggleCamera}
        maxWidth="sm"
        body={
          <Camera
            formik={formik}
            videoRef={videoRef}
            photoRef={photoRef}
            setSelfie={setSelfie}
            setStream={setStream}
            modal={showCameraModal}
            toggle={toggleCamera}
          />
        }
      />
      <Loading loading={loading} />
      <Card>
        <CardHeader>
          <h3 className="mb-0"> Driver Form</h3>
        </CardHeader>

        <Container>
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} sm={12} my={1}>
                    <Typography
                      variant="h6"
                      align="left"
                      color={colors.grey[700]}
                    >
                      First name <span style={{ color: 'red' }}> *</span>
                    </Typography>
                    <TextField
                      className={classes.search}
                      name="firstName"
                      autoComplete="namer"
                      type="text"
                      fullWidth
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      variant="outlined"
                      value={formik.values.firstName}
                    />
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.firstName &&
                        formik.touched.firstName &&
                        formik.errors.firstName}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography
                      variant="h6"
                      align="left"
                      color={colors.grey[700]}
                    >
                      Middle name
                    </Typography>
                    <TextField
                      className={classes.search}
                      name="middleName"
                      autoComplete="namer"
                      type="text"
                      fullWidth
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      variant="outlined"
                      value={formik.values.middleName}
                    />
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.middleName &&
                        formik.touched.middleName &&
                        formik.errors.middleName}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography
                      variant="h6"
                      align="left"
                      color={colors.grey[700]}
                    >
                      Last name <span style={{ color: 'red' }}> *</span>
                    </Typography>
                    <TextField
                      className={classes.search}
                      name="lastName"
                      autoComplete="namer"
                      type="text"
                      fullWidth
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      variant="outlined"
                      value={formik.values.lastName}
                    />
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.lastName &&
                        formik.touched.lastName &&
                        formik.errors.lastName}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={12} md={12} sm={12}>
                    <Typography
                      variant="h6"
                      align="left"
                      color={colors.grey[700]}
                    >
                      Birth date <span style={{ color: 'red' }}> *</span>
                    </Typography>
                    <Flatpickr
                      render={({ defaultValue, value, ...props }, ref) => {
                        return (
                          <CustomInputDate
                            classes={classes}
                            labelText=""
                            formik={formik}
                            name="birthDate"
                            formikValue={formik.values.birthDate}
                            formikTouched={formik.touched.birthDate}
                            formikError={formik.errors.birthDate}
                            defaultValue={defaultValue}
                            value={value}
                            inputRef={ref}
                            {...props}
                          />
                        );
                      }}
                      options={{ disableMobile: true }}
                      onChange={([date]) => {
                        formik.setFieldValue(
                          'birthDate',
                          dateFormat(new Date(date))
                        );
                      }}
                    />
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.birthDate &&
                        formik.touched.birthDate &&
                        formik.errors.birthDate}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography
                      variant="h6"
                      align="left"
                      color={colors.grey[700]}
                    >
                      Nationality <span style={{ color: 'red' }}> *</span>
                    </Typography>
                    <Autocomplete
                      size="small"
                      fullWidth
                      id="nationality"
                      onInputChange={(event, newInputValue) => {
                        formik.setFieldValue('nationality', newInputValue);
                      }}
                      options={nationalities.sort(
                        (a, b) => -b.localeCompare(a)
                      )}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="nationality"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          variant="outlined"
                          value={formik.values.nationality}
                        />
                      )}
                    />
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.nationality &&
                        formik.touched.nationality &&
                        formik.errors.nationality}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography
                      variant="h6"
                      align="left"
                      color={colors.grey[700]}
                    >
                      Address line 1 (f.e. 1234 Test Street){' '}
                      <span style={{ color: 'red' }}> *</span>
                    </Typography>
                    <TextField
                      className={classes.search}
                      name="street"
                      autoComplete="namer"
                      type="text"
                      fullWidth
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      variant="outlined"
                      value={formik.values.street}
                    />
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.street &&
                        formik.touched.street &&
                        formik.errors.street}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography
                      variant="h6"
                      align="left"
                      color={colors.grey[700]}
                    >
                      Address line 2 (apt, room, or unit number only)
                    </Typography>
                    <TextField
                      className={classes.search}
                      name="address"
                      autoComplete="namer"
                      type="text"
                      fullWidth
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      variant="outlined"
                      value={formik.values.address}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography
                      variant="h6"
                      align="left"
                      color={colors.grey[700]}
                    >
                      City <span style={{ color: 'red' }}> *</span>
                    </Typography>
                    <TextField
                      className={classes.search}
                      name="city"
                      autoComplete="namer"
                      type="text"
                      fullWidth
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      variant="outlined"
                      value={formik.values.city}
                    />
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.city &&
                        formik.touched.city &&
                        formik.errors.city}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography
                      variant="h6"
                      align="left"
                      color={colors.grey[700]}
                    >
                      State/Province <span style={{ color: 'red' }}> *</span>
                    </Typography>
                    <Autocomplete
                      size="small"
                      fullWidth
                      // value={value}
                      onChange={(event, newValue) => {
                        // console.log(newValue);
                        formik.setFieldValue('stateProvinceId', newValue.id);
                      }}
                      // inputValue={inputValue}
                      options={states}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, newValue) =>
                        option.id === newValue.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="stateProvinceId"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          variant="outlined"
                          value={formik.values.stateProvinceId}
                        />
                      )}
                      renderOption={(props, option, { inputValue }) => {
                        const matches = match(option.name, inputValue, {
                          insideWords: true,
                        });
                        const parts = parse(option.name, matches);

                        return (
                          <li {...props}>
                            <div>
                              {parts.map((part, index) => (
                                <span
                                  key={`${index + 1}`}
                                  style={{
                                    fontWeight: part.highlight ? 700 : 400,
                                  }}
                                >
                                  {part.text}
                                </span>
                              ))}
                            </div>
                          </li>
                        );
                      }}
                    />
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.stateProvinceId &&
                        formik.touched.stateProvinceId &&
                        formik.errors.stateProvinceId}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography
                      variant="h6"
                      align="left"
                      color={colors.grey[700]}
                    >
                      Zipcode <span style={{ color: 'red' }}> *</span>
                    </Typography>
                    <TextField
                      className={classes.search}
                      name="zipcode"
                      autoComplete="namer"
                      type="number"
                      fullWidth
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      onKeyDown={(e) => {
                        if (
                          e.key === 'e' ||
                          e.key === 'E' ||
                          e.key === '-' ||
                          e.key === '+'
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onInput={(e) => {
                        e.target.value = Math.max(
                          0,
                          parseInt(e.target.value, 10)
                        )
                          .toString()
                          .slice(0, 5);
                      }}
                      variant="outlined"
                      value={formik.values.zipcode}
                    />
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.zipcode &&
                        formik.touched.zipcode &&
                        formik.errors.zipcode}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography
                      variant="h6"
                      align="left"
                      color={colors.grey[700]}
                    >
                      Phone <span style={{ color: 'red' }}> *</span>
                    </Typography>
                    <InputMask
                      className={classes.inputmask}
                      autoComplete="off"
                      size="small"
                      onChange={formik.handleChange}
                      name="phone"
                      onBlur={formik.handleBlur}
                      value={formik.values.phone}
                      placeholder="+1 _ _ _ - _ _ _ - _ _ _ _ "
                      mask="+1 999-999-9999"
                      maskChar="_"
                      style={{
                        border: '1px solid #c4c4c4',
                        outline: '1px solid #F7F8FA',
                        paddingLeft: '14px',
                      }}
                    />
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.phone &&
                        formik.touched.phone &&
                        formik.errors.phone}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography
                      variant="h6"
                      align="left"
                      color={colors.grey[700]}
                    >
                      Alternate phone
                    </Typography>
                    <InputMask
                      className={classes.inputmask}
                      autoComplete="off"
                      size="small"
                      onChange={formik.handleChange}
                      name="alternatePhone"
                      onBlur={formik.handleBlur}
                      value={formik.values.alternatePhone}
                      placeholder="+1 _ _ _ - _ _ _ - _ _ _ _ "
                      mask="+1 999-999-9999"
                      maskChar="_"
                      style={{
                        border: '1px solid #c4c4c4',
                        outline: '1px solid #F7F8FA',
                        paddingLeft: '14px',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography
                      variant="h6"
                      align="left"
                      color={colors.grey[700]}
                    >
                      Email <span style={{ color: 'red' }}> *</span>
                    </Typography>
                    <div className={classes.verifyEmail}>
                      <TextField
                        disabled={sendCodeEmail}
                        className={classes.search}
                        name="email"
                        autoComplete="namer"
                        type="text"
                        fullWidth
                        size="small"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        variant="outlined"
                        value={formik.values.email}
                      />
                      {sendCodeEmail ? (
                        <IconButton
                          aria-label="delete"
                          size="medium"
                          onClick={() => {
                            setSendCodeEmail(false);
                            setConfirmEmail(false);
                            setConfirmEmailDuring(false);
                          }}
                        >
                          <ModeEditOutlinedIcon fontSize="inherit" />
                        </IconButton>
                      ) : null}
                    </div>
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.email &&
                        formik.touched.email &&
                        formik.errors.email}
                    </FormHelperText>
                    {/* <>
                      {!sendCodeEmail ? (
                        <Button
                          disabled={!isValidEmail(formik.values.email)}
                          fullWidth
                          size="small"
                          variant="contained"
                          sx={{
                            textTransform: 'none',
                            fontSize: 14,
                            transition: 'all 0.3s ease-in-out',
                            backgroundColor: '#2A455F',
                            '&:hover': {
                              backgroundColor: '#2A455F',
                              opacity: 0.8,
                            },
                          }}
                          color="error"
                          onClick={() => {
                            setLoading(true);
                            usersApi
                              .sendConfirmationCode2(id, {
                                email: formik.values.email,
                              })
                              .then((res) => {
                                setLoading(false);
                                toast.success(res, {
                                  transition: Flip,
                                });

                                setSendCodeEmail(true);
                                clearTimer(getDeadTime());
                              })
                              .catch((err) => {
                                setLoading(false);
                                console.log(err.response);
                                toast.error(err.response.data, {
                                  transition: Flip,
                                });
                              });
                          }}
                        >
                          Verify
                        </Button>
                      ) : (
                        !confirmEmailDuring && (
                          <>
                            <Typography
                              variant="h6"
                              align="left"
                              color={colors.grey[700]}
                            >
                              {timer !== '00:00'
                                ? 'Enter the code we have sent your email'
                                : ''}
                            </Typography>

                            {timer !== '00:00' ? (
                              <>
                                <div className={classes.verifyEmail}>
                                  <TextField
                                    className={classes.search}
                                    autoComplete="namer"
                                    type="text"
                                    // fullWidth
                                    size="small"
                                    id="code"
                                    variant="outlined"
                                  />
                                  <Button
                                    sx={{
                                      backgroundColor: '#2A455F',
                                      color: '#FFFFFF',
                                      textTransform: 'none',
                                      fontSize: 14,
                                    }}
                                    variant="contained"
                                    // startIcon={<AddIcon />}
                                    onClick={() => {
                                      setLoading(true);
                                      usersApi
                                        .formEmailConfirmationCode(id, {
                                          email: formik.values.email,
                                          code: document.querySelector('#code')
                                            .value,
                                        })
                                        .then((res) => {
                                          setConfirmEmailDuring(true);
                                          setConfirmEmail(true);
                                          setLoading(false);
                                          toast.success(res, {
                                            transition: Flip,
                                          });
                                        })
                                        .catch((err) => {
                                          setLoading(false);
                                          console.log(err.response);
                                          toast.error(
                                            err.response.data.message,
                                            {
                                              transition: Flip,
                                            }
                                          );
                                        });
                                    }}
                                    // color="info"
                                  >
                                    Confirm code
                                  </Button>
                                </div>
                                <Typography color={colors.grey[900]}>
                                  {timer}
                                </Typography>
                              </>
                            ) : (
                              <div className={classes.verifyEmail}>
                                {timer !== '00:00' ? null : (
                                  <>
                                    <Typography
                                      onClick={resend}
                                      sx={{
                                        color: 'rgba(22, 22, 22, 0.6) ',
                                        fontSize: '14px',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                        transition: 'all 0.3s ease-in-out',
                                        '&:hover': {
                                          color: '#2A455F',
                                        },
                                      }}
                                    >
                                      Resend
                                    </Typography>
                                  </>
                                )}
                              </div>
                            )}
                          </>
                        )
                      )}
                      {confirmEmail ? (
                        <>
                          <Typography
                            variant="h6"
                            align="left"
                            color={colors.green[800]}
                          >
                            Verified
                          </Typography>
                        </>
                      ) : null}
                    </> */}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={6} xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} sm={12} my={1}>
                    <Typography
                      variant="h6"
                      align="left"
                      color={colors.grey[700]}
                    >
                      Social security number (SSN){' '}
                      <span style={{ color: 'red' }}> *</span>
                    </Typography>
                    <InputMask
                      className={classes.inputmask}
                      autoComplete="off"
                      size="small"
                      onChange={formik.handleChange}
                      name="socialSecurityNumber"
                      onBlur={formik.handleBlur}
                      value={formik.values.socialSecurityNumber}
                      placeholder="_ _ _ - _ _ - _ _ _ _ "
                      mask="999-99-9999"
                      maskChar="_"
                      style={{
                        border: '1px solid #c4c4c4',
                        outline: '1px solid #F7F8FA',
                        paddingLeft: '14px',
                      }}
                      error={
                        formik.touched.socialSecurityNumber &&
                        Boolean(formik.errors.socialSecurityNumber)
                      }
                    />
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.socialSecurityNumber &&
                        formik.touched.socialSecurityNumber &&
                        formik.errors.socialSecurityNumber}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography
                      variant="h6"
                      align="left"
                      color={colors.grey[700]}
                    >
                      License issuing state{' '}
                      <span style={{ color: 'red' }}> *</span>
                    </Typography>
                    <Autocomplete
                      size="small"
                      fullWidth
                      // value={value}
                      onChange={(event, newValue) => {
                        formik.setFieldValue(
                          'licenseIssuedJurisdictionId',
                          newValue.id
                        );
                      }}
                      // inputValue={inputValue}
                      options={states.sort(
                        (a, b) => -b.name.localeCompare(a.name)
                      )}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, newValue) =>
                        option.id === newValue.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="licenseIssuedJurisdictionId"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          variant="outlined"
                          value={formik.values.licenseIssuedJurisdictionId}
                        />
                      )}
                      renderOption={(props, option, { inputValue }) => {
                        const matches = match(option.name, inputValue, {
                          insideWords: true,
                        });
                        const parts = parse(option.name, matches);

                        return (
                          <li {...props}>
                            <div>
                              {parts.map((part, index) => (
                                <span
                                  key={`${index + 1}`}
                                  style={{
                                    fontWeight: part.highlight ? 700 : 400,
                                  }}
                                >
                                  {part.text}
                                </span>
                              ))}
                            </div>
                          </li>
                        );
                      }}
                    />
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.licenseIssuedJurisdictionId &&
                        formik.touched.licenseIssuedJurisdictionId &&
                        formik.errors.licenseIssuedJurisdictionId}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography
                      variant="h6"
                      align="left"
                      color={colors.grey[700]}
                    >
                      CDL number <span style={{ color: 'red' }}> *</span>
                    </Typography>
                    <TextField
                      className={classes.search}
                      name="licenseNumber"
                      autoComplete="namer"
                      type="text"
                      fullWidth
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      onKeyDown={(e) => {
                        if (e.key === '-') {
                          e.preventDefault();
                        }
                      }}
                      variant="outlined"
                      value={formik.values.licenseNumber}
                    />
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.licenseNumber &&
                        formik.touched.licenseNumber &&
                        formik.errors.licenseNumber}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography
                      variant="h6"
                      align="left"
                      color={colors.grey[700]}
                    >
                      CDL expire date <span style={{ color: 'red' }}> *</span>
                    </Typography>
                    <Flatpickr
                      // id="cdlExpireDate"
                      // className="form-control"
                      render={({ defaultValue, value, ...props }, ref) => {
                        return (
                          <CustomInputDate
                            classes={classes}
                            labelText=""
                            name="licenseExpiration"
                            formik={formik}
                            formikValue={formik.values.licenseExpiration}
                            formikTouched={formik.touched.licenseExpiration}
                            formikError={formik.errors.licenseExpiration}
                            defaultValue={defaultValue}
                            value={value}
                            inputRef={ref}
                            {...props}
                          />
                        );
                      }}
                      options={{ disableMobile: true }}
                      onChange={([date]) => {
                        formik.setFieldValue(
                          'licenseExpiration',
                          dateFormat(new Date(date))
                        );
                      }}
                      // options={{
                      //   dateFormat: 'Z',
                      //   altInput: true,
                      //   altFormat: 'm-d-Y',
                      // }}
                    />
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.licenseExpiration &&
                        formik.touched.licenseExpiration &&
                        formik.errors.licenseExpiration}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography
                      variant="h6"
                      align="left"
                      color={colors.grey[700]}
                    >
                      CDL issue date <span style={{ color: 'red' }}> *</span>
                    </Typography>
                    <Flatpickr
                      // id="cdlExpireDate"
                      // className="form-control"
                      render={({ defaultValue, value, ...props }, ref) => {
                        return (
                          <CustomInputDate
                            classes={classes}
                            labelText=""
                            name="licenseIssueTime"
                            formik={formik}
                            formikValue={formik.values.licenseIssueTime}
                            formikTouched={formik.touched.licenseIssueTime}
                            formikError={formik.errors.licenseIssueTime}
                            defaultValue={defaultValue}
                            value={value}
                            inputRef={ref}
                            {...props}
                          />
                        );
                      }}
                      options={{ disableMobile: true }}
                      onChange={([date]) => {
                        formik.setFieldValue(
                          'licenseIssueTime',
                          dateFormat(new Date(date))
                        );
                      }}
                      // options={{
                      //   dateFormat: 'Z',
                      //   altInput: true,
                      //   altFormat: 'm-d-Y',
                      // }}
                    />
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.licenseIssueTime &&
                        formik.touched.licenseIssueTime &&
                        formik.errors.licenseIssueTime}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography
                      variant="h6"
                      align="left"
                      color={colors.grey[700]}
                    >
                      Med Card/DOT physcial expire date{' '}
                      <span style={{ color: 'red' }}> *</span>
                    </Typography>
                    <Flatpickr
                      // id="cdlExpireDate"
                      // className="form-control"
                      render={({ defaultValue, value, ...props }, ref) => {
                        return (
                          <CustomInputDate
                            classes={classes}
                            labelText=""
                            formik={formik}
                            formikValue={formik.values.medicalCardRenewal}
                            formikTouched={formik.touched.medicalCardRenewal}
                            formikError={formik.errors.medicalCardRenewal}
                            defaultValue={defaultValue}
                            value={value}
                            inputRef={ref}
                            {...props}
                          />
                        );
                      }}
                      options={{ disableMobile: true }}
                      onChange={([date]) => {
                        formik.setFieldValue(
                          'medicalCardRenewal',
                          dateFormat(new Date(date))
                        );
                      }}
                      // options={{
                      //   dateFormat: 'Z',
                      //   altInput: true,
                      //   altFormat: 'm-d-Y',
                      // }}
                    />
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.medicalCardRenewal &&
                        formik.touched.medicalCardRenewal &&
                        formik.errors.medicalCardRenewal}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography
                      variant="h6"
                      align="left"
                      color={colors.grey[700]}
                    >
                      CDL (old, new if available){' '}
                      <span style={{ color: 'red' }}> *</span>
                    </Typography>
                    <input
                      id="licenseFiles"
                      style={{ display: 'none' }}
                      type="file"
                      multiple
                      hidden
                      onChange={(e) => {
                        handleDrop(e.target.files);
                      }}
                      name="[licenseFiles]"
                    />

                    <DragAndDrop handlerDrop={handleDrop}>
                      <div
                        style={{
                          minHeight: '250px',
                          maxHeight: '250px',
                          // display: 'flex',
                          // gap: '6rem',
                          // minWidth: '400px',
                        }}
                      >
                        {filesUpload.map((file, i) => {
                          return (
                            <AttachmentItem
                              key={`${i + 1}`}
                              file={file}
                              id={file.id}
                              name={file.name}
                              size={file.file.size}
                              downloadStatus={false}
                              filesUpload={filesUpload}
                              setFilesUpload={setFilesUpload}
                              i={i}
                            />
                          );
                        })}
                      </div>
                    </DragAndDrop>

                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.licenseFiles &&
                        formik.touched.licenseFiles &&
                        formik.errors.licenseFiles}
                    </FormHelperText>
                    <Button
                      color="primary"
                      fullWidth
                      size="medium"
                      variant="contained"
                      onClick={() => {
                        document.querySelector('#licenseFiles').click();
                      }}
                    >
                      Select files
                    </Button>
                  </Grid>
                  {/* <Grid item xs={12} md={12} sm={12}>
                    <Typography
                      variant="h6"
                      align="left"
                      color={colors.grey[700]}
                    >
                      CDL (old, new if available){' '}
                      <span style={{ color: 'red' }}> *</span>
                    </Typography>
                    <input
                      id="licenseFile"
                      style={{ display: 'none' }}
                      type="file"
                      hidden
                      onChange={(e) => {
                        formik.setFieldValue(
                          'licenseFile',
                          e.target.files[0].name
                        );
                        setLicenseFile(e.target.files[0]);
                        // uploadSSN(e.target.files[0]);
                      }}
                      name="[licenseFile]"
                    />
                    <TextField
                      disabled
                      name="licenseFile"
                      autoComplete="namer"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      fullWidth
                      size="small"
                      variant="outlined"
                      type="text"
                      value={formik.values.licenseFile}
                      onClick={() => {
                        document.querySelector('#licenseFile').click();
                      }}
                      InputProps={{
                        endAdornment: (
                          <IconButton component="label">
                            <UploadFileRoundedIcon />
                          </IconButton>
                        ),
                      }}
                    />
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.licenseFile &&
                        formik.touched.licenseFile &&
                        formik.errors.licenseFile}
                    </FormHelperText>
                    
                  </Grid> */}
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography
                      variant="h6"
                      align="left"
                      color={colors.grey[700]}
                    >
                      Medical card/DOT physcial
                      <span style={{ color: 'red' }}> *</span>
                    </Typography>
                    <input
                      id="medicalFile"
                      style={{ display: 'none' }}
                      type="file"
                      hidden
                      onChange={(e) => {
                        formik.setFieldValue(
                          'medicalFile',
                          e.target.files[0].name
                        );
                        setMedicalCartFile(e.target.files[0]);
                        // uploadSSN(e.target.files[0]);
                      }}
                      name="[medicalFile]"
                    />
                    <TextField
                      disabled
                      name="medicalFile"
                      autoComplete="namer"
                      fullWidth
                      size="small"
                      variant="outlined"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.medicalFile}
                      onClick={() => {
                        document.querySelector('#medicalFile').click();
                      }}
                      InputProps={{
                        endAdornment: (
                          <IconButton component="label">
                            <UploadFileRoundedIcon />
                          </IconButton>
                        ),
                      }}
                    />
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.licenseFile &&
                        formik.touched.licenseFile &&
                        formik.errors.licenseFile}
                    </FormHelperText>
                    {/* <Label for="customFile">
                      Medical card/DOT physcial{' '}
                      <span style={{ color: 'red' }}> *</span>
                    </Label> */}
                    {/* <CustomInput
                      type="file"
                      id="medicalCart"
                      onInput={(e) => uploadMedicalCart(e.target.files[0])}
                    /> */}
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography
                      variant="h6"
                      align="left"
                      color={colors.grey[700]}
                    >
                      SSN file
                      <span style={{ color: 'red' }}> *</span>
                    </Typography>
                    <input
                      id="ssnFile"
                      style={{ display: 'none' }}
                      type="file"
                      hidden
                      onChange={(e) => {
                        formik.setFieldValue('ssnFile', e.target.files[0].name);
                        setSsnFile(e.target.files[0]);
                        // uploadSSN(e.target.files[0]);
                      }}
                      name="[ssnFile]"
                    />
                    <TextField
                      disabled
                      name="ssnFile"
                      autoComplete="namer"
                      fullWidth
                      size="small"
                      variant="outlined"
                      type="text"
                      value={formik.values.ssnFile}
                      onClick={() => {
                        document.querySelector('#ssnFile').click();
                      }}
                      InputProps={{
                        endAdornment: (
                          <IconButton component="label">
                            <UploadFileRoundedIcon />
                          </IconButton>
                        ),
                      }}
                    />
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.ssnFile &&
                        formik.touched.ssnFile &&
                        formik.errors.ssnFile}
                    </FormHelperText>
                  </Grid>
                  {/* <Grid item xs={12} md={12} sm={12}>
                    <Col md="4">
                      <span>Selfie</span>
                      <span style={{ color: 'red' }}> *</span>
                    </Col>
                    <Col md="8">
                      <Box
                        sx={{
                          py: 2,
                        }}
                      >
                        <CardMedia
                          component="img"
                          style={{
                            width: 210,
                            height: 210,
                            borderRadius: 5,
                            objectFit: 'fill',
                          }}
                          image={selfie || UploadIcon}
                          alt=""
                        />
                        <FormHelperText
                          sx={{
                            color: colors.red[700],
                          }}
                        >
                          {formik.errors.photoFile &&
                            formik.touched.photoFile &&
                            formik.errors.photoFile}
                        </FormHelperText>
                        
                        <Box
                          sx={{
                            py: 0.5,
                          }}
                        />
                        <Button
                          // type="file"
                          fullWidth
                          size="small"
                          variant="contained"
                          sx={{
                            width: 210,
                            textTransform: 'none',
                            fontSize: 20,
                            transition: 'all 0.3s ease-in-out',
                            backgroundColor: '#2A455F',
                            '&:hover': {
                              backgroundColor: '#2A455F',
                              opacity: 0.8,
                            },
                          }}
                          color="error"
                          onClick={() => {
                            toggleCamera();
                          }}
                        >
                          Camera
                        </Button>
                      </Box>
                    </Col>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
            <Box
              sx={{
                py: 1.5,
              }}
            >
              <Button
                color="success"
                fullWidth
                size="medium"
                variant="contained"
                type="submit"
              >
                <Icon.Send size={22} /> &nbsp;Send
              </Button>
            </Box>
          </form>
        </Container>
      </Card>
    </>
  );
}
