/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Input, Col } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import { toast, Flip } from 'react-toastify';
import { FormControlLabel, Checkbox } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ModalWindow from '../../../../../components/main/Modals';
import GoogleMapsModalWindow from '../../../../../components/main/Modals/GoogleMaps';
import PilotFuelPricesModal from '../PilotFuelPrices';
import LovesFuelPricesModal from '../LovesFuelPrices';
import fuelService from '../../../../../services/fuel';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

export default function BookModal({
  modal,
  loadTruckOptions,
  loadShopOptions,
  toggle,
  setterFuelShopType,
}) {
  const [truck, setTruck] = useState(null);

  const [truckSelected, setTruckSelected] = useState(null);
  const [shopSelected, setShopSelected] = useState(null);

  const [driverId, setDriverId] = useState(null);
  const [fuelShopTypeList, setFuelShopTypeList] = useState(['Pilot', 'Loves']);
  const [fuelShopType, setFuelShopType] = useState(0);
  const [address, setAddress] = useState('');
  const [location, setLocation] = useState('');
  const [fuelShopId, setFuelShopId] = useState(null);
  const [isFull, setIsFull] = useState(false);
  const [fuelAmount, setFuelAmount] = useState(0);

  const [driver, setDriver] = useState(' - ');
  const [interState, setInterState] = useState('');

  const [showPilotFuelPriceModal, setShowPilotFuelPriceModal] = useState(false);
  const [showLovesFuelPriceModal, setShowLovesFuelPriceModal] = useState(false);
  const [showGoogleMapsModal, setShowGoogleMapsModal] = useState(false);

  const newBook = () => {
    if (truck === null) {
      toast.error('Please, select truck', {
        transition: Flip,
      });
    } else if (fuelShopId === null) {
      toast.error('Please, select fuel shop place', {
        transition: Flip,
      });
    } else {
      const sendingData = {
        vehicleId: truck,
        driverId,
        isFull,
        fuelAmount: parseInt(document.querySelector('#fuel_amount').value, 10),
        fuelShopId,
        fuelShopType: fuelShopTypeList[fuelShopType],
        note: document.querySelector('#note').value,
      };

      fuelService
        .createBook(sendingData)
        .then((data) => {
          toast.success('Book successfuly edited', {
            transition: Flip,
          });
          toggle();
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            transition: Flip,
          });
          // toast.error("Something went wrong", { transition: Flip });
          return Promise.reject();
        });
    }
  };

  const sendInterStateData = () => {
    const data = {
      type: fuelShopTypeList[fuelShopType],
      store: shopSelected.store,
      data: document.querySelector('#interstate').value,
    };

    fuelService
      .updateInterState(data)
      .then((data) => {
        toast.success('Successfuly edited', {
          transition: Flip,
        });
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
        // toast.error("Something went wrong", { transition: Flip });
        return Promise.reject();
      });
  };

  useEffect(() => {}, []);

  return (
    <>
      <GoogleMapsModalWindow
        title="Google Maps"
        modal={showGoogleMapsModal}
        handleModal={() => {
          setShowGoogleMapsModal(false);
        }}
        maxWidth="md"
        body={
          <div style={{ width: '100%' }}>
            <iframe
              width="100%"
              height="600"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              referrerPolicy="no-referrer-when-downgrade"
              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBg_HOHrN9beMc1xiSfkz_P3wbwK07rvzY&q=Tower, USA&center=${location}&zoom=18&maptype=satellite`}
            >
              {}
            </iframe>
          </div>
        }
      />
      <ModalWindow
        title="Pilot fuel prices modal"
        modal={showPilotFuelPriceModal}
        handleModal={() => {
          setShowPilotFuelPriceModal(false);
        }}
        maxWidth="md"
        body={
          <PilotFuelPricesModal
            disabled={true}
            modal={showPilotFuelPriceModal}
            toggle={() => {
              setShowPilotFuelPriceModal(false);
            }}
          />
        }
      />
      <ModalWindow
        title="Loves fuel prices modal"
        modal={showLovesFuelPriceModal}
        handleModal={() => {
          setShowLovesFuelPriceModal(false);
        }}
        maxWidth="md"
        body={
          <LovesFuelPricesModal
            disabled={true}
            modal={showLovesFuelPriceModal}
            toggle={() => {
              setShowLovesFuelPriceModal(false);
            }}
          />
        }
      />

      <Form>
        <FormGroup className="align-items-center" row>
          <Col md="4">
            <span>Truck number</span>
            <span style={{ color: 'red' }}> *</span>
          </Col>
          <Col md="8" style={{ zIndex: 10000 }}>
            <div style={{}}>
              <AsyncSelect
                noOptionsMessage={(value) =>
                  !value.inputValue
                    ? 'type something to search'
                    : 'nothing to show'
                }
                placeholder="Search"
                isClearable={true}
                defaultValue={null}
                loadOptions={loadTruckOptions}
                value={truckSelected}
                onChange={(value) => {
                  if (value == null) {
                    setTruck(null);
                    setTruckSelected(null);
                    setDriver(' - ');
                    setDriverId(null);
                  } else {
                    setTruck(value.value);
                    setTruckSelected(value);
                    setDriver(value.driver);
                    setDriverId(value.driverId);
                  }
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary50: '#fe810b',
                    primary25: 'rgb(253, 179, 46)',
                    primary: 'rgb(253, 179, 46)',
                  },
                })}
              />
            </div>
          </Col>
        </FormGroup>
        <FormGroup className="align-items-center" row>
          <Col md="4">
            <span>Driver</span>
          </Col>
          <Col md="8">
            <span>{driver}</span>
          </Col>
        </FormGroup>
        <FormGroup className="align-items-center" row>
          <Col md="4">
            <span>Fuel shop</span>
          </Col>
          <Col md="8">
            <FormControl sx={{ width: 200 }} size="small">
              <InputLabel id="demo-simple-select-label0">
                Select fuel shop <span style={{ color: 'red' }}> *</span>
              </InputLabel>
              <Select
                labelId="demo-simple-select-label0"
                id="demo-simple-select"
                value={fuelShopType}
                label="Select fuel shop"
                MenuProps={MenuProps}
                onChange={(e) => {
                  const { value } = e.target;
                  setAddress('');
                  setLocation('');
                  if (value === undefined) {
                    setFuelShopType(null);
                  } else {
                    setFuelShopType(value);
                    setterFuelShopType(value);
                  }
                }}
              >
                {fuelShopTypeList.map((item, i) => {
                  return (
                    <MenuItem key={`${i + 1}`} value={i}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            &nbsp;
            <Button
              color="success"
              onClick={() => {
                if (fuelShopType === 0) {
                  setShowPilotFuelPriceModal(true);
                } else {
                  setShowLovesFuelPriceModal(true);
                }
              }}
            >
              Open price list
            </Button>
          </Col>
        </FormGroup>
        <FormGroup className="align-items-center" row>
          <Col md="4">
            <span>Fuel place</span>
            <span style={{ color: 'red' }}> *</span>
          </Col>
          <Col md="8" style={{ zIndex: 10000 }}>
            <div>
              <AsyncSelect
                noOptionsMessage={(value) =>
                  !value.inputValue
                    ? 'type something to search'
                    : 'nothing to show'
                }
                placeholder="Search"
                isClearable={true}
                defaultValue={null}
                loadOptions={loadShopOptions}
                value={shopSelected}
                onChange={(value) => {
                  console.log(value);
                  if (value == null) {
                    setFuelShopId(null);
                    setShopSelected(null);
                    setInterState('');
                    setAddress('');
                  } else {
                    setFuelShopId(value.id);
                    setShopSelected(value);

                    if (fuelShopType === 1) {
                      setInterState(
                        `Address: ${value.address} \nCity: ${value.city}, ${
                          value.state
                        } \nZipcode: ${value.zip} \nInterState: ${
                          value.highwayExit ? value.highwayExit : ''
                        }`
                      );
                      setLocation(`${value.latitude}, ${value.longitude}`);
                    } else {
                      setLocation('');
                      setAddress(`City: ${value.city}, ${value.state}`);
                      setInterState(
                        `${value.highwayExit ? value.highwayExit : ''}`
                      );
                    }
                  }
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary50: '#fe810b',
                    primary25: 'rgb(253, 179, 46)',
                    primary: 'rgb(253, 179, 46)',
                  },
                })}
              />
            </div>
          </Col>
        </FormGroup>
        <FormGroup className="align-items-center" row>
          <Col md="4">
            <span>Interstate</span>
          </Col>
          <Col md="8">
            <div>
              {address !== '' ? <span>{address} &nbsp; &nbsp; </span> : null}
              {fuelShopType === 1 && location !== '' ? (
                <Button
                  color="success"
                  onClick={() => {
                    setShowGoogleMapsModal(true);
                  }}
                >
                  Google maps
                </Button>
              ) : null}
              &nbsp;
              <Input
                rows={5}
                // cols={6}
                type="textarea"
                autoComplete="off"
                value={interState}
                id="interstate"
                disabled={fuelShopType === 1}
                onChange={(e) => {
                  setInterState(e.target.value);
                }}
              />
              {fuelShopType === 0 ? (
                <Button color="success" onClick={() => sendInterStateData()}>
                  Save
                </Button>
              ) : null}
            </div>
          </Col>
        </FormGroup>
        <FormGroup className="align-items-center" row>
          <Col md="4">
            <span>Fuel amount</span>
            <span style={{ color: 'red' }}> *</span>
          </Col>
          <Col md="8">
            <FormControlLabel
              id="is_full"
              name="is_full"
              control={<Checkbox />}
              label="Is full ?"
              checked={isFull}
              onChange={(val) => {
                setIsFull(!isFull);
              }}
            />
            <Input
              disabled={isFull}
              type="text"
              autoComplete="off"
              value={fuelAmount}
              id="fuel_amount"
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === '' || re.test(e.target.value)) {
                  setFuelAmount(e.target.value);
                }
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup className="align-items-center" row>
          <Col md="4">
            <span>Notes</span>
          </Col>
          <Col md="8">
            <Input type="textarea" id="note" />
          </Col>
        </FormGroup>

        <FormGroup className="align-items-right" row>
          <Button color="primary" onClick={() => newBook()}>
            Submit
          </Button>
        </FormGroup>
      </Form>
    </>
  );
}
