export function dataSelector(dataResponse, page, size) {
  const filtered = dataResponse.data.map((item, i) => ({
    index: i + 1 + page * size,
    id: item.id,
    title: item.title,
    description: item.description,
    createdAt: item.createdAt,
    isForAll: item.isForAll ? 1 : 0,
    deleted: item.deleted,
    driverName: item.driverNames.join(',\n'),
    data: item,
    dataDriver: item,
  }));
  return { total: dataResponse.total_elements, data: filtered };
}
