/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useMemo, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Media,
  Row,
  Col,
  Button,
} from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Flatpickr from 'react-flatpickr';
import { toast, Flip } from 'react-toastify';
import '../../../../../assets/scss/pages/users.scss';
import jwtDecode from 'jwt-decode';
import unitService from '../../../../../services/units';
import stateProvinceService from '../../../../../services/state_province';
import tripService from '../../../../../services/trips';
import { fetchData } from '../../../../../redux/modules/trips/actions';
import { useQuery } from '../../../useQuery';
import Loading from '../../../../../components/main/Loading';
import Table from '../../../../../components/main/table';
import { unitTripsHeader } from '../../../../../redux/constants/table/headers';

export default function UnitViewModal({ id, headerMaker }) {
  const {
    query,
    search,
    status,
    pageIndex,
    pageSize,
    setPageIndex,
    setPageSize,
    setSearch,
    setStatus,
    setTripStatusId,
    setTruckId,
    handleOnTableChange,
  } = useQuery({ fetchData });

  const [states, setStates] = useState([]);
  const [driverStatuses, setDriverStatuses] = useState([]);
  const [datas, setDatas] = useState({});
  const [trips, setTrips] = useState();

  const [statusChanged, setStatusChanged] = useState([]);
  const [prevFiles, setPrevFiles] = useState([]);
  const [unitStatuses, setUnitStatuses] = useState([]);
  const [statuss, setStatuss] = useState();
  const [eldUnTil, setEldUnTil] = useState();
  const [modal, setModal] = useState(false);
  const [initialLocation, setInitialLocation] = useState();

  const token = localStorage.getItem(
    localStorage.getItem('current_account') + '_access_token'
  );
  const decodedToken = jwtDecode(token.replace('Bearer ', ''));

  const headers = useMemo(
    () => headerMaker(unitTripsHeader),
    [unitTripsHeader]
  );

  const { data, loading, total } = useSelector((state) => state.tripsReducer);
  localStorage.setItem('total_data', total);

  const createHeaders = (headerData) => {
    const cData = headerData.map((item) => ({
      item,
      // ref: useRef(),
    }));
    // console.log(cData);

    // setColumns(cData);

    return cData;
  };

  const [columns, setColumns] = useState(createHeaders(headers));

  const handleModal = () => {
    setModal(!modal);
  };

  const setterStatus = (ids) => {
    const data = {
      eldUnTil:
        statuss === 7 && document.querySelector('#eldUnTil')
          ? Date.parse(document.querySelector('#eldUnTil').value)
          : null,
    };
    unitService
      .updateStatus(id, ids, data)
      .then((res) => {
        if (res) {
          toast.success('Status successfuly changed', {
            transition: Flip,
          });
          setStatuss(ids);
          setStatusChanged(false);
        } else {
          toast.error('Something went wrong', { transition: Flip });
          // res.text();
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const componentDidMount = () => {
    // updateTripsList();
    stateProvinceService
      .getAll()
      .then((data) => {
        setStates(data);

        unitService
          .getContext()
          .then((data) => {
            setUnitStatuses(data.unit_statuses);
            unitService
              .getOne(id)
              .then((data) => {
                if (data.files !== null && typeof data.files !== 'object') {
                  const fileIdsData = new Map();
                  const prevFilesData = [];
                  data.files.forEach((key) => {
                    prevFilesData.push({
                      id: parseInt(key, 10),
                      name: data.files[key],
                    });
                    fileIdsData.set(parseInt(key, 10), data.files[key]);
                  });

                  setPrevFiles(prevFilesData);
                }
                setDatas(data);
                setStatuss(data.unitStatusId);
                setEldUnTil(data.eldUnTil);
                setInitialLocation(data.initialLocation);
              })
              .catch((err) => {
                toast.error(err.response.data.message, {
                  transition: Flip,
                });
              });
          })
          .catch((err) => {
            toast.error(err.response.data.message, {
              transition: Flip,
            });
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  useEffect(() => {
    setTruckId(id);
    componentDidMount();
  }, []);

  return (
    <>
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader>
              <CardTitle>Unit</CardTitle>
            </CardHeader>
            <CardBody>
              <Row className="mx-0" col="12">
                <Col className="pl-0" sm="12">
                  <Media className="d-sm-flex d-block">
                    <Media body>
                      <Row>
                        <Col sm="9" md="6" lg="6">
                          <div className="users-page-view-table">
                            <div className="d-flex user-info">
                              <div className="user-info-title font-weight-bold">
                                Number
                              </div>
                              <div>{datas.number}</div>
                            </div>
                            <div className="d-flex user-info">
                              <div className="user-info-title font-weight-bold">
                                Type
                              </div>
                              <div className="text-truncate">
                                <span>{datas.unitTypeName}</span>
                              </div>
                            </div>
                            <div className="d-flex user-info">
                              <div className="user-info-title font-weight-bold">
                                Ownership Type
                              </div>
                              <div className="text-truncate">
                                <span>{datas.unitName}</span>
                              </div>
                            </div>
                            <div className="d-flex user-info">
                              <div className="user-info-title font-weight-bold">
                                Description
                              </div>
                              <div>{datas.description}</div>
                            </div>
                          </div>
                        </Col>
                        <Col md="12" lg="6">
                          <div className="users-page-view-table">
                            <div className="d-flex user-info">
                              <div className="user-info-title font-weight-bold">
                                Initial Location:
                              </div>
                              <div>
                                {initialLocation &&
                                  states.find((item) => {
                                    return (
                                      item.id ===
                                      parseInt(
                                        initialLocation.stateProvince,
                                        10
                                      )
                                    );
                                  }).name}
                              </div>
                            </div>
                            <div className="d-flex user-info">
                              <div className="user-info-title font-weight-bold">
                                City:
                              </div>
                              <div>
                                <span>
                                  {initialLocation && initialLocation.city}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex user-info">
                              <div className="user-info-title font-weight-bold">
                                Street:
                              </div>
                              <div>
                                <span>
                                  {data.initialLocation &&
                                    datas.initialLocation.street}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Media>
                  </Media>
                </Col>
                <Col className="mt-1 pl-0 d-flex" sm="12">
                  {decodedToken.role !== 'ROLE_DISPATCHER' &&
                    decodedToken.role !== 'ROLE_UPDATER' && (
                      <Link
                        to={`/units/edit/${id}`}
                        className="d-flex align-items-center text-white mr-1"
                      >
                        <Button
                          color="primary"
                          className="d-flex align-items-center"
                          type="button"
                        >
                          Edit Unit
                        </Button>
                      </Link>
                    )}
                  {statuss === 7 && (
                    <>
                      <Flatpickr
                        id="eldUnTil"
                        className="form-control eldUntil ml-1"
                        placeholder="ELD Until"
                        data-enable-time
                        options={{
                          dateFormat: 'Z',
                          altInput: true,
                          altFormat: 'm-d-Y H:i',
                        }}
                        value={eldUnTil}
                        onChange={(e) => {
                          setEldUnTil(Date.parse(e[0]));
                        }}
                      />
                    </>
                  )}
                  {statusChanged && (
                    <Button
                      color="success"
                      className="d-flex align-items-center ml-1"
                      type="button"
                      onClick={() => setterStatus(statuss)}
                    >
                      Submit
                    </Button>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col sm="6">
          <Card>
            <CardHeader>
              <CardTitle>Extra Info 1</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="users-page-view-table">
                <div className="d-flex user-info">
                  <div className="user-info-title font-weight-bold">VIN</div>
                  <div>{datas.vin}</div>
                </div>
                <div className="d-flex user-info">
                  <div className="user-info-title font-weight-bold">Make</div>
                  <div>{datas.make}</div>
                </div>
                <div className="d-flex user-info">
                  <div className="user-info-title font-weight-bold">Model</div>
                  <div className="text-truncate">
                    <span>{datas.model}</span>
                  </div>
                </div>
                <div className="d-flex user-info">
                  <div className="user-info-title font-weight-bold">
                    Year Purchased
                  </div>
                  <div className="text-truncate">
                    <span>{datas.yearPurchased}</span>
                  </div>
                </div>
                <div className="d-flex user-info">
                  <div className="user-info-title font-weight-bold">Year</div>
                  <div className="text-truncate">
                    <span>{datas.year}</span>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col sm="6">
          <Card>
            <CardHeader>
              <CardTitle>Extra Info 2</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="users-page-view-table">
                <div className="d-flex user-info">
                  <div className="user-info-title font-weight-bold">Make</div>
                  <div>{datas.make}</div>
                </div>
                <div className="d-flex user-info">
                  <div className="user-info-title font-weight-bold">
                    Purchase Price
                  </div>
                  <div className="text-truncate">
                    <span>{datas.purchasedPrice}</span>
                  </div>
                </div>
                <div className="d-flex user-info">
                  <div className="user-info-title font-weight-bold">
                    License Plate Number
                  </div>
                  <div className="text-truncate">
                    <span>{datas.licensePlateNumbe}</span>
                  </div>
                </div>
                <div className="d-flex user-info">
                  <div className="user-info-title font-weight-bold">
                    License Plate Expiration
                  </div>
                  <div className="text-truncate">
                    <span>{datas.licenseExpirationFormatted}</span>
                  </div>
                </div>
                <div className="d-flex user-info">
                  <div className="user-info-title font-weight-bold">
                    Inspection Sticker Expiration
                  </div>
                  <div className="text-truncate">
                    <span>{datas.inspectionStickerExpirationFormatted}</span>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm="6">
          <Card>
            <CardHeader>
              <CardTitle>Files</CardTitle>
            </CardHeader>
            <CardBody>
              {prevFiles &&
                prevFiles.map((item) => (
                  <div
                    key={item.id}
                    style={{
                      flex: '30%',
                      maxWidth: '30%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        width: 225,
                        cursor: 'pointer',
                      }}
                      className="mt-1"
                      href={`${process.env.REACT_APP_BASE_URL}/file/${item.id}`}
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_BASE_URL}/file/${item.id}`,
                          '_blank'
                        )
                      }
                    >
                      {item.name}
                    </div>
                  </div>
                ))}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Card className="overflow-hidden agGrid-card">
        <div className="d-flex justify-content-between m-2">
          <h3>Trips list</h3>
        </div>
        <CardBody className="py-0 no-pagination">
          <div className="w-100 ag-grid-table with-pagination">
            <Table
              data={data}
              headers={headers}
              // toolTips={toolTips}
              onChange={handleOnTableChange}
              modal={handleModal}
              columns={columns}
              setColumns={setColumns}
              // hidePagination={1}
            />
          </div>
        </CardBody>
      </Card>
    </>
  );
}
