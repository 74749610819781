import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ModalWindowFullScreen from '../../Modals/FullScreen';
import ViewDriverModal from '../../../../views/pages/Drivers/Modals/DriverModal';

export default ({ value }) => {
  const [driverModal, setDriverModal] = useState(false);
  const [driverId, setDriverId] = useState();

  return (
    <>
      <ModalWindowFullScreen
        title="Driver"
        modal={driverModal}
        handleModal={() => {
          setDriverModal(!driverModal);
        }}
        body={<ViewDriverModal modal={driverModal} id={driverId} />}
      />
      <Link
        onClick={() => {
          setDriverId(value.driverId);
          setDriverModal(true);
        }}
        // to={`/drivers/view/${value.driverId}`}
      >
        {value.driverName}
      </Link>
      {value.teammateId !== null && (
        <Link
          onClick={() => {
            setDriverId(value.teammateId);
            setDriverModal(true);
          }}
          // to={`/drivers/view/${value.teammateId}`}
        >
          {value.teammateName}
        </Link>
      )}
    </>
  );
};
