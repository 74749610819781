import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ColorPicker, createColor } from 'material-ui-color';
import { toast, Flip } from 'react-toastify';
import { Form, FormGroup, Input } from 'reactstrap';
import {
  Box,
  Button,
  Container,
  Typography,
  TextField,
  Grid,
  InputAdornment,
  Card,
  InputLabel,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { makeStyles, useTheme } from '@mui/styles';
import ownershipTypeService from '../../../../../../services/ownership_type';

const useStyles = makeStyles({
  root: {
    border: '1px solid red',
  },
});

export default function AddEditOwnershipTypeModal({
  modal,
  id,
  toggle,
  getList,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const schema = Yup.object().shape({
    name: Yup.string().required('Required'),
    abbreviation: Yup.string().required('Required'),
    driverCount: Yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      abbreviation: '',
      driverCount: 1,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (id) {
        const data = {
          id,
          name: values.name,
          abbreviation: values.abbreviation,
          driverCount: values.driverCount,
          deleted: values.deleted,
        };
        ownershipTypeService
          .update(data)
          .then((res) => {
            console.log(res);
            toggle();
          })
          .catch((err) => {
            console.log(err);
            toast.error(err.response.data.message, {
              transition: Flip,
            });
          });
      } else {
        const data = {
          name: values.name,
          abbreviation: values.abbreviation,
          driverCount: values.driverCount,
          deleted: values.deleted,
        };
        ownershipTypeService
          .create(data)
          .then((res) => {
            console.log(res);
            toggle();
          })
          .catch((err) => {
            console.log(err);
            toast.error(err.response.data.message, {
              transition: Flip,
            });
          });
      }
    },
  });

  useEffect(() => {}, [modal]);

  useEffect(() => {
    if (id) {
      console.log(id);
      ownershipTypeService
        .getOne(id)
        .then((res) => {
          formik.setValues({
            ...formik.values,
            name: res.name,
            abbreviation: res.abbreviation,
            driverCount: res.driverCount,
            deleted: res.deleted,
          });
        })
        .catch((err) => {});
    }
  }, [id]);

  return (
    <>
      <Container sx={{}}>
        <Card sx={{ padding: 2 }}>
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <Grid container my={2}>
              <Grid item xs={12} md={12} sm={12}>
                <TextField
                  InputProps={{
                    style: {
                      backgroundColor: theme.palette.background.paper,
                      '&:focus': {
                        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
                      },
                    },
                  }}
                  // InputLabelProps={{
                  //   sx: {
                  //     color: 'orange',
                  //     [`&.${inputLabelClasses.shrink}`]: {
                  //       color: 'red',
                  //     },
                  //   },
                  // }}
                  error={Boolean(formik.touched.name && formik.errors.name)}
                  size="small"
                  fullWidth
                  helperText={formik.touched.name && formik.errors.name}
                  label="Name"
                  name="name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.name}
                  variant="outlined"
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} md={12} sm={12} my={2}>
                <TextField
                  InputProps={{
                    style: {
                      backgroundColor: theme.palette.background.paper,
                      '&:focus': {
                        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
                      },
                    },
                  }}
                  // InputLabelProps={{
                  //   sx: {
                  //     color: 'orange',
                  //     [`&.${inputLabelClasses.shrink}`]: {
                  //       color: 'red',
                  //     },
                  //   },
                  // }}
                  error={Boolean(
                    formik.touched.abbreviation && formik.errors.abbreviation
                  )}
                  size="small"
                  fullWidth
                  helperText={
                    formik.touched.abbreviation && formik.errors.abbreviation
                  }
                  label="Abbreviation"
                  name="abbreviation"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.abbreviation}
                  variant="outlined"
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} md={12} sm={12}>
                <FormControl fullWidth>
                  <InputLabel id="drviercount-select-label">
                    Driver count
                  </InputLabel>
                  <Select
                    name="driverCount"
                    size="small"
                    labelId="drviercount-select-label"
                    id="demo-simple-select"
                    value={formik.values.driverCount}
                    label="Driver count"
                    // onBlur={formik.handleBlur}
                    onChange={(event) => {
                      console.log(event);
                      formik.setFieldValue('driverCount', event.target.value);
                    }}
                  >
                    {[1, 2].map((item, i) => {
                      return (
                        <MenuItem key={`${i + 1}`} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              onClick={() => {
                // addDamageCategory();
              }}
            >
              Save
            </Button>
          </form>
        </Card>
      </Container>
    </>
  );
}
