import React, { useState, useEffect } from 'react';
import { Box, CardMedia, Button } from '@mui/material';
import ModalWindow from '../Modals';

export default function SweetAlert({
  show,
  onCancel,
  title,
  onConfirm,
  confirmBtnText = 'Yes',
  cancelBtnText = 'Cancel',
}) {
  return (
    <ModalWindow
      title={title}
      modal={show}
      handleModal={onCancel}
      maxWidth={400}
      minWidth={400}
      maxHeight={150}
      minHeight={150}
      body={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 2,
            py: 2,
          }}
        >
          <Button
            sx={{
              backgroundColor: '#CFE0F0',
              color: '#161616',
              textTransform: 'none',
            }}
            variant="contained"
            onClick={() => {
              onConfirm();
            }}
          >
            {confirmBtnText}
          </Button>
          <Button
            sx={{
              backgroundColor: '#2A455F',
              color: 'white',
              textTransform: 'none',
            }}
            name="add"
            variant="contained"
            type="submit"
            color="primary"
            // startIcon={
            //   isSubmitting ? <CircularProgress size="0.9rem" /> : undefined
            // }
            onClick={() => {
              onCancel();
            }}
          >
            {cancelBtnText}
          </Button>
        </Box>
      }
    />
  );
}
