import React, { useState, useEffect } from 'react';
import {
  // Form,
  Form,
  Button,
  FormGroup,
  Input,
  // Label,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  // Row,
  Col,
  Card,
  CardBody,
  // CardTitle,
  CardHeader,
  // Button
} from 'reactstrap';
import Select from 'react-select';
import * as Icon from 'react-feather';
import { connect } from 'react-redux';
import { toast, Flip } from 'react-toastify';
import Flatpickr from 'react-flatpickr';
import AsyncSelect from 'react-select/async';
import InfiniteFileUploader from '../../../../components/main/infiniteFileUploader';
import unitService from '../../../../services/units';
import dispatcherService from '../../../../services/users';
import fileUploadService from '../../../../services/file_upload';
import stateProvinceService from '../../../../services/state_province';
import driverService from '../../../../services/drivers';
import loadService from '../../../../services/loads';

export default function NewUnit() {
  const [states, setStates] = useState([]);
  const [status, setStatus] = useState(1);
  const [type, setType] = useState(1);

  const [drivers, setDrivers] = useState([]);
  const [driver, setDriver] = useState(null);
  const [driverId, setDriverId] = useState(null);
  const [secondDriver, setSecondDriver] = useState(null);
  const [secondDriverId, setSecondDriverId] = useState(null);
  const [unitTypes, setUnitTypes] = useState([]);
  const [ownershipTypes, setOwnershipTypes] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const [prevFiles, setPrevFiles] = useState([]);
  const [fileIds, setFileIds] = useState(new Map());
  const [currentFile, setCurrentFile] = useState(0);
  const [unitStatuses, setUnitStatuses] = useState([]);
  const [dispatchers, setDispatchers] = useState([]);
  const [dispatcher, setDispatcher] = useState(null);

  const [abbreviation, setAbbreviation] = useState('');
  const [ownedCompanies, setOwnedCompanies] = useState([]);

  const [number, setNumber] = useState('');

  const newFile = () => {
    const newFilesData = newFiles;
    const currentFileData = currentFile;
    newFilesData.push(currentFileData);
    setNewFiles(newFilesData);
    setCurrentFile(currentFileData + 1);
  };

  const deleteFile = (item) => {
    const newFilesData = newFiles;
    const fileIdsData = fileIds;
    fileIdsData.delete(item);
    setFileIds(fileIdsData);

    const index = newFilesData.indexOf(item);
    if (index > -1) {
      newFilesData.splice(index, 1);
    }
    setNewFiles(newFilesData);
  };

  const deletePrevFile = (item) => {
    const prevFilesData = prevFiles;
    const fileIdsData = fileIds;
    fileIdsData.delete(item);
    setFileIds(fileIdsData);

    const obj = prevFilesData.find((el) => el.id === item);
    const index = prevFilesData.indexOf(obj);
    if (index > -1) {
      prevFilesData.splice(index, 1);
    }
    setPrevFiles(prevFilesData);
  };

  const uploadFile = (file, item) => {
    const formData = new FormData();
    formData.append('file', file);
    if (file === undefined) {
      return;
    }
    fileUploadService
      .upload('/file/upload')
      .then((data) => {
        const newFilesData = newFiles;
        const newFile = newFilesData.indexOf(item);
        newFilesData[newFile] = data;
        const fileIdsData = fileIds;
        fileIdsData.set(data, file.name);
        setNewFiles(newFilesData);
        setFileIds(fileIdsData);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const newUnit = () => {
    const obj = Object.create(null);
    // eslint-disable-next-line no-restricted-syntax
    for (const [k, v] of fileIds) {
      obj[k] = v;
    }

    const data = {
      eldUnTil:
        status === 7 && document.querySelector('#eldUnTil')
          ? Date.parse(document.querySelector('#eldUnTil').value)
          : null,
      notes: document.querySelector('#notes').value,
      number: document.querySelector('#number').value + '-' + abbreviation,
      files: obj,
      unitStatusId: status,
      unitTypeId: type,
      driverId,
      secondDriverId,
      // teamId: this.state.team,
      dispatcherId: dispatcher ? dispatcher.value : null,
      ownershipTypeId: parseInt(
        document.querySelector('#ownershipType').value,
        10
      ),
      initialLocation: {
        stateProvince: parseInt(document.querySelector('#state').value, 10),
        city: document.querySelector('#city').value,
        street: document.querySelector('#street').value,
      },
      vin: document.querySelector('#vin').value,
      make: document.querySelector('#make').value,
      model: document.querySelector('#model').value,
      description: document.querySelector('#description').value,
      year: parseInt(document.querySelector('#year').value, 10),
      licensePlateNumber: document.querySelector('#licensePlateNumber').value,
      licensePlateExpiration: document.querySelector('#licensePlateExpiration')
        .value,
      inspectionStickerExpiration: document.querySelector(
        '#inspectionStickerExpiration'
      ).value,
      registrationExpirationTime:
        document.querySelector('#registrationExpirationTime').value &&
        Date.parse(document.querySelector('#registrationExpirationTime').value),
      annualInspectionExpirationTime:
        document.querySelector('#annualInspectionExpirationTime').value &&
        Date.parse(
          document.querySelector('#annualInspectionExpirationTime').value
        ),
    };
    unitService
      .create(data)
      .then((res) => {
        if (res) {
          toast.success('Unit successfuly added', {
            transition: Flip,
          });
          window.history.back();
        } else {
          toast.error('Something went wrong', { transition: Flip });
          // res.text();
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const getDataDispatchers = (company) => {
    setDispatcher(null);
    dispatcherService
      .getAll(
        `size=10000&roleId=3${company ? `&currentEmployerId=${company}` : ''}`
      )
      .then((data) => {
        const dataToShow = [];
        data.content.forEach((item) => {
          const elToShow = {
            value: item.id,
            label: item.username,
          };
          dataToShow.push(elToShow);
        });
        setDispatchers(dataToShow);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const teamChange = (value) => {
    if (value == null) {
      setDispatcher(null);
    } else {
      setDispatcher(value);
    }
  };

  const componentDidMount = () => {
    getDataDispatchers();
    driverService
      .getAll(`sort=id,DESC&size=10000`)
      .then((data) => {
        const driversData = data.content.map((item) => {
          return {
            value: item.id,
            label: `${item.firstName} ${item.lastName}`,
          };
        });
        setDrivers(driversData);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
    stateProvinceService
      .getAll()
      .then((data) => setStates(data))
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
    unitService
      .getContext()
      .then((data) => {
        setUnitTypes(data.unit_types);
        setOwnershipTypes(data.ownership_types);
        setUnitStatuses(data.unit_statuses);
        // let dataToShow = [];
        // data.teams.forEach((el) => {
        //     let elToShow = {
        //         value: el.id,
        //         label: el.name,
        //     };
        //     dataToShow.push(elToShow);

        // });
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });

    loadService
      .getContext()
      .then((data) => {
        setOwnedCompanies(data.owned_companies);
        setAbbreviation(data.owned_companies[0].abbreviation);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const loadOptions = (inputValue, callback) => {
    driverService
      .getAll(`sort=id,DESC&size=100&page=0&search=${inputValue}`)
      .then((data) => {
        callback(
          data.content.map((el) => {
            return {
              ...el,
              value: el.id,
              label: `${el.firstName} ${el.lastName} ${el.searchName}`,
            };
          })
        );
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const setterAbbreviation = (e) => {
    const abbreviationData = ownedCompanies.find(
      (item) => item.id === e.target.value * 1
    );
    setAbbreviation(abbreviationData.abbreviation);
  };

  useEffect(() => {
    componentDidMount();
  }, []);

  return (
    <>
      <Card>
        <CardHeader>
          <h3 className="mb-0">Adding a new Unit</h3>
        </CardHeader>
        <CardBody>
          <Form className="d-flex">
            <div style={{ width: '50%', marginRight: 20 }}>
              <h4>Main</h4>
              <FormGroup className="align-items-center mt-2" row>
                <Col md="4">
                  <span>Company</span>
                  <span style={{ color: 'red' }}> *</span>
                </Col>
                <Col md="8">
                  <CustomInput
                    type="select"
                    name="select"
                    id="owned_company"
                    onChange={(e) => setterAbbreviation(e)}
                  >
                    {ownedCompanies.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </CustomInput>
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Number</span>
                  <span style={{ color: 'red' }}> *</span>
                </Col>
                <Col md="8">
                  <InputGroup>
                    <Input
                      placeholder=""
                      id="number"
                      value={number || ''}
                      autoComplete="off"
                      onKeyPress={(e) => {
                        if (e.key === '-') {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        setNumber(e.target.value.replaceAll('-', ''));
                      }}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>{abbreviation}</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
              </FormGroup>
              {/* <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Number*</span>
                </Col>
                <Col md="8">
                  <Input type="text" id="number" />
                </Col>
              </FormGroup> */}
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>VIN</span>
                </Col>
                <Col md="8">
                  <Input type="text" id="vin" />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Type</span>
                  <span style={{ color: 'red' }}> *</span>
                </Col>
                <Col md="8">
                  <CustomInput
                    type="select"
                    name="select"
                    id="type"
                    onChange={(e) => setType(e.target.value)}
                  >
                    {unitTypes.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </CustomInput>
                </Col>
              </FormGroup>

              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Ownership type</span>
                </Col>
                <Col md="8">
                  <CustomInput type="select" name="select" id="ownershipType">
                    {ownershipTypes.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </CustomInput>
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Status</span>
                </Col>
                <Col md={status === 7 ? '4' : '8'}>
                  <CustomInput
                    type="select"
                    name="select"
                    id="status"
                    onChange={(e) => setStatus(e.target.value)}
                    value={status}
                  >
                    {unitStatuses.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </CustomInput>
                </Col>
                {status === 7 && (
                  <Col md="4">
                    <Flatpickr
                      id="eldUnTil"
                      className="form-control"
                      placeholder="ELD Until"
                      data-enable-time
                      options={{
                        dateFormat: 'Z',
                        altInput: true,
                        altFormat: 'm-d-Y H:i',
                      }}
                    />
                  </Col>
                )}
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Driver</span>
                </Col>
                <Col md="8">
                  <AsyncSelect
                    noOptionsMessage={(value) =>
                      !value.inputValue
                        ? 'type something to search'
                        : 'nothing to show'
                    }
                    placeholder="Search"
                    isClearable={true}
                    defaultValue={driver}
                    loadOptions={loadOptions}
                    onChange={(value) => {
                      if (value !== null) {
                        setDriverId(value.value);
                        setDriver(value);
                      } else {
                        setDriverId(null);
                        setDriver(null);
                      }
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary50: '#fe810b',
                        primary25: 'rgb(253, 179, 46)',
                        primary: 'rgb(253, 179, 46)',
                      },
                    })}
                  />
                  {/* <Select
                                            className="React"
                                            classNamePrefix="select"
                                            name="color"
                                            options={this.state.drivers}
                                            value={this.state.driver}
                                            isClearable={true}
                                            onChange={(value) => {
                                                if (value !== null) {
                                                    this.setState({
                                                        driverId: value.value,
                                                        driver: value,
                                                    });
                                                } else {
                                                    this.setState({
                                                        driverId: null,
                                                        driver: null,
                                                    });
                                                }
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary50: "#fe810b",
                                                    primary25:
                                                        "rgb(253, 179, 46)",
                                                    primary:
                                                        "rgb(253, 179, 46)",
                                                },
                                            })}
                                        /> */}
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Second driver(optimal)</span>
                </Col>
                <Col md="8">
                  <AsyncSelect
                    noOptionsMessage={(value) =>
                      !value.inputValue
                        ? 'type something to search'
                        : 'nothing to show'
                    }
                    placeholder="Search"
                    isClearable={true}
                    defaultValue={secondDriver}
                    loadOptions={loadOptions}
                    onChange={(value) => {
                      if (value == null) {
                        setSecondDriver(null);
                        setSecondDriverId(null);
                      } else {
                        setSecondDriver(value);
                        setSecondDriverId(value.value);
                      }
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary50: '#fe810b',
                        primary25: 'rgb(253, 179, 46)',
                        primary: 'rgb(253, 179, 46)',
                      },
                    })}
                  />
                  {/* <Select
                                            className="React"
                                            classNamePrefix="select"
                                            name="color"
                                            value={this.state.secondDriver}
                                            options={this.state.drivers}
                                            isClearable={true}
                                            onChange={(val) => {
                                                if (val === null) {
                                                    this.setState({
                                                        secondDriver: null,
                                                        secondDriverId: null,
                                                    });
                                                } else {
                                                    this.setState({
                                                        secondDriver: val,
                                                        secondDriverId:
                                                            val.value,
                                                    });
                                                }
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary50: "#fe810b",
                                                    primary25:
                                                        "rgb(253, 179, 46)",
                                                    primary:
                                                        "rgb(253, 179, 46)",
                                                },
                                            })}
                                        /> */}
                </Col>
              </FormGroup>
              {/* <FormGroup className="align-items-center" row>
                                    <Col md="4">
                                        <span>Team</span>
                                    </Col>
                                    <Col md="8">
                                        <Select
                                            className="React"
                                            classNamePrefix="select"
                                            name="color"
                                            options={this.state.teams}
                                            isClearable={true}
                                            value={this.state.teamSelected}
                                            onChange={this.teamChange}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary50: "#fe810b",
                                                    primary25:
                                                        "rgb(253, 179, 46)",
                                                    primary:
                                                        "rgb(253, 179, 46)",
                                                },
                                            })}
                                        />
                                    </Col>
                                </FormGroup> */}
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Dispatcher</span>
                </Col>
                <Col md="8">
                  <Select
                    className="React"
                    classNamePrefix="select"
                    name="color"
                    options={dispatchers}
                    isClearable={true}
                    value={dispatcher}
                    onChange={(e) => {
                      teamChange(e);
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary50: '#fe810b',
                        primary25: 'rgb(253, 179, 46)',
                        primary: 'rgb(253, 179, 46)',
                      },
                    })}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Initial Location</span>
                </Col>
                <Col md="8">
                  <CustomInput type="select" name="select" id="state">
                    {states.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </CustomInput>
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>City</span>
                  <span style={{ color: 'red' }}> *</span>
                </Col>
                <Col md="8">
                  <Input type="text" id="city" />
                </Col>
              </FormGroup>

              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Street</span>
                </Col>
                <Col md="8">
                  <Input type="text" id="street" />
                </Col>
              </FormGroup>
            </div>
            <div style={{ width: '50%', marginRight: 20 }}>
              <h4>Optional</h4>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Make</span>
                </Col>
                <Col md="8">
                  <Input type="text" id="make" />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Model</span>
                </Col>
                <Col md="8">
                  <Input type="text" id="model" />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Description</span>
                </Col>
                <Col md="8">
                  <Input type="text" id="description" />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Year</span>
                </Col>
                <Col md="8">
                  <Input type="text" id="year" />
                </Col>
              </FormGroup>

              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>License Plate Number</span>
                </Col>
                <Col md="8">
                  <Input type="text" id="licensePlateNumber" />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>License Plate Expiration</span>
                </Col>
                <Col md="8">
                  <Flatpickr
                    id="licensePlateExpiration"
                    className="form-control"
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Inspection Sticker Expiration</span>
                </Col>
                <Col md="8">
                  <Flatpickr
                    id="inspectionStickerExpiration"
                    className="form-control"
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Annual Inspection Expiration Time</span>
                </Col>
                <Col md="8">
                  <Flatpickr
                    id="annualInspectionExpirationTime"
                    className="form-control"
                    data-enable-time
                    options={{
                      dateFormat: 'Z',
                      altInput: true,
                      altFormat: 'm-d-Y H:i',
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Registration Expiration Time</span>
                </Col>
                <Col md="8">
                  <Flatpickr
                    id="registrationExpirationTime"
                    className="form-control"
                    data-enable-time
                    options={{
                      dateFormat: 'Z',
                      altInput: true,
                      altFormat: 'm-d-Y H:i',
                    }}
                  />
                </Col>
              </FormGroup>

              <FormGroup>
                <Input
                  type="textarea"
                  id="notes"
                  placeholder="Notes"
                  maxLength="1000"
                  style={{ minHeight: 98 }}
                />
              </FormGroup>
            </div>
          </Form>
        </CardBody>
      </Card>
      <InfiniteFileUploader
        newFiles={newFiles}
        prevFiles={prevFiles}
        deletePrevFile={deletePrevFile}
        newFile={newFile}
        deleteFile={deleteFile}
        uploadFile={uploadFile}
      />
      <div className="d-flex justify-content-center mt-2">
        <Button
          color="success"
          className="d-flex align-items-center"
          type="button"
          onClick={() => newUnit()}
        >
          <Icon.Check size={22} /> Save unit
        </Button>
      </div>
    </>
  );
}

// const mapStateToProps = (state) => {
//   return {
//     token: state.auth.login.token,
//   };
// };
// export default connect(mapStateToProps)(NewUnit);
