export function dataSelector(dataResponse, page, size) {
  const filtered = dataResponse.content.map((item, i) => ({
    index: i + 1 + page * size,
    name: item.name,
    id: item.id,
    username: item.username,
    role: item.role,
    phone: item.phone,
  }));
  return { total: dataResponse.totalElements, data: filtered };
}
