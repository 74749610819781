export function dataSelector(dataResponse, page, size) {
  const filtered = dataResponse.data.map((item, i) => ({
    index: i + 1 + page * size,
    date: item.date,
    username: item.username,
    trailerName: item.trailerName,
    trailerOwned: item.trailerOwned,
    issueCategory: item.issueCategory,
    amount: item.amount,
    paymentMethod: item.paymentMethod,
    truck: item.truck,
    // purchaseDate: item.purchaseDate,
    drivers: item.drivers,
    shopServiceName: item.shopServiceName,
    numberAttachments: item.numberAttachments,
    data: item,
  }));
  return { total: dataResponse.total, data: filtered };
}
