import React from 'react';
import { motion } from 'framer-motion';

const AnimateDiv = ({ children }) => {
  return (
    <motion.div
      animate={{
        scale: [1, 1, 1, 1, 1],
        rotate: [0, 40, 0, -40, 0],
        borderRadius: ['0%', '0%', '0%', '0%', '0%'],
      }}
      transition={{
        duration: 1,
        ease: 'easeInOut',
        times: [0, 0.2, 0.4, 0.6, 0.8, 1],
        repeat: Infinity,
        repeatDelay: 1,
      }}
    >
      {children}
    </motion.div>
  );
};
export default AnimateDiv;
