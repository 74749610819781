export function dataSelector(dataResponse, page, size) {
  const filtered = dataResponse.content.map((item, i) => ({
    index: i + 1 + page * size,
    state: item.stateProvinceName,
    id: item.id,
    number: item.number,
    vin: item.vin,
    type: item.unitName,
    unitStatus: item.unitStatus,
    ownership: item.ownershipName,
    licenseExpiration: item.licenseExpirationFormatted,
    inspectation: item.inspectionStickerExpirationFormatted,
    actions: item.id,
    unitStatusColor: item.unitStatusColor,
    data: item,
    unitData: item,
  }));
  return { total: dataResponse.totalElements, data: filtered };
}
