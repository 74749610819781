import { service } from '.';

export default {
  getAll: (query) => service.get(`/unit/list?${query}`),
  getContext: () => service.get(`/unit/context`),
  getOne: (id) => service.get(`/unit/${id}`),
  getSearch: (value) => service.get(`/unit/search_by_number?q=${value}`),
  create: (data) => service.post('/unit/new', data),
  update: (data) => service.put(`/unit/edit`, data),
  getUnitStatuses: (query) => service.get(`/unit/statuses?${query}`),
  getOneUnitStatus: (id) => service.get(`/unit/one/status/${id}`),
  createUnitStatus: (data) => service.post('/unit/new/status', data),
  updateUnitStatus: (data) => service.put(`/unit/update/status`, data),
  deleteUnitStatus: (id) => service.delete(`/unit/delete/status/${id}`),
  updateStatus: (id, status, data) =>
    service.put(`/unit/update_status/${id}/${status}`, data),
  clearAtHomeTime: (id, data) =>
    service.put(`/unit/clear_at_home_time/${id}`, data),
  delete: (id) => service.delete(`/unit/${id}`),
};
