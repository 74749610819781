import { service } from '.';

export default {
  getAll: (query) => service.get(`/location/list?${query}`),
  getSearch: (value) => service.get(`/location/search?q=${value}`),
  getContext: () => service.get(`/location/context`),
  getOne: (id) => service.get(`/location/by_id/${id}`),
  create: (data) => service.post(`/location/new`, data),
  delete: (id) => service.delete(`/location/${id}`),
};
