import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast, Flip } from 'react-toastify';
import Moment from 'react-moment';
import Button from '@mui/material/Button';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import unitService from '../../../../services/units';
import { setRefreshPage } from '../../../../redux/modules/store/actions';
import ShowModal from './Modals/ShowModal';

export default ({ value }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const saveStatus = (statusId, data) => {
    unitService
      .updateStatus(value.truckId, statusId, data)
      .then(() => {
        toggleModal();
        dispatch(setRefreshPage(true));
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const handleLeaveHome = (date) => {
    const data = {
      leaveHomeDate: date,
    };
    saveStatus(0, data);
  };

  return (
    <>
      <ShowModal
        modal={showModal}
        toggleModal={toggleModal}
        handleChange={handleLeaveHome}
        item={value}
      />
      {value.leaveHomeDate !== null && value.leaveHomeDate !== undefined ? (
        <div style={{ display: 'flex' }}>
          <Button
            variant="outlined"
            startIcon={<ArrowCircleLeftIcon />}
            onClick={() => {
              toggleModal();
            }}
          >
            <Moment format="YYYY-MM-DD HH:mm">{value.leaveHomeDate}</Moment>
          </Button>
        </div>
      ) : (
        <Button
          variant="outlined"
          startIcon={<ArrowCircleLeftIcon />}
          onClick={() => {
            toggleModal();
          }}
        >
          {null}
        </Button>
      )}
    </>
  );
};
