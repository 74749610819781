import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import Moment from 'react-moment';
import EditIcon from '@mui/icons-material/Edit';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { toast, Flip } from 'react-toastify';
import unitService from '../../../../services/units';
import { setRefreshPage } from '../../../../redux/modules/store/actions';
import ShowModal from './Modals/ShowModal';

export default ({ value }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const [statuses, setStatuses] = useState([
    { id: 1, name: 'COVERED' },
    { id: 2, name: 'UPCOMING' },
    { id: 3, name: 'READY' },
    { id: 5, name: 'REPAIR' },
    { id: 4, name: 'NO DRIVER' },
    { id: 6, name: 'OFF' },
    { id: 7, name: 'WAITING FOR ELD' },
    { id: 8, name: 'AT HOME' },
    { id: 9, name: 'INSPECTION' },
    { id: 10, name: 'INSURANCE' },
    { id: 11, name: 'REGISTRATION' },
  ]);

  const getUnitStatuses = () => {
    unitService
      .getUnitStatuses('page=0&size=1000&deleted=false')
      .then((data) => {
        setStatuses(data.content);
      })
      .catch((err) => {
        // toast.error(err.response.data.message, {
        //   transition: Flip,
        // });
      });
  };

  const saveStatus = (statusId, data) => {
    unitService
      .updateStatus(value.truckId, statusId, data)
      .then(() => {
        dispatch(setRefreshPage(true));
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const handleChange = (event) => {
    if (event.target.value === 8) {
      toggleModal();
    } else {
      saveStatus(event.target.value, {});
    }
  };

  const handleLeaveHome = (atHomeDate, leaveHomeDate) => {
    const data = {
      atHomeDate,
      leaveHomeDate,
    };

    toggleModal();
    saveStatus(8, data);
  };

  useEffect(() => {
    getUnitStatuses();
  }, []);

  return (
    <>
      <ShowModal
        modal={showModal}
        toggleModal={toggleModal}
        handleChange={handleLeaveHome}
        item={value}
      />
      <FormControl fullWidth sx={{ paddingLeft: 2, paddingRight: 2 }}>
        <Select
          sx={{
            color: 'white',
            backgroundColor: value.unitStatusColor,
          }}
          size="small"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value.unitStatusId}
          // value={statuses.find((item) => item.id === value.unitStatusId).id}
          label=""
          onChange={handleChange}
        >
          {statuses.map((item, i) => {
            return (
              <MenuItem key={`${i + 1}`} value={item.id}>
                {item.id === 8 ? item.name + ' ' : item.name}
                {item.id === 8 &&
                value.leaveHomeDate !== undefined &&
                value.leaveHomeDate !== null ? (
                  <>
                    &nbsp;
                    <Moment format="YYYY-MM-DD">{value.leaveHomeDate}</Moment>
                  </>
                ) : null}
              </MenuItem>
            );
          })}
        </Select>
        {value.unitStatusId === 8 ? (
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => {
              toggleModal();
            }}
          >
            <EditOutlinedIcon />
          </IconButton>
        ) : null}
      </FormControl>
    </>
  );
};
