/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from 'react';
// import driverService from '../../../../../services/drivers';
import './style.css';

export default function Camera({
  formik,
  videoRef,
  photoRef,
  setStream,
  setSelfie,
  toggle,
}) {
  const [hasPhoto, setHasPhoto] = useState(false);
  const [ph, setPh] = useState();

  const getVideo = () => {
    console.log(navigator);
    if ('mediaDevices' in navigator && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: {} })
        .then((stream) => {
          setStream(stream);
          const video = videoRef.current;
          video.srcObject = stream;
          video.play();
        })
        .catch((err) => console.log(err));
    }
  };

  const takePhoto = () => {
    const width = videoRef.current.clientWidth;
    const height = videoRef.current.clientHeight;
    const video = videoRef.current;
    const photo = photoRef.current;

    photo.width = width;
    photo.height = height;

    const ctx = photo.getContext('2d');
    ctx.drawImage(video, 0, 0, width, height);

    const image = photo.toDataURL();
    // photo.getCroppedCanvas().toBlob((blob) => {
    //   console.log(blob);
    // });

    setPh(image);
    setHasPhoto(true);
  };

  const readyPhoto = () => {
    formik.setFieldValue('photoFile', ph);
    setSelfie(ph);
    toggle();
  };

  const closePhoto = () => {
    const photo = photoRef.current;
    const ctx = photo.getContext('2d');

    ctx.clearRect(0, 0, photo.width, photo.height);
    setHasPhoto(false);
  };

  useEffect(() => {
    if (videoRef.current) {
      getVideo();
    }
  }, [videoRef]);

  return (
    <>
      <div className="camera">
        <video
          autoPlay={true}
          playsInline={true}
          muted={true}
          ref={videoRef}
          style={{ width: '100%' }}
        />
        <button
          id="snap"
          onClick={() => {
            takePhoto();
          }}
        >
          SNAP!
        </button>
      </div>
      <div className={'result ' + (hasPhoto ? 'hasPhoto' : '')}>
        <canvas ref={photoRef} />
        <button
          id="close"
          onClick={() => {
            closePhoto();
          }}
        >
          Camera
        </button>
        <button
          id="ready"
          onClick={() => {
            readyPhoto();
          }}
        >
          Ready!
        </button>
      </div>
    </>
  );
}
