import axios from 'axios';
// import { useDispatch } from 'react-redux';
import auth from './auth';

const baseURL = process.env.REACT_APP_BASE_URL;

const service = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

service.interceptors.response.use(
  (res) => res.data,
  (error) => {
    console.log(error.message);
    if (error.message !== 'Network Error') {
      if (error.response.status === 401 || error.response.status === 403) {
        const refreshToken = localStorage.getItem('refresh_token');
        const data = { refresh_token: refreshToken };

        // localStorage.removeItem(
        //   localStorage.getItem('current_account') + '_access_token'
        // );
        // localStorage.removeItem('refresh_token');
        // localStorage.removeItem('dashboard_data');
        localStorage.clear();
        window.open('/login', '_self');

        // auth.refreshToken(data)
        //     .then((resp) => {
        //         if (resp.status === 200) {
        //             if (resp.data.error) {
        //                 localStorage.removeItem("access_token");
        //                 localStorage.removeItem("refresh_token");
        //                 window.location.href("/login");
        //             } else {
        //                 localStorage.setItem(
        //                     "access_token",
        //                     resp.data.access_token
        //                 );
        //                 localStorage.setItem(
        //                     "refresh_token",
        //                     resp.data.refresh_token
        //                 );
        //             }
        //         }
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //         // localStorage.removeItem("access_token");
        //         // localStorage.removeItem("refresh_token");
        //         window.location.href = "/login";
        //     });
      }
    }
    return Promise.reject(error);
  }
);

service.interceptors.request.use((config) => {
  const token = localStorage.getItem(
    localStorage.getItem('current_account') + '_access_token'
  );

  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export function execute(promise) {
  return new Promise((resolve, reject) => {
    promise
      .then((response) => {
        // console.log(response.data);
        resolve(response.data);
      })
      .catch(reject);
  });
}

export { service };
