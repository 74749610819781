import React, { useState, useEffect } from 'react';
import {
  // Form,
  Form,
  Button,
  FormGroup,
  Input,
  // Label,
  CustomInput,
  // Row,
  Col,
  Card,
  CardBody,
  // CardTitle,
  CardHeader,
  // Button
} from 'reactstrap';
import * as Icon from 'react-feather';
import { connect } from 'react-redux';
import {
  // ToastContainer,
  toast,
  // Slide,
  // Zoom,
  Flip,
  // Bounce,
} from 'react-toastify';
// import { history } from "../../../history";
import AsyncSelect from 'react-select/async';
import companyService from '../../../../services/companies';
import fileUploadService from '../../../../services/file_upload';
import locationService from '../../../../services/locations';

export default function NewCustomer() {
  const [states, setStates] = useState([]);
  const [customerTypes, setCustomerTypes] = useState([]);
  const [logoFileId, setLogoFileId] = useState(null);
  const [stateProvince, setStateProvince] = useState(null);

  const newCustomer = () => {
    const data = {
      alternatePhone: document.querySelector('#alternatePhone').value,
      alternatePhoneExtensionNumber: document.querySelector(
        '#alternatePhoneExtensionNumber'
      ).value,
      aptSuiteOther: document.querySelector('#aptSuiteOther').value,
      companyName: document.querySelector('#companyName').value,
      contact: document.querySelector('#contact').value,
      customerTypeId: parseInt(
        document.querySelector('#customerType').value,
        10
      ),
      email: document.querySelector('#email').value,
      fax: document.querySelector('#fax').value,
      motorCarrierNumber: document.querySelector('#motorCarrierNumber').value,
      notes: document.querySelector('#notes').value,
      phoneExtensionNumber: document.querySelector('#phoneExtensionNumber')
        .value,
      phoneNumber: document.querySelector('#phoneNumber').value,
      locationId: stateProvince,
      street: document.querySelector('#street').value,
      taxId: document.querySelector('#taxId').value,
      webSite: document.querySelector('#webSite').value,
      zipCode: document.querySelector('#zipCode').value,
    };
    companyService
      .create(data)
      .then((res) => {
        if (res) {
          toast.success('Company successfuly added', {
            transition: Flip,
          });
          window.history.back();
        } else {
          toast.error('Something went wrong', { transition: Flip });
          // res.text();
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const onDrop = (pictureFiles, pictureDataURLs) => {
    if (pictureFiles.length === 0) {
      return;
    }
    const formData = new FormData();
    formData.append('file', pictureFiles[0], pictureFiles[0].name);

    fileUploadService
      .upload('/file/upload', formData)
      .then((data) => setLogoFileId(data))
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const loadOptions = (inputValue, callback) => {
    const req = { search: inputValue };
    locationService
      .getSearch(inputValue)
      .then((data) =>
        callback(
          data.data.map((el) => {
            return {
              value: el.id,
              label: el.nameWithParentAnsi,
            };
          })
        )
      )
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const componentDidMount = () => {
    companyService
      .getContext()
      .then((data) => {
        setCustomerTypes(data.customer_types);
        setStates(data.state_province);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  useEffect(() => {
    componentDidMount();
  }, []);

  return (
    <Card>
      <CardHeader>
        <h3 className="mb-0">Adding a new Customer</h3>
      </CardHeader>
      <CardBody className="d-flex">
        <div style={{ marginRight: 20, flex: 1 }}>
          <Form>
            <FormGroup className="align-items-center" row>
              <Col md="4">
                <span>Company Name</span>
                <span style={{ color: 'red' }}> *</span>
              </Col>
              <Col md="8">
                <Input
                  type="text"
                  id="companyName"
                  onChange={(e) => {
                    document.querySelector('#companyName').value =
                      e.target.value.toUpperCase();
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup className="align-items-center" row>
              <Col md="4">
                <span>Customer Type</span>
              </Col>
              <Col md="8">
                <CustomInput type="select" name="select" id="customerType">
                  {customerTypes.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </CustomInput>
              </Col>
            </FormGroup>
            <FormGroup className="align-items-center" row>
              <Col md="4">
                <span>Street</span>
              </Col>
              <Col md="8">
                <Input type="text" id="street" />
              </Col>
            </FormGroup>
            <FormGroup className="align-items-center" row>
              <Col md="4">
                <span>Apt/Suite/Other</span>
              </Col>
              <Col md="8">
                <Input type="text" id="aptSuiteOther" />
              </Col>
            </FormGroup>
            <FormGroup className="align-items-center" row>
              <Col md="4">
                <span>City</span>
                <span style={{ color: 'red' }}> *</span>
              </Col>
              <Col md="8">
                <AsyncSelect
                  noOptionsMessage={(value) =>
                    !value.inputValue
                      ? 'type something to search'
                      : 'nothing to show'
                  }
                  placeholder="Search"
                  isClearable={true}
                  defaultValue={null}
                  loadOptions={loadOptions}
                  onChange={(value) => {
                    if (value !== null) {
                      setStateProvince(value.value);
                    } else {
                      setStateProvince(null);
                    }
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary50: '#fe810b',
                      primary25: 'rgb(253, 179, 46)',
                      primary: 'rgb(253, 179, 46)',
                    },
                  })}
                />
              </Col>
            </FormGroup>

            <FormGroup className="align-items-center" row>
              <Col md="4">
                <span>Zip Code</span>
              </Col>
              <Col md="8">
                <Input type="text" id="zipCode" />
              </Col>
            </FormGroup>
            <FormGroup className="align-items-center" row>
              <Col md="4">
                <span>Phone Number</span>
              </Col>
              <Col md="8">
                <Input type="text" id="phoneNumber" />
              </Col>
            </FormGroup>
            <FormGroup className="align-items-center" row>
              <Col md="4">
                <span>Phone Extension number</span>
              </Col>
              <Col md="8">
                <Input type="text" id="phoneExtensionNumber" />
              </Col>
            </FormGroup>
            <FormGroup className="align-items-center" row>
              <Col md="4">
                <span>Alternate Phone</span>
              </Col>
              <Col md="8">
                <Input type="text" id="alternatePhone" />
              </Col>
            </FormGroup>
          </Form>
          <Button
            color="success"
            className="d-flex align-items-center"
            type="button"
            onClick={() => newCustomer()}
          >
            <Icon.Check size={22} /> Save customer
          </Button>
        </div>
        <div style={{ width: '50%' }}>
          <FormGroup className="align-items-center" row>
            <Col md="4">
              <span>Alternate Phone Extension number</span>
            </Col>
            <Col md="8">
              <Input type="text" id="alternatePhoneExtensionNumber" />
            </Col>
          </FormGroup>

          <FormGroup className="align-items-center" row>
            <Col md="4">
              <span>Fax</span>
            </Col>
            <Col md="8">
              <Input type="text" id="fax" />
            </Col>
          </FormGroup>
          <FormGroup className="align-items-center" row>
            <Col md="4">
              <span>Email</span>
            </Col>
            <Col md="8">
              <Input type="text" id="email" />
            </Col>
          </FormGroup>
          <FormGroup className="align-items-center" row>
            <Col md="4">
              <span>Website</span>
            </Col>
            <Col md="8">
              <Input type="text" id="webSite" />
            </Col>
          </FormGroup>
          <FormGroup className="align-items-center" row>
            <Col md="4">
              <span>Contact</span>
            </Col>
            <Col md="8">
              <Input type="text" id="contact" />
            </Col>
          </FormGroup>
          <FormGroup className="align-items-center" row>
            <Col md="4">
              <span>Notes</span>
            </Col>
            <Col md="8">
              <Input type="textarea" id="notes" maxLength="1000" />
            </Col>
          </FormGroup>
          <FormGroup className="align-items-center" row>
            <Col md="4">
              <span>Motor Carrier Number</span>
            </Col>
            <Col md="8">
              <Input type="text" id="motorCarrierNumber" />
            </Col>
          </FormGroup>
          <FormGroup className="align-items-center" row>
            <Col md="4">
              <span>Tax ID (EIN#)</span>
            </Col>
            <Col md="8">
              <Input type="text" id="taxId" />
            </Col>
          </FormGroup>
        </div>
      </CardBody>
    </Card>
  );
}

// const mapStateToProps = (state) => {
//   return {
//     token: state.auth.login.token,
//   };
// };
// export default connect(mapStateToProps)(NewCompany);
