import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { byDelete, byStatusState } from './helper';
import { useStyles } from '../style';
import FilterButton from '../../FormElements/FilterButton';

export default ({ trailerStatus, setTrailerStatus, getFile }) => {
  const classes = useStyles();
  const [selectedFilter, setSelectedFilter] = useState(0);

  const handleStatus = (e) => {
    console.log(e.target.value);
    setTrailerStatus(e.target.value);
    if (!trailerStatus) {
      setSelectedFilter((prev) => prev + 1);
    }
  };

  const handleClearFilters = () => {
    setTrailerStatus('');
    setSelectedFilter(0);
  };

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.root}>
        <FormControl size="small">
          <InputLabel id="status"> Filter by status</InputLabel>
          <Select
            className={classes.input}
            value={trailerStatus}
            onChange={handleStatus}
            labelId="status"
            label="Filter by status"
          >
            {byStatusState.map((item, index) => (
              <MenuItem key={`${index + 1}`} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          size="sm"
          color="primary"
          className="d-flex align-items-center"
          type="button"
          variant="outlined"
          onClick={() => {
            getFile();
          }}
        >
          Download
        </Button>
      </Box>
      {/* <FilterButton
        selectedFilter={selectedFilter}
        handleClearFilters={handleClearFilters}
      /> */}
    </Box>
  );
};
