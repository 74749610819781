import { service } from '.';

export default {
  getAll: (query) => service.get(`/trailer_general/list?${query}`),
  getAllSearch: (query) =>
    service.get(`/trailer_general/list/searching?${query}`),
  getOne: (id) => service.get(`/trailer_general/${id}`),
  getExcelFile: (query) => service.get(`/trailer_general/get_excel?${query}`),
  create: (data) => service.post('/trailer_general/new', data),
  update: (data) => service.put(`/trailer_general/edit`, data),
  deleteTrailer: (id) => service.delete(`/trailer_general/${id}`),
  addComment: (data) => service.put(`/trailer_general/add/comments`, data),
  editComment: (data) => service.put(`/trailer_general/edit/comments`, data),
  getComments: (query) => service.get(`/trailer_general/comments?${query}`),
  deleteComment: (query) =>
    service.delete(`/trailer_general/delete/comments/${query}`),
};
