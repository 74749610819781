import EditIcon from '@mui/icons-material/Edit';
import BlockIcon from '@mui/icons-material/Block';

export const toolTips = [
  {
    name: 'Edit',
    icon: <EditIcon color="action" fontSize="small" />,
    onClick: (id, { history }) => {
      history('/app/customers/create');
    },
  },
  {
    name: 'Delete',
    icon: <BlockIcon color="action" fontSize="small" />,
    onClick: (id, { dispatch, statusModal }) => {
      //   dispatch(setOrderData(id));
      statusModal();
    },
  },
];
