import { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import { GridLoader } from 'react-spinners';
import Typography from '@mui/material/Typography';
import './style.css';

export default ({ loading }) => {
  return (
    <Backdrop
      sx={{
        display: 'flex',
        flexDirection: 'column',
        zIndex: 10000,
        gap: '10px',
      }}
      open={loading}
    >
      <Typography variant="h4" color="white">
        LOADING...
      </Typography>
      <GridLoader color="#36d7b7" loading={loading} />
    </Backdrop>
  );
};
