import React from 'react';
import Chip from '@mui/material/Chip';

export default ({ value }) => {
  return (
    <Chip
      size="small"
      style={{
        height: '30px',
        fontSize: '13px',
        color: value.statusColor ? 'white' : 'black',
        backgroundColor: value.statusColor ? value.statusColor : 'white',
        textAlign: 'center',
        textTransform: 'uppercase',
      }}
      label={value.statusName}
      variant="outlined"
    />
  );
};
