import React, { useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

export function useQuery({ fetchData }) {
  const getPageSize = () => {
    if (localStorage.getItem('page_size') !== null) {
      const size = localStorage.getItem('page_size') * 1;
      if (Number.isNaN(size)) {
        return 10;
      }
      return size;
    }
    return 10;
  };
  const dispatch = useDispatch();
  const location = useLocation();
  const [driverReport, setDriverReport] = useState(false);
  const [getReport, setGetReport] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState('');
  const [deleted, setDeleted] = useState('');
  const [tripStatusId, setTripStatusId] = useState();
  const [pageSize, setPageSize] = useState(getPageSize());
  const [pageIndex, setPageIndex] = useState(0);
  const [currentEmployerId, setCurrentEmployerId] = useState();
  const [dispatcherId, setDispatcherId] = useState(
    localStorage.getItem('dispatcher')
      ? localStorage.getItem('dispatcher') * 1
      : null
  );
  const [unitStatusId, setUnitStatusId] = useState();
  const [truckId, setTruckId] = useState();
  const [driverId, setDriverId] = useState();
  const [trailerIds, setTrailerIds] = useState();
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [companysTruck, setCompanysTruck] = useState();
  const [carrier, setCarrier] = useState();
  const [weekly, setWeekly] = useState(true);
  const [trailerStatus, setTrailerStatus] = useState();
  const [inProcess, setInProcess] = useState('');
  // const [start, setStart] = useState(
  //   moment().startOf('day').subtract(7, 'day').valueOf()
  // );
  // const [end, setEnd] = useState(
  //   moment().startOf('day').add(1, 'day').valueOf()
  // );

  const trailerStatusQuery = useMemo(
    () =>
      trailerStatus !== undefined && trailerStatus !== null
        ? `&trailer_status=${trailerStatus}`
        : '',
    [trailerStatus]
  );

  const inProcessQuery = useMemo(
    () =>
      inProcess !== undefined || inProcess !== null
        ? `&in_process=${inProcess === 2}`
        : '',
    [inProcess]
  );

  const deletedQuery = useMemo(
    () =>
      deleted !== undefined || deleted !== null
        ? `&deleted=${deleted === 1}`
        : '',
    [deleted]
  );

  const paginationQuery = useMemo(
    () => `page=${pageIndex}&size=${pageSize}`,
    [pageIndex, pageSize]
  );

  const query = useMemo(
    () =>
      `${paginationQuery}${deletedQuery}${inProcessQuery}${trailerStatusQuery}`,
    [paginationQuery, deletedQuery, inProcessQuery, trailerStatusQuery]
  );

  const handleOnTableChange = ({ pageIndex, pageSize }) => {
    localStorage.setItem('pageIndex', pageIndex);
    setPageIndex(pageIndex);
    setPageSize(pageSize);
  };

  useEffect(() => {
    // setPageIndex(0);
    dispatch(
      fetchData({
        isSearch: true,
        searchText: search,
        pageIndex,
        pageSize,
        query: `page=0&size=${pageSize}${
          inProcess !== undefined || inProcess !== null
            ? `&in_process=${inProcess === 2}`
            : ''
        }${
          deleted !== undefined || deleted !== null
            ? `&deleted=${deleted === 1}`
            : ''
        }${search ? '&search=' + search : ''}${start ? `&start=${start}` : ''}${
          end ? `&end=${end}` : ''
        }${currentEmployerId ? `&currentEmployerId=${currentEmployerId}` : ''}${
          dispatcherId ? `&dispatcherId=${dispatcherId}` : ''
        }${tripStatusId ? `&status_id=${tripStatusId}` : ''}${
          truckId ? `&truck_id=${truckId}` : ''
        }${unitStatusId ? `&unitStatusId=${unitStatusId}` : ''}${
          carrier ? `&carrier=${carrier}` : ''
        }${
          trailerStatus !== null && trailerStatus !== undefined
            ? `&trailer_status=${trailerStatus}`
            : ''
        }${trailerIds != null ? `&trailerIds=${trailerIds}` : ''}${
          driverId ? `&driver_id=${driverId}` : ''
        }`,
      })
    );

    // eslint-disable-next-line
  }, [
    dispatch,
    search,
    start,
    end,
    currentEmployerId,
    dispatcherId,
    tripStatusId,
    truckId,
    unitStatusId,
    carrier,
    trailerStatus,
    trailerIds,
    driverId,
  ]);

  useEffect(() => {
    if (location.pathname === '/units/list') {
      if (pageSize > 0) {
        dispatch(
          fetchData({
            isSearch: false,
            searchText: search,
            pageIndex,
            pageSize,
            query: `${query}${search ? `&search=${search}` : ''}${
              start ? `&start=${start}` : ''
            }${end ? `&end=${end}` : ''}${
              currentEmployerId ? `&currentEmployerId=${currentEmployerId}` : ''
            }${truckId ? `&truck_id=${truckId}` : ''}${
              unitStatusId ? `&unitStatusId=${unitStatusId}` : ''
            }${carrier ? `&carrier=${carrier}` : ''}${
              trailerIds != null ? `&trailerIds=${trailerIds}` : ''
            }${driverId ? `&driver_id=${driverId}` : ''}`,
          })
        );
      }
    } else {
      dispatch(
        fetchData({
          isSearch: false,
          searchText: search,
          pageIndex,
          pageSize,
          query: `${query}${search ? `&search=${search}` : ''}${
            start ? `&start=${start}` : ''
          }${end ? `&end=${end}` : ''}${
            currentEmployerId ? `&currentEmployerId=${currentEmployerId}` : ''
          }${dispatcherId ? `&dispatcherId=${dispatcherId}` : ''}${
            truckId ? `&truck_id=${truckId}` : ''
          }${unitStatusId ? `&unitStatusId=${unitStatusId}` : ''}${
            carrier ? `&carrier=${carrier}` : ''
          }${trailerIds != null ? `&trailerIds=${trailerIds}` : ''}${
            driverId ? `&driver_id=${driverId}` : ''
          }`,
        })
      );
    }

    // eslint-disable-next-line
  }, [query, dispatch]);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
      dispatch(
        fetchData({
          isSearch: true,
          searchText: search,
          pageIndex,
          pageSize,
          query: `${query}${search ? '&search=' + search : ''}${
            start ? `&start=${start}` : ''
          }${end ? `&end=${end}` : ''}${
            currentEmployerId ? `&currentEmployerId=${currentEmployerId}` : ''
          }${dispatcherId ? `&dispatcherId=${dispatcherId}` : ''}${
            tripStatusId ? `&status_id=${tripStatusId}` : ''
          }${truckId ? `&truck_id=${truckId}` : ''}${
            unitStatusId ? `&unitStatusId=${unitStatusId}` : ''
          }${carrier ? `&carrier=${carrier}` : ''}${
            trailerIds != null ? `&trailerIds=${trailerIds}` : ''
          }${driverId ? `&driver_id=${driverId}` : ''}`,
        })
      );
    }
    // eslint-disable-next-line
  }, [refresh, dispatch]);

  useEffect(() => {
    if (getReport) {
      setGetReport(false);
      dispatch(
        fetchData({
          isSearch: true,
          searchText: search,
          pageIndex,
          pageSize,
          query: `${query}${search ? '&search=' + search : ''}${
            start ? `&start=${start}` : ''
          }${end ? `&end=${end}` : ''}${
            currentEmployerId ? `&currentEmployerId=${currentEmployerId}` : ''
          }${dispatcherId ? `&dispatcherId=${dispatcherId}` : ''}${
            tripStatusId ? `&status_id=${tripStatusId}` : ''
          }${truckId ? `&truck_id=${truckId}` : ''}${
            companysTruck ? `&all_by_companys_truck=${companysTruck}` : ''
          }${weekly ? `&weekly=${weekly}` : ''}${
            carrier ? `&carrier=${carrier}` : ''
          }${
            driverId ? `&driver_id=${driverId}` : ''
          }${`&driver_report=${driverReport}`}`,
        })
      );
    }
    // eslint-disable-next-line
  }, [getReport, companysTruck, dispatch]);

  return {
    query,
    search,
    deleted,
    inProcess,
    currentEmployerId,
    dispatcherId,
    tripStatusId,
    truckId,
    start,
    end,
    pageIndex,
    pageSize,
    refresh,
    getReport,
    companysTruck,
    trailerStatus,
    trailerIds,
    unitStatusId,
    driverId,
    driverReport,
    setDriverReport,
    setDriverId,
    setSearch,
    setDeleted,
    setInProcess,
    setCurrentEmployerId,
    setDispatcherId,
    setTripStatusId,
    setUnitStatusId,
    setTruckId,
    setStart,
    setEnd,
    setPageIndex,
    setPageSize,
    setRefresh,
    setGetReport,
    setCompanysTruck,
    setCarrier,
    setWeekly,
    setTrailerStatus,
    setTrailerIds,
    handleOnTableChange,
  };
}
