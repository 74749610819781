import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Col,
} from 'reactstrap';
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DragAndDrop from '../CRUD/DragAndDrop';
import AttachmentItem from './AttachmentItem';
import fileUploadService from '../../../../services/file_upload';
import folderService from '../../../../services/folders';

export default function FolderFilesModal({ modal, toggle, item }) {
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState(files);
  const [amount, setAmount] = useState();
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [date, setDate] = useState();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openDrop, setOpenDrop] = useState(false);
  const [rename, setRename] = useState(false);
  const [fileName, setFileName] = useState('');
  const [fileId, setFileId] = useState(null);
  console.log(modal);

  useEffect(() => {
    const fileList = [];
    if (item && item.filesUpload) {
      item.filesUpload.forEach((items, i) => {
        const data = {
          id: item.filesUpload[i].id,
          uploadedAt: item.filesUpload[i].uploadedAt,
          originalFileName: item.filesUpload[i].originalFileName,
          size: item.filesUpload[i].size,
        };
        fileList.push(data);
      });
    }
    setFiles(fileList);
  }, []);

  const handleCloseDrop = () => {
    setOpenDrop(false);
  };
  const handleToggle = () => {
    setOpenDrop(!openDrop);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getFolders = (id) => {
    folderService.getOne(id).then((data) => {
      if (data !== null) {
        setFiles([]);
        const fileList = [];
        if (data.filesUpload) {
          data.filesUpload.forEach((el, i) => {
            const datas = {
              id: data.filesUpload[i].id,
              uploadedAt: data.filesUpload[i].uploadedAt,
              originalFileName: data.filesUpload[i].originalFileName,
              size: data.filesUpload[i].size,
            };
            fileList.push(datas);
          });

          setFiles(fileList);
        }
      }
    });
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    if (file === undefined) {
      return;
    }
    await axios.post(
      process.env.REACT_APP_BASE_URL + '/file/upload/trailer',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization:
            'Bearer ' +
            localStorage.getItem(
              localStorage.getItem('current_account') + '_access_token'
            ),
        },
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          // this.setState({
          //     progress,
          // });
          // console.log(progressEvent);
          //   setProgress(progress);
        },
        onDownloadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;

          const fileList = files;
          const data = JSON.parse(progressEvent.target.response);
          fileList.push({
            id: data.id,
            url: data.originalFileName,
            originalFileName: data.originalFileName,
            size: data.size,
            uploadedAt: data.uploadedAt,
          });
          setFiles(fileList);

          if (item.filesUpload) {
            item.filesUpload.push(data);
          } else {
            // eslint-disable-next-line no-param-reassign
            item.filesUpload = [data];
          }

          setOpenDrop(false);
          folderService.update(item).then((res) => {
            console.log(res);
          });
          // this.setState({
          //     progress,
          // });
          //   setProgress(progress);
        },
      }
    );
  };

  const handleDrop = (filesData) => {
    handleToggle();

    Array.from(filesData).forEach((el, i) => {
      if (!filesData[i].name) return;
      uploadFile(filesData[i]);
    });
  };

  return (
    <>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openDrop}
        // onClick={handleCloseDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div>
        <FormGroup className="align-items-center" row>
          <Col md="12">
            <DragAndDrop
              handlerDrop={handleDrop}
              children={
                <div
                  style={{
                    // display: "flex",
                    // gap: "6rem",
                    minHeight: '300px',
                    maxHeight: '300px',
                  }}
                >
                  {files.map((file, i) => {
                    return (
                      <AttachmentItem
                        key={`${i + 1}`}
                        item={item}
                        file={file}
                        i={i}
                        getFolders={getFolders}
                      />
                    );
                  })}
                </div>
              }
            />
          </Col>
        </FormGroup>
      </div>
    </>
  );
}
