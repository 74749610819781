import React, { useState, useEffect } from 'react';
import {
  Form,
  FormGroup,
  Input,
  Label,
  CustomInput,
  Col,
  Card,
  CardBody,
  CardHeader,
} from 'reactstrap';
import { Box, CardMedia, Button } from '@mui/material';
import * as Icon from 'react-feather';
import { connect } from 'react-redux';
import { toast, Flip } from 'react-toastify';
import Flatpickr from 'react-flatpickr';
import Cropper from 'react-cropper';
import unitService from '../../../../services/units';
import fileUploadService from '../../../../services/file_upload';
import stateProvinceService from '../../../../services/state_province';
import driverService from '../../../../services/drivers';
import UploadIcon from '../../../../assets/icons/upload.svg';
import ModalWindow from '../../../../components/main/Modals';
import { nationalities } from '../../../../redux/constants/nationalities';

export default function NewDriver() {
  const [states, setStates] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [licenseFileId, setLicenseFileId] = useState(null);
  const [medicalCardFileId, setMedicalCardFileId] = useState(null);
  const [trucks, setTrucks] = useState([]);
  const [driverStatuses, setDriverStatuses] = useState([]);
  const [socialSecurityFileId, setSocialSecurityFileId] = useState(null);
  const [driverPermissions, setDriverPermissions] = useState([
    'Active',
    'Terminate',
    'In process',
  ]);

  const [selfie, setSelfie] = useState();
  const [photo, setPhoto] = useState('');
  const [cropper, setCropper] = useState();
  const [openCrop, setOpenCrop] = useState(false);
  const [image, setImage] = useState('');

  const newDriver = (photoFileId) => {
    const data = {
      photoFileId,
      socialSecurityFileId,
      alternatePhone: document.querySelector('#alternatePhone').value,
      city: document.querySelector('#city').value,
      defaultPaymentTypeId: parseInt(
        document.querySelector('#defaultPaymentType').value,
        10
      ),
      email: document.querySelector('#email').value,
      firstName: document.querySelector('#firstName').value,
      hireDate: document.querySelector('#hireDate').value,
      lastName: document.querySelector('#lastName').value,
      middleName: document.querySelector('#middleName').value,
      birthDate: new Date(document.querySelector('#birthDate').value).getTime(),
      nationality: document.querySelector('#nationality').value,
      licenseExpiration: document.querySelector('#licenseExpiration').value,
      licenseIssuedJurisdictionId: document.querySelector(
        '#licenseIssuingState'
      ).value,
      licenseNumber: document.querySelector('#licenseNumber').value,
      licenseIssueTime: new Date(
        document.querySelector('#cdlIssueDate').value
      ).getTime(),
      medicalCardRenewal: document.querySelector('#medicalCardRenewal').value,
      phone: document.querySelector('#phone').value,
      stateProvinceId: parseInt(document.querySelector('#state').value, 10),
      street: document.querySelector('#street').value,
      terminationDate: document.querySelector('#terminationDate').value,
      zipCode: document.querySelector('#zipCode').value,
      licenseFileId,
      medicalCardFileId,
      note: document.querySelector('#note').value,
      truckId: parseInt(document.querySelector('#truck').value, 10),
      driverStatusId: parseInt(document.querySelector('#status').value, 10),
    };
    if (document.querySelector('#permission').value === 'In process') {
      data.inProcess = true;
    } else if (document.querySelector('#permission').value === 'Active') {
      data.deleted = false;
      data.inProcess = false;
    } else if (document.querySelector('#permission').value === 'Terminate') {
      data.deleted = true;
      data.inProcess = false;
    }
    driverService
      .create(data)
      .then((res) => {
        if (res) {
          toast.success('Driver successfuly added', {
            transition: Flip,
          });
          window.history.back();
        } else {
          toast.error('Something went wrong', { transition: Flip });
          // res.text();
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const uploadSelfie = () => {
    console.log(photo);
    if (photo !== '') {
      const formData = new FormData();
      formData.append('file', photo, new Date().getTime());

      fileUploadService
        .upload('/file/upload', formData)
        .then((data) => {
          console.log(data);
          newDriver(data);
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            transition: Flip,
          });
        });
    } else {
      newDriver(null);
    }
  };

  const uploadLicense = (file) => {
    const formData = new FormData();
    formData.append('file', file, 'license');

    fileUploadService
      .upload('/file/upload', formData)
      .then((data) => setLicenseFileId(data))
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const uploadSSN = (file) => {
    const formData = new FormData();
    formData.append('file', file, file.name);

    fileUploadService
      .upload('/file/upload', formData)
      .then((data) => setSocialSecurityFileId(data))
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const uploadMedicalCart = (file) => {
    const formData = new FormData();
    formData.append('file', file, 'cart');

    fileUploadService
      .upload('/file/upload', formData)
      .then((data) => setMedicalCardFileId(data))
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const componentDidMount = () => {
    stateProvinceService
      .getAll()
      .then((data) => setStates(data))
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
    driverService
      .getContext()
      .then((data) => {
        const statuses = data.driver_statuses;
        const removeValFromIndex = [0, 1, 3];
        removeValFromIndex.forEach((item, i) => {
          statuses.splice(removeValFromIndex[i], 1);
        });

        setPaymentTypes(data.payment_types);
        setDriverStatuses(statuses);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
    unitService
      .getAll('size=10000')
      .then((data) => {
        setTrucks(data.content);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const imgSelectHandler = (e) => {
    if (e.target.files.length !== 0) {
      setImage(URL.createObjectURL(e.target.files[0]));
      // setPreviewImg(URL.createObjectURL(e.target.files[0]));
      setPhoto(e.target.files[0]);
      setOpenCrop(!openCrop);
    }
  };

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      setSelfie(cropper.getCroppedCanvas().toDataURL());
      cropper.getCroppedCanvas().toBlob((blob) => {
        setPhoto(
          new File([blob], 'image.png', {
            type: 'image/png',
            lastModified: new Date().getTime(),
          })
        );
      });
    }
  };

  const handleCropModal = () => {
    setOpenCrop(!openCrop);
  };

  useEffect(() => {
    componentDidMount();
  }, []);

  return (
    <>
      <ModalWindow
        title="Image cropper"
        modal={openCrop}
        handleModal={handleCropModal}
        maxWidth="md"
        body={
          <Box>
            <div style={{ width: '100%' }}>
              <Cropper
                style={{ height: 400, width: '100%' }}
                zoomTo={0}
                initialAspectRatio={1}
                preview=".img-preview"
                src={image}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={true}
              />
            </div>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
                py: 2,
              }}
            >
              <Button
                sx={{
                  backgroundColor: '#CFE0F0',
                  color: '#161616',
                  textTransform: 'none',
                }}
                variant="contained"
                onClick={() => handleCropModal()}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  backgroundColor: '#2A455F',
                  color: 'white',
                  textTransform: 'none',
                }}
                name="add"
                variant="contained"
                type="submit"
                color="primary"
                // startIcon={
                //   isSubmitting ? <CircularProgress size="0.9rem" /> : undefined
                // }
                onClick={() => {
                  getCropData();
                  handleCropModal();
                }}
              >
                Ready
              </Button>
            </Box>
          </Box>
        }
      />
      <Card>
        <CardHeader>
          <h3 className="mb-0">Adding a new Driver</h3>
        </CardHeader>
        <CardBody>
          <Form className="d-flex">
            <div style={{ width: '50%', marginRight: 20 }}>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Active/Terminate/In process</span>
                </Col>
                <Col md="8">
                  <CustomInput type="select" name="select" id="permission">
                    {driverPermissions.map((item, i) => (
                      <option key={`${i + 1}`} value={item}>
                        {item}
                      </option>
                    ))}
                  </CustomInput>
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Photo</span>
                </Col>
                <Col md="8">
                  <Box
                    sx={{
                      py: 2,
                    }}
                  >
                    <CardMedia
                      component="img"
                      style={{
                        width: 210,
                        height: 210,
                        borderRadius: 5,
                        objectFit: 'fill',
                      }}
                      image={selfie || UploadIcon}
                      alt=""
                    />
                    <input
                      id="selectFile"
                      type="file"
                      accept=".png,.jpg"
                      style={{ display: 'none' }}
                      onChange={imgSelectHandler.bind(this)}
                    />
                    <Box
                      sx={{
                        py: 0.5,
                      }}
                    />
                    <Button
                      // type="file"
                      fullWidth
                      size="small"
                      variant="contained"
                      sx={{
                        width: 210,
                        textTransform: 'none',
                        fontSize: 20,
                        transition: 'all 0.3s ease-in-out',
                        backgroundColor: '#2A455F',
                        '&:hover': {
                          backgroundColor: '#2A455F',
                          opacity: 0.8,
                        },
                      }}
                      color="error"
                      onClick={() => {
                        document.getElementById('selectFile').click();
                      }}
                    >
                      Select
                    </Button>
                  </Box>
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>First Name</span>
                  <span style={{ color: 'red' }}> *</span>
                </Col>
                <Col md="8">
                  <Input type="text" id="firstName" />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Middle Name</span>
                </Col>
                <Col md="8">
                  <Input type="text" id="middleName" />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Last Name</span>
                  <span style={{ color: 'red' }}> *</span>
                </Col>
                <Col md="8">
                  <Input type="text" id="lastName" />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Pay to</span>
                  <span style={{ color: 'red' }}> *</span>
                </Col>
                <Col md="8">
                  <Input type="text" id="pay_to" />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Birth date</span>
                  <span style={{ color: 'red' }}> *</span>
                </Col>
                <Col md="8">
                  <Flatpickr
                    id="birthDate"
                    className="form-control"
                    options={{
                      dateFormat: 'Z',
                      altInput: true,
                      altFormat: 'm-d-Y',
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Nationality</span>
                  <span style={{ color: 'red' }}> *</span>
                </Col>
                <Col md="8">
                  <CustomInput type="select" name="select" id="nationality">
                    {nationalities.map((item, i) => (
                      <option key={`${i + 1}`} value={item}>
                        {item}
                      </option>
                    ))}
                  </CustomInput>
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Status</span>
                </Col>
                <Col md="8">
                  <CustomInput type="select" name="select" id="status">
                    {driverStatuses.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </CustomInput>
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center mt-3" row>
                <Col md="4">
                  <span>Truck</span>
                </Col>
                <Col md="8">
                  <CustomInput type="select" name="select" id="truck">
                    {trucks.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.number}
                      </option>
                    ))}
                  </CustomInput>
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Street</span>
                </Col>
                <Col md="8">
                  <Input type="text" id="street" />
                </Col>
              </FormGroup>

              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>City</span>
                  <span style={{ color: 'red' }}> *</span>
                </Col>
                <Col md="8">
                  <Input type="text" id="city" />
                </Col>
              </FormGroup>

              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>State/Province</span>
                  <span style={{ color: 'red' }}> *</span>
                </Col>
                <Col md="8">
                  <CustomInput type="select" name="select" id="state">
                    {states.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </CustomInput>
                </Col>
              </FormGroup>

              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Zip Code</span>
                </Col>
                <Col md="8">
                  <Input type="text" id="zipCode" />
                </Col>
              </FormGroup>
              <FormGroup>
                <Label for="customFile">Note</Label>
                <Input
                  type="textarea"
                  id="note"
                  placeholder=""
                  maxLength="1000"
                />
              </FormGroup>
            </div>
            <div style={{ width: '50%' }}>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Email</span>
                </Col>
                <Col md="8">
                  <Input type="text" id="email" />
                </Col>
              </FormGroup>

              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Phone</span>
                </Col>
                <Col md="8">
                  <Input type="text" id="phone" placeholder="" />
                </Col>
              </FormGroup>
              {/* <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Alternate Phone</span>
                </Col>
                <Col md="8">
                  <Input type="text" id="alternatePhone" placeholder="" />
                </Col>
              </FormGroup> */}
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Default Payment Type</span>
                  <span style={{ color: 'red' }}> *</span>
                </Col>
                <Col md="8">
                  <CustomInput
                    type="select"
                    name="select"
                    id="defaultPaymentType"
                  >
                    {paymentTypes.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </CustomInput>
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>License number</span>
                </Col>
                <Col md="8">
                  <Input type="text" id="licenseNumber" />
                </Col>
              </FormGroup>

              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>License Expiration</span>
                </Col>
                <Col md="8">
                  <Flatpickr id="licenseExpiration" className="form-control" />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>CDL issue date</span>
                </Col>
                <Col md="8">
                  <Flatpickr id="cdlIssueDate" className="form-control" />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>License issuing state/jurisdiction</span>
                </Col>
                <Col md="8">
                  <CustomInput
                    type="select"
                    name="select"
                    id="licenseIssuingState"
                  >
                    {states.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </CustomInput>
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Medical Card Renewal</span>
                </Col>
                <Col md="8">
                  <Flatpickr id="medicalCardRenewal" className="form-control" />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Hire Date</span>
                </Col>
                <Col md="8">
                  <Flatpickr id="hireDate" className="form-control" />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Termination Date</span>
                </Col>
                <Col md="8">
                  <Flatpickr id="terminationDate" className="form-control" />
                </Col>
              </FormGroup>
              {/* <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Social security number</span>
                </Col>
                <Col md="8">
                  <Input type="text" id="ssn" placeholder="" />
                </Col>
              </FormGroup> */}
              <FormGroup>
                <Label for="customFile">License</Label>
                <CustomInput
                  type="file"
                  id="licenseFile"
                  onInput={(e) => uploadLicense(e.target.files[0])}
                />
              </FormGroup>
              <FormGroup>
                <Label for="customFile">Medical Cart</Label>
                <CustomInput
                  type="file"
                  id="medicalCart"
                  onInput={(e) => uploadMedicalCart(e.target.files[0])}
                />
              </FormGroup>
              <FormGroup>
                <Label for="customFile">SSN</Label>
                <CustomInput
                  type="file"
                  id="ssn"
                  onInput={(e) => uploadSSN(e.target.files[0])}
                />
              </FormGroup>
            </div>
          </Form>
          <Button
            size="medium"
            variant="contained"
            onClick={() => uploadSelfie()}
          >
            <Icon.Check size={22} /> Save driver
          </Button>
        </CardBody>
      </Card>
    </>
  );
}
