import React, { useState } from 'react';
import {
  // Form,
  Form,
  Button,
  FormGroup,
  Input,
  Label,
  CustomInput,
  // Row,
  Col,
  Card,
  CardBody,
  // CardTitle,
  CardHeader,
  // Button
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import jwtDecode from 'jwt-decode';
import Backdrop from '@mui/material/Backdrop';
import MenuIcon from '@mui/icons-material/Menu';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SaveIcon from '@mui/icons-material/Save';
import {
  // ToastContainer,
  toast,
  // Slide,
  // Zoom,
  Flip,
  // Bounce,
} from 'react-toastify';
import Flatpickr from 'react-flatpickr';
import InfiniteFileUploader from '../../../../../components/main/infiniteFileUploader';
import fileUploadService from '../../../../../services/file_upload';
import trailerGeneralService from '../../../../../services/trailer_general';

export default function NewTrailer() {
  const [newFiles, setNewFiles] = useState([]);
  const [prevFiles, setPrevFiles] = useState([]);
  const [fileIds, setFileIds] = useState(new Map());
  const [currentFile, setCurrentFile] = useState(0);
  const [filesUpload, setFilesUpload] = useState([]);

  const [purchaseDate, setPurchaseDate] = useState();
  const [saleDate, setSaleDate] = useState();
  const [subscriptionStartDate, setSubscriptionStartDate] = useState();
  const [inServiceOnDate, setInServiceOnDate] = useState();
  const [nextInspectionOnDate, setNextInspectionOnDate] = useState();
  const [tagsExpireOnDate, setTagsExpireOnDate] = useState();
  const [daysLeft, setDaysLeft] = useState();
  const [purchaseDatePriceInput, setPurchaseDatePriceInput] = useState('');
  const [saleDatePriceInput, setSaleDatePriceInput] = useState('');

  const token = localStorage.getItem(
    localStorage.getItem('current_account') + '_access_token'
  );
  const decodedToken = jwtDecode(token);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const actions = [{ icon: <SaveIcon />, name: 'Save' }];

  const newFile = () => {
    const newFilesData = newFiles;
    const currentFileData = currentFile;
    newFilesData.push(currentFileData);
    setNewFiles(newFilesData);
    setCurrentFile(currentFileData + 1);
  };

  const deleteFile = (item) => {
    const newFilesData = newFiles;
    const fileIdsData = fileIds;
    fileIdsData.delete(item);
    setFileIds(fileIdsData);

    const index = newFilesData.indexOf(item);
    if (index > -1) {
      newFilesData.splice(index, 1);
    }
    setNewFiles(newFilesData);
  };

  const deletePrevFile = (item) => {
    const element = filesUpload[item];
    const arr = filesUpload.filter(
      (item) => item.originalFileName !== element.originalFileName
    );
    setFilesUpload(arr);
    const prevFilesData = prevFiles;
    const fileIdsData = fileIds;
    fileIds.delete(item);
    setFileIds(fileIdsData);

    const obj = prevFilesData.find((el) => el.id === item);
    const index = prevFilesData.indexOf(obj);
    if (index > -1) {
      prevFilesData.splice(index, 1);
    }
    setPrevFiles(prevFilesData);
  };

  const uploadFile = (file, item) => {
    const formData = new FormData();
    formData.append('file', file);
    if (file === undefined) {
      return;
    }
    fileUploadService.upload('/file/upload/trailer', formData).then((data) => {
      const newFilesData = newFiles;
      const newFile = newFilesData.indexOf(item);
      newFilesData[newFile] = data;
      const fileIdsData = fileIds;
      fileIdsData.set(data, file.name);
      filesUpload.push(data);
      setFileIds(fileIdsData);
      setNewFiles(newFilesData);
    });
  };

  const newTrailer = () => {
    if (document.querySelector('#trailer_number').value === '') {
      toast.error('Enter trailer number', { transition: Flip });
    } else {
      const dataGeneral = {
        trailerNumber: document.querySelector('#trailer_number').value,
        type: document.querySelector('#trailer_type').value,
        make: document.querySelector('#make').value,
        makeYear: document.querySelector('#make_year').value,
        model: document.querySelector('#model').value,
        vin: document.querySelector('#vin').value,
        titleNumber: document.querySelector('#title_number').value,
        licensePlate: document.querySelector('#license_plate').value,
        purchaseDate,
        saleDate,
        purchaseDatePrice: document.querySelector('#purchase_date_price').value,
        saleDatePrice: document.querySelector('#sale_date_price').value,
        financedBy: document.querySelector('#financed_by').value,
        leasingCompany: document.querySelector('#leasing_company').value,
        leaseReference: document.querySelector('#lease_reference').value,
        inServiceOn: inServiceOnDate,
        tagsExpireOn: tagsExpireOnDate,
        nextInspectionOn: nextInspectionOnDate,
        filesUpload,
        daysLeft,

        ownedBy: document.querySelector('#owned_by').value,
        brand: document.querySelector('#brand').value,
        year: document.querySelector('#year').value,
        reeferUnitType: document.querySelector('#reefer_unit_type').value,
        serialNumber: document.querySelector('#serial_number').value,

        trackingType: document.querySelector('#tracking_type').value,
        unitSerialNumber: document.querySelector('#unit_serial_number').value,
        subscriptionStartDate,

        color: document.querySelector('#color').value,
        roofMaterial: document.querySelector('#roof_material').value,
        wallMaterial: document.querySelector('#wall_material').value,
        floorMaterial: document.querySelector('#floor_material').value,
        wheelTypes: document.querySelector('#wheel_types').value,
        wheelSealType: document.querySelector('#wheel_seal_type').value,
        inflationSystem: document.querySelector('#inflation_system').value,
        tireSize: document.querySelector('#tire_size').value,
        wheelMountType: document.querySelector('#wheel_mount_type').value,
        doorType: document.querySelector('#door_type').value,
        sliderType: document.querySelector('#slider_type').value,
        suspensionType: document.querySelector('#suspension_type').value,
        suspensionModel: document.querySelector('#suspension_model').value,
        suspensionSerial: document.querySelector('#suspension_serial').value,
        lightsType: document.querySelector('#lights_type').value,
      };

      trailerGeneralService.create(dataGeneral).then((res) => {
        if (res) {
          toast.success('Unit successfuly added', { transition: Flip });
          window.history.back();
        } else {
          toast.error('Something went wrong', { transition: Flip });
          // res.text();
        }
      });
    }
  };

  // const sendComment = (text) => {
  //   const data = {
  //     userId: this.state.userId,
  //     username: this.props.username,
  //     comment: text,
  //   };
  //   comments.push(data);
  //   setComments(comments);
  //   document.querySelector('#notes').value = '';
  // };

  const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const checkAllow = (role) => {
    if (role === 'ROLE_ADMIN' || role === 'SUPER_ADMIN') {
      return true;
    }
    return false;
  };

  return (
    <>
      <Helmet>
        <title> Trailers </title>
      </Helmet>
      <Card>
        <CardHeader>
          <h3 className="mb-0">Adding a new Trailer</h3>
        </CardHeader>
        <CardBody>
          <Form className="d-flex">
            <div style={{ width: '50%', marginRight: 20 }}>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Trailer number</span>
                  <span style={{ color: 'red' }}> *</span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="trailer_number"
                    onChange={(e) => {
                      document.querySelector('#trailer_number').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Type</span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="trailer_type"
                    onChange={(e) => {
                      document.querySelector('#trailer_type').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Make</span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="make"
                    onChange={(e) => {
                      document.querySelector('#make').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Make year</span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="make_year"
                    onChange={(e) => {
                      document.querySelector('#make_year').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Model</span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="model"
                    onChange={(e) => {
                      document.querySelector('#model').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>VIN</span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="vin"
                    onChange={(e) => {
                      document.querySelector('#vin').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Title number</span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="title_number"
                    onChange={(e) => {
                      document.querySelector('#title_number').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>License plate</span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="license_plate"
                    onChange={(e) => {
                      document.querySelector('#license_plate').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>
            </div>
            <div style={{ width: '50%', marginRight: 20 }}>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Purchase date /price</span>
                </Col>
                <Col md="4">
                  <Flatpickr
                    id="from"
                    className="form-control"
                    data-enable-time
                    options={{
                      dateFormat: 'Z',
                      altInput: true,
                      altFormat: 'm-d-Y H:i',
                    }}
                    value={purchaseDate}
                    onChange={(val, date, fbo) => {
                      setPurchaseDate(new Date(val[0]).getTime());
                      // fbo.close();
                    }}
                  />
                </Col>
                <Col md="4">
                  <Input
                    autoComplete="off"
                    value={purchaseDatePriceInput}
                    type="text"
                    id="purchase_date_price"
                    onChange={(e) => {
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        setPurchaseDatePriceInput(e.target.value);
                      }
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Sale date /price</span>
                </Col>
                <Col md="4">
                  <Flatpickr
                    id="from"
                    className="form-control"
                    data-enable-time
                    options={{
                      dateFormat: 'Z',
                      altInput: true,
                      altFormat: 'm-d-Y H:i',
                    }}
                    value={saleDate}
                    onChange={(val, date, fbo) => {
                      setSaleDate(new Date(val[0]).getTime());
                      // fbo.close();
                    }}
                  />
                </Col>
                <Col md="4">
                  <Input
                    autoComplete="off"
                    value={saleDatePriceInput}
                    type="text"
                    id="sale_date_price"
                    onChange={(e) => {
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        setSaleDatePriceInput(e.target.value);
                      }
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Financed by</span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="financed_by"
                    onChange={(e) => {
                      document.querySelector('#financed_by').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Leasing company</span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="leasing_company"
                    onChange={(e) => {
                      document.querySelector('#leasing_company').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>

              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Lease reference</span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="lease_reference"
                    onChange={(e) => {
                      document.querySelector('#lease_reference').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>In service on</span>
                </Col>
                <Col md="8">
                  <Flatpickr
                    id="from"
                    className="form-control"
                    data-enable-time
                    options={{
                      dateFormat: 'Z',
                      altInput: true,
                      altFormat: 'm-d-Y H:i',
                    }}
                    value={inServiceOnDate}
                    onChange={(val, date, fbo) => {
                      setInServiceOnDate(new Date(val[0]).getTime());
                      // fbo.close();
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Tags expire on</span>
                </Col>
                <Col md="8">
                  <Flatpickr
                    id="from"
                    className="form-control"
                    data-enable-time
                    options={{
                      dateFormat: 'Z',
                      altInput: true,
                      altFormat: 'm-d-Y H:i',
                    }}
                    value={tagsExpireOnDate}
                    onChange={(val, date, fbo) => {
                      setTagsExpireOnDate(new Date(val[0]).getTime());
                      // fbo.close();
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Next inspection on</span>
                </Col>
                <Col md="8">
                  <Flatpickr
                    id="from"
                    className="form-control"
                    data-enable-time
                    options={{
                      dateFormat: 'Z',
                      altInput: true,
                      altFormat: 'm-d-Y H:i',
                    }}
                    value={nextInspectionOnDate}
                    onChange={(val, date, fbo) => {
                      setNextInspectionOnDate(new Date(val[0]).getTime());
                      // fbo.close();
                    }}
                  />
                </Col>
              </FormGroup>
              {/* <FormGroup className="align-items-center" row>
                                    <Col md="4">
                                        <span>Days Left</span>
                                    </Col>
                                    <Col md="8">
                                        <Flatpickr
                                            id="from"
                                            className="form-control"
                                            data-enable-time
                                            options={{
                                                dateFormat: "Z",
                                                altInput: true,
                                                altFormat: "m-d-Y H:i",
                                            }}
                                            value={this.state.daysLeft}
                                            onChange={(val, date, fbo) => {
                                                this.setState({
                                                    daysLeft: new Date(
                                                        val[0]
                                                    ).getTime(),
                                                });
                                                // fbo.close();
                                            }}
                                        />
                                    </Col>
                                </FormGroup> */}
            </div>
          </Form>
        </CardBody>
      </Card>

      <Card>
        <CardHeader>
          <h3 className="mb-0">Adding a new Ownership</h3>
        </CardHeader>
        <CardBody>
          <Form className="d-flex">
            <div style={{ width: '50%', marginRight: 20 }}>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Owned by</span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="owned_by"
                    onChange={(e) => {
                      document.querySelector('#owned_by').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>
            </div>
          </Form>
        </CardBody>
      </Card>

      <Card>
        <CardHeader>
          <h3 className="mb-0">Adding a new Tracking Information</h3>
        </CardHeader>
        <CardBody>
          <Form className="d-flex">
            <div style={{ width: '50%', marginRight: 20 }}>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Tracking type</span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="tracking_type"
                    onChange={(e) => {
                      document.querySelector('#tracking_type').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Unit serial number</span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="unit_serial_number"
                    onChange={(e) => {
                      document.querySelector('#unit_serial_number').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Subscription start date</span>
                </Col>
                <Col md="8">
                  <Flatpickr
                    id="from"
                    className="form-control"
                    data-enable-time
                    options={{
                      dateFormat: 'Z',
                      altInput: true,
                      altFormat: 'm-d-Y H:i',
                    }}
                    value={subscriptionStartDate}
                    onChange={(val, date, fbo) => {
                      setSubscriptionStartDate(new Date(val[0]).getTime());
                      // fbo.close();
                    }}
                  />
                </Col>
              </FormGroup>
            </div>
          </Form>
        </CardBody>
      </Card>

      <Card>
        <CardHeader>
          <h3 className="mb-0">Adding a new Reefer Unit</h3>
        </CardHeader>
        <CardBody>
          <Form className="d-flex">
            <div style={{ width: '50%', marginRight: 20 }}>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Brand</span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="brand"
                    onChange={(e) => {
                      document.querySelector('#brand').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Year</span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="year"
                    onChange={(e) => {
                      document.querySelector('#year').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Type</span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="reefer_unit_type"
                    onChange={(e) => {
                      document.querySelector('#reefer_unit_type').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Serial number</span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="serial_number"
                    onChange={(e) => {
                      document.querySelector('#serial_number').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>
            </div>
          </Form>
        </CardBody>
      </Card>

      <Card>
        <CardHeader>
          <h3 className="mb-0">Adding a new Trailer Options</h3>
        </CardHeader>
        <CardBody>
          <Form className="d-flex">
            <div style={{ width: '50%', marginRight: 20 }}>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Color</span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="color"
                    onChange={(e) => {
                      document.querySelector('#color').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Roof material</span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="roof_material"
                    onChange={(e) => {
                      document.querySelector('#roof_material').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Wall material</span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="wall_material"
                    onChange={(e) => {
                      document.querySelector('#wall_material').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Floor material</span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="floor_material"
                    onChange={(e) => {
                      document.querySelector('#floor_material').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Wheel types</span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="wheel_types"
                    onChange={(e) => {
                      document.querySelector('#wheel_types').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Wheel seal type</span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="wheel_seal_type"
                    onChange={(e) => {
                      document.querySelector('#wheel_seal_type').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Inflation system</span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="inflation_system"
                    onChange={(e) => {
                      document.querySelector('#inflation_system').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Tire size </span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="tire_size"
                    onChange={(e) => {
                      document.querySelector('#tire_size').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>
            </div>
            <div style={{ width: '50%', marginRight: 20 }}>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Wheel mount type</span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="wheel_mount_type"
                    onChange={(e) => {
                      document.querySelector('#wheel_mount_type').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Door type</span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="door_type"
                    onChange={(e) => {
                      document.querySelector('#door_type').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Slider type</span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="slider_type"
                    onChange={(e) => {
                      document.querySelector('#slider_type').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Suspension type</span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="suspension_type"
                    onChange={(e) => {
                      document.querySelector('#suspension_type').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Suspension model</span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="suspension_model"
                    onChange={(e) => {
                      document.querySelector('#suspension_model').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Suspension serial</span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="suspension_serial"
                    onChange={(e) => {
                      document.querySelector('#suspension_serial').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Lights type</span>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    id="lights_type"
                    onChange={(e) => {
                      document.querySelector('#lights_type').value =
                        e.target.value.toUpperCase();
                    }}
                  />
                </Col>
              </FormGroup>
            </div>
          </Form>
        </CardBody>
      </Card>

      {/* <InfiniteFileUploader
                    newFiles={this.state.newFiles}
                    prevFiles={this.state.prevFiles}
                    deletePrevFile={this.deletePrevFile}
                    newFile={this.newFile}
                    deleteFile={this.deleteFile}
                    uploadFile={this.uploadFile}
                /> */}

      {/* <Card>
                    <CardHeader>
                        <h3 className="mb-0">Comments</h3>
                    </CardHeader>
                    <CardBody>
                        <Form className="d-flex">
                            <div style={{ width: "100%", marginRight: 20 }}>
                                <FormGroup>
                                    <Input
                                        type="textarea"
                                        id="notes"
                                        placeholder="Notes"
                                        // maxLength="10000"
                                        style={{ minHeight: 100 }}
                                    />
                                </FormGroup>
                                <Button
                                    color="success"
                                    className="d-flex align-items-center"
                                    type="button"
                                    onClick={() => {
                                        this.sendComment(
                                            document.querySelector("#notes")
                                                .value
                                        );
                                    }}
                                >
                                    <Icon.Send size={22} /> Send
                                </Button>
                            </div>
                        </Form>
                        <div>
                            {this.state.comments.map((item, i) => (
                                <Paper
                                    key={`${i + 1}`}
                                    style={{
                                        padding: "40px 20px",
                                        marginTop: 10,
                                    }}
                                    elevation={6}
                                >
                                    <Grid container wrap="nowrap" spacing={2}>
                                        <Grid item>
                                            <Avatar
                                                alt=""
                                                src=""
                                                sx={{
                                                    bgcolor: this.stringToColor(
                                                        item.username
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Grid
                                            justifyContent="left"
                                            item
                                            xs
                                            zeroMinWidth
                                        >
                                            <h4
                                                style={{
                                                    margin: 0,
                                                    textAlign: "left",
                                                }}
                                            >
                                                {item.username}
                                            </h4>
                                            <p
                                                style={{
                                                    textAlign: "left",
                                                    color: "gray",
                                                }}
                                            >
                                                {new Date(
                                                    item.publishDate
                                                ).getDate() < 10
                                                    ? "0" +
                                                      new Date(
                                                          item.publishDate
                                                      ).getDate()
                                                    : new Date(
                                                          item.publishDate
                                                      ).getDate()}
                                                /
                                                {new Date(
                                                    item.publishDate
                                                ).getMonth() < 10
                                                    ? "0" +
                                                      new Date(
                                                          item.publishDate
                                                      ).getMonth()
                                                    : new Date(
                                                          item.publishDate
                                                      ).getMonth()}
                                                /
                                                {new Date(
                                                    item.publishDate
                                                ).getFullYear()}{" "}
                                                {new Date(
                                                    item.publishDate
                                                ).getHours() < 10
                                                    ? "0" +
                                                      new Date(
                                                          item.publishDate
                                                      ).getHours()
                                                    : new Date(
                                                          item.publishDate
                                                      ).getHours()}
                                                :
                                                {new Date(
                                                    item.publishDate
                                                ).getMinutes() < 10
                                                    ? "0" +
                                                      new Date(
                                                          item.publishDate
                                                      ).getMinutes()
                                                    : new Date(
                                                          item.publishDate
                                                      ).getMinutes()}
                                            </p>

                                            <p style={{ textAlign: "left" }}>
                                                {item.comment}
                                            </p>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            ))}
                        </div>
                    </CardBody>
                </Card> */}

      {/* <div className="d-flex justify-content-center mt-2">
        <Button
          color="success"
          className="d-flex align-items-center"
          type="button"
          onClick={() => newTrailer()}
        >
          <Icon.Check size={22} /> Save
        </Button>
      </div> */}
      {checkAllow(decodedToken.role) ? (
        <>
          <Backdrop open={open} />
          <SpeedDial
            ariaLabel="SpeedDial"
            sx={{ position: 'absolute', bottom: 16, right: 16 }}
            icon={<MenuIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
          >
            {actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                tooltipOpen
                onClick={() => {
                  handleClose();
                  newTrailer();
                }}
              />
            ))}
          </SpeedDial>
        </>
      ) : null}
    </>
  );
}
