import React, { useState, useEffect, useMemo } from 'react';
import { Card, CardBody, Button } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { TextField, Box } from '@mui/material';
import * as Icon from 'react-feather';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchData } from '../../../../redux/modules/locations/actions';
import { useQuery } from '../../useQuery';
import Loading from '../../../../components/main/Loading';
import Table from '../../../../components/main/table';
import { locationHeader } from '../../../../redux/constants/table/headers';
import { headerMaker } from '../../../../components/main/table/helper';
import { toolTips } from './helper';

export default function Locations() {
  const {
    query,
    search,
    deleted,
    pageIndex,
    pageSize,
    setPageIndex,
    setPageSize,
    setSearch,
    setDeleted,
    handleOnTableChange,
  } = useQuery({ fetchData });

  const [modal, setModal] = useState(false);

  const headers = useMemo(() => headerMaker(locationHeader), [locationHeader]);

  const { data, loading, total } = useSelector(
    (state) => state.locationsReducer
  );
  localStorage.setItem('total_data', total);

  const createHeaders = (headerData) => {
    const cData = headerData.map((item) => ({
      item,
      // ref: useRef(),
    }));
    // console.log(cData);

    // setColumns(cData);

    return cData;
  };

  const [columns, setColumns] = useState(createHeaders(headers));

  // state = {
  //   data: [],
  //   loading: false,
  //   paginationPageSize: 10,
  //   defaultColDef: {
  //     sortable: true,
  //     resizable: true,
  //     // suppressMenu: true,
  //     // wrapText: true,
  //     // autoHeight: true,
  //     floatingFilter: true,
  //     tooltipValueGetter: (params) => {
  //       return params.value;
  //     },
  //   },
  //   columnDefs: [
  //     {
  //       headerName: 'ID',
  //       field: 'id',
  //       minWidth: 100,
  //       maxWidth: 300,
  //       filter: false,
  //     },
  //     {
  //       headerName: 'ANSI',
  //       field: 'ansi',
  //       minWidth: 100,
  //       filter: 'agTextColumnFilter',
  //       flex: 1,
  //     },
  //     {
  //       headerName: 'Name',
  //       field: 'name',
  //       minWidth: 100,
  //       filter: 'agTextColumnFilter',
  //       flex: 1,
  //     },
  //     {
  //       headerName: 'Parent ANSI',
  //       field: 'parentAnsi',
  //       minWidth: 100,
  //       filter: 'agTextColumnFilter',
  //       flex: 1,
  //     },
  //     {
  //       headerName: 'First Time Zone',
  //       field: 'firstTimeZone',
  //       minWidth: 100,
  //       filter: 'agTextColumnFilter',
  //       flex: 1,
  //     },
  //     {
  //       headerName: 'Second Time Zone',
  //       field: 'secondTimeZone',
  //       minWidth: 100,
  //       filter: 'agTextColumnFilter',
  //       flex: 1,
  //     },
  //     {
  //       headerName: 'Parent Time Zone',
  //       field: 'parentTimeZone',
  //       minWidth: 100,
  //       filter: 'agTextColumnFilter',
  //       flex: 1,
  //     },
  //   ],
  // };

  useEffect(() => {}, []);

  const handleModal = () => {
    setModal(!modal);
  };

  return (
    <>
      <Helmet>
        <title> Locations </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: '#000',
          height: 'calc(100vh - 6.5rem)',
          py: 1,
        }}
      >
        <Loading loading={loading} />
        <Card
          className="overflow-hidden agGrid-card"
          style={{ marginRight: 8 }}
        >
          <div className="d-flex justify-content-between mt-2 mx-2 mb-1">
            <h3>All locations list</h3>
            <div>
              <Link
                to="/locations/new"
                className="d-flex align-items-center text-white"
              >
                <Button
                  color="success"
                  className="d-flex align-items-center"
                  type="button"
                  size="sm"
                >
                  <Icon.Plus size={20} /> Add new location
                </Button>
              </Link>
            </div>
          </div>
          <CardBody className="py-0 no-pagination">
            <div className="w-100 ag-grid-table with-pagination">
              <Table
                data={data}
                headers={headers}
                toolTips={toolTips}
                onChange={handleOnTableChange}
                modal={handleModal}
                columns={columns}
                setColumns={setColumns}
                // hidePagination={1}
              />
              <br />
            </div>
          </CardBody>
        </Card>
      </Box>
    </>
  );
}
