import React, { useState, useEffect } from 'react';
import {
  Form,
  FormGroup,
  Input,
  Label,
  CustomInput,
  Col,
  Card,
  CardBody,
  CardHeader,
} from 'reactstrap';
import * as Icon from 'react-feather';
import {
  colors,
  Grid,
  Box,
  CardMedia,
  Button,
  Typography,
} from '@mui/material';
import { connect } from 'react-redux';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { toast, Flip } from 'react-toastify';
import Flatpickr from 'react-flatpickr';
import Cropper from 'react-cropper';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import SweetAlert from '../../../../components/main/SweetAlert';
import unitService from '../../../../services/units';
import fileUploadService from '../../../../services/file_upload';
import stateProvinceService from '../../../../services/state_province';
import driverService from '../../../../services/drivers';
import MobileAppAccess from '../Modals/MobileAppAccess';
import UploadIcon from '../../../../assets/icons/upload.svg';
import ModalWindow from '../../../../components/main/Modals';
import Loading from '../../../../components/main/Loading';
import DragAndDrop from '../components/DragAndDrop';
import AttachmentItem from '../components/AttachmentItem';
import { nationalities } from '../../../../redux/constants/nationalities';

export default function EditDriver() {
  const navigate = useNavigate();
  const [status, setStatus] = useState('');
  const [states, setStates] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [licenseFileId, setLicenseFileId] = useState();

  const [medicalCardFileId, setMedicalCardFileId] = useState();
  const [socialSecurityFileId, setSocialSecurityFileId] = useState();
  const [trucks, setTrucks] = useState([]);
  const [driverStatuses, setDriverStatuses] = useState([]);
  const [driverPermissions, setDriverPermissions] = useState([
    'Active',
    'Terminate',
    'In process',
  ]);
  const [loading, setLoading] = useState(true);
  const [showMobileAppAccessModal, setShowMobileAppAccessModal] =
    useState(false);
  const [login, setLogin] = useState('');

  const { id } = useParams();

  const [selfie, setSelfie] = useState();
  const [photo, setPhoto] = useState('');
  const [cropper, setCropper] = useState();
  const [openCrop, setOpenCrop] = useState(false);
  const [image, setImage] = useState('');

  const [replaceImageStatus, setReplaceImageStatus] = useState(false);
  const [imageFileId, setImageFileId] = useState();

  const [openDrop, setOpenDrop] = useState(false);

  const [filesUpload, setFilesUpload] = useState([]);
  const [fileId, setFileId] = useState();

  const [deleteCDLFile, setDeleteCDLFile] = useState(false);

  const setterStatus = (ids) => {
    driverService
      .editStatus(id, ids)
      .then((res) => {
        if (res) {
          toast.success('Status successfuly changed', {
            transition: Flip,
          });
          setStatus(ids);
        } else {
          toast.error('Something went wrong', { transition: Flip });
          // res.text();
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const uploadSSN = (file) => {
    const formData = new FormData();
    formData.append('file', file, file.name);

    fileUploadService
      .upload('/file/upload', formData)
      .then((data) => setSocialSecurityFileId(data))
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const editDriver = (photoFileId) => {
    const data = {
      id,
      alternatePhone: document.querySelector('#alternatePhone').value,
      city: document.querySelector('#city').value,
      defaultPaymentTypeId: parseInt(
        document.querySelector('#defaultPaymentType').value,
        10
      ),
      email: document.querySelector('#email').value,
      photoFileId: imageFileId,
      firstName: document.querySelector('#firstName').value,
      hireDate: document.querySelector('#hireDate').value,
      lastName: document.querySelector('#lastName').value,
      middleName: document.querySelector('#middleName').value,
      birthDate: new Date(document.querySelector('#birthDate').value).getTime(),
      nationality: document.querySelector('#nationality').value,
      licenseExpiration: document.querySelector('#licenseExpiration').value,
      licenseIssuedJurisdictionId: document.querySelector(
        '#licenseIssuingState'
      ).value,
      licenseNumber: document.querySelector('#licenseNumber').value,
      medicalCardRenewal: document.querySelector('#medicalCardRenewal').value,
      phone: document.querySelector('#phone').value,
      stateProvinceId: parseInt(document.querySelector('#state').value, 10),
      street: document.querySelector('#street').value,
      terminationDate: document.querySelector('#terminationDate').value,
      licenseIssueTime: new Date(
        document.querySelector('#cdlIssueDate').value
      ).getTime(),
      zipCode: document.querySelector('#zipCode').value,
      licenseFileId,
      medicalCardFileId,
      socialSecurityFileId,
      note: document.querySelector('#note').value,
      truckId: parseInt(document.querySelector('#truck').value, 10),
      driverStatusId: parseInt(document.querySelector('#status').value, 10),
    };

    if (photoFileId !== null) {
      data.photoFileId = photoFileId;
    }
    if (document.querySelector('#permission').value === 'In process') {
      data.inProcess = true;
    } else if (document.querySelector('#permission').value === 'Active') {
      data.deleted = false;
      data.inProcess = false;
    } else if (document.querySelector('#permission').value === 'Terminate') {
      data.deleted = true;
      data.inProcess = false;
    }

    driverService
      .update(data)
      .then((res) => {
        if (res) {
          toast.success('Driver successfuly edited', {
            transition: Flip,
          });
          navigate('/drivers/list');
        } else {
          toast.error('Something went wrong', { transition: Flip });
          // res.text();
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const uploadLicense = (photoId) => {
    const data = new FormData();
    filesUpload.forEach((item, i) => {
      if (item.file) {
        data.append(`files`, item.file, item.name);
      }
    });

    if (data.get('files')) {
      fileUploadService
        .upload('/file/upload/license/' + id, data)
        .then((data) => {
          setLoading(false);
          editDriver(photoId);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.response.data.message, {
            transition: Flip,
          });
        });
    } else {
      editDriver(photoId);
    }
  };

  const uploadSelfie = () => {
    if (replaceImageStatus) {
      fetch(selfie)
        .then((response) => response.blob())
        .then((base64Response) => {
          const imageBlob = base64Response;

          const file = new File([imageBlob], 'image.png', {
            type: 'image/png',
            lastModified: new Date().getTime(),
          });
          const formData = new FormData();
          formData.append('file', file, new Date().getTime());

          fileUploadService
            .upload('/file/upload', formData)
            .then((data) => {
              uploadLicense(data);
            })
            .catch((err) => {
              toast.error(err.response.data.message, {
                transition: Flip,
              });
            });
        });
    } else {
      uploadLicense(null);
    }
  };

  const uploadMedicalCart = (file) => {
    const formData = new FormData();
    formData.append('file', file, file.name);

    fileUploadService
      .upload('/file/upload', formData)
      .then((data) => setMedicalCardFileId(data))
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const dateFormat = (dateValue) => {
    const date = new Date(dateValue + 1 * 24 * 60 * 60 * 1000);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const dateS = day.toString().padStart(2, '0');

    const monthS = month.toString().padStart(2, '0');
    return `${year}-${monthS}-${dateS}`;
  };

  const componentDidMount = () => {
    setLoading(true);
    stateProvinceService
      .getAll()
      .then((data) => setStates(data))
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
    driverService
      .getContext()
      .then((data) => {
        setPaymentTypes(data.payment_types);
        setDriverStatuses(data.driver_statuses);

        unitService
          .getAll('size=10000')
          .then((data) => {
            setTrucks(data.content);

            driverService.getOne(id).then((data) => {
              setLicenseFileId(data.licenseFileId);
              setMedicalCardFileId(data.medicalCardFileId);
              setStatus(data.driverStatusId);
              setSocialSecurityFileId(data.socialSecurityFileId);
              setLoading(false);
              setLogin(data.login);

              if (data.inProcess) {
                document.querySelector('#permission').value = 'In process';
              } else if (!data.deleted) {
                document.querySelector('#permission').value = 'Active';
              } else {
                document.querySelector('#permission').value = 'Terminate';
              }

              if (data.photoFileId !== null) {
                if (data.photoFileId !== undefined) {
                  fileUploadService
                    .download('/file/image/' + data.photoFileId)
                    .then((image) => {
                      setSelfie('data:image/png;base64,' + image);
                      setImageFileId(data.photoFileId);
                    })
                    .catch((err) => {
                      toast.error(err.response.data.message, {
                        transition: Flip,
                      });
                    });
                }
              }
              if (data.birthDate !== null) {
                document.querySelector('#birthDate').value = dateFormat(
                  data.birthDate
                );
              }
              if (data.licenseIssueTime !== null) {
                document.querySelector('#cdlIssueDate').value = dateFormat(
                  data.licenseIssueTime
                );
              }

              document.querySelector('#nationality').value = data.nationality;
              document.querySelector('#alternatePhone').value =
                data.alternatePhone;
              document.querySelector('#city').value = data.city;
              document.querySelector('#defaultPaymentType').value =
                data.defaultPaymentTypeId;
              document.querySelector('#email').value = data.email;
              document.querySelector('#firstName').value = data.firstName;
              document.querySelector('#lastName').value = data.lastName;
              document.querySelector('#middleName').value = data.middleName;
              document.querySelector('#hireDate').value = data.hireDate;

              if (data.licenseExpiration !== null) {
                document.querySelector('#licenseExpiration').value = dateFormat(
                  new Date(data.licenseExpiration).getTime()
                );
              }

              document.querySelector('#licenseIssuingState').value =
                data.licenseIssuedJurisdictionId;
              document.querySelector('#licenseNumber').value =
                data.licenseNumber;
              if (data.medicalCardRenewal !== null) {
                document.querySelector('#medicalCardRenewal').value =
                  dateFormat(new Date(data.medicalCardRenewal).getTime());
              }

              document.querySelector('#phone').value = data.phone;
              document.querySelector('#state').value = data.stateProvinceId;
              document.querySelector('#street').value = data.street;
              document.querySelector('#terminationDate').value =
                data.terminationDateFormatted;
              document.querySelector('#zipCode').value = data.zipCode;

              document.querySelector('#note').value = data.note;
              document.querySelector('#status').value = data.driverStatusId;
              document.querySelector('#truck').value = data.truckId;
              if (data.licenseFileIds !== null) {
                // const arrFile = [];
                // data.licenseFileIds.forEach((item, i) => {
                //   arrFile.push(item.id);
                // });
                // setFileIds(arrFile);
                setFilesUpload(data.licenseFileIds);
              }
            });
          })
          .catch((err) => {
            setLoading(false);
            toast.error(err.response.data.message, {
              transition: Flip,
            });
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const deleteSSN = () => {
    setSocialSecurityFileId(null);
  };

  const deleteMedicalCard = () => {
    setMedicalCardFileId(null);
  };

  const toggleMobileAppAccess = () => {
    setShowMobileAppAccessModal(!showMobileAppAccessModal);
  };

  const imgSelectHandler = (e) => {
    if (e.target.files.length !== 0) {
      setImage(URL.createObjectURL(e.target.files[0]));
      // setPreviewImg(URL.createObjectURL(e.target.files[0]));
      setPhoto(e.target.files[0]);
      setOpenCrop(!openCrop);
    }
  };

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      setSelfie(cropper.getCroppedCanvas().toDataURL());
      cropper.getCroppedCanvas().toBlob((blob) => {
        setPhoto(
          new File([blob], 'image.png', {
            type: 'image/png',
            lastModified: new Date().getTime(),
          })
        );
        setReplaceImageStatus(true);
      });
    }
  };

  const handleCropModal = () => {
    setOpenCrop(!openCrop);
  };

  const handleDrop = (filesData) => {
    setOpenDrop(!openDrop);

    const fileListUpload = filesUpload;
    Array.from(filesData).forEach((data) => {
      const a = fileListUpload.length + 1;
      const filterList = fileListUpload.filter((element) => {
        return element.name === data.name;
      });

      if (filterList.length > 0) {
        const spName = data.name.split('.');
        let name = '';
        spName.forEach((val, i) => {
          if (i < spName.length - 1) {
            name += val + '-';
          }
        });
        fileListUpload.push({
          id: a,
          name:
            name +
            'Copy(' +
            filterList.length +
            ').' +
            data.name.split('.').pop(),
          size: data.size,
          file: data,
        });
      } else {
        fileListUpload.push({
          id: a,
          name: data.name,
          size: data.size,
          file: data,
        });
      }
    });

    setFilesUpload(fileListUpload);
  };

  const removeCDLFile = () => {
    driverService
      .deleteLicenseFile(id, fileId)
      .then(() => {
        setDeleteCDLFile(false);
        componentDidMount();
      })
      .catch((err) => {
        setDeleteCDLFile(false);
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const handlerLicense = (id) => {
    setFileId(id);
    setDeleteCDLFile(true);
  };

  useEffect(() => {
    componentDidMount();
  }, []);

  return (
    <>
      <Loading loading={loading} />
      <SweetAlert
        title="Are you sure?"
        warning
        show={deleteCDLFile}
        showCancel
        reverseButtons
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        onConfirm={() => {
          removeCDLFile();
        }}
        onCancel={() => setDeleteCDLFile(false)}
      >
        {}
      </SweetAlert>
      <ModalWindow
        title="Image cropper"
        modal={openCrop}
        handleModal={handleCropModal}
        maxWidth="md"
        body={
          <Box>
            <div style={{ width: '100%' }}>
              <Cropper
                style={{ height: 400, width: '100%' }}
                zoomTo={0}
                initialAspectRatio={1}
                preview=".img-preview"
                src={image}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={true}
              />
            </div>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
                py: 2,
              }}
            >
              <Button
                sx={{
                  backgroundColor: '#CFE0F0',
                  color: '#161616',
                  textTransform: 'none',
                }}
                variant="contained"
                onClick={() => handleCropModal()}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  backgroundColor: '#2A455F',
                  color: 'white',
                  textTransform: 'none',
                }}
                name="add"
                variant="contained"
                type="submit"
                color="primary"
                // startIcon={
                //   isSubmitting ? <CircularProgress size="0.9rem" /> : undefined
                // }
                onClick={() => {
                  getCropData();
                  handleCropModal();
                }}
              >
                Ready
              </Button>
            </Box>
          </Box>
        }
      />
      <MobileAppAccess
        driverId={id}
        login={login}
        modal={showMobileAppAccessModal}
        toggle={toggleMobileAppAccess}
      />
      <Card>
        <CardHeader>
          <h3 className="mb-0">Editing Driver</h3>
        </CardHeader>
        <CardBody>
          <>
            {' '}
            <Form className="d-flex">
              <div
                style={{
                  width: '50%',
                  marginRight: 20,
                }}
              >
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Active/Terminate/In process</span>
                  </Col>
                  <Col md="8">
                    <CustomInput type="select" name="select" id="permission">
                      {driverPermissions.map((item, i) => (
                        <option key={`${i + 1}`} value={item}>
                          {item}
                        </option>
                      ))}
                    </CustomInput>
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Photo</span>
                  </Col>
                  <Col md="8">
                    <Box
                      sx={{
                        py: 2,
                      }}
                    >
                      <CardMedia
                        component="img"
                        style={{
                          width: 210,
                          height: 210,
                          borderRadius: 5,
                          objectFit: 'fill',
                        }}
                        image={selfie || UploadIcon}
                        alt=""
                      />
                      <input
                        id="selectFile"
                        type="file"
                        accept=".png,.jpg"
                        style={{ display: 'none' }}
                        onChange={imgSelectHandler.bind(this)}
                      />
                      <Box
                        sx={{
                          py: 0.5,
                        }}
                      />
                      <Button
                        // type="file"
                        fullWidth
                        size="small"
                        variant="contained"
                        sx={{
                          width: 210,
                          textTransform: 'none',
                          fontSize: 20,
                          transition: 'all 0.3s ease-in-out',
                          backgroundColor: '#2A455F',
                          '&:hover': {
                            backgroundColor: '#2A455F',
                            opacity: 0.8,
                          },
                        }}
                        color="error"
                        onClick={() => {
                          document.getElementById('selectFile').click();
                        }}
                      >
                        Select
                      </Button>
                    </Box>
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>First Name</span>
                    <span style={{ color: 'red' }}> *</span>
                  </Col>
                  <Col md="8">
                    <Input type="text" id="firstName" />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Middle Name</span>
                  </Col>
                  <Col md="8">
                    <Input type="text" id="middleName" />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Last Name</span>
                    <span style={{ color: 'red' }}> *</span>
                  </Col>
                  <Col md="8">
                    <Input type="text" id="lastName" />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Pay to</span>
                    <span style={{ color: 'red' }}> *</span>
                  </Col>
                  <Col md="8">
                    <Input type="text" id="pay_to" />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Birth date</span>
                    <span style={{ color: 'red' }}> *</span>
                  </Col>
                  <Col md="8">
                    <Flatpickr id="birthDate" className="form-control" />
                  </Col>
                </FormGroup>

                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Nationality</span>
                    <span style={{ color: 'red' }}> *</span>
                  </Col>
                  <Col md="8">
                    <CustomInput type="select" name="select" id="nationality">
                      {nationalities.map((item, i) => (
                        <option key={`${i + 1}`} value={item}>
                          {item}
                        </option>
                      ))}
                    </CustomInput>
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Status</span>
                  </Col>
                  <Col md="8">
                    <CustomInput type="select" name="select" id="status">
                      {driverStatuses.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </CustomInput>
                  </Col>
                </FormGroup>

                <FormGroup className="align-items-center mt-3" row>
                  <Col md="4">
                    <span>Truck</span>
                  </Col>
                  <Col md="8">
                    <CustomInput type="select" name="select" id="truck">
                      {trucks.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.number}
                        </option>
                      ))}
                    </CustomInput>
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Street</span>
                  </Col>
                  <Col md="8">
                    <Input type="text" id="street" />
                  </Col>
                </FormGroup>

                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>City</span>
                    <span style={{ color: 'red' }}> *</span>
                  </Col>
                  <Col md="8">
                    <Input type="text" id="city" />
                  </Col>
                </FormGroup>

                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>State/Province</span>
                    <span style={{ color: 'red' }}> *</span>
                  </Col>
                  <Col md="8">
                    <CustomInput type="select" name="select" id="state">
                      {states.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </CustomInput>
                  </Col>
                </FormGroup>

                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Zip Code</span>
                  </Col>
                  <Col md="8">
                    <Input type="text" id="zipCode" />
                  </Col>
                </FormGroup>

                <FormGroup>
                  <Label for="customFile">Note</Label>
                  <Input
                    type="textarea"
                    id="note"
                    placeholder=""
                    maxLength="1000"
                  />
                </FormGroup>
              </div>
              <div style={{ width: '50%' }}>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Email</span>
                  </Col>
                  <Col md="8">
                    <Input type="text" id="email" />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Phone</span>
                  </Col>
                  <Col md="8">
                    <Input type="text" id="phone" placeholder="" />
                  </Col>
                </FormGroup>
                {/* <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Alternate Phone</span>
                  </Col>
                  <Col md="8">
                    <Input type="text" id="alternatePhone" placeholder="" />
                  </Col>
                </FormGroup> */}
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Default Payment Type</span>
                    <span style={{ color: 'red' }}> *</span>
                  </Col>
                  <Col md="8">
                    <CustomInput
                      type="select"
                      name="select"
                      id="defaultPaymentType"
                    >
                      {paymentTypes.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </CustomInput>
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>License number</span>
                  </Col>
                  <Col md="8">
                    <Input type="text" id="licenseNumber" />
                  </Col>
                </FormGroup>

                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>License Expiration</span>
                  </Col>
                  <Col md="8">
                    <Flatpickr
                      id="licenseExpiration"
                      className="form-control"
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>CDL issue date</span>
                  </Col>
                  <Col md="8">
                    <Flatpickr id="cdlIssueDate" className="form-control" />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>License issuing state</span>
                  </Col>
                  <Col md="8">
                    <CustomInput
                      type="select"
                      name="select"
                      id="licenseIssuingState"
                    >
                      {states.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </CustomInput>
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Medical Card Renewal</span>
                  </Col>
                  <Col md="8">
                    <Flatpickr
                      id="medicalCardRenewal"
                      className="form-control"
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Hire Date</span>
                  </Col>
                  <Col md="8">
                    <Flatpickr id="hireDate" className="form-control" />
                  </Col>
                </FormGroup>
                <FormGroup className="align-items-center" row>
                  <Col md="4">
                    <span>Termination Date</span>
                  </Col>
                  <Col md="8">
                    <Flatpickr id="terminationDate" className="form-control" />
                  </Col>
                </FormGroup>
                <Grid item xs={12} md={12} sm={12} my={1}>
                  <Typography
                    variant="h6"
                    align="left"
                    color={colors.grey[700]}
                  >
                    CDL (old, new if available){' '}
                    <span style={{ color: 'red' }}> *</span>
                  </Typography>
                  <input
                    id="licenseFiles"
                    style={{ display: 'none' }}
                    type="file"
                    multiple
                    hidden
                    onChange={(e) => {
                      handleDrop(e.target.files);
                    }}
                    name="[licenseFiles]"
                  />

                  <DragAndDrop handlerDrop={handleDrop}>
                    <div
                      style={{
                        minHeight: '250px',
                        maxHeight: '250px',
                        // display: 'flex',
                        // gap: '6rem',
                        // minWidth: '400px',
                      }}
                    >
                      {filesUpload.map((file, i) => {
                        return (
                          <AttachmentItem
                            key={`${i + 1}`}
                            downloadStatus={true}
                            id={file.id}
                            name={file.name}
                            size={file.size}
                            filesUpload={filesUpload}
                            setFilesUpload={setFilesUpload}
                            i={i}
                            deleteHandler={handlerLicense}
                          />
                        );
                      })}
                    </div>
                  </DragAndDrop>

                  {/* <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.licenseFiles &&
                        formik.touched.licenseFiles &&
                        formik.errors.licenseFiles}
                    </FormHelperText> */}

                  <Button
                    color="primary"
                    fullWidth
                    size="medium"
                    variant="contained"
                    onClick={() => {
                      document.querySelector('#licenseFiles').click();
                    }}
                  >
                    Select files
                  </Button>
                </Grid>
                {/* {licenseFileId ? (
                    <>
                      <div className="d-flex align-items-center justify-content-end">
                        <Button
                          style={{ width: 225 }}
                          className="mt-1"
                          fullWidth
                          size="small"
                          variant="contained"
                          type="button"
                          onClick={() => {
                            fetch(
                              `${process.env.REACT_APP_BASE_URL}/file/${licenseFileId}`,
                              {
                                method: 'GET',
                                headers: {
                                  Authorization:
                                    'Bearer ' +
                                    localStorage.getItem(
                                      localStorage.getItem('current_account') +
                                        '_access_token'
                                    ),
                                },
                              }
                            ).then((response) => {
                              response.blob().then((blob) => {
                                const spl = response.headers
                                  .get('content-disposition')
                                  .split(';');

                                const filename = spl[1]
                                  .split('=')[1]
                                  .replace('"', '');
                                const fileName2 = filename.replace('"', '');

                                // Creating new object of PDF file
                                const fileURL =
                                  window.URL.createObjectURL(blob);
                                // Setting various property values
                                const alink = document.createElement('a');
                                alink.href = fileURL;
                                alink.target = '_blank';
                                alink.download = fileName2;
                                alink.click();
                              });
                            });
                          }}
                        >
                          Download License
                        </Button>
                        <Button
                          className="btn-icon mt-1"
                          color="error"
                          type="button"
                          onClick={() => deleteLicense()}
                        >
                          <Icon.Trash2 />
                        </Button>
                      </div>
                    </>
                  ) : (
                    <FormGroup>
                      <Label for="customFile">Upload License</Label>
                      <CustomInput
                        type="file"
                        id="licenseFile"
                        onInput={(e) => uploadLicense(e.target.files[0])}
                      />
                    </FormGroup>
                  )} */}
                {medicalCardFileId ? (
                  <>
                    <div className="d-flex align-items-center justify-content-end">
                      <Button
                        style={{ width: 225 }}
                        className="mt-1"
                        type="button"
                        fullWidth
                        size="small"
                        variant="contained"
                        // href={`${process.env.REACT_APP_BASE_URL}/file/${this.state.medicalCardFileId}`}
                        onClick={() => {
                          fetch(
                            `${process.env.REACT_APP_BASE_URL}/file/${medicalCardFileId}`,
                            {
                              method: 'GET',
                              headers: {
                                Authorization:
                                  'Bearer ' +
                                  localStorage.getItem(
                                    localStorage.getItem('current_account') +
                                      '_access_token'
                                  ),
                              },
                            }
                          ).then((response) => {
                            response.blob().then((blob) => {
                              const spl = response.headers
                                .get('content-disposition')
                                .split(';');

                              const filename = spl[1]
                                .split('=')[1]
                                .replace('"', '');
                              const fileName2 = filename.replace('"', '');

                              // Creating new object of PDF file
                              const fileURL = window.URL.createObjectURL(blob);
                              // Setting various property values
                              const alink = document.createElement('a');
                              alink.href = fileURL;
                              alink.target = '_blank';
                              alink.download = fileName2;
                              alink.click();
                            });
                          });
                        }}
                      >
                        Download Medical Cart
                      </Button>
                      <Button
                        className="btn-icon mt-1"
                        color="error"
                        type="button"
                        onClick={() => deleteMedicalCard()}
                      >
                        <Icon.Trash2 />
                      </Button>
                    </div>
                  </>
                ) : (
                  <FormGroup>
                    <Label for="customFile">Medical Cart</Label>
                    <CustomInput
                      type="file"
                      id="medicalCart"
                      onInput={(e) => uploadMedicalCart(e.target.files[0])}
                    />
                  </FormGroup>
                )}
                {socialSecurityFileId ? (
                  <>
                    <div className="d-flex align-items-center justify-content-end">
                      <Button
                        style={{ width: 225 }}
                        className="mt-1"
                        type="button"
                        fullWidth
                        size="small"
                        variant="contained"
                        // href={`${process.env.REACT_APP_BASE_URL}/file/${this.state.socialSecurityFileId}`}
                        onClick={() => {
                          fetch(
                            `${process.env.REACT_APP_BASE_URL}/file/${socialSecurityFileId}`,
                            {
                              method: 'GET',
                              headers: {
                                Authorization:
                                  'Bearer ' +
                                  localStorage.getItem(
                                    localStorage.getItem('current_account') +
                                      '_access_token'
                                  ),
                              },
                            }
                          ).then((response) => {
                            response.blob().then((blob) => {
                              const spl = response.headers
                                .get('content-disposition')
                                .split(';');

                              const filename = spl[1]
                                .split('=')[1]
                                .replace('"', '');
                              const fileName2 = filename.replace('"', '');

                              // Creating new object of PDF file
                              const fileURL = window.URL.createObjectURL(blob);
                              // Setting various property values
                              const alink = document.createElement('a');
                              alink.href = fileURL;
                              alink.target = '_blank';
                              alink.download = fileName2;
                              alink.click();
                            });
                          });
                        }}
                      >
                        Download SSN
                      </Button>
                      <Button
                        className="btn-icon mt-1"
                        color="error"
                        type="button"
                        onClick={() => deleteSSN()}
                      >
                        <Icon.Trash2 />
                      </Button>
                    </div>
                  </>
                ) : (
                  <FormGroup>
                    <Label for="customFile">SSN</Label>
                    <CustomInput
                      type="file"
                      id="ssn"
                      onInput={(e) => uploadSSN(e.target.files[0])}
                    />
                  </FormGroup>
                )}
                <Button
                  size="medium"
                  variant="contained"
                  onClick={() => {
                    setShowMobileAppAccessModal(true);
                  }}
                >
                  <Icon.Edit size={22} />
                  &nbsp; Mobile App Access
                </Button>
              </div>
            </Form>
            <Button
              size="medium"
              variant="contained"
              onClick={() => uploadSelfie()}
            >
              <Icon.Check size={22} /> Save Driver
            </Button>
          </>
        </CardBody>
      </Card>
      <Card style={{ width: '30%', minWidth: 400 }}>
        <CardBody>
          <FormGroup className="align-items-center" row>
            <Col md="4">
              <span>Status</span>
            </Col>
            <Col md="8">
              <CustomInput
                type="select"
                name="select"
                id="select"
                onChange={(e) => setterStatus(e.target.value)}
                value={status}
              >
                {driverStatuses.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </CustomInput>
            </Col>
          </FormGroup>
        </CardBody>
      </Card>
    </>
  );
}
