import React, { useState, useEffect } from 'react';

export default function DragAndDrop({ editStatus, children, handlerDrop }) {
  const [drag, setDrag] = useState(false);

  const dropRef = React.createRef();
  let dragCounter = 0;

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter += 1;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDrag(true);
    }
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter -= 1;
    if (dragCounter === 0) {
      setDrag(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);

    console.log(e.dataTransfer.files);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handlerDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
      dragCounter = 0;
    }
  };
  const componentDidMount = () => {
    const div = dropRef.current;
    div.addEventListener('dragenter', handleDragIn);
    div.addEventListener('dragleave', handleDragOut);
    div.addEventListener('dragover', handleDrag);
    div.addEventListener('drop', handleDrop);
  };

  const componentWillUnmount = () => {
    const div = dropRef.current;
    div.removeEventListener('dragenter', handleDragIn);
    div.removeEventListener('dragleave', handleDragOut);
    div.removeEventListener('dragover', handleDrag);
    div.removeEventListener('drop', handleDrop);
  };

  useEffect(() => {
    componentDidMount();
  }, []);

  return (
    <div
      disabled={editStatus}
      style={{
        border: '2px solid gray',
        width: '100%',
        display: 'inline-block',
        position: 'relative',
        overflow: 'auto',
      }}
      ref={dropRef}
    >
      {drag && (
        <div
          style={{
            border: 'dashed grey 4px',
            backgroundColor: 'rgba(255,255,255,.8)',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 9999,
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              right: 0,
              left: 0,
              textAlign: 'center',
              color: 'grey',
              fontSize: 36,
            }}
          >
            <div>drop here :)</div>
          </div>
        </div>
      )}
      {children}
    </div>
  );
}
