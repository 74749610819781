/* eslint-disable consistent-return */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { MakeSelector } from '../Menu/MakeSelector';

export default function DataTable({
  rowData,
  days,
  onCellValueChanged,
  onRowSelected,
  onSelectionChanged,
  currencyFormatter,
  sumEfsFuel,
  sumFuelDiscount,
  sumEfsFleet,
  sumToll,
  sumGross,
  sumTotalPay,
  sumProfitLoss,
}) {
  const [data, setData] = useState([]);
  // const [newSumEfsFuel, setNewSumEfsFuel] = useState(sumEfsFuel);

  useEffect(() => {
    setData(rowData);
    // setNewSumEfsFuel(sumEfsFuel);
  }, [rowData]);

  const frameworkComponents = {
    makeSelector: MakeSelector,
  };

  const autoGroupColumnDef = useMemo(() => {
    return {
      maxWidth: 100,
      cellRendererParams: {
        footerValueGetter: (params) => {
          const isRootLevel = params.node.level === -1;
          if (isRootLevel) {
            return 'Total';
          }
        },
      },
    };
  }, []);

  return (
    <>
      <AgGridReact
        // binding to array properties
        rowData={data}
        // no binding, just providing hard coded strings for the properties
        // boolean properties will default to true if provided (ie enableColResize => enableColResize="true")
        suppressRowClickSelection
        enableCellTextSelection="true"
        rowSelection="multiple"
        enableColResize
        enableSorting
        filter={true}
        sortable={true}
        resizable={true}
        groupHeaders
        rowHeight="30"
        onCellValueChanged={onCellValueChanged}
        onRowSelected={onRowSelected}
        onSelectionChanged={onSelectionChanged}
        groupDefaultExpanded={1}
        suppressAggFuncInHeader={true}
        enableCellChangeFlash={true}
        animateRows={true}
        frameworkComponents={frameworkComponents}
        getRowId={(data) => {
          return data.data.rowId;
        }}
        colResizeDefault="shift"
        pivotPanelShow="always"
        autoGroupColumnDef={autoGroupColumnDef}
        sideBar={true}
        groupIncludeFooter={true}
        groupIncludeTotalFooter={true}
      >
        {/* first column has the checkboxes */}
        <AgGridColumn
          headerName=""
          width={35}
          checkboxSelection={true}
          headerCheckboxSelection={true}
          suppressSorting
          suppressMenu
          suppressFilter
          pinned
          resizable={true}
          filter={true}
          sortable={true}
        />
        <AgGridColumn headerName="Info">
          <AgGridColumn
            field="number"
            headerName="№"
            width={70}
            height={35}
            pinned
            // editable
            rowGroup={true}
            resizable={true}
            sortable={true}
          />
          <AgGridColumn
            field="name"
            headerName="Full name"
            width={150}
            height={35}
            pinned
            editable
            resizable={true}
            filter={true}
            sortable={true}
            valueGetter={(params) => {
              return params.data ? params.data.name : 'Total';
            }}
          />
          <AgGridColumn
            field="email"
            width={110}
            headerName="Email"
            pinned
            editable
            resizable={true}
            filter={true}
            sortable={true}
          />
          <AgGridColumn
            field="address"
            width={110}
            headerName="Address"
            pinned
            editable
            resizable={true}
            filter={true}
            sortable={true}
          />
          <AgGridColumn
            field="phone"
            width={110}
            headerName="Phone"
            pinned
            editable
            resizable={true}
            filter={true}
            sortable={true}
          />
          <AgGridColumn
            field="dispatcher"
            width={110}
            headerName="Dispatcher"
            pinned
            editable
            resizable={true}
            filter={true}
            sortable={true}
          />
          <AgGridColumn
            field="collected_deposit"
            width={110}
            headerName="Collected Deposit"
            pinned
            editable
            resizable={true}
            filter={true}
            sortable={true}
          />
          <AgGridColumn
            field="rate"
            width={90}
            headerName="RATE"
            pinned
            editable
            resizable={true}
            filter={true}
            sortable={true}
          />
          <AgGridColumn
            field="unit"
            width={110}
            headerName="Unit"
            pinned
            // editable
            rowGroup={true}
            resizable={true}
            filter={true}
            sortable={true}
          />
          <AgGridColumn
            field="fuel_discount"
            width={110}
            headerName="FUEL DISCOUNT"
            pinned
            editable
            resizable={true}
            filter={true}
            sortable={true}
            valueGetter={(params) => {
              return params.data
                ? params.data.fuel_discount
                : currencyFormatter(sumFuelDiscount * 1, '$');
            }}
            valueFormatter={(params) =>
              currencyFormatter(
                params.data ? params.data.fuel_discount * 1 : 0,
                '$'
              )
            }
          />
        </AgGridColumn>
        {/* then the last group with three columns */}
        <AgGridColumn headerName="Work Space">
          <AgGridColumn
            field="efs_fuel"
            width={130}
            headerName="EFS FUEL"
            editable
            resizable={true}
            filter={true}
            sortable={true}
            valueGetter={(params) => {
              return params.data
                ? params.data.efs_fuel
                : currencyFormatter(sumEfsFuel * 1, '$');
            }}
            valueFormatter={(params) => {
              currencyFormatter(
                params.data ? params.data.efs_fuel * 1 : 0,
                '$'
              );
            }}
          />
          <AgGridColumn
            field="efs_fleet"
            width={150}
            headerName="EFS FLEET"
            editable
            resizable={true}
            filter={true}
            sortable={true}
            valueGetter={(params) => {
              return params.data
                ? params.data.efs_fleet
                : currencyFormatter(sumEfsFleet * 1, '$');
            }}
            valueFormatter={(params) =>
              currencyFormatter(params.data ? params.data.efs_fleet : 0, '$')
            }
          />
          <AgGridColumn
            field="toll"
            width={150}
            headerName="TOLL"
            editable
            resizable={true}
            filter={true}
            sortable={true}
            valueGetter={(params) => {
              return params.data
                ? params.data.toll
                : currencyFormatter(sumToll * 1, '$');
            }}
            valueFormatter={(params) =>
              currencyFormatter(params.data ? params.data.toll : 0, '$')
            }
          />
          <AgGridColumn
            field="miles"
            width={150}
            headerName="MILES"
            editable
            resizable={true}
            filter={true}
            sortable={true}
          />
          {days.map((item, i) => {
            return (
              <AgGridColumn
                key={`${i + 1}`}
                field={'days_' + i}
                width={150}
                headerName={item}
                editable
                resizable={true}
                filter={true}
                sortable={true}
                // valueParser={"Number(newValue)"}
                // filter={"agNumberColumnFilter"}
                cellEditor="makeSelector"
              />
            );
          })}
          <AgGridColumn
            field="total_day"
            width={150}
            headerName="Total day"
            // editable
            resizable={true}
            sortable={true}
            valueParser="Number(newValue)"
            filter="agNumberColumnFilter"
            valueGetter={(params) => {
              return params.data
                ? params.data.days_0 * 1 +
                    params.data.days_1 * 1 +
                    params.data.days_2 * 1 +
                    params.data.days_3 * 1 +
                    params.data.days_4 * 1 +
                    params.data.days_5 * 1 +
                    params.data.days_6 * 1
                : '';
            }}
          />
          <AgGridColumn
            field="cash_adv"
            width={150}
            headerName="Cash Adv"
            editable
            resizable={true}
            filter={true}
            sortable={true}
          />
          <AgGridColumn
            field="expenses"
            width={150}
            headerName="Expenses"
            editable
            resizable={true}
            filter={true}
            sortable={true}
          />
          <AgGridColumn
            field="bonus"
            width={150}
            headerName="Bonus"
            // editable
            resizable={true}
            filter={true}
            sortable={true}
          />
          <AgGridColumn
            field="violation_damage"
            width={150}
            headerName="Violation & Damage"
            editable
            resizable={true}
            filter={true}
            sortable={true}
          />
          <AgGridColumn
            field="total_pay"
            width={150}
            headerName="Total pay"
            // editable
            resizable={true}
            filter={true}
            sortable={true}
            valueGetter={(params) => {
              return params.data
                ? ((params.data.rate * 1) / 7) * params.data.total_day +
                    params.data.expenses * 1 +
                    params.data.bonus * 1 -
                    params.data.cash_adv * 1 -
                    params.data.violation_damage * 1
                : currencyFormatter(sumTotalPay * 1, '$');
            }}
          />
          <AgGridColumn
            field="note"
            width={150}
            headerName="NOTE"
            editable
            resizable={true}
            filter={true}
            sortable={true}
          />
          <AgGridColumn
            field="gross"
            width={150}
            headerName="Gross"
            editable
            resizable={true}
            filter={true}
            sortable={true}
            valueGetter={(params) => {
              return params.data
                ? params.data.gross
                : currencyFormatter(sumGross * 1, '$');
            }}
            valueFormatter={(params) =>
              currencyFormatter(params.data ? params.data.gross : 0, '$')
            }
          />
          <AgGridColumn
            field="dpm"
            width={150}
            headerName="DPM"
            editable
            resizable={true}
            filter={true}
            sortable={true}
          />
          <AgGridColumn
            field="previous_gross"
            width={150}
            headerName="PREVIOUS GROSS"
            editable
            resizable={true}
            filter={true}
            sortable={true}
          />
          <AgGridColumn
            field="cash_adv_note"
            width={150}
            headerName="Cash ADV note"
            editable
            resizable={true}
            filter={true}
            sortable={true}
          />
          <AgGridColumn
            field="exsp_note"
            width={150}
            headerName="Exsp note"
            editable
            resizable={true}
            filter={true}
            sortable={true}
          />
          <AgGridColumn
            field="bonus_note"
            width={150}
            headerName="Bonus note"
            editable
            resizable={true}
            filter={true}
            sortable={true}
          />
          <AgGridColumn
            field="violation_damage_note"
            width={150}
            headerName="Violation & Damage note"
            editable
            resizable={true}
            filter={true}
            sortable={true}
          />
          <AgGridColumn
            field="paid_by_company"
            width={150}
            headerName="PAID by company"
            editable
            resizable={true}
            filter={true}
            sortable={true}
          />
          <AgGridColumn
            field="salary"
            width={150}
            headerName="SALARY"
            // editable
            resizable={true}
            filter={true}
            sortable={true}
            valueFormatter={(params) =>
              currencyFormatter(params.data ? params.data.salary : 0, '$')
            }
          />
          <AgGridColumn
            field="fuel_efs_toll"
            width={150}
            headerName="FUEL+EFS+TOLL"
            editable
            resizable={true}
            filter={true}
            sortable={true}
            valueFormatter={(params) =>
              currencyFormatter(
                params.data ? params.data.fuel_efs_toll : 0,
                '$'
              )
            }
          />
          <AgGridColumn
            field="dispatch_28"
            width={150}
            headerName="2.8% DISPATCH"
            // editable
            resizable={true}
            filter={true}
            sortable={true}
            valueFormatter={(params) =>
              currencyFormatter(params.data ? params.data.dispatch_28 : 0, '$')
            }
          />
          <AgGridColumn
            field="insurance"
            width={150}
            headerName="INSURANCE"
            editable
            resizable={true}
            filter={true}
            sortable={true}
            valueFormatter={(params) =>
              currencyFormatter(params.data ? params.data.insurance : 0, '$')
            }
          />
          <AgGridColumn
            field="lease"
            width={150}
            headerName="LEASE"
            editable
            resizable={true}
            filter={true}
            sortable={true}
            valueFormatter={(params) =>
              currencyFormatter(params.data ? params.data.lease : 0, '$')
            }
          />
          <AgGridColumn
            field="other_exp"
            width={150}
            headerName="OTHER EXP"
            editable
            resizable={true}
            filter={true}
            sortable={true}
            valueFormatter={(params) =>
              currencyFormatter(params.data ? params.data.other_exp : 0, '$')
            }
          />
          <AgGridColumn
            field="profit_loss"
            width={150}
            headerName="PROFIT/LOSS"
            resizable={true}
            filter={true}
            sortable={true}
            aggFunc={(params) => {
              console.log(params);
            }}
            valueGetter={(params) => {
              return params.data
                ? params.data.gross * 1 -
                    params.data.salary * 1 -
                    params.data.fuel_efs_toll * 1 -
                    params.data.dispatch_28 * 1 -
                    params.data.insurance * 1 -
                    params.data.lease * 1 -
                    params.data.other_exp * 1
                : currencyFormatter(sumProfitLoss * 1, '$');
            }}
            // editable
            valueFormatter={(params) =>
              currencyFormatter(
                params.data
                  ? params.data.gross * 1 -
                      params.data.salary * 1 -
                      params.data.fuel_efs_toll * 1 -
                      params.data.dispatch_28 * 1 -
                      params.data.insurance * 1 -
                      params.data.lease * 1 -
                      params.data.other_exp * 1
                  : 0,
                '$'
              )
            }
          />
        </AgGridColumn>
      </AgGridReact>
    </>
  );
}
