import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Col,
} from 'reactstrap';
import { Link } from 'react-router-dom';

export default function ShowLoadModal({ modal, toggleLoadModal, item }) {
  return (
    <Modal
      centered={true}
      isOpen={modal}
      toggle={toggleLoadModal}
      className="sticky"
      backdrop="static"
    >
      <ModalHeader toggle={() => toggleLoadModal()}>
        {item.loadDtos ? 'Load' : 'Status'}
      </ModalHeader>
      <ModalBody>
        {item.loadDtos ? (
          <Form>
            {item.statusId && item.statusId === 1 ? (
              <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Status:</span>
                </Col>
                <Col md="8">
                  <span> {item.statusName ? item.statusName : ''}</span>
                </Col>
              </FormGroup>
            ) : null}
            <FormGroup className="align-items-center" row>
              <Col md="4">
                <span>Load number:</span>
              </Col>
              <Col md="8">
                <Link
                  className="link-in-table"
                  to={`/loads/edit/${item.loadDtos ? item.loadDtos.id : ''}`}
                >
                  {item.loadDtos ? item.loadDtos.customLoadNumber : ''}
                </Link>
              </Col>
            </FormGroup>
            <FormGroup className="align-items-center" row>
              <Col md="4">
                <span>Truck number:</span>
              </Col>
              <Col md="8">
                <span> {item.truckNumber ? item.truckNumber : ''}</span>
              </Col>
            </FormGroup>
            <FormGroup className="align-items-center" row>
              <Col md="4">
                <span>From:</span>
              </Col>
              <Col md="8">
                <span> {item.loadDtos ? item.loadDtos.from : ''}</span>
              </Col>
            </FormGroup>
            <FormGroup className="align-items-center" row>
              <Col md="4">
                <span>Pickup date:</span>
              </Col>
              <Col md="8">
                <span>
                  {' '}
                  {item.loadDtos ? item.loadDtos.pickupDateFormatted : ''}
                </span>
              </Col>
            </FormGroup>

            <FormGroup className="align-items-center" row>
              <Col md="4">
                <span>To:</span>
              </Col>
              <Col md="8">
                <span> {item.loadDtos ? item.loadDtos.to : ''}</span>
              </Col>
            </FormGroup>
            <FormGroup className="align-items-center" row>
              <Col md="4">
                <span>Delivery date:</span>
              </Col>
              <Col md="8">
                <span>
                  {' '}
                  {item.loadDtos ? item.loadDtos.deliveryDateFormatted : ''}
                </span>
              </Col>
            </FormGroup>
          </Form>
        ) : (
          <Form>
            <FormGroup className="align-items-center" row>
              <Col md="4">
                <span>Status:</span>
              </Col>
              <Col md="8">
                <span> {item.statusName ? item.statusName : ''}</span>
              </Col>
            </FormGroup>
            <FormGroup className="align-items-center" row>
              <Col md="4">
                <span>From: </span>
              </Col>
              <Col md="8">
                <span>
                  {item.startTime && (
                    <p>
                      {new Date(item.startTime).getMonth() + 1 < 10
                        ? '0' +
                          parseInt(new Date(item.startTime).getMonth() + 1, 10)
                        : new Date(item.startTime).getMonth() + 1}
                      -
                      {new Date(item.startTime).getDate() < 10
                        ? '0' + new Date(item.startTime).getDate()
                        : new Date(item.startTime).getDate()}
                      -{new Date(item.startTime).getFullYear()}{' '}
                      {new Date(item.startTime).getHours() < 10
                        ? '0' + new Date(item.startTime).getHours()
                        : new Date(item.startTime).getHours()}
                      :
                      {new Date(item.startTime).getMinutes() < 10
                        ? '0' + new Date(item.startTime).getMinutes()
                        : new Date(item.startTime).getMinutes()}
                    </p>
                  )}
                </span>
              </Col>
            </FormGroup>
            <FormGroup className="align-items-center" row>
              <Col md="4">
                <span>To:</span>
              </Col>
              <Col md="8">
                <span>
                  {item.endTime && (
                    <p>
                      {new Date(item.endTime).getMonth() + 1 < 10
                        ? '0' +
                          parseInt(new Date(item.endTime).getMonth() + 1, 10)
                        : new Date(item.endTime).getMonth() + 1}
                      -
                      {new Date(item.endTime).getDate() < 10
                        ? '0' + new Date(item.endTime).getDate()
                        : new Date(item.endTime).getDate()}
                      -{new Date(item.endTime).getFullYear()}{' '}
                      {new Date(item.endTime).getHours() < 10
                        ? '0' + new Date(item.endTime).getHours()
                        : new Date(item.endTime).getHours()}
                      :
                      {new Date(item.endTime).getMinutes() < 10
                        ? '0' + new Date(item.endTime).getMinutes()
                        : new Date(item.endTime).getMinutes()}
                    </p>
                  )}
                </span>
              </Col>
            </FormGroup>
            <FormGroup className="align-items-center" row>
              <Col md="4">
                <span>User:</span>
              </Col>
              <Col md="8">
                <span>
                  {' '}
                  {item.user && item.user.username ? item.user.username : 'BOT'}
                </span>
              </Col>
            </FormGroup>
          </Form>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => toggleLoadModal()}>
          OK
        </Button>{' '}
      </ModalFooter>
    </Modal>
  );
}
