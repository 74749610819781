import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Chip } from '@mui/material';

export default ({ value }) => {
  return (
    <>
      {value.unitStatus ? (
        <Chip
          size="small"
          style={{
            textAlign: 'center',
            textTransform: 'uppercase',
            color: 'white',
            backgroundColor: value.unitStatusColor,
          }}
          label={value.unitStatus}
          variant="outlined"
        />
      ) : null}
    </>
  );
};
